
<section  class="innovation-section bgBanner">
  <div class="container ">
    <div class="row">
      <div class="col-md-6">
        <h1>Banco de idéias</h1>
        <h1>Inove com confiança</h1>
        <p>
          Tem uma ideia inovadora? Nossa plataforma ajuda a transformar suas propostas em soluções valiosas.
        </p>
        <br />
        <button *ngIf="!conteudo?.showFull" routerLink="/banco-ideias" class="text-md-start text-lg-start text-xl-start" style="display: inline-flex; height: 80px; align-items: center; flex-shrink: 0; border-radius: 5px; background: rgb(245, 250, 255); box-shadow: rgba(0, 0, 0, 0.04) 0px 2.667px 0px 0px; padding: 21px 89px 21px 70.333px;">
          <span _ngcontent-ng-c493740300="" style="color: #19244F; text-align: center; text-shadow: 0px -1.333px 0px rgba(0, 0, 0, 0.12); font-family: var(--fonte-padrao); font-size: 30px; font-style: normal; font-weight: 400; line-height: 37.333px;">
            Saiba mais
          </span>
        </button>
        <button *ngIf="conteudo?.showFull" routerLink="/banco-ideias" class="text-md-start text-lg-start text-xl-start" style="display: inline-flex; height: 80px; align-items: center; flex-shrink: 0; border-radius: 5px; background: rgb(245, 250, 255); box-shadow: rgba(0, 0, 0, 0.04) 0px 2.667px 0px 0px; padding: 21px 89px 21px 70.333px;">
          <span _ngcontent-ng-c493740300="" style="color: #19244F; text-align: center; text-shadow: 0px -1.333px 0px rgba(0, 0, 0, 0.12); font-family: var(--fonte-padrao); font-size: 30px; font-style: normal; font-weight: 400; line-height: 37.333px;">
            Cadastrar idéia
          </span>
        </button>
      </div>
      <div class="col-md-6 " >
       
      </div>

    </div>
  </div>
</section>
<ng-container *ngIf="conteudo?.showFull">
  <section class="innovation-section2">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <img src="/assets/bancoideias1.jpg" class="imgBannerleft">

        </div>
        <div class="col-md-6">
          <h1>
            Envie ideias facilmente
          </h1>
          <p>

            No Banco de Ideias, valorizamos cada proposta criativa. Nossa plataforma simplifica o envio de ideias, permitindo que você compartilhe suas sugestões rapidamente e com facilidade.

          </p>
        </div>


      </div>
    </div>
  </section>

  <div class="image-container">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Inovação Colaborativa</h1>
          <p>
            Participe do nosso espaço de inovação e colabore com propostas criativas que podem mudar o mundo. Juntos, podemos transformar desafios em oportunidades reais para a sociedade.
          </p>
        </div>
      </div>
      <!--<svg class="svg-left" fill="none" viewBox="0 0 404 392" data-v-3612f197=""><defs data-v-3612f197=""><pattern id="8228f071-bcee-4ec8-905a-2a059a2cc4fb" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse" data-v-3612f197=""><rect x="0" y="0" width="4" height="4" class="text-primary/10 dark:text-primary/30" fill="currentColor" data-v-3612f197=""></rect></pattern></defs><rect width="404" height="392" fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" data-v-3612f197=""></rect></svg>-->
    </div>
  </div>
</ng-container>
