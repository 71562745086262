import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-image-viewer',
  template: `
    <button mat-icon-button class="close-button" (click)="fecharPopup()">
      <mat-icon>close</mat-icon>
    </button>
    <img [src]="data.imagemUrl" style="width: 100%" />
  `,
  styles: [`
    .image-viewer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
    
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      color: white; /* ou a cor desejada */
    }
  `]
})
export class ImageViewerComponent {
  constructor(
    public dialogRef: MatDialogRef<ImageViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  fecharPopup() {
    this.dialogRef.close();
  }
}
