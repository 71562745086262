<div style="background-color: aliceblue; width: 100%; padding: 5px; font-weight: 600; margin-bottom: 10px; font-size: 1.3rem; "
     *ngIf="iskit && itemAtualKit?.subtitulo">{{itemAtualKit?.subtitulo}}</div>
<!--{{cartorioEmissor |json}}---->
<app-form-cartorio [uf]="uf"
                   [cidade]="cidade"
                   [itemAtualKit]="itemAtualKit"
                   [iskit]="iskit"
                   (setTemCoberturaFilho)="setTemCoberturaFilho($event)"
                   (setCartorios)="setCartorios($event)"
                   [cartorioEmissor]="cartorioEmissor"
                   [itemAtual]="itemAtual"
                   [multiplo]="true"
                   [classObrigatoria]="classObrigatoria"></app-form-cartorio>
<ng-container *ngIf="temCobertura || edicao">

  <ng-container *ngIf="temCobertura || edicao">

    <form [formGroup]="orderDetails">

      <div class="col-md-12">
        <div class="content-list" formArrayName="compradores">
          <div *ngFor="let comprador of compradores.controls; let i = index, let last=last" [formGroupName]="i" class="row" style="align-items: end;">
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Nome da Busca</mat-label>
                <input matInput type="text" class="col-lg-12" formControlName="nome" placeholder="Nome que Constará na Certidão" maxlength="100">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-radio-group formControlName="tipoPessoa" aria-label="Select an option" (change)="limparDados(i)">
                <mat-radio-button value="fisica">CPF</mat-radio-button>
                <mat-radio-button value="juridica">CNPJ</mat-radio-button>
              </mat-radio-group>
              <ng-container *ngIf="comprador?.get('tipoPessoa')?.value === 'fisica'">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label for="documento">CPF</mat-label>
                  <input matInput type="text" formControlName="documento" mask="000.000.000-00" placeholder="CPF">
                  <mat-error *ngIf="validaCPFCNPJ(i)">
                    Documento inválido
                  </mat-error>
                  <mat-error *ngIf="validaRequired(i)">
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="comprador?.get('tipoPessoa')?.value === 'juridica'">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label for="documento">CNPJ</mat-label>
                  <input matInput type="text" formControlName="documento" mask="00.000.000/0000-00" placeholder="CNPJ">
                  <mat-error *ngIf="validaCPFCNPJ(i)">
                    Documento inválido
                  </mat-error>
                  <mat-error *ngIf="validaRequired(i)">
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </ng-container>
            </div>
            <ng-container *ngIf="comprador?.get('tipoPessoa')?.value === 'fisica'">


              <div class="col-md-3">
                <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label for="nascimento">Data de Nascimento</mat-label>
                  <input matInput appMaskDate maxlength="10" [matDatepicker]="picker" type="text" formControlName="nascimento" maxlength="10">
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error>
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </div>

            </ng-container>


          </div>
        </div>
      </div>

    </form>

    <app-aviso *ngIf="!edicao" [aviso]="itemAtual?.aviso"></app-aviso>

    <div class="row">
      <div class="col-md-5"></div>
      <div class="col-auto" *ngIf="!edicao">
        <button type="button" mat-button [routerLink]="['/']" class="btn-next" style="color: white; background-color: green;">Voltar</button>
      </div>
      <div class="col-auto" *ngIf="edicao">
        <button type="button" mat-button (click)="cancelarEdicaoForm()" class="btn-next" style="color: white; background-color: green;">Cancelar</button>
      </div>
      <div class="col-auto">
        <button mat-button (click)="updatePedido()" class="btn-next"
                style="display: block; margin: 0 auto;">
          <span *ngIf="!edicao">Continuar</span>
          <span *ngIf="edicao">Salvar</span>
        </button>
      </div>
    </div>
  </ng-container>
