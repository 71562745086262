import {AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TesteService} from 'src/app/_sevice/teste.service';
import {environment} from 'src/environments/environment';
import {LocalStorage} from '../../../_entidade/LocalStorage';
import {CrudService} from '../../../_sevice/CrudService';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-form-10',
  templateUrl: './form10.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class Form10Component implements OnInit, AfterViewInit {
  @Input() itemAtual: any;
  @Input() itemEdicao: any;
  @Input() edicao: any=false;
  @Output() cancelarEdicao = new EventEmitter<any>();

  @Output() salvarItem = new EventEmitter<any>();
  cartorioEmissor: any[];
  public visibilidadeDetalhes: boolean[] = [];
  distritos: any
  concelhos: any
  freguesias: any

  camposForm = this.formBuilder.group({
    nomeRequerente: ['', Validators.required],
    finalidade: ['', Validators.required],
    apostilamento: ['false', Validators.required],
    envioCorreios: ['false'],

    localizacao: this.formBuilder.group({
      distrito: ['', Validators.required],
      concelho: ['', Validators.required],
      freguesia: ['', Validators.required],
    }),

    dadosFicha: this.formBuilder.group({
      numero: ['', Validators.required],
      fracaoAutonoma: [''],
      fracaoTemporal: [''],
    }),

    dadosLivro: this.formBuilder.group({
      descricao: [''],
      livro: [''],
      folhas: [''],
      posicaoFolha: [''],
      fracaoAutonoma: [''],
      fracaoTemporal: [''],
    }),

    dadosMatricial: this.formBuilder.group({
      natureza: [''],
      numero: [''],
      provisorio: [''],
      fracao:[''],
      seccao: [''],
      arvores: [''],
    }),

    dadosEntrega: this.formBuilder.group({
      pais: [''],
      estado: [''],
      cidade: [''],
      enderecoCompleto: [''],
    }),
  });

  constructor(
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public teste: TesteService,
    private routeAc: ActivatedRoute,
    public service: CrudService
  ) {

  }
  display = ''
  openModal() {
    this.display = "block";
  }
  onCloseHandled() {
    this.display = "none";
  }
  ngOnInit(): void {
    this.getDistrito()
    if (document.location.href.indexOf('meu-carrinho') == -1) {
      this.carrregarEdicao()
    }
  }

  ngAfterViewInit() {

  }

  // get itemAtual() {
  //   return this.storage.localStorageGet("item_atual");
  // }

  updatePedido() {
    
    this.camposForm.markAllAsTouched();

    if (this.camposForm.valid) {
      let item: any = this.camposForm.value;
      this.salvarItem.emit(item);
    } else if (this.TodosValidosExcetoApostilamento()) {
      let item: any = this.camposForm.value;
      this.salvarItem.emit(item);
    }
  }

  cancelarEdicaoForm() {
    this.cancelarEdicao.emit()
  }

  TodosValidosExcetoApostilamento() {
    const controlsToValidate = [
      'dadosEntrega', 'dadosFicha', 'dadosLivro',
      'dadosMatricial', 'finalidade', 'localizacao', 'nomeRequerente'
    ];

    // A função 'every' retornará 'true' apenas se todos os controles listados estiverem 'VALID', exceto o apostilamento
    // e se o entergaCorreio for undefined,
    return controlsToValidate.every(controlName => {
      const control = this.camposForm.get(controlName);
      return control && control.status === 'VALID';
    }) && this.itemAtual.entregaCorreio === undefined;
  }


  setaDadosEntregaObrigatorio() {
    const envioCorreios = this.camposForm.get('envioCorreios')?.value === 'true';
    const dadosEntregaControls = (this.camposForm.get('dadosEntrega') as FormGroup).controls;

    for (const key in dadosEntregaControls) {
      if (dadosEntregaControls.hasOwnProperty(key)) {
        const control = dadosEntregaControls[key];
        if (envioCorreios) {
          control.setValidators(Validators.required);
        } else {
          control.clearValidators();
        }
        control.updateValueAndValidity();
      }
    }
  }


  carrregarEdicao() {
    let paramId = this.routeAc.snapshot.paramMap.get('id');

    if (paramId != null || this.itemEdicao != null) {
      let pedido = this.storage.localStorageGet("pedido")

      if (pedido?.listaCompra != null && pedido?.listaCompra.length > 0 && paramId != null) {
        let prodAtu = { ...pedido.listaCompra?.find((x: any) => x.idItem == paramId) }
        this.service.preencheCampos(prodAtu, this.camposForm)
        this.getConcelhos(prodAtu?.localizacao?.distrito?.id)
        this.getFreguesia(prodAtu?.localizacao?.concelho?.id)
      }
    } else {
      let prodAtu = this.itemEdicao
      this.service.preencheCampos(prodAtu, this.camposForm)
      this.getConcelhos(prodAtu?.localizacao?.distrito?.id)
      this.getFreguesia(prodAtu?.localizacao?.concelho?.id)
    }
  }

  getCartorios() {
    this.service.getObservable('/cartorios-pedido').subscribe({
      next: async (response) => {
        //console.log(response, "teste")
        if (response != null) {
          this.cartorioEmissor = response

        }
      }, error: (err) => {

        console.error(err);
      }
    });
  }

  getDistrito() {
    this.service.getObservableDirect(environment.http.API_BACK + 'Consulta/Distrito').subscribe({
      next: async (response: any) => {
        this.distritos = response
        if (document.location.href.indexOf('meu-carrinho') == -1) {
          //this.carregarEdicao()
        }
      },
      error: (err) => {

        console.error(err);
      }
    })
  }
  getConcelhosInput(event: any) {
    this.getConcelhos(event.value.id)
  }
  getFreguesiaInput(event: any) {
    this.getFreguesia(event.value.id)
  }
  getConcelhos(value: any) {

    this.service.getObservableDirect(environment.http.API_BACK + 'Consulta/Concelho?idDistrito=' + value).subscribe({
      next: async (response: any) => {
        this.concelhos = response
        this.freguesias = null
      },
      error: (err) => {

        console.error(err);
      }
    })
  }
  getFreguesia(value: any) {

    this.service.getObservableDirect(environment.http.API_BACK + 'Consulta/Freguesia?idConcelho=' + value).subscribe({
      next: async (response: any) => {
        this.freguesias = response
      },
      error: (err) => {

        console.error(err);
      }
    })
  }

  compareDistrito(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }
  compareConcelho(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }
  compareFreguesia(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }
  compareFinalidade(object1: any, object2: any) {

    return object1 && object2 && object1.id == object2.id;
  }
  compareTipoAto(object1: any, object2: any) {

    return object1 && object2 && object1.id == object2.id;
  }



  onCheckboxChange(event: any, item: any) {
    if (event.checked) {
      item["selecionado"] = true
    } else {
      item["selecionado"] = false

    }
  }


  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // //console.log(event.key, event.ctrlKey, event.shiftKey, environment.production)
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {
      this.teste.setValueForm(this.camposForm);
      this.teste.setValueForm(this.camposForm.get('localizacao') as any);
    }
  }

}
