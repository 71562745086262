import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-orderDetails-1',
  templateUrl: './orderDetails1.component.html',
  styleUrls: ['./../_formSteps.component.scss'] 
})
export class OrderDetails1Component  {
  @Input() item: any
  @Output() excluirItem = new EventEmitter<any>();
  @Output() editar = new EventEmitter<any>();
  public visibilidadeDetalhes: boolean=false;

}
