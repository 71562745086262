
<div class="wrapper-item">

  <div class="wrapper-header-item">
    <h3 class="content-title">
      {{ item.produto.titulo }}
      <span *ngIf="item?.isKit"> - {{item?.KitTitulo}}</span>
    </h3>
    <div class="wrapper-buttons">
      <div class="content-button" *ngIf="!item?.isKit">
        <button class="buttons-action" (click)="editar.emit(item)">
          Editar
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/05a3523c983604fe0000ef79b0c38af35a7a81c7e6b32230af7c4a88344d432f?"
               class="img-11" />
        </button>
      </div>
      <div class="content-button">
        <button class="buttons-action" (click)="excluirItem.emit(item)">
          Excluir
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/dcdd700d816beff971594d71d60bdd298e4130c545dd466135231c4783571eb5?"
               class="img-12" />
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="w-100"></mat-divider>

  <div class="wrapper-info-cartorio" *ngFor="let cartorio of item.listCartorio">
    <div class="wrapper-topo">
      <div class="content-left">
        <span class="title-box">Cartório selecionado</span>
        <p class="text-info-box">
          {{ cartorio.cartorio }}
        </p>
      </div>
      <div class="content-right">
        <span class="title-box">Emolumento</span>
        <p class="text-info-box">{{ cartorio.valor | currency:'BRL' }}</p>
      </div>
    </div>

    <ng-container *ngIf="visibilidadeDetalhes">
      <div class="content-info-solicitante">
        <div class="row" *ngIf="item?.finalidade">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Finalidade</h3>
            <p class="info-section">{{item?.finalidade?.descricao}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Estado</h3>
            <p class="info-section">{{item?.estado?.Nome}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Cidade</h3>
            <p class="info-section">{{item?.municipio?.Nome}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section" *ngIf="!item?.produto?.idFormSubtipo">Matrícula do Imóvel</h3>
            <h3 class="title-section" *ngIf="item?.produto?.idFormSubtipo">IPTU</h3>
            <p class="info-section">{{item?.matricula}}</p>
          </div>
          
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">CEP</h3>
            <p class="info-section">{{item.cep}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Tipo de logradouro</h3>
            <p class="info-section">{{item.tipoLogradouro}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Logradouro</h3>
            <p class="info-section">{{item.logradouro}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Número</h3>
            <p class="info-section">{{item.numero}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Bairro</h3>
            <p class="info-section">{{item.bairro}}</p>
          </div>
        </div>
        <h2 class="title-info-solicitante">Complemento</h2>
        <ng-container *ngFor="let complemento of item.complemento">
          <div class="row">
            <div class="wrapper-info col-lg-4">
              <h3 class="title-section">Tipo de logradouro</h3>
              <p class="info-section">{{complemento.tipo}}</p>
            </div>
            <div class="wrapper-info col-lg-4">
              <h3 class="title-section">Número</h3>
              <p class="info-section">{{complemento.valor}}</p>
            </div>
          </div>

        </ng-container>
      </div>
    </ng-container>
  </div>
  <button class="view-more-details" (click)="visibilidadeDetalhes=!visibilidadeDetalhes">
    {{ visibilidadeDetalhes ? 'Ocultar detalhes' : 'Ver detalhes' }}
  </button>
</div>
