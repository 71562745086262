<form [formGroup]="camposForm">
  <div class="row d-flex">

    <!-- Tipo de Solicitação -->
    <ng-container *ngIf="itemAtual && itemAtual?.titulo === 'Registro de Títulos e Documentos'">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="w-100">
            <mat-label for="tipoSolicitacao">Tipo de registro</mat-label>
            <mat-select #tipoSolicitacao formControlName="tipoSolicitacao">
              <mat-option value="Averbação">Novo registro</mat-option>
              <mat-option value="Averbação">Averbação / Alteração de registro</mat-option>
              <mat-option value="Registro">Cancelamento de Registro</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Tipo Documento -->
        <div class="col-md-6">
          <mat-form-field class="w-100">
            <mat-label for="tipoDocumento">Tipo de documento</mat-label>
            <mat-select id="tipoDocumento" name="tipoDocumento" formControlName="tipoDocumento">
              <mat-option value="Registro de contratos; Documentos; Atas">Registro de contratos; Documentos; Atas</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="itemAtual && itemAtual?.titulo === 'Registro de Imóvel'">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="w-100">
            <mat-label for="tipoSolicitacao">Tipo de registro</mat-label>
            <mat-select id="tipoSolicitacao" name="tipoSolicitacao" formControlName="tipoSolicitacao">
              <mat-option value="Registro">Registro</mat-option>
              <mat-option value="Averbação">Averbação</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Tipo Documento -->
        <div class="col-md-6">
          <mat-form-field class="w-100">
            <mat-label for="tipoDocumento">Tipo de documento</mat-label>
            <mat-select id="tipoDocumento" name="tipoDocumento" formControlName="tipoDocumento">
              <mat-option value="Cédula de Crédito Rural">Cédula de Crédito Rural</mat-option>
              <mat-option value="Cédula de Crédito Bancário">Cédula de Crédito Bancário</mat-option>
              <mat-option value="Cédula de Crédito à Exportação">Cédula de Crédito à Exportação</mat-option>
              <mat-option value="Cédula de Crédito Industrial">Cédula de Crédito Industrial</mat-option>
              <mat-option value="Escritura Pública">Escritura Pública</mat-option>
              <mat-option value="Instrumento Particular">Instrumento Particular</mat-option>
              <mat-option value="Instrumento Particular com Força de Escritura Pública">Instrumento Particular com Força de Escritura Pública</mat-option>
              <mat-option value="Requerimento de Averbação">Requerimento de Averbação</mat-option>
              <mat-option value="Ordens Judiciais e Administrativas">Ordens Judiciais e Administrativas</mat-option>
              <mat-option value="Outros">Outros</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-container>


    <div class="row d-flex">
      <!-- UF -->
      <div class="col-md-6">
        <mat-form-field class="w-100">
          <mat-label for="uf">UF:</mat-label>
          <mat-select id="uf" name="uf" (selectionChange)="getCidade()" formControlName="uf" [compareWith]="compareId">
            <mat-option *ngFor="let item of listuf" [value]="item">{{ item.Nome }}</mat-option>
          </mat-select>
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Cidade -->
      <div class="col-md-6">
        <mat-form-field class="w-100">
          <mat-label for="cidade">Cidade:</mat-label>
          <mat-select id="cidade" name="cidade" formControlName="cidade" [compareWith]="compareId">
            <mat-option *ngFor="let item of listCidade" [value]="item">{{ item.Nome }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row" *ngIf="itemAtual?.titulo == 'Registro de Imóvel'">
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Cartório</mat-label>
          <input matInput type="text" class="col-lg-12" formControlName="cartorio" placeholder="Cartório">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row d-flex">
      <!-- Informações Adicionais -->
      <div class="col-md-12">
        <mat-form-field class="w-100">
          <mat-label>Informações Adicionais</mat-label>
          <textarea matInput formControlName="informacaoAdicional" maxlength="255"></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="row d-flex">
      <!-- Arquivo 1 -->
      <div class="content-list col-md-6" [class]="validarArquivosRegistro">
        <h2>Documentos para Registro</h2>
        <app-uploadFiles [tipo]="1" (setaArquivos)="setaArquivos($event)" [filesAtu]="prodAtu?.arquivosRegistro" (resetaArquivos)="resetaArquivos($event)"></app-uploadFiles>
      </div>

      <!-- Arquivo 2 -->
      <div class="content-list col-md-6" [class]="validarArquivosAdicionais">
        <h2>Documentos Adicionais</h2>
        <app-uploadFiles [tipo]="2" (setaArquivos)="setaArquivos($event)" [extensaoTipos]="['gif','jpg','jpeg','png','pdf']" [filesAtu]="prodAtu?.arquivosAdicionais" (resetaArquivos)="resetaArquivos($event)"></app-uploadFiles>
      </div>
    </div>

  </div>
</form>

<app-aviso [aviso]="itemAtual?.aviso" [classObrigatoriaAviso]="classObrigatoriaAviso" (temAceite)="temAceite($event)"></app-aviso>

<div class="row">
  <div class="col-md-5"></div>
  <div class="col-auto" *ngIf="!edicao">
    <button type="button" mat-button [routerLink]="['/']" class="btn-next" style="color: white; background-color: green;">Voltar</button>
  </div>
  <div class="col-auto" *ngIf="edicao">
    <button type="button" mat-button (click)="cancelarEdicaoForm()" class="btn-next" style="color: white; background-color: green;">Cancelar</button>
  </div>
  <div class="col-auto">
    <button mat-button (click)="updatePedido()" class="btn-next" style="display: block; margin: 0 auto;">
      <span  *ngIf="!edicao">Continuar</span> 
        <span  *ngIf="edicao">Salvar</span> 
    </button>
  </div>
</div>
