import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from './constants';

@Pipe({
    name: 'dateFormat',
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  override transform(value: any, args?: any): any {
    if ( value.charAt('/') == 0 ) {
      let dd = value.substring(0,2);
      let mm = value.substring(2,4);
      let yy = value.substring(4,8);
      let dateTemp = new Date(`${yy}/${mm}/${dd}`);
      value = dateTemp;
    }
    return super.transform(value, Constants.DATE_FMT)
  }

}
