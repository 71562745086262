<form [formGroup]="camposForm">
  <div class="row">
    <div class="col-md-6">
      <!--Tipo-->
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Tipo Ato:</mat-label>
        <mat-select formControlName="tipo" [compareWith]="compareTipoAto">
          <mat-option *ngFor="let tipo of tipoAtos" [value]="tipo">
            {{ tipo.descricao }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <!--Finalidade-->
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Finalidade:</mat-label>
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
        <mat-select formControlName="finalidade" [compareWith]="compareFinalidade">
          <mat-option *ngFor="let item of finalidades" [value]="item">
            {{ item.descricao }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-4">
      <!--Distrito-->
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Distrito:</mat-label>
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
        <mat-select formControlName="distrito" (selectionChange)="getConcelhosInput($event)" [compareWith]="compareDistrito">
          <mat-option *ngFor="let item of distritos" [value]="item">
            {{ item.descricao }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-4">
      <!--Concelhos-->
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Concelho:</mat-label>
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
        <mat-select formControlName="concelho" (selectionChange)="getFreguesiaInput($event)" [compareWith]="compareConcelho">
          <mat-option *ngFor="let item of concelhos" [value]="item">
            {{ item.descricao }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-4">
      <!--Freguesia-->
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Freguesia:</mat-label>
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
        <mat-select formControlName="freguesia" [compareWith]="compareFreguesia">
          <mat-option *ngFor="let item of freguesias" [value]="item">
            {{ item.descricao }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!--Nome do Pai-->
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Nome do Pai</mat-label>
          <input matInput type="text" formControlName="pai" maxlength="255">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>

      <!--Nome do Mãe-->
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Nome do Mãe</mat-label>
          <input matInput type="text" formControlName="mae" maxlength="255">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <!--Nome-->
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Nome</mat-label>
          <input matInput type="text" formControlName="nome" maxlength="255">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>

      <!--Conjuge-->
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Conjuge</mat-label>
          <input matInput type="text" formControlName="conjuge" maxlength="255">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <!--Cota Atual-->
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Cota Atual</mat-label>
          <input matInput type="text" formControlName="cota" maxlength="255">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>

      <!--Nº Página-->
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Nº Página</mat-label>
          <input matInput type="number" formControlName="pagina" maxlength="4">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <!--Data Ato/Documento-->
    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label for="dataato">Data Ato/Documento</mat-label>
          <input matInput appMaskDate maxlength="10" [matDatepicker]="picker" type="text" formControlName="dataato"
                 name="dataato">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <!--Informação adicional-->
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Informação Adicional</mat-label>
          <input matInput type="text" class="col-lg-12" formControlName="informacaoAdicional"
                 placeholder="Informação Adicional" maxlength="500">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>

    </div>

    <ng-container *ngIf="itemAtual.entregaCorreio === 'true'">

      <!--Apostilar Certidão-->
      <div class="row">
        <div class="col-md-6">
          <mat-label>Apostilar Certidão? </mat-label>
          <mat-radio-group formControlName="apostilamento" (change)="setaDadosApostilamento()">
            <mat-radio-button value='true'>Sim</mat-radio-button>
            <mat-radio-button value='false'>Não</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <!--Enviar a certidão pelo Correios-->
      <div class="row">
        <div class="col-md-6">
          <mat-label>Enviar a certidão pelo Correios? </mat-label>
          <mat-radio-group formControlName="envioCorreios" (change)="setaDadosEntregaObrigatorio()">
            <mat-radio-button value='true'>Sim</mat-radio-button>
            <mat-radio-button value='false'>Não</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>


      <ng-container *ngIf="camposForm.get('envioCorreios')?.value == 'true'">
        <div class="content-list" formGroupName="dadosEntrega">
          <ng-container class="row">
            <div class="row">
              <!--Pais-->
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Pais</mat-label>
                  <input matInput type="text" formControlName="pais" maxlength="50">
                  <mat-error>
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </div>
              <!--Estado-->
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Estado</mat-label>
                  <input matInput type="text" formControlName="estado" maxlength="150">
                  <mat-error>
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <!--Cidade-->
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Cidade</mat-label>
                  <input matInput type="text" formControlName="cidade" maxlength="150">
                  <mat-error>
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </div>
              <!--Logradouro-->
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Logradouro</mat-label>
                  <input matInput type="text" formControlName="enderecoCompleto" maxlength="250">
                  <mat-error>
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </ng-container>
        </div>

      </ng-container>

    </ng-container>
  </div>

</form>

<app-aviso [aviso]="itemAtual?.aviso" [classObrigatoriaAviso]="classObrigatoriaAviso" (temAceite)="temAceite($event)"></app-aviso>

<div class="row">
  <div class="col-md-5"></div>
  <div class="col-auto" *ngIf="!edicao">
    <button type="button" mat-button [routerLink]="['/']" class="btn-next" style="color: white; background-color: green;">Voltar</button>
  </div>
  <div class="col-auto" *ngIf="edicao">
    <button type="button" mat-button (click)="cancelarEdicaoForm()" class="btn-next" style="color: white; background-color: green;">Cancelar</button>
  </div>
  <div class="col-auto">
    <button mat-button (click)="updatePedido()" class="btn-next"
            style="display: block; margin: 0 auto;">
            <span  *ngIf="!edicao">Continuar</span> 
            <span  *ngIf="edicao">Salvar</span> 
    </button>
  </div>
</div>
