<div class="wrapper-item">

  <div class="wrapper-header-item">
    <h3 class="content-title">
      {{ item.produto.titulo }}
    </h3>
    <div class="wrapper-buttons">
      <div class="content-button">
        <button class="buttons-action"  (click)="editar.emit(item)">
          Editar
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/05a3523c983604fe0000ef79b0c38af35a7a81c7e6b32230af7c4a88344d432f?"
               class="img-11" />
        </button>
      </div>
      <div class="content-button">
        <button class="buttons-action" (click)="excluirItem.emit(item)">
          Excluir
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/dcdd700d816beff971594d71d60bdd298e4130c545dd466135231c4783571eb5?"
               class="img-12" />
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="w-100"></mat-divider>

  <div class="wrapper-info-cartorio">
    <div class="wrapper-topo">
      <div class="content-left">
        <span class="title-box">{{item.produto.titulo}}</span>
      </div>
      <div class="content-right">
        <span class="title-box">Valor</span>
        <p class="text-info-box">{{item.totalAto | currency:'BRL' }}</p>
    </div>
    </div>
    <ng-container *ngIf="visibilidadeDetalhes">
      <div class="content-info-solicitante">
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Requerente</h3>
            <p class="info-section">{{item.nomeRequerente}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Finalidade</h3>
            <p class="info-section">{{ item.finalidade }}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-3">
            <h3 class="title-section">Distrito</h3>
            <p class="info-section">{{item.localizacao.distrito.descricao}}</p>
          </div>
          <div class="wrapper-info col-lg-3">
            <h3 class="title-section">Concelho</h3>
            <p class="info-section">{{item.localizacao.concelho.descricao}}</p>
          </div>
          <div class="wrapper-info col-lg-3">
            <h3 class="title-section">Freguesia</h3>
            <p class="info-section">{{item.localizacao.freguesia.descricao}}</p>
          </div>
        </div>
        <h2 class="title-info-solicitante">Descrição em Ficha ou Informatizado</h2>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Nº da Ficha</h3>
            <p class="info-section">{{item.dadosFicha.numero}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Fração Autónoma</h3>
            <p class="info-section">{{item.dadosFicha.fracaoAutonoma}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Fração Temporal</h3>
            <p class="info-section">{{item.dadosFicha.fracaoTemporal}}</p>
          </div>
        </div>
        <h2 class="title-info-solicitante">Descrição em livro</h2>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Nº de Descrição</h3>
            <p class="info-section">{{item.dadosLivro.descricao}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Livro</h3>
            <p class="info-section">{{item.dadosLivro.livro}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Folhas</h3>
            <p class="info-section">{{item.dadosLivro.folhas}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Fração Autónoma</h3>
            <p class="info-section">{{item.dadosLivro.fracaoAutonoma}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Fração Temporal</h3>
            <p class="info-section">{{item.dadosLivro.fracaoTemporal}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Posição Folha</h3>
            <p class="info-section">{{item.dadosLivro.posicaoFolha}}</p>
          </div>
        </div>
        <h2 class="title-info-solicitante">Informação Matricial</h2>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Natureza</h3>
            <p class="info-section">{{item.dadosMatricial.natureza}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Natureza do Artigo</h3>
            <p class="info-section">{{item.dadosMatricial.numero}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Fração</h3>
            <p class="info-section">{{item.dadosMatricial.fracao}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Secção</h3>
            <p class="info-section">{{item.dadosMatricial.seccao}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Árvore</h3>
            <p class="info-section">{{item.dadosMatricial.arvores}}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <button class="view-more-details" (click)="visibilidadeDetalhes=!visibilidadeDetalhes">
    {{ visibilidadeDetalhes ? 'Ocultar detalhes' : 'Ver detalhes' }}
  </button>
</div>
