import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-orderDetails-21',
  templateUrl: './orderDetails2.1.component.html',
  styleUrls: ['../_formSteps.component.scss'] 
})
export class OrderDetails21Component implements OnInit {
  @Input() item: any
  @Output() excluirItem = new EventEmitter<any>();
  @Output() editar = new EventEmitter<any>();
  public visibilidadeDetalhes: boolean=false;


  constructor(
    
  ) {

  }

  ngOnInit(): void {

  }

}
