<section class="header-pedido">
  <div class="container">
    <h2>Meus Pedidos</h2>
  </div>
</section>
<div class="wrapper-content e-cartorio">
  <div class="container">
    <form [formGroup]="camposForm">
      <ng-container>
        <div class="row">
          <b>Filtros:</b>
          <div class="col-md-2">
            <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="dataInicial">Data inicial</mat-label>
              <input matInput [matDatepicker]="picker" type="text"
                     formControlName="dataInicial"
                     appMaskDate
                     maxlength="10" (dateInput)="pesquisar()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field> 
          </div>
          <div class="col-md-2">
            <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="dataFinal">Data final</mat-label>
              <input matInput [matDatepicker]="picker2" type="text"
                     formControlName="dataFinal"
                     appMaskDate
                     maxlength="10" (dateInput)="pesquisar()">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
             
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Número do pedido</mat-label>
              <input matInput type="text" (input)="pesquisar()" class="col-lg-12" formControlName="numeroPedido" placeholder="Número do pedido">
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Número do ato</mat-label>
              <input matInput type="text" (input)="pesquisar()" class="col-lg-12" formControlName="numeroAto" placeholder="Número do ato">
            </mat-form-field>
          </div>
          <div class="col-md-1"style="text-align: center;">
            <img title="Filtro avançado" style="width: 30px; margin-top: 25px; cursor: pointer;" alt="" src="assets/icon-filter.svg" class="ng-star-inserted" (click)="showMaisFiltro = !showMaisFiltro" >
          </div>

        </div>
        <div class="row" *ngIf="showMaisFiltro">
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>CERP</mat-label>
              <input matInput type="text" (input)="pesquisar()" class="col-lg-12" formControlName="cerp" placeholder="CERP">
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Selo + Aleatório</mat-label>
              <input matInput type="text" (input)="pesquisar()" class="col-lg-12" formControlName="selo" placeholder="Selo + Aleatório  EX:EBTC10015 LCJ">
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Status</mat-label>
              <mat-select formControlName="tipoStatus" (selectionChange)="pesquisar()">
                <mat-option value="">Todos</mat-option>
                <mat-option value="33">Aguardando Análise Documental</mat-option>
                <mat-option value="-33">Aguardando Orçamento</mat-option>
                <mat-option value="38">Aguardando Retirada na Sede do e-Cartório</mat-option>
                <mat-option value="35">Aguardando Retorno do Cliente</mat-option>
                <mat-option value="10">Ato Cancelado</mat-option>
                <mat-option value="11">Ato Disponivel</mat-option>
                <mat-option value="12">Ato Disponivel no Cartório</mat-option>
                <mat-option value="13">Ato Divergente</mat-option>
                <mat-option value="29">Ato Gratuito</mat-option>
                <mat-option value="14">Ato Transferido</mat-option>
                <mat-option value="15">Ato Transmitido</mat-option>
                <mat-option value="31">Coleta de assinatura finalizada</mat-option>
                <mat-option value="30">Coletando assinaturas</mat-option>
                <mat-option value="23">Divergência Material</mat-option>
                <mat-option value="32">Documento Disponível</mat-option>
                <mat-option value="37">Documento Entregue</mat-option>
                <mat-option value="36">Documento Retirado na Sede do e-Cartório</mat-option>
                <mat-option value="16">Em Execução Cartório</mat-option>
                <mat-option value="34">Exigência Documental</mat-option>
                <mat-option value="17">Exigência Finalizada</mat-option>
                <mat-option value="18">Exigência Financeira</mat-option>
                <mat-option value="19">Exigência Informacional</mat-option>
                <mat-option value="28">Exigência Pré-autorizada</mat-option>
                <mat-option value="20">Exigência Respondida</mat-option>
                <mat-option value="21">Exigência sem retorno</mat-option>
                <mat-option value="22">Pagamento Cancelado por compensação</mat-option>
                <mat-option value="1">Pagamento Comissionado</mat-option>
                <mat-option value="6">Pagamento Divergente</mat-option>
                <mat-option value="7">Pagamento Duplicado</mat-option>
                <mat-option value="27">Pagamento não aprovado</mat-option>
                <mat-option value="8">Pagamento Pendente</mat-option>
                <mat-option value="26">Pagamento pré-autorizado</mat-option>
                <mat-option value="9">Pagamento Realizado</mat-option>
                <mat-option value="25">Pré Aprovado</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </form>

    <ng-container *ngFor="let item of pedidos; let i = index">

      <div class="card-item" [id]="i+'p'">
        <div class="row">
          <div class="col-lg-5">
            <b>Número do pedido:</b> {{item?.numeroPedido}}
          </div>

          <div class="col-lg-2" *ngIf="item.todosAtosDisponiveis">
            <a (click)="baixarTodosAtos(item.idPedido, item.numeroPedido)" style="cursor: pointer"><b>Baixar atos</b></a>
          </div>

          
          <div class="col-lg-2" *ngIf="item.dadosPagamento?.notaFiscalDisponivel">
            <a (click)="baixarNotaFiscal(item.idPedido)" style="cursor: pointer"><b>Nota Fiscal</b></a>
          </div>

          <div class="col-lg-3 txtRigth">
            <button mat-button [matMenuTriggerFor]="menu">
              Opções do pedido
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button type="button" mat-menu-item (click)="verDadosFaturamento(item,i)">
                <mat-icon>account_circle</mat-icon>
                <span>Dados do requerente</span>
              </button>
              <button type="button" (click)="verDetalhesPedidoImpressao(item,i)" mat-menu-item [useExistingCss]="true" [bodyClass]="'theme-dark'" [printSectionId]="i+'p'" ngxPrint>
                <mat-icon>print</mat-icon>
                <span>Imprimir</span>
              </button>
              <button type="button" (click)="copiarPedido(item,i)" mat-menu-item>
                <mat-icon>content_copy</mat-icon>
                <span>Copiar</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>

        <ng-container *ngIf="item?.showDadosFaturamento==true">

          <div style="background-color: azure; padding: 10px;">
            <ng-container *ngIf="!item?.dadosFaturamento">
              Sem dados
            </ng-container>
            <ng-container *ngFor="let fatura of item?.dadosFaturamento; let i = index">

              <div class="row">
                <div class="col-sm-3">
                  <h3 class="title-section"><b>Nome</b></h3>
                  <p class="info-section">{{fatura?.nome}}</p>
                </div>
                <div class="col-sm-3">
                  <h3 class="title-section"><b>CPF/CNPJ</b></h3>
                  <p class="info-section">{{fatura?.cnpjCpf}}</p>
                </div>
                <div class="col-sm-4">
                  <h3 class="title-section"><b>Endereço</b></h3>
                  <p class="info-section">{{fatura?.tipoLogradouro}} {{fatura?.logradouro}}, {{fatura?.numero }}-{{fatura?.bairro}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <h3 class="title-section"><b>Cidade e Estado</b></h3>
                  <p class="info-section">{{fatura?.cidade.Nome}} - {{fatura?.uf.Sigla}}</p>
                </div>
                <div class="col-sm-3">
                  <h3 class="title-section"><b>CEP</b></h3>
                  <p class="info-section">{{fatura?.cep}}</p>
                </div>
              </div>
              <hr>
            </ng-container>
          </div>

        </ng-container>
        <!---->
        <div class="row">
          <div class="col-lg-8">
            <b>Data do pedido:</b> {{ getdate(item?.dataCriacao) | date: 'dd/MM/yyyy' }}
          </div>
          <div class="col-lg-4 txtRigth">
            <b>Valor total:</b> {{ item?.totalPagar | currency:'BRL' }}
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 txtCenter">
            <button mat-button *ngIf="item?.verDetalhes" class="button-menu" type="button" (click)="ocultarDetalhes(item,i)">
              Ocultar detalhes
            </button>
            <button mat-button *ngIf="!item?.verDetalhes" class="button-menu" type="button" (click)="verDetalhesPedido(item,i)">
              Ver detalhes
            </button>
          </div>
        </div>
        <!---->
        <ng-container *ngIf="item?.verDetalhes">
          <ng-container *ngFor="let compra of item?.listaCompra">
            <mat-accordion class="d-block col-md-12">
              <mat-expansion-panel [expanded]="true" (click)="verPanelOpenState(item,i)">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="item">
                      <div class="div-16">
                        <div class="div-17">
                          [{{compra?.numeroAto}}] {{compra?.produto?.titulo}}
                        </div>
                        <ng-container *ngIf="compra?.listCartorio">
                          <div class="div-18" *ngFor="let cartorio of compra?.listCartorio">
                            {{cartorio?.cartorio}}
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!compra?.listCartorio">
                          <div class="div-18">
                            {{compra?.cartorio?.nome}}
                          </div>
                        </ng-container>
                      </div>
                      <div class="div-19">
                        <div class="div-20">Status</div>
                        <div class="div-21">
                          {{compra?.status}}
                          <a (click)="$event.stopPropagation();baixarAto(compra?.numeroAto)" *ngIf="compra?.status == 'Ato Disponível' || compra?.status == 'Documento Disponível' "><img style="width: 15px;" alt="" src="assets/icon-download.svg" class="ng-star-inserted"></a>
                        </div>
                      </div>
                      <div class="div-19">
                        <div class="col-lg-4">

                          <b>Valor</b> {{ compra?.totalAto | currency:'BRL' }}
                        </div>
                      </div>
                    </div>
                    <div class="div-22">
                      <div class="div-23">{{ item.panelOpenState ? 'Ocultar detalhes' : 'Ver detalhes' }}</div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container>
 
                </ng-container>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <h2></h2>
    <mat-paginator [length]="totalItens"
                   [pageSize]="tamanhoPagina"
                   [pageSizeOptions]="[5, 10, 25, 100]"
                   (page)="alterarPagina($event)"
                   aria-label="Selecionar página">
    </mat-paginator>
    <h2></h2>
  </div>
</div>

