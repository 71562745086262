import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LocalStorage } from '../_entidade/LocalStorage';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  private AUTH_HEADER = "Authorization";
  private token = this.getTokenUser();
  param2: string='';

  constructor(
    private route: ActivatedRoute,
    public storage: LocalStorage,
    private router: Router
  ) { }

  getTokenUser(): string {

    //console.log('lendo token autenticacao')
   // let param2 = this.route.snapshot.paramMap.get('tk')
    this.param2 = this.route.snapshot.queryParamMap.get('tk') || '';
    let tk = null;
    if (this.param2 != null && this.param2 != '') {

      return this.param2;
    } else {
      if (this.storage.localStorageGet("userlogged") != null) {
        tk = this.storage.localStorageGet("userlogged").token ||''
      } else {
        tk = null;
        if (this.router.url.indexOf('admin/') > -1 || this.router.url.indexOf('fadmin/') > -1)
          this.router.navigate(['/login/entrar']);
      }
    }
    return tk;
  }



  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
     //   (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    let param2 = this.route.snapshot.queryParamMap.get('tk')

    if (param2 && document.location.href.indexOf('/login/entrar') > -1) {

      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.getTokenUser()
        }
      });

      var headers = new Headers({
        "Content-Type": "application/json",
        "Accept": "application/json"
      });

      req = this.addAuthenticationToken(req);
    } else if ((document.location.href.indexOf('/admin') > -1 || document.location.href.indexOf('/fadmin') > -1)
      && this.storage.localStorageGet("userlogged") != null
    ) {
      // //console.log(req.headers)
      if (!req.headers.has("Content-Type")) {
        //console.log('ddf 11100000');
        req = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + this.getTokenUser()
          }
        });
      }
       

      req = this.addAuthenticationToken(req);
    } 

    return next.handle(req)
      .pipe(
        // Log when response observable either completes or errors
        finalize(() => { 

          (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%";
        })
      );

  }



  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {

    if (!this.token) {
      return request;
    }

    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, "Bearer " + this.getTokenUser())
    });
  }
}
