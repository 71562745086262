import { Injectable } from '@angular/core'; 
import { ActivatedRoute,Router } from "@angular/router";
import { LocalStorage } from '../_entidade/LocalStorage';

@Injectable()
export class AuthenticationGuard  {
  param2: string='';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storage: LocalStorage,
     
  ) {
  }

  canActivate(): boolean {  
    this.storage.localStorageRemove("_negociacao"); 
    if (!this.storage.EstaLogado()) {
      this.router.navigate(['/']);
      return false
    } else {
      return true;
    }
  }

  token:any= "";

  getTokenUser(): string {
    this.param2 = this.route.snapshot.queryParamMap.get('tk')||'';
    if (this.param2 != null) {
      this.token = this.param2;
    } else {
      if (this.storage.localStorageGet("userlogg") != null) {
        this.token =this.storage.localStorageGet("userlogg").Token.access_token;
      }
    }
   
    return this.token;
  }

  setUserAuth(user:any): void {
    this.storage.localStorageSet("auth", user);
  }

  getUserAuth(): any {
    if (this.storage.localStorageGet("userlogg") != null) {
      return this.storage.localStorageGet("userlogg");
    }
  }

  setUserPictureProfile(user: any, picture:any): void {
    var dataUser = this.getUserAuth();

    if (dataUser != null) {
      dataUser.ProfilePicture = picture;
    }

    user.ProfilePicture = picture;

    this.setUserAuth(dataUser);
  }
}   
