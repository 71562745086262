import { Component, OnInit, Input, ViewEncapsulation} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { Router } from "@angular/router";

@Component({
  selector: 'app-conteudo',
  templateUrl: './conteudo.component.html',
  styleUrls: ['./conteudo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConteudoComponent implements OnInit {
  @Input() conteudo: any;
  
  constructor(
     public deviceService: DeviceDetectorService
     , private router: Router
  ) { }

  ngOnInit(): void {

  }
     navegar(url: any) {
        this.router.navigate([url])
      }


}
