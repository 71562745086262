<form [formGroup]="camposForm">
  <div class="row">
    <div class="col-md-6">
      <!--tipo Ato-->
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Tipo Ato</mat-label>
        <mat-select formControlName="ato" (selectionChange)="changeAtos($event.value)">
          <mat-option *ngFor="let item of atos" [value]="item.Id">
            {{ item.Descricao }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <!--nome completo-->
  <h2 class="text-center">Dados do Requerente</h2>
  <div class="row">
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Nome Completo</mat-label>
        <input matInput type="text" class="col-lg-12" formControlName="nomeCompleto"
               placeholder="Nome Completo">
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Nº ID Civil</mat-label>
        <input matInput type="text" class="col-lg-12" formControlName="identidade"
               placeholder="Nº ID Civil">
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <!--nif-->
  <div class="row">
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>NIF</mat-label>
        <input matInput type="text" class="col-lg-12" formControlName="nif" maxlength="9"
               placeholder="NIF">
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Telefone</mat-label>
        <input matInput type="text" class="col-lg-12" formControlName="telefone"
               placeholder="Telefone">
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <!--email-->
  <div class="row">
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Email</mat-label>
        <input matInput type="text" class="col-lg-12 email" formControlName="email" placeholder="email">

        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <h2 class="text-center">Dados do Pedido</h2>
  <!--//dados pedido-->
  <div class="row">
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Nome do Pai</mat-label>
        <input matInput type="text" class="col-lg-12" formControlName="nomePai"
               placeholder="Nome do Pai">
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>

    <!--Finalidade-->
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Finalidade</mat-label>
        <mat-select formControlName="finalidade">
          <mat-option *ngFor="let item of finalidades" [value]="item">
            {{ item.Descricao }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!--conjuge e nome da mae-->
  <div class="row">
    <div class="col-md-6" *ngIf="camposForm.get('ato')?.value == '10030'">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Nome do Conjuge</mat-label>
        <input matInput type="text" class="col-lg-12" formControlName="conjuge"
               placeholder="Nome do Conjuge">
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Nome da Mãe</mat-label>
        <input matInput type="text" class="col-lg-12" formControlName="nomeMae"
               placeholder="Nome da Mãe">
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <!--Data de Nascimento e Expedição -->
  <div class="row" *ngIf="camposForm.get('ato')?.value == '10031'">

    <div class="col-md-6">
      <!-- Data Nascimento -->
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Data Nascimento</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="dataNascimento"
               placeholder="Data Nascimento">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>


    <div class="col-md-6">
      <!-- Data Expedição -->
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Data Expedição</mat-label>
        <input matInput [matDatepicker]="picker1" formControlName="dataExpedicao" placeholder="Data Expedição">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>

  </div>

  <div class="row" *ngIf="camposForm.get('ato')?.value == '10030'">
    <!--data casamento-->
    <div class="col-md-6">
      <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label for="dataCasamento">Data do Casamento</mat-label>
        <input matInput [matDatepicker]="picker" type="text" formControlName="dataCasamento">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <!--local casamento-->
  <div class="row" *ngIf="camposForm.get('ato')?.value == '10030' || camposForm.get('ato')?.value == '10032'">
    <div class="col-md-3">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label *ngIf="camposForm.get('ato')?.value == '10030'"> Local Casamento</mat-label>
        <mat-label *ngIf="camposForm.get('ato')?.value == '10032'"> Local Óbito</mat-label>
        <mat-select formControlName="pais">
          <mat-option *ngFor="let item of paises" [value]="item.Descricao">
            {{ item.Descricao }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <ng-container *ngIf="camposForm.get('pais')?.value == 'Portugal'">
      <div class="col-md-3">
        <!--Distrito-->
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Distrito</mat-label>
          <mat-select formControlName="distrito">
            <mat-option *ngFor="let item of distritos" [value]="item.Descricao">
              {{ item.Descricao }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <!--Concelhos-->
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Concelho</mat-label>
          <mat-select formControlName="concelho">
            <mat-option *ngFor="let item of concelhos" [value]="item.Descricao">
              {{ item.Descricao }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <!--Freguesia-->
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Freguesia</mat-label>
          <mat-select formControlName="freguesia">
            <mat-option *ngFor="let item of freguesias" [value]="item.Descricao">
              {{ item.Descricao }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

  </div>

  <div class="row" *ngIf="camposForm.get('ato')?.value == '10032'">
    <!--data obito-->
    <div class="col-md-6">
      <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label for="dataObito">Data Óbito</mat-label>
        <input matInput [matDatepicker]="picker2" type="text" formControlName="dataObito">
        <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <!--Pedido feito por terceiro-->
  <div class="col-md-4">
    <mat-label>Pedido feito por terceiro? </mat-label>
    <mat-radio-group formControlName="pedidoFeitoPorTerceiro">
      <mat-radio-button value="Sim">Sim</mat-radio-button>
      <mat-radio-button value="Nao">Não</mat-radio-button>
    </mat-radio-group>
  </div>
  <ng-container class="row" formGroupName="dadosSolicitante">
    <h2 class="text-center">Dados do Solicitante</h2>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Nome Completo</mat-label>
          <input matInput type="text" class="col-lg-12" formControlName="nomeCompleto"
                 placeholder="Nome Completo">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Nº ID Civil</mat-label>
          <input matInput type="text" class="col-lg-12" formControlName="identidade"
                 placeholder="Nº ID Civil">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>NIF</mat-label>
          <input matInput type="text" class="col-lg-12" formControlName="nif" maxlength="9"
                 placeholder="NIF">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <!-- Data Nascimento -->
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Data Nascimento</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="dataNascimento"
                 placeholder="Data Nascimento">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label> Naturalidade</mat-label>
          <mat-select formControlName="pais">
            <mat-option *ngFor="let item of paises" [value]="item.Descricao">
              {{ item.Descricao }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </ng-container>

  <!--Informação adicional-->
  <div class="row">
    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Informação Adicional</mat-label>
        <input matInput type="text" class="col-lg-12" formControlName="informacaoAdicional"
               placeholder="Informação Adicional">
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <app-uploadFiles (setaArquivos)="setaArquivos($event)" [prodAtu]="prodAtu"></app-uploadFiles>

    </div>


  </div>


</form>

<app-aviso [aviso]="itemAtual?.aviso"></app-aviso>

<div class="row">
  <div class="col-md-5"></div>
  <div class="col-auto" *ngIf="!edicao">
    <button type="button" mat-button [routerLink]="['/']" class="btn-next" style="color: white; background-color: green;">Voltar</button>
  </div>
  <div class="col-auto" *ngIf="edicao">
    <button type="button" mat-button (click)="cancelarEdicaoForm()" class="btn-next" style="color: white; background-color: green;">Cancelar</button>
  </div>
  <div class="col-auto">
    <button mat-button (click)="updatePedido()" class="btn-next"
            style="display: block; margin: 0 auto;">
      Continuar
    </button>
  </div>
</div>
