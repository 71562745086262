import { ChangeDetectorRef, Component, HostListener, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { configPage } from '../../_configPage/configPage';
import { ConfigPaginas } from '../../_configPage/dadosPaginas';
import { globalPage } from '../../_configPage/globalPage';
import { ModelPage } from '../../_configPage/modelPage';
import { LocalStorage } from '../../_entidade/LocalStorage';
import { CrudService } from '../../_sevice/CrudService';
import { MockService } from '../../_sevice/MockService';

@Component({
  selector: 'crud-global',
  templateUrl: './crud-global.component.html',
  styleUrls: ['./crud-global.component.scss']
})
export class CrudGlobalComponent implements OnInit, OnDestroy, OnChanges {
  navigationSubscription;
  edicao: boolean = false;
  dataJson: any = new globalPage().Itens;
  configPaginas: any = new ConfigPaginas().Paginas;
  conteudoPagina: any;
  entidadeFiltroOriginal: any;
  entidadeOriginal: any;
  dadosEntidadeOriginal: any;
  loadItens: boolean = false;
  page: number = 1;
  configPage: any = new configPage().Itens;
  collectionSize: number = 0;
  dataSouceGrid: any;
  previousPage = 0;
  pageSizeAtual = 0;
  model: any = new ModelPage().Itens;
  edicaoPG: boolean = false
  tipoGrid: any = null

  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
    public service: CrudService,
    public mockService: MockService,
    public st: LocalStorage
  ) {
    //this.listarEntidadeLocal = debounce(async () => {this.listarEntidadeLocal}, 200)
    this.edicaoPG = this.st.localStorageGet('modoEdicao') != null;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        // //console.log('ddffd')
        this.tipoGrid = null
        this.loadPagina();

      }
    });

  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  async ngOnInit() {
    //console.log('asdf')
    //this.loadPagina();
  }

  ngOnChanges() {
    // this.cdRef.detectChanges();
  }
  ngAfterViewInit(): void {

    // this.cdRef.detectChanges();

  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.keyCode == 69 && event.ctrlKey && event.shiftKey) {
      if (this.st.localStorageGet("modoEdicao") == null) {
        this.st.localStorageSet("modoEdicao", 1)
        this.edicaoPG = true
      } else {
        this.st.localStorageRemove("modoEdicao")
        this.edicaoPG = false
      }
      window.location.reload()


    }
  }

  async listarEntidadeLocal(forcarCarregamento: boolean = false) {
    if (this.conteudoPagina.urlLista != '' && this.conteudoPagina.pesquisar) {
      if (!this.conteudoPagina.paginaAtual) {
        this.conteudoPagina.paginaAtual = 1
      }

      if (
        this.previousPage != this.conteudoPagina.paginaAtual
        || this.configPage.pageSize != this.pageSizeAtual
        || forcarCarregamento
      ) {
        this.pageSizeAtual = this.configPage.pageSize;

        if (this.conteudoPagina.paginaAtual == -1) {
          this.conteudoPagina.paginaAtual = 1;
        }
        this.previousPage = this.conteudoPagina.paginaAtual;

        if (this.conteudoPagina.condicoesFiltro) {

          for (var i = 0; i < this.conteudoPagina.condicoesFiltro.length; i++) {
            var object = this.conteudoPagina.condicoesFiltro[i]["condicao"]
            var itesn: Array<any> = new Array();
            var executeAcao = false;
            for (let key in object) {
              if (key != 'acao') {
                var obj1 = JSON.stringify(this.conteudoPagina.dadosFiltro['default'][key])
                var obj2 = JSON.stringify(object[key])

                if (obj1 == obj2) {
                  executeAcao = true;

                }
              }
            }

            if (executeAcao) {
              var acao = this.conteudoPagina.condicoesFiltro[i]["condicao"]["acao"]
              this.conteudoPagina.dadosFiltro['default'][acao.prop] = acao.valor
            }
          }

        }

        let filtro = this.conteudoPagina.dadosFiltro ? this.conteudoPagina.dadosFiltro.default : null;
        let urlLista = this.conteudoPagina.urlLista ? this.conteudoPagina.urlLista : this.conteudoPagina.urlRest

        if (this.conteudoPagina.urlLista) {
          await this.service.postObservable(filtro, urlLista).subscribe(
            resposta => {

              if (resposta) {
                this.conteudoPagina.optionsProp = resposta.ReturnData;
                this.conteudoPagina.collectionSize = resposta.QtdeItens;
                this.page = this.conteudoPagina.paginaAtual;
                this.conteudoPagina.optionsPropPag = this.conteudoPagina.optionsProp

                this.loadItens = true;
                let tableArr: any[] = this.conteudoPagina.optionsProp
                this.dataSouceGrid = new MatTableDataSource(tableArr);
                this.tipoGrid = this.conteudoPagina["tipoGrid"] == null ? 'grid' : this.conteudoPagina["tipoGrid"]



              }
            })
        } else {
          if (urlLista != undefined) {
            await this.service.getObservable(urlLista).subscribe(
              resposta => {

                if (resposta) {
                  this.conteudoPagina.optionsProp = resposta.ReturnData;
                  this.conteudoPagina.collectionSize = resposta.QtdeItens;
                  this.page = this.conteudoPagina.paginaAtual;
                  this.conteudoPagina.optionsPropPag = this.conteudoPagina.optionsProp

                  this.loadItens = true;
                  let tableArr: any[] = this.conteudoPagina.optionsProp
                  this.dataSouceGrid = new MatTableDataSource(tableArr);
                  this.tipoGrid = this.conteudoPagina["tipoGrid"] == null ? 'grid' : this.conteudoPagina["tipoGrid"]



                }
              })
          }else{
            this.conteudoPagina.edicao = true
            this.conteudoPagina.dadosEntidade ={"default":{"Nacionalidade":"","TipoDocumento":""}}
            //console.log('asdf', this.conteudoPagina)
          }
        }

      }

    }
    else if (this.conteudoPagina.urlLista == '' && this.conteudoPagina.pesquisar) {
      ////console.log('entrou')

      if (this.previousPage != this.conteudoPagina.paginaAtual || this.configPage.pageSize != this.pageSizeAtual) {

        this.pageSizeAtual = this.configPage.pageSize;
        if (this.conteudoPagina.paginaAtual == -1) {
          this.conteudoPagina.paginaAtual = 1;

        }
        this.previousPage = this.conteudoPagina.paginaAtual;

        if (!this.conteudoPagina.optionsProp || this.conteudoPagina.optionsProp.length == 0) {
          await this.mockService.gerarMockLista(30, this.conteudoPagina);
          let tableArr: any[] = this.conteudoPagina.optionsProp
          let itensCarregar = tableArr.slice(0, this.configPage.pageSize)
          this.conteudoPagina.optionsPropPag = itensCarregar

          this.dataSouceGrid = new MatTableDataSource(itensCarregar);


          //this.dataSouceGrid = new MatTableDataSource(tableArr);
          this.loadItens = true;

        } else {

          let tableArr: Array<any> = new Array();
          for (var i = 0; i < this.conteudoPagina.optionsProp.length; i++) {
            let encontrouTodos = true;
            for (let key in this.conteudoPagina.dadosFiltro.default) {

              if (typeof this.conteudoPagina.dadosFiltro.default[key] != 'object') {
                // //console.log(this.conteudoPagina.optionsProp)
                if (this.conteudoPagina.dadosFiltro.default[key] && this.conteudoPagina.dadosFiltro.default[key] != '' && key != 'Pagina') {
                  if (this.conteudoPagina.optionsProp[i][key].toLowerCase().indexOf(this.conteudoPagina.dadosFiltro.default[key].toLowerCase()) < 0) {
                    encontrouTodos = false;
                  }

                }
              } else {
                //let very = this.conteudoPagina.optionsProp[i][key].indexOf(this.conteudoPagina.dadosFiltro.default[key]) < 0
                // //console.log(this.conteudoPagina.dadosFiltro.default[key])
                if (this.conteudoPagina.dadosFiltro.default[key]['Descricao'] != '' && this.conteudoPagina.dadosFiltro.default[key] != this.conteudoPagina.optionsProp[i][key]) {
                  //console.log(this.conteudoPagina.dadosFiltro.default[key])
                  encontrouTodos = false;

                }
              }
            }
            //console.log(encontrouTodos)
            if (encontrouTodos)
              tableArr.push(this.conteudoPagina.optionsProp[i])


          }

          const start = (this.conteudoPagina.paginaAtual - 1) * this.configPage.pageSize;
          const end = (this.conteudoPagina.paginaAtual) * this.configPage.pageSize;
          this.conteudoPagina.collectionSize = tableArr.length
          let itensCarregar = tableArr.slice(start, end)
          this.conteudoPagina.optionsPropPag = itensCarregar
          this.dataSouceGrid = new MatTableDataSource(itensCarregar);
        }
      }

    }
  }
  abaAtual: any = { label: '', rota: '' };
  editarAba(aba: any) {
    this.abaAtual = aba
      (<HTMLInputElement>document.getElementById("btnModalEditarPagina")).click();

  }
  confirmarEdicaoPagina() {
    this.st.localStorageSet('pgEdit', this.conteudoPagina)
  }
  changeConteudo(novoConteudo: any) {
    this.conteudoPagina = this.st.localStorageGet('pgConfig')
  }

  async loadPagina() {
    if (this.st.localStorageGet('pgCrudEdit') != null) {

      this.conteudoPagina = this.st.localStorageGet('pgCrudEdit')
      this.concluirPagina()

    } else {
      var url = this.router.url.replace(/[/]/g, '')

      let id = this.st.localStorageGet("projeto").IdProjeto

      await this.service.getObservableDirect(environment.http.API_SISTEMA + 'pagina/GetPage/' + url + '/' + id).subscribe(
        resposta => {
          if (resposta) {
            if (resposta.JsonPagina != null) {
              //console.log(resposta)
              this.st.localStorageSet("pgCrudEdit", JSON.parse(resposta.JsonPagina))
              this.st.localStorageSet("pgInfo", resposta)
              // this.st.localStorageSet("pgCrudEdit", resposta.JsonPagina)
              this.conteudoPagina = JSON.parse(resposta.JsonPagina);
              this.concluirPagina()
            } else {

              this.conteudoPagina = this.model[1];
            }
          } else {
            this.conteudoPagina = this.model[1];
          }
        })
    }
  }
  concluirPagina() {

    this.previousPage = 0;
    this.collectionSize = 0;

    if (this.conteudoPagina) {
      this.conteudoPagina["paginaAtual"] = -1;
      this.entidadeFiltroOriginal = { ...this.conteudoPagina.inputsFiltro };
      this.entidadeOriginal = { ...this.conteudoPagina.inputs };

      if (this.conteudoPagina.dadosEntidade) {
        this.st.localStorageSet("dadosEntidadeOriginal", this.conteudoPagina.dadosEntidade)
        this.dadosEntidadeOriginal = this.st.localStorageGet("dadosEntidadeOriginal");

      }
      this.conteudoPagina.pesquisar = false;
      this.conteudoPagina.edicao = false;
      if (this.conteudoPagina.urlLista == '') {
        this.pageSizeAtual = this.configPage.pageSize;
        this.conteudoPagina.paginaAtual = 1;
        this.previousPage = this.conteudoPagina.paginaAtual;
        this.mockService.gerarMockLista(30, this.conteudoPagina);
        this.conteudoPagina.collectionSize = 30;
        let tableArr: any[] = this.conteudoPagina.optionsProp
        let itensCarregar = tableArr.slice(0, this.configPage.pageSize)
        this.conteudoPagina.optionsPropPag = itensCarregar;
        //console.log(this.conteudoPagina.optionsPropPag)
        this.dataSouceGrid = new MatTableDataSource(itensCarregar);

        this.tipoGrid = this.conteudoPagina["tipoGrid"] == null ? 'grid' : this.conteudoPagina["tipoGrid"]

      } else {
        //console.log('dddd')
        this.conteudoPagina.pesquisar = true;
        this.listarEntidadeLocal();
      }
    }
    this.loadItens = true;

  }

  listar(recarregar: boolean = false) {
    this.listarEntidadeLocal(recarregar);
  }



  editar(modoEdicao: boolean) {

    this.conteudoPagina.edicao = modoEdicao
  }

}
