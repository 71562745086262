
  <div class="row mt-1 headerEdit">
    <div class="col-sm mt-2">
      <h3 class="title-page">{{conteudoPagina.title}}</h3>
    </div>
  </div>

<div class="container-fluid h-100" *ngIf="conteudoPagina">
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <app-generic-form [conteudoPagina]="conteudoPagina"
                        (recarregarGrid)="listarGrid()"
                        [editar]="true"
                        [dados]="conteudoPagina.dadosEntidade"
                        style="width:100%"
                        [inputs]="conteudoPagina.inputs">

      </app-generic-form>
    </div>
  </div>
</div>
