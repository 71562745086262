import {AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormControlName, Validators} from '@angular/forms';
import {TesteService} from 'src/app/_sevice/teste.service';
import {environment} from 'src/environments/environment';
import {LocalStorage} from '../../../_entidade/LocalStorage';
import {CrudService} from '../../../_sevice/CrudService';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-form-12',
  templateUrl: './form12.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class Form12Component implements OnInit, AfterViewInit {
  @Input() itemAtual: any;
  @Input() itemEdicao: any;
  @Input() edicao: any=false;
  @Output() cancelarEdicao = new EventEmitter<any>();

  listuf: any[];
  listCidade: any[];

  @Input() documentos: any;

  @Output() salvarItem = new EventEmitter<any>();

  camposForm = this.formBuilder.group({
    tipoRegistro: ['', Validators.required],
    tipoDocumento: ['', Validators.required],
    uf: ['', Validators.required],
    cidade: ['', Validators.required],
    informacaoAdicional: ['', Validators.required],
    arquivosRegistro: new FormControl([]),
    arquivosAdicionais:new FormControl([])
  });

  constructor(
    private formBuilder: FormBuilder,
    private routeAc: ActivatedRoute,
    public storage: LocalStorage,
    public teste: TesteService,
    public service: CrudService
  ) {

  }

  ngOnInit(): void {
    if (document.location.href.indexOf('meu-carrinho') == -1) {
      this.getEstado();
    }
  }

  ngAfterViewInit() {

  }

  // get itemAtual() {
  //   return this.storage.localStorageGet("item_atual");
  // }


  getEstado() {
    const url = environment.http.API + 'localidade/uf'
    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {
        if (response != null) {
          this.listuf = response;
          this.carrregarEdicao()
        }
      }, error: (err) => {
        console.error(err);
      }
    });
  }

  cancelarEdicaoForm() {
    this.cancelarEdicao.emit()
  }

  getCidade() {
    // @ts-ignore
    //console.log(this.camposForm.get('uf').value);
    // @ts-ignore
    let idEstado = this.camposForm.get('uf').value['IdEstado'];
    const url = environment.http.API + 'Localidade/cidade?IdEstado=' + idEstado;
    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {
        if (response != null) {
          this.listCidade = response;
        }
      }, error: (err) => {

        console.error(err);
      }
    });
  }


  onCheckboxChange(event: any, item: any) {
    if (event.checked) {
      item["selecionado"] = true
    } else {
      item["selecionado"] = false

    }
  }

  updatePedido() {
    //console.log(this.camposForm, "form teste");
    
    this.camposForm.markAllAsTouched();
    if (this.camposForm.valid) {
      let prodAtu = null

      prodAtu = this.camposForm.value

      this.salvarItem.emit(prodAtu);
    }

  }

  //setaArquivos(arquivos: any) {

  //  //console.log('camposForm', this.camposForm.value)

  //  //this.camposForm.controls.arquivosRegistro.setValue(arquivos);

  //  //this.camposForm.controls.arquivosAdicionais.setValue(arquivos);
  //}


  setaArquivosRegistro(arquivos: any) {
    // Lógica para lidar com os arquivos do registro
    //console.log('Arquivos para registro:', arquivos);
    this.camposForm.controls.arquivosRegistro.setValue(arquivos);
  }

  setaArquivosAdicionais(arquivos: any) {
    // Lógica para lidar com os arquivos adicionais
    //console.log('Arquivos adicionais:', arquivos);
    this.camposForm.controls.arquivosAdicionais.setValue(arquivos);
  }

  prodAtu : any
  carrregarEdicao() {
    let paramId = this.routeAc.snapshot.paramMap.get('id');

    if (paramId != null || this.itemEdicao != null) {
      let pedido = this.storage.localStorageGet("pedido")

      if (pedido?.listaCompra != null && pedido?.listaCompra.length > 0) {
        this.prodAtu = { ...pedido.listaCompra?.find((x: any) => x.produto.aLink == this.itemAtual.aLink) }

        this.service.preencheCampos(this.prodAtu, this.camposForm)
      }
    }
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // //console.log(event.key, event.ctrlKey, event.shiftKey, environment.production)
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {

      this.teste.setValueForm(this.camposForm);

    }
  }

}
