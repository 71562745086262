<div class="container my-4">
  <mat-horizontal-stepper [linear]="true" #stepperPrincipal [selectedIndex]="selectedIndex" labelPosition="bottom">
    <form [formGroup]="formMediacao">
      <mat-step label="Solicitação">
        <span class="m-2 title-form">Informações Polo</span>
        <div class="content-list">
          <div class="row my-2 position-relative" [ngClass]="exibeObrigatoriedadeCampos1">
            <div class="col-md-12">
              <span class="m-2 label-form">Polo</span>
              <mat-radio-group formControlName="tipoPolo" (ngModelChange)="onChange($any($event), 1)">
                <mat-radio-button value="Solicitante" checked>Solicitante</mat-radio-button>
                <mat-radio-button value="Convidado">Convidado</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div formGroupName="infoPolo">
            <div class="row mt-3 my-2">
              <div class="col-md-6 position-relative" [ngClass]="exibeObrigatoriedadeCampos2">
                <span class="m-2 label-form">Tipo Pessoa</span>
                <mat-radio-group formControlName="tipoPessoa" (ngModelChange)="onChange($any($event), 2)">
                  <mat-radio-button value="Física" [checked]="true">Física</mat-radio-button>
                  <mat-radio-button value="Jurídica">Jurídica</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="col-md-6 position-relative" [ngClass]="exibeObrigatoriedadeCampos3" *ngIf="infoPolo?.get('tipoPessoa')?.value === 'Física' || infoPolo?.get('tipoPessoa')?.value === ''">
                <span class="m-2 label-form">Gênero</span>
                <mat-radio-group formControlName="genero" (ngModelChange)="onChange($any($event), 3)">
                  <mat-radio-button value="Masculino">Masculino</mat-radio-button>
                  <mat-radio-button value="Feminino">Feminino</mat-radio-button>
                  <mat-radio-button value="Não informar">Não informar</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="row mt-3 my-2">
              <div class="col-md-4">
                <ng-container *ngIf="infoPolo?.get('tipoPessoa')?.value === 'Física' || infoPolo?.get('tipoPessoa')?.value === ''">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>CPF</mat-label>
                    <input matInput mask="000.000.000-00" type="text" placeholder="CPF" formControlName="cnpjCpf">
                    <mat-error *ngIf="infoPolo.get('cnpjCpf')?.errors?.['required']">Preenchimento obrigatório</mat-error>
                    <mat-error *ngIf="infoPolo.get('cnpjCpf')?.errors?.['documentoNotValid']">Documento inválido</mat-error>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="infoPolo?.get('tipoPessoa')?.value === 'Jurídica'">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>CNPJ</mat-label>
                    <input matInput mask="00.000.000/0000-00" type="text" placeholder="CNPJ" formControlName="cnpjCpf">
                    <mat-error *ngIf="infoPolo.get('cnpjCpf')?.errors?.['required']">Preenchimento obrigatório</mat-error>
                    <mat-error *ngIf="infoPolo.get('cnpjCpf')?.errors?.['documentoNotValid']">Documento inválido</mat-error>
                  </mat-form-field>
                </ng-container>
              </div>
              <div class="col-md-4">
                <mat-form-field class="w-100" appearance="outline" *ngIf="infoPolo?.get('tipoPessoa')?.value === 'Física' || infoPolo?.get('tipoPessoa')?.value === ''">
                  <mat-label>Nome</mat-label>
                  <input matInput type="text" placeholder="Nome" formControlName="nome">
                  <mat-error>Preenchimento obrigatório</mat-error>
                </mat-form-field>
                <mat-form-field class="w-100" appearance="outline" *ngIf="infoPolo?.get('tipoPessoa')?.value === 'Jurídica'">
                  <mat-label>Razão Social</mat-label>
                  <input matInput type="text" placeholder="Razão Social" formControlName="razaoSocial">

                  <mat-error>Preenchimento obrigatório</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field class="w-100" appearance="outline" *ngIf="infoPolo?.get('tipoPessoa')?.value === 'Física' || infoPolo?.get('tipoPessoa')?.value === ''">
                  <mat-label>Nome Social</mat-label>
                  <input matInput type="text" placeholder="Nome Social" formControlName="nomeSocial">
                  <mat-error>Preenchimento obrigatório</mat-error>
                </mat-form-field>
                <mat-form-field class="w-100" appearance="outline" *ngIf="infoPolo?.get('tipoPessoa')?.value === 'Jurídica'">
                  <mat-label>Nome Fantasia</mat-label>
                  <input matInput type="text" placeholder="Nome Fantasia" formControlName="nomeFantasia">
                  <mat-error>Preenchimento obrigatório</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-3">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>CEP</mat-label>
                  <input matInput mask="00000-000" type="text" placeholder="CEP" formControlName="cep">
                  <mat-error>Preenchimento obrigatório</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="w-100">
                  <mat-label>Estado</mat-label>
                  <input type="text"
                         placeholder="Estado"
                         aria-label="Number"
                         matInput
                         formControlName="uf"
                         [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete"
                                    id="autoUf"
                                    [displayWith]="displayProperty"
                                    (optionSelected)="getCidade('infoPolo', $event)">
                    <mat-option *ngFor="let item of listufFilter" [value]="item">
                      {{ item.Nome }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error>
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="w-100">
                  <mat-label *ngIf="itemAtual?.titulo == 'Certidão de Busca'">Comarca</mat-label>
                  <mat-label *ngIf="itemAtual?.titulo != 'Certidão de Busca'">Cidade</mat-label>
                  <input type="text"
                         placeholder="Cidade"
                         aria-label="Number"
                         matInput [readonly]="edicao || iskit"
                         formControlName="municipio"
                         [matAutocomplete]="cidadePolo">
                  <mat-autocomplete #cidadePolo="matAutocomplete"
                                    id="autoCidadePolo"
                                    [displayWith]="displayPropertyCidade"
                                    (optionSelected)="setLocal()">
                    <mat-option *ngFor="let itemCidadePolo of listCidadeFilter; let i = index" [value]="itemCidadePolo">
                      {{ itemCidadePolo.Nome }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error>
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Logradouro</mat-label>
                  <input matInput type="text" placeholder="Logradouro" formControlName="logradouro">
                  <mat-error>
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Número</mat-label>
                  <input matInput type="text" maxlength="10" placeholder="Numero" formControlName="numero">
                  <mat-error>
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Complemento</mat-label>
                  <input matInput type="text" placeholder="Complemento" formControlName="complemento">
                  <mat-error>
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Bairro</mat-label>
                  <input matInput type="text" placeholder="Bairro" formControlName="bairro">
                  <mat-error>
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Telefone</mat-label>
                  <input matInput type="text" mask="(00) 00000-0000" placeholder="Telefone"
                         formControlName="telefone">
                  <mat-error>
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field class="w-100" appearance="outline">

                  <mat-label>E-mail</mat-label>
                  <input matInput type="email" placeholder="E-mail" formControlName="email">
                  <mat-error *ngIf="infoPolo.get('email')?.errors?.['required']">Preenchimento obrigatório</mat-error>
                  <mat-error *ngIf="infoPolo.get('email')?.errors?.['email']">
                    E-mail inválido
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4 position-relative" [ngClass]="exibeObrigatoriedadeCampos4">
                <span class="label-form">Possui advogados</span>
                <mat-radio-group formControlName="possuiAdvogado" (ngModelChange)="onChange($any($event), 4)">
                  <mat-radio-button value="Sim">Sim</mat-radio-button>
                  <mat-radio-button value="Não">Não</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <app-uploadFiles (setaArquivos)="setaArquivosPolo($event)" [tipo]="1"
                                 (resetaArquivos)="resetaArquivosPolo($event)" [filesAtu]="meet?.infoPolo?.arquivos"></app-uploadFiles>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <button type="button" (click)="cancelForm()" class="btnSubmit btn w-100 btn-primary btn-sm cancelButton">Cancelar</button>
          </div>
          <div class="col-3 offset-6">
            <button type="button" (click)="stepperNext()" class="btnSubmit btn w-100 btn-primary btn-sm">Próximo</button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Polo - Parte Contrária">
        <span class="m-2 title-form">Polo - Parte Contrária</span>
        <div class="content-list" formGroupName="poloContraria">
          <div class="row mt-3 my-2">
            <div class="col-md-6 position-relative" [ngClass]="exibeObrigatoriedadeCampos2">
              <span class="m-2 label-form">Tipo Pessoa</span>
              <mat-radio-group formControlName="tipoPessoa" (ngModelChange)="onChange($any($event), 2)">
                <mat-radio-button value="Física" [checked]="true">Física</mat-radio-button>
                <mat-radio-button value="Jurídica">Jurídica</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col-md-6 position-relative" [ngClass]="exibeObrigatoriedadeCampos3" *ngIf="poloContraria?.get('tipoPessoa')?.value === 'Física' || poloContraria?.get('tipoPessoa')?.value === ''">
              <span class="m-2 label-form">Gênero</span>
              <mat-radio-group formControlName="genero" (ngModelChange)="onChange($any($event), 3)">
                <mat-radio-button value="Masculino">Masculino</mat-radio-button>
                <mat-radio-button value="Feminino">Feminino</mat-radio-button>
                <mat-radio-button value="Não informar">Não informar</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row mt-3 my-2">
            <div class="col-md-4">
              <ng-container *ngIf="poloContraria?.get('tipoPessoa')?.value === 'Física' || poloContraria?.get('tipoPessoa')?.value === ''">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>CPF</mat-label>
                  <input matInput mask="000.000.000-00" type="text" placeholder="CPF" formControlName="cnpjCpf">
                  <mat-error *ngIf="poloContraria.get('cnpjCpf')?.errors?.['required']">Preenchimento obrigatório</mat-error>
                  <mat-error *ngIf="poloContraria.get('cnpjCpf')?.errors?.['documentoNotValid']">Documento inválido</mat-error>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="poloContraria?.get('tipoPessoa')?.value === 'Jurídica'">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>CNPJ</mat-label>
                  <input matInput mask="00.000.000/0000-00" type="text" placeholder="CNPJ" formControlName="cnpjCpf">
                  <mat-error *ngIf="poloContraria.get('cnpjCpf')?.errors?.['required']">Preenchimento obrigatório</mat-error>
                  <mat-error *ngIf="poloContraria.get('cnpjCpf')?.errors?.['documentoNotValid']">Documento inválido</mat-error>
                </mat-form-field>
              </ng-container>
            </div>
            <div class="col-md-4">
              <mat-form-field class="w-100" appearance="outline" *ngIf="poloContraria?.get('tipoPessoa')?.value === 'Física' || poloContraria?.get('tipoPessoa')?.value === ''">
                <mat-label>Nome</mat-label>
                <input matInput type="text" placeholder="Nome" formControlName="nome">
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
              <mat-form-field class="w-100" appearance="outline" *ngIf="poloContraria?.get('tipoPessoa')?.value === 'Jurídica'">
                <mat-label>Razão Social</mat-label>
                <input matInput type="text" placeholder="Nome" formControlName="razaoSocial">
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="w-100" appearance="outline" *ngIf="poloContraria?.get('tipoPessoa')?.value === 'Física' || poloContraria?.get('tipoPessoa')?.value === ''">
                <mat-label>Nome Social</mat-label>
                <input matInput type="text" placeholder="Nome Social" formControlName="nomeSocial">
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
              <mat-form-field class="w-100" appearance="outline" *ngIf="poloContraria?.get('tipoPessoa')?.value === 'Jurídica'">
                <mat-label>Nome Fantasia</mat-label>
                <input matInput type="text" placeholder="Nome Social" formControlName="nomeFantasia">
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row my-2">
            <div class="col-md-3">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>CEP</mat-label>
                <input matInput mask="00000-000" type="text" placeholder="CEP" formControlName="cep">
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field class="w-100">
                <mat-label>Estado</mat-label>
                <input type="text"
                       placeholder="Estado"
                       aria-label="Number"
                       matInput [readonly]="edicao || iskit"
                       formControlName="uf"
                       [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete"
                                  id="autoUf"
                                  [displayWith]="displayProperty"
                                  disabled (optionSelected)="getCidade('poloContraria', $event)">
                  <mat-option *ngFor="let item of listufFilter" [value]="item">
                    {{ item.Nome }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field class="w-100">
                <mat-label>Cidade</mat-label>
                <input type="text"
                       placeholder="Cidade"
                       aria-label="Number"
                       matInput [readonly]="edicao || iskit"
                       formControlName="municipio"
                       [matAutocomplete]="cidadePoloContraria">
                <mat-autocomplete #cidadePoloContraria="matAutocomplete"
                                  id="autoCidadePoloContraria"
                                  [displayWith]="displayPropertyCidade"
                                  (optionSelected)="setLocal()">
                  <mat-option *ngFor="let itemCidadePoloContraria of listCidadeFilter; let i = index" [value]="itemCidadePoloContraria">
                    {{ itemCidadePoloContraria.Nome }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Logradouro</mat-label>
                <input matInput type="text" placeholder="Logradouro" formControlName="logradouro">
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row my-2">
            <div class="col-md-4">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Número</mat-label>
                <input matInput type="text" maxlength="10" placeholder="Numero" formControlName="numero">
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Complemento</mat-label>
                <input matInput type="text" placeholder="Complemento" formControlName="complemento">
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Bairro</mat-label>
                <input matInput type="text" placeholder="Bairro" formControlName="bairro">
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row my-2">
            <div class="col-md-4">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Telefone</mat-label>
                <input matInput type="text" mask="(00) 00000-0000" placeholder="Telefone"
                       formControlName="telefone">
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>E-mail</mat-label>
                <input matInput type="email" placeholder="E-mail" formControlName="email">
                <mat-error *ngIf="poloContraria.get('email')?.errors?.['required']">Preenchimento obrigatório</mat-error>
                <mat-error *ngIf="poloContraria.get('email')?.errors?.['email']">
                  E-mail inválido
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4 position-relative" [ngClass]="exibeObrigatoriedadeCampos4">
              <span class="label-form">Possui advogado</span>
              <mat-radio-group formControlName="possuiAdvogado" (ngModelChange)="onChange($any($event), 4)">
                <mat-radio-button value="Sim">Sim</mat-radio-button>
                <mat-radio-button value="Não">Não</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <app-uploadFiles (setaArquivos)="setaArquivosPolo($event)" [tipo]="2"
                               (resetaArquivos)="resetaArquivosPolo($event)" [filesAtu]="meet?.poloContraria?.arquivos"></app-uploadFiles>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <button type="button" (click)="cancelForm()" class="btnSubmit btn w-100 btn-primary btn-sm cancelButton">Cancelar</button>
          </div>
          <div class="col-3 offset-3">
            <button type="button" (click)="stepperPrev()" class="btnSubmit btn w-100 btn-primary btn-sm">Voltar</button>
          </div>
          <div class="col-3">
            <button type="button" (click)="stepperNext()" class="btnSubmit btn w-100 btn-primary btn-sm">Próximo</button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Informações Adicionais">
        <span class="m-2 title-form">Informações Adicionais</span>
        <div class="content-list" formGroupName="infoAdicionais">
          <div class="row my-2">
            <div class="col-md-6">
              <span class="m-2 label-form">Competência</span>
              <mat-radio-group formControlName="competencia">
                <mat-radio-button value="Cível">Cível</mat-radio-button>
                <mat-radio-button value="Família">Família</mat-radio-button>
                <mat-radio-button value="Tributário">Tributário</mat-radio-button>
                <mat-radio-button value="Trabalhista">Trabalhista</mat-radio-button>
                <mat-radio-button value="Outros">Outros</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col-md-6">
              <mat-form-field class="w-100">
                <mat-label>Relato dos fatos</mat-label>
                <textarea matInput placeholder="Relato dos fatos" maxlength="1450"
                          formControlName="relatoFatos"></textarea>
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row mt-3 my-2">
            <div class="col-md-6">
              <mat-form-field class="w-100">
                <mat-label>Pedido sucinto</mat-label>
                <textarea matInput placeholder="Pedido sucinto" maxlength="450"
                          formControlName="pedidoSucinto"></textarea>
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Valor Sugerido</mat-label>
                <input matInput type="text" currencyMask
                       [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" placeholder="Valor Sugerido"
                       formControlName="valorSugerido">
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div [ngClass]="exibeObrigatoriedadeCampos" class="my-4 position-relative">
          <div class="row">
            <div class="col-md-12">
              <mat-checkbox formControlName="cienteInstrucoes">
                <small>Estou ciente das instruções</small>
              </mat-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-checkbox formControlName="cienteProcessos">
                <small>Declaro que não há processo judicial sobre esta reclamação</small>
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-3 col-xl-3">
            <button type="button" (click)="cancelForm()" class="btnSubmit btn w-100 btn-primary btn-sm cancelButton">Cancelar</button>
          </div>
          <div class="col-md-3 col-lg-3 col-xl-3 offset-3"><button type="button" (click)="stepperPrev()" class="btnSubmit btn w-100 btn-primary btn-sm">Voltar</button></div>
          <div class="col-3 col-lg-3 col-xl-3"><button type="button" (click)="salvar()" class="btnSubmit btn w-100 btn-primary btn-sm">Salvar</button></div>
        </div>
      </mat-step>
    </form>
    <mat-step label="Pagamento">
      <ng-template matStepLabel>Pedido concluído</ng-template>
      <app-payment [origem]="'meet'" *ngIf="selectedIndex==3"></app-payment>
    </mat-step>
  </mat-horizontal-stepper>
</div>
