<div class="wrapper-item" *ngIf="!compra?.editar">

  <div class="row">
    <div class="wrapper-info col-lg-3">
      <h3 class="info-section"><b>Requerente</b></h3>
      <p class="info-section">{{compra.nomeRequerente}}</p>
    </div>
    <div class="wrapper-info col-lg-3">
      <h3 class="info-section"><b>Distrito</b></h3>
      <p class="info-section">{{compra.localizacao.distrito.descricao}}</p>
    </div>
    <div class="wrapper-info col-lg-3">
      <h3 class="info-section"><b>Concelho</b></h3>
      <p class="info-section">{{compra.localizacao.concelho.descricao}}</p>
    </div>
    <div class="wrapper-info col-lg-3">
      <h3 class="info-section"><b>Freguesia</b></h3>
      <p class="info-section">{{compra.localizacao.freguesia.descricao}}</p>
    </div>
  </div>
  <h2 class="title-info-solicitante">Descrição em Ficha ou Informatizado</h2>
  <div class="row">
    <div class="wrapper-info col-lg-3">
      <h3 class="info-section"><b>Nº da Ficha</b></h3>
      <p class="info-section">{{compra.dadosFicha.numero}}</p>
    </div>
    <div class="wrapper-info col-lg-3">
      <h3 class="info-section"><b>Fração Autónoma</b></h3>
      <p class="info-section">{{compra.dadosFicha.fracaoAutonoma}}</p>
    </div>
    <div class="wrapper-info col-lg-3">
      <h3 class="info-section"><b>Fração Temporal</b></h3>
      <p class="info-section">{{compra.dadosFicha.fracaoTemporal}}</p>
    </div>
  </div>
  <h2 class="title-info-solicitante">Descrição em livro</h2>
  <div class="row">
    <div class="wrapper-info col-lg-3">
      <h3 class="info-section"><b>Nº de Descrição</b></h3>
      <p class="info-section">{{compra.dadosLivro.descricao}}</p>
    </div>
    <div class="wrapper-info col-lg-3">
      <h3 class="info-section"><b>Livro</b></h3>
      <p class="info-section">{{compra.dadosLivro.livro}}</p>
    </div>
    <div class="wrapper-info col-lg-3">
      <h3 class="info-section"><b>Folhas</b></h3>
      <p class="info-section">{{compra.dadosLivro.folhas}}</p>
    </div>
    <div class="wrapper-info col-lg-3">
      <h3 class="info-section"><b>Fração Autónoma</b></h3>
      <p class="info-section">{{compra.dadosLivro.fracaoAutonoma}}</p>
    </div>
    <div class="wrapper-info col-lg-3">
      <h3 class="info-section"><b>Fração Temporal</b></h3>
      <p class="info-section">{{compra.dadosLivro.fracaoTemporal}}</p>
    </div>
  </div>
  <h2 class="title-info-solicitante">Informação Matricial</h2>
  <div class="row">
    <div class="wrapper-info col-lg-3">
      <h3 class="info-section"><b>Natureza</b></h3>
      <p class="info-section">{{compra.dadosMatricial.natureza}}</p>
    </div>
    <div class="wrapper-info col-lg-3">
      <h3 class="info-section"><b>Número</b></h3>
      <p class="info-section">{{compra.dadosMatricial.numero}}</p>
    </div>
    <div class="wrapper-info col-lg-3">
      <h3 class="info-section"><b>Fração</b></h3>
      <p class="info-section">{{compra.dadosMatricial.fracao}}</p>
    </div>
    <div class="wrapper-info col-lg-3">
      <h3 class="info-section"><b>Secção</b></h3>
      <p class="info-section">{{compra.dadosMatricial.seccao}}</p>
    </div>
    <div class="wrapper-info col-lg-3">
      <h3 class="info-section"><b>Árvore</b></h3>
      <p class="info-section">{{compra.dadosMatricial.arvores}}</p>
    </div>

    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>CERP</b></h3>
      <p class="info-section">{{compra?.idItem}}</p>
    </div>
  </div>
</div>

<app-form-10 *ngIf="compra?.editar" [itemEdicao]="compra" [edicao]="true" (cancelarEdicao)="cancelarEdicao(compra)"
  (salvarItem)="salvarItem($event)" [itemAtual]="compra.produto">
</app-form-10>
