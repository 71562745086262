<div class="wrapper-item" *ngIf="!compra?.editar">
  <div class="wrapper-header-item">

    <mat-divider class="w-100"></mat-divider>

    <div class="wrapper-info-cartorio">

      <ng-container>
        <ng-container *ngFor="let result of itemDetalhes.campos">
          <div class="content-info-solicitante" *ngIf="result.tipo!='breakLine'">
            <div class="row">
              <div class="wrapper-info" [class]="'col-lg-'+result.largura">
                <h3 class="info-section"><b>{{result.label}}</b></h3>
                <p class="info-section" *ngIf="result.tipo=='cpfCnpj' || result.tipo=='cpf' || result.tipo== 'cnpj'">
                  {{compra[result.prop] | mask:'000.000.000-00||00.000.000/0000-00'}}
                </p>
                <p class="info-section"
                   *ngIf="result.tipo!='cpfCnpj' && result.tipo!='cpf'
                   &&  result.tipo!='data'&&  result.tipo!='cnpj' &&  result.tipo!='uf'">
                  {{compra[result.prop]}}
                </p>
                <p class="info-section"
                   *ngIf="result.tipo =='uf'">
                  {{compra[result.prop].Nome}}
                </p>
                <p class="info-section" *ngIf="result.tipo=='data'">{{compra[result.prop] | date:'dd/MM/yyyy':'UTC'}}</p>
              </div>


            </div>
          </div>
        </ng-container>
        <div class="row">
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>CERP</b></h3>
            <p class="info-section">{{compra?.idItem}}</p>
          </div>
        </div>

      </ng-container>
    </div>
  </div>
</div>

<app-form-15 *ngIf="compra?.editar" [itemEdicao]="compra" [edicao]="true" (cancelarEdicao)="cancelarEdicao(compra)"
  (salvarItem)="salvarItem($event)" [itemAtual]="compra.produto"></app-form-15>
