import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LocalStorage } from "src/app/_entidade/LocalStorage";
import { CrudService } from "src/app/_sevice/CrudService";
import { ActivatedRoute, Router } from "@angular/router";
import { map, Observable, of, switchMap, tap, throwError } from "rxjs";
import { environment } from 'src/environments/environment';
import { MatCheckboxChange } from "@angular/material/checkbox";
import { forEach } from "lodash";

@Component({
  selector: 'app-cadastro-agendamento-zoom',
  templateUrl: './cadastro-agendamento-zoom.component.html',
  styleUrls: ['./cadastro-agendamento-zoom.component.less']
})
export class CadastroAgendamentoZoomComponent implements OnInit {
  formAgendamento: FormGroup;
  dataAtual = new Date();
  meetingAccess: any;
  meetingInfoAtual: any;
  statusMeeting: any;
  dadoSubmit = false;

  constructor(private fb: FormBuilder, private st: LocalStorage, private service: CrudService, private router: ActivatedRoute, private route: Router) { }

  ngOnInit(): void {
    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.carregarForm();
    this.statusMeeting = JSON.parse(this.router.snapshot.params['status']);

    this.service.getObservable(`MeetingAccess/${this.router.snapshot.params['meetingGuid']}`).subscribe({
      next: (response: any) => {
        if (response) {
          this.meetingAccess = response;

          this.loadMeetingInfoAtual();
          this.loadParticipantes(this.meetingInfoAtual.participantes);
        }
      },
      error: (error: any) => {
        console.log(error);
      },
      complete: () => (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%"
    });
  }

  get anfitrioes(): FormArray {
    return this.formAgendamento.get('anfitrioes') as FormArray;
  }

  get mediadores(): FormArray {
    return this.formAgendamento.get('mediadores') as FormArray;
  }

  get participantes(): FormArray {
    return this.formAgendamento.get('participantes') as FormArray;
  }

  async addMediadorLoop(qtde: any): Promise<Observable<any>> {

    return new Promise<Observable<any>>((resolve) => {
      for (var i = 0; i < qtde; i++) {
        this.addMediador();
      }
      resolve(of(true));
    })
  }

  async copiarDadosAnfitriao(event: MatCheckboxChange) {
    if (event.checked) {
      let tm = this.anfitrioes.controls.length
      let tmMe = this.mediadores.controls.length
      let diff = tm - tmMe 
      await (await this.addMediadorLoop(diff)).subscribe((resultado) => {
        console.log('sdfg')

        for (var i = 0; i < tm; i++) {


          this.mediadores.controls[i].get('guid')?.setValue(this.anfitrioes.controls[i].get('guid')?.value);
          this.mediadores.controls[i].get('cnpjCpf')?.setValue(this.anfitrioes.controls[i].get('cnpjCpf')?.value);
          this.mediadores.controls[i].get('nome')?.setValue(this.anfitrioes.controls[i].get('nome')?.value);
          this.mediadores.controls[i].get('email')?.setValue(this.anfitrioes.controls[i].get('email')?.value);
          this.mediadores.controls[i].get('telefone')?.setValue(this.anfitrioes.controls[i].get('telefone')?.value);

        }

      })

      
      // Se o checkbox está marcado, copia os valores


      //this.dadosCobranca.get('nome')?.setValue(nome);
      //this.dadosCobranca.get('cnpjCpf')?.setValue(cnpjCpf);
    } else {
      // Se o checkbox está desmarcado, limpa os valores
      // this.dadosCobranca.get('nome')?.setValue('');
      // this.dadosCobranca.get('cnpjCpf')?.setValue('');
    }
  }

  carregarForm() {
    const usuarioLogado = this.st.localStorageGet('userlogged');
    const anfitriaoDefault: any = {
      userGuid: usuarioLogado?.keyLogin,
      nome: usuarioLogado?.nomeUsuario,
      email: usuarioLogado?.email,
      tipoPolo: null,
      cnpjCpf: usuarioLogado?.cpfCnpj,
      endereco: null,
      genero: null,
      nomeSocial: null,
      possuiAdvogado: null,
      telefone: usuarioLogado?.telefone,
      tipoPessoa: null
    };

    this.formAgendamento = this.fb.group({
      titulo: ['', Validators.required],
      dataReuniao: ['', Validators.required],
      timeInicial: ['', Validators.required],
      timeFinal: ['', Validators.required],
      anfitrioes: this.fb.array([this.createUser(anfitriaoDefault)], Validators.required),
      mediadores: this.fb.array([this.createUser()], Validators.required),
      participantes: this.fb.array([this.createUser()], Validators.required)
    });
  }
  buscarKey(event: any, i: any, tipo: any) {

    if (event.target.value != event.target.oldvalue) {
      let url = environment.http.API_AUTH + 'v1/account/usuario/buscarkey/' + event.target.value
      this.service.getObservableDirect(url).subscribe({
        next: async (response) => {
          if (response != null) {
            if (response.key) {
              if (tipo == 'anfitrioes')
                this.anfitrioes.controls[i].get('guid')?.setValue(response.key)
              else if (tipo == 'mediadores')
                this.mediadores.controls[i].get('guid')?.setValue(response.key)
              else if (tipo == 'participantes')
                this.participantes.controls[i].get('guid')?.setValue(response.key)
            }
          }
          console.log(this.formAgendamento.value)

        }, error: (err) => {
          console.error(err);
        }
      });


    }

  }
  setOldValue(event: any) {
    event.target.oldvalue = event.target.value
  }
  loadMeetingInfoAtual() {
    if (this.meetingAccess.status.slug == 'pagamento-audiencia-realizado') {
      this.meetingInfoAtual = this.meetingAccess?.meetingInfo[0];

    } else {
      const newMeetingInfo = { ...this.meetingAccess.meetingInfo[this.meetingAccess?.meetingInfo.length - 1] };
      this.meetingAccess.meetingInfo.push(newMeetingInfo);

      this.meetingInfoAtual = this.meetingAccess.meetingInfo[this.meetingAccess?.meetingInfo.length - 1];
      this.meetingInfoAtual.meetingGuid = crypto.randomUUID();
    }

    this.meetingInfoAtual.tipo = this.loadMeetingInfoTipo();
    this.meetingInfoAtual.meetFinalizada = false;
    this.meetingInfoAtual.ataEnviada = false;
  }

  loadMeetingInfoTipo(): any {
    let resultTipo: any;

    switch (this.statusMeeting.slug) {
      case "aguardando-audiencia-inicial":
        resultTipo = { slug: 'audiencia-inicial', descricao: 'Audiência Inicial' };
        break;

      case "aguardando-audiencia-instruçao":
        resultTipo = { slug: 'audiencia-instruçao', descricao: 'Audiência Instrução' };
        break;

      case "aguardando-audiencia-julgamento":
        resultTipo = { slug: 'audiencia-julgamento', descricao: 'Audiência Julgamento' };
        break;
    }

    return resultTipo;
  }

  loadParticipantes(participantes: any[]) {
    this.participantes.clear();

    participantes?.forEach((participante: any) =>
      this.addParticipante(participante));
  }

  createUser(user: any | null = null): FormGroup {
    return this.fb.group({
      guid: [(user?.userGuid ?? crypto.randomUUID())],
      cnpjCpf: [(user?.cnpjCpf ?? ''), Validators.required],
      nome: [(user?.nome ?? ''), Validators.required],
      email: [(user?.email ?? ''), Validators.required],
      telefone: [(user?.telefone ?? ''), Validators.required],
    });
  }

  remAnfitriao(i: number) {
    this.anfitrioes.removeAt(i)
  }

  remMediador(i: number) {
    this.mediadores.removeAt(i)
  }

  addMediador() {
     

    this.mediadores.push(this.createUser());
  }

  addAnfitriao() {
    
    this.anfitrioes.push(this.createUser());
  }

  remParticipante(i: number) {
    this.participantes.removeAt(i)
  }

  addParticipante(participante: any | null = null) {
    if (this.participantes.invalid && participante == null) {
      this.participantes.at(this.participantes.length - 1).markAllAsTouched();
      return;
    }

    this.participantes.push(this.createUser(participante));
  }

  enviar() {
    if (this.formAgendamento.invalid)
      return;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    const objForm = this.formAgendamento.value;

    const timeInicioSplit = objForm.timeInicial.split(':');
    const timeFinalSplit = objForm.timeFinal.split(':');

    const dataInicio = this.transformAgendaDate(objForm.dataReuniao, timeInicioSplit[0], timeInicioSplit[1]);
    const dataFinal = this.transformAgendaDate(objForm.dataReuniao, timeFinalSplit[0], timeFinalSplit[1]);

    this.atualizarObjMeetingAccess(objForm, dataInicio, dataFinal);
    const use = this.st.localStorageGet('userlogged');

    let resultTipo=''
    switch (this.statusMeeting.slug) {
      case "aguardando-audiencia-inicial":
        resultTipo = 'Audiência Inicial' ;
        break;

      case "aguardando-audiencia-instruçao":
        resultTipo ='Audiência Instrução' 
        break;

      case "aguardando-audiencia-julgamento":
        resultTipo = 'Audiência Julgamento' 
        break;
    }

    if (this.meetingAccess['historico']) {
      this.meetingAccess['historico'].push({ acao: 'agendamento da ' + resultTipo , usuario: use?.nomeUsuario, guidUser: use?.keyLogin, data: new Date() })

    } else {
      this.meetingAccess['historico'] = [{ acao: 'agendamento da ' + resultTipo , usuario: use?.nomeUsuario, guidUser: use?.keyLogin, data: new Date() }]

    }

    this.service.putObservable('MeetingAccess', this.meetingAccess)
      .pipe(
        tap(() => this.dadoSubmit = true),
        switchMap(() => this.enviarEmailMeeting())
      )
      .subscribe({
        next: () => this.route.navigate(['/fadmin/meus-agendamentos']),
        error: () => {
          if (this.dadoSubmit)
            this.route.navigate(['/fadmin/meus-agendamentos'])
        },
        complete: () => (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%"
      });
  }

  atualizarObjMeetingAccess(objForm: any, dtInicio: Date, dtFinal: Date) {
    this.meetingInfoAtual.tituloMeeting = objForm.titulo;
    this.meetingInfoAtual.agendaDataInicio = dtInicio;
    this.meetingInfoAtual.agendaDataFinal = dtFinal;
    this.meetingAccess.agendamentoConcluido = true;

    this.meetingAccess.status = this.statusMeeting;
    this.meetingInfoAtual.statusMeeting = this.statusMeeting;

    this.atualizarAnfitrioesMeetingAccess();
    this.atualizarParticipantesMeetingAccess();
    this.atualizarMediadoresMeetingAccess();
  }

  enviarEmailMeeting(): Observable<any> {
    const emailsAnfitrao = this.loadUsersEnvioEmail(this.meetingInfoAtual.meetingGuid, this.anfitrioes);
    const emailsParticipantes = this.loadUsersEnvioEmail(this.meetingInfoAtual.meetingGuid, this.participantes);

    const payloadEmail = {
      usersEmail: [
        ...emailsAnfitrao,
        ...emailsParticipantes
      ],
      assunto: `Mediação - ${this.meetingInfoAtual.tituloMeeting}`,
      template: 'meeting_geral'
    };

    const smsAnfitrao = this.loadUsersEnvioSms(this.meetingInfoAtual.meetingGuid, this.anfitrioes);
    const smsParticipantes = this.loadUsersEnvioSms(this.meetingInfoAtual.meetingGuid, this.participantes);

    const payloadSms = {
      usersSms: [
        ...smsAnfitrao,
        ...smsParticipantes
      ],
      template: 'meeting_geral'
    };

    let envio = { emailRequest: payloadEmail, smsRequest: payloadSms }

    return this.service.postObservable(envio, 'Notificacao/EnviarEmailSmsLote');
  }

  enviarSmsMeeting(): Observable<any> {
    const smsAnfitrao = this.loadUsersEnvioSms(this.meetingInfoAtual.meetingGuid, this.anfitrioes);
    const smsParticipantes = this.loadUsersEnvioSms(this.meetingInfoAtual.meetingGuid, this.participantes);

    const payloadSms = {
      usersSms: [
        ...smsAnfitrao,
        ...smsParticipantes
      ],
      template: 'meeting_geral'
    };

    return this.service.postObservable(payloadSms, 'Notificacao/EnviarSmsLote');
  }

  loadUsersEnvioEmail(meetingGuid: any, users: FormArray): any[] {
    return users.controls.map((controlUser: any) => {
      return {
        nome: controlUser.get('nome')?.value,
        email: controlUser.get('email')?.value,
        parametrosReplace: {
          user_name: controlUser.get('nome')?.value,
          data_meeting: `${this.meetingInfoAtual.agendaDataInicio.toLocaleDateString("pt-br")} ${this.meetingInfoAtual.agendaDataInicio.toLocaleTimeString("pt-br")}`,
          link: `${location.origin}/meeting/video-mediacao/${meetingGuid}/${controlUser.get('guid')?.value}`
        }
      };
    });
  }

  loadUsersEnvioSms(meetingGuid: any, users: FormArray): any[] {
    return users.controls.map((controlUser: any) => {
      return {
        telefone: controlUser.get('telefone')?.value,
        parametrosReplace: {
          link: `${location.origin}/meeting/video-mediacao/${meetingGuid}/${controlUser.get('guid')?.value}`
        }
      };
    });
  }

  atualizarMediadoresMeetingAccess() {
    this.meetingInfoAtual.mediadores = [];

    for (let i = 0; i < this.mediadores.length; i++) {
      const objPartic: any = {
        userGuid: this.mediadores.at(i).value.guid,
        nome: this.mediadores.at(i).value.nome,
        email: this.mediadores.at(i).value.email,
        cnpjCpf: this.mediadores.at(i).value.cnpjCpf,
        endereco: null,
        genero: null,
        nomeSocial: null,
        possuiAdvogado: null,
        telefone: this.mediadores.at(i).value.telefone,
        tipoPessoa: null,
        tipoPolo: null
      };

      this.meetingInfoAtual?.mediadores?.push(objPartic);
    }
  }

  atualizarAnfitrioesMeetingAccess() {
    this.meetingInfoAtual.anfitrioes = [];

    for (let i = 0; i < this.anfitrioes.length; i++) {
      const objPartic: any = {
        userGuid: this.anfitrioes.at(i).value.guid,
        nome: this.anfitrioes.at(i).value.nome,
        email: this.anfitrioes.at(i).value.email,
        cnpjCpf: this.anfitrioes.at(i).value.cnpjCpf,
        endereco: null,
        genero: null,
        nomeSocial: null,
        possuiAdvogado: null,
        telefone: this.anfitrioes.at(i).value.telefone,
        tipoPessoa: null,
        tipoPolo: null
      };

      this.meetingInfoAtual?.anfitrioes?.push(objPartic);
    }
  }

  atualizarParticipantesMeetingAccess() {
    this.meetingInfoAtual.convidados = []
    for (let i = 0; i < this.participantes.length; i++) {

      const objPartic: any = {
        userGuid: this.participantes.at(i).value.guid,
        nome: this.participantes.at(i).value.nome,
        email: this.participantes.at(i).value.email,
        cnpjCpf: this.participantes.at(i).value.cnpjCpf,
        endereco: null,
        genero: null,
        nomeSocial: null,
        possuiAdvogado: null,
        telefone: this.participantes.at(i).value.telefone,
        tipoPessoa: null,
        tipoPolo: null
      }

      this.meetingInfoAtual.convidados.push(objPartic);

    }
  }

  transformAgendaDate(data: Date, hora: number, minuto: number): Date {
    const dataResult = new Date(data);
    dataResult.setHours(hora, minuto);

    return dataResult;
  }
}
