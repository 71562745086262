 
<footer>
	<div class="content-footer-all container">
		<div class="content-footer-top d-none d-lg-block" *ngIf="conteudo.temDestaque" [ngClass]="conteudo.temDestaque == true ? 'bannerFooter' : ''">
			<img loading="lazy" src="/assets/img-footer-enegocie.png" alt="imagem footer enegocie">
			<div class="content-text-info">
				<h2>{{conteudo.textoDestaque}}</h2>
				<a [href]="urlCadastro">	<button>{{conteudo.txtBtnDestaque}}</button></a>
			</div>
		</div>
		<div class="content-footer-bottom">
      <div class="row">
        <div class="d-flex content-logo-footer col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
					<div>
            <!--                  [src]="conteudo?.logo" -->
            <img loading="lazy" id="logo"
                 alt="logo Etechss"

                 src="../../../../assets/logo-ETHOS.png"
                 style="height: 15vh;"
                 >
          </div>
          <div class="px-2 py-3 lh-base">
            <h4 class="d-flex w-75">
              <a>
                <h4>Desenvolvido por ETHOS & Logos Tecnologia e serviços</h4>
              </a>
            </h4>
          </div>
        </div>
        <div class="col-8">&nbsp;</div>
      </div>
			<div class="content-footer-copy row">
				<div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
					<span>© 2024</span>
					|
					<span>
						Todos os direitos reservados
					</span>
				</div>
			</div>
		</div>
	</div>
</footer>
