import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Chart, registerables } from 'chart.js'; 
import { LocalStorage } from '../../_entidade/LocalStorage';
import { CrudService } from '../../_sevice/CrudService';
Chart.register(...registerables);

@Component({
  selector: 'app-grafico',
  templateUrl: './grafico.component.html',
  styleUrls: ['./grafico.component.less']
})


export class GraficoComponent implements OnInit {
 
  options: Array<any> = new Array()
  selectedSistema: any = { IdBase: 0, DescBase: "Escolha" }
  myControl = new FormControl(this.selectedSistema);
  graficos: any = [{ id: 'horizontal' }, { id: 'linha' }]
  constructor( 
      public storage: LocalStorage
    , public service: CrudService) {
  }


  ngOnInit() {
    this.litarBase()   
  }

  getOptionText(option: any) {
    return option.DescBase;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedSistema = this.options.filter(x => x.IdBase == event.option.value.IdBase)[0]
    this.montarGraficoVenda()
  }

  litarBase() {
    this.service.getObservable('/base').subscribe({
      next: (retorno) => {
        this.options = retorno
        this.montarGraficoVenda() 
      }
    })
  }

  montarGraficoVenda() {
    var vlrs:any = [];
    var lbls: any = [];
    var total: any=0
    this.service.getObservable('Relatorio/GetListSlug/venda/' + this.selectedSistema.IdBase).subscribe({
      next: (retorno) => {
         
        for (var i = 0; i < retorno.length; i++) {
          vlrs.push(retorno[i].valor)
          lbls.push(retorno[i].coluna)
          total += retorno[i].valor
        }
        
        var chartExist = Chart.getChart("myChart"); // <canvas> id
        if (chartExist != undefined)
          chartExist.destroy(); 


        new Chart('horizontal', {
          type: 'bar',
          data: {
            labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
            datasets: [
              {
                label: '# of Votes',
                data: [12, 19, 3, 5, 2, 3],
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      

        new Chart("linha", {
          type: 'line',
          data: {
            labels: lbls,
            datasets: [{
              label: 'Vendas (Total:' + total +')',
              data: vlrs,
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)'
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)'
              ],
              borderWidth: 1
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
      }
    })
  }

}
