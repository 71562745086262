import { AfterViewInit, Component, HostListener, OnInit, Output, EventEmitter, Input, SimpleChanges, ChangeDetectorRef, OnChanges, ViewChild, ElementRef } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl, FormGroup, Validators
} from '@angular/forms';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
import { MatDialog } from '@angular/material/dialog';
import { ImageViewerComponent } from '../../imageViewer/imageViewer.component';
import { localidade } from 'src/app/_configPage/listas/localidade';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-form-7',
  templateUrl: './form7.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class Form7Component implements OnInit, AfterViewInit, OnChanges {

  @Input() uf: any;
  @Input() cidade: any;
  @Input() itemAtual: any;
  @Input() itemEdicao: any;
  @Input() classObrigatoria: any;
  @Input() cartorioEmissor: any[];
  @Input() multiplo: any;
  @Input() edicao: any = false;
  @Output() salvarItem = new EventEmitter<any>();
  @Output() setTemCoberturaFilho = new EventEmitter<any>();
  @Output() setCartorios = new EventEmitter<any>();
  @Output() cancelarEdicao = new EventEmitter<any>();

  load: boolean = false
  temCobertura: boolean = false
  classObrigatoriaAviso: any;
  classObrigatoriaMatricula: any;

  valorApostilamento: any = 0;

  apostilamento: any = "false"

  constructor(
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public teste: TesteService,
    public dialog: MatDialog,
    public service: CrudService,
    private routeAc: ActivatedRoute,
    private cdRef: ChangeDetectorRef
  ) {

  }

  listuf: any[] = localidade.listuf
  listufFilter: any = this.listuf
  listCidade: any[] = localidade.listCidade
  listCidadeFilter: any = this.listCidade
  listCartorios: any[];
  ufSelected = this.storage.localStorageGet('localidade')?.uf || { "Id": "65b11f81c0095aa0cc393545", "IdEstado": 19, "Sigla": "RJ", "Nome": "Rio de Janeiro" }
  cidadeSelected = this.storage.localStorageGet('localidade')?.cidade || { "Id": "65b11fe7c0095aa0cc394397", "IdCidade": "3658", "IdEstado": "19", "Nome": "Rio de Janeiro" }
  @ViewChild('cidade') fieldName: ElementRef;
  public listEndereco: any;

  public visibilidadeDetalhes: boolean[] = [];

  paises: any = [{ "Id": '23', "Descricao": "BRASIL" }]

  localidade = this.formBuilder.group({
    uf: [this.storage.localStorageGet('localidade')?.uf || this.ufSelected, Validators.required],
    cidade: [this.storage.localStorageGet('localidade')?.cidade || this.cidadeSelected, Validators.required],
  });

  camposForm = this.formBuilder.group({
    cartorio: ['', Validators.required],
    aceites: [''],
    apostilamento: [''],
    certidaoNascimento: this.formBuilder.array([this.createNacimento()]),
    certidaoCasamento: this.formBuilder.array([this.createCasamento()]),
    certidaoObito: this.formBuilder.array([this.createObito()]),
    dadosEntrega: this.formBuilder.group({
      nomeDestinatario: ['', Validators.required],
      pais: ['', Validators.required],
      cep: ['', Validators.required],
      endereco: ['', Validators.required],
      numero: ['', Validators.required],
      bairro: ['', Validators.required],
      cidade: ['', Validators.required],
      uf: ['', Validators.required],
      complemento: [''],
    }),
    valorCertidao: [''],

  });

  ngOnInit(): void {
    this.dadosEntrega.get('pais')?.setValue('Brasil');
    this.carrregarEdicao();
  }

  validarLivroFolhaTermoCasamento() {
    const detalhesArray = this.camposForm.get('certidaoCasamento') as FormArray;
    const detalhe = detalhesArray.at(0);

    const matriculaControl = detalhe.get('matricula');
    const livroControl = detalhe.get('livro');
    const folhaControl = detalhe.get('folha');
    const termoControl = detalhe.get('termo');

    const isMatriculaFilled = matriculaControl && matriculaControl.value !== '';

    const areLivroFolhaTermoFilled = livroControl && livroControl.value !== '' &&
      folhaControl && folhaControl.value !== '' &&
      termoControl && termoControl.value !== '';

    if (!isMatriculaFilled && (!areLivroFolhaTermoFilled)) {
      this.classObrigatoriaMatricula = 'redClass';
    } else {
      this.classObrigatoriaMatricula = '';
    }

    if (matriculaControl && matriculaControl.value === '') {
      livroControl?.setValidators(Validators.required);
      folhaControl?.setValidators(Validators.required);
      termoControl?.setValidators(Validators.required);
    } else {
      livroControl?.clearValidators();
      folhaControl?.clearValidators();
      termoControl?.clearValidators();
    }

    livroControl?.updateValueAndValidity();
    folhaControl?.updateValueAndValidity();
    termoControl?.updateValueAndValidity();
  }

  validarLivroFolhaTermoNascimento() {
    const detalhesArray = this.camposForm.get('certidaoNascimento') as FormArray;
    const detalhe = detalhesArray.at(0);

    const matriculaControl = detalhe.get('matricula');
    const livroControl = detalhe.get('livro');
    const folhaControl = detalhe.get('folha');
    const termoControl = detalhe.get('termo');

    const isMatriculaFilled = matriculaControl && matriculaControl.value !== '';

    const areLivroFolhaTermoFilled = livroControl && livroControl.value !== '' &&
      folhaControl && folhaControl.value !== '' &&
      termoControl && termoControl.value !== '';

    if (!isMatriculaFilled && (!areLivroFolhaTermoFilled)) {
      this.classObrigatoriaMatricula = 'redClass';
    } else {
      this.classObrigatoriaMatricula = '';
    }

    if (matriculaControl && matriculaControl.value === '') {
      livroControl?.setValidators(Validators.required);
      folhaControl?.setValidators(Validators.required);
      termoControl?.setValidators(Validators.required);
    } else {
      livroControl?.clearValidators();
      folhaControl?.clearValidators();
      termoControl?.clearValidators();
    }

    livroControl?.updateValueAndValidity();
    folhaControl?.updateValueAndValidity();
    termoControl?.updateValueAndValidity();
  }

  buscarValor() {
    let cartorioFoiSelecionado = this.camposForm.controls['cartorio']?.value

    if (!cartorioFoiSelecionado) {

      this.camposForm.controls['cartorio'].setErrors({ required: true })
      this.camposForm.controls.cartorio.markAsTouched();

    }
    else {

      if (this.apostilamento == "false")
      {

        this.valorApostilamento = 0

        delete this.cartorioSelecionado['valoresApostilamento'];

        if (this.cartorioSelecionado['valorEmolumento'])
          this.cartorioSelecionado.valor = this.cartorioSelecionado['valorEmolumento'];

        //Splice para remover o valor
        let arrTipovlor = this.itemAtual.composicaoValor.filter((x: any) => !x.idTipoValor)
        this.itemAtual.composicaoValor = arrTipovlor

        // Armazenando o item_atual
        this.storage.localStorageSet('item_atual', this.itemAtual);

        this.atualizarValorCartorio();
      }
      else {
        /*buscar o valor de apostilamento de acordo com o Estado.*/
        let url = environment.http.API_BACK + 'Consulta/ServicosExtraRCPN?uf=' + this.uf.Sigla;

        this.service.getObservableDirect(url).subscribe({
          next: async (response) => {

            if ((response != null) || (response != '[]')) {

              this.valorApostilamento = response.reduce((acc: any, val: any) => acc += val.valor, 0)

              let composicaoValor = this.itemAtual.composicaoValor
              let vlTaxa = response.find((x: any) => x.idTipoValor == 29)
              if (vlTaxa)
                composicaoValor.push({ tipo: 'adicionalAdm', valor: vlTaxa.valor, idTipoValor: 29 })

              let arrValorExtra = response.filter((x: any) => x.idTipoValor != 29)

              this.cartorioSelecionado['valoresApostilamento'] = response;
              this.cartorioSelecionado['valorEmolumento'] = this.cartorioSelecionado.valor;


              this.cartorioSelecionado.valor += arrValorExtra.reduce((acc: any, val: any) => acc += val.valor, 0)

              this.storage.localStorageSet('item_atual', this.itemAtual)

              this.atualizarValorCartorio()
            }
            else {
              this.zeraValorAoTrocarDeCidade()
            }

          }, error: (err) => {
            console.error(err);
          }
        });
      }
    }
  }


  validarLivroFolhaTermoObito() {
    const detalhesArray = this.camposForm.get('certidaoObito') as FormArray;
    const detalhe = detalhesArray.at(0);

    const matriculaControl = detalhe.get('matricula');
    const livroControl = detalhe.get('livro');
    const folhaControl = detalhe.get('folha');
    const termoControl = detalhe.get('termo');

    const isMatriculaFilled = matriculaControl && matriculaControl.value !== '';

    const areLivroFolhaTermoFilled = livroControl && livroControl.value !== '' &&
      folhaControl && folhaControl.value !== '' &&
      termoControl && termoControl.value !== '';

    if (!isMatriculaFilled && (!areLivroFolhaTermoFilled)) {
      this.classObrigatoriaMatricula = 'redClass';
    } else {
      this.classObrigatoriaMatricula = '';
    }

    if (matriculaControl && matriculaControl.value === '') {
      livroControl?.setValidators(Validators.required);
      folhaControl?.setValidators(Validators.required);
      termoControl?.setValidators(Validators.required);
    } else {
      livroControl?.clearValidators();
      folhaControl?.clearValidators();
      termoControl?.clearValidators();
    }

    livroControl?.updateValueAndValidity();
    folhaControl?.updateValueAndValidity();
    termoControl?.updateValueAndValidity();
  }

  get estado() {
    return this.dadosEntrega.get('uf')
  }

  get cidadee() {
    return this.dadosEntrega.get('cidade')
  }


  ngOnChanges(changes: SimpleChanges) {

    if (changes['cidade']) {

      this.getCartorios()
    }
    this.cdRef.detectChanges();


  }

  ngAfterViewInit() {
    this.estado?.valueChanges.subscribe(newValue => {
      this.listufFilter = this._filter(newValue);
    })
    this.cidadee?.valueChanges.subscribe(newValue => {
      this.listCidadeFilter = this._filterCidade(newValue);
    })
    if (this.storage.localStorageGet('localidade')) {
      this.loadCidade(this.storage.localStorageGet('localidade')?.uf?.IdEstado)
    }

  }

  temAceiteObrigatorio = false
  aceites: any

  temAceite(event: any) {
    this.temAceiteObrigatorio = true
    this.aceites = event
  }

  cancelarEdicaoForm() {
    this.cancelarEdicao.emit()
  }

  private _filter(value: any): any {
    if (value && typeof value === "string")
      return this.listuf.filter((x) => x.Nome.toLowerCase().indexOf(value.toLowerCase()) == 0)
    else
      return this.listuf
    //return this.listuf.filter((option:any) => option.Nome.toLowerCase().includes(filterValue));
  }

  private _filterCidade(value: any): any {
    if (value && typeof value === "string") {
      let valueNormalize = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return this.listCidade.filter((x) => x.Nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(valueNormalize) == 0);
    }
    else
      return this.listCidade
    //return this.listuf.filter((option:any) => option.Nome.toLowerCase().includes(filterValue));
  }

  public displayProperty(value: any) {
    if (value) {
      return value.Nome;
    }
  }

  public displayPropertyCidade(value: any) {

    if (value) {
      return value.Nome;
    }
  }

  getCidade(event: any) {
    this.loadCidade(event.option.value['IdEstado'])
    this.ufSelected = this.estado?.value

    // Limpa o campo da cidade quando o estado é alterado manualmente
    if (!event.isUserInput) {
      this.dadosEntrega.get('cidade')?.setValue(null);
    }


  }

  setLocal() {
    this.storage.localStorageSet('localidade', this.localidade?.value)

    this.cidadeSelected = this.cidadee?.value
    //this.showSemCobertura=false
  }

  loadCidade(IdEstado: any, cidade: any = null) {
    const url = environment.http.API + 'Localidade/cidade?IdEstado=' + IdEstado;

    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {
        if (response != null) {
          this.listCidadeFilter = response;
          this.listCidade = response;

          if (cidade == null)
            this.localidade.get('cidade')?.setValue(null)
          else {
            let city = response.find((x: any) => x.Nome == cidade)
            this.cidadeSelected = city
            this.dadosEntrega.get('cidade')?.setValue(city)
          }

          this.load = true

        }
      }, error: (err) => {

        //console.error(err);
      }
    });
  }

  getCartorios() {
    //console.log('teste')
    this.load = false
    this.service.getCartorios(this.itemAtual.titulo, this.uf.Sigla, this.cidade.Nome, this.itemAtual?.origem).subscribe({
      next: async (response) => {

        if (response != null) {
          this.cartorioEmissor = response

          if (this.cartorioEmissor?.length > 0) {
            this.temCobertura = true
          } else {
            this.temCobertura = false
          }
          this.setTemCoberturaFilho.emit(this.temCobertura);

          this.load = true

          this.cartorioSelecionado = null
          this.zeraValorAoTrocarDeCidade()

          // this.setCartorios.emit(this.cartorioEmissor);
          this.carrregarEdicao()
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  cartorioSelecionado: any; // Substitua `any` pelo tipo apropriado, se necessário

  atualizarValorCartorio() {

    this.cartorioSelecionado = this.camposForm.get('cartorio')?.value

    if (this.cartorioSelecionado) {

      const valorCartorio = this.cartorioSelecionado.valor // Assumindo que cada cartório tem uma propriedade 'valor'

      const valorFormatado = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valorCartorio);
      const elementoValor = document.getElementById('valorCartorioSelcionado');
      if (elementoValor) {
        elementoValor.textContent = valorFormatado;
      }
    }
  }

  zeraValorAoTrocarDeCidade() {
    const valorCartorio = 0

    const valorFormatado = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valorCartorio);
    const elementoValor = document.getElementById('valorCartorioSelcionado');
    if (elementoValor) {
      elementoValor.textContent = valorFormatado;
    }

  }


  compareCartorio(object1: any, object2: any) {

    return object1 && object2 && object1.idCartorio == object2.idCartorio;
  }

  createNacimento(): FormGroup {
    return this.formBuilder.group({
      nomeCompleto: ['', Validators.required],
      cpf: [''],
      mae: ['', Validators.required],
      nascimento: ['', Validators.required],
      livro: [''],
      folha: [''],
      termo: [''],
      matricula: [''],

    });
  }


  createCasamento(): FormGroup {
    return this.formBuilder.group({
      conjuge: ['', Validators.required],
      conjuge2: ['', Validators.required],
      cpf: [''],
      cpf2: [''],
      dataCasamento: ['', Validators.required],
      livro: [''],
      folha: [''],
      termo: [''],
      matricula: [''],
    });
  }

  createObito(): FormGroup {
    return this.formBuilder.group({
      nome: ['', Validators.required],
      cpf: [''],
      pai: ['', Validators.required],
      mae: ['', Validators.required],
      dataObito: ['', Validators.required],
      livro: [''],
      folha: [''],
      termo: [''],
      matricula: [''],
    });
  }


  get certidaoNascimento(): FormArray {
    return this.camposForm.get('certidaoNascimento') as FormArray;
  }
  get certidaoCasamento(): FormArray {
    return this.camposForm.get('certidaoCasamento') as FormArray;
  }

  get certidaoObito(): FormArray {
    return this.camposForm.get('certidaoObito') as FormArray;
  }


  get dadosEntrega(): FormGroup {
    return this.camposForm.get('dadosEntrega') as FormGroup;
  }

  get camposFormCompleto(): FormGroup {
    return this.camposForm as FormGroup;
  }



  updatePedido() {
    if (this.itemAtual?.subFormTipo == 'Óbito') {
      this.validarLivroFolhaTermoObito()
      this.camposFormCompleto.removeControl('certidaoCasamento')
      this.camposFormCompleto.removeControl('certidaoNascimento')
      for (var i = 0; i < this.certidaoObito.controls.length; i++) {
        let val = this.certidaoObito.controls[i].get('dataObito')?.value

        if (val && val != '')
          this.certidaoObito.controls[i].get('dataObito')?.setValue(new Date(this.certidaoObito.controls[i].get('dataObito')?.value))

      }

    } else if (this.itemAtual?.subFormTipo == 'Casamento') {
      this.validarLivroFolhaTermoCasamento()
      for (var i = 0; i < this.certidaoCasamento.controls.length; i++) {
        let val = this.certidaoCasamento.controls[i].get('dataCasamento')?.value

        if (val && val != '')
          this.certidaoCasamento.controls[i].get('dataCasamento')?.setValue(new Date(this.certidaoCasamento.controls[i].get('dataCasamento')?.value))

      }

      this.camposFormCompleto.removeControl('certidaoObito')
      this.camposFormCompleto.removeControl('certidaoNascimento')

    } else if (this.itemAtual?.subFormTipo == 'Nascimento') {
      this.validarLivroFolhaTermoNascimento()
      for (var i = 0; i < this.certidaoNascimento.controls.length; i++) {
        let val = this.certidaoNascimento.controls[i].get('nascimento')?.value

        if (val && val != '')
          this.certidaoNascimento.controls[i].get('nascimento')?.setValue(new Date(this.certidaoNascimento.controls[i].get('nascimento')?.value))

      }

      this.camposFormCompleto.removeControl('certidaoObito')
      this.camposFormCompleto.removeControl('certidaoCasamento')


    }

    if (this.temAceiteObrigatorio) {

      let aceitFinal = this.storage.localStorageGet('aceiteDireto')
      let temsemaceite = aceitFinal.find((x: any) => x.selecionado == false)

      if (temsemaceite == undefined) {

        this.camposForm.get('aceites')?.setValue(aceitFinal)
        this.classObrigatoriaAviso = ''

      } else {
        this.classObrigatoriaAviso = 'redClass'
        return

      }

    } else {

      this.classObrigatoriaAviso = ''
    }

    let prodAtu = null

    prodAtu = this.camposForm.value

    this.camposForm.markAllAsTouched();
    if (this.camposForm.valid) {
      this.salvarItem.emit(prodAtu);
    } else {
      let el = document.getElementById('topoPedido');
      el?.scrollIntoView();
    }
  }

  abrirImagemPopupRG() {
    const imagemUrl = "./assets/rg-exemplo.jpg";

    if (imagemUrl) {
      const dialogRef = this.dialog.open(ImageViewerComponent, {
        data: { imagemUrl }
      });

      dialogRef.afterClosed().subscribe(result => {
        //console.log('O popup foi fechado', result);
      });
    }
  }
  abrirImagemPopupCertidao() {
    // Define a mapping from subFormTipo to the image URL
    const urlsPorTipo: { [key: string]: string } = {
      Nascimento: "./assets/certidao-nascimento-exemplo.jpg",
      Casamento: "./assets/certidao-casamento-exemplo.jpg",
      Óbito: "./assets/certidao-obito-exemplo.jpg"
    };

    // Assert that subFormTipo is a key of urlsPorTipo
    const subFormTipo = this.itemAtual.subFormTipo as keyof typeof urlsPorTipo;

    // Use the mapping to get the image URL
    const imagemUrl = urlsPorTipo[subFormTipo];

    // Only proceed if imagemUrl is defined
    if (imagemUrl) {
      const dialogRef = this.dialog.open(ImageViewerComponent, {
        data: { imagemUrl }
      });

      dialogRef.afterClosed().subscribe(result => {
        //console.log('O popup foi fechado', result);
      });
    } else {
      // Handle the case where subFormTipo does not match any key in urlsPorTipo
      //console.log('Tipo de subForm ' + subFormTipo + ' não reconhecido.');
    }
  }


  buscarEndereco($event: any) {
    let cep = $event.target.value.replace('-', '')
    if (cep.length == 8) {
      this.service.getObservableDirect('https://viacep.com.br/ws/' + cep + '/json').subscribe({
        next: async (response: any) => {
          //Zera os campos para receber os novos valores
          this.restarDadosForm();
          //atribui o retorno a lista de endereço
          this.listEndereco = response;
          //Preenche o Form com os dados da consulta de CEP.
          this.popularDadosForm(this.listEndereco);

          this.ufSelected = localidade.listuf.find((x: any) => x.Sigla == response.uf)
          this.dadosEntrega.get('uf')?.setValue(this.ufSelected)
          this.loadCidade(this.ufSelected.IdEstado, response.localidade)
        },
        error: (err) => {

          //console.error(err);
        }
      })

    }
  }

  cepPreenchido(): boolean {
    const localidade = this.storage.localStorageGet('localidade');
    return localidade && localidade.cep !== null && localidade.cep !== '';
  }


  carrregarEdicao() {

    let paramId = this.routeAc.snapshot.paramMap.get('id');

    if (paramId != null || this.itemEdicao != null) {
      let pedido = this.storage.localStorageGet("pedido");

      if (pedido?.listaCompra != null && pedido?.listaCompra.length > 0 && paramId != null) {
        let prodAtu = { ...pedido.listaCompra?.find((x: any) => x.idItem == paramId) }

        this.service.preencheCampos(prodAtu, this.camposForm)
        this.atualizarValorCartorio()
      }
      else {
        let prodAtu = this.itemEdicao;

        this.service.preencheCampos(prodAtu, this.camposForm);
        this.atualizarValorCartorio()
      }
    }
  }

  popularDadosForm(dados: any) {
    this.camposForm.patchValue({
      dadosEntrega: {
        cep: dados.cep,
        endereco: dados.logradouro,
        bairro: dados.bairro,
        cidade: dados.localidade,
        uf: dados.uf
      }

    })
  }

  restarDadosForm() {
    this.camposForm.patchValue({
      dadosEntrega: {
        cep: null,
        endereco: null,
        bairro: null,
        cidade: null,
        uf: null
      }

    })
  }



  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // //console.log(event.key, event.ctrlKey, event.shiftKey, environment.production)
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {

      this.teste.setValueForm(this.camposForm);
      this.teste.setValueForm(this.dadosEntrega);

      for (let control of this.certidaoNascimento.controls) {

        if (control instanceof FormGroup) {
          //console.log(control)
          this.teste.setValueForm(control);
        }

      }
      for (let control of this.certidaoCasamento.controls) {

        if (control instanceof FormGroup) {
          //console.log(control)
          this.teste.setValueForm(control);
        }

      }
      for (let control of this.certidaoObito.controls) {

        if (control instanceof FormGroup) {
          //console.log(control)
          this.teste.setValueForm(control);
        }

      }
    }

  }

}
