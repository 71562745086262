import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
import { lista } from 'src/app/_configPage/listas/forms';
import { GenericValidator } from 'src/app/_sevice/GenericValidator';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';

import { map, startWith } from 'rxjs/operators';
import { localidade } from 'src/app/_configPage/listas/localidade';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-form-15',
  templateUrl: './form15.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class Form15Component implements OnInit, AfterViewInit {
  @Output() salvarItem = new EventEmitter<any>();

  @Input() itemAtual: any;
  @Input() itemEdicao: any;
  @Input() edicao: any=false;
  @Output() cancelarEdicao = new EventEmitter<any>();

  @Input() cidade: any;
  @Input() classObrigatoria: any;
  @Input() cartorioEmissor: any[];
  @Input() multiplo: any;
  @Output() setTemCoberturaFilho = new EventEmitter<any>();
  formularios: any[] = lista
  @Input() itemAtualKit: any;
  listuf: any[] = localidade.listuf
  listufFilter: any = this.listuf
  itemAtualConfig: any
  filteredOptionsUf: Observable<string[]>;
  @ViewChildren(MatAutocompleteTrigger) trigger: QueryList<MatAutocompleteTrigger>;
  ufSelected: any
  classeObrigagtoria: any = '';

  constructor(private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public teste: TesteService,
    private routeAc: ActivatedRoute,
    public service: CrudService
  ) {

  }

  camposForm: FormGroup

  ngOnInit(): void {


    this.toFormGroup()

  }

  private _confereUf(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.listufFilter.filter(
      (option: any) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }

  public displayProperty(value: any) {
    if (value) {
      return value.Nome;
    }
  }

  get uf() {
    return this.camposForm?.get('uf') as FormControl
  }

  ngAfterViewInit() {
    this.uf?.valueChanges.subscribe(newValue => {
      this.listufFilter = this._filter(newValue);
    });
  }

  private _filter(value: any): any {
    //console.log(typeof value?.uf)

    if (value && typeof value === "string")
      return this.listuf.filter((x) => x.Nome.toLowerCase().indexOf(value.toLowerCase()) == 0)
    else
      return this.listuf
    //return this.listuf.filter((option:any) => option.Nome.toLowerCase().includes(filterValue));
  }

  cancelarEdicaoForm() {
    this.cancelarEdicao.emit()
  }

  apenasNumeros(event: any) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  //trocaform(item: any) {
  //  //console.log("teste item", item.value)
  //  this.itemAtualConfig = item.value
  //  this.storage.localStorageSet("frmatPlex", this.itemAtual)

  //  this.toFormGroup();

  //}

  toFormGroup() {

    this.itemAtualConfig = this.formularios.find((x: any) => x.idAto == this.itemAtual.idAto)
    //console.log(this.itemAtualConfig)

    if (this.itemAtualConfig) {
      const group: any = {};

      for (var i = 0; i < this.itemAtualConfig.campos.length; i++) {
        let campo = this.itemAtualConfig.campos[i]


        if (campo.tipo != 'breakLine') {
          if (campo.prop == "cpf" || campo.prop == "cpfCnpj" || campo.prop == "cnpj")
            group[campo.prop] = campo.required ? new FormControl('', 
                                                      Validators.compose([Validators.required, GenericValidator.isValidCPFCNPJ()])
                                                    ) : new FormControl('');
          else
            group[campo.prop] = campo.required ? new FormControl('', Validators.required) : new FormControl('');
        }

        if (campo?.disabled)
          group[campo.prop]?.disable();
      }

      this.camposForm = new FormGroup(group);
      //console.log(this.camposForm);

      this.carrregarEdicao();
      this.loadUf();
      this.loadValidateRequiredOptionalChange();
    }

  }

  loadUf() {
    this.filteredOptionsUf = this.uf?.valueChanges.pipe(
      startWith(''),
      map(value => this._confereUf(value))
    );

    let cpUf = this.itemAtualConfig.campos.find((opt: any) => opt.prop == 'uf');
    this.uf?.setValue(cpUf?.valueUfDefault);
  }

  loadValidateRequiredOptionalChange() {
    let camposValidate = this.itemAtualConfig.campos.filter((opt: any) => opt?.requiredOptional);

    for (var i = 0; i < camposValidate.length; i++) {
      let cp = camposValidate[i];
      let control = this.camposForm.get(cp.prop);

      control?.valueChanges.subscribe(() => this.ValidarRequiredOptional(cp));
    }
  }

  updatePedido() {
    this.classeObrigagtoria = 'alertared';
    let validSubmit = true;

    for (var i = 0; i < this.itemAtualConfig.campos.length; i++) {
      let cp = this.itemAtualConfig.campos[i];

      if (cp?.requiredDependency) this.ValidarRequiredDependency(cp);
      if (cp?.requiredOptional) this.ValidarRequiredOptional(cp);
      if (cp?.dependencyLengthCampo) this.ValidarDependencyLength(cp);

      if (cp['erroValidacao']) validSubmit = false;
    }

    this.camposForm.markAllAsTouched();

    if (this.camposForm.get('dataNascimento')?.hasError('maxlength'))
      this.camposForm.get('dataNascimento')?.setErrors(null);

    if (this.camposForm.valid && validSubmit) {
      let item: any = this.camposForm.value
      this.salvarItem.emit(item);
    } else {
      //console.log('form inválido', this.camposForm)
    }
  }

  ValidarRequiredDependency(cp: any) {
    let campoPai = this.camposForm.get(cp.requiredDependency);
    let control = this.camposForm.get(cp.prop);

    campoPai?.clearValidators();
    control?.clearValidators();

    if (campoPai?.value != '') {
      control?.setValidators(Validators.required);
      control?.updateValueAndValidity();
    }
  }

  ValidarRequiredOptional(cp: any) {
    let control = this.camposForm.get(cp.prop);
    let valueCampoPaiValid = false;

    cp.requiredOptional.forEach((requiredOptionalCurrent: any) => {
      let campoPai = this.camposForm.get(requiredOptionalCurrent);
      let campoPaiFilter = this.itemAtualConfig.campos.find((atual: any) => atual.prop == requiredOptionalCurrent);
      
      delete campoPaiFilter['erroValidacao'];

      if (campoPai?.value != '')
        valueCampoPaiValid = true;
    });

    if (!valueCampoPaiValid && control?.value == '') {
      let lastCampoRequired = this.itemAtualConfig.campos.find((atual: any) => atual.prop == cp?.lastCampoRequiredOptional);
      lastCampoRequired['erroValidacao'] = true;
    } else
      delete cp['erroValidacao']
  }

  ValidarDependencyLength(cp: any) {
    let dependencyLengthCampo = cp?.dependencyLengthCampo?.campo;
    let dependencyLengthMaximo = cp?.dependencyLengthCampo?.length;

    let control = this.camposForm.get(cp.prop);
    let campoPai = this.camposForm.get(dependencyLengthCampo);

    control?.clearValidators();

    if (campoPai?.value.length == dependencyLengthMaximo)
      control?.setValidators(Validators.required);

    control?.updateValueAndValidity();
  }

  carrregarEdicao() {
    let paramId = this.routeAc.snapshot.paramMap.get('id');

    if (paramId != null || this.itemEdicao != null) {
      if (this.itemEdicao == null) {
        let pedido = this.storage.localStorageGet("pedido")

        if (pedido?.listaCompra != null && pedido?.listaCompra.length > 0 && paramId != null) {
          let prodAtu = { ...pedido.listaCompra?.find((x: any) => x.idItem == paramId) }
          this.service.preencheCampos(prodAtu, this.camposForm)
        }
      } else {
        let prodAtu = this.itemEdicao
        this.service.preencheCampos(prodAtu, this.camposForm)
      }
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // //console.log(event.key, event.ctrlKey, event.shiftKey, environment.production)
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {

      this.teste.setValueForm(this.camposForm);

    }
    
  }

}
