<!--Recuperação de senha-->
<ng-container *ngIf="conteudo.tipo=='esquecisenha'  && exibeForm==false">
  <h2>Recuperação de senha</h2>
  <p>Digite seu CPF/CNPJ</p>
  <form (ngSubmit)="recuperarSenha()" [formGroup]="formCadastro">
    <div class="col-6">

      <div style="height:50px" *ngIf="isLoading"><mat-spinner [diameter]="35" style="float:left"></mat-spinner>carregando finalidades...</div>
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo" *ngIf="!isLoading">
        <mat-label for="cpf">CPF/CNPJ</mat-label>
        <input matInput type="text" id="filtro" name="filtro"
               mask="000.000.000-00||00.000.000/0000-00"
               formControlName="filtro" placeholder="CPF/CNPJ">
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12" *ngIf="!isLoading">
      <button mat-raised-button color="primary" class="mr-5">Continuar</button>
    </div>
  </form>
  <br />
</ng-container>

<!--=============================================================================-->
<!--Redefinição de senha-->
<ng-container *ngIf="conteudo.tipo=='redefinirsenha' && exibeForm==false">
  <br />
  <h2>Alteração de senha</h2>
  <p>Crie uma nova senha com no mínimo 3 caracteres.</p>
  <p>
    Procure mesclar letras maiúsculas e minúsculas, símbolos e números.
  </p>

  <form (ngSubmit)="trocarSenha()" [formGroup]="formCadastro">
    <div class="col-6">
      <mat-form-field appearance="outline" class="w-100 my-2">
        <input matInput [type]="password ? 'password' : 'text'" id="password" name="senha" formControlName="password" placeholder="Digite sua senha" required>
        <button mat-icon-button matSuffix type="button" (click)="togglePassword('password')">
          <mat-icon>{{ password ? 'remove_red_eye' : 'visibility_off' }}</mat-icon>
        </button>
        <mat-error *ngIf="f['password']?.errors?.['required']">
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="outline" class="w-100 my-2">
        <input matInput [type]="confirmPassword ? 'password' : 'text'" id="confirmPassword" name="confirmPassword" placeholder="Confirme a sua senha" formControlName="confirmPassword" required>
        <button mat-icon-button matSuffix type="button" (click)="togglePassword('confirmPassword')">
          <mat-icon>{{ confirmPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
        </button>
        <mat-error *ngIf="formCadastro.hasError('passwordsNotMatch', 'confirmPassword')">
          As senhas não coincidem.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12">
      <button mat-raised-button color="primary" class="mr-5">Salvar</button>
    </div>
  </form>
  <br />
  <br />
</ng-container>

<!--=============================================================================-->
<!--=============================================================================-->
<!--Alterar Senha de Usuário Logado-->
<ng-container *ngIf="conteudo.tipo=='alterarsenha' && exibeForm==false">
  <h2>Alteração de senha</h2>
  <p>Crie uma nova senha com no mínimo 3 caracteres.</p>
  <p>
    Procure mesclar letras maiúsculas e minúsculas, símbolos e números.
  </p>

  <form (ngSubmit)="alterarSenha()" [formGroup]="formCadastro">
    <div class="col-6">
      <mat-form-field appearance="outline" class="w-100 my-2">
        <input matInput [type]="password ? 'password' : 'text'" id="password" name="senha" formControlName="password" placeholder="Digite sua senha" required>
        <button mat-icon-button matSuffix type="button" (click)="togglePassword('password')">
          <mat-icon>{{ password ? 'remove_red_eye' : 'visibility_off' }}</mat-icon>
        </button>
        <mat-error *ngIf="f['password']?.errors?.['required']">
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="outline" class="w-100 my-2">
        <input matInput [type]="confirmPassword ? 'password' : 'text'" id="confirmPassword" name="confirmPassword" placeholder="Confirme a sua senha" formControlName="confirmPassword" required>
        <button mat-icon-button matSuffix type="button" (click)="togglePassword('confirmPassword')">
          <mat-icon>{{ confirmPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
        </button>
        <mat-error *ngIf="formCadastro.hasError('passwordsNotMatch', 'confirmPassword')">
          As senhas não coincidem.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12">
      <button mat-raised-button color="primary" class="mr-5">Salvar</button>
    </div>
  </form>
  <br />
</ng-container>

<!--=============================================================================-->
<!--form para exibição de mensagem na tela-->
<form *ngIf="exibeForm">
  <h2>Recuperação de senha</h2>
  <div class="alert alert-warning" *ngIf="exibeFormAlerta">
    <strong>Alerta!</strong> Dados não encontrados para recuperação de senha.
  </div>
  <!-- -->
  <div *ngIf="exibeFormAlerta==false">
    <p>{{ mensagem }}</p>
  </div> <br />

  <div class="row" *ngIf="exibeFormAlerta==true">
    <div class="col-12">
      <button mat-raised-button color="primary" class="mr-5" (click)="voltarTela()">Voltar</button>
    </div>
  </div>
</form>
