import uitoolkit from '@zoom/videosdk-ui-toolkit'

import { DOCUMENT } from "@angular/common";
import { Component, Inject, NgZone, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { KJUR } from "jsrsasign";
import { CrudService } from "src/app/_sevice/CrudService";
import { environment } from 'src/environments/environment';
import { ConfigMeetingJoin } from "./config-meeting-join.model";
import { switchMap } from 'rxjs';


@Component({
  selector: 'app-video-medicao-zoom',
  templateUrl: './video-medicao-zoom.component.html',
  styleUrls: ['./video-medicao-zoom.component.less'],
})
export class VideoMedicaoZoomComponent implements OnInit, OnDestroy {
  previewContainer: any;
  sessionContainer: any;
  userMeetingSchedule: any;
  meetEncerrada: boolean = false
  meetEncerradaForca: boolean = false
  timeMeetingEnd: number;
  inSession: boolean = false;
  userAccess: any = { accessAllowed: false, messageAccess: 'SEM ACESSO PARA ENTRAR NA MEDIAÇÂO' };

  roleType: number = 0;

  config: ConfigMeetingJoin;
  navigationSubscription;

  constructor(
    @Inject(DOCUMENT) document: any,
    private ngZone: NgZone,
    private route: Router,
    private service: CrudService,
    private router: ActivatedRoute
  ) {
    this.navigationSubscription = this.route.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        console.log(this.roleType)
        this.roleType = 0


      }
    });
  }

  ngOnInit(): void {

    const initZoom = () => {
      if (this.userAccess.accessAllowed) {
        this.loadConfigZoom();
        this.verifySessionClosed();
      }
    };

    this.verifyUserMeeting(this.router.snapshot.params['guidMeeting'], this.router.snapshot.params['guidUser'], initZoom);
  }

  ngOnDestroy(): void {
    if (!this.meetEncerrada) {
      this.meetEncerradaForca = true

      this.sessionClosed()

    }
  }

  verifyUserMeeting(guidMeeting: any, guidUser: any, fnCallbackSucess: Function | null, fnCallbackError: Function | null = null) {
    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.getObservable(`MeetingAccess/ScheduleMeetingByUser/${guidMeeting}/${guidUser}`).subscribe({
      next: (response: any) => {
        if (response != null) {
          this.userMeetingSchedule = response;
          this.userAccess = { accessAllowed: this.userMeetingSchedule.AccessAllowed, messageAccess: this.userMeetingSchedule.MessageAccess };
        }

        if (fnCallbackSucess != null)
          fnCallbackSucess();
      },
      error: (error: any) => {
        console.log('error usersMeeting = ', error);

        if (fnCallbackError != null)
          fnCallbackError();
      },
      complete: () => (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%"
    });
  }

  loadConfigZoom() {
    this.previewContainer = document.getElementById('previewContainer');
    uitoolkit.openPreview(this.previewContainer!);

    this.LoadConfigMeetingEnd(new Date(this.userMeetingSchedule?.MeetingSchedule.ScheduleEnd));

    this.roleType = this.userMeetingSchedule?.MeetingSchedule.IsHost ? 1 : 0;

    this.config = {
      userName: this.userMeetingSchedule?.User?.Nome ?? "Convidado",
      sessionName: this.userMeetingSchedule?.MeetingSchedule.MeetingTitle,
      sessionPasscode: '123',
      features: ['video', 'audio', 'settings', 'users', 'chat', 'share'],
      videoSDKJWT: ''
    };
  }

  LoadConfigMeetingEnd(dateEnd: Date) {
    const dateNow = new Date();
    this.timeMeetingEnd = dateEnd.getTime() - dateNow.getTime();
  }

  generateSignature(sdkKey: string, sdkSecret: string): string {
    const iat = Math.round(new Date().getTime() / 1000) - 30;
    const exp = iat + 60 * 60 * 2;
    const oHeader = { alg: 'HS256', typ: 'JWT' };

    const oPayload = {
      app_key: sdkKey,
      tpc: this.config.sessionName,
      role_type: this.roleType,
      session_key: this.config.sessionPasscode,
      version: 1,
      iat: iat,
      exp: exp
    };

    

    const sHeader = JSON.stringify(oHeader);
    const sPayload = JSON.stringify(oPayload);

    return KJUR.jws.JWS.sign('HS256', sHeader, sPayload, sdkSecret)
  }

  getVideoSDKJWT() {

    this.sessionContainer = document.getElementById('sessionContainer');
    this.inSession = true;

    this.config.videoSDKJWT = this.generateSignature(environment.sdkZoom.sdkKey, environment.sdkZoom.sdkSecret);

    this.joinSession();
  }

  joinSession() {
    uitoolkit.joinSession(this.sessionContainer, this.config);
    uitoolkit.onSessionClosed(this.sessionClosed);
    uitoolkit.onSessionJoined(this.marcarEntrada)

  }

  dadosEntrada: any
  private marcarEntrada = (() => {
    let descricao: string = this.userMeetingSchedule.MeetingSchedule?.StatusMeeting?.Descricao
    descricao = descricao.replace('Aguardando', "");

    this.dadosEntrada = { acao: 'entrou na mediação' + descricao, usuario: this.userMeetingSchedule?.User?.Nome, guidUser: this.userMeetingSchedule?.User?.UserGuid, data: new Date() }


  });



  verifySessionClosed() {
    setTimeout(
      () => window.alert(`Reunião sera encerrada em ${(environment.sdkZoom.timeOutMettingAlert / (1000 * 60))} minuto(s)`),
      (this.timeMeetingEnd - environment.sdkZoom.timeOutMettingAlert)
    );

    setTimeout(this.sessionClosed, this.timeMeetingEnd);
  }

  private sessionClosed = (() => {
    this.inSession = false; 
    uitoolkit.closeSession(this.sessionContainer);
    uitoolkit.closePreview(this.previewContainer);

    if (this.roleType == 1 && !this.meetEncerradaForca) {
      this.meetEncerrada = true
      this.atualizarMeeting('finalizou a mediação', true,true);

    } else {

      this.meetEncerrada = true
      this.atualizarMeeting('saiu da mediação', true);

      this.route.navigate(['/fadmin/meus-agendamentos']);

    }

  });

  atualizarMeeting(entrada: any, atualizarStatus: boolean = false, anfitriao: boolean = false) {
    const guidMeeting = this.userMeetingSchedule.MeetingSchedule?.GuidMeeting;

    this.service.getObservable(`MeetingAccess/${guidMeeting}`)
      .pipe(
        switchMap((meeting: any) => {
          const statusUpdate = this.loadStatusMeetingUpdate(this.userMeetingSchedule.MeetingSchedule?.StatusMeeting?.Slug);
          let meetingInfo = meeting.meetingInfo.find((m: any) => m.meetingGuid == guidMeeting);

          if (atualizarStatus && anfitriao) {
            meeting.status = statusUpdate;
            meetingInfo.meetFinalizada = true;
            meetingInfo.statusMeeting = statusUpdate;
          } 
          let descricao: string = this.userMeetingSchedule.MeetingSchedule?.StatusMeeting?.Descricao
          descricao = descricao.replace('Aguardando ', "");
          if (meeting['historico']) {
            meeting['historico'].push(this.dadosEntrada)
            meeting['historico'].push({ acao: entrada + '  ' + descricao, usuario: this.userMeetingSchedule?.User?.Nome, guidUser: this.userMeetingSchedule?.User?.UserGuid, data: new Date() })

          } else {
            meeting['historico'] = [this.dadosEntrada]
            meeting['historico'].push({ acao: entrada + '  ' + descricao, usuario: this.userMeetingSchedule?.User?.Nome, guidUser: this.userMeetingSchedule?.User?.UserGuid, data: new Date() })

          }


          return this.service.putObservable('MeetingAccess/', meeting);
        })
      ).subscribe({
        next: () => {
          this.route.navigate(['/fadmin/meus-agendamentos']);
        },
        error: (error: any) => console.log('erro ao atualizar o meeting = ', error)
      });
  }

  loadStatusMeetingUpdate(status: string): any {
    switch (status) {
      case 'aguardando-audiencia-inicial':
        return { slug: 'audiencia-inicial-realizada', descricao: 'Audiência inicial realizada' };
      case 'aguardando-audiencia-instruçao':
        return { slug: 'audiencia-instrucao-realizada', descricao: 'Audiência de instrução realizada' };
      case 'aguardando-audiencia-julgamento':
        return { slug: 'audiencia-julgamento-realizada', descricao: 'Audiência de julgamento realizada' };
    }
  }
}
