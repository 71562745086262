import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-horizontal',
  templateUrl: './cardHorizontal.component.html',
  styleUrls: ['./cardHorizontal.component.scss']
})
export class CardHorizontalComponent implements OnInit {
  @Input() conteudo: any;
  
  constructor() { }

  ngOnInit(): void {

  }

}
