import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalStorage } from '../../../_entidade/LocalStorage';

@Component({
  selector: 'app-aviso',
  templateUrl: './aviso.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class AvisoComponent implements OnInit {
  @Input() aviso: any;

  avisoVermelho: any
  avisoAmarelo: any
  avisoVerde: any
  avisoNeutro: any
  aceiteDireto: any
  aceiteModal: any
  @Output() temAceite = new EventEmitter<any>();
  @Input() classObrigatoriaAviso: any;


  constructor(public storage: LocalStorage) { }

  ngOnInit(): void {
    this.carregarAvisos(this.aviso);
  }

  carregarAvisos(avisos: any) {
    //console.log(`this.aviso = `, avisos);

    this.avisoVermelho = avisos?.filter((x: any) => x.tipo == 'vermelho')
    this.avisoVerde = avisos?.filter((x: any) => x.tipo == 'verde')
    this.avisoAmarelo = avisos?.filter((x: any) => x.tipo == 'amarelo')
    this.avisoNeutro = avisos?.filter((x: any) => x.tipo == 'neutro')
    this.aceiteDireto = avisos?.filter((x: any) => x.tipo == 'aceiteDireto')

    if (this.aceiteDireto != null && this.aceiteDireto != undefined && this.aceiteDireto.length > 0) {
      this.storage.localStorageSet('aceiteDireto', this.aceiteDireto)
      this.temAceite.emit(this.aceiteDireto)
    }
  }

  marcaAceite(event: any, item: any) {

    //console.log(event.checked)
    if (event.checked) {
      this.storage.localStorageSet('aceiteDireto', this.aceiteDireto)
      this.classObrigatoriaAviso = ''
    } else {
      this.storage.localStorageRemove('aceiteDireto')

    }

  }
}
