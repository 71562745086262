import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Injectable({
  providedIn: 'root'
})
export class MockService {

  constructor(
  ) {
  }
  itensDinamicoGrid: Array<any> = new Array();


  retornaBoleano(): any {
    var periodos = [0, 1]

    var itemretorno = periodos[this.randomInteger(0, 1)];
    return itemretorno;
  }

  retornaDataDescPeriodo(): any {
    var periodos = [' manhã ', ' tarde ', ' manhã+tarde ', ' diaria ']
    var itemretorno = this.randomInteger(1, 27) + '/' + this.randomInteger(1, 12) + '/' + this.randomInteger(new Date().getFullYear() - 6, new Date().getFullYear());
    itemretorno += periodos[this.randomInteger(1, 4)];
    return itemretorno;
  }

  retornaLocalidade(): any {
    var periodos = ['Municipal', 'Intermunicipal', 'Interestadual'
      , 'Municipal', 'Intermunicipal', 'Interestadual']
    var index = this.randomInteger(0, periodos.length - 1);

    var itemretorno = periodos[index];
    return itemretorno;
  }

  retornaCota(): any {
    var periodos = [
      'sob aprovação'
      , '1'
      , 'sob aprovação'
      , 'sob aprovação'
      , '55'
      , '33'
      , '4'
      , '5'
      , 'sob aprovação', '8', '10', '55', 'sob aprovação', '33', 'sob aprovação', '88', 'sob aprovação']
    var index = this.randomInteger(0, periodos.length - 1);

    var itemretorno = periodos[index];
    return itemretorno;
  }

  getNewDate(): any {
    return this.randomInteger(1, 27) + '/' + this.randomInteger(1, 12) + '/' + this.randomInteger(new Date().getFullYear() - 6, new Date().getFullYear());
  }

  retornaIntervaloData(): any {

    var periodos = [
      this.getNewDate() + ' à ' + this.getNewDate()
      , this.getNewDate() + ' à ' + this.getNewDate()
      , this.getNewDate() + ' à ' + this.getNewDate()
      , this.getNewDate() + ' à ' + this.getNewDate()
      , this.getNewDate() + ' à ' + this.getNewDate()
      , this.getNewDate() + ' à ' + this.getNewDate()
      , this.getNewDate() + ' à ' + this.getNewDate()
      , this.getNewDate() + ' à ' + this.getNewDate()
      , this.getNewDate() + ' à ' + this.getNewDate()
      , this.getNewDate() + ' à ' + this.getNewDate()
      , this.getNewDate() + ' à ' + this.getNewDate()
      , this.getNewDate() + ' à ' + this.getNewDate()
    ]
    var index = this.randomInteger(1, 6);

    var itemretorno = periodos[index];
    return itemretorno;
  }

  retornaEmpresaEndereco(): any {
    return 'Escola Teste ' + this.randomInteger(1, 99999) + ' - Rua Teste' + this.randomInteger(1, 99999) + ', ' + this.randomInteger(1, 500) + ' - Bairro Teste ' + this.randomInteger(1, 500) + ', Campinas / SP'
  }

  retornaDia() {
    var str = '00' + String(this.randomInteger(1, 27))
    return str.slice(-2);
  }

  retornaMes() {
    var str = '00' + String(this.randomInteger(1, 12))
    return str.slice(-2);
  }

  retornaAno() {
    return this.randomInteger(new Date().getFullYear() - 3, new Date().getFullYear());
  }

  async gerarMockLista(qtde:any, conteudo:any) {

    this.itensDinamicoGrid = new Array();
    var id = 1
    for (var j = 0; j < qtde; j++) {
      var newItem: any = { Id: id };
      id++;
      for (var i = 0; i < conteudo.dadosGrid.length; i++) {
        if (conteudo.dadosGrid[i]["tipoMock"] == "intervaloData") {
          newItem[conteudo.dadosGrid[i]["id"]] = this.retornaIntervaloData();
        } else if (conteudo.dadosGrid[i]["tipoMock"] == "cota") {
          newItem[conteudo.dadosGrid[i]["id"]] = this.retornaCota();
        } else if (conteudo.dadosGrid[i]["tipoMock"] == "boolean") {
          newItem[conteudo.dadosGrid[i]["id"]] = this.retornaBoleano();
        } else if (conteudo.dadosGrid[i]["tipoMock"] == "number") {
          newItem[conteudo.dadosGrid[i]["id"]] = this.randomInteger(1, 9999);
        } else if (conteudo.dadosGrid[i]["tipoMock"] == "localidade") {
          newItem[conteudo.dadosGrid[i]["id"]] = this.retornaLocalidade();
        } else if (conteudo.dadosGrid[i]["tipoMock"] == "empresaEndereco") {
          newItem[conteudo.dadosGrid[i]["id"]] = this.retornaEmpresaEndereco();
        } else if (conteudo.dadosGrid[i]["tipoMock"] == "dia") {
          newItem[conteudo.dadosGrid[i]["id"]] = this.retornaDia();
        } else if (conteudo.dadosGrid[i]["tipoMock"] == "mes") {
          newItem[conteudo.dadosGrid[i]["id"]] = this.retornaMes();
        } else if (conteudo.dadosGrid[i]["tipoMock"] == "ano") {
          newItem[conteudo.dadosGrid[i]["id"]] = this.retornaAno();
        }
        else {
          if (conteudo.dadosGrid[i]['subProp']) {
            ////console.log(conteudo.dadosGrid[i])
            newItem[conteudo.dadosGrid[i]["id"]] = { Id: id, Descricao: "Teste " + this.randomInteger(1, 99999) };

          } else {
            newItem[conteudo.dadosGrid[i]["id"]] = "Teste " + this.randomInteger(1, 99999);

          }
        }

      }
      this.itensDinamicoGrid.push(newItem)
    }

    for (let key in conteudo.dadosFiltro.default) {

      if (typeof conteudo.dadosFiltro.default[key] == 'object') {
        var temitem = conteudo.dadosGrid.find((i:any) => i.prop == key);
        if (temitem) {
          let result = this.itensDinamicoGrid.map(a => a[key]);
          if (result) {
            let index = conteudo.inputsFiltro.forms[0].campos.findIndex((j:any) => j.prop == key);
            if (index >= 0)
              conteudo.inputsFiltro.forms[0].campos[index].options = result;
          }
        }
      }
    }
    for (let key in conteudo.dadosEntidade.default) {

      if (typeof conteudo.dadosEntidade.default[key] == 'object') {
        var temitem = conteudo.dadosGrid.find((i:any) => i.prop == key);
        if (temitem) {
          let result = this.itensDinamicoGrid.map(a => a[key]);
          if (result) {
            let index = conteudo.inputs.forms[0].campos.findIndex((j:any) => j.prop == key);
             
            if (index >= 0) {
              conteudo.inputs.forms[0].campos[index].options = result;

            }
          }
        }
      }
    }

    conteudo.optionsProp = this.itensDinamicoGrid;
    conteudo.page = conteudo.paginaAtual;
    conteudo.collectionSize = qtde;
    conteudo.edicao = false;


  }
  async gerarMockSelect(input:any) {
    var itensDinamico: Array<any> = new Array();

    this.itensDinamicoGrid = new Array();
    var id = 1
    for (var j = 0; j < 10; j++) {
      var newItem = { Id: id, Descricao: "Teste " + this.randomInteger(1, 99999) };
      id++;

      itensDinamico.push(newItem)
    }

    input.options = itensDinamico;
  }

  randomInteger(min: number, max: number): any {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
