<div [formGroup]="formDinamico">


  <div [formGroupName]="input.subProp ? input.prop :'default'">
    <mat-form-field appearance="outline" *ngIf="inputType === 'default' && !input.maskInput"
                    class="inputMat">
      <mat-label>{{input.label}}</mat-label>
      <input type="text" matInput
             [formControlName]="input.subProp ?input.subProp : input.prop"
             (keyup)="changeInput($event)"
             [(ngModel)]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
             [placeholder]="input.placeholder">
      <mat-error>
        {{input.label}} é <strong>obrigatório</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="inputType === 'default' && input.maskInput"
                    class="inputMat">
      <mat-label>{{input.label}}</mat-label>
      <input type="text" matInput
             [formControlName]="input.subProp ?input.subProp : input.prop"
             [mask]="input.maskInput"
             (keyup)="changeInput($event)"
             [(ngModel)]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
             [placeholder]="input.placeholder">
      <mat-error>
        {{input.label}} é <strong>obrigatório</strong>
      </mat-error>
    </mat-form-field>


    <mat-form-field appearance="outline" class="inputMat"
                    *ngIf="inputType === 'cep'">
      <mat-label for="cep">CEP</mat-label>
      <input type="text" matInput mask="00000-000"
             [formControlName]="input.subProp ?input.subProp : input.prop"
             (keyup)="buscarEndereco($event)"
             [(ngModel)]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
             [placeholder]="input.placeholder">


      <mat-error>
        {{input.label}} é <strong>obrigatório</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="inputMat"
                    *ngIf="inputType === 'senha'">
      <mat-label>Senha</mat-label>
      <input matInput [type]="hidePassword1 ? 'password' : 'text'"
             [formControlName]="input.subProp ?input.subProp : input.prop"
             placeholder="Digite sua senha" [(ngModel)]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
             required>
      <button class="border-0 mx-3 rounded-5" mat-icon-button matSuffix type="button"
              (click)="hidePassword1 = !hidePassword1">
        <mat-icon>{{ hidePassword1 ? 'remove_red_eye' : 'visibility_off' }}</mat-icon>
      </button>
      <mat-error>
        {{input.label}} é <strong>obrigatório</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="inputMat"
                    *ngIf="inputType === 'confirmarSenha'">
      <mat-label>Confirmar senha</mat-label>
      <input matInput [type]="hidePassword2 ? 'password' : 'text'"
             [formControlName]="input.subProp ?input.subProp : input.prop"
             placeholder="Digite sua senha" [(ngModel)]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
             required>
      <mat-error>
        {{input.label}} é <strong>obrigatório</strong>
      </mat-error>
      <!--<mat-error *ngIf="f.passwordConfirm.hasError('erroConfirmarSenha')">
      {{erroConfirmarSenha}}
    </mat-error>-->
      <button class="border-0 mx-3 rounded-5" mat-icon-button matSuffix type="button"
              (click)="togglePasswordVisibility(2)">
        <mat-icon>{{ hidePassword2 ? 'remove_red_eye' : 'visibility_off' }}</mat-icon>
      </button>
    </mat-form-field>

    <!--<mat-form-field appearance="outline" *ngIf="inputType === 'cep'"
                  class="inputMat">
    <mat-label>{{input.label}}</mat-label>
    <input type="text" matInput
           [formControlName]="input.subProp ?input.subProp : input.prop"
           (keyup)="changeInput($event)"
           [(ngModel)]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
           [placeholder]="input.placeholder">
    <mat-error>
      {{input.label}} é <strong>obrigatório</strong>
    </mat-error>
  </mat-form-field>-->

    <div class="checkbox" *ngIf="input.tipo === 'checkbox'">
      <mat-checkbox class="example-margin"
                    [formControlName]="input.subProp ?input.subProp : input.prop"
                    [(ngModel)]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]">
        {{input.label}}
      </mat-checkbox>
    </div>

    <ng-container *ngIf="inputType === 'select'">
      <mat-form-field appearance="outline" class="inputMat">
        <mat-label>{{input.Label ? input.Label : input.placeholder}}</mat-label>
        ma
        <mat-select [formControlName]="input.subProp ?input.subProp : input.prop"
                    [placeholder]="input.placeholder"
                    (selectionChange)="changeInput($event)"
                    [(ngModel)]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
                    [compareWith]="compareSelect">

          <mat-option *ngFor="let option of input.options" [value]="option">
            {{input.subPropDesc ? option[input.propDesc][input.subPropDesc] :  option[input.propDesc]}}
          </mat-option>
        </mat-select>
        <mat-error>
          {{input.label}} é <strong>obrigatório</strong>
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="inputType === 'boleanSelect'">
      <mat-form-field appearance="outline" class="inputMat">
        <mat-label>{{input.Label ? input.Label : input.placeholder}}</mat-label>
        <mat-select [formControlName]="input.subProp ?input.subProp : input.prop"
                    [placeholder]="input.placeholder"
                    (selectionChange)="changeInput($event)"
                    [(ngModel)]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
                    [compareWith]="compareSelect">

          <mat-option *ngFor="let option of boleanSelect" [value]="option.Valor">
            {{option.Descricao}}
          </mat-option>
        </mat-select>
        <mat-error>
          {{input.label}} é <strong>obrigatório</strong>
        </mat-error>
      </mat-form-field>
    </ng-container>

    <mat-form-field appearance="outline" *ngIf="inputType === 'date'" class="inputMat">
      <mat-label>{{input.Label ? input.Label : input.placeholder}}</mat-label>
      <input matInput [matDatepicker]="agendamentoDataA" (dateChange)="changeInput($event)"
             [formControlName]="input.subProp ?input.subProp : input.prop"
             [(ngModel)]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
             [placeholder]="input.placeholder">
      <mat-datepicker-toggle matSuffix [for]="agendamentoDataA"></mat-datepicker-toggle>
      <mat-datepicker #agendamentoDataA></mat-datepicker>
      <ng-container *ngIf="input.subProp">
        <mat-error *ngIf="formDinamico.get(input.prop)
                   && formDinamico.get(input.prop)?.get(input.subProp)
                   && input.compareDate && formDinamico.get(input.prop)?.get(input.subProp)?.hasError('loaddate')">
          Vigência - Fim deve ser <strong>superior ou igual à Data de início da vigência</strong>
        </mat-error>
        <mat-error *ngIf="formDinamico.get(input.prop)
                   && formDinamico.get(input.prop)?.get(input.subProp)
                   &&  formDinamico.get(input.prop)?.get(input.subProp)?.hasError('required')">
          {{input.label}} é <strong>obrigatório</strong>
        </mat-error>
      </ng-container>
      <ng-container *ngIf="!input.subProp">
        <mat-error *ngIf="formDinamico.get(input.prop) && input.compareDate && formDinamico.get(input.prop)?.hasError('loaddate')">
          Vigência - Fim deve ser <strong>superior ou igual à Data de início da vigência</strong>
        </mat-error>
        <mat-error *ngIf="formDinamico.get(input.prop) && formDinamico.get(input.prop)?.hasError('required')">
          {{input.label}} é <strong>obrigatório</strong>
        </mat-error>
      </ng-container>
    </mat-form-field>

    <ng-container *ngIf="inputType === 'autocomplete'">
      <mat-form-field appearance="outline" style="width:100%" class="inputMat">
        <mat-label>{{input.Label ? input.Label : input.placeholder}}</mat-label>
        <input type="text" [placeholder]="input.placeholder" matInput [formControlName]="input.prop"
               (blur)="blurInput($event,input)"
               [(ngModel)]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
               [matAutocomplete]="auto" (keyup)="onChangeSearch($event, input)">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText">
          <mat-option *ngFor="let option of input.options " [value]="option">
            {{option.Descricao}}
          </mat-option>
        </mat-autocomplete>
        <mat-error>
          {{input.label}} é <strong>obrigatório</strong>
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="input.tipo == 'toggle'">
      <mat-slide-toggle (change)="changeInput($event)"
                        [formControlName]="input.subProp ?input.subProp : input.prop"
                        [(ngModel)]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
                        [class]="input.class">{{input.placeholder}}</mat-slide-toggle>
    </ng-container>

  </div>


</div>



<!--


<ng-container *ngIf="inputType === 'default' && maskInput">
  <input (keyup)="changeInput($event)"
         (blur)="blurInput($event)"
         [disabled]="disabled"
         [ngClass]="{ error: error && message, 'disabled': disabled , 'classed': backgroundInput}"
         [ngStyle]="{'background-image': backgroundInput ? 'url('+backgroundInput+')' : '', 'background-repeat':'no-repeat', 'background-position-x':'calc(100% - 10px)', 'background-position-y': '15px'}"
         [type]="type"
         [placeholder]="placeholder"
         [value]="value"
         [textMask]="{ mask: masks[maskInput], guide: false }" />
</ng-container>

<ng-container *ngIf="input.tipo == 'toggle'">
  <mat-slide-toggle (change)="changeInput($event)"
                    [checked]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
                    [class]="input.class">{{input.placeholder}}</mat-slide-toggle>
</ng-container>

<input *ngIf="inputType === 'number' && !maskInput"
       (keyup)="changeInput($event,input)"
       (blur)="blurInput($event)"
       [disabled]="disabled"
       [ngClass]="{ 'error': error && message}"
       (keypress)="apenasNumeros($event)"
       [attr.maxlength]="maxlength"
       [attr.max]="max?max:''"
       [attr.min]="min?min:''"
       [id]="id"
       type="text"
       [placeholder]="placeholder ? placeholder : '' "
       [style]="width ?'width: '+width+'px;'  : ''"
       style="text-align:center"
       [value]="value" />

<input *ngIf="inputType === 'number' && maskInput"
       (keyup)="changeInput($event,input)"
       (blur)="blurInput($event)"
       [disabled]="disabled"
       [ngClass]="{ 'error': error && message}"
       [textMask]="{ mask: masks[maskInput], guide: false }"
       (keypress)="apenasNumeros($event)"
       [attr.maxlength]="maxlength"
       [attr.max]="48"
       [attr.min]="1"
       [type]="type"
       [placeholder]="placeholder"
       [value]="value" />

<input *ngIf="inputType === 'currency'"
       (keyup)="changeInput($event,input)"
       (blur)="blurInput($event)"
       [disabled]="disabled"
       [ngClass]="{ error: error && message }"
       currencyMask
       [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
       [type]="type"
       [name]="inputType"
       [placeholder]="placeholder"
       [value]="value" />

<input *ngIf="inputType === 'email'"
       (keyup)="changeInput($event,input)"
       (blur)="blurInput($event)"
       [disabled]="disabled"
       [ngClass]="{ error: error && message }"
       type="email"
       (keydown)="suggestEmail()"
       [placeholder]="placeholder"
       [value]="value" />



<div *ngIf="suggestedEmail && inputType === 'email'" class="validadorEmail">
  <div> Você quis dizer</div>
  <div><strong>{{suggestedEmail}}?</strong></div>
  <div><a href="javascript:void(0);" (click)="onChangeEmail()">Sim</a><span>/</span><a href="javascript:void(0);" (click)="onChangeEmailNot()">Não</a></div>
</div>




<div *ngIf="inputType === 'autocomplete'" [ngClass]="{ error: error && message }" class="input__autocomplete">
  <span (click)="changeAutoCompleteFocused(false,null)"
        [ngClass]="{ show: focused }"
        class="close hidden">X</span>

  <div *ngIf="showMinChar && focused" class="minChar">
    <span>Digite 3 ou mais letras</span>
  </div>

  <ng-autocomplete [data]="options"
                   [isLoading]="autocompleteLoading"
                   [class]="input && input.class ? input.class : ''"
                   (selected)="onSelectAuto($event, input)"
                   minQueryLength="3"
                   [searchKeyword]="idOption"
                   (inputCleared)="onClearSearch(input)"
                   (inputChanged)="onChangeSearch($event, input)"
                   [itemTemplate]="itemTemplate"
                   [notFoundTemplate]="notFoundTemplate"
                   [placeHolder]="placeholder"
                   notFoundText="Não encontrado"
                   [disabled]="disabled"
                   [(ngModel)]="descInputAutoComplete"
                   (clear)="(clearAutoComplete)" descInputAutoComplete
                   (inputFocused)="changeAutoCompleteFocused(true, $event)"
                   (closed)="changeAutoCompleteClosed(input)"
                   [debounceTime]="600"
                   #auto>
  </ng-autocomplete>


  <mat-icon class="select-icon">
    keyboard_arrow_down
  </mat-icon>
  <ng-template #itemTemplate let-item>
    <a [innerHTML]="item[idOption]"></a>
  </ng-template>

  <ng-template #notFoundTemplate let-notFound>
    <div *ngIf="focused" [innerHTML]="notFound"></div>
  </ng-template>


</div>




<div class="checkbox" *ngIf="inputType === 'checkbox'">
  <input type="checkbox" (change)="changeInput($event)" [checked]="value ==1 ? true : null" [id]="id" [disabled]="disabled" />
  <label>{{ label }}</label>
</div>

<textarea *ngIf="inputType === 'textarea'"
          (blur)="blurInput($event)"
          (change)="changeInput($event)"
          [ngClass]="{ error: error && message }"
          class="text-area"
          [placeholder]="placeholder"
          rows="7"
          maxlength="250"></textarea>

<span class="messager__error" *ngIf="message">{{ message }}</span>
<div *ngIf="loading" class="loading">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>-->
