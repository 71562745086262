<div class="wrapper-content-pagamento">
  <div class="content-info-pagamento">
    <div class="info-pedido">
      <span class="material-symbols-outlined">
        fact_check
      </span>
      <div class="info-text">
        <label for="">Número do Pedido</label>
        <p>{{pedido.numeroPedido}}</p>
      </div>
    </div>
    <div class="info-value">
      <span class="material-symbols-outlined">
        payments
      </span>
      <div class="info-text">
        <label for="">Total a pagar</label>
        <p>{{totalPagar |currency :'BLR'}}</p>
      </div>
    </div>
  </div>
  <div class="content-formas-pagamento">
    <h2>Formas de pagamento</h2>
    <div class="wrapper-formas-pagamento">
      <mat-tab-group animationDuration="0ms">
        <!-- Boleto -->
        <mat-tab>
          <ng-template mat-tab-label class="item-tab">
            <span class="material-symbols-outlined ico-tab">
              barcode
            </span>
            Boleto Bancário
          </ng-template>
          <div class="prot-opcao-pg">
            <div class="prot-pagamento-formas-titulo">
              <h2>Boleto Bancário</h2>
            </div>
            <br>

            <p>
              Total a pagar {{totalPagar |currency :'BLR'}}
            </p>

            <p>
              Imprima o boleto e pague no banco. Ou pague pela internet utilizando o código de barras do boleto.
            </p>

            <p>
              O boleto pode ser pago em 5 dias.
            </p>

            <br><br>

            <!-- Caso um boleto ainda não tenha sido gerado -->
            <div>
              <button class="btn-pgtos">
                Gerar Boleto
              </button>
              <!-- <div *ngIf="gerandoBoleto" style="text-align: center; padding-top: 15px;">
                  <div class="spinner-border text-info" role="status">
                      <span class="sr-only">{{ '_globals.loading' | translate }}</span>
                  </div>
              </div> -->
            </div>

            <!-- Boleto já foi gerado -->
            <!-- <div *ngIf="dadosPagamento.DadosBoleto">

                <p>
                    {{ 'pagamento.boletoEmitidoEm' | translate }}:
                    {{
                    (dadosPagamento.DadosBoleto.dataEmissao ?
                    dadosPagamento.DadosBoleto.dataEmissao :
                    dadosPagamento.DadosBoleto.DataEmissao) | date
                    }}
                </p>
                <p>
                    {{ 'pagamento.vencimentoDoBoleto' | translate }}:
                    {{
                    (dadosPagamento.DadosBoleto.dataVencimento ?
                    dadosPagamento.DadosBoleto.dataVencimento :
                    dadosPagamento.DadosBoleto.DataVencimento) | date
                    }}
                </p>
                <p>
                    {{ 'pagamento.codigoDoBoleto' | translate }}:
                </p>
                <div class="prot-pgto-codigo">
                    {{
                    dadosPagamento.DadosBoleto?.linhaDigitavel ?
                    dadosPagamento.DadosBoleto?.linhaDigitavel :
                    dadosPagamento.DadosBoleto?.LinhaDigitavel
                    }}
                </div>

                <p style="color: orange;">
                    {{ 'pagamento.casoPagoIgnore' | translate }}.
                </p>

                Boleto gerado ainda não passou da data de vencimento
                <a *ngIf="!boletoVencido"
                    [href]="dadosPagamento.DadosBoleto.linkBoleto ? dadosPagamento.DadosBoleto.linkBoleto : dadosPagamento.DadosBoleto.LinkBoleto"
                    target="_blank">
                    <button class="btn-pgtos">
                        {{ 'pagamento.fazerDownloadDoBoleto' | translate }}
                    </button>
                </a>

                Boleto vencido
                <div *ngIf="boletoVencido">
                    <div>
                        {{ 'pagamento.casoNaoPagoGerarBoleto' | translate }}
                    </div>

                    <button class="btn-pgtos" (click)="btnGerarBoletoClick()"
                        [disabled]="gerandoBoleto">
                        {{ 'pagamento.gerarOutroBoleto' | translate }}
                    </button>

                    <div *ngIf="gerandoBoleto" style="text-align: center; padding-top: 15px;">
                        <div class="spinner-border text-info" role="status">
                            <span class="sr-only">{{ '_globals.loading' | translate }}</span>
                        </div>
                    </div>
                </div>
            </div> -->

          </div>
        </mat-tab>

        <!-- Cartão de Crédito -->
        <mat-tab>
          <ng-template mat-tab-label class="item-tab">
            <span class="material-symbols-outlined ico-tab">
              credit_card
            </span>
            Cartão de Crédido
          </ng-template>
          <div class="prot-opcao-pg">
            <div class="prot-pagamento-formas-titulo">
              <h2>Cartão de Crédito</h2>
            </div>

            <div>

              <div>
                <!--Começa cartão-->
                <!-- <div class="container preload">
                    <div >
                        <div class="front">
                            <div id="ccsingle" #ccsingle></div>
                            <svg version="1.1" id="cardfront"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 750 471"
                                style="enable-background:new 0 0 750 471;"
                                xml:space="preserve">
                                <g id="Front">
                                    <g id="CardBackground">
                                        <g id="Page-1_1_">
                                            <g id="amex_1_">
                                                <path id="Rectangle-1_1_"
                                                    class="lightcolor lightblue" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                                C0,17.9,17.9,0,40,0z" />
                                            </g>
                                        </g>
                                        <path class="darkcolor lightbluedark"
                                            d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z" />
                                    </g>
                                    <text transform="matrix(1 0 0 1 60.106 295.0121)"
                                        id="svgnumber"
                                        class="st2 st3 st4">{{dadosCredito.numeroCartao}}</text>
                                    <text transform="matrix(1 0 0 1 54.1064 428.1723)"
                                        id="svgname"
                                        class="st2 st5 st6">{{dadosCredito.nomeTitular}}</text>
                                    <text transform="matrix(1 0 0 1 54.1074 389.8793)"
                                        class="st7 st5 st8">nome
                                        do
                                        cartão</text>
                                    <text transform="matrix(1 0 0 1 479.7754 388.8793)"
                                        class="st7 st5 st8">validade</text>
                                    <text transform="matrix(1 0 0 1 65.1054 241.5)"
                                        class="st7 st5 st8">número
                                        do
                                        cartão</text>
                                    <g>
                                        <text transform="matrix(1 0 0 1 574.4219 433.8095)"
                                            id="svgexpire"
                                            class="st2 st5 st9">{{dadosCredito.validadeMes}}/{{dadosCredito.validadeAno}}</text>
                                        <text transform="matrix(1 0 0 1 479.3848 417.0097)"
                                            class="st2 st10 st11">válido</text>
                                        <text transform="matrix(1 0 0 1 479.3848 435.6762)"
                                            class="st2 st10 st11">até</text>
                                        <polygon class="st2"
                                            points="554.5,421 540.4,414.2 540.4,427.9 		" />
                                    </g>
                                    <g id="cchip">
                                        <g>
                                            <path class="st2"
                                                d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3
                            c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z" />
                                        </g>
                                        <g>
                                            <g>
                                                <rect x="82" y="70" class="st12" width="1.5"
                                                    height="60" />
                                            </g>
                                            <g>
                                                <rect x="167.4" y="70" class="st12"
                                                    width="1.5" height="60" />
                                            </g>
                                            <g>
                                                <path class="st12"
                                                    d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
                                c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
                                C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
                                c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
                                c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z" />
                                            </g>
                                            <g>
                                                <rect x="82.8" y="82.1" class="st12"
                                                    width="25.8" height="1.5" />
                                            </g>
                                            <g>
                                                <rect x="82.8" y="117.9" class="st12"
                                                    width="26.1" height="1.5" />
                                            </g>
                                            <g>
                                                <rect x="142.4" y="82.1" class="st12"
                                                    width="25.8" height="1.5" />
                                            </g>
                                            <g>
                                                <rect x="142" y="117.9" class="st12"
                                                    width="26.2" height="1.5" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Back">
                                </g>
                            </svg>
                        </div>
                        <div class="back">
                            <svg version="1.1" id="cardback"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 750 471"
                                style="enable-background:new 0 0 750 471;"
                                xml:space="preserve">
                                <g id="Front">
                                    <line class="st0" x1="35.3" y1="10.4" x2="36.7"
                                        y2="11" />
                                </g>
                                <g id="Back">
                                    <g id="Page-1_2_">
                                        <g id="amex_2_">
                                            <path id="Rectangle-1_2_"
                                                class="darkcolor lightbluedark" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                            C0,17.9,17.9,0,40,0z" />
                                        </g>
                                    </g>
                                    <rect y="61.6" class="st2" width="750" height="78" />
                                    <g>
                                        <path class="st3" d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
                        C707.1,246.4,704.4,249.1,701.1,249.1z" />
                                        <rect x="42.9" y="198.6" class="st4" width="664.1"
                                            height="10.5" />
                                        <rect x="42.9" y="224.5" class="st4" width="664.1"
                                            height="10.5" />
                                        <path class="st5"
                                            d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z" />
                                    </g>
                                    <text transform="matrix(1 0 0 1 621.999 227.2734)"
                                        id="svgsecurity"
                                        class="st6 st7">{{dadosCredito.cvc}}</text>
                                    <g class="st8">
                                        <text transform="matrix(1 0 0 1 518.083 280.0879)"
                                            class="st9 st6 st10">CVV</text>
                                    </g>
                                    <rect x="58.1" y="378.6" class="st11" width="375.5"
                                        height="13.5" />
                                    <rect x="58.1" y="405.6" class="st11" width="421.7"
                                        height="13.5" />
                                    <text transform="matrix(1 0 0 1 59.5073 228.6099)"
                                        id="svgnameback"
                                        class="st12 st13">{{dadosCredito.nomeTitular}}</text>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div> -->

              </div>

              <div class="prot-pgto-credito">
                <form [formGroup]="dadosCredito">
                  <div>
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Titular do Cartão</mat-label>
                      <input matInput id="nomeTitu" type="text" class="col-lg-6" formControlName="nomeTitular"
                             placeholder="Titular do Cartão">
                      <mat-error *ngIf="f.nomeTitular.errors?.['required']">
                        Preenchimento obrigatório
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div>
                    <div class="content-documento">
                      <mat-form-field>
                        <mat-label for="DocumentoPgto">Documento</mat-label>
                        <mat-select id="DocumentoPgto" formControlName="tipoDocumento" name="DocumentoPgto">
                          <mat-option value="CPF">CPF</mat-option>
                          <mat-option value="CNPJ">CNPJ</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div>
                      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                        <mat-label>Número do documento</mat-label>
                        <input matInput id="numDoc" type="text" class="col-lg-6" formControlName="numeroDocumento"
                               placeholder="Número do documento">
                        <mat-error *ngIf="f.numeroDocumento.errors?.['required']">
                          Preenchimento obrigatório
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div>
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Número do Cartão</mat-label>
                      <input matInput id="numCar" type="text" class="col-lg-6" formControlName="numeroCartao"
                             placeholder="Número do Cartão" [maxlength]="16">
                      <mat-error *ngIf="f.numeroCartao.errors?.['required']">
                        Preenchimento obrigatório
                      </mat-error>
                    </mat-form-field>
                    <!-- <label>Número do Cartão</label>
                    <input id="numCar" formControlName="dadosCredito.numeroCartao"
                        (change)="numeroCartaoCreditoAlterado()" mask="0000000000000099"> -->
                  </div>

                  <div>
                    <div>
                      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                        <mat-label>CVC</mat-label>
                        <input matInput id="CartCredCvc" type="text" class="col-lg-6" formControlName="cvc"
                               placeholder="CVC" [maxlength]="4">
                        <mat-error *ngIf="f.cvc.errors?.['required']">
                          Preenchimento obrigatório
                        </mat-error>
                      </mat-form-field>
                      <!-- <label>CVC</label>
                      <input id="CartCredCvc" formControlName="dadosCredito.cvc" mask="0009"
                          (focus)="focusCvv()" (focusout)="focusOutCvv()"> -->
                    </div>
                    <div class="content-validade-cartao">
                      <label>Data de vencimento</label>
                      <div class="col-md-12 col-xs-12">
                        <div class="wrapper-content">
                          <mat-form-field class="col-md-6">
                            <mat-label for="ValidMes">Selecione o Mês</mat-label>
                            <mat-select id="ValidMes" formControlName="validadeMes" name="ValidMes">
                              <mat-option value="01">Janeiro</mat-option>
                              <mat-option value="02">Fevereiro</mat-option>
                              <mat-option value="03">Março</mat-option>
                              <mat-option value="04">Abril</mat-option>
                              <mat-option value="05">Maio</mat-option>
                              <mat-option value="06">Junho</mat-option>
                              <mat-option value="07">Julho</mat-option>
                              <mat-option value="08">Agosto</mat-option>
                              <mat-option value="09">Setembro</mat-option>
                              <mat-option value="10">Outubro</mat-option>
                              <mat-option value="11">Novembro</mat-option>
                              <mat-option value="12">Dezembro</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="col-md-6 my-2 prot-tab-input-conteudo">
                            <mat-label>Ano</mat-label>
                            <input matInput id="ValidAno" type="text" formControlName="validadeAno"
                                   placeholder="CVC">
                            <mat-error *ngIf="f.cvc.errors?.['required']">
                              Preenchimento obrigatório
                            </mat-error>
                          </mat-form-field>

                        </div>

                      </div>
                      <!-- <table>
                          <tr>
                              <td>
                                  <input id="ValidMes"
                                      formControlName="dadosCredito.validadeMes">
                              </td>
                              <td><input id="2" disabled value="/"></td>
                              <td><input id="ValidAno"
                                      formControlName="dadosCredito.validadeAno"></td>
                          </tr>
                      </table> -->
                    </div>
                  </div>
                  <div>
                    <mat-form-field class="col-md-12">
                      <mat-label for="ValidMes">Parcelas</mat-label>
                      <mat-select id="ValidMes" formControlName="qtdParcelas" name="qtdParcelas">
                        <mat-option *ngFor="let item of parcelas" [value]="item.valor">{{item.descricao}}</mat-option>

                      </mat-select>
                    </mat-form-field>
                  </div>


                  <button class="btn-pgtos">
                    Pagar
                  </button>

                </form>

              </div>

            </div>

            <!-- <div *ngIf="pagandoCredito" style="text-align: center; padding-top: 15px;">

                <div class="spinner-border text-info" role="status">
                    <span class="sr-only">{{ '_globals.loading' | translate }}</span>
                </div>

            </div> -->

          </div>
        </mat-tab>

        <!-- Pix -->
        <mat-tab>
          <ng-template mat-tab-label class="item-tab">
            <span class="material-symbols-outlined ico-tab">
              qr_code_scanner
            </span>
            Pix
          </ng-template>
          <div class="prot-pgto-pix">

            <span class="w-50 my-4 text-center">Utilize o QR code abaixo para realizar o pagamento</span>

            <span class="text-center mb-4 rounded eneg-status-pix">
              AGUARDANDO PAGAMENTO
            </span>

            <div>
              <img class="img-fluid" [src]="'data:image/jpeg;base64,' + qrCodePix.qrCodeBase64" />
            </div>

            <span class="w-50 my-4 text-center">
              Ou copie o código abaixo e utilize no seu internet banking
            </span>

            <input [(ngModel)]="qrCodePix.qrCode" />

            <button class="btn-pgtos mb-4" (click)="copyMessage(qrCodePix.qrCodeBase64)">Copiar código</button>
            <p>
              Lembre-se de que, por enquanto, o Banco Central do Brasil funciona de segunda a sexta-feira das 9h às 18hs e, caso se solicite um cadastro fora desse horário, confirmaremos no próximo dia útil. Além disso, existe um limite para o valor de Pix que poderá ser movimentado no decorrer do período noturno (entre 20h e 6h) de R$1 mil. Para o período diurno (entre 6h e 20h), contudo, não há limite de movimentação.
            </p>

          </div>
        </mat-tab>
      </mat-tab-group>
      <!-- <div class="item">
        <span class="material-symbols-outlined">
          barcode
        </span>
        <label for="">Boleto Bancário</label>
      </div>
      <div class="item">
        <span class="material-symbols-outlined">
          credit_card
        </span>
        <label for="">Cartão de Crédido</label>
      </div>
      <div class="item">
        <span class="material-symbols-outlined">
          qr_code_scanner
        </span>
        <label for="">Pix</label>
      </div> -->
    </div>
  </div>

  <div class="prot-pagamento-formas">

    <!-- <div class="prot-pagamento-formas-titulo">

        <h2 *ngIf="(!gerandoBoleto) && (!dadosPagamento.DadosBoleto)">
            {{
            'pagamento.formasDePagamento' | translate
            }}
        </h2>

        <h3 [class.prot-status-aberto]="dadosPagamento.NomeStatusTransacao == 'Aberto'"
            [class.prot-status-autorizado-pago]="dadosPagamento.NomeStatusTransacao == 'Autorizado' || dadosPagamento.NomeStatusTransacao == 'Pago'"
            [class.prot-status-divergente]="dadosPagamento.NomeStatusTransacao == 'Divergente'">

            <h3 *ngIf="dadosPagamento.NomeStatusTransacao == 'Aberto'">
                {{ 'pagamento.aberto' | translate }}
            </h3>
            <h3 *ngIf="dadosPagamento.NomeStatusTransacao == 'Autorizado'">
                {{ 'pagamento.autorizado' | translate }}
            </h3>
            <h3 *ngIf="dadosPagamento.NomeStatusTransacao == 'Pago'">
                {{ 'pagamento.pago' | translate }}
            </h3>
            <h3 *ngIf="dadosPagamento.NomeStatusTransacao == 'Divergente'">
                {{ 'pagamento.divergente' | translate }}
            </h3>
        </h3>
    </div> -->
    <!-- <div class="prot-pagamento-todas">

        <div (click)="formaPagamento = 'boleto'; scrollToBottom();"
            [class.pgativo]="formaPagamento == 'boleto'">
            <img src="/assets/img/pagamento-divida-icone-boleto.svg">
            <h3>{{ 'pagamento.boletoBancario' | translate }}</h3>
        </div>

        <div (click)="formaPagamento = 'credito'; scrollToBottom();"
            *ngIf="(!gerandoBoleto) && (!dadosPagamento.DadosBoleto)"
            [class.pgativo]="formaPagamento == 'credito'">
            <img src="/assets/img/pagamento-divida-icone-credito.svg">
            <h3>{{ 'pagamento.cartaoDeCredito' | translate }}</h3>
        </div>

        <div (click)="formaPagamento = 'debito'; gerarIdPreferencia(); scrollToBottom();" [class.pgativo]="formaPagamento == 'debito'">
                    <img src="/assets/img/pagamento-divida-icone-debito.svg" >
                    <h2>** Cartão de débito</h2>
                </div>

        <div (click)="formaPagamento = 'pix'; btnPagarViaPixClick(); scrollToBottom();"
            *ngIf="(!gerandoBoleto) && (!dadosPagamento.DadosBoleto)"
            [class.pgativo]="formaPagamento == 'pix'">
            <img src="/assets/img/pagamento-divida-icone-debito.svg">
            <h3>PIX</h3>
        </div>

    </div> -->
  </div>
</div>
