import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { lista } from 'src/app/_configPage/listas/forms';


@Component({
  selector: 'app-orderDetails-15',
  templateUrl: './orderDetails15.component.html',
  styleUrls: ['../_formSteps.component.scss'] 
})
export class OrderDetails15Component implements OnInit {
  @Input() item: any
  @Output() excluirItem = new EventEmitter<any>();
  @Output() editar = new EventEmitter<any>();
  public visibilidadeDetalhes: boolean=false;
  itemDetalhes :any//= lista.find((x:any) => x.idAto == this.item.produto.idAto)

  constructor(
    public storage: LocalStorage,
    private route: Router,
  ) {

  }

  ngOnInit(): void {
    this.itemDetalhes = lista.find((x:any) => x.idAto == this.item.produto.idAto);
  }
  
  getPreco(item:any):any{
    let valor=0
    item.forEach((resultado: any) => {        
         valor += resultado.valor        
        }); 
    return valor
  }

}
