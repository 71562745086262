<div class="wrapper-item">

  <div class="wrapper-header-item">
    <h3 class="content-title">
      {{ item.produto.titulo }}
    </h3>
    <div class="wrapper-buttons">
      <div class="content-button">
        <button class="buttons-action"  (click)="editar.emit(item)">
          Editar
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/05a3523c983604fe0000ef79b0c38af35a7a81c7e6b32230af7c4a88344d432f?"
               class="img-11" />
        </button>
      </div>
      <div class="content-button">
        <button class="buttons-action" (click)="excluirItem.emit(item)">
          Excluir
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/dcdd700d816beff971594d71d60bdd298e4130c545dd466135231c4783571eb5?"
               class="img-12" />
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="w-100"></mat-divider>

  <div class="wrapper-info-cartorio">
    <div class="wrapper-topo">
      <div class="content-left">
        <span class="title-box">{{item.produto.titulo}}</span>
      </div>
      <div class="content-right">
        <span class="title-box">Valor</span>
        <p class="text-info-box">{{item.produto.valorTotal | currency:'BRL'}}</p>
      </div>
    </div>
    <ng-container *ngIf="visibilidadeDetalhes">
      <div class="content-info-solicitante">
        <div class="wrapper-info col-lg-6">
          <h3 class="title-section">Documentos anexados:</h3>
          <p class="info-section" *ngFor="let arquivo of item.documentos">
            <a [href]="arquivo?.nome" target="_blank" class="col-md-6 col-xs-12">
              {{ arquivo.nomeOriginal }}
            </a>
          </p>
        </div>

        <h2 class="title-info-solicitante">Dados dos assinadores:</h2>
        <ng-container *ngFor="let assinador of item.assinadores">
          <div class="row">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome</h3>
              <p class="info-section">{{assinador.nomeCompleto}}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Email</h3>
              <p class="info-section">{{assinador.email}}</p>
            </div>
          </div>
          <div class="row">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">CPF | CNPJ</h3>
              <p class="info-section">{{assinador.cpf | mask:'000.000.000-00||00.000.000/0000-00'}}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">telefone</h3>
              <p class="info-section">{{assinador.telefone}}</p>
            </div>
          </div>
        </ng-container>
        <h2 class="title-info-solicitante">Dados dos leitores em cópia:</h2>
        <ng-container *ngFor="let leitor of item.copia">
          <div class="row">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome</h3>
              <p class="info-section">{{leitor.nome}}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Email</h3>
              <p class="info-section">{{leitor.email}}</p>
            </div>
          </div>

        </ng-container>
      </div>
    </ng-container>
  </div>
  <button class="view-more-details" (click)="visibilidadeDetalhes=!visibilidadeDetalhes">
    {{ visibilidadeDetalhes ? 'Ocultar detalhes' : 'Ver detalhes' }}
  </button>
</div>
