import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-v1',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponentV1 {
  @Input() data: any;

}
