<form [formGroup]="camposForm">


  <div class="row">
    <div class="col-md-12">
      <mat-form-field class="w-100">
        <mat-label for="cartorio">Cartórios</mat-label>
        <mat-select id="cartorio" name="cartorio" [compareWith]="compareCartorio" formControlName="cartorio" [(value)]="cartorioSelecionado" (selectionChange)="buscarValor()">
          <mat-option *ngFor="let item of cartorioEmissor" [value]="item">{{ item.cartorio }}</mat-option>
        </mat-select>
        <mat-error>
            Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>
  </div> 
  <div class="row">
    <div class="col-md-6">
      <mat-label>Apostilamento? </mat-label>
      <mat-radio-group formControlName="apostilamento" (change)="buscarValor()" [(ngModel)]="apostilamento">
        <mat-radio-button value="true">Sim</mat-radio-button>
        <mat-radio-button value="false">Não</mat-radio-button>
      </mat-radio-group>
    </div>
  </div> <br />
  <ng-container *ngIf="itemAtual?.subFormTipo == 'Nascimento'">
    <div class="col-md-12 text-center">
      <div class="row">
        <div class="content-list">
          <h3 class="mb-0">
            Saiba como localizar os dados do cartório no <button (click)="abrirImagemPopupRG()">RG</button> ou
            <button (click)="abrirImagemPopupCertidao()">
              Certidão de Nascimento
            </button>
          </h3>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="itemAtual?.subFormTipo == 'Casamento'">
    <div class="col-md-12 text-center">
      <div class="row">
        <div class="content-list">
          <h3 class="mb-0">
            Saiba como localizar os dados do cartório no <button (click)="abrirImagemPopupRG()">RG</button> ou
            <button (click)="abrirImagemPopupCertidao()">
              Certidão de Casamento
            </button>
          </h3>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="itemAtual?.subFormTipo == 'Óbito'">
    <div class="col-md-12 text-center">
      <div class="row">
        <div class="content-list">
          <h3 class="mb-0">
            Saiba como localizar os dados do cartório no <button (click)="abrirImagemPopupRG()">RG</button> ou
            <button (click)="abrirImagemPopupCertidao()">
              Certidão de Óbito
            </button>
          </h3>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="itemAtual?.subFormTipo == 'Nascimento'">
    <div class="row">
      <div class="col-md-12">
        <h2 class="text-center">Certidão de Nascimento</h2>
      </div>
    </div>

    <!--certidao nascimento-->
    <div class="content-list" formArrayName="certidaoNascimento">
      <ng-container *ngFor="let item of certidaoNascimento.controls; let i = index" [formGroupName]="i" class="row">
        <div class="row">
          <div class="col-md-8">
            <!--Nome Completo-->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Nome Completo</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="nomeCompleto"
                     placeholder="Nome Completo" maxlength="400">
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-4">
            <!--cpf-->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="cpf">CPF</mat-label>
              <input matInput type="text" mask="000.000.000-00" formControlName="cpf" name="cpf">
            </mat-form-field>
          </div>

        </div>

        <div class="row">
          <div class="col-md-12">
            <!--Nome Mãe-->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Nome Completo da Mãe</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="mae"
                     placeholder="Nome Completo da Mãe" maxlength="400">
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!--data nascimento-->
        <div class="row">
          <div class="col-md-3">
            <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="nascimento">Data de Nascimento</mat-label>
              <input matInput [matDatepicker]="picker" type="text" formControlName="nascimento"
                     name="nascimento" appMaskDate maxlength="10">
              <mat-datepicker-toggle matIconSuffix [for]="picker" max="2024-02-29"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="row">
            <div class="content-list" [class]="classObrigatoriaMatricula">
              <div class="col-md-12">
                <div class="row ">
                  <div class="col-md-4">
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Número do Livro</mat-label>
                      <input matInput type="text" class="col-lg-12" formControlName="livro"
                             placeholder="Número do Livro" maxlength="20">
                      <mat-error>Preenchimento obrigatório</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Número da Folha</mat-label>
                      <input matInput type="text" class="col-lg-12" formControlName="folha"
                             placeholder="Número da Folha" maxlength="20">
                      <mat-error>Preenchimento obrigatório</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Número do Termo</mat-label>
                      <input matInput type="text" class="col-lg-12" formControlName="termo"
                             placeholder="Número do Termo" maxlength="20">
                      <mat-error>Preenchimento obrigatório</mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mt-3 text-center">
                    <p class="mb-0">OU</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Número da Matrícula</mat-label>
                      <input matInput type="text" class="col-lg-12" formControlName="matricula"
                             placeholder="Número da Matrícula" (change)="validarLivroFolhaTermoNascimento()" maxlength="50">
                      <mat-error>Preenchimento obrigatório</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </ng-container>
    </div>

  </ng-container>

  <ng-container *ngIf="itemAtual?.subFormTipo == 'Casamento'">
    <div class="row">
      <div class="col-md-12">
        <h2 class="text-center">Certidão de Casamento</h2>
      </div>
    </div>

    <!--certidao Casamento-->
    <div class="content-list" formArrayName="certidaoCasamento">
      <ng-container *ngFor="let item of certidaoCasamento.controls; let i = index" [formGroupName]="i" class="row">
        <div class="row">
          <div class="col-md-8">
            <!--Nome Completo Conjuge 1-->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Nome Completo Cônjuge 1</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="conjuge"
                     placeholder="Nome Completo Cônjuge 1" maxlength="400">
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-4">
            <!--cpf-->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="cpf">CPF Cônjuge 1</mat-label>
              <input matInput type="text" mask="000.000.000-00" formControlName="cpf" name="cpf">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <!--Nome Completo  Conjuge 2-->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Nome Completo Cônjuge 2</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="conjuge2"
                     placeholder="Nome Completo Cônjuge 2" maxlength="400">
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-4">
            <!--cpf-->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="cpf">CPF Cônjuge 2</mat-label>
              <input matInput type="text" mask="000.000.000-00" formControlName="cpf2" name="cpf2">
            </mat-form-field>
          </div>
        </div>

        <!--data casamento-->
        <div class="row">
          <div class="col-md-3">
            <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="nascimento">Data de Casamento</mat-label>
              <input matInput appMaskDate maxlength="10" [matDatepicker]="picker" type="text" formControlName="dataCasamento"
                     name="dataCasamento">
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="row">
            <div class="content-list" [class]="classObrigatoriaMatricula">
              <div class="col-md-12">
                <div class="row ">
                  <div class="col-md-4">
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Número do Livro</mat-label>
                      <input matInput type="text" class="col-lg-12" formControlName="livro"
                             placeholder="Número do Livro" maxlength="20">
                      <mat-error>Preenchimento obrigatório</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Número da Folha</mat-label>
                      <input matInput type="text" class="col-lg-12" formControlName="folha"
                             placeholder="Número da Folha" maxlength="20">
                      <mat-error>Preenchimento obrigatório</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Número do Termo</mat-label>
                      <input matInput type="text" class="col-lg-12" formControlName="termo"
                             placeholder="Número do Termo" maxlength="20">
                      <mat-error>Preenchimento obrigatório</mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mt-3 text-center">
                    <p class="mb-0">OU</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Número da Matrícula</mat-label>
                      <input matInput type="text" class="col-lg-12" formControlName="matricula"
                             placeholder="Número da Matrícula" maxlength="50" (change)="validarLivroFolhaTermoNascimento()">
                      <mat-error>Preenchimento obrigatório</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="itemAtual?.subFormTipo == 'Óbito'">
    <div class="row">
      <div class="col-md-12">
        <h2 class="text-center">Certidão de Óbito</h2>
      </div>
    </div>
    <!--certidao Óbito-->
    <div class="content-list" formArrayName="certidaoObito">
      <ng-container *ngFor="let item of certidaoObito.controls; let i = index" [formGroupName]="i" class="row">
        <div class="row">
          <div class="col-md-8">
            <!--Nome Completo-->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Nome Completo</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="nome"
                     placeholder="Nome Completo" maxlength="400">
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-4">
            <!--cpf-->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="cpf">CPF</mat-label>
              <input matInput type="text" mask="000.000.000-00" formControlName="cpf" name="cpf">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!--Nome Pai-->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Nome Completo Pai na Certidão</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="pai"
                     placeholder="Nome Completo Pai na Certidão" maxlength="400">
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!--Nome mae-->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Nome Completo Mãe na Certidão</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="mae"
                     placeholder="Nome Completo Mãe na Certidão" maxlength="400">
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>
        </div>


        <!--data obito-->
        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="nascimento">Data de Óbito</mat-label>
              <input matInput appMaskDate maxlength="10" [matDatepicker]="picker" type="text" formControlName="dataObito"
                     name="dataObito">
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="row">
            <div class="content-list" [class]="classObrigatoriaMatricula">
              <div class="col-md-12">
                <div class="row ">
                  <div class="col-md-4">
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Número do Livro</mat-label>
                      <input matInput type="text" class="col-lg-12" formControlName="livro"
                             placeholder="Número do Livro" maxlength="20">
                      <mat-error>Preenchimento obrigatório</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Número da Folha</mat-label>
                      <input matInput type="text" class="col-lg-12" formControlName="folha"
                             placeholder="Número da Folha" maxlength="20">
                      <mat-error>Preenchimento obrigatório</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Número do Termo</mat-label>
                      <input matInput type="text" class="col-lg-12" formControlName="termo"
                             placeholder="Número do Termo" maxlength="20">
                      <mat-error>Preenchimento obrigatório</mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mt-3 text-center">
                    <p class="mb-0">OU</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Número da Matrícula</mat-label>
                      <input matInput type="text" class="col-lg-12" formControlName="matricula"
                             placeholder="Número da Matrícula" maxlength="50" (change)="validarLivroFolhaTermoNascimento()">
                      <mat-error>Preenchimento obrigatório</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </ng-container>
    </div>
  </ng-container>



  <!--DADOS ENTREGA-->
  <div class="row">
    <div class="col-md-12">
      <h2 class="text-center">Dados para Entrega</h2>
    </div>
  </div>

  <div class="content-list" formGroupName="dadosEntrega">
    <ng-container class="row">
      <div class="row">
        <div class="col-md-12">
          <!--Nome Destinatário-->
          <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
            <mat-label>Nome Destinatário</mat-label>
            <input matInput type="text" class="col-lg-12" formControlName="nomeDestinatario"
                   placeholder="Nome Destinatário" maxlength="100">
            <mat-error>
              Preenchimento obrigatório
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
            <mat-label>País</mat-label>
            <input matInput type="text" formControlName="pais" readonly value="Brasil">
          </mat-form-field>
        </div>
        <div class="col-md-3 col-xs-12">
          <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
            <mat-label for="cep">CEP</mat-label>
            <input matInput type="text" mask="00000-000" formControlName="cep" name="cep"
                   (keyup)="buscarEndereco($event)">
            <mat-error>
              Preenchimento obrigatório
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
            <mat-label>Estado</mat-label>
            <input type="text"
                   placeholder="Estado"
                   aria-label="Number"
                   matInput
                   formControlName="uf"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProperty" (optionSelected)="getCidade($event)">
              <mat-option *ngFor="let item of listufFilter" [value]="item">
                {{item.Nome }}
              </mat-option>
            </mat-autocomplete>
            <mat-error>
              Preenchimento obrigatório
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
            <mat-label>Cidade</mat-label>
            <input type="text"
                   #cidadeInterna
                   id="cidadeInterna"
                   placeholder="Cidade"
                   aria-label="Number"
                   matInput
                   formControlName="cidade"
                   [matAutocomplete]="auto2">
            <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayPropertyCidade" (optionSelected)="setLocal()">
              <mat-option *ngFor="let item of listCidadeFilter" [value]="item">
                {{item.Nome }}
              </mat-option>
            </mat-autocomplete>
            <mat-error>
              Preenchimento obrigatório
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">

        <div class="col-md-6">
          <!--Endereco-->
          <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
            <mat-label>Endereço</mat-label>
            <input matInput type="text" class="col-lg-12" formControlName="endereco"
                   placeholder="Endereço" maxlength="200">
            <mat-error>
              Preenchimento obrigatório
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <!--numero-->
          <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
            <mat-label>Número</mat-label>
            <input matInput type="text" class="col-lg-12" formControlName="numero"
                   placeholder="Número" maxlength="5">
            <mat-error>
              Preenchimento obrigatório
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!--Bairro-->
          <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
            <mat-label>Bairro</mat-label>
            <input matInput type="text" class="col-lg-12" formControlName="bairro"
                   placeholder="Bairro" maxlength="50">
            <mat-error>
              Preenchimento obrigatório
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">

      </div>

      <div class="row">

      </div>

      <div class="row">
        <div class="col-md-12">
          <!--Complemento-->
          <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
            <mat-label>Complemento</mat-label>
            <input matInput type="text" class="col-lg-12" formControlName="complemento"
                   placeholder="Complemento" maxlength="150">
            <mat-error>
              Preenchimento obrigatório
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-container>

  </div>

  <div class="row">
    <div class="col-md-12">
      <h2 class="text-center">Valor da Certidão</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 m-auto">
      <div class="content-list">
        <p class="text-center fw-bold" id="valorCartorioSelcionado">
          R$ 0,00
        </p>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="content-list">
      <div class="col-md-12">
        <p class="mb-0">*O valor da certidão pode variar e a diferença será comunicada para aprovação do solicitante</p>
        <p class="mb-0">*O eCARTÓRIO se reserva no direito de distribuir e apresentar este pedido para ser materializado em qualquer cartório de Registro Civil de Pessoas Naturais do Brasil</p>
      </div>
    </div>
  </div>

  <app-aviso [aviso]="itemAtual?.aviso" [classObrigatoriaAviso]="classObrigatoriaAviso" (temAceite)="temAceite($event)"></app-aviso>

  <div class="row">
    <div class="col-md-5"></div>
    <div class="col-auto" *ngIf="!edicao">
      <button type="button" mat-button [routerLink]="['/']" class="btn-next" style="color: white; background-color: green;">Voltar</button>
    </div>
    <div class="col-auto" *ngIf="edicao">
      <button type="button" mat-button (click)="cancelarEdicaoForm()" class="btn-next" style="color: white; background-color: green;">Cancelar</button>
    </div>
    <div class="col-auto">
      <button mat-button (click)="updatePedido()" class="btn-next"
              style="display: block; margin: 0 auto;">
        <span *ngIf="!edicao">Continuar</span>
        <span *ngIf="edicao">Salvar</span>
      </button>
    </div>
  </div>
</form>
