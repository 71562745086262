import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-meusPedidosOrderDetails-2',
  templateUrl: './meusPedidosOrderDetail2.component.html',
  styleUrls: ['./../_meus-pedidos.component.scss']
})
export class MeusPedidosOrderDetails2Component  {
  @Input() compra: any
  public visibilidadeDetalhes: boolean=false;
  @Output() updatePedido = new EventEmitter<any>();

  ngOnInit(): void {
    //console.log('compra2', this.compra)
  }

  cancelarEdicao(compra: any) {
    compra.editar = false
  }

  salvarItem(itemPedido: any) {
    this.updatePedido.emit(itemPedido)
  }
  
}
