import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit, HostListener } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorage } from '../../_entidade/LocalStorage';
import { environment } from 'src/environments/environment';
import { CrudService } from '../../_sevice/CrudService';
import { GenericValidator } from 'src/app/_sevice/GenericValidator';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TesteService } from 'src/app/_sevice/teste.service';
import { localidade } from 'src/app/_configPage/listas/localidade';

@Component({
  selector: 'app-cadastre-se',
  templateUrl: './cadastre-se.component.html',
  styleUrls: ['./cadastre-se.component.scss']
})


export class CadastroComponent implements OnInit {
  @Input() conteudo: any;
  @Input() edicao: boolean = false;
  @Input() dadosAtualizados: boolean = false;

  password = true;
  confirmPassword = true;
  dataFormatada: string = '';
  hideDataNasc: boolean = false;

  form: FormGroup;

  listuf: any[] = localidade.listuf
  listufFilter: any = this.listuf
  listCidade: any[]
  listCidadeFilter: any
  ufSelected = this.storage.localStorageGet('localidade')?.uf || { "Id": "65b11f81c0095aa0cc393545", "IdEstado": 19, "Sigla": "RJ", "Nome": "Rio de Janeiro" }
  cidadeSelected = this.storage.localStorageGet('localidade')?.cidade || { "Id": "65b11fe7c0095aa0cc394397", "IdCidade": "3658", "IdEstado": "19", "Nome": "Rio de Janeiro" }

  @Input() relacionarCompra: any = false

  errorAceite: any = false

  camposForm = this.formBuilder.group(
    {
      nome: ['', Validators.required],
      cnpjCpf: ['', Validators.compose([Validators.required, GenericValidator.isValidCPFCNPJ()])],
      nascimento: ['', Validators.required],
      celular: ['', Validators.required],
      telFixo: [''],
      email: ['',
        Validators.compose(
          [
            Validators.required,
            Validators.email
          ]
        )
      ],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      profissao: ['', Validators.required],
      dadosCobranca: this.createDadosCobranca()
    }, { validator: this.passwordsMatchValidatorv2.bind(this) });

  createFormCadastro() {
    this.camposForm = this.formBuilder.group(
      {
        nome: ['', Validators.required],
        cnpjCpf: ['', Validators.compose([Validators.required,GenericValidator.isValidCPFCNPJ()])],
        nascimento: ['', Validators.required],
        celular: ['', Validators.required],
        telFixo: [''],
        email: ['',
          Validators.compose(
            [
            Validators.required,
              Validators.email
            ]
          )
        ],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
        profissao: ['', Validators.required],
        dadosCobranca: this.createDadosCobranca()
      }, { validator: this.passwordsMatchValidatorv2.bind(this) });

  }

  validateIsCnpj() {

    let cnpjCpfLength = this.camposForm.get(['cnpjCpf'])?.value?.length;

    if (cnpjCpfLength == 14)
    {
      this.camposForm.get(['nascimento'])?.removeValidators([Validators.required]);
      this.hideDataNasc = true;
    }
    else
    {
      this.camposForm.get(['nascimento'])?.addValidators(Validators.required);
      this.hideDataNasc = false;
    }
    this.camposForm.get(['nascimento'])?.updateValueAndValidity();
  }
 
  createFormAtualizacao() {
    this.camposForm = this.formBuilder.group({
      nome: ['', Validators.required],
      cnpjCpf: ['', Validators.compose([
        Validators.required,
        GenericValidator.isValidCPFCNPJ()
      ])],
      nascimento: ['', Validators.required],
      celular: ['', Validators.required],
      telFixo: [''],
      email: [
        '',
        Validators.compose(
          [
            Validators.required,
            Validators.email
          ]
        )
      ],
      profissao: ['', Validators.required],
      dadosCobranca: this.createDadosCobrancaAtualizacao(),
    }, { validator: this.passwordsMatchValidatorv2.bind(this) });

  }
  createDadosCobranca(): FormGroup {
    return this.formBuilder.group({
      nome: ['', Validators.required],
      cnpjCpf: ['', Validators.compose([
        Validators.required,
        GenericValidator.isValidCPFCNPJ()
      ])],
      cep: ['', Validators.required],
      uf: ['', Validators.required],
      cidade: ['', Validators.required],
      bairro: ['', Validators.required],
      tipoLogradouro: ['', Validators.required],
      logradouro: ['', Validators.required],
      numero: ['', Validators.required],
      complemento: [''],
      aceiteTermos: ['', Validators.required]
    });

  }

  createDadosCobrancaAtualizacao(): FormGroup {
    return this.formBuilder.group({
      nome: ['', Validators.required],
      cnpjCpf: ['', Validators.compose([
        Validators.required,
        GenericValidator.isValidCPFCNPJ()
      ])],
      cep: ['', Validators.required],
      uf: ['', Validators.required],
      cidade: ['', Validators.required],
      bairro: ['', Validators.required],
      tipoLogradouro: ['', Validators.required],
      logradouro: ['', Validators.required],
      numero: ['', Validators.required], 
      complemento: [''],
    });

  }


  formCadastro = this.formBuilder.group({
    cpf: ['', Validators.required],
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required]
  });


  ngOnInit() {
    this.service.ajustaSelect()

    if (document.location.href.indexOf('/admin/meus-dados') > -1) {
      this.createFormAtualizacao()

      //this.carregaUsuarioANR()
      this.carregaUsuario()

      this.edicao = true;
    }
    else
    {
      this.createFormCadastro()
    }

    this.uf?.valueChanges.subscribe((newValue: any) => {
      this.listufFilter = this._filter(newValue);
    })
    this.cidade?.valueChanges.subscribe((newValue: any) => {
      this.listCidadeFilter = this._filterCidade(newValue);
    })

  }

  togglePassword(field: string) {
    if (field === 'password') {
      this.password = !this.password;
    } else if (field === 'confirmPassword') {
      this.confirmPassword = !this.confirmPassword;
    }
  }


  constructor(
    private formBuilder: FormBuilder
    , public router: Router
    , public storage: LocalStorage
    , public service: CrudService
    , public teste: TesteService
    , public st: LocalStorage

    , @Inject(DOCUMENT) private document: any) {

    this.formCadastro = this.formBuilder.group({
      cpf: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, { validator: this.passwordsMatchValidator });

    this.formCadastro.get('cpf')?.setValue('');

  }


  get dadosCobranca(): any {

    return this.camposForm.get('dadosCobranca')
  }


  getDadosCobrancaControl(index: number): FormGroup {
    return (this.camposForm.get('dadosCobranca') as FormArray).at(index) as FormGroup;
  }

  get f() {
    return this.formCadastro.controls;
  }

  passwordsMatchValidator(formGroup: FormGroup) {
    const passwordControl = formGroup.get('password');
    const confirmPasswordControl = formGroup.get('confirmPassword');

    if (passwordControl && confirmPasswordControl) {
      const password = passwordControl.value;
      const confirmPassword = confirmPasswordControl.value;

      if (password !== confirmPassword) {
        confirmPasswordControl.setErrors({ passwordsNotMatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    }
  }

  passwordsMatchValidatorv2(formGroup: FormGroup): ValidationErrors | null {
    const password = formGroup.get('password')?.value;
    const confirmPassword = formGroup.get('confirmPassword')?.value;
    if (password !== confirmPassword) {
      formGroup.get('confirmPassword')?.setErrors({ passwordsNotMatch: true });
      return { passwordsNotMatch: true }; // Retorna um objeto de erro se as senhas não coincidirem
    } else {
      formGroup.get('confirmPassword')?.setErrors(null); // Limpa os erros se as senhas coincidirem
    }
    return null; // Retorna nulo se não houver erro
  }

  copiarDadosPessoais(event: MatCheckboxChange): void {
    if (event.checked) {
      // Se o checkbox está marcado, copia os valores
      const nome = this.camposForm.get('nome')?.value;
      const cnpjCpf = this.camposForm.get('cnpjCpf')?.value;

      this.dadosCobranca.get('nome')?.setValue(nome);
      this.dadosCobranca.get('cnpjCpf')?.setValue(cnpjCpf);
    } else {
      // Se o checkbox está desmarcado, limpa os valores
      this.dadosCobranca.get('nome')?.setValue('');
      this.dadosCobranca.get('cnpjCpf')?.setValue('');
    }
  }

  confirmarAceite(event: MatCheckboxChange): void {
    if (event.checked) {
      this.errorAceite = false 
    }
    else {
      this.errorAceite = true 
    }
  }


  setTipoLogradouro(logra: any) {
    if (logra.indexOf('Rua') == 0) {
      this.dadosCobranca.get('tipoLogradouro')?.setValue('RUA');

    } else if (logra.indexOf('Av. ') == 0 || logra.indexOf('Avenida') == 0) {
      this.dadosCobranca.get('tipoLogradouro')?.setValue('AVENIDA');

    } else if (logra.indexOf('Alameda') == 0) {
      this.dadosCobranca.get('tipoLogradouro')?.setValue('ALAMEDA');

    }
  }
  buscarEndereco($event: any) {
    let cep = $event.target.value.replace('-', '')
    if (cep.length == 8) {
      this.service.getObservableDirect('https://viacep.com.br/ws/' + cep + '/json').subscribe(
        {
          next: async (response: any) => {
            if (!response['erro']) {
              let logLimpo = response.logradouro.replace('Rua ', '').replace('Av. ', '').replace('Avenida ', '').replace('Alameda ', '')

              this.dadosCobranca.get('logradouro')?.setValue(logLimpo)
              this.dadosCobranca.get('bairro')?.setValue(response.bairro)
              this.ufSelected = localidade.listuf.find((x: any) => x.Sigla == response.uf)
              this.dadosCobranca.get('uf')?.setValue(this.ufSelected)
              this.loadCidade(this.ufSelected.IdEstado, true, response.localidade)
              this.setTipoLogradouro(response.logradouro)
            } else {

              this.dadosCobranca.get('cep').setErrors({ cepNotMatch: true });

            }

          },
          error: (err) => {

            console.error(err);
          }
        }
      )

    }
  }

  incluir() {

    if (this.formCadastro.valid) {
      let usuario = {
        login: this.f.cpf.value,
        senha: 123,
        perfil: ''
      }
      //console.log(usuario)

      if (usuario.login == 'admin')
        usuario['perfil'] = 'admin'
      else
        usuario['perfil'] = 'usuario'



      this.service.postObservable(usuario, 'usuario').subscribe(
        (resultado) => {
          let pedido = this.storage.localStorageGet("pedido")
          pedido.UserId = this.f.cpf.value
          pedido.PendenteAdmin = true
          pedido['numeroPedido'] = new Date().valueOf()

          let url = 'pedidos'
          this.service.putObservable(url + '/' + pedido.id, pedido).subscribe(
            (resultado1: any) => {
              this.storage.localStorageSet("userlogged", resultado)
              this.storage.localStorageSet("logado", true)
              this.router.navigate(['/admin']);
            },
            (erro) => {
              //console.log(erro)
            }
          )
        },
        (erro) => {
          //console.log(erro)
        }
      )
    }
  }

  submitFormCadastro() {
    this.service.getObservable('usuario?login=' + this.f.cpf.value).subscribe(
      {
        next: async (response: any) => {
          if (response.length == 0) {
            this.incluir()
          } else {
            const loginPasswordControl = this.f.cpf;
            loginPasswordControl.setErrors({ usuarioExistente: true });

          }

        },
        error: (err) => {

          console.error(err);
        }
      }

    )

  }

  get uf() {
    return this.dadosCobranca.get('uf')
  }

  get cidade() {
    return this.dadosCobranca.get('cidade')
  }
  erroCadastro:any=null
  cadastroUsuario() {
    this.camposForm.markAllAsTouched();
    if (this.dadosCobranca.get('aceiteTermos').invalid) {
      this.errorAceite = true
    } else {
      this.errorAceite = false

    }
    if (this.camposForm.valid) {
      let url = environment.http.API_AUTH + 'v1/account/login/cadastrar'

      //
      let dt = this.camposForm.get('nascimento')?.value;
      let dataNascimento = new Date(dt);
      let ano = dataNascimento.getFullYear().toString();
      let mes = (dataNascimento.getMonth() + 1).toString().padStart(2, '0'); 
      let dia = dataNascimento.getDate().toString().padStart(2, '0'); 
      let nascimentoFormatado = ano +'-'+ mes +'-'+ dia;
      this.camposForm.get('nascimento')?.setValue(nascimentoFormatado);

      //console.log('validacao', this.camposForm.value);

      let it = this.camposForm.value;

      let usuario = {
        Login: it.email,
        Senha: it.password,
        Nome: it.nome
      };

      if (it.cnpjCpf.length == 14) {
        delete it['nascimento']
      }

      (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

      this.service.postObservableDirect(url, it).subscribe({
        next: async (response) => {
          if (response != null && response.error == 0) {

            (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%";

            this.storage.localStorageSet("userlogged", response['returnData'])
            this.storage.localStorageSet("logado", true)
            this.storage.localStorageRemove("BackOffice")
            
            let ped = this.storage.localStorageGet("pedido")

            if (ped != null && ped.guidUser == null) {
              document.location.href = '/pedido/meu-carrinho'
            } else {
              this.router.navigate(['/admin/meus-pedidos']);

            }

          } else {
            this.erroCadastro = response.messageError

            //console.log(response)
          }
        }, error: (err) => {


          console.error(err);
        }
      });


      //console.log(this.camposForm.value, "teste form")
    }
  }


  alterarUsuario() {
    let dt = this.camposForm.get('nascimento')?.value;
    let dataNascimento = new Date(dt);
    let ano = dataNascimento.getFullYear().toString();
    let mes = (dataNascimento.getMonth() + 1).toString().padStart(2, '0'); 
    let dia = dataNascimento.getDate().toString().padStart(2, '0'); 
    let nascimentoFormatado = ano + '-' + mes + '-' + dia;
    this.camposForm.get('nascimento')?.setValue(nascimentoFormatado);


    //Verifica termo de aceite
    var termoAceito = this.dadosCobranca.get('aceiteTermos')?.value
    if ((termoAceito == false) || (termoAceito == null)) {
      this.dadosCobranca.get('aceiteTermos')?.setValue(true)
    }

    ///
    this.camposForm.markAllAsTouched();

    if (this.camposForm.valid) {

      (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

      let keyuser = this.storage.localStorageGet('userlogged')?.keyLogin;

      let url = environment.http.API_AUTH + `v1/account/login/alterar/${keyuser}`
      let it = this.camposForm.value

      delete it['password']
      delete it['confirmPassword']

      it['guidUser'] = keyuser

      if (it.cnpjCpf.length == 14) {
        delete it['nascimento']
      }

      //Atualiza o Nome do usuario Logado.
      let newUser = this.storage.localStorageGet('userlogged');
      newUser.nomeUsuario = this.camposForm.get('nome')?.value
      this.storage.localStorageSet("userlogged", newUser)
      //

      this.service.putObservableDirect(url, it).subscribe({
        next: async (response) => {
          if (response.returnData == 'OK') {
            this.dadosAtualizados = true;
          }
        }, error: (err) => {
          console.error(err);
        }
      });

      


    }
  }


  alterarSenha() {
    this.router.navigate(['/institucional/redefinicao-senha'])
  }


  voltarMeusDados() {
    this.dadosAtualizados = false;
    window.location.reload();
  }

  irMeusPedidos() {
    this.dadosAtualizados = false;
    //window.location.reload();
    this.router.navigate(['/admin/meus-pedidos']);
  }


  public displayProperty(value: any) {
    if (value) {
      return value.Nome;
    }
  }

  public displayPropertyCidade(value: any) {

    if (value) {
      return value.Nome;
    }
  }

  getCidade(event: any) {

    this.setLocal()

    this.loadCidade(event.option.value['IdEstado'])
    this.ufSelected = this.uf?.value

    if (!event.isUserInput) {
      this.dadosCobranca.get('cidade')?.setValue(null);
    }

  }

  setLocal() {

    this.cidadeSelected = this.cidade?.value
  }

  loadCidade(IdEstado: any, setCidade: boolean = false, strCidade: string = '') {
    const url = environment.http.API + 'Localidade/cidade?IdEstado=' + IdEstado;

    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {
        if (response != null) {
          this.camposForm.get('cidade')?.setValue(null)

          this.listCidadeFilter = response;
          this.listCidade = response;
          if (setCidade) {

            let cit = response.find((x: any) => x.Nome.toLowerCase() == strCidade.toLowerCase())
            this.dadosCobranca.get('cidade')?.setValue(cit);
          }
        }
      }, error: (err) => {

        console.error(err);
      }
    });
  }

  carregaUsuario() {

    let keyuser = this.storage.localStorageGet('userlogged')?.keyLogin

    let url = environment.http.API_AUTH + `v1/account/usuario/buscarusuario/${keyuser}`

    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {

        if (response != null) {

          let objetoJson = JSON.parse(response.dadosComplementares)

          delete objetoJson?.password;
          delete objetoJson?.confirmPassword;
          delete objetoJson?.guidUser
          delete objetoJson?.dadosCobranca?.aceiteTermos;

          if (objetoJson?.cnpjCpf.length === 14) {
            let new_nascimento = '1900-01-01';

            response.nascimento = new_nascimento
            objetoJson.nascimento = new_nascimento
          }

          this.camposForm.setValue(objetoJson)
          this.validateIsCnpj()

        }
        else {
          this.carregaUsuarioANR() 
        }
      }, error: (err) => {

        console.error(err);
      }
    });
  }

  carregaUsuarioANR() {
    let keyuser = this.storage.localStorageGet('userlogged')?.keyLogin;

    let url = environment.http.API_BACK+ 'Usuario/Usuario?guidUser=' + keyuser;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {
        
        if ((response != null) && (response.cnpjCpf != null)) {
          delete response['guidUser']
          delete response['password']
          delete response['dadosCobranca']['idUsuarioFaturacao']
          delete response['dadosCobranca']['email']
          //
          this.camposForm.setValue(response)
          //
          this.validateIsCnpj()
          //

          let _infoUf = response.dadosCobranca?.uf.Sigla

          let uf = this.listuf.find((x: any) => x.Sigla === _infoUf);

          if (uf) {
            this.loadCidade(uf.IdEstado, true, response.dadosCobranca.cidade.Nome);
            this.dadosCobranca.get('uf')?.setValue(uf);
          } else {
            console.error('UF não encontrada.');
          }
        }
      }, error: (err) => {

        console.error(err);
      }
    });
  }

  compareUF(object1: any, object2: any) {
    //console.log(object1, object2)

    return object1 && object2 && object1.id == object2.id;
  }

  private _filter(value: any): any {

    if (value && typeof value === "string")
      return this.listuf.filter((x) => x.Nome.toLowerCase().indexOf(value.toLowerCase()) == 0)
    else
      return this.listuf
  }

  private _filterCidade(value: any): any {

    if (value && typeof value === "string")
      return this.listCidade?.filter((x) => x.Nome.toLowerCase().indexOf(value.toLowerCase()) == 0)
    else
      return this.listCidade
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {
      //  //console.log(this.camposForm)

      this.teste.setValueForm(this.camposForm);
      this.teste.setValueForm(this.dadosCobranca);



    }
  }

}
