<div class="wrapper-item" *ngIf="!compra?.editar">

  <div class="row">
    <div class="col-md-6">
      <div class="wrapper-info">
        <h3 class="info-section"><b>UF</b></h3>
        <p class="info-section">{{compra.uf.Sigla}}</p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="wrapper-info">
        <h3 class="info-section"><b>Cidade</b></h3>
        <p class="info-section">{{compra.cidade.Nome}}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="wrapper-info">
        <h3 class="info-section"><b>Tipo de Registro</b></h3>
        <p class="info-section">{{compra.tipoSolicitacao}}</p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="wrapper-info">
        <h3 class="info-section"><b>Tipo de Documento</b></h3>
        <p class="info-section">{{compra.tipoDocumento}}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="wrapper-info">
        <h3 class="info-section"><b>Cartório</b></h3>
        <p class="info-section">{{compra.cartorio}}</p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="wrapper-info">
        <h3 class="info-section"><b>Informação Adicional</b></h3>
        <p class="info-section">{{compra.informacaoAdicional}}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Documentos para Registro</b></h3>
      <p class="info-section" *ngFor="let arquivo of compra.arquivosRegistro">
        <a [href]="carregarPathDonwload(arquivo?.nome, arquivo.nomeOriginal)" target="_blank" class="col-md-6 col-xs-12">
          {{ arquivo.nomeOriginal }}
        </a>
      </p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Documentos Adicionais</b></h3>
      <p class="info-section" *ngFor="let arquivo of compra.arquivosAdicionais">
        <a [href]="carregarPathDonwload(arquivo?.nome, arquivo.nomeOriginal)" target="_blank" class="col-md-6 col-xs-12">
          {{ arquivo.nomeOriginal }}
        </a>
      </p>
    </div>
  </div>

  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>CERP</b></h3>
      <p class="info-section">{{compra?.idItem}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Documentos Anexados</b></h3>
      <p class="info-section" *ngFor="let arquivo of compra?.anexos">
        <a [href]="carregarPathDonwload(arquivo?.path, arquivo.nomeArquivo)" target="_blank" class="col-md-6 col-xs-12">
          {{ arquivo.nomeArquivo }}
        </a>
      </p>
    </div>

  </div>

</div>


<app-form-13 *ngIf="compra?.editar" [itemEdicao]="compra" [itemAtual]="compra.produto" [edicao]="true"
  (salvarItem)="salvarItem($event)" (cancelarEdicao)="cancelarEdicao(compra)"></app-form-13>
