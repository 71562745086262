import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-btn-whatsapp-flutuante',
  templateUrl: './btn-whatsapp.component.html',
  styleUrls: ['./btn-whatsapp.component.scss']
})
export class BtnWhatsAppFlutuanteComponent implements OnInit {
  @Input() conteudo: any;
  
  constructor() { }

  ngOnInit(): void {

  }

}
