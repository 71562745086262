import { environment } from "../../environments/environment";

export class LocalStorage {


  /**
   * Limpa a LocalStorage
   * @param Key Nome da LocalStorage que será limpa
   */

  localStorageRemove(Key: string) {
   
      localStorage.removeItem(Key);
  }

  limpar() {
      localStorage.clear();
  }

  localStorageSet(Key: string, value: any, parse: boolean = true) {
      

      if (parse)
        localStorage.setItem(Key, JSON.stringify(value));
      else
        localStorage.setItem(Key, value);
   
  }

  localStorageGet(Key: string, parse: boolean = true): any {
   
      if (localStorage.getItem(Key) != null) {
        if (!parse) {
          return localStorage.getItem(Key);
        } else {
          var it = localStorage.getItem(Key) || '';


          return JSON.parse(it);
        }
      } else {
        return null;
      }
   
  }


  UsuarioLogado(): any {
   
      var userLog = this.localStorageGet("userlogg");
      if (userLog != null) {
        return userLog;
      } else {
        return {}
      }
   
  }

  EstaLogado(): boolean {
   

      if (this.localStorageGet("userlogg") != null) {
        return true;
      } else {
        return false;
      }
   
  }

  public static localStorageClear(): void {
    
      let au = localStorage.getItem('auth')
      localStorage.clear();
      localStorage.setItem('auth', au || '')
   
  }
}
