<div class="wrapper-search barra-busca" >

  <div class="container">
    
    <form [formGroup]="formBusca">

      <input type="text"
             id="inputSearch"
             matInput 
             placeholder="O que você procura hoje?" 
             (input)="onInputChange($event)" #inputSearch>

      <button type="submit" 
              class="btn-search-menu " 
              aria-label="search menu">
        <mat-icon matPrefix class="my-icon ico">search</mat-icon>

      </button>

      <!-- Lista de resultados -->

      <ul *ngIf="searchResults && searchResults.length > 0" class="results">

        <li *ngFor="let result of searchResults; let ln = index;" [ngClass]=" ln % 2 === 0 ? 'linha-azul': 'linha-branca'">
        
          <a (click)="solicitarNovo(result)"
             [href]="'pedido'+result.aLink">{{ result.titulo }}
          </a>
        
        </li>

      </ul>
    
    </form>

  </div>

</div>
