<form [formGroup]="camposForm">
  <h2 class="text-center">Identificação do Requerente</h2>
  <div class="row">
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Nome Completo</mat-label>
        <input matInput type="text" class="col-lg-12" formControlName="nomeCompleto"
               placeholder="Nome Completo">
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>N° ID Civil</mat-label>
        <input matInput type="text" class="col-lg-12" formControlName="identidade"
               placeholder="N° ID Civil">
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>NIF</mat-label>
        <input matInput type="text" class="col-lg-12" formControlName="nif" maxlength="9"
               placeholder="NIF">
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Telefone</mat-label>
        <input matInput type="text" class="col-lg-12" formControlName="telefone" mask="(00) 00000-0000"
               placeholder="Telefone">
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Email</mat-label>
        <input matInput type="text" class="col-lg-12 email" formControlName="email"
               placeholder="Email">
      </mat-form-field>
    </div>
  </div>
  <div class="row">

  </div>

    <h2 class="text-center">Dados do Pedido</h2>
    <ng-container *ngIf="itemAtual && itemAtual.titulo === 'Pesquisa de certidão de casamento'">
      <div class="row" formArrayName="detalhes">
        <div *ngFor="let detalhes of detalhes.controls; let i=index" [formGroupName]="i">
          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Nome do Pai</mat-label>
                <input matInput type="text" class="col-lg-12" formControlName="nomePai"
                       placeholder="Nome do Pai">
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Finalidade:</mat-label>
                <mat-select formControlName="finalidade" [compareWith]="compareFinalidade">
                  <mat-option *ngFor="let item of finalidades" [value]="item">
                    {{ item.descricao }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Nome Cônjuge:</mat-label>
                <input matInput type="text" class="col-lg-12" formControlName="nomeConjuge"
                       placeholder="Nome Cônjuge">
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Nome da Mãe</mat-label>
                <input matInput type="text" class="col-lg-12"
                       formControlName="nomeMae" name="nomeMae">
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label for="pais">Lugar do Casamento:</mat-label>
                <mat-select formControlName="pais" name="pais" [compareWith]="comparePais" (selectionChange)="validarLocalizacaoDetalhes($event.value, i)">
                  <mat-option *ngFor="let item of listPais" [value]="item">{{ item.pais }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label for="data">Data do Casamento</mat-label>
                <input matInput appMaskDate maxlength="10" [matDatepicker]="picker" type="text" formControlName="data"
                       name="data">
                <mat-datepicker-toggle matIconSuffix [for]="picker" max="2024-02-29"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <ng-container *ngIf="detalhes?.get('pais')?.value?.pais == 'Portugal'">
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Distrito:</mat-label>
                  <mat-select formControlName="distritoDetalhes" (selectionChange)="getConcelhosInput($event, 'detalhes')" [compareWith]="compareDistrito">
                    <mat-option *ngFor="let item of distritoDetalhes" [value]="item">
                      {{ item.descricao }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Concelho:</mat-label>
                  <mat-select formControlName="concelhoDetalhes" (selectionChange)="getFreguesiaInput($event, 'detalhes')" [compareWith]="compareConcelho">
                    <mat-option *ngFor="let item of concelhoDetalhes" [value]="item">
                      {{ item.descricao }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Freguesia:</mat-label>
                  <mat-select formControlName="freguesiaDetalhes" [compareWith]="compareFreguesia">
                    <mat-option *ngFor="let item of freguesiaDetalhes" [value]="item">
                      {{ item.descricao }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="itemAtual && itemAtual.titulo === 'Pesquisa de certidão de nascimento'">
      <div class="row" formArrayName="detalhes">
        <div *ngFor="let detalhes of detalhes.controls; let i=index" [formGroupName]="i">
          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Nome do Pai</mat-label>
                <input matInput type="text" class="col-lg-12" formControlName="nomePai"
                      placeholder="Nome do Pai">
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Finalidade:</mat-label>
                <mat-select formControlName="finalidade" [compareWith]="compareFinalidade">
                  <mat-option *ngFor="let item of finalidades" [value]="item">
                    {{ item.descricao }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Nome da Mãe</mat-label>
                <input matInput type="text" class="col-lg-12"
                       formControlName="nomeMae" name="nomeMae">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="itemAtual && itemAtual.titulo === 'Pesquisa de certidão de óbito'">
    <div class="row" formArrayName="detalhes">
      <div *ngFor="let detalhes of detalhes.controls; let i=index" [formGroupName]="i">
        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Nome do Pai</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="nomePai"
                    placeholder="Nome do Pai">
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Finalidade:</mat-label>
              <mat-select formControlName="finalidade" [compareWith]="compareFinalidade">
                <mat-option *ngFor="let item of finalidades" [value]="item">
                  {{ item.descricao }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="data">Data do Óbito</mat-label>
              <input matInput appMaskDate maxlength="10" [matDatepicker]="picker" type="text" formControlName="data"
                    name="data">
              <mat-datepicker-toggle matIconSuffix [for]="picker" max="2024-02-29"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Nome da Mãe</mat-label>
              <input matInput type="text" class="col-lg-12"
                    formControlName="nomeMae" name="nomeMae">
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="pais">País do Óbito:</mat-label>
              <mat-select formControlName="pais" name="pais" [compareWith]="comparePais" (selectionChange)="validarLocalizacaoDetalhes($event.value, i)">
                <mat-option *ngFor="let item of listPais" [value]="item">{{ item.pais }}</mat-option>
              </mat-select>

            </mat-form-field>
          </div>
          <ng-container *ngIf="detalhes?.get('pais')?.value?.pais == 'Portugal'">
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Distrito:</mat-label>
                <mat-select formControlName="distritoDetalhes" (selectionChange)="getConcelhosInput($event, 'detalhes')" [compareWith]="compareDistrito">
                  <mat-option *ngFor="let item of distritoDetalhes" [value]="item">
                    {{ item.descricao }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Concelho:</mat-label>
                <mat-select formControlName="concelhoDetalhes" (selectionChange)="getFreguesiaInput($event, 'detalhes')" [compareWith]="compareConcelho">
                  <mat-option *ngFor="let item of concelhoDetalhes" [value]="item">
                    {{ item.descricao }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Freguesia:</mat-label>
                <mat-select formControlName="freguesiaDetalhes" [compareWith]="compareFreguesia">
                  <mat-option *ngFor="let item of freguesiaDetalhes" [value]="item">
                    {{ item.descricao }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    </ng-container>


  <h2 class="text-center">Dados do Pedido</h2>
    <!--Pedido feito por terceiro-->
    <div class="col-md-4">
      <mat-label>Pedido feito por terceiro? </mat-label>
      <mat-radio-group formControlName="pedidoFeitoPorTerceiro">
        <mat-radio-button value='true'>Sim</mat-radio-button>
        <mat-radio-button value='false'>Não</mat-radio-button>
      </mat-radio-group>
    </div>
  <div class="row" formArrayName="dadosSolicitante">
    <div *ngFor="let solicitante of solicitante.controls; let i=index" [formGroupName]="i">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
            <mat-label>Nº ID Civil</mat-label>
            <input matInput type="text" class="col-lg-12" formControlName="identidade"
                   placeholder="Nº ID Civil">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
            <mat-label>Nome Completo:</mat-label>
            <input matInput type="text" class="col-lg-12" formControlName="nomeCompleto"
                   placeholder="Nome Completo">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
            <mat-label>Número | Ano do registro:</mat-label>
            <input matInput type="text" class="col-lg-12" formControlName="dataExpedicao" maxlength="4" (keypress)="service.apenasNumeros($event)"
                   placeholder="Número | Ano do registro" min="0">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
            <mat-label for="nascimento">Data de Nascimento</mat-label>
            <input matInput appMaskDate maxlength="10" [matDatepicker]="picker" type="text" formControlName="dataNascimento"
                   name="nascimento">
            <mat-datepicker-toggle matIconSuffix [for]="picker" max="2024-02-29"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-5">
          <mat-form-field class="w-100">
            <mat-label for="pais">Naturalidade:</mat-label>
            <mat-select formControlName="pais" name="pais" [compareWith]="comparePais" class="w-100" (selectionChange)="validarLocalizacaoSolicitante($event.value, i)">
              <mat-option *ngFor="let item of listPais" [value]="item">{{ item.pais }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <ng-container *ngIf="solicitante?.get('pais')?.value?.pais == 'Portugal'">
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Distrito:</mat-label>
              <mat-select formControlName="distritoSolicitante" (selectionChange)="getConcelhosInput($event, 'solicitante')" [compareWith]="compareDistrito">
                <mat-option *ngFor="let item of distritoSolicitante" [value]="item">
                  {{ item.descricao }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Concelho:</mat-label>
              <mat-select formControlName="concelhoSolicitante" (selectionChange)="getFreguesiaInput($event, 'solicitante')" [compareWith]="compareConcelho">
                <mat-option *ngFor="let item of concelhoSolicitante" [value]="item">
                  {{ item.descricao }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Freguesia:</mat-label>
              <mat-select formControlName="freguesiaSolicitante" [compareWith]="compareFreguesia">
                <mat-option *ngFor="let item of freguesiaSolicitante" [value]="item">
                  {{ item.descricao }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Informação Adicional:</mat-label>
        <input matInput type="text" class="col-lg-12" formControlName="informacaoAdicional"
               placeholder="Informação Adicional">
      </mat-form-field>
    </div>
  </div>


</form>
<app-aviso [aviso]="itemAtual?.aviso"></app-aviso>

<div class="row">
  <div class="col-md-5"></div>
  <div class="col-auto" *ngIf="!edicao">
    <button type="button" mat-button [routerLink]="['/']" class="btn-next" style="color: white; background-color: green;">Voltar</button>
  </div>
  <div class="col-auto" *ngIf="edicao">
    <button type="button" mat-button (click)="cancelarEdicaoForm()" class="btn-next" style="color: white; background-color: green;">Cancelar</button>
  </div>
  <div class="col-auto">
    <button mat-button (click)="updatePedido()" class="btn-next"
            style="display: block; margin: 0 auto;">
            <span  *ngIf="!edicao">Continuar</span> 
            <span  *ngIf="edicao">Salvar</span> 
    </button>
  </div>
</div>
