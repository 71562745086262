export const environment = {
  production: true,
  sistema: 'cnr',
  urlCadastro: '/login/cadastrar',
  urlLogin: '/login/entrar',
  urlPainel: '/admin/meus-pedidos',
  userBaseTeste: false,
  assetsDir: "/assets/",
  sdkZoom: {
    sdkKey: 'nmcZk2Ru8JSqCkUSU3ixRbN5TkkbwxBS4902',
    sdkSecret: 'MTkKfLd9DgOJOGcw3IoNBH5pDvVlYAmf8XUn',
    // meetingNumber: '4956963746',
    // passWordMeeting: '120488',
    timeOutMettingAlert: 300000
  },
  http: {
    //homologacao
    //API: 'https://api-cnr.etechss.com.br/api/',
    //producao
    API: 'https://vendacnrsolucoes.cnrsolucoes.com.br/api/',
    //local
    //API: 'https://localhost:7172/api/',

    //producao
    API_VENDA_ECARTORIO: 'https://certidoes.cnrsolucoes.com.br/api/',


    //homologacao
    //API_BACK: 'https://apiecrt.e-cartoriotcs.com.br/',
    // pre producao
    API_BACK: 'https://apiectpre.e-cartoriorj.com.br/',
    //producao
    //API_BACK: 'https://apiect.e-cartoriorj.com.br/',
    //local
    //API_BACK: 'https://localhost:44330/',


    //homologacao
    //API_AUTH: 'https://autenticacaocnr.etechss.com.br/',
    //producao
    API_AUTH: 'https://auth.cnrsolucoes.com.br/',
    //local
    //API_AUTH: 'https://localhost:5001/',

    //local
    //API_SISTEMA: 'https://localhost:65084/'
    //producao
    API_SISTEMA: 'https://apisistemas.etechss.com.br/'
  }

};
