<div class="wrapper-item" *ngIf="!compra?.editar">
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Protocolo</b></h3>
      <p class="info-section">{{compra?.protocolo}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Registro</b></h3>
      <p class="info-section">{{compra?.registro}}</p>
    </div>
  </div>
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Número do Ato</b></h3>
      <p class="info-section">{{compra?.numeroAto}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Data do Ato</b></h3>
      <p class="info-section">{{compra?.dataAto | date:'dd/MM/yyyy':'UTC'}}</p>
    </div>
  </div>
</div>

<app-form-4 *ngIf="compra?.editar" [edicao]="true" [itemEdicao]="compra" (cancelarEdicao)="cancelarEdicao(compra)" (salvarItem)="salvarItem($event)"
             [itemAtual]="compra.produto"></app-form-4>
