import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CrudService } from '../../../_sevice/CrudService';
import { GlobalService } from '../../../_sevice/GlobalService';
import { LocalStorage } from 'src/app/_entidade/LocalStorage';

@Component({
	selector: 'app-table-data',
	templateUrl: './table-data.component.html',
	styleUrls: ['./table-data.component.scss']
})

export class TableDataComponent implements OnInit {

	@Input() conteudo: any;
	@Output() dadosAtualizados: EventEmitter<any> = new EventEmitter<any>();

	panelOpenState = false;

	dataTable: any;
	loading: boolean = false;
	user: any;
	dados: any = [];
	selectedTabIndex: any = 0

	constructor(
		public globalService: GlobalService
		, public service: CrudService
		, public ls: LocalStorage) {
      this.ls.localStorageRemove('pedido')
    }

	ngOnInit(): void {

		this.obterDados(0);

	}
	idAtual: any
	saveDetails(id: any) {
		this.idAtual = id;

	}

	setPedido(dados: any) {

		console.log(dados)
		if (this.idAtual == undefined) {
      this.idAtual = this.ls.localStorageGet("idatu")
    }
		let pedido = dados.pedido
		console.log(pedido, 'teste pedido')
		if (dados.tipo == 'fotoOrcamento') {
			if (pedido?.Passo8 == null)
				pedido['Passo8'] = { OrcamentoPrevioFile: '' }

			pedido.Passo8['OrcamentoPrevioFile'] = dados.nome
			pedido.Passo8['ValorOrcamento'] = dados.ValorOrcamento
		}  
		else if (dados.tipo == 'fotoMinuta') {
			if (pedido?.Passo10 == null)
				pedido['Passo10'] = { UploadFileMinuta: '' }

			pedido.Passo10['UploadFileMinuta'] = dados.nome
		}  
		else if (dados.tipo == 'fotoAllDocs') {
			if (pedido?.Passo10 == null)
				pedido['Passo10'] = { UploadFileAllDocs: '' }

			pedido.Passo10['UploadFileAllDocs'] = dados.nome
		}  



		this.salvarPedido(pedido);

	}


	salvarPedido(pedido: any) {
		let url = 'pedidos'
		this.service.putObservable(url + '/' + pedido.id, pedido).subscribe(
			(resultado) => {

			},
			(erro) => {
				console.log(erro)
			}
		)


  }

  excluir(id: any) {
		let url = 'pedidos'
    this.service.deleteObservable(url + '/' + id).subscribe(
      (resultado) => {
        this.obterDados(0);
      },
      (erro) => {
        //console.log(erro)
      }
    )

  }

	obterDados(index: any) {


		this.user = this.ls.localStorageGet("userlogged")

		this.loading = true;
		let url = this.user['perfil'] == 'admin' ? 'pedidos' : 'pedidos';
    if (index == 0) {
      let courl = this.user['perfil'] == 'admin' ? '&AguardandoOrcamento=true' : '&UserId=' + this.user.login;
      url = url + '?PendenteAdmin=true' + courl
    }
    else if (index == 1)
      url = url + '?PendenteAdmin=true&GuiasConcluidas=false&AguardandoOrcamento=false'
		else if (index == 2)
      url = url + '?PendenteAdmin=true&AguardandoEscritura=true&GuiasConcluidas=true&AguardandoOrcamento=false&PedidoConcluido=false'
    else if (index == 3)
      url = url + '?PedidoConcluido=true'

    //console.log(url)
		this.service.getObservable(url).subscribe(
			(resultado: any) => {

				this.dados = resultado
				
				this.loading = false;
			},
			(erro) => {
				console.log(erro)
				this.loading = false;
				return false
			}
		)

	}





}
