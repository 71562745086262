import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
import { debounce } from 'lodash';
import { environment } from 'src/environments/environment';
import { produtosLimpos } from 'src/app/_configPage/listas/prdutosCNR';
import { Router } from '@angular/router';

@Component({
  selector: 'app-searchbar-v1',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchBarV1Component implements OnInit {
  @Input() class: any;
  @Input() conteudo: any;
  formBusca = new FormGroup({
    palavraPesquisada: new FormControl('', Validators.required)
  });
  load: any
  itens: any
  @Output() searchQuery = new EventEmitter<string>();
  searchResults: any;

  @ViewChild('inputSearch') myinput: ElementRef

  onInputChange(event: any): void {
    const query = event?.target?.value;

    // Emitir evento apenas se a string de pesquisa não estiver vazia
    if (query !== null && query !== undefined) {
      this.getProdutos(query)

    }
  }

  constructor(
    public storage: LocalStorage,
    private route: Router, 
    private cdRef: ChangeDetectorRef,
    public service: CrudService
  ) {
    this.onInputChange = debounce(this.onInputChange, 500)

  }



  ngOnInit(): void {
     
    setTimeout(() => {
      this.myinput.nativeElement.focus()
    },20)

  }
  solicitarNovo(item: any) {
    this.storage.localStorageRemove("kit");

    this.storage.localStorageRemove("item_atual")


    let novoItem = produtosLimpos.find((x: any) => x.aLink == '/pedido' + item.aLink)

    if (novoItem != null && novoItem != undefined) {

      this.storage.localStorageSet('item_atual', novoItem)
      this.route.navigate(['/pedido' + item.aLink])

    }
  }

  loadProdutos() {

     
      this.searchResults = [
        {
          "titulo": "buscando...",
          "aLink": "",
          "descricao": "buscando...",
          "idAto": 0,
          "idTipoAto": 0,
          "descricaoTipoAto": "0",
          "origem": ""
        }
      ]
  
  }

  getProdutos(query: any) {

    this.load = false
    if (query) {


      this.service.getObservableDirect(environment.http.API_BACK+ 'Consulta/Produto?busca=' + query).subscribe({
        next: async (response: any) => {
          this.searchResults = response
          if (response.length == 0) {
            this.searchResults = [
              {
                "titulo": "não encontramos itens com o valor pesquisado",
                "aLink": "",
                "descricao": "não encontramos itens com o valor pesquisado",
                "idAto": 0,
                "idTipoAto": 0,
                "descricaoTipoAto": "0",
                "origem": ""
              }
            ]
          }

        },
        error: (err) => {

          this.searchResults = [
            {
              "titulo": "não encontramos itens com o valor pesquisado",
              "aLink": "",
              "descricao": "não encontramos itens com o valor pesquisado",
              "idAto": 0,
              "idTipoAto": 0,
              "descricaoTipoAto": "0",
              "origem": ""
            }
          ]
        }
      })
    } else {
      this.searchResults = []
    }
  }


}
