<form [formGroup]="camposForm">

  <ng-container>
    <div class="col-md-12">
      <h2>Proprietário</h2>
      <div class="content-list" formArrayName="proprietario">
        <div *ngFor="let item of proprietario.controls; let i = index; let last = last;" [formGroupName]="i" class="row">
          <div class="row" *ngIf="i > 0">

            <div class="col-md-12" style="min-height: 94px;">
               <hr />
            </div>
          </div>
          <div class="row">

            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Nome Completo do Proprietário(a)</mat-label>
                <input matInput type="text" class="col-lg-12" formControlName="nomeCompleto"
                       placeholder="Nome Completo" maxlength="200">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>CPF</mat-label>
                <input matInput type="text"
                       mask="000.000.000-00"
                       class="col-lg-12" formControlName="cpf" placeholder="CPF">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <!-- Identidade -->
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Identidade</mat-label>
                <input matInput type="text" class="col-lg-12" formControlName="identidade" placeholder="Identidade" maxlength="25">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <!-- Órgão Expedidor -->
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Órgão Expedidor</mat-label>
                <input matInput type="text" class="col-lg-12" formControlName="orgaoExpedidor"
                       placeholder="Órgão Expedidor" maxlength="100">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <!-- Data Expedição -->
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Data Expedição</mat-label>
                <input matInput appMaskDate maxlength="10" [matDatepicker]="picker" formControlName="dataExpedicao" placeholder="Data Expedição">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <!-- E-mail -->
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>E-mail</mat-label>
                <input matInput type="text" class="col-lg-12 email" formControlName="email" placeholder="E-mail" maxlength="300">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Telefone -->
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Telefone / Celular</mat-label>
                <input matInput type="text" class="col-lg-12" formControlName="telefone" mask="(00) 0000-0000 || (00) 0 0000-0000" placeholder="Telefone">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-6" *ngIf="last">
              <button class="w-100 my-2 add-new-name" type="button" (click)="addProrietario()">Adicionar Novo Nome</button>
            </div>
            <div class="col-md-6" *ngIf="last">
              <button class="w-100 my-2 add-new-name" type="button" (click)="restartForm()">Remover</button>
            </div>
          </div> -->
          <div class="row">
            <!-- Botão Remover -->
            <div class="col-md-9" style="min-height: 94px;" *ngIf="i > 0">

            </div>
            <div class="col-md-3" style="min-height: 94px;" *ngIf="i > 0">
              <button class="w-100 my-2 add-new-name" type="button" (click)="restartForm(i)">Remover</button>

            </div>
            <div class="col-md-9 " *ngIf="last && i > 0 "></div>
            <div class="col-md-6 " *ngIf="last">
              <button class="w-100 my-2 add-new-name" type="button" (click)="addProrietario()">Adicionar Novo Nome</button>
            </div>
          </div>
        </div>
      </div>
      <h2>Dados do Pet</h2>
      <div class="content-list" formGroupName="dadosPet">
        <div class="row">
          <div class="col-md-6">
            <!-- Nome -->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Nome</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="nome"
                     placeholder="Nome" maxlength="200">
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-6">
            <!-- Espécie -->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Espécie</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="especie"
                     placeholder="Espécie" maxlength="100">
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!-- Raça -->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Raça</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="raca"
                     placeholder="Raça" maxlength="100">
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-6">
            <!-- Cor -->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Sexo</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="sexo"
                     placeholder="Sexo" maxlength="10">
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">

          <div class="col-md-4">
            <!-- Pelagem/Plumagem -->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Pelagem/Plumagem</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="pelagem"
                     placeholder="Pelagem/Plumagem" maxlength="50">
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-4">
            <!-- Data Nascimento -->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Data Nascimento</mat-label>
              <input matInput appMaskDate maxlength="10" [matDatepicker]="picker" formControlName="dataNascimento"
                     placeholder="Data Nascimento">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-4">
            <!-- Idade -->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Idade</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="idade"
                     placeholder="Idade" (keypress)="service.apenasNumeros($event)" maxlength="2">
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!-- Número do Registro -->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Sinais Característicos</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="caracteristicas"
                     placeholder="Sinais Característicos" maxlength="100">
            </mat-form-field>
          </div>

          <div class="col-md-6">
            <!-- Microchip -->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Microchip</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="microchip"
                     placeholder="Microchip" maxlength="100">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!-- Endereço -->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Endereço</mat-label>
              <textarea matInput type="text" class="col-lg-12" formControlName="endereco"
                        placeholder="Endereço" maxlength="255"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="content-list">
        <div class="row d-flex flex-wrap">
          <div class="content-list col-md-4" [class]="validarArquivosFotos">
            <h2>Foto do Pet (gif,jpg,jpeg,png)</h2>
            <app-uploadFiles [tipo]="1" [extensaoTipos]="['gif','jpg','jpeg','png']" (setaArquivos)="setaArquivos($event)" [filesAtu]="prodAtu?.arquivosFotos" (resetaArquivos)="resetaArquivos($event)"></app-uploadFiles>
          </div>

          <div class="content-list col-md-4" [class]="validarArquivosIdentificacao">
            <h2>Documentos de Identificação (jpg,jpeg,png,pdf)</h2>
            <app-uploadFiles [tipo]="2" (setaArquivos)="setaArquivos($event)" [extensaoTipos]="['jpg','jpeg','png','pdf']" [filesAtu]="prodAtu?.arquivosIdentificacao" (resetaArquivos)="resetaArquivos($event)"></app-uploadFiles>
          </div>

          <div class="content-list col-md-4" [class]="validarArquivosResidencia">
            <h2>Comprovante de Residência  (jpg,jpeg,png,pdf)</h2>
            <app-uploadFiles [tipo]="3" (setaArquivos)="setaArquivos($event)" [extensaoTipos]="['jpg','jpeg','png','pdf']" [filesAtu]="prodAtu?.arquivosResidencia" (resetaArquivos)="resetaArquivos($event)"></app-uploadFiles>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</form>

<app-aviso [aviso]="itemAtual?.aviso" [classObrigatoriaAviso]="classObrigatoriaAviso" (temAceite)="temAceite($event)"></app-aviso>

<div class="row">
  <div class="col-md-5"></div>
  <div class="col-auto" *ngIf="!edicao">
    <button type="button" mat-button [routerLink]="['/']" class="btn-next" style="color: white; background-color: green;">Voltar</button>
  </div>
  <div class="col-auto" *ngIf="edicao">
    <button type="button" mat-button (click)="cancelarEdicaoForm()" class="btn-next" style="color: white; background-color: green;">Cancelar</button>
  </div>
  <div class="col-auto">
    <button mat-button (click)="updatePedido()" class="btn-next"
            style="display: block; margin: 0 auto;">
            <span  *ngIf="!edicao">Continuar</span> 
            <span  *ngIf="edicao">Salvar</span> 
    </button>
  </div>
</div>
