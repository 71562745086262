import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  FormArray,
  FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
import { localidade } from 'src/app/_configPage/listas/localidade';
import { ActivatedRoute } from '@angular/router';

interface Detalhes {
  data: string;
  filhoDe1: string;
  filhoDe2: string;
  nomeMae: string;
  nomePai: string;
  nomeConjuge: string;
  pais: string;
  distrito: string;
  concelho: string;
  freguesia: string;
}

interface Solicitante {
  identidade: string;
  dataExpedicao: string;
  nomeCompleto: string;
  dataNascimento: string;
  pais: string;
  distrito: string;
  concelho: string;
  freguesia: string;
}

interface Item {
  ato: string;
  nomeCompleto: string;
  email: string;
  identidade: string;
  nif: string;
  telefone: string;
  tipo: string;
  detalhes: Detalhes[];
  dadosSolicitante: Solicitante[];
  informacaoAdicional: string;
}

@Component({
  selector: 'app-form-101',
  templateUrl: './form10.1.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class Form101Component implements OnInit, AfterViewInit, OnChanges {
  @Output() salvarItem = new EventEmitter<any>();
  @Input() edicao: any=false;

  @Input() itemAtual: any;
  @Input() itemEdicao: any;
  @Output() cancelarEdicao = new EventEmitter<any>();

  finalidades: any = [{ "id": '19', "descricao": "EXTRAJUDICIAL" }, { "id": '20', "descricao": "INFORMAÇÃO PESSOAL" },
  { "id": '18', "descricao": "JUDICIAL" }]

  cartorioEmissor: any[];
  classObrigatoria: any = ''
  public visibilidadeDetalhes: boolean[] = [];
  listPais: any[] = localidade.listPais
  distritoSolicitante: any;
  concelhoSolicitante: any;
  freguesiaSolicitante: any;

  distritoDetalhes: any;
  concelhoDetalhes: any;
  freguesiaDetalhes: any;


  camposForm = this.formBuilder.group({
    nomeCompleto: ['', Validators.required],
    email: ['', Validators.compose([
      Validators.email
    ])],
    identidade: [''],
    nif: [''],
    telefone: [''],
    detalhes: this.formBuilder.array([this.createDetalhe()]),
    dadosSolicitante: this.formBuilder.array([this.createSolicitante()]),
    informacaoAdicional: [''],
    envioCorreios: ['false'],
    apostilamento: ['false'],
    pedidoFeitoPorTerceiro: ['false']
  });

  createDetalhe(): FormGroup {
    return this.formBuilder.group({
      data: [''],
      filhoDe1: [''],
      filhoDe2: [''],
      nomeMae: [''],
      nomePai: [''],
      nomeConjuge: [''],
      nomeCompleto: [''],
      pais: [''],
      finalidade: [''],
      distritoDetalhes: [''],
      concelhoDetalhes: [''],
      freguesiaDetalhes: ['']
    });
  }
  createSolicitante(): FormGroup {
    return this.formBuilder.group({
      identidade: [''],
      dataExpedicao: [''],
      nomeCompleto: [''],
      dataNascimento: [''],
      pais: [''],
      distritoSolicitante: [''],
      concelhoSolicitante: [''],
      freguesiaSolicitante: ['']
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public teste: TesteService,
    private cdRef: ChangeDetectorRef,
    private routeAc: ActivatedRoute,
    public service: CrudService
  ) {

  }
  
  cancelarEdicaoForm() {
    this.cancelarEdicao.emit()
  }

  ngOnChanges(changes: SimpleChanges) {


    this.cdRef.detectChanges();

  }

  addSolicitante() {
    this.solicitante.push(this.createSolicitante());
  }

  get solicitante(): FormArray {
    return this.camposForm.get('dadosSolicitante') as FormArray;
  }

  getSolicitanteControl(index: number): FormGroup {
    return (this.camposForm.get('dadosSolicitante') as FormArray).at(index) as FormGroup;
  }
  addDetalhes() {
    this.detalhes.push(this.createSolicitante());
  }

  get detalhes(): FormArray {
    return this.camposForm.get('detalhes') as FormArray;
  }

  getDetalhesControl(index: number): FormGroup {
    return (this.camposForm.get('detalhes') as FormArray).at(index) as FormGroup;
  }

  ngOnInit(): void {
    this.getDistrito()
    this.setupFormChanges()
    this.initializeValidators()
  }

  ngAfterViewInit() {
    this.carrregarEdicao()
  }

  // get itemAtual() {
  //   return this.storage.localStorageGet("item_atual");
  // }

  validarLocalizacaoSolicitante(valorSelecionado: any, indice: number) {
    const solicitanteArray = this.camposForm.get('dadosSolicitante') as FormArray;
    const solicitante = solicitanteArray.at(indice);

    const pais = (typeof valorSelecionado === 'object' && valorSelecionado !== null) ? valorSelecionado.pais : valorSelecionado;
    const camposLocalizacao = ['distritoSolicitante', 'concelhoSolicitante', 'freguesiaSolicitante'];

    camposLocalizacao.forEach(campo => {
      if (pais === 'Portugal') {
        solicitante.get(campo)?.setValidators(Validators.required);
      } else {
        solicitante.get(campo)?.clearValidators();
      }
      solicitante.get(campo)?.updateValueAndValidity();
    });
  }

  comparePais(object1: any, object2: any) {

    return object1 && object2 && object1.id == object2.id;
  }

  validarLocalizacaoDetalhes(valorSelecionado: any, indice: number) {
    const detalhesArray = this.camposForm.get('detalhes') as FormArray;
    const detalhe = detalhesArray.at(indice);

    const pais = (typeof valorSelecionado === 'object' && valorSelecionado !== null) ? valorSelecionado.pais : valorSelecionado;
    const camposLocalizacao = ['distritoDetalhes', 'concelhoDetalhes', 'freguesiaDetalhes'];

    camposLocalizacao.forEach(campo => {
      if (pais === 'Portugal') {
        detalhe.get(campo)?.setValidators(Validators.required);
      } else {
        detalhe.get(campo)?.clearValidators();
      }
      detalhe.get(campo)?.updateValueAndValidity();
    });
  }

  carrregarEdicao() {
    let paramId = this.routeAc.snapshot.paramMap.get('id');

    if (paramId != null || this.itemEdicao != null) {
      let pedido = this.storage.localStorageGet("pedido")

      if (pedido?.listaCompra != null && pedido?.listaCompra.length > 0 && paramId != null) {
        let prodAtu = { ...pedido.listaCompra?.find((x: any) => x.idItem == paramId) }
        this.service.preencheCampos(prodAtu, this.camposForm)
        this.setupFormChanges()

          // Verifica se existem valores definidos e extrai os IDs
          if (prodAtu.detalhes[0].distritoDetalhes && prodAtu.detalhes[0].concelhoDetalhes ||
                prodAtu.dadosSolicitante[0].distritoSolicitante && prodAtu.dadosSolicitante[0].concelhoSolicitante) {
                const detalhesDistritoId = prodAtu.detalhes[0].distritoDetalhes.id;
                const detalhesConcelhoId = prodAtu.detalhes[0].concelhoDetalhes.id;
                const solicitanteDistritoId = prodAtu.dadosSolicitante[0].distritoSolicitante.id;
                const solicitanteConcelhoId = prodAtu.dadosSolicitante[0].concelhoSolicitante.id;

              // Chama as funções passando os IDs extraídos
              this.getConcelhosInput({ value: { id: detalhesDistritoId } }, 'detalhes');
              this.getFreguesiaInput({ value: { id: detalhesConcelhoId } }, 'detalhes');
              this.getConcelhosInput({ value: { id: solicitanteDistritoId } }, 'solicitante');
              this.getFreguesiaInput({ value: { id: solicitanteConcelhoId } }, 'solicitante');
           }
      }
      this.cdRef.detectChanges();
    }
  }

  onCheckboxChange(event: any, item: any) {
    if (event.checked) {
      item["selecionado"] = true
    } else {
      item["selecionado"] = false

    }
  }

  getDistrito() {
    this.service.getObservableDirect(environment.http.API_BACK + 'Consulta/Distrito').subscribe({
      next: async (response: any) => {
        this.distritoSolicitante = response;
        this.distritoDetalhes = response;
        if (document.location.href.indexOf('meu-carrinho') == -1) {
          this.carrregarEdicao()
        }
      },
      error: (err) => {
        console.error(err);
      }
    })
  }
  getConcelhosInput(event: any, tipo: string) {
    if (tipo === 'solicitante') {
      this.getConcelhos(event.value.id, tipo);
    } else if (tipo === 'detalhes') {
      this.getConcelhos(event.value.id, tipo);
    }
  }
  getFreguesiaInput(event: any, tipo: string) {
    if (tipo === 'solicitante') {
      this.getFreguesia(event.value.id, tipo);
    } else if (tipo === 'detalhes') {
      this.getFreguesia(event.value.id, tipo);
    }
  }

  getConcelhos(value: any, tipo: string) {
    this.service.getObservableDirect(environment.http.API_BACK + 'Consulta/Concelho?idDistrito=' + value).subscribe({
      next: async (response: any) => {
        if (tipo === 'solicitante') {
          this.concelhoSolicitante = response;
        } else if (tipo === 'detalhes') {
          this.concelhoDetalhes = response;
        }
        if (tipo === 'solicitante') {
          this.freguesiaSolicitante = null;
        } else if (tipo === 'detalhes') {
          this.freguesiaDetalhes = null;
        }
      },
      error: (err) => {
        console.error(err);
      }
    })
  }

  getFreguesia(value: any, tipo: string) {
    this.service.getObservableDirect(environment.http.API_BACK + 'Consulta/Freguesia?idConcelho=' + value).subscribe({
      next: async (response: any) => {
        if (tipo === 'solicitante') {
          this.freguesiaSolicitante = response;
        } else if (tipo === 'detalhes') {
          this.freguesiaDetalhes = response;
        }
      },
      error: (err) => {
        console.error(err);
      }
    })
  }

  compareDistrito(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }
  compareConcelho(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }
  compareFreguesia(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }
  compareFinalidade(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }
  compareTipoAto(object1: any, object2: any) {

    return object1 && object2 && object1.id == object2.id;
  }

  setupFormChanges() {
    this.camposForm.get('pedidoFeitoPorTerceiro')?.valueChanges.subscribe(value => {
      this.adjustValidatorsForSolicitantes(value);
    });
  }


  initializeValidators() {
    const initialPedidoValue = this.camposForm.get('pedidoFeitoPorTerceiro')?.value;
    this.adjustValidatorsForSolicitantes(initialPedidoValue);
  }

  adjustValidatorsForSolicitantes(value: any) {
    const solicitantes = this.camposForm.get('dadosSolicitante') as FormArray;
    solicitantes.controls.forEach(control => {
      if (value === 'true') {
        control.get('identidade')?.setValidators([Validators.required]);
        control.get('nomeCompleto')?.setValidators([Validators.required]);
        control.get('dataExpedicao')?.setValidators([Validators.required]);
        control.get('dataNascimento')?.setValidators([Validators.required]);
        control.get('pais')?.setValidators([Validators.required]);
      } else {
        control.get('identidade')?.clearValidators();
        control.get('nomeCompleto')?.clearValidators();
        control.get('dataExpedicao')?.clearValidators();
        control.get('dataNascimento')?.clearValidators();
        control.get('pais')?.clearValidators();
      }

      control.get('identidade')?.updateValueAndValidity();
      control.get('nomeCompleto')?.updateValueAndValidity();
      control.get('dataExpedicao')?.updateValueAndValidity();
      control.get('dataNascimento')?.updateValueAndValidity();
      control.get('pais')?.updateValueAndValidity();
    });
  }

  updatePedido() {
    this.setupFormChanges()
    //this.ajustarValidadoresCertidao()

    for (var i = 0; i < this.solicitante.controls.length; i++) {
      let val = this.solicitante.controls[i].get('dataNascimento')?.value

      if (val && val != '')
        this.solicitante.controls[i].get('dataNascimento')?.setValue(new Date(this.solicitante.controls[i].get('dataNascimento')?.value))

    }
    for (var i = 0; i < this.detalhes.controls.length; i++) {
      let val = this.detalhes.controls[i].get('data')?.value
      //console.log(val)

      if (val && val != '')
        this.detalhes.controls[i].get('data')?.setValue(new Date(this.detalhes.controls[i].get('data')?.value))

    }

    //let val = this.solicitante.get('dataNascimento')?.value

    

    this.camposForm.markAllAsTouched();
    
    if (this.camposForm.valid) {
      let item: any = this.camposForm.value
      this.salvarItem.emit(item);
    }

  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // //console.log(event.key, event.ctrlKey, event.shiftKey, environment.production)
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {

      this.teste.setValueForm(this.camposForm);

    }
  }

}
