export const lista: any[] = [
    { idAto: 10081, campos: [
        { "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 18, "largura": 4, "required": false, "error": false, "requiredOptional": ["rntrc"], "lastCampoRequiredOptional": "rntrc", "divisor": true }, 
        { "tipo": "numero", "prop": "rntrc", "placeholder": "RNTRC", "label": "RNTRC", "tamanhoMaximo": 10, "largura": 4, "required": false, "error": false, "requiredOptional": ["cpfCnpj"], "lastCampoRequiredOptional": "rntrc" }, 
        { "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 4, "required": false, "error": false }
    ] }
    , { idAto: 10082, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    
    , {
      idAto: 10083, campos: [
        { "tipo": "uf", "prop": "uf", "placeholder": "UF", "label": "UF", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false },   
        { "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": ["inscricaoEstadual"], "lastCampoRequiredOptional": "inscricaoEstadual", "divisor": true },
        { "tipo": "texto", "prop": "inscricaoEstadual", "placeholder": "Inscrição Estadual", "label": "Inscrição Estadual", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": ["cpfCnpj"], "lastCampoRequiredOptional": "inscricaoEstadual" }
      ]
    }
    , { idAto: 10084, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10085, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "cpfCnpj", "label": "cpfCnpj", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10086, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10087, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10088, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "placa", "label": "placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10089, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10090, campos: [
        { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 4, "required": false, "error": false, "requiredOptional": ["chassi"], "lastCampoRequiredOptional": "chassi", "divisor": true }, 
        { "tipo": "texto", "prop": "chassi", "placeholder": "Chassi", "label": "Chassi", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": ["renavam"], "lastCampoRequiredOptional": "chassi" }
    ] }
    , { idAto: 10091, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10092, campos: [
        { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 4, "required": false, "error": false, "requiredOptional": ["chassi"], "lastCampoRequiredOptional": "chassi", "divisor": true }, 
        { "tipo": "texto", "prop": "chassi", "placeholder": "Chassi", "label": "Chassi", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": ["renavam"], "lastCampoRequiredOptional": "chassi" }
    ] }
    , { idAto: 10093, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10094, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10095, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10096, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10097, campos: [{ "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10098, campos: [
        { "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 4, "required": true, "error": false }, 
        { "tipo": "texto", "prop": "chassi", "placeholder": "Chassi", "label": "Chassi", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }, 
        { "tipo": "bool", "prop": "detalhamentoMultasAutuacoes", "placeholder": "Detalhamento Multas e Autuações", "label": "Detalhamento Multas e Autuações", "tamanhoMaximo": 100, "largura": 6, "required": false, "error": false }
    ] }
    , { idAto: 10099, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10100, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }, { "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10101, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10102, campos: [
        { "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, 
        { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": false, "error": false }
    ] }
    , { idAto: 10103, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10104, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10105, campos: [{ "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10106, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10107, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10108, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10109, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10110, campos: [{ "tipo": "uf", "prop": "uf", "placeholder": "UF", "label": "UF", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10111, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10112, campos: [
        { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 4, "required": false, "error": false, "requiredOptional": ["chassi"], "lastCampoRequiredOptional": "chassi", "divisor": true }, 
        { "tipo": "texto", "prop": "chassi", "placeholder": "Chassi", "label": "Chassi", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": ["renavam"], "lastCampoRequiredOptional": "chassi" }, 
        { "tipo": "texto", "prop": "codigo", "placeholder": "Código de segurança", "label": "Código", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }
    ] }
    , { idAto: 10113, campos: [{ "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }, { "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }, { "tipo": "texto", "prop": "senha", "placeholder": "Senha", "label": "Senha", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10114, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10115, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }, { "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10116, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10117, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10118, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10119, campos: [
      { 
        "tipo": "uf", 
        "prop": "uf", 
        "placeholder": "UF", 
        "label": "UF", "tamanhoMaximo": 100, 
        "largura": 50, 
        "required": true, 
        "error": false, 
        "valueUfDefault": {
            "Id": "65b11f81c0095aa0cc39353d",
            "IdEstado": 11,
            "Sigla": "MG",
            "Nome": "Minas Gerais"
        }, 
        "disabled": true 
      }, 
      { "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4 , "required": false, "error": false,"requiredOptional": ["inscricaoEstadualMg"], "lastCampoRequiredOptional": "inscricaoEstadualMg", "divisor": true }, 
      { "tipo": "texto", "prop": "inscricaoEstadualMg", "placeholder": "Inscrição Estadual Mg", "label": "Inscrição Estadual Mg", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": ["cpfCnpj"], "lastCampoRequiredOptional": "inscricaoEstadualMg" }, 
      { "tipo": "texto", "prop": "cep", "placeholder": "CEP", "label": "CEP", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, 
      { "tipo": "texto", "prop": "numero", "placeholder": "Número", "label": "Número", "tamanhoMaximo": 100, "largura": 50, "error": false }, 
      { 
        "tipo": "select", 
        "prop": "tipoComplemento", 
        "placeholder": "Tipo Complemento", 
        "label": "Tipo Complemento", 
        "tamanhoMaximo": 100, 
        "largura": 50, 
        "required": false, 
        "requiredDependency": "complemento", 
        "error": false,
        "itensSelect": [
            { "value" : "ALA", "description": "ALA" },
            { "value" : "ANDAR", "description": "ANDAR" },
            { "value" : "ANEXO", "description": "ANEXO" },
            { "value" : "APARTAMENTO", "description": "APARTAMENTO" },
            { "value" : "AREA", "description": "AREA" },
            { "value" : "BLOCO", "description": "BLOCO" },
            { "value" : "BOX", "description": "BOX" },
            { "value" : "CASA", "description": "CASA" },
            { "value" : "COBERTURA", "description": "COBERTURA" },
            { "value" : "CONDOMÍNIO", "description": "CONDOMÍNIO" },
            { "value" : "CONJUNTO", "description": "CONJUNTO" },
            { "value" : "ENTRADA", "description": "ENTRADA" },
            { "value" : "ESTAÇÃO", "description": "ESTAÇÃO" },
            { "value" : "FAVELA", "description": "FAVELA" },
            { "value" : "FAZENDA", "description": "FAZENDA" },
            { "value" : "FORTALEZA", "description": "FORTALEZA" },
            { "value" : "FRENTE", "description": "FRENTE" },
            { "value" : "FUNDOS", "description": "FUNDOS" },
            { "value" : "GALERIA", "description": "GALERIA" },
            { "value" : "GALPÃO", "description": "GALPÃO" },
            { "value" : "GLEBA", "description": "GLEBA" },
            { "value" : "GRUPO", "description": "GRUPO" },
            { "value" : "ILHA", "description": "ILHA" },
            { "value" : "JUNTO", "description": "JUNTO" },
            { "value" : "LOJA", "description": "LOJA" },
            { "value" : "LOJAS", "description": "LOJAS" },
            { "value" : "LOTE", "description": "LOTE" },
            { "value" : "LOTEAMENTO", "description": "LOTEAMENTO" },
            { "value" : "MERCADO", "description": "MERCADO" },
            { "value" : "NOME DO PROPRIETÁRIO", "description": "NOME DO PROPRIETÁRIO" },
            { "value" : "PA", "description": "PA" },
            { "value" : "PAL", "description": "PAL" },
            { "value" : "PARTE", "description": "PARTE" },
            { "value" : "PORTAO", "description": "PORTAO" },
            { "value" : "PORTARIA", "description": "PORTARIA" },
            { "value" : "PROPRIETÁRIO", "description": "PROPRIETÁRIO" },
            { "value" : "PROXIMO", "description": "PROXIMO" },
            { "value" : "QUADRA", "description": "QUADRA" },
            { "value" : "QUILOMETRO", "description": "QUILOMETRO" },
            { "value" : "QUINTA", "description": "QUINTA" },
            { "value" : "S/LJ", "description": "S/LJ" },
            { "value" : "SALA", "description": "SALA" },
            { "value" : "SALAS", "description": "SALAS" },
            { "value" : "SHOPPING", "description": "SHOPPING" },
            { "value" : "SITIO", "description": "SITIO" },
            { "value" : "SOBRADO", "description": "SOBRADO" },
            { "value" : "SOBRELOJA", "description": "SOBRELOJA" },
            { "value" : "SUBSOLO", "description": "SUBSOLO" },
            { "value" : "TERRENO", "description": "TERRENO" },
            { "value" : "TERREO", "description": "TERREO" },
            { "value" : "TORRE", "description": "TORRE" },
            { "value" : "VAGA", "description": "VAGA" },
            {"value" : "VILA", "description": "VILA"}
        ]
      },
      { "tipo": "texto", "prop": "complemento", "placeholder": "Complemento", "label": "Complemento", "tamanhoMaximo": 100, "largura": 50, "required": false, "error": false }, 
    ] }
    , { idAto: 10120, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10121, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10122, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10123, campos: [
      { "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false },
      { "tipo": "cpf", "prop": "cpfSolicitante", "placeholder": "CPF Solicitante", "label": "CPF Solicitante", "tamanhoMaximo": 100, "largura": 50, "required": false, "error": false, "dependencyLengthCampo": { "campo": "cpfCnpj", "length": 14 } }
    ] }
    , { idAto: 10124, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10125, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10126, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10127, campos: [{ "tipo": "texto", "prop": "placa", "placeholder": "Placa", "label": "Placa", "tamanhoMaximo": 9, "largura": 50, "required": true, "error": false }, { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10128, campos: [{ "tipo": "cnpj", "prop": "cnpj", "placeholder": "CNPJ", "label": "CNPJ", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "cei", "placeholder": "CEI", "label": "CEI", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10129, campos: [
        { "tipo": "texto", "prop": "cbmerj", "placeholder": "CBME RJ", "label": "CBME RJ", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, 
        { "tipo": "texto", "prop": "inscricaoPredial", "placeholder": "Inscrição Predial", "label": "Inscrição Predial", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, 
        { "tipo": "texto", "prop": "municipio", "placeholder": "Município", "label": "Município", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }
    ] }
    , { idAto: 10130, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10131, campos: [{ "tipo": "cpf", "prop": "cpf", "placeholder": "CPF de login", "label": "CPF", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "senha", "placeholder": "Senha para login", "label": "Senha", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "nire", "placeholder": "NIRE a ser pesquisado", "label": "NIRE", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "nome", "placeholder": "Nome a ser pesquisado", "label": "Nome", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10132, campos: [{ "tipo": "cpf", "prop": "cpf", "placeholder": "CPF de login", "label": "CPF", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "senha", "placeholder": "Senha para login", "label": "Senha", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "nire", "placeholder": "NIRE a ser pesquisado", "label": "NIRE", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "nome", "placeholder": "Nome a ser pesquisado", "label": "Nome", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10133, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10134, campos: [{ "tipo": "tipoMPSP", "prop": "tipo", "placeholder": "Tipo", "label": "Tipo", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }, { "tipo": "texto", "prop": "nome", "placeholder": "Nome", "label": "Nome", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "nomeMae", "placeholder": "Nome da Mãe", "label": "Nome da Mãe", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "data", "prop": "dataNascimento", "placeholder": "Data de Nascimento", "label": "Data de Nascimento", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10135, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10136, campos: [{ "tipo": "texto", "prop": "nome", "placeholder": "Nome", "label": "Nome", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10137, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10138, campos: [
        { "tipo": "cpf", "prop": "cpf", "placeholder": "CPF a ser pesquisado", "label": "CPF", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": [ "cnpj", "cnpjBase", "aiim", "etiqueta", "inscricaoEstadualSp", "renavam" ], "lastCampoRequiredOptional": "renavam", "divisor": true }, 
        { "tipo": "cnpj", "prop": "cnpj", "placeholder": "CNPJ a ser pesquisado", "label": "CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": [ "cpf", "cnpjBase", "aiim", "etiqueta", "inscricaoEstadualSp", "renavam" ], "lastCampoRequiredOptional": "renavam", "divisor": true }, 
        { "tipo": "texto", "prop": "cnpjBase", "placeholder": "Raiz do CNPJ, primeira parte", "label": "CNPJ Base", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": [ "cnpj", "cpf", "aiim", "etiqueta", "inscricaoEstadualSp", "renavam" ], "lastCampoRequiredOptional": "renavam", "divisor": true }, 
        { "tipo": "texto", "prop": "aiim", "placeholder": "Número do AIIM", "label": "AIIM", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false,"requiredOptional": [ "cnpj", "cnpjBase", "cpf", "etiqueta", "inscricaoEstadualSp", "renavam" ], "lastCampoRequiredOptional": "renavam","divisor": true }, 
        { "tipo": "texto", "prop": "etiqueta", "placeholder": "Etiqueta", "label": "Etiqueta", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": [ "cnpj", "cnpjBase", "aiim", "cpf", "inscricaoEstadualSp", "renavam" ], "lastCampoRequiredOptional": "renavam", "divisor": true }, 
        { "tipo": "texto", "prop": "inscricaoEstadualSp", "placeholder": "Inscrição Estadual Sp", "label": "Inscrição Estadual Sp", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": [ "cnpj", "cnpjBase", "aiim", "etiqueta", "cpf", "renavam" ], "lastCampoRequiredOptional": "renavam", "divisor": true }, 
        { "tipo": "numero", "prop": "renavam", "placeholder": "Renavam", "label": "Renavam", "tamanhoMaximo": 11, "largura": 4, "required": false, "error": false,"requiredOptional": [ "cnpj", "cnpjBase", "aiim", "etiqueta", "inscricaoEstadualSp", "cpf" ], "lastCampoRequiredOptional": "renavam" }
    ] }
    , { idAto: 10139, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10140, campos: [{ "tipo": "texto", "prop": "nome", "placeholder": "Nome", "label": "Nome", "tamanhoMaximo": 100, "largura": 7, "required": true, "error": false }, { "tipo": "breakLine" }, { "tipo": "texto", "prop": "rg", "placeholder": "RG", "label": "RG", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }, { "tipo": "data", "prop": "dataNascimento", "placeholder": "Data de Nascimento", "label": "Data de Nascimento", "tamanhoMaximo": 100, "largura": 3, "required": true, "error": false }] }
    , { idAto: 10141, campos: [
        { "tipo": "texto", "prop": "nome", "placeholder": "Nome", "label": "Nome", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, 
        { "tipo": "cpf", "prop": "cpf", "placeholder": "CPF", "label": "CPF", "tamanhoMaximo": 100, "largura": 50, "required": false, "error": false }
    ] }
    , { idAto: 10142, campos: [{ "tipo": "numero", "prop": "inscricaoImobiliaria", "placeholder": "Inscrição Imobiliária", "label": "Inscrição Imobiliária", "tamanhoMaximo": 8, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10143, campos: [{ "tipo": "numero", "prop": "inscricaoImobiliaria", "placeholder": "Inscrição Imobiliária", "label": "Inscrição Imobiliária", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10144, campos: [{ "tipo": "numero", "prop": "inscricaoImobiliaria", "placeholder": "Inscrição Imobiliária", "label": "Inscrição Imobiliária", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10145, campos: [{ "tipo": "texto", "prop": "sql", "placeholder": "SQL(setor-quadra-lote) do imóvel", "label": "SQL(setor-quadra-lote)", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10146, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ de login", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "senha", "placeholder": "Senha para login", "label": "Senha", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "sql", "placeholder": "SQL(setor-quadra-lote) do imóvel", "label": "SQL(setor-quadra-lote) do imóvel", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "anoExercicio", "placeholder": "Ano de exercício do IPTU", "label": "Ano Exercício", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10147, campos: [{ "tipo": "texto", "prop": "sql", "placeholder": "SQL(setor-quadra-lote) do imóvel", "label": "SQL(setor-quadra-lote)", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10148, campos: [
        { "tipo": "cnpj", "prop": "cnpj", "placeholder": "CNPJ", "label": "CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": ["cei"], "lastCampoRequiredOptional": "cei", "divisor": true }, 
        { "tipo": "texto", "prop": "cei", "placeholder": "CEI", "label": "CEI", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": ["cnpj"], "lastCampoRequiredOptional": "cei" }
    ] }
    , { idAto: 10149, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10150, campos: [{ "tipo": "cpf", "prop": "cpf", "placeholder": "CPF", "label": "CPF", "tamanhoMaximo": 14, "largura": 4, "required": true, "error": false }, { "tipo": "data", "prop": "dataNascimento", "placeholder": "Data de Nascimento", "label": "Data de Nascimento", "tamanhoMaximo": 10, "largura": 3, "required": true, "error": false }] }
    , { idAto: 10151, campos: [
        { "tipo": "cnpj", "prop": "cnpj", "placeholder": "CNPJ", "label": "CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": ["cnpjBase"], "lastCampoRequiredOptional": "cnpjBase", "divisor": true }, 
        { "tipo": "cnpj", "prop": "cnpjBase", "placeholder": "CNPJ Base", "label": "CNPJ Base", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": ["cnpj"], "lastCampoRequiredOptional": "cnpjBase" }
    ] }
    , { idAto: 10152, campos: [
        { "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": ["rip"], "lastCampoRequiredOptional": "rip", "divisor": true }, 
        { "tipo": "texto", "prop": "rip", "placeholder": "RIP", "label": "RIP", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": ["cpfCnpj"], "lastCampoRequiredOptional": "rip" }
    ] }
    , { idAto: 10153, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10154, campos: [{ "tipo": "texto", "prop": "car", "placeholder": "CAR", "label": "CAR", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10155, campos: [{ "tipo": "texto", "prop": "codigoImovelRural", "placeholder": "Código Imóvel Rural", "label": "Código Imóvel Rural", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "uf", "placeholder": "UF", "label": "UF", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "municipio", "placeholder": "Município", "label": "Município", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }, { "tipo": "texto", "prop": "naturezaJuridica", "placeholder": "Natureza Jurídica", "label": "Natureza Jurídica", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10156, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10157, campos: [
        { "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }
    ] }
    , { idAto: 10158, campos: [
      { "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": false, "requiredOptional": ["nome"],"lastCampoRequiredOptional": "nome", "divisor": true, "error": false }, 
      { "tipo": "texto", "prop": "nome", "placeholder": "Nome", "label": "Nome", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": ["cpfCnpj"], "lastCampoRequiredOptional": "nome" },
       { "tipo": "texto", "prop": "foro", "placeholder": "Foro", "label": "Foro", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, 
       { "tipo": "bool", "prop": "pesquisarNomeCompleto", "placeholder": "Pesquisar Nome Completo", "label": "Pesquisar Nome Completo", "tamanhoMaximo": 100, "largura": 50, "required": false, "error": false }
      ] }
    , { idAto: 10159, campos: [{ "tipo": "tipoCertidaoTjDft", "prop": "tipoCertidao", "placeholder": "Tipo Certidão", "label": "Tipo Certidão", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }, { "tipo": "texto", "prop": "nomeMae", "placeholder": "Nome da Mãe", "label": "Nome da Mãe", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10160, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }, { "tipo": "texto", "prop": "nome", "placeholder": "Nome", "label": "Nome", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "instancia", "placeholder": "Instância", "label": "Instância", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "comarca", "placeholder": "Comarca", "label": "Comarca", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "tipoPessoa", "placeholder": "Tipo Pessoa", "label": "Tipo Pessoa", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "naturezaProcesso", "placeholder": "Natureza Processo", "label": "Natureza Processo", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "situacaoParte", "placeholder": "Situação Parte", "label": "Situação Parte", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10161, campos: [
        { "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": false, "error": false, "requiredOptional": ["numeroProcesso"], "lastCampoRequiredOptional": "numeroProcesso", "divisor": true }, 
        { "tipo": "texto", "prop": "numeroProcesso", "placeholder": "Número do Processo", "label": "Número do Processo", "tamanhoMaximo": 100, "largura": 6, "required": false, "error": false, "requiredOptional": ["cpfCnpj"], "lastCampoRequiredOptional": "numeroProcesso" }
    ] }
    , { idAto: 10162, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }, { "tipo": "texto", "prop": "nome", "placeholder": "Nome", "label": "Nome", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "origem", "placeholder": "Origem", "label": "Origem", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "comarca", "placeholder": "Comarca", "label": "Comarca", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "competencia", "placeholder": "Competência", "label": "Competência", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "anoInicial", "placeholder": "AnoInicial", "label": "AnoInicial", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "anoFinal", "placeholder": "Ano Final", "label": "Ano Final", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "somenteProcessosEmAndamento", "placeholder": "Somente Processos em Andamento", "label": "Somente Processos em Andamento", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10163, campos: [{ "tipo": "texto", "prop": "tipo", "placeholder": "Tipo", "label": "Tipo", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }, { "tipo": "texto", "prop": "nome", "placeholder": "Nome", "label": "Nome", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "endereco", "placeholder": "Endereço", "label": "Endereço", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "rg", "placeholder": "RG", "label": "RG", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "orgaoExpedidorRg", "placeholder": "Orgão Expedidor RG", "label": "Orgão Expedidor Rg", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "uf", "prop": "ufRg", "placeholder": "UF Rg", "label": "UF RG", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "nomeMae", "placeholder": "Nome da Mãe", "label": "Nome da Mãe", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "data", "prop": "dataNascimento", "placeholder": "Data de Nascimento", "label": "Data de Nascimento", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10164, campos: [{ "tipo": "texto", "prop": "modelo", "placeholder": "Modelo", "label": "Modelo", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }, { "tipo": "texto", "prop": "nome", "placeholder": "Nome", "label": "Nome", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "rg", "placeholder": "RG", "label": "RG", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "nomeMae", "placeholder": "Nome da Mãe", "label": "Nome da Mãe", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "dataNascimento", "placeholder": "Data de Nascimento", "label": "Data de Nascimento", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "sexo", "placeholder": "Sexo", "label": "Sexo", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }, { "tipo": "texto", "prop": "email", "placeholder": "E-mail", "label": "E-mail", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10165, campos: [
        { "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 3, "required": false, "error": false, "requiredOptional": ["nome", "numeroProcesso"], "lastCampoRequiredOptional": "numeroProcesso", "divisor": true }, 
        { "tipo": "texto", "prop": "nome", "placeholder": "Nome", "label": "Nome", "tamanhoMaximo": 100, "largura": 3, "required": false, "error": false, "requiredOptional": ["cpfCnpj", "numeroProcesso"], "lastCampoRequiredOptional": "numeroProcesso", "divisor": true }, 
        { "tipo": "texto", "prop": "numeroProcesso", "placeholder": "Número do Processo", "label": "Número do Processo", "tamanhoMaximo": 100, "largura": 3, "required": false, "error": false, "requiredOptional": ["cpfCnpj", "nome"], "lastCampoRequiredOptional": "numeroProcesso" },
        { "tipo": "bool", "prop": "pesquisarPorNomeCompleto", "placeholder": "Pesquisar por Nome Completo", "label": "Pesquisar por Nome Completo", "tamanhoMaximo": 100, "largura": 6, "required": false, "error": false }
    ] }
    , { idAto: 10166, campos: [{ "tipo": "cnpj", "prop": "cnpj", "placeholder": "CNPJ", "label": "CNPJ", "tamanhoMaximo": 100, "largura": 50, "required": true, "error": false }] }
    , { idAto: 10167, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10168, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
    , { idAto: 10169, campos: [{ "tipo": "cpfCnpj", "prop": "cpfCnpj", "placeholder": "CPF ou CNPJ", "label": "CPF ou CNPJ", "tamanhoMaximo": 100, "largura": 4, "required": true, "error": false }] }
  
  ];
