<div class="wrapper-item">

  <div class="wrapper-header-item">
    <h3 class="content-title">
      {{ item.produto.titulo }}
    </h3>
    <div class="wrapper-buttons">
      <div class="content-button">
        <button class="buttons-action"  (click)="editar.emit(item)">
          Editar
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/05a3523c983604fe0000ef79b0c38af35a7a81c7e6b32230af7c4a88344d432f?"
               class="img-11" />
        </button>
      </div>
      <div class="content-button">
        <button class="buttons-action" (click)="excluirItem.emit(item)">
          Excluir
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/dcdd700d816beff971594d71d60bdd298e4130c545dd466135231c4783571eb5?"
               class="img-12" />
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="w-100"></mat-divider>


  <div class="wrapper-info-cartorio">
    <div class="wrapper-topo">
      <div class="content-left">
        <span class="title-box">{{item.produto.titulo}}</span>
      </div>
      <div class="content-right">
        <span class="title-box">Valor</span>
        <p class="text-info-box">{{item.valorAto | currency:'BRL'}}</p>
      </div>
    </div>
    <ng-container *ngIf="visibilidadeDetalhes">
      <div class="content-info-solicitante">
        <div *ngIf="item && item.produto && item.produto.titulo === 'Pesquisa de certidão de casamento'">
          <div class="row" *ngFor="let item2 of item.detalhes">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome Completo</h3>
              <p class="info-section">{{ item.nomeCompleto }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Identidade</h3>
              <p class="info-section">{{ item.identidade }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">NIF</h3>
              <p class="info-section">{{ item.nif }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Telefone</h3>
              <p class="info-section">{{ item.telefone }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Email</h3>
              <p class="info-section">{{ item.email }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome do Pai</h3>
              <p class="info-section">{{ item2.nomePai }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Finalidade</h3>
              <p class="info-section">{{ item2.finalidade.descricao }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome Cônjuge</h3>
              <p class="info-section">{{ item2.nomeConjuge }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome da Mãe</h3>
              <p class="info-section">{{ item2.nomeMae }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Lugar do Casamento</h3>
              <p class="info-section">{{ item2.pais.pais }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Data do Casamento</h3>
              <p class="info-section">{{ item2.data }}</p>
            </div>

            <div *ngIf="item2.pais.pais === 'Portugal'">
              <div class="row">
                <div class="wrapper-info col-lg-6">
                  <h3 class="title-section">Distrito Detalhes</h3>
                  <p class="info-section">{{ item2.distritoDetalhes.descricao }}</p>
                </div>
                <div class="wrapper-info col-lg-6">
                  <h3 class="title-section">Concelho Detalhes</h3>
                  <p class="info-section">{{ item2.concelhoDetalhes.descricao }}</p>
                </div>
                <div class="wrapper-info col-lg-6">
                  <h3 class="title-section">Freguesia Detalhes</h3>
                  <p class="info-section">{{ item2.freguesiaDetalhes.descricao }}</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Pedido feito por terceiro?</h3>
                <p class="info-section">{{ item.pedidoFeitoPorTerceiro === 'true' ? 'Sim' : 'Não' }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Nº ID Civil</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].identidade }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Nome Completo</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].nomeCompleto }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Número | Ano do registro</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].dataExpedicao }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Data de Nascimento</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].dataNascimento }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Naturalidade</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].pais.pais }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Distrito Solicitação</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].distritoSolicitante.descricao }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Concelho Solicitação</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].concelhoSolicitante.descricao }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Freguesia Solicitação</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].freguesiaSolicitante.descricao }}</p>
              </div>
            </div>
            <div class="row">
              <div class="wrapper-info col-lg-12">
                <h3 class="title-section">Informação Adicional</h3>
                <p class="info-section">{{ item.informacaoAdicional }}</p>
              </div>
            </div>

          </div>
        </div>

        <div *ngIf="item && item.produto && item.produto.titulo === 'Pesquisa de certidão de nascimento'">
          <div class="row" *ngFor="let item2 of item.detalhes">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome Completo</h3>
              <p class="info-section">{{ item.nomeCompleto }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Identidade</h3>
              <p class="info-section">{{ item.identidade }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">NIF</h3>
              <p class="info-section">{{ item.nif }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Telefone</h3>
              <p class="info-section">{{ item.telefone }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Email</h3>
              <p class="info-section">{{ item.email }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome do Pai</h3>
              <p class="info-section">{{ item2.nomePai }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Finalidade</h3>
              <p class="info-section">{{ item2.finalidade.descricao }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome da Mãe</h3>
              <p class="info-section">{{ item2.nomeMae }}</p>
            </div>

            <div class="row">
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Pedido feito por terceiro?</h3>
                <p class="info-section">{{ item.pedidoFeitoPorTerceiro === 'true' ? 'Sim' : 'Não' }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Nº ID Civil</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].identidade }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Nome Completo</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].nomeCompleto }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Número | Ano do registro</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].dataExpedicao }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Data de Nascimento</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].dataNascimento }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Naturalidade</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].pais.pais }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Distrito Solicitação</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].distritoSolicitante.descricao }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Concelho Solicitação</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].concelhoSolicitante.descricao }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Freguesia Solicitação</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].freguesiaSolicitante.descricao }}</p>
              </div>
            </div>
            <div class="row">
              <div class="wrapper-info col-lg-12">
                <h3 class="title-section">Informação Adicional</h3>
                <p class="info-section">{{ item.informacaoAdicional }}</p>
              </div>
            </div>

          </div>
        </div>

        <div *ngIf="item && item.produto && item.produto.titulo === 'Pesquisa de certidão de óbito'">
          <div class="row" *ngFor="let item2 of item.detalhes">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome Completo</h3>
              <p class="info-section">{{ item.nomeCompleto }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Identidade</h3>
              <p class="info-section">{{ item.identidade }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">NIF</h3>
              <p class="info-section">{{ item.nif }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Telefone</h3>
              <p class="info-section">{{ item.telefone }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Email</h3>
              <p class="info-section">{{ item.email }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome do Pai</h3>
              <p class="info-section">{{ item2.nomePai }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Finalidade</h3>
              <p class="info-section">{{ item2.finalidade.descricao }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Data do Óbito</h3>
              <p class="info-section">{{ item2.data }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome da Mãe</h3>
              <p class="info-section">{{ item2.nomeMae }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">País do Óbito</h3>
              <p class="info-section">{{ item2.pais.pais }}</p>
            </div>
            <div *ngIf="item2.pais.pais === 'Portugal'">
              <div class="row">
                <div class="wrapper-info col-lg-6">
                  <h3 class="title-section">Distrito Detalhes</h3>
                  <p class="info-section">{{ item2.distritoDetalhes.descricao }}</p>
                </div>
                <div class="wrapper-info col-lg-6">
                  <h3 class="title-section">Concelho Detalhes</h3>
                  <p class="info-section">{{ item2.concelhoDetalhes.descricao }}</p>
                </div>
                <div class="wrapper-info col-lg-6">
                  <h3 class="title-section">Freguesia Detalhes</h3>
                  <p class="info-section">{{ item2.freguesiaDetalhes.descricao }}</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Pedido feito por terceiro?</h3>
                <p class="info-section">{{ item.pedidoFeitoPorTerceiro === 'true' ? 'Sim' : 'Não' }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Nº ID Civil</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].identidade }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Nome Completo</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].nomeCompleto }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Número | Ano do registro</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].dataExpedicao }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Data de Nascimento</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].dataNascimento }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Naturalidade</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].pais.pais }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Distrito Solicitação</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].distritoSolicitante.descricao }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Concelho Solicitação</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].concelhoSolicitante.descricao }}</p>
              </div>
              <div *ngIf="item.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
                <h3 class="title-section">Freguesia Solicitação</h3>
                <p class="info-section">{{ item.dadosSolicitante[0].freguesiaSolicitante.descricao }}</p>
              </div>
            </div>
            <div class="row">
              <div class="wrapper-info col-lg-12">
                <h3 class="title-section">Informação Adicional</h3>
                <p class="info-section">{{ item.informacaoAdicional }}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <button class="view-more-details" (click)="visibilidadeDetalhes=!visibilidadeDetalhes">
    {{ visibilidadeDetalhes ? 'Ocultar detalhes' : 'Ver detalhes' }}
  </button>
</div>
