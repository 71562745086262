
<div class="container">
  <div class="row">
    <div class="col-md-3">
      <button type="button" (click)="navegar('fadmin/meeting/cadastro-mediacao')" mat-menu-item>
        <a>Nova Reuniao</a>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <mat-card class="inline-calendar-card m-2">
        <mat-calendar [(selected)]="dateSelected" date
                      (selectedChange)="selecionarDataAgendamento($event)"></mat-calendar>
      </mat-card>
    </div>
    <div class="col-md-9">
      <div class="row">
        <div class="col-md-2" *ngFor="let j of [].constructor(5); let i = index">
          {{i}}
          <div [style.heigth]="l==0 && i==0 ? '150px': '50'" style="min-height: 50px; border: 1px solid #c3bdbd; display: flex; justify-content: center; align-items: center;" *ngFor="let k of [].constructor(14); let l= index">
             
            <div *ngIf="l==0 && i==0" style="background:#a1c0fb; height:150px">
              reuniao de 7 as 9
            </div>
          </div>

        </div>
      </div>
      
    </div>
  </div>
</div>
