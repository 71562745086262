<h2>USUÁRIO</h2>

<button mat-raised-button color="primary" class="mr-5" [routerLink]="['/cadastrar-usuario']">Novo</button>
<div class="row mb-0 " *ngFor="let usuario of listUsuario">
  <div class="col-12 col-md-6 p10 card">
    <h3>{{usuario.Nome}}</h3>
  </div>
  <div class="col-12 col-md-5 p10 card">
    {{usuario.Login}}
  </div>
  <div class="col-12 col-md-1 p10 card">
    <img loading="lazy" class="iconXls" src="assets/editar.png" [routerLink]="['/cadastrar-usuario/'+usuario.IdUsuario]">
  </div>
</div>
