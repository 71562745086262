<!--<iframe src="https://admin.e-cartoriodobrasil.com/Pedido/MeusPedidos" style="min-height:800px; width:100%"></iframe>-->
<!--<iframe src="https://homologacao1.e-cartoriotcs.com.br/Usuario/CrossDomainAccess/?org=nac&guid=952fbfe2-e18d-4705-b709-448adca36870&callback=https%3A%2F%2Fhomologacao1.e-cartoriotcs.com.br%2FPedido%2FMeusPedidos" style="min-height:800px; width:100%"></iframe>-->
<img style="display:none" id="logoPrint" src="/assets/cnr/logoCNR.png" />
<section class="header-pedido" id="titleMeusPedidos">
  <div class="container">
    <h2>Currículos</h2>
  </div>
</section>
<div class="wrapper-content e-cartorio">
  <div class="container" id="conpr">
    <form [formGroup]="camposForm" id="filtro">
      <ng-container>
        <div class="row">
          <b>Filtros:</b>
          <div class="col-md-2">
            <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="dataInicial">Data inicial</mat-label>
              <input matInput [matDatepicker]="picker" type="text" formControlName="dataInicial" appMaskDate
                     maxlength="10" (dateInput)="pesquisar()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="dataFinal">Data final</mat-label>
              <input matInput [matDatepicker]="picker2" type="text" formControlName="dataFinal" appMaskDate
                     maxlength="10" (dateInput)="pesquisar()">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Objetivo:</mat-label>
              <mat-select formControlName="objetivo" (selectionChange)="pesquisar()">
                <mat-option value="">Selecione</mat-option>
                <mat-option value="PRIMEIRO EMPREGO">Primeiro Emprego</mat-option>
                <mat-option value="JOVEM APRENDIZ">Jovem Aprendiz</mat-option>
                <mat-option value="ESTÁGIO">Estágio</mat-option>
                <mat-option value="AUXILIAR">Auxiliar</mat-option>
                <mat-option value="ESCREVENTE">Escrevente</mat-option>
                <mat-option value="OUTROS">Outros</mat-option>
              </mat-select>
              <mat-error>Preenchimento obrigatório</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Formação Acadêmica:</mat-label>
              <mat-select formControlName="formacaoAcademica" (selectionChange)="pesquisar()">
                <mat-option value="">Selecione</mat-option>
                <mat-option value="Ensino Fundamental Incompleto">Ensino Fundamental Incompleto</mat-option>
                <mat-option value="Ensino Fundamental em Andamento">Ensino Fundamental em Andamento</mat-option>
                <mat-option value="Ensino Fundamental Completo">Ensino Fundamental Completo</mat-option>
                <mat-option value="Ensino Médio Incompleto">Ensino Médio Incompleto</mat-option>
                <mat-option value="Ensino Médio em Andamento">Ensino Médio em Andamento</mat-option>
                <mat-option value="Ensino Médio Completo">Ensino Médio Completo</mat-option>
                <mat-option value="Ensino Técnico Incompleto">Ensino Técnico Incompleto</mat-option>
                <mat-option value="Ensino Técnico em Andamento">Ensino Técnico em Andamento</mat-option>
                <mat-option value="Ensino Técnico Completo">Ensino Técnico Completo</mat-option>
                <mat-option value="Ensino Superior Incompleto">Ensino Superior Incompleto</mat-option>
                <mat-option value="Ensino Superior em Andamento">Ensino Superior em Andamento</mat-option>
                <mat-option value="Ensino Superior Completo">Ensino Superior Completo</mat-option>
                <mat-option value="Pós-Graduação Especialização Incompleto">Pós-Graduação Especialização Incompleto</mat-option>
                <mat-option value="Pós-Graduação Especialização em Andamento">Pós-Graduação Especialização em Andamento</mat-option>
                <mat-option value="Pós-Graduação Especialização Completo">Pós-Graduação Especialização Completo</mat-option>
                <mat-option value="Pós-Graduação MBA Incompleto">Pós-Graduação MBA Incompleto</mat-option>
                <mat-option value="Pós-Graduação MBA em Andamento">Pós-Graduação MBA em Andamento</mat-option>
                <mat-option value="Pós-Graduação MBA Completo">Pós-Graduação MBA Completo</mat-option>
                <mat-option value="Mestrado Incompleto">Mestrado Incompleto</mat-option>
                <mat-option value="Mestrado em Andamento">Mestrado em Andamento</mat-option>
                <mat-option value="Mestrado Completo">Mestrado Completo</mat-option>
                <mat-option value="Doutorado Incompleto">Doutorado Incompleto</mat-option>
                <mat-option value="Doutorado em Andamento">Doutorado em Andamento</mat-option>
                <mat-option value="Doutorado Completo">Doutorado Completo</mat-option>
                <mat-option value="Pós-Doutorado Incompleto">Pós-Doutorado Incompleto</mat-option>
                <mat-option value="Pós-Doutorado em Andamento">Pós-Doutorado em Andamento</mat-option>
                <mat-option value="Pós-Doutorado Completo">Pós-Doutorado Completo</mat-option>
              </mat-select>
              <mat-error>Preenchimento obrigatório</mat-error>
            </mat-form-field>
</div>
        </div>
        <div class="row" *ngIf="showMaisFiltro">
        </div>
      </ng-container>
    </form>

    <ng-container *ngFor="let item of pedidos; let i = index">
      <div class="card-item" [id]="i+'p'">
        <ng-container *ngIf="true">
          <!--DADOS PESSOAIS *1-->
          <div class="row py-4">
            <div class="col-12" class="linhaTitulo">
              <span class="fs-3 fw-bolder">Dados Pessoais</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4 col-lg-4 col-xl-4 ">
              <h3 class="title-section"><b>Nome Completo</b></h3>
              <span>{{item.nome}}</span>
            </div>
            <div class="col-12 col-md-4 col-lg-4 col-xl-4 ">
              <h3 class="title-section"><b>Data de Nascimento</b></h3>
              <span>{{ item.nascimento | date }}</span>
            </div>
            <div class="col-12 col-md-4 col-lg-4 col-xl-4 ">
              <h3 class="title-section"><b>Celular</b></h3>
              <span>{{ item.celular | phone }}</span>
            </div>
          </div>
          <!--DADOS PESSOAIS *2-->
          <div class="row">
            <div class="col-md-4">
              <h3 class="title-section"><b>Telefone Fixo</b></h3>
              <span>{{ item.telFixo | phone }}</span>
            </div>

            <div class="col-md-3">
              <h3 class="title-section"><b>E-mail</b></h3>
              <span>{{item.email}}</span>
            </div>
            <div class="col-md-3">
              <h3 class="title-section"><b>Profissão</b></h3>
              <span>{{item.profissao}}</span>
            </div>

          </div>

          <!--OBJETIVO-->
          <div class="row py-4">
            <div class="col-12" class="linhaTitulo">
              <span class="fs-3 fw-bolder">Objetivo</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <span>{{item.objetivo}}</span>
            </div>
          </div>

          <!--FORMAÇÃO ACADÊMICA-->
          <div class="row py-4">
            <div class="col-12" class="linhaTitulo">
              <span class="fs-3 fw-bolder">Formação Acadêmica</span>
            </div>
          </div>
          <div class="row" *ngFor="let fa of item.formacaoAcademica">
            <div class="col-12 col-md-3 col-lg-3 col-xl-3">
              <h3 class="title-section"><b>Formação Acadêmica</b></h3>
              {{ fa.formacaoAcademica }}
            </div>
            <div class="col-12 col-md-5 col-lg-5 col-xl-5">
              <h3 class="title-section"><b>Instituição</b></h3>
              {{ fa.instituicaoAcademica }}
            </div>
            <div class="col-12 col-md-2 col-lg-2 col-xl-2">
              <h3 class="title-section"><b>Período Início</b></h3>
              {{ fa.periodoAnoInicio | dateFormat: 'pt-BR' }}
            </div>
            <div class="col-12 col-md-2 col-lg-2 col-xl-2">
              <h3 class="title-section"><b>Período Término</b></h3>
              {{ fa.periodoAnoTermino | dateFormat : 'pt-BR' }}
            </div>
          </div>

          <!--EXPERIÊNCIA PROFISSIONAL-->
          <div class="row py-4">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12" class="linhaTitulo">
              <span class="fs-3 fw-bolder">Experiência Profissional</span>
            </div>
          </div>
          <div class="row" *ngFor="let ep of item.experienciaProfissional">
            <div class="col-12 col-md-3 col-lg-3 col-xl-3">
              <h3 class="title-section"><b>Empresa</b></h3>
              {{ep.experienciaProfissionalEmpresa}}
            </div>
            <div class="col-12 col-md-4 col-lg-4 col-xl-4">
              <h3 class="title-section"><b>Cargo</b></h3>
              {{ep.experienciaProfissionalCargo}}
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 py-4">
              <h3 class="title-section"><b>Atividades</b></h3>
              {{ep.experienciaProfissionalAtividades}}
            </div>
          </div>

          <!--CURSOS LIVRES-->
          <div class="row py-4">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12" class="linhaTitulo">
              <span class="fs-3 fw-bolder">Cursos Livres</span>
            </div>
          </div>
          <div class="row" *ngFor="let curso of item.cursosLivres">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <h3 class="title-section"><b>Nome do Curso</b></h3>
              <span>{{curso.cursosLivresNomeCurso}}</span>
            </div>
            <div class="col-12 col-md-5 col-lg-5 col-xl-5">
              <h3 class="title-section"><b>Instituição</b></h3>
              <span>{{curso.cursosLivresInstituicao}}</span>
            </div>
            <div class="col-12 col-md-1 col-lg-1 col-xl-1">
              <h3 class="title-section"><b>Ano</b></h3>
              <span>{{curso.cursosLivresAno}}</span>
            </div>
          </div>

          <!--IDIOMAS-->
          <div class="row py-4">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12" class="linhaTitulo">
              <span class="fs-3 fw-bolder">Idiomas</span>
            </div>
          </div>
          <div class="row" *ngFor="let idiomas of item.Idiomas">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <h3 class="title-section"><b>Língua</b></h3>
              <span>{{idiomas.IdiomasLingua}}</span>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <h3 class="title-section"><b>Nível</b></h3>
              <span>{{idiomas.idiomasNivel}}</span>
            </div>
          </div>

          <!--Upload and Download-->
          <div class="row py-4">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12" class="linhaTitulo">
              <span class="fs-3 fw-bolder">Arquivos</span>
            </div>
          </div>
          <div class="row py-3" *ngFor="let arquivo of item.arquivos">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12">
              <div class="row">
                <div class="col-2 d-flex justify-content-center">
                  <label><strong>Arq</strong></label>
                </div>
                <div class="col-10 d-flex justify-content-start">
                  <label><strong>Nome do Arquivo</strong></label>
                </div>
              </div>
              <div class="row">
                <div class="col-2 d-flex justify-content-center">
                  <span><a [href]="arquivo.nome" target="_blank"><img src="../../../assets/download-ico.svg" ></a></span>
                </div>
                <div class="col-10 d-flex justify-content-start align-items-center">
                  <span>{{ arquivo.nomeOriginal }}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <h2></h2>
    <mat-paginator id="paginacaoItens" [length]="totalItens" [pageSize]="tamanhoPagina"
                   [pageSizeOptions]="[5, 10, 25, 100]" (page)="alterarPagina($event)" aria-label="Selecionar página">
    </mat-paginator>
    <h2></h2>
  </div>
</div>
