<div class="prot-novo-geral">
  <div class="prot-novo-conteudo">
    <div class="prot-veri">
      <div class="prot-veri-conteudo">

        <div *ngIf="!concluido">
          <form class="d-flex flex-wrap flex-sm-nowrap flex-column col-lg-12" (ngSubmit)="consultar()"
            [formGroup]="formBusca">

            <div class="prot-tab-inputs">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Nome completo</mat-label>
                <input matInput type="text" class="col-lg-6" formControlName="Nome" placeholder="Nome completo">
                <mat-error *ngIf="f.Nome.errors?.['required']">
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">

                <mat-label>E-mail</mat-label>
                <input matInput type="email" class="col-lg-6  email" formControlName="Email" placeholder="E-mail">
                <mat-error *ngIf="f.Email.errors?.['required']">
                  Preenchimento obrigatório
                </mat-error>
                <mat-error *ngIf="f.Email.errors?.['pattern']">
                  E-mail inválido
                </mat-error>
              </mat-form-field>
            </div>
            <div class="prot-tab-inputs">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">

                <mat-label>Telefone</mat-label>
                <input matInput type="text" class="col-lg-6" mask="(00) 00000-0000||(00) 0000-0000" formControlName="Telefone" placeholder="Telefone">
                <mat-error *ngIf="f.Telefone.errors?.['required']">
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">

                <mat-label>Assunto</mat-label>
                <input matInput type="text" class="col-lg-6" formControlName="Assunto" placeholder="Assunto">
                <mat-error *ngIf="f.Assunto.errors?.['required']">
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <div class="prot-tab-inputs">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">

                <mat-label>mensagem</mat-label>
                <textarea matInput formControlName="Mensagem" placeholder="Mensagem" cdkTextareaAutosize
                  cdkAutosizeMinRows="10" cdkAutosizeMaxRows="50"></textarea>

                <mat-error *ngIf="f.Mensagem.errors?.['required']">
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <button class="prot-botao-form-azul">Enviar mensagem</button>
          </form>
        </div>
        <div *ngIf="concluido">
          <h3>Sucesso!!</h3><br>
          <p>Mensagem enviada com sucesso. Responderemos em breve.</p><br>
          <button class="prot-botao-form-azul" (click)="voltar()">Voltar</button>

        </div>
      </div>
    </div>
  </div>
</div>
