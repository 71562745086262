<div class="wrapper-item" *ngIf="!compra?.editar">

  <h2 class="title-info-solicitante" *ngIf="compra.proprietario?.length > 1; else singleOwner">
    Dados dos Proprietários
  </h2>
  <ng-template #singleOwner>
    <h2 class="title-info-solicitante">Dados do Proprietário</h2>
  </ng-template>
  <div *ngFor="let proprietario of compra.proprietario">
    <div class="row">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Nome Completo</b></h3>
        <p class="info-section">{{proprietario.nomeCompleto}}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>CPF</b></h3>
        <p class="info-section">{{proprietario.cpf}}</p>
      </div>
    </div>
    <div class="row">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Identidade</b></h3>
        <p class="info-section">{{proprietario.identidade}}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Orgão Expedidor</b></h3>
        <p class="info-section">{{proprietario.orgaoExpedidor}}</p>
      </div>
    </div>
    <div class="row">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Data Expedição</b></h3>
        <p class="info-section">{{proprietario.dataExpedicao | date: 'dd/MM/yyyy':'UTC'}}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Email</b></h3>
        <p class="info-section">{{proprietario.email}}</p>
      </div>
    </div>
    <div class="row">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Telefone</b></h3>
        <p class="info-section">{{proprietario.telefone}}</p>
      </div>
    </div>

    <h2 class="title-info-solicitante">Dados do PET</h2>
    <div class="row">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Nome</b></h3>
        <p class="info-section">{{compra.dadosPet.nome}}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Espécie</b></h3>
        <p class="info-section">{{compra.dadosPet.especie}}</p>
      </div>
    </div>
    <div class="row">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Raça</b></h3>
        <p class="info-section">{{compra.dadosPet.raca}}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Sexo</b></h3>
        <p class="info-section">{{compra.dadosPet.sexo}}</p>
      </div>
    </div>
    <div class="row">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Pelagem / Plumagem</b></h3>
        <p class="info-section">{{compra.dadosPet.pelagem}}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Data de Nascimento</b></h3>
        <p class="info-section">{{compra.dadosPet.dataNascimento}}</p>
      </div>
    </div>
    <div class="row">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Idade</b></h3>
        <p class="info-section">{{compra.dadosPet.idade}}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Sinais Características</b></h3>
        <p class="info-section">{{compra.dadosPet.caracteristicas}}</p>
      </div>
    </div>
    <div class="row">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Microchip</b></h3>
        <p class="info-section">{{compra.dadosPet.microchip}}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Endereço</b></h3>
        <p class="info-section">{{compra.dadosPet.endereco}}</p>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Foto do Pet</b></h3>
        <p class="info-section" *ngFor="let arquivo of compra.arquivosFotos">
          <a [href]="arquivo?.nome" target="_blank" class="col-md-6 col-xs-12">
            {{ arquivo.nomeOriginal }}
          </a>
        </p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Documentos de Identificação</b></h3>
        <p class="info-section" *ngFor="let arquivo of compra.arquivosIdentificacao">
          <a [href]="arquivo?.nome" target="_blank" class="col-md-6 col-xs-12">
            {{ arquivo.nomeOriginal }}
          </a>
        </p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Comprovante de Residência</b></h3>
        <p class="info-section" *ngFor="let arquivo of compra.arquivosResidencia">
          <a [href]="arquivo?.nome" target="_blank" class="col-md-6 col-xs-12">
            {{ arquivo.nomeOriginal }}
          </a>
        </p>
      </div>

      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>CERP</b></h3>
        <p class="info-section">{{compra?.idItem}}</p>
      </div>
    </div>

  </div>
</div>

<app-form-14 *ngIf="compra?.editar" [itemEdicao]="compra" [edicao]="true" (cancelarEdicao)="cancelarEdicao(compra)"
  (salvarItem)="salvarItem($event)" [itemAtual]="compra.produto"></app-form-14>
