import { ChangeDetectorRef, AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { globalPage } from '../../_configPage/globalPage';
import { LocalStorage } from '../../_entidade/LocalStorage';
import { CrudService } from '../../_sevice/CrudService';
import { MenuEdicaoTopoComponent } from '../menu-edicao-topo/menu-edicao-topo.component';

@Component({
  selector: 'app-pagina',
  templateUrl: './pagina.component.html',
  styleUrls: ['./pagina.component.less']
})


export class PaginaComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() changeAnotacao = new EventEmitter<any>();
  @ViewChild(MenuEdicaoTopoComponent) child: QueryList<MenuEdicaoTopoComponent>;
  idx = 0;
  navigationSubscription;
  edicaoPG: boolean = false
  conteudoPagina: any
  @Input() iten: any = null
  dataJson: any = new globalPage().Itens;

  searchResults: string[] = [];

  onSearch(query: string, iten: any): void {
    if (!query) {
      iten.searchResults = [];
    } else {
      iten.searchResults = this.simulateSearch(query);
    }
  }

  private simulateSearch(query: string): string[] {
    return ['Resultado 1 para ' + query, 'Resultado 2 para ' + query, 'Resultado 3 para ' + query];
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public service: CrudService,
    private cdr: ChangeDetectorRef,
    public st: LocalStorage
  ) {
    //this.listarEntidadeLocal = debounce(async () => {this.listarEntidadeLocal}, 200)
    this.edicaoPG = this.st.localStorageGet('modoEdicao') != null;

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        // //console.log('ddffd')
        if (this.iten == null)
          this.loadPagina();

      }
    });

  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  ngOnInit() {


    if (this.iten != null) {
      this.conteudoPagina = { itens: [] }
      this.conteudoPagina['itens'].push(this.iten)
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key == 'E' && event.ctrlKey && event.shiftKey) {
      if (this.st.localStorageGet("modoEdicao") == null) {
        this.st.localStorageSet("modoEdicao", 1)
        this.edicaoPG = true
      } else {
        this.st.localStorageRemove("modoEdicao")
        this.edicaoPG = false
      }
      window.location.reload()


    }
  }

  ngAfterViewInit() {

    this.cdr.detectChanges();


  }


  async loadPagina() {
    let token = this.route.snapshot.paramMap.get('modulo')
    // //console.log(token)

    let url = '';

    if (environment.production) {
      if (this.router.url.indexOf('entrar') > -1) {
        url = 'loginentrar'

      } else if (this.router.url.indexOf('pedido/') > -1) {
        url = 'pedidosolicitar'

      } else {
        url = this.router.url.replace(/[/]/g, '')

      }
    }
    else {
      //console.log(this.router.url)
      if (this.router.url.indexOf('entrar') > -1) {
        url = 'loginentrar'

      } else if (this.router.url.indexOf('pedido/') > -1) {
        url = 'pedidosolicitar'

      } else {
        url = this.router.url.replace(/[/]/g, '')

      }

    }


    if (url.indexOf('?') > -1)
      url = url.substr(0, url.indexOf('?'))

    if (url == '')
      url = 'home'



    if (environment.production) {
      let jpg = this.dataJson.find((x: any) => x.Url.replace(/[/]/g, '') == url.replace(/[/]/g, ''))
      //console.log('dataJson', this.dataJson)

      //console.log('environment.production', environment.production) 

      if (jpg != undefined)
        this.conteudoPagina = JSON.parse(jpg.JsonPagina);
      else {
        url = 'home'
        let jpg = this.dataJson.find((x: any) => x.Url.replace(/[/]/g, '') == url.replace(/[/]/g, ''))
        this.conteudoPagina = JSON.parse(jpg.JsonPagina);

      }

      //if (this.st.localStorageGet("projeto") != null) {
      //  let id = this.st.localStorageGet("projeto").IdProjeto

      //  //console.log('loading page', environment.http.API_SISTEMA + 'pagina/GetPage/' + url + '/' + id)

      //  await this.service.getObservableDirect(environment.http.API_SISTEMA + 'pagina/GetPage/' + url + '/' + id).subscribe(
      //    resposta => {
      //      if (resposta) {
      //        if (resposta.JsonPagina != null) {
      //          this.st.localStorageSet('pgInfo', resposta)

      //        }
      //      }


      //    })
      //}
    }
    else {

      if (this.st.localStorageGet("projeto") != null) {
        let id = this.st.localStorageGet("projeto").IdProjeto

        //console.log('loading page', environment.http.API_SISTEMA + 'pagina/GetPage/' + url + '/' + id)

        await this.service.getObservableDirect(environment.http.API_SISTEMA + 'pagina/GetPage/' + url + '/' + id).subscribe(
          resposta => {
            if (resposta) {
              if (resposta.JsonPagina != null) {
                this.st.localStorageSet('pgInfo', resposta)
                this.conteudoPagina = JSON.parse(resposta.JsonPagina);
              } else {
                this.conteudoPagina = null;
              }
            } else {
              this.conteudoPagina = null;
            }


          })
      }
    }

  }
  shoModal: any = false
  openModalBusca() {
    //console.log('dfdfd')
    this.shoModal = true;

  }

  closeModal() {
    this.shoModal = false;

  }

}
