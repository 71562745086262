import { AfterViewInit, Component, HostListener, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl, FormGroup, Validators
} from '@angular/forms';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-form-5',
  templateUrl: './form5.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class Form5Component implements OnInit, AfterViewInit {

  constructor(
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public teste: TesteService,
    private routeAc: ActivatedRoute,
    public service: CrudService
  ) {
  }

  @Input() itemEdicao: any;
  @Output() salvarItem = new EventEmitter<any>();

  classObrigatoriaAviso: any;
  validarArquivoDocumentos: any;
  tipos: any = [{ "Id": 'I', "Descricao": "ICP BRASIL" }, { "Id": 'E', "Descricao": "ELETRONICA" }]

  camposForm = this.formBuilder.group({
    //documentos: [''],
    documentos: new FormControl([], Validators.required),
    assinadores: this.formBuilder.array([this.createAssinatura()]),
    copia: this.formBuilder.array([this.createCopia()]),
  });

  createAssinatura(): FormGroup {
    return this.formBuilder.group({
      nomeCompleto: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.email
      ])],
      cpf: ['', Validators.required],
      tipo: ['', Validators.required],
      telefone: [''],
    });
  }

  createCopia(): FormGroup {
    return this.formBuilder.group({
      nome: [''],
      email: ['', Validators.compose([
        Validators.email
      ])],
    });
  }

  ngOnInit(): void {
    if (document.location.href.indexOf('meu-carrinho') == -1) {

      this.carregarEdicao()
    }
  }

  ngAfterViewInit() { }

  addAssinatura() {
    this.assinadores.push(this.createAssinatura());
  }
  remAssinatura(i: number) {
    this.assinadores.removeAt(i)
  }
  remCopia(i: number) {
    this.copia.removeAt(i)
  }

  addCopia() {
    this.copia.push(this.createCopia());
  }

  get assinadores(): FormArray {
    return this.camposForm.get('assinadores') as FormArray;
  }
  get copia(): FormArray {
    return this.camposForm.get('copia') as FormArray;
  }
  get itemAtual() {
    return this.storage.localStorageGet("item_atual");
  }


  updatePedido() {
    this.camposForm.markAllAsTouched();
    this.ValidarArquivos();
    if (this.camposForm.valid) {
      let prodAtu = this.camposForm.value;
      this.salvarItem.emit(prodAtu);
      this.classObrigatoriaAviso = '';
    } else {
      this.classObrigatoriaAviso = 'redClass'
    }
  }


  documentos: any = []
  setaArquivos(files: any) {
    this.documentos = [...this.documentos, ...files.arquivos]
    this.camposForm.controls['documentos'].setValue(this.documentos);
    this.ValidarArquivos();
  }

  resetaArquivos(entrada: any) {
    this.documentos = entrada.arquivos
    this.camposForm.controls['documentos'].setValue(this.documentos);
  }

  ValidarArquivos() {
    const camposForm = this.camposForm;

    const arquivosDocumentos = camposForm.get('documentos')?.value;

    const arquivosDocumentosPreenchido = arquivosDocumentos && arquivosDocumentos.length > 0;

    if (!arquivosDocumentosPreenchido) {
      this.validarArquivoDocumentos = 'redClass';
    } else {
      this.validarArquivoDocumentos = '';
    }

  }

  prodAtu: any
  carregarEdicao() {
    if (this.routeAc.snapshot.paramMap.get('id') != null) {

      let pedido = this.storage.localStorageGet("pedido")

      this.prodAtu = { ...pedido.listaCompra?.find((x: any) => x.idItem == this.routeAc.snapshot.paramMap.get('id')) }

      if (this.prodAtu != null && this.prodAtu != undefined)
        {
          let qtdeAss = this.prodAtu?.assinadores?.length - 1
          for (var i = 0; i < qtdeAss; i++) {
            this.addAssinatura()
          }
          let qtdeCopia = this.prodAtu?.copia?.length - 1

          for (var i = 0; i < qtdeCopia; i++) {

            this.addCopia()
          }
        }
      //
      this.preencheCampos(this.prodAtu, this.camposForm)

      this.documentos = this.prodAtu?.documentos

    }
    //console.log(this.prodAtu)
  }

  compareTipo(object1: any, object2: any) {
    return object1 && object2 && object1.Id == object2.Id;
  }

  preencheCampos(object: any, form: FormGroup) {
    for (const key in object) {

      form.get(key)?.setValue(object[key])
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {
      for (let control of this.assinadores.controls) {
        if (control instanceof FormControl) {
        }
        if (control instanceof FormGroup) {
          this.teste.setValueForm(control);
        }
        if (control instanceof FormArray) {
        }
      }
    }
  }

}
