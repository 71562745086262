import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {

  conteudo: any
  @Input() projeto: any;


  ngOnInit() {
    if (
      typeof this.projeto.JsonConfiguracao === 'object'
    ) {
      this.conteudo = this.projeto.JsonConfiguracao

    } else {
      this.conteudo = JSON.parse(this.projeto.JsonConfiguracao)

    }
  }

}
