import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Schedule } from './schedule';
import { Expert } from '../experts/expert';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  private apiUrl = `${environment.http.API}MeetingAccess/getAgenda`;

  constructor(private http: HttpClient) {
    console.log(this.apiUrl);
   }

 
  findAllPost(filtroCalendatio: any) {
    return this.http.post<Schedule[]>(`${this.apiUrl}`, filtroCalendatio);
  }
   
  

}
