<div class="wrapper-item">
  <div class="wrapper-header-item">
    <h3 class="content-title">
      {{ item.produto.titulo }}

      <span *ngIf="item.apostilamento=='true'">
        (com apostilamento)
      </span>

    </h3>
    <div class="wrapper-buttons">
      <div class="content-button">
        <button class="buttons-action" (click)="editar.emit(item)">
          Editar
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/05a3523c983604fe0000ef79b0c38af35a7a81c7e6b32230af7c4a88344d432f?" class="img-11" />
        </button>
      </div>
      <div class="content-button">
        <button class="buttons-action" (click)="excluirItem.emit(item)">
          Excluir
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/dcdd700d816beff971594d71d60bdd298e4130c545dd466135231c4783571eb5?" class="img-12" />
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="w-100"></mat-divider>

  <div class="wrapper-info-cartorio">
    <div class="wrapper-topo">
      <div class="content-left">
        <span class="title-box">Cartório selecionado</span>
        <p class="text-info-box">
          {{ item.cartorio.cartorio}}
        </p>
      </div>
      <div class="content-right">
        <span class="title-box">Emolumento</span>
        <p class="text-info-box">{{ item.cartorio.valor | currency:'BRL' }}</p>
      </div>
    </div>
    <ng-container *ngIf="visibilidadeDetalhes">
      <div class="content-info-solicitante">
        <div *ngIf="item.produto.subFormTipo === 'Casamento'">
          <div class="row" *ngFor="let item2 of item.certidaoCasamento">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Cônjuge</h3>
              <p class="info-section">{{ item2.conjuge }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Cônjuge2</h3>
              <p class="info-section">{{ item2.conjuge2 }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">CPF</h3>
              <p class="info-section">{{ item2.cpf | mask:'000.000.000-00||00.000.000/0000-00' }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">CPF2</h3>
              <p class="info-section">{{ item2.cpf2 | mask:'000.000.000-00||00.000.000/0000-00' }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Data de Casamento</h3>
              <p class="info-section">{{ item2.dataCasamento | date:'dd/MM/yyyy' }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Livro</h3>
              <p class="info-section">{{ item2.livro }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Folha</h3>
              <p class="info-section">{{ item2.folha }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Matrícula</h3>
              <p class="info-section">{{ item2.matricula }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Termo</h3>
              <p class="info-section">{{ item2.termo }}</p>
            </div>
          </div>
        </div>
        <div *ngIf="item.produto.subFormTipo === 'Nascimento'">
          <div class="row" *ngFor="let item2 of item.certidaoNascimento">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome Completo</h3>
              <p class="info-section">{{ item2.nomeCompleto }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">CPF</h3>
              <p class="info-section">{{ item2.cpf | mask:'000.000.000-00||00.000.000/0000-00' }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Data de Nascimento</h3>
              <p class="info-section">{{ item2.nascimento | date:'dd/MM/yyyy' }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome da Mãe</h3>
              <p class="info-section">{{ item2.mae }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Livro</h3>
              <p class="info-section">{{ item2.livro }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Folha</h3>
              <p class="info-section">{{ item2.folha }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Matrícula</h3>
              <p class="info-section">{{ item2.matricula }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Termo</h3>
              <p class="info-section">{{ item2.termo }}</p>
            </div>
          </div>
        </div>
        <div *ngIf="item.produto.subFormTipo === 'Óbito'">
          <div class="row" *ngFor="let item2 of item.certidaoObito">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome</h3>
              <p class="info-section">{{ item2.nome }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">CPF</h3>
              <p class="info-section">{{ item2.cpf | mask:'000.000.000-00||00.000.000/0000-00' }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Data do Óbito</h3>
              <p class="info-section">{{ item2.dataObito | date:'dd/MM/yyyy' }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome do Pai</h3>
              <p class="info-section">{{ item2.pai }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome da Mãe</h3>
              <p class="info-section">{{ item2.mae }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Livro</h3>
              <p class="info-section">{{ item2.livro }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Folha</h3>
              <p class="info-section">{{ item2.folha }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Matrícula</h3>
              <p class="info-section">{{ item2.matricula }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Termo</h3>
              <p class="info-section">{{ item2.termo }}</p>
            </div>
          </div>
        </div>
        <!--DADOS ENTREGA-->
        <div class="row">
          <div class="col-md-12">
            <h2 class="text-center">Dados para Entrega</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome Destinatário</h3>
              <p class="info-section">{{ item.dadosEntrega?.nomeDestinatario }}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">CEP</h3>
              <p class="info-section">{{ item.dadosEntrega?.cep }}</p>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-md-8">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Logradouro</h3>
              <p class="info-section">{{ item.dadosEntrega?.endereco }}</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Número</h3>
              <p class="info-section">{{item.dadosEntrega?.numero}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Complemento</h3>
              <p class="info-section">{{item.dadosEntrega?.complemento}}</p>
            </div>
          </div>

          <div class="col-md-6">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Bairro</h3>
              <p class="info-section">{{ item.dadosEntrega?.bairro }}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Cidade</h3>
              <p class="info-section">{{ item.dadosEntrega?.cidade.Nome }}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Estado</h3>
              <p class="info-section">{{ item.dadosEntrega?.uf?.Nome }}</p>
            </div>
          </div>
          
        </div>
      </div>
    </ng-container>
  </div>

  <button class="view-more-details" (click)="visibilidadeDetalhes=!visibilidadeDetalhes">
    {{ visibilidadeDetalhes ? 'Ocultar detalhes' : 'Ver detalhes' }}
  </button>
</div>
