<div class="row">
  <div class="col-lg-12 avisoVermelho" *ngFor="let item of avisoVermelho">
    <p class="mb-0" [innerHTML]="item.mensagem"></p>
  </div>
  <div class="col-lg-12 avisoAmarelo" *ngFor="let item of avisoAmarelo" style="border: solid 1px #b3b3b3;margin-bottom: 8px;border-radius: 0.25rem;border: 1px solid var(--primria-blue-blue-100, #B8BFCC);">
    <p class="mb-0" [innerHTML]="item.mensagem"></p>
  </div>
</div>

<div class="row" *ngFor="let item of aceiteDireto">
  <div class="content-list" [class]="classObrigatoriaAviso">
    <div class="col-md-12">
      <p class="mb-0" [innerHTML]="item.mensagem"></p>
      <p class="mb-0"><mat-checkbox class="example-margin" [(ngModel)]="item.selecionado" (change)="marcaAceite($event,item)">Li e Condordo</mat-checkbox> </p>
    </div>
  </div>
</div>
