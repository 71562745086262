import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TesteService } from 'src/app/_sevice/teste.service';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';


@Component({
  selector: 'app-form-kit',
  templateUrl: './formKit.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class FormKitComponent implements OnInit, AfterViewInit {
  municipios: String[] = [];
  selectedMunicipio: String;
  cartorioEmissor: any[];
  public visibilidadeDetalhes: boolean[] = [];
  isshwo: boolean = false
  @Output() salvarItem = new EventEmitter<any>();
  @Input() uf: any;
  @Input() cidade: any;
  @Input() municipio: any;
   
  isLoading: boolean=false
  @Output() setTemCobertura = new EventEmitter<any>();
  temCobertura: boolean = true
  classObrigatoria: any = ''
  erroCartorio: any
  camposForm: any
  finalidadeCertidao: any[];
  cepInvalido: boolean = false
  cepNaoCorresponde: string = '';
  kit = this.storage.localStorageGet('kit')

  constructor(
    public storage: LocalStorage,
    public teste: TesteService,
    public service: CrudService,
  ) {

  }

  setTemCoberturaFilho(tem: boolean) {
    this.temCobertura = tem
    this.setTemCobertura.emit(tem);

  }

  ngOnInit(): void {
    
  }
   
  ngAfterViewInit() {

  }
      

     
    

}
