<div class="wrapper-item" *ngIf="!compra?.editar">
  <div class="row" *ngFor="let comprador of compra?.compradores">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Nome da Busca</b></h3>
      <p class="info-section">{{comprador.nome}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>CNPJ</b></h3>
      <p class="info-section">{{comprador.documento | mask:'000.000.000-00||00.000.000/0000-00'}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>CERP</b></h3>
      <p class="info-section">{{compra?.idItem}}</p>
    </div>
  </div>
</div>

<app-form-22 *ngIf="compra?.editar" [itemEdicao]="compra" [edicao]="true" (cancelarEdicao)="cancelarEdicao(compra)"
  (salvarItem)="salvarItem($event)" [itemAtual]="compra.produto"></app-form-22>
