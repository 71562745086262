<!--atuando hoje 07/05/2024 17:25-->
<div class="content-wrapper"
     style="height: 13vh; padding-top: 3vh; padding-bottom: 3vh;"
     [style]="conteudo.background">

  <h2 *ngIf="conteudo.titulo" [innerHTML]="conteudo.titulo"></h2>
  <p *ngIf="conteudo?.subTitulo" [innerHTML]="conteudo.subTitulo"></p>
  <div class="row our-partners"  >
    <ng-container *ngFor="let item of conteudo.itens">
      <div class="items" [class]="item.class">
        <a [href]="item.link" target="_blank" *ngIf="item.link">
          <img loading="lazy" [src]="['./../assets/'+item.src]" [alt]="item.alt" [title]="item.alt">
        </a>
        <img *ngIf="item.link != true" loading="lazy" [src]="['./../assets/'+item.src]" [alt]="item.alt" [title]="item.alt">
      </div>
    </ng-container>
  </div>
</div>
