<div class="container-fluid py-0">
  <div class="row py-0 ">
    <div [style]="conteudo.style" class="py-0">
      <div class="col-12 py-5">
          <div class="fs-1 fw-bold text-white py-4 d-flex justify-content-center ">
            <span>Especialistas em documentação</span>
          </div>
          <div class="fs-5 fw-bold text-white py-3 d-flex justify-content-center  ">
            <span class="w-50 text-center">Nossa equipe experiente cuida de tudo para você, desde a solicitação até a entrega do seu documento.</span>
          </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="fs-5 fw-light text-white py-2 d-flex justify-content-center">
            <span class="fs-6">Conheça alguns de nossos parceiros</span>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-center">
        <div class="row">
          <div class="col-4">
            <img src="../../../assets/logo-e-cartorio-bgw.png">
          </div>
          <div class="col-4">
            <img src="../../../assets/logo-lumen-bgw.png">
          </div>
          <div class="col-4">
            <img src="../../../assets/logo-cartorio-protesto-bgw.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
