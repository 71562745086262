<form [formGroup]="camposForm">



  <div class="row">
    <ng-container *ngFor="let item of itemAtualConfig?.campos; let i = index">


      <div *ngIf="item.tipo != 'breakLine'" [class]="'col-lg-'+item.largura">
        <!-- {{item |json}} -->
        <mat-form-field class="w-100" *ngIf="item.tipo=='uf'">
          <mat-label>Estado</mat-label>
          <input type="text" placeholder="Estado"
                 aria-label="Number" matInput [formControlName]="item.prop" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" id="autoUf" [displayWith]="displayProperty">
            <mat-option *ngFor="let item of listufFilter" [value]="item">
              {{item.Nome }}
            </mat-option>
          </mat-autocomplete>
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>


        <div class="d-flex flex-column mb-3" *ngIf="item.tipo=='bool'">
          <mat-label>{{ item.placeholder }}</mat-label>
          <mat-radio-group [formControlName]="item.prop" aria-label="Select an option">
            <mat-radio-button value="sim">Sim</mat-radio-button>
            <mat-radio-button value="nao">Não</mat-radio-button>
          </mat-radio-group>
        </div>

        <mat-form-field *ngIf="item.tipo=='select'" appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>{{ item.label }}</mat-label>
          <mat-select [formControlName]="item.prop">
            <mat-option *ngFor="let itemSelect of item?.itensSelect" [value]="itemSelect.value">
              {{ itemSelect.description }}
            </mat-option>
          </mat-select>
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo"
          *ngIf="item.tipo!='data' && item.tipo!='uf' && item.tipo!='bool' && item.tipo!='select'">
          <mat-label>
            {{item.label}}
          </mat-label>
          <input matInput type="text" [formControlName]="item.prop" [maxlength]="item.tamanhoMaximo"
            [placeholder]="item.placeholder" *ngIf="item.tipo=='texto'">
          <input matInput type="text" [formControlName]="item.prop" [required]="item.required"
            [maxlength]="item.tamanhoMaximo" [placeholder]="item.placeholder" mask="000.000.000-00||00.000.000/0000-00"
            *ngIf="item.tipo=='cpfCnpj' || item.tipo=='cpf' || item.tipo=='cnpj'">

          <input matInput type="text" [formControlName]="item.prop" [required]="item.required"
            [maxlength]="item.tamanhoMaximo" [placeholder]="item.placeholder" (keypress)="apenasNumeros($event)"
            *ngIf="item.tipo=='numero'">

          <mat-error *ngIf="camposForm.get(item.prop)?.errors?.['required']">
            Preenchimento obrigatório
          </mat-error>
          <mat-error *ngIf="camposForm.get(item.prop)?.errors?.['maxlength']">
            Preenchimento obrigatório
          </mat-error>
          <mat-error *ngIf="camposForm.get(item.prop)?.errors?.['mask']">
            Preenchimento incorreto
          </mat-error>
          <mat-error *ngIf="camposForm.get(item.prop)?.errors?.['documentoNotValid']">
            Documento inválido
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100 my-2 prot-tab-input-conteudo" *ngIf="item.tipo=='data'">
          <mat-label for="nascimento">Data de Nascimento</mat-label>
          <input matInput [matDatepicker]="picker" type="text" [formControlName]="item.prop"
            [placeholder]="item.placeholder" appMaskDate maxlength="10">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-lg-1" style="align-content: space-around; text-align: center;"
        *ngIf="item?.requiredOptional && item?.divisor">
        <span>ou</span>
      </div>

      <mat-error style="position: relative; top: -15px" class="col-lg-12"
        *ngIf="item?.requiredOptional && !item?.divisor && item?.erroValidacao">
        preencha um dos campos acima
      </mat-error>

      <div *ngIf="item.tipo == 'breakLine'" class="col-lg-12"></div>
    </ng-container>
  </div>
</form>
<app-aviso [aviso]="itemAtual?.aviso"></app-aviso>

<div class="row">
  <div class="col-md-5"></div>
  <div class="col-auto" *ngIf="!edicao">
    <button type="button" mat-button [routerLink]="['/']" class="btn-next"
      style="color: white; background-color: green;">
      Voltar
    </button>
  </div>
  <div class="col-auto" *ngIf="edicao">
    <button type="button" mat-button (click)="cancelarEdicaoForm()" class="btn-next" style="color: white; background-color: green;">Cancelar</button>
  </div>
  <div class="col-auto">
    <button mat-button (click)="updatePedido()" class="btn-next" style="display: block; margin: 0 auto;">
      <span  *ngIf="!edicao">Continuar</span> 
      <span  *ngIf="edicao">Salvar</span> 
    </button>
  </div>
</div>