import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder, Validators
} from '@angular/forms';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-form-4',
  templateUrl: './form4.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class Form4Component implements OnInit, AfterViewInit {
  @Output() salvarItem = new EventEmitter<any>();
  @Output() setTemCobertura = new EventEmitter<any>();
  @Output() cancelarEdicao = new EventEmitter<any>();
  @Input() uf: any;
  @Input() edicao: any=false;
  @Input() cidade: any;
  temCobertura: boolean = false
  @Input() itemAtual: any;
  @Input() itemEdicao: any;
  classObrigatoria: any = ''
  erroCartorio: boolean = false

  cartorioEmissor: any[];
  public visibilidadeDetalhes: boolean[] = [];

  camposForm = this.formBuilder.group({
    protocolo: ['', Validators.required],
    registro: ['', Validators.required],
    numeroAto: [''],
    dataAto: ['']
  });

  constructor(
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public teste: TesteService,
    private routeAc: ActivatedRoute,
    public service: CrudService
  ) {

  }

  ngOnInit(): void {
    this.carrregarEdicao()
  }

  ngAfterViewInit() {

  }

  setTemCoberturaFilho(tem: boolean) {
    this.temCobertura = tem
    this.setTemCobertura.emit(tem);

  }

  updatePedido() {
    if (this.camposForm.get('dataAto')?.hasError('maxlength'))
        this.camposForm.get('dataAto')?.setErrors(null);
    //let lv = this.camposForm.get('dataAto')?.value
    //console.log(lv)
    //if (lv && lv != '') {
    //  this.camposForm.get('dataAto')?.setValue(new Date(lv).toUTCString())
    //}

    let carsel = this.cartorioEmissor?.filter((x: any) => x.selecionado == true)

    if (carsel == null || carsel.length == 0) {
      this.erroCartorio = true;
      this.classObrigatoria = 'redClass'
    } else {
      this.erroCartorio = false;
      this.classObrigatoria = ''
    }

    this.camposForm.markAllAsTouched();
    if (this.camposForm.valid && !this.erroCartorio) {
      let carsel = this.cartorioEmissor?.filter((x: any) => x.selecionado == true)
      let item: any = this.camposForm.value
      item['listCartorio'] = carsel
      this.salvarItem.emit(item);
    }

  }

  cancelarEdicaoForm() {
    this.cancelarEdicao.emit()
  }

  setCartorios(cartorios: any) {
    this.cartorioEmissor = cartorios
    this.carrregarEdicao()
  }

  carrregarEdicao() {
    let paramId = this.routeAc.snapshot.paramMap.get('id');

    if (paramId != null || this.itemEdicao != null) {
      let pedido = this.storage.localStorageGet("pedido");

      if (pedido?.listaCompra != null && pedido?.listaCompra.length > 0 && paramId != null) {

        let prodAtu = { ...pedido.listaCompra?.find((x: any) => x.idItem == paramId) }

        this.service.preencheCampos(prodAtu, this.camposForm)

      } else {
        let prodAtu = this.itemEdicao
        this.service.preencheCampos(prodAtu, this.camposForm)
      }
    }
  }


  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // //console.log(event.key, event.ctrlKey, event.shiftKey, environment.production)
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {

      this.teste.setValueForm(this.camposForm);

    }
  }

}
