import { Component, OnInit, Inject, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { GlobalService } from './_sevice/GlobalService';
import { LocalStorage } from './_entidade/LocalStorage';
//import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { globalPage } from './_configPage/globalPage';
import { CrudService } from './_sevice/CrudService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
  showHeader: boolean = true
  showFooter: boolean = true
  navigationSubscription;
  projetos: any
  projeto: any = environment.production ? new globalPage().projeto : null
  loading: any
  isAreaCliente: boolean=false
  constructor(
    public router: Router,
    public gs: GlobalService,
    public ls: LocalStorage,
    public service: CrudService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: any
  ) {

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        let url = this.router.url

        if (url.indexOf('?limparCache=1') > -1) {
          this.ls.limpar()
          window.location.href = '/'
        }

        if (url.indexOf('?l=1') > -1) {
          this.ls.localStorageRemove('logado')
        }
        this.projeto = new globalPage().projeto
        this.loading = true
        if (!environment.production) {
          if (this.ls.localStorageGet("projeto") != null) {
            this.projeto = this.ls.localStorageGet("projeto")
            this.loading = true
          } else {
            this.projeto = new globalPage().projeto
            this.ls.localStorageSet("projeto", this.projeto)
            this.loading = true
          }

        }

      }
    });
  }

  closeModal(modal:any) {
  (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%";

  }

  isQA() {
    return this.ls.localStorageGet("qa") == 1

  }
  getIdexAnotacoes() {
    if (document.location.href.indexOf('meu-carrinho') > -1)
      return -1
    else if (document.location.href.indexOf('pagar') > -1)
      return -2
    else if (document.location.href.indexOf('conclusao') > -1)
      return -3
    else if (document.location.href.indexOf('meus-pedidos') > -1)
      return -4
    else if (document.location.href.indexOf('pedido') > -1)
      return (this.itemAtual?.idItemUnico != null
        && this.itemAtual?.idItemUnico != undefined) ? this.itemAtual?.idItemUnico : this.itemAtual?.idAto
    else
      return -5
  }
  get itemAtual() {
    return this.ls.localStorageGet("item_atual");
  }

  async getProjeto() {
    //fianceiro=1, enegocie=3,atanoraial=4, enegocie2=5
    await this.service.getObservableDirect(environment.http.API_SISTEMA + 'projeto/GetById/' + 3).subscribe(
      resposta => {
        if (resposta) {
          this.ls.localStorageSet("projeto", resposta)
        } else {

        }


      })
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //console.log(event.key == 'Ç' && event.ctrlKey && event.shiftKey && !environment.production)
    if (event.key == 'Ç' && event.ctrlKey && event.shiftKey && !environment.production) {

      this.gs.getObservableDirect(environment.http.API_SISTEMA + 'Projeto').subscribe(
        resposta => {
          if (resposta) {
            this.projetos = resposta
            this.openModal()
          }
        })
    }
  }
  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
  ngOnInit() {
     
      var url_string = location.href
      var url = new URL(url_string);
      var userQA = url.searchParams.get("user");
      var qa = url.searchParams.get("qa");
      var keyQa = url.searchParams.get("keyQa");

      if (userQA != null && userQA != undefined && keyQa != null && keyQa != undefined) {
        this.ls.localStorageSet('userTeste', userQA)
        this.ls.localStorageSet('keyQa', keyQa)
        this.ls.localStorageSet('qa', 1)


        document.location.href = document.location.origin

      } else if (qa != null) {
        this.ls.localStorageSet('qa', 1)
        this.ls.localStorageSet('userTeste', userQA)

      }


     
      if (this.ls.localStorageGet('localidade') == null) {
       let locpadrao=  {
       
          uf : { "Id": "65b11f81c0095aa0cc393545", "IdEstado": 19, "Sigla": "RJ", "Nome": "Rio de Janeiro" },
          cidade: { "Id": "65b11fe7c0095aa0cc394397", "IdCidade": "3658", "IdEstado": "19", "Nome": "Rio de Janeiro" }
        
        }
        this.ls.localStorageSet('localidade', locpadrao) 

      }
   



  }


  display = ''
  openModal() {
    this.display = "block";
  }
  onCloseHandled() {
    this.projeto.JsonConfiguracao = JSON.parse(this.projeto.JsonConfiguracao)
    this.ls.localStorageSet("projeto", this.projeto)
    this.ls.localStorageRemove("pgCrudEdit")
    this.ls.localStorageRemove("pgInfo")
    window.location.href = this.projeto.PaginaInicial

  }


}
