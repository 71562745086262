<div *ngIf="modoTeste" class="modoTeste" (click)="preencherDadosTeste()">teste</div>
<form [formGroup]="formDinamico" (ngSubmit)="onSubmit()">
  <div class="row my-3">
    <ng-container *ngFor="let form of forms; let i = index;">
      <ng-container *ngFor="let input of form.campos; let j = index;">
        <div class="col-3" *ngIf="input.tipo == 'pesquisar'">
          <button class="btn btn-form btn-primary" type="button" *ngIf="edicao" (click)="openDialog()">
            + Adicionar
            Campo
          </button>
        </div>

        <div
          *ngIf="!input.ngIf ? true  :  input.ngIf == 'S'  ? true : dados['default'][input.ngIfPropValor] == input.ngIfEscolhaValor"
          class="form-col"
          [class]="input.larguraM  ? 'col-'+   input.larguraM :  crudService.mobileCheck()  ? 'col-12' : 'col-'+   input.largura">

          <button *ngIf="edicao && input && input.tipo != 'pesquisar'" style=" width: 20px !important; border-radius: 1px !important;
          height: 23px !important; padding: 0px !important; margin: 0px !important; min-height: 5px !important;"
            class="btn btn-form btn-primary " type="button" (click)="editarInputProp(input)">
            <i _ngcontent-stl-c282="" class="fa fa-cog"></i>
          </button>
          <ng-container *ngIf="!input.exibir || (input.exibir && showObject(input))">
            <!--<label for="" style="margin-bottom: 0px;" *ngIf="input.label"><span>{{input.label}}: </span></label>-->

            <ng-container *ngIf="input.tipo == 'pesquisar'">
              <button (keydown.space)="$event.preventDefault()" id="btnLimparListaEntidade" type="button"
                class="btn btn-form btn-secondary mr-2" alt="Limpar" title="Limpar" (click)="limpaFormulario()">
                Limpar <i class="fas fa-eraser"></i>
              </button>

              <button (keydown.space)="$event.preventDefault()" title="Pesquisar {{conteudoPagina.title}}"
                id="btnListarEntidade" type="submit" class="btn btn-form btn-primary">
                Pesquisar <i class="fas fa-search"></i>
              </button>

              <!--</mat-form-field>-->
            </ng-container>
            <ng-container *ngIf="input.tipo == 'toggle'">

              <app-input class="input" [input]="input" [dados]="dados" [class]="input.classApp"
                [formDinamico]="formDinamico" (sendNewInputValue)="changeInputValue($event, input)"></app-input>

            </ng-container>
            <ng-container *ngIf="input.tipo == 'fildSet'">
              <fieldset>
                <legend>{{input.legend}}</legend>
                <ul>
                  <ng-container *ngFor="let item of input.itens">
                    <li>{{item}}</li>
                  </ng-container>
                </ul>
              </fieldset>

              <!--</mat-form-field>-->
            </ng-container>
            <ng-container *ngIf="input.tipo == 'legenda'">
              <div class="legenda">


                <div>Legenda:</div>
                <ng-container *ngFor="let item of input.options">
                  <div class="label" [style.background-color]="item.cor">{{item.Descricao}}</div>
                </ng-container>
              </div>


              <!--</mat-form-field>-->
            </ng-container>
            <!-- Radio Cards -->
            <ng-container *ngIf="input.tipo == 'radioCards'">
              <div class="box-radio-cards">
                <div *ngFor="let card of input.cards" class="radio-card">
                  <div class="item-radio">
                    <input type="radio" name="radioItem" value=""
                      [checked]="dados['default'][input.prop] == card.propValue"
                      (change)="changeRadioItem(input, card, dados)" />
                    <span for="radioItem" (click)="changeRadioItem(input, card, dados)"
                      [ngClass]="{'selected': dados['default'][input.prop] == card.propValue}">{{card.labelRadio}}</span>
                  </div>
                  <p>
                    {{ card.descricao }}
                  </p>
                </div>
              </div>
            </ng-container>

            <!-- Table Data -->
            <ng-container *ngIf="input.tipo == 'tableData'">
              <!--<app-table-data [input]="input" [dados]="dados" (setValueData)="setValueData($event)"
        [configTema]="configTema"></app-table-data>-->
              <!--<ng-container *ngFor="let row of input.rows">
          <ng-container *ngIf="row.tipo != 'labelDescription'">
            <div class="table-data">
              <div class="row-table-data" [style]="row.style">
                <span>{{row.label}}</span>
                <span>{{row.valor}}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="row.tipo == 'labelDescription'">
            <ng-container *ngIf="row.icone">
              <div style="display: flex;align-items: center;" [style]="row.labelDescriptionStyle">
                <img [src]="row.icone" [style]="row.estiloIcone" alt="Alternate Text" />
                <span class="label-description" [innerHTML]="row.descricao" [style]="row.estiloDescricao"></span>
              </div>
            </ng-container>

            <ng-container *ngIf="!row.icone">
              <span class="label-description" [innerHTML]="row.descricao" [style]="row.estiloDescricao"></span>
            </ng-container>
          </ng-container>

        </ng-container>-->


            </ng-container>
            <!-- Label Descripton -->
            <ng-container *ngIf="input.tipo == 'labelDescription'">
              <ng-container *ngIf="input.icone">
                <div style="display: flex;align-items: center;" [style]="input.labelDescriptionStyle"
                  [class]="input.class">
                  <img [src]="configTema.logoPrimario" [style]="input.estiloIcone" alt="Alternate Text"
                    *ngIf="input.logoTheme" />
                  <img [src]="input.icone" [style]="input.estiloIcone" alt="Alternate Text" *ngIf="!input.logoTheme" />
                  <span class="label-description" [innerHTML]="input.descricao"
                    [style.color]="input.colorTheme ? configTema[input.colorTheme]: false"
                    [style]="input.estiloDescricao"></span>
                </div>
              </ng-container>

              <ng-container *ngIf="!input.icone">
                <ng-container *ngIf="!input.prop">
                  <span class="label-description" [style.color]="input.colorTheme ? configTema[input.colorTheme]: false"
                    [innerHTML]="sanitizer.bypassSecurityTrustHtml(input.descricao)" [style]="input.estiloDescricao"
                    [class]="input.class"></span>
                </ng-container>
                <ng-container *ngIf="input.prop && dados['default'][input.prop]">
                  <span class="label-description" [style.color]="input.colorTheme ? configTema[input.colorTheme]: false"
                    [innerHTML]="sanitizer.bypassSecurityTrustHtml(dados['default'][input.prop])"
                    [style]="input.estiloDescricao" [class]="input.class"></span>
                  /s
                </ng-container>
              </ng-container>

              <ng-container *ngIf="input.multiTags">



                <!--{{dados | json}}-->
                in
                <ng-container *ngFor="let item of input.multiTags.itens">

                  <div style="margin-top: 10px;">
                    <span style="margin-bottom: 10px; display: block;">{{ item.titulo }} </span>
                    <div class="box-tags my-2">
                      <ng-container *ngIf="item.prop">
                        <ng-container *ngIf="dados['default'][item.prop]">
                          <span class="tag" *ngFor="let tag of dados['default'][item.prop]; let i = index"
                            [style.border]="'1px solid '+configTema.corPrimaria" (click)="selecionarTag(item, tag)"
                            [style.background-color]="item.selectedTag == tag || (!item.selectedTag && i == 0) ? configTema.corPrimaria : 'white'"
                            [style.color]="item.selectedTag == tag || (!item.selectedTag && i == 0) ? 'white' : configTema.corPrimaria"
                            [ngClass]="{'selected-tag': item.selectedTag == tag || (!item.selectedTag && i == 0)}">
                            <ng-container *ngIf="item.icone">
                              <img [src]="item.icone" alt="Alternate Text" *ngIf="(!item.selectedTag && i != 0)" />
                              <img [src]="item.iconeAtivo" alt="Alternate Text"
                                *ngIf="item.selectedTag == tag || !item.selectedTag && i == 0" />
                            </ng-container>
                            {{ tag[item.propLabel]}}
                          </span>
                        </ng-container>
                      </ng-container>

                      <ng-container *ngIf="!item.prop">
                        <span class="tag" *ngFor="let tag of item.tags" (click)="selecionarTag(item, tag)"
                          [ngClass]="{'selected-tag': item.selectedTag == tag || (!item.selectedTag && tag.selected)}">
                          <ng-container *ngIf="item.icone">
                            <img [src]="item.icone" alt="Alternate Text" *ngIf="item.selectedTag != tag" />
                            <img [src]="item.iconeAtivo" alt="Alternate Text"
                              *ngIf="item.selectedTag == tag || !item.selectedTag && tag.selected" />
                          </ng-container>
                          {{ tag.label }}
                        </span>
                      </ng-container>

                    </div>
                  </div>
                </ng-container>
              </ng-container>

            </ng-container>

            <!-- Label form -->
            <ng-container *ngIf="input.tipo == 'label'">
              <label *ngIf="!input.nvl" type="text"> {{ dados['default'][input.prop] }}</label>
              <label *ngIf="input.nvl == 1" type="text"> {{ dados['default'][input.propNvl1][input.prop] }}</label>
              <label *ngIf="input.nvl == 2" type="text">
                {{
                dados['default'][input.propNvl1][input.propNvl2][input.prop]
                }}
              </label>
            </ng-container>

            <ng-container *ngIf="input.tipo == 'labelTitle'">
              <div><label type="text"> {{ input.label }}</label></div>

            </ng-container>
            <ng-container *ngIf="input.tipo == 'h2Title'">
              <h2 type="text"> {{ input.label }}</h2>

            </ng-container>
            <!-- CardBacground -->
            <ng-container *ngIf="input.tipo == 'cardBackround'">
              <ng-container *ngIf="input.backgroundSrc">
                <div class="cardbackground"
                  [ngStyle]="{'background-image': 'url(' + input.backgroundSrc + ')', 'height': input.height, 'background-repeat': 'no-repeat', 'background-size':'cover' }">
                  <ng-container *ngIf="input.icon">
                    <div style="margin-bottom: 10px;">
                      <img [src]="input.icon" alt="Ícone card" />
                    </div>
                  </ng-container>
                  <span class="cardbackground-descritpion" [style]="input.estiloDescricao">{{input.descricao}}</span>
                </div>
              </ng-container>

              <ng-container *ngIf="!input.backgroundSrc">
                <div class="cardbackground" style="width: 100%;">
                  <ng-container *ngIf="input.icon">
                    <div style="margin-bottom: 10px;">
                      <img [src]="input.icon" alt="Ícone card" />
                    </div>
                  </ng-container>
                  <span class="cardbackground-descritpion" [style]="input.estiloDescricao">{{input.descricao}}</span>
                </div>
              </ng-container>
            </ng-container>

            <!-- ItensList -->
            <ng-container *ngIf="!crudService.mobileCheck()">

              <ng-container *ngIf="input.tipo == 'itensList'">
                <div *ngIf="input.bordaTop">
                  <img [src]="input.bordaTop" alt="Alternate Text" style="width: 100%;margin-bottom: -1px;" />
                </div>
                <div class="itensList" [style]="input.cardItensStyle">
                  <h2 [style]="input.h2Style">{{ input.h2 }}</h2>
                  <ng-container *ngFor="let item of input.itens">
                    <!-- Item do rodapé do tipo button -->
                    <ng-container *ngIf="item.tipo == 'evento'">
                      <div style="padding: 0px 40px;">
                        <!--<button [class]="item.class" type="button" (click)="setNewMode('Compra', item)">{{item.label}}</button>-->
                        <button type="button" [class]="item.class"
                          (click)="clickEventFormPage(dados, item, inputs.forms[i].campos)" style="margin-right: 2px;">
                          {{item.label }}
                        </button>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="item.tipo != 'evento'">
                      <div style="padding: 0px 40px;">
                        <div>
                          <img *ngIf="item.icon" [src]="item.icon"
                            style="display: block; margin-left: auto; margin-right: auto " alt="Alternate Text" />
                          <p class="text-center mt-3">{{ item.label }}</p>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
                <div *ngIf="input.bordaBottom">
                  <img [src]="input.bordaBottom" alt="Alternate Text"
                    style="width: 100%;margin-top: -1px; margin-bottom: 15px;" />
                </div>
              </ng-container>

            </ng-container>

            <!-- SELECT  -->

            <ng-container *ngIf="input.tipo == 'select' || input.tipo == 'boleanSelect'">
              <app-input class="input" (sendNewInputValue)="changeInputValue($event, input)" [input]="input"
                [dados]="dados" [formDinamico]="formDinamico"></app-input>

            </ng-container>
            <!-- INPUT TEXT -->
            <ng-container *ngIf="input.tipo == 'texto'
                          ||input.subTipo == 'texto'
                          ">

              <app-input class="input" [input]="input" [dados]="dados"
                (sendNewInputValue)="changeInputValue($event, input)" [formDinamico]="formDinamico"></app-input>

            </ng-container>

            <ng-container *ngIf="input.tipo == 'number'">

              <app-input class="input" [maxlength]="input.tamanhoMaximo" [max]="input.max" [min]="input.min"
                [tipo]="input.tipo" [dados]="dados" [inputType]='input.inputType' [placeholder]="input.placeholder"
                [value]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
                [error]="input.error" [message]="input.message" [type]="input.type"
                [(ngModel)]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
                ngDefaultControl [maskInput]="input.mask"
                (sendNewInputValue)="changeInputValue($event, input)"></app-input>
            </ng-container>
            <!--<ng-container *ngIf="input.tipo == 'intervaloDataQuebrada'">

        <app-intervalo-data-quebrada class="input"
                                     [input]="input"
                                     [dados]="dados"
                                     [formDinamico]="formDinamico"></app-intervalo-data-quebrada>


        <span id="erroFildset" class="messager__error" style="display:none">erro fildese</span>
      </ng-container>-->

            <ng-container *ngIf="input.tipo == 'currency'">
              <app-input class="input" [tipo]="input.tipo" [dados]="dados" [inputType]='input.inputType'
                [placeholder]="input.placeholder"
                [value]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
                [error]="input.error" [message]="input.message" [type]="input.type"
                [(ngModel)]="dados['default'][input.prop]" [maskInput]="input.mask" name="input.prop" ngDefaultControl
                (sendNewInputValue)="changeInputValue($event, input)"></app-input>
            </ng-container>

            <!-- INPUT EMAIL -->
            <ng-container *ngIf="input.tipo == 'email'">

              <app-input class="input" [tipo]="input.tipo" [disabled]="input.disabled || checkDisabledInput(input)"
                [input]="input" [dados]="dados" [inputType]='input.inputType' [placeholder]="input.placeholder"
                [value]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
                [error]="input.error" [message]="input.message" [type]="input.type"
                [(ngModel)]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
                ngDefaultControl [maskInput]="input.mask"
                (sendNewInputValue)="changeInputValue($event, input)"></app-input>
            </ng-container>

            <!-- DATE PICKER -->
            <ng-container *ngIf="input.tipo == 'date'">
              <mat-label for="dataNacimento">{{input.label}}</mat-label>
              <app-input class="input" [placeholder]="input.label" [input]="input" [dados]="dados"
                (sendNewInputValue)="changeInputValue($event, input)" [formDinamico]="formDinamico">
              </app-input>
            </ng-container>

            <ng-container *ngIf="input.tipo == 'radioList'">
              <ng-container *ngFor="let inputOpcoes of input.data;  let inptRdLst = index">
                <div (click)="this.dados['default'][input.prop] = inputOpcoes[input.propIdData]"
                  [class]="inputOpcoes[input.propIdData] === this.dados['default'][input.prop] ? 'selected-radio-box' : 'default'"
                  class="radio-box">
                  <input type="radio" [name]="input.prop" [value]="inputOpcoes[input.propIdData]"
                    [class]="inputOpcoes.class ?inputOpcoes.class : 'defaultRadio' "
                    [(ngModel)]="this.dados['default'][input.prop]" />

                  <span style="font-size: 16px;" class="main-label-checkbox">
                    {{
                    inputOpcoes[input.propLabelData]
                    }}
                  </span><br />
                  <span style="font-size: 15px;" class="sub-label-checkbox">
                    {{
                    inputOpcoes.PersonalizedSubTitle ?
                    getPersonalizedSubTitle(inputOpcoes[input.propLabelSubtitle]) : inputOpcoes[input.propLabelSubtitle]
                    }}
                  </span>

                </div>
              </ng-container>
            </ng-container>

            <!-- EDIÇÃO FOTO PERFIL -->
            <ng-container *ngIf="input.tipo == 'editFotoPerfil'">
              <div class="box-edit-perfil">
                <div class="box-moldura">
                  <img [src]="obterImagemPerfil()" alt="Imagem de perfil" />
                  <!--<img *ngIf="!getImagemPerfil()" src="https://hml-static.kaemi.com.br/site_cliente/42/user_perfil_default.png" alt="Alternate Text" />-->
                </div>
                <div class="box-button">
                  <button [disabled]="isLoginSocial()" (click)="fileImagemPerfil.click()"> TROCAR IMAGEM </button>
                  <input type="file" name="imagemPefil" value="" style="display: none;"
                    (change)="alterarImagemPerfil($event)" accept="image/png, image/jpeg" #fileImagemPerfil />
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="input.tipo == 'listCartoesCredito'">
              <div class="box-cartoes">
                <ng-container
                  *ngIf="dados['default'][input.propCartoes] && dados['default'][input.propCartoes].length > 0">
                  <div class="box-cartao mb-3" *ngFor="let cartao of dados['default'][input.propCartoes]">
                    <div>
                      <div class="box-flag">
                        <img src="./assets/img/flag_card_green.png" alt="Alternate Text" /> <span>
                          {{
                          formataBandeira(cartao.Bandeira)
                          }}
                        </span>
                      </div>

                      <div class="box-info">
                        <span><b>{{ cartao.NomeTitular }}</b></span>
                        <span>{{ formataNumCartao(cartao.Cartao) }} </span>
                        <span>Venc.: {{ cartao.Validade }} </span>
                      </div>
                    </div>
                    <div class="btn-actions">
                      <img src="./assets/img/pencil_edit.png" style="cursor:pointer" (click)="bindDataCard(cartao)"
                        alt="Alternate Text" />
                      <img src="./assets/img/trash_icon.png" style="cursor:pointer;"
                        (click)="showModalRemoveCard = true; entidadeCartao = cartao" alt="Alternate Text" />

                    </div>
                  </div>
                </ng-container>

                <div class="box-add-card mb-3" (click)="showModalCard = true">
                  <img src="./assets/img/icon_add_card.png" alt="Alternate Text" />
                  <span>ADICIONAR CARTÃO</span>
                </div>
              </div>

              <div class="overlay-modal-add-card" *ngIf="showModalCard">
                <div class="modal-add-card">
                  <div class="row" *ngIf="input.form">
                    <ng-container *ngFor="let input of input.form.inputs">
                      <div
                        [class]="input.larguraM  ? 'col-'+   input.larguraM :  crudService.mobileCheck()  ? 'col-12' : 'col-'+   input.largura "
                        [style]="input.style" [ngStyle]="{'margin-bottom': '30px'}">


                        <ng-container *ngIf="input.tipo == 'texto'">


                          <app-input class="input" [tipo]="input.tipo" [dados]="dados" [inputType]='input.inputType'
                            [placeholder]="input.placeholder"
                            [value]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop] ? dados['default'][input.prop] : '' "
                            [error]="input.error" [message]="input.message" [type]="input.type"
                            [(ngModel)]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]"
                            ngDefaultControl [maskInput]="input.mask" [backgroundInput]="input.backgroundInput"
                            (sendNewInputValue)="changeInputValue($event, input)"></app-input>
                        </ng-container>

                      </div>
                    </ng-container>

                  </div>

                  <div class="box-btn">
                    <button (click)="showModalCard = false; modoEdicaoCartao = false;" class="back__btn">
                      CANCELAR
                    </button>
                    <button class="btn-info" (click)="addCartao(dados, input.form, input.form.inputs, input)">
                      {{
                      modoEdicaoCartao ? 'SALVAR':
                      'ADICIONAR'
                      }}
                    </button>
                  </div>
                </div>
              </div>

              <div class="overlay-modal-remove" *ngIf="showModalRemoveCard">
                <div class="modal-remove-card">
                  <span>Deseja realmente remover o cartão?</span>
                  <div class="box-btn">
                    <button class="btn-danger" (click)="removerCartao(input)">Sim</button>
                    <button class="back__btn" (click)="showModalRemoveCard = false; entidadeCartao = null;">
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>

            </ng-container>

            <ng-container *ngIf="input.tipo == 'card'">

              <div class="card" style="margin: 2px 15px;">
                <h3 class="cardTitle text-center">{{input.h1}}</h3>
                <p class="cardDescription text-center">{{input.h2}} </p>
                <!-- Card-body -->
                <div *ngIf="dados.IndexExibicao" style="margin: 5px 2px;">
                  <ng-container *ngIf="dados.IndexExibicao == input.index">
                    <!-- Inputs card -->
                    <ng-container *ngFor="let campo of input.campos">
                      <!-- Card Input Text -->
                      <ng-container *ngIf="campo.tipo == 'texto'">
                        <div class="inputs">
                          <h3> INPUT TEXTO </h3>
                          <!--[mask]="campo.mask"-->
                          <app-input class="input" [tipo]="campo.tipo" [dados]="dados" value=""
                            [placeholder]="campo.placeholder" [error]="campo.error" [message]="campo.message"
                            [type]="campo.type" (sendNewInputValue)="changeInputValue($event, campo)">

                          </app-input>
                        </div>
                      </ng-container>


                      <ng-container *ngIf="campo.tipo == 'radioList'">


                        <ng-container *ngFor="let inputOpcoes of campo.data;  let inptRdLst = index">
                          <input type="radio" [name]="campo.prop" [value]=""
                            [class]="inputOpcoes.class ?inputOpcoes.class : 'defaultRadio' "
                            [(ngModel)]="this.dados['default'][campo.prop]" /> {{campo.data[inptRdLst].Descricao }}
                          <ng-container>

                          </ng-container>
                          <br />

                        </ng-container>
                      </ng-container>

                    </ng-container>
                  </ng-container>

                </div>
                <div>
                  <ng-container *ngFor="let evento of input.eventos">
                    <ng-container *ngIf="!evento.eventType || evento.index != dados.IndexExibicao">
                      <button type="button" (click)="clickEventFormPage(dados, evento, input.campos)"
                        style="margin-right: 2px;">
                        {{ evento.label }}
                      </button>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-container>

            <!-- CardAgendamento -->
            <ng-container *ngIf="input.tipo == 'cardAgendamento'">
              <!--<app-card-agendamento [mode]="input.mode"
                              [dados]="dados"
                              [input]="input"
                              (eventoForm)="eventOutput($event)"
                              [labelButtonConfirm]="input.labelButttonConfirm">

        </app-card-agendamento>-->
            </ng-container>

            <!--<ng-container *ngIf="input.tipo == 'multiple-cards'">
        <app-multiple-card [input]="input" [dados]="dados"></app-multiple-card>
      </ng-container>-->
            <!--<ng-container *ngIf="input.tipo == 'cardExcludindgModal'">
        <app-excluding-modal [checkboxesOptions]="input.listaItensExcludentes" [dados]="dados" [input]="input" (dadosOutput)="teste($event)"></app-excluding-modal>
      </ng-container>-->

            <ng-container *ngIf="input.tipo == 'cardCobertura'">
              <!--<app-not-covered [input]="input"></app-not-covered>-->
            </ng-container>



            <ng-container *ngIf="input.tipo == 'autocomplete'">
              <app-input class="input" (sendNewInputValue)="changeInputValue($event, input)" [input]="input"
                [dados]="dados" [formDinamico]="formDinamico"></app-input>

            </ng-container>

            <!--<ng-container *ngIf="input.tipo == 'dateCalendar'">
        <p-calendar [(ngModel)]="value"
                    [disabledDates]="invalidDates"
                    [readonlyInput]="true"
                    [showIcon]="true"
                    [locale]="ptBr"
                    [showOtherMonths]="false"
                    [disabledDays]="[0, 6]"
                    [disabled]="(input.propDependency && dados['default'][input.propDependency] == null)"
                    dateFormat="dd/mm/yy"
                    (onFocus)="getDates(input, value)"
                    (onSelect)="setDate($event, input)"></p-calendar>

      </ng-container>-->

            <ng-container *ngIf="input.tipo == 'dropdown'">
              dropdow
              <!--<p-dropdown [options]="input.dataComplete"
                    (onChange)="dropdownChange($event, input, dados)"
                    [(ngModel)]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]">
        </p-dropdown>-->
            </ng-container>




            <!-- SWITCH FORM -->
            <ng-container *ngIf="input.tipo == 'switchForm'">
              <ng-container *ngIf="input.modeloSwitch && input.modeloSwitch == 'navs'">
                <div class="switch-navs">
                  <div class="nav" *ngFor="let nav of input.navs; let i = index;"
                    [ngClass]="{'active': nav.prop == dados['default'][input.prop] }"
                    (click)="switchPropForm(input, dados, nav)">
                    <span *ngIf="nav.icone" class="icone-nav">
                      <img [src]="nav.prop == dados['default'][input.prop] ? nav.iconeAtivo : nav.icone"
                        alt="Alternate Text" />
                    </span>
                    <span [style]="nav.prop == dados['default'][input.prop] ? nav.labelStyleAtivo : nav.labelStyle">
                      {{
                      nav.label
                      }}
                    </span>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="input.modeloSwitch && input.modeloSwitch == 'radio'">
                <div class="switch-radio"
                  [ngClass]="{'radio-horizontal': input.alinhamento && input.alinhamento == 'horizontal'}">
                  <ng-container *ngFor="let radio of input.radios">
                    <input type="radio" name="switchInput" [value]="radio.prop" [checked]="radio.checked"
                      (change)="switchPropForm(input, dados, radio)" /><span
                      style="display: block; margin: 0px 50px 0px 5px;" [style]="radio.labelStyle">
                      {{
                      radio.label
                      }}
                    </span>
                  </ng-container>
                </div>
              </ng-container>

              <ng-container *ngIf="!input.modeloSwitch">
                <div class="" style="width: 100%; max-width: 690px; position: relative; margin: auto;">
                  <span [style]="input.style" [ngStyle]="{'display': 'block', 'width': '100%'}"
                    (click)="switchPropForm(input, dados)">
                    {{ input.switched ? input.textoAlternativo : input.texto }}
                  </span>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="input.tipo == 'checkbox'">
              <app-input class="input" (sendNewInputValue)="changeInputValue($event, input)" [input]="input"
                [dados]="dados" [formDinamico]="formDinamico"></app-input>

            </ng-container>

            <!--<ng-container *ngIf="input.tipo == 'checkboxBoolean'">

        <div class="row">

          <div class="col-12">
            <label class="container-checkbox" style="margin-bottom: 0px;">

              <span [innerHTML]="input.labelDescription" [style]="input.labelStyle"></span>

              <ng-container *ngIf="input.subProp">
                <input type="checkbox" value="S" [name]="input.prop" [checked]="dados['default'][input.prop][input.subProp] == 'S'" (change)="changeInputValue($event, input)" [style]="input.style" />

              </ng-container>
              <ng-container *ngIf="!input.subProp">
                <input type="checkbox" value="S" [name]="input.prop" [checked]="dados['default'][input.prop] == 'S'" (change)="changeInputValue($event, input)" [style]="input.style" />
              </ng-container>
              <span class="checkmark" [style.background-color]="configTema.corPrimaria"></span>
            </label>

          </div>
        </div>

      </ng-container>-->
            <!--<ng-container *ngIf="input.tipo == 'listaMCheckbox'">
        <ng-container *ngFor="let data of input.data">
          <div class="row">
            <div class="col-12">
              {{data.Descricao}}
            </div>
            <div class="col-6">
              <input type="checkbox" [checked]="data.FlOriginal== 'S'" (change)="escolhaCheboxU(data,'S')" />Original
            </div>
            <div class="col-6">
              <input type="checkbox" [checked]="data.FlOriginal== 'N'" (change)="escolhaCheboxU(data,'N')" />Personalisado
            </div>
          </div>
        </ng-container>
      </ng-container>-->
            <!--<ng-container *ngIf="input.tipo == 'listaNNiveisCheckbox'">
        <ng-container *ngFor="let nivel of input.data">
          <div class="row"> {{ nivel.label }}</div>
          <ng-container *ngFor="let subNivel of nivel.subNiveis">
            <div class="row ml-2"> {{ subNivel.label }}</div>
            <ng-container *ngFor="let check of subNivel.checks">
              <div class="row ml-4">
                <input type="checkbox" [checked]="check.checked">
                <span style="font-weight: bold;"> {{ check.label }}</span>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>-->
            <!--<ng-container *ngIf="input.tipo == 'listaNNiveis'">
      <div class="row" style="margin-left:15px">

        <ng-container *ngFor="let plDados of input.data;">

          <ng-container *ngFor="let plCampos of input.campos;">

            <div [class]="plCampos.larguraM  ? 'col-'+   plCampos.larguraM :  crudService.mobileCheck()  ? 'col-12' : 'col-'+   plCampos.largura ">
              <label *ngIf="plCampos.label"><strong>{{plCampos.label}}: </strong></label>
              <span *ngIf="plCampos.tipo != 'lista'"> {{ pldados['default'][plCampos.prop] }} </span>

              <ng-container *ngIf="plCampos.tipo == 'lista'">

                <div class="row" style="margin-left:5px">
                  <ng-container *ngFor="let slDados of pldados['default'][plCampos.prop] ;let j = index;">-->
            <!--secItem-->
            <!--<ng-container *ngFor="let slCampos of plCampos.campos;">-->
            <!--{{slCampos |json}}-->
            <!--<div [class]="slCampos.larguraM  ? 'col-'+   slCampos.larguraM :  crudService.mobileCheck()  ? 'col-12' : 'col-'+   slCampos.largura ">

                          <label *ngIf="slCampos.label"><strong>{{slCampos.label}}: </strong></label>
                          <span *ngIf="slCampos.tipo != 'lista'"> {{ sldados['default'][slCampos.prop] }} </span>

                          <ng-container *ngIf="slCampos.tipo == 'lista'">
                            <div class="row" style="margin-left:5px">
                              <ng-container *ngFor="let tlDados of sldados['default'][slCampos.prop] ;">

                                <ng-container *ngFor="let tlCampos of slCampos.campos; ">
                                  <div [class]="tlCampos.larguraM  ? 'col-'+   tlCampos.larguraM :  crudService.mobileCheck()  ? 'col-12' : 'col-'+   tlCampos.largura ">
                                    <label *ngIf="tlCampos.label"><strong>{{tlCampos.label}}: </strong></label>
                                    <input type="radio" [name]="j" />
                                    <span *ngIf="tlCampos.tipo != 'lista'"> {{ tldados['default'][tlCampos.prop] }} </span>
                                  </div>
                                </ng-container>

                              </ng-container>
                            </div>

                          </ng-container>

                        </div>
                      </ng-container>
                    </ng-container>
                  </div>

                </ng-container>
              </div>
            </ng-container>

          </ng-container>
        </div>
      </ng-container>-->
            <!--<ng-container *ngIf="input.tipo == 'containerEventos'">
        <div class="" style="display: flex; justify-content:center; flex-wrap: wrap;">
          <ng-container *ngFor="let evento of input.eventos">
            <button *ngIf="!evento.mode"
                    type="button"
                    [class]="evento.class"
                    [ngClass]="{'btn-social-login': evento.btnRedeSocial }"
                    style="margin-right: 35px;"
                    (click)="clickEventFormPage(dados, evento, inputs.forms[i].campos)">
              <img *ngIf="evento.btnRedeSocial && evento.redeSocial == 'GOOGLE'" src="./assets/img/icon_google.png" class="mr-2" alt="Alternate Text" />
              <img *ngIf="evento.btnRedeSocial && evento.redeSocial == 'FACEBOOK'" src="./assets/img/icon_facebook.png" class="mr-2" alt="Alternate Text" />

              {{evento.labelButton}}
            </button>


          </ng-container>
        </div>
      </ng-container>-->

          </ng-container>
        </div>

        <ng-container *ngIf="input.tipo == 'selectListaValores'">
          <div class="col-md-12">
            <div class="content-list">
              <div class="row">
    
                <ul class="wrapper-items">
                  <li>
                    <ng-container *ngFor="let col of input.campos; ">
                      <div   [class]="col.checkbox ? 'column-checkbox' : 'col-'+ col.largura">
                        <h3>{{col.label}}</h3>
                        <!-- {{item[col.prop]}} -->
                      </div>
                    </ng-container>
                  </li>
                </ul>
                <ul class="wrapper-items">
                  <ng-container *ngFor="let item of input.data; let k = index">
                    <li>
                      <ng-container *ngFor="let col of input.campos; ">
                        <div   [class]="col.checkbox ? 'column-checkbox' : 'col-'+ col.largura">
                          <ng-container *ngIf="!col.checkbox">
                            
                            <ng-container *ngIf="!col.tipo">{{item[col.prop]}}</ng-container>

                            <ng-container *ngIf="col.tipo">
                              <ng-container *ngIf="col.tipo == 'currency'">
                                {{item[col.prop] | currency:'BRL'}}
                              </ng-container>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="col.checkbox">
                            <!-- {{input | json}} -->
                            <mat-checkbox class="example-margin"
                                          [formControlName]="col.prop"
                                          [(ngModel)]="input.subProp ? dados['default'][input.prop][input.subProp] : dados['default'][input.prop]">
                            </mat-checkbox>

                          </ng-container>
                        </div>
                      </ng-container>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>

        </ng-container>

        <ng-container *ngIf="input.breaqLine">
          <div
            [class]="input.larguraM  ? 'col-'+   input.larguraM :  crudService.mobileCheck()  ? 'col-12' : 'col-'+   input.breaqLine">
          </div>
        </ng-container>

      </ng-container>

      <div id="botoes-principal" class="botoes-principal mt-2" *ngIf="editar && !conteudoPagina.formWeb">

        <button (click)="pesquisar()" id="btnCancelarEditarManterFuncao" type="button"
          class="btn btn-form btn-secondary mr-2">
          Cancelar <i class="fas fa-times"></i>
        </button>

        <span [attr.title]="this._tolltipBotaoAcaoSalvar">
          <button id="btnSalvarEditarManterFuncao" type="submit" class="btn btn-form btn-success">
            Salvar
            <i class="fas"></i>
          </button>
          <!--[ngClass]="{ 'fa-ban': _incluirAlterarDesabilitado === false, 'fa-check': _incluirAlterarDesabilitado === true }"-->
          <!--[ngClass]="{ 'desabilitado': _incluirAlterarDesabilitado === false, '': _incluirAlterarDesabilitado === true }"-->

        </span>

      </div>
      <div id="botoes-principal" class="botoes-principal mt-2" *ngIf="conteudoPagina.formWeb">


        <span [attr.title]="this._tolltipBotaoAcaoSalvar">
          <button type="button" (click)="enviarFormUnico(conteudoPagina)" class="btn btn-form btn-success">
            Enviar
            <i class="fas"></i>
          </button>

        </span>

      </div>


      <div style="display: flex; flex-direction: row-reverse; margin: 0 auto;" *ngIf="form.eventos">

        <ng-container *ngFor="let evento of form.eventos; let k = index">
          <ng-container
            *ngIf="!evento.ngIf ? true  :  evento.ngIf == 'S'  ? true : dados['default'][evento.ngIfPropValor] == evento.ngIfEscolhaValor">
            <ng-container *ngIf="evento.tipo == 'eventLabel'">
              <div class="col-12">
                <span class="footer__text" [style]="evento.labelStyle">{{evento.label}} </span><br />
              </div>
            </ng-container>

            <ng-container *ngIf="evento.tipo == 'dropForm'">
              <button class="drop-event-button" (click)="form.extendido = !form.extendido; evento.ativo = !evento.ativo"
                [style.color]="configTema.corTerciaria">
                {{evento.label}}
                <img src="./assets/img/arrow_down_amarelo.png" class="icon-arrow" [ngClass]="{'active': evento.ativo }"
                  alt="Alternate Text" style="margin-left: 10px;" />
              </button>
            </ng-container>

            <ng-container *ngIf="(evento.tipo != 'eventLabel') && (evento.tipo != 'dropForm')">
              <div class="button-rodape">
                <button type="button" [disabled]="avancoDesabilitado && evento.desativavel"
                  [ngClass]="{'disabled': avancoDesabilitado && evento.desativavel }" [class]="evento.class"
                  (click)="clickEventFormPage(dados, evento, inputs.forms[i].campos)"
                  style="margin-left: 35px !important">
                  {{
                  !evento.labelSecundario ? evento.label : evento.labelSecundario && dados['default'][evento.setProp]
                  == evento.propValor ? evento.labelSecundario : evento.label
                  }}
                </button>
              </div>
            </ng-container>
          </ng-container>


        </ng-container>
      </div>



    </ng-container>

  </div>

</form>
<button type="button" class="btn-primary" *ngIf="edicao" (click)="openModal()">adicionar campo</button>
<button (keydown.space)="$event.preventDefault()" [hidden]="true" id="btnModalExcluirEntidade" type="button"
  data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#modalExcluirEntidade">Open
  Modal</button>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="label" id="excluiEntidade">ADICIONAR CAMPO</h5>

        <button style=" width: 20px !important; border-radius: 1px !important;
          height: 23px !important; padding: 0px !important; margin: 0px !important; min-height: 5px !important;"
          class="btn btn-form btn-primary btnRemover" type="button" (click)="removerCampoFiltro()">
          -
        </button>
        <button style=" width: 20px !important; border-radius: 1px !important;
          height: 23px !important; padding: 0px !important; margin: 0px !important; min-height: 5px !important;"
          class="btn btn-form btn-primary " type="button" (click)="mudarPosicao(-1)">
          < </button>
            <button style=" width: 20px !important; border-radius: 1px !important;
          height: 23px !important; padding: 0px !important; margin: 0px !important; min-height: 5px !important;"
              class="btn btn-form btn-primary " type="button" (click)="mudarPosicao(1)">
              >
            </button>
            <button id="btnFechaModalExcluirEntidade" style="width:50px" (keydown.space)="$event.preventDefault()"
              (click)="onCloseHandled()" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
      </div>
      <div class="container-fluid w-100 pt-2">
        <app-card id="cardModalExcluirEntidade" titulo="">
          <div class="row">
            <div class="col-sm-5">
              Tipo:
            </div>
            <div class="col-sm-7">
              <select [(ngModel)]="novoInput.tipo">
                <option value="">Selecione o tipo do campo</option>
                <option value="texto">Texto</option>
                <option value="autocomplete">Auto Complete</option>
                <option value="checkbox">check box</option>
                <option value="select">select</option>
                <option value="boleanSelect">boleanSelect</option>
                <option value="date">date</option>
                <option value="toggle">toggle</option>
                <option value="intervaloDataQuebrada">intervaloDataQuebrada</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5">
              Label:
            </div>
            <div class="col-sm-7">
              <input [(ngModel)]="novoInput.label">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5">
              Place Holder:
            </div>
            <div class="col-sm-7">
              <input [(ngModel)]="novoInput.placeholder">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5">
              Propriedade:
            </div>
            <div class="col-sm-7">
              <input [(ngModel)]="novoInput.prop">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5">
              Tamanho Maximo:
            </div>
            <div class="col-sm-7">
              <input [(ngModel)]="novoInput.tamanhoMaximo">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5">
              Largura:
            </div>
            <div class="col-sm-7">
              <input [(ngModel)]="novoInput.largura">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5">
              Obrigatorio:
            </div>
            <div class="col-sm-7">
              <input [(ngModel)]="novoInput.required">
            </div>
          </div>
        </app-card>
        <div class="input-group mb-3 mt-3 justify-content-end">

          <button (keydown.space)="$event.preventDefault()" (click)="onCloseHandled()" id="btnCancelarExcluirEntidade"
            type="button" class="btn btn-form btn-secondary mr-2" title="Cancelar" data-dismiss="modal">
            Cancelar <i class="fas fa-times"></i>
          </button>
          <button (keydown.space)="$event.preventDefault()" id="btnConfirmarExcluirEntidade" type="button"
            class="btn btn-form btn-success" data-dismiss="modal" *ngIf="add" (click)="addNovoCampo()">
            Adicionar <i class="fas fa-check"></i>
          </button>
          <button (keydown.space)="$event.preventDefault()" id="btnConfirmarExcluirEntidade" type="button"
            class="btn btn-form btn-success" *ngIf="!add" data-dismiss="modal" (click)="alterNovoCampo()">
            Alterar <i class="fas fa-check"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
