import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
import { GenericValidator } from '../../../_sevice/GenericValidator';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';


@Component({
  selector: 'app-form-2',
  templateUrl: './form2.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class Form2Component implements OnInit, AfterViewInit, OnChanges {
  @Input() conteudo: any;
  @Input() iskit: any;

  @Output() salvarItem = new EventEmitter<any>();
  @Output() setTemCobertura = new EventEmitter<any>();
  @ViewChild('stepperPrincipal') stepper: MatStepper;
  @Input() uf: any;
  @Input() edicao: any=false;
  @Input() cidade: any;
  @Input() itemAtual: any;
  @Input() itemEdicao: any;
  @Input() itemAtualKit: any;

  multiplo: boolean = true
  load: boolean = false
  temCobertura: boolean = false
  classObrigatoria: any = ''
  // rows: any[] = [];
  form: FormGroup;
  erroCartorio: boolean = false
  temCompradores: boolean = false
  cartorioEmissor: any[];
  finalidadeCertidao: any[];
  public visibilidadeDetalhes: boolean[] = [];
  isLoading: boolean = false
  @Output() cancelarEdicao = new EventEmitter<any>();


  orderDetails = this.formBuilder.group({
    finalidade: ['', Validators.required],
    compradores: this.formBuilder.array([this.createComprador()]),
    idPedidoAtual: [''],
    aceites: [''],

  });

  createComprador(): FormGroup {
    return this.formBuilder.group({
      id: [crypto.randomUUID()],
      nome: ['', Validators.required],
      documento: ['', Validators.compose([
        Validators.required,
        GenericValidator.isValidCPFCNPJ()
      ])],
      pai: [''],
      mae: [''],
      tipoPessoa: ['fisica'],
      nascimento: ['']
    });
  }

  selectedIndex: any = 0


  constructor(
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public teste: TesteService,
    private cdRef: ChangeDetectorRef,
    private route: Router,
    private routeAc: ActivatedRoute,
    public service: CrudService
  ) {

  }

  ngOnInit(): void {
    if (this.itemAtual?.cartorioMultiplo != undefined)
      this.multiplo = this.itemAtual?.cartorioMultiplo

    this.carrregarEdicao()

  }

  ngAfterViewInit() {
    //console.log(2)
     
    
  }
  cancelarEdicaoForm() {
    this.cancelarEdicao.emit()
  }
  ngOnChanges(changes: SimpleChanges) {
    this.cdRef.detectChanges();

  }


  validaCPFCNPJ(i: number) {
    return (this.getCompradorControl(i).get('documento')?.hasError('documentoNotValid'));
  }

  validaRequired(i: number) {

    return (this.getCompradorControl(i).get('documento')?.hasError('required'));
  }

  remComprador(i: number) {
    this.compradores.removeAt(i)
  }

  addComprador() {
    this.compradores.push(this.createComprador());
  }

  // Getter para obter o FormArray de compradores
  get compradores(): FormArray {

    return this.orderDetails.get('compradores') as FormArray;
  }

  getCompradorControl(index: number): FormGroup {
    return (this.orderDetails.get('compradores') as FormArray).at(index) as FormGroup;
  }

  limparDados(i: number) {
    this.getCompradorControl(i).get('documento')?.reset()
  }

  setTemCoberturaFilho(tem: boolean) {
    this.temCobertura = tem
    this.setTemCobertura.emit(tem);

  }

  cartoriosSelecionados: any=0

  setCartorios(cartorios: any) {

    this.limpaFinalidadeAoTrocarDeCidade()

    this.cartorioEmissor = cartorios

    this.cartoriosSelecionados = this.cartorioEmissor.filter((c: any) => c?.selecionado == true);

    // Verifica se há cartórios selecionados
    if (this.cartoriosSelecionados.length > 0) {
      this.getFinalidade()
      this.carrregarEdicao()
    }
    else
    {
      this.finalidadeCertidao = [];
    }
  }


  getFinalidade() {
    if (this.edicao) {

      this.cartorioEmissor = this.itemEdicao.listCartorio
    }

    //console.log('getFinalidade', this.edicao)
    
    this.cdRef.detectChanges();
    let cart = this.cartorioEmissor?.filter((x: any) => x.selecionado == true).map(({ idCartorio }) => idCartorio);

    if (cart && cart.length > 0) {
      var url = environment.http.API_BACK + `Consulta/FinalidadesPorCartorioAto?idAto=${this.itemAtual?.idAto}&idCartorios=${cart}`
      this.isLoading = true

      this.service.getObservableDirect(url).subscribe({
        next: async (response) => {
          if (response != null) {
              this.finalidadeCertidao = response
              this.isLoading = false
            //
            //Marcação para informar ao Usuario que deve selecionar uma funcionalidade.
            this.orderDetails.controls['finalidade'].setErrors({ required: true })
            this.orderDetails.controls.finalidade.markAsTouched();
            //
            
          }
        }, error: (err) => {

          console.error(err);
        }
      });
    }
  }

  limpaFinalidadeAoTrocarDeCidade() {
    this.orderDetails.controls['finalidade'].setValue('')
  }


  home() {
    this.route.navigate(['home'])
  }
  classObrigatoriaAviso: any;
  temAceiteObrigatorio = false
  aceites: any
  temAceite(event: any) {
    this.temAceiteObrigatorio = true
    this.aceites = event
  }

  async verificarSeEhMesmoPedido(): Promise<Observable<boolean>> {

    return new Promise<Observable<boolean>>((resolve) => {
      return false


      let pedido = this.storage.localStorageGet("pedido");
      let existeMesmoPedido = false;

      if (pedido?.listaCompra != null && pedido?.listaCompra.length) {
        // Cria um mapa de documentos para IDs, para que possamos verificar se um documento duplicado pertence ao mesmo comprador
        let documentosCompradoresMap = new Map(pedido.listaCompra
          .flatMap((item: any) => item?.compradores)
          .map((comprador: any) => [comprador?.documento.replace(/\D/g, ''), comprador?.id])
        );

        this.compradores.controls.forEach((control) => {
          let documentoAtual = control.get('documento')?.value.replace(/\D/g, '');
          let compradorAtualId = control.get('id')?.value;

          // Verifica se o documento já existe no carrinho e se o id é diferente
          if (documentosCompradoresMap.has(documentoAtual) && documentosCompradoresMap.get(documentoAtual) !== compradorAtualId) {
            control.get('documento')?.setErrors({ documentoDuplicado: true });
            existeMesmoPedido = true;
          }
        });
      }

      resolve(of(existeMesmoPedido));
    });
  }

  async updatePedido() {

      if (this.temAceiteObrigatorio) {

        let aceitFinal = this.storage.localStorageGet('aceiteDireto')
        let temsemaceite = aceitFinal.find((x: any) => x.selecionado == false)
        if (temsemaceite == undefined) {
          this.orderDetails.get('aceites')?.setValue(aceitFinal)
          this.classObrigatoriaAviso = ''
        } else {
          this.classObrigatoriaAviso = 'redClass'
          return
        }
      } else {
        this.classObrigatoriaAviso = ''
      }

      for (var i = 0; i < this.compradores.controls.length; i++) {
        let val = this.compradores.controls[i].get('nascimento')?.value
        if (val && val != '')
          this.compradores.controls[i].get('nascimento')?.setValue(new Date(this.compradores.controls[i].get('nascimento')?.value))
      } 

      this.orderDetails.markAllAsTouched();
      let carsel = this.cartorioEmissor?.filter((x: any) => x.selecionado == true)

      if (carsel == null || carsel.length == 0) {
        this.erroCartorio = true;
        this.classObrigatoria = 'redClass'
      } else {
        this.erroCartorio = false;
        this.classObrigatoria = ''
      }
      
      if (this.orderDetails.valid && !this.erroCartorio) {
        let item: any = this.orderDetails.value
        item['listCartorio'] = carsel
        this.salvarItem.emit(item);
      }

    }

  finAtu: any;

  compareFinalidade(object1: any, object2: any) {

    return object1 && object2 && object1.id == object2.id;
  }

  carrregarEdicao() {
    let paramId = this.routeAc.snapshot.paramMap.get('id');

    if (paramId != null || this.itemEdicao != null) {

      

      if (this.itemEdicao == null) {
        let pedido = this.storage.localStorageGet("pedido")

        if (pedido?.listaCompra != null && pedido?.listaCompra.length > 0 && paramId != null) {
          let prodAtu = { ...pedido.listaCompra?.find((x: any) => x.idItem == paramId) }

          //this.cartorioEmissor = prodAtu.listCartorio

          let qtdeAss = prodAtu?.compradores?.length - 1
          //this.getFinalidade()


          //Verificar se existem já existe solicitação, se sim! não poderá adicionar um novo nome.
          let temComprador = prodAtu?.compradores[0]?.nome
          if ((temComprador != null) || (temComprador != '')) {
            this.temCompradores = true
          }
          //

          for (var i = 0; i < qtdeAss; i++) {
            if (this.compradores.controls.length <= qtdeAss) {
              this.addComprador()
            }
              
          }

          this.finAtu = prodAtu?.finalidade

          this.service.preencheCampos(prodAtu, this.orderDetails)


        }
      }
      else {
        

        let prodAtu = this.itemEdicao
        this.getFinalidade()
        this.finAtu = prodAtu?.finalidade

        this.service.preencheCampos(prodAtu, this.orderDetails)

      }
    }


  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // //console.log(event.key, event.ctrlKey, event.shiftKey, environment.production)
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {
      for (let control of this.compradores.controls) {
        if (control instanceof FormControl) {
          // is a FormControl
        }
        if (control instanceof FormGroup) {
          this.teste.setValueForm(control);
        }
        if (control instanceof FormArray) {
          // is a FormArray
        }
      }
    }
  }

}
