
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
//import * as Mailcheck from 'mailcheck';
import { CrudService } from '../../../_sevice/CrudService';
import { GlobalService } from '../../../_sevice/GlobalService';
import { MockService } from '../../../_sevice/MockService';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, AfterViewInit {
  @ViewChild('auto') auto:any;
  @Input() input: any;
  @Input() label: any;
  @Input() type: any;
  @Input() tipo: any;
  @Input() dados: any;
  @Input() idOption: any;
  @Input() keyOption = 'Id';
  @Input() placeholder: any;
  @Input() minlength: any;
  @Input() value: any;
  @Input() initialValue: any;
  @Input() maskInput: any;
  @Input() loadOptionsUrl: any = "N";
  @Input() keyword: any;
  @Input() message: boolean;
  @Input() disabled: boolean;
  @Input() disabledDates: Array<any>;
  @Input() loading: boolean;
  @Input() clearAutoComplete: boolean;
  @Input() error: boolean;
  @Input() options: Array<any>;
  @Input() showMinChar?: boolean = false;
  @Input() backgroundInput: any;
  @Input() width: any;
  @Input() id: any;
  @Input() formDinamico!: FormGroup;
  @Input() inputType: any;

  
  @Output() sendNewInputValue = new EventEmitter<string>();
  @Output() sendBlurValue = new EventEmitter<string>();
  @Output() sendSelectedAutoComplete = new EventEmitter<any>();
  @Output() sendValueAutoComplete = new EventEmitter<any>();
  @Output() sendDate = new EventEmitter<string>();
  @Output() sendRef = new EventEmitter<any>();

  


  focused: boolean;
  autocompleteLoading: boolean = false;
  disableDate = null
  dateFilter: any = null;
  @Input() autoCompleteSelected: any;
  valorAnterior: string;
  descInputAutoComplete: string;
  inputValue:any;
  openCalendarMask = false;
  hidePassword1:boolean=true
  hidePassword2:boolean=true
  @Input() maxlength = null;
  @Input() max = null;
  @Input() min = null;
  selected = '2020';
  //tooltip material
  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[1]);
  boleanSelect:any= [{ Valor: true, 'Descricao': 'Sim' }, { Valor: false,'Descricao': 'Nao' }];

  public masks =
    {
      celularMask: ['(', /[1-9]/, /\d/, ')', ' ', '9', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      cepMask: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
      cpfMask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
      idadeMask: [/\d/, /\d/],
      cartaoMask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
      validadeCartaoMask: [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
      cvvMask: [/\d/, /\d/, /\d/]
    };

  constructor(private cdr: ChangeDetectorRef,
    private crudService: CrudService
    , private globalService: GlobalService
    , public mockService: MockService
    , private http: HttpClient) {

  }
  email = new FormControl('', [Validators.required, Validators.email]);
  Descricao = new FormControl('', [Validators.required, Validators.email]);
  getItenForm(iten: any) {
    return this.formDinamico.get(iten)
  }


  getOptionText(option: any) {
    //console.log(input)
    if (option && option != '')
      return option.Descricao;
  }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  ngOnInit() {
    this.inputType = this.input.tipo;

    if (this.inputType == 'autocomplete') {
      //console.log(this.dados['default'][this.input.prop],this.input.prop)
      if (this.dados['default'][this.input.prop]) {
       
      }
      
      //this.autoCompleteSelected = this.dados['default'][this.input.prop]
    } else if (this.inputType == 'select') {
      if (this.input.urlLoad) {

        this.crudService.getObservable(this.input.urlLoad).subscribe((retorno: any) => {

          this.input.options = retorno

        });
      }
    }


    if (this.input.tipo != null) {
      this.inputType = this.input.tipo
      if (this.inputType == "texto") {
        this.inputType = 'default'
      }


    }

    this.dateFilter = (date: Date) => {
      const filter = this.disabledDates.filter(disableDay => new Date(disableDay).getTime() === new Date(date).getTime())[0];

      if (!filter) return date
      else return null;
    };


  }


  optionSelected(item: any, input: any) {
    //console.log(input)
    this.dados['default'][input.prop] = item
   
  }
  apenasNumeros(event: any) {


    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  togglePasswordVisibility(field: number): void {
    if (field === 1) {
      this.hidePassword1 = !this.hidePassword1;
    } else if (field === 2) {
      this.hidePassword2 = !this.hidePassword2;
    }
  }

  onChangeEmail() {
    this.dados['default'][this.input.prop] = this.suggestedEmail;
    this.suggestedEmail = "";
  }

  onChangeEmailNot() {
    this.suggestedEmail = "";
  }

  suggestedEmail: string;

  suggestEmail() {

    let email = this.dados['default'][this.input.prop];

    //let check = Mailcheck.run({
    //	email: email,
    //	domains: ['hotmail.com', 'gmail.com', 'yahoo.com',],
    //	secondLevelDomains: ['hotmail', 'gmail', 'yahoo'],
    //	topLevelDomains: ["com", "net", "org", "info", "com.br"],
    //	suggested: (suggestion) => {
    //		return suggestion;
    //	},
    //	empty: () => {
    //		return false;
    //	}
    //});

    //if (check && check.full) {
    //	this.suggestedEmail = check.full;
    //}
  }

  


  async ngAfterViewInit() {


  }

  changeInput(event: any): any {
    this.sendNewInputValue.emit(event);
  }
  compareSelect(object1: any, object2: any) {
    return object1 && object2 && Object.values(object1)[0] == Object.values(object2)[0];
  }
  blurInput(event: any, input: any): any {
    setTimeout(() => {
      if (typeof this.dados[input.prop] != 'object') {
        this.dados[input.prop] = { Id: 0, Descricao: '' }

      }
    }, 200);

    if (event.target.max) {

      if (isNaN(event.target.value)) {
        event.target.value = '';
      } else if (Number(event.target.value) > event.target.max) {
        event.target.value = '';
      }
    }
    if (event.target.min) {
      if (isNaN(event.target.value)) {
        event.target.value = '';
      } else if (Number(event.target.value) < event.target.min) {
        event.target.value = '';
      }
    }

    if (this.maskInput == "cepMask") {


      this.buscaCep(this.value)

    }

  }

  buscarEndereco($event: any) {
    let cep = $event.target.value.replace('-', '')
    if (cep.length == 8) {
      //console.log(cep)

      this.http.get('https://viacep.com.br/ws/' + cep + '/json').subscribe({
        next: async (response: any) => {
          this.dados['default']['Logradouro'] = response.logradouro
          this.dados['default']['Bairro'] = response.bairro;
          this.dados['default']['Complemento'] = response.complemento;
          this.dados['default']['Municipio'] = response.localidade; 
          this.dados['default']['UF'] = response.uf;

        },
        error: (err) => {

          console.error(err);
        }
      })

    }
  }

  async buscaCep(cep: string): Promise<any> {

    let cepValido = cep.replace('-', '')

    if (cepValido.length == 8) {
      await this.http.get(`https://viacep.com.br/ws/${cep}/json/`).toPromise()
        .then((retorno: any) => {


          if (this.dados['default']['Aluno']) {
            this.dados['default']['Aluno']['Endereco'] = retorno['logradouro'] ? retorno['logradouro'] : '';
            this.dados['default']['Aluno']['Cidade'] = retorno['localidade'] ? retorno['localidade'] : '';
            this.dados['default']['Aluno']['Bairro'] = retorno['bairro'] ? retorno['bairro'] : '';
            this.dados['default']['Aluno']['Complemento'] = retorno['complemento'] ? retorno['complemento'] : '';
            this.dados['default']['Aluno']['Uf'] = retorno['uf'] ? retorno['uf'] : '';


          }
        });
    }


  }

  onDate(event: any) {

    this.sendDate.emit(event.value);
    this.openCalendarMask = false;
  }


  onChangeSearch(event:any,value: string) {

    this.showMinChar = false;
    let condicaoTipo = this.input && this.input.condicaoType ? this.input.condicaoType : null;
    this.autoCompleteSearchEvent(event, this.input, condicaoTipo)

  }

  onSelectAuto(event: any, input: any) {

    this.dados['default'][input.propId] = event.Id;
    this.dados['default'][input.propDescricao] = event.Descricao;
    this.descInputAutoComplete = event.Descricao;

  }

  changeAutoCompleteFocused(value: any, event: any) {

    if (!this.auto.query || this.auto.query.length < 1) {
      this.showMinChar = true;
    } else {
      this.showMinChar = false;
    }
    this.focused = value;
    return;

  }

  changeAutoCompleteClosed(input: any) {
    this.showMinChar = false;
    if (this.dados['default'][input.propId] == '') {
      this.descInputAutoComplete = '';
    }
    return;
  }

  onClearSearch(input: any) {
    this.dados['default'][input.propId] = "";
    this.dados['default'][input.propDescricao] = "";
  }

  async autoCompleteSearchEvent(event: any, input: any, condicaoType = null) {

    this.dados['default'][input.propId] = "";
    this.dados['default'][input.propDescricao] = "";

    this.autocompleteLoading = true;
    this.showMinChar = false;
    if (condicaoType == "notSearch") {
      return new Promise((resolve, reject) => {
        //console.log(resolve)
        if (this.options != null) {
          this.autocompleteLoading = false;
          resolve(this.options)
        }
      }).then((resultData: any) => {
        input.dataComplete = resultData.map((value: any) => value)
      })
    }

    let filtro

    // Faz a busca com o filtro baseado no condicaoType do input
    if (condicaoType) {
      filtro = {
        ColunaOrdenacao: input.propOrdenacao,
        Campo: input.propValor,
        Valor: event,
        Condicao: condicaoType
      }

    } else if (input && input.propValueSearch) {
      // Faz a busca baseada numa propriedade já existente no ngModel dos dados globais
      filtro = {
        ColunaOrdenacao: input.propOrdenacao,
        Campo: input.propValor,
        Valor: this.dados['default'][input.propValueSearch],
      }
    }
    else if (input) {
      // Faz a busca baseada no valor digitado no autocomplete
      
      filtro = {
         
        entrada: event.target.value,
      }
    }

    if (input) {
      if (input.urlOpcoes && input.urlOpcoes != '') {
        await this.crudService.postObservable(filtro, input.urlOpcoes)
          .subscribe((data: any) => {

            input.options = data;
            //console.log(input.options)
            this.autocompleteLoading = false;


          })
      } else {
        if (!input.options || input.options.length ==0)
         await this.mockService.gerarMockSelect(input);
       
      }
      
    }
  }


} //Fim
