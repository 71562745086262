<app-form-cartorio [uf]="uf"
                   [cidade]="cidade"
                   (setTemCoberturaFilho)="setTemCoberturaFilho($event)"
                   (setCartorios)="setCartorios($event)"
                   [cartorioEmissor]="cartorioEmissor"
                   [itemAtual]="itemAtual"
                   [multiplo]="true"
                   [classObrigatoria]="classObrigatoria"
                   ></app-form-cartorio>
                   <!-- -->

<form [formGroup]="camposForm" *ngIf="temCobertura || edicao" >
  <div class="row">
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Protocolo</mat-label>
        <input matInput type="text" class="col-lg-12" formControlName="protocolo" maxlength="100">
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Registro</mat-label>
        <input matInput
               type="text"
               class="col-lg-12"
               formControlName="registro"
               maxlength="100">
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Numero do Ato</mat-label>
        <input matInput type="text" class="col-lg-12" formControlName="numeroAto" maxlength="100">
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6 ">
      <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label for="dataAto">Data do ato</mat-label>
        <input matInput appMaskDate maxlength="10" [matDatepicker]="picker" type="text" formControlName="dataAto"
               name="dataAto">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

</form>

<app-aviso *ngIf="!edicao" [aviso]="itemAtual?.aviso"></app-aviso>

<div class="row">
  <div class="col-md-5"></div>
  <div class="col-auto" *ngIf="!edicao">
    <button type="button" mat-button [routerLink]="['/']" class="btn-next" style="color: white; background-color: green;">Voltar</button>
  </div>
  <div class="col-auto" *ngIf="edicao">
    <button type="button" mat-button (click)="cancelarEdicaoForm()" class="btn-next" style="color: white; background-color: green;">Cancelar</button>
  </div>
  <div class="col-auto">
    <button mat-button (click)="updatePedido()" class="btn-next"
            style="display: block; margin: 0 auto;">
            <span  *ngIf="!edicao">Continuar</span> 
            <span  *ngIf="edicao">Salvar</span> 
    </button>
  </div>
</div>
