import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-form-13',
  templateUrl: './form13.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class Form13Component implements OnInit, AfterViewInit {
  @Input() itemAtual: any;
  @Input() itemEdicao: any;
  @Input() edicao: any = false;
  @Output() cancelarEdicao = new EventEmitter<any>();

  listuf: any[];
  listCidade: any[];
  @Output() salvarItem = new EventEmitter<any>();

  camposForm = this.formBuilder.group({
    tipoSolicitacao: ['', Validators.required],
    tipoDocumento: ['', Validators.required],
    uf: ['', Validators.required],
    cidade: ['', Validators.required],
    cartorio: ['', Validators.required],
    informacaoAdicional: [''],
    arquivosRegistro: new FormControl([], Validators.required),
    arquivosAdicionais: new FormControl([], Validators.required),
    aceites: ['']
  });

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    public storage: LocalStorage,
    private route: Router,
    private routeAc: ActivatedRoute,
    public teste: TesteService,
    public service: CrudService
  ) {

  }

  ngOnInit(): void {


    if (document.location.href.indexOf('meu-carrinho') == -1) {
      this.getEstado();
      this.carrregarEdicao()
    }
  }

  ngAfterViewInit() {

  }

  cancelarEdicaoForm() {
    this.cancelarEdicao.emit()
  }

  getEstado() {
    const url = environment.http.API + 'localidade/uf'
    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {
        if (response != null) {
          this.listuf = response;
          this.getCidade()
        }
      }, error: (err) => {
        console.error(err);
      }
    });
  }

  getCidade() {
    // @ts-ignore
    //console.log(this.camposForm.get('uf').value);
    // @ts-ignore


    let idEstado = this.camposForm.get('uf').value['IdEstado'];

    if (idEstado) {
      const url = environment.http.API + 'Localidade/cidade?IdEstado=' + idEstado;
      this.service.getObservableDirect(url).subscribe({
        next: async (response) => {
          if (response != null) {

            this.listCidade = response;
            this.carrregarEdicao()
          }
        }, error: (err) => {

          console.error(err);
        }
      });
    }
  }

  onCheckboxChange(event: any, item: any) {
    if (event.checked) {
      item["selecionado"] = true
    } else {
      item["selecionado"] = false

    }
  }

  home() {
    this.route.navigate(['home'])
  }

  classObrigatoriaAviso: any;
  validarArquivosRegistro: any;
  validarArquivosAdicionais: any;

  temAceiteObrigatorio = false
  aceites: any
  temAceite(event: any) {
    this.temAceiteObrigatorio = true
    this.aceites = event
  }



  updatePedido() {

    if (this.temAceiteObrigatorio) {

      let aceitFinal = this.storage.localStorageGet('aceiteDireto')
      let temsemaceite = aceitFinal.find((x: any) => x.selecionado == false)
      if (temsemaceite == undefined) {
        this.camposForm.get('aceites')?.setValue(aceitFinal)
        this.classObrigatoriaAviso = ''
      } else {
        this.classObrigatoriaAviso = 'redClass'
        return
      }
    } else {
      this.classObrigatoriaAviso = ''
    }


    if (this.itemAtual?.titulo === 'Registro de Títulos e Documentos') {
      this.camposForm.controls.cartorio.setErrors(null);
      this.camposForm.controls.cartorio.markAsTouched();
    }

    this.camposForm.markAllAsTouched();
    this.ValidarArquivos();

    if (this.camposForm.valid) {
      let item: any = this.camposForm.value

      this.salvarItem.emit(item);
    }

  }

  ValidarArquivos() {
    const camposForm = this.camposForm;

    const arquivosRegistro = camposForm.get('arquivosRegistro')?.value;
    const arquivosAdicionais = camposForm.get('arquivosAdicionais')?.value;

    const arquivosRegistroPreenchido = arquivosRegistro && arquivosRegistro.length > 0;
    const arquivosAdicionaisPreenchido = arquivosAdicionais && arquivosAdicionais.length > 0;

    if (!arquivosRegistroPreenchido) {
      this.validarArquivosRegistro = 'redClass';
    } else {
      this.validarArquivosRegistro = '';
    }

    if (!arquivosAdicionaisPreenchido) {
      this.validarArquivosAdicionais = 'redClass';
    } else {
      this.validarArquivosAdicionais = '';
    }
  }


  arquivosRegistro: any = []
  arquivosAdicionais: any = []
  setaArquivos(event: any) {
    if (event.tipo === 1) {
      this.arquivosRegistro = [...this.arquivosRegistro, ...event.arquivos]
      this.camposForm.controls['arquivosRegistro'].setValue(this.arquivosRegistro);
      this.ValidarArquivos();
    } else if (event.tipo === 2) {
      this.arquivosAdicionais = [...this.arquivosAdicionais, ...event.arquivos]
      this.camposForm.controls['arquivosAdicionais'].setValue(this.arquivosAdicionais);
      this.ValidarArquivos();
    }
  }

  resetaArquivos(entrada: any) {

    if (entrada.tipo === 1) {
      this.arquivosRegistro = entrada.arquivos
      this.camposForm.controls['arquivosRegistro'].setValue(this.arquivosRegistro);
    } else if (entrada.tipo === 2) {
      this.arquivosAdicionais = entrada.arquivos
      this.camposForm.controls['arquivosAdicionais'].setValue(this.arquivosAdicionais);
    }
  }


  compareId(object1: any, object2: any) {
    return object1 && object2 && object1.Id == object2.Id;
  }

  prodAtu: any
  carrregarEdicao() {
    let paramId = this.routeAc.snapshot.paramMap.get('id');

    if (paramId != null || this.itemEdicao != null) {
      let pedido = this.storage.localStorageGet("pedido")

      if (pedido?.listaCompra != null && pedido?.listaCompra.length > 0 && paramId != null) {
        this.prodAtu = { ...pedido.listaCompra?.find((x: any) => x.idItem == paramId) }

        this.service.preencheCampos(this.prodAtu, this.camposForm)
      } else {
        this.prodAtu = this.itemEdicao
        this.service.preencheCampos(this.prodAtu, this.camposForm)
      }
      if (this.prodAtu?.arquivosRegistro) {
        this.arquivosRegistro = this.prodAtu.arquivosRegistro
      }
    }

  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // //console.log(event.key, event.ctrlKey, event.shiftKey, environment.production)
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {

      this.teste.setValueForm(this.camposForm);

    }
  }

}
