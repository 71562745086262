import { Component, OnInit, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-texto-institucional',
  templateUrl: './texto-institucional.component.html',
  styleUrls: ['./texto-institucional.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextoInstitucionalComponent implements OnInit {
  @Input() conteudo: any;
  
  constructor() { }

  ngOnInit(): void {

  }

}
