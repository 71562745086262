{{ conteudo[0] }}
<div id="nivel-1"
      class="row"
      [class]="conteudo.class"
      [style]="conteudo.style"
      style="background-color: #18244F; --bs-gutter-x: 0rem;">

  <div id="coluna-esquerda" class="col-lg-2" *ngIf="!deviceService.isMobile()">&nbsp;</div>
  

  <div id="coluna-central" class="col-lg-8" >

    <!-- linha 1-->
    <div class="row mt-5 mb-0" style="margin: 0 auto;">

      <!--coluna-1-->
      <div class="xcol-2 xcol-sm-2 col-md-4 col-lg-4 col-xl-4 px-3 py-3 mt-0">
        <div id="card0" class="rounded fundoColor pt-4 ps-4 pe-4 pb-4" style="min-height: 16.3rem; min-width: 19rem;">
          <div class="icon-wrapper" *ngIf="conteudo.itens[0].icon">
            <div class="fundo-img text-center pt-3 pb-3 tamanhoImg">
              <img loading="lazy" [src]="['./../assets/'+conteudo.itens[0].icon]" alt="Acesso rápido" class="mb-2">
            </div>
          </div>
          <div *ngIf="conteudo.itens[0].txtPosition != 'top'" class="text-wrapper tamanhoCard" style="text-align:center">
            <h3 class="title-card">{{conteudo.itens[0].titulo}}</h3>
            <span class="description-card" [innerHTML]="conteudo.itens[0].descricao"></span>
          </div>
        </div>
      </div>

      <!--coluna-2-->
      <div class="xcol-2 xcol-sm-2 col-md-4 col-lg-4 col-xl-4 px-3 py-3 mt-0">
        <div id="card1" class="rounded fundoColor pt-4 ps-4 pe-4 pb-4" style="min-height: 16.3rem; min-width: 19rem;">
          <div class="icon-wrapper" *ngIf="conteudo.itens[1].icon">
            <div class="fundo-img text-center pt-3 pb-3 tamanhoImg">
              <img loading="lazy" [src]="['./../assets/'+conteudo.itens[1].icon]" alt="Acesso rápido" class="mb-2">
            </div>
          </div>
          <div *ngIf="conteudo.itens[1].txtPosition != 'top'" class="text-wrapper tamanhoCard" style="text-align:center">
            <h3 class="title-card">{{conteudo.itens[1].titulo}}</h3>
            <span class="description-card" [innerHTML]="conteudo.itens[1].descricao"></span>
          </div>
        </div>
      </div>

      <!--coluna-3-->
      <div class="xcol-2 xcol-sm-2 col-md-4 col-lg-4 col-xl-4 px-3 py-3 mt-0">
        <div id="card2" class="rounded fundoColor pt-4 ps-4 pe-4 pb-4" style="min-height: 16.3rem; min-width: 19rem;">
          <div class="icon-wrapper" *ngIf="conteudo.itens[2].icon">
            <div class="fundo-img text-center pt-3 pb-3 tamanhoImg">
              <img loading="lazy" [src]="['./../assets/'+conteudo.itens[2].icon]" alt="Acesso rápido" class="mb-2">
            </div>
          </div>
          <div *ngIf="conteudo.itens[2].txtPosition != 'top'" class="text-wrapper tamanhoCard" style="text-align:center">
            <h3 class="title-card">{{conteudo.itens[2].titulo}}</h3>
            <span class="description-card" [innerHTML]="conteudo.itens[2].descricao"></span>
          </div>
        </div>
      </div>

    </div>


    <!--linha 2-->
    <div id="xpto" class="row mt-1 mb-sm-2 mb-md-5 mb-lg-5" style="margin: 0 auto;">

      <!--coluna-1-->
      <div class="xcol-2 xcol-sm-2 col-md-4 col-lg-4 col-xl-4 px-3 py-3 mt-0">
        <div id="card3" class="rounded fundoColor pt-4 ps-4 pe-4 pb-4" style="min-height: 16.3rem; min-width: 19rem;">
          <div class="icon-wrapper" *ngIf="conteudo.itens[3].icon">
            <div class="fundo-img text-center pt-3 pb-3 tamanhoImg">
              <img loading="lazy" [src]="['./../assets/'+conteudo.itens[3].icon]" alt="Acesso rápido" class="mb-2">
            </div>
          </div>
          <div *ngIf="conteudo.itens[3].txtPosition != 'top'" class="text-wrapper tamanhoCard" style="text-align:center">
            <h3 class="title-card">{{conteudo.itens[3].titulo}}</h3>
            <span class="description-card" [innerHTML]="conteudo.itens[3].descricao"></span>
          </div>
        </div>
      </div>

      <!--coluna-2-->
      <div class="xcol-2 xcol-sm-2 col-md-4 col-lg-4 col-xl-4 px-3 py-3 mt-0">
        <div id="card4" class="rounded fundoColor pt-4 ps-4 pe-4 pb-4" style="min-height: 16.3rem; min-width: 19rem;">
          <div class="icon-wrapper" *ngIf="conteudo.itens[4].icon">
            <div class="fundo-img text-center pt-3 pb-3 tamanhoImg">
              <img loading="lazy" [src]="['./../assets/'+conteudo.itens[4].icon]" alt="Acesso rápido" class="mb-2">
            </div>
          </div>
          <div *ngIf="conteudo.itens[4].txtPosition != 'top'" class="text-wrapper tamanhoCard" style="text-align:center">
            <h3 class="title-card">{{conteudo.itens[4].titulo}}</h3>
            <span class="description-card" [innerHTML]="conteudo.itens[4].descricao"></span>
          </div>
        </div>
      </div>

      <!--coluna-3-->
      <div class="xcol-2 xcol-sm-2 col-md-4 col-lg-4 col-xl-4 px-3 py-3 mt-0">
        <div id="card5" class="rounded fundoColor pt-4 ps-4 pe-4 pb-4" style="min-height: 16.3rem; min-width: 19rem;">
          <div class="icon-wrapper" *ngIf="conteudo.itens[5].icon">
            <div class="fundo-img text-center pt-3 pb-3 tamanhoImg">
              <img loading="lazy" [src]="['./../assets/'+conteudo.itens[5].icon]" alt="Acesso rápido" class="mb-2">
            </div>
          </div>
          <div *ngIf="conteudo.itens[5].txtPosition != 'top'" class="text-wrapper tamanhoCard" style="text-align:center">
            <h3 class="title-card">{{conteudo.itens[5].titulo}}</h3>
            <span class="description-card" [innerHTML]="conteudo.itens[5].descricao"></span>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div id="coluna-direita" class="col-lg-2" >&nbsp;</div>

</div>
