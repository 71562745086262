import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-meusPedidosOrderDetails-13',
  templateUrl: './meusPedidosOrderDetail13.component.html',
  styleUrls: ['./../_meus-pedidos.component.scss']
})
export class MeusPedidosOrderDetails13Component  {
  @Input() compra: any

  public visibilidadeDetalhes: boolean=false;
  
  @Output() updatePedido = new EventEmitter<any>();

  cancelarEdicao(compra:any) {
    compra.editar = false;
  }

  salvarItem(itemPedido: any) {     
    this.updatePedido.emit(itemPedido)
  }

  carregarPathDonwload(pathArquivo: any, arquivoNome: any) {
    if (pathArquivo.startsWith('https://apisistemas.etechss.com.br')) {
      return pathArquivo;
    } else {
      const pathSplit = pathArquivo.split('\\').filter((value: any) => value != "");
      const pathFormat = pathSplit.slice(1, pathSplit.length).join('\\');

      const extensaoSplit = arquivoNome.split('.');
      const extensao = '.' + extensaoSplit[extensaoSplit.length -1];

      const extensoesAccept = [
        { extensao: '.PDF', accept: 'application/pdf' },
        { extensao: '.JPG', accept: 'image/jpg' },
        { extensao: '.JPEG', accept: 'image/jpeg' },
        { extensao: '.PNG', accept: 'image/png' },
        { extensao: '.GIF', accept: 'image/gif' }
      ];
      
      const acceptName = extensoesAccept.find((e: any) => e.extensao == extensao.toUpperCase())?.accept;

      const pathApiSistemaBase = `${environment.http.API_SISTEMA}Arquivo/GetFile`;

      const pathExtensao = pathFormat.substring(pathFormat.length - 4, pathFormat.length);
      const filePath = pathExtensao.toUpperCase().includes('.PDF') ? pathFormat : `${pathFormat}`;

      const pathFull = `${pathApiSistemaBase}?filePath=${filePath}&fileNameDownload=${arquivoNome}&acceptName=${acceptName}`;

      return pathFull;
    }
  }
}
