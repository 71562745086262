<div id="myNavBusca" class="overlay-loading">

  <div class="header">
    <div>
      <a routerLink="/">
        <img loading="lazy"
             class="navigation-brand logo"
             src="../../../assets/logoCNR.png"
             id="logo"
             alt="Brand" />
      </a>
    </div>

    <a href="javascript:void(0)"
       class="closebtn"
       (click)="closeModalInterno('myNavBusca')"
       onclick="document.getElementById('myNavBusca').style.width = '0%';">
      &times;
    </a>
  </div>



  <div class="overlay-content-loading">

    <div class="login-logo"></div>

    <div id="paginaComponent" class="cssload-container">

      <app-searchbar-v1 ></app-searchbar-v1>

    </div>

    <div class="sk-spinner sk-spinner-wave" style="text-align: center; color: #fff; font-size: 20px;">Aguarde...</div>

  </div>

</div>
