
<mat-table #table [dataSource]="dataSouceGrid" *ngIf="dataSouceGrid" class="gridMat">
  <ng-container matColumnDef="{{column['id']}}" *ngFor="let column of columnNames">
    <ng-container>

      <mat-header-cell *matHeaderCellDef [hidden]="showColumn(column)">
        <div style="clear:both" *ngIf="column['label']!='Ação'">{{column['label']}}</div>
        <div style="clear:both" *ngIf="!edicao && column['label']=='Ação'">
          <a id="btnIncluirEntidade"
             title="Incluir {{conteudoPagina.title}}"
             href="javascript:void(0)"
             (click)="novaEntidade()"
             class="btn btn-form btn-primary" style="width: 62px;font-size: 14px;">
            +
            <i class="fas"></i>
          </a>
        </div>

        <ng-container *ngIf="edicao && column['label']=='Ação'">
          <button style=" width: 20px !important; border-radius: 1px !important;
          height: 23px !important; padding: 0px !important; margin: 0px !important; min-height: 5px !important;"
                  class="btn btn-form btn-primary btnRemover" type="button" (click)="addColumn()">
            +
          </button>
        </ng-container>
        <ng-container *ngIf="edicao && column['label']!='Ação'">
          
          <button style=" width: 20px !important; border-radius: 1px !important;
          height: 23px !important; padding: 0px !important; margin: 0px !important; min-height: 5px !important;"
                  class="btn btn-form btn-primary " type="button" (click)="addColumn(column)">
            <i _ngcontent-stl-c282="" class="fa fa-cog"></i>
          </button>

        </ng-container>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [style.background-color]="getBackground(element)" [hidden]="showColumn(column)">

        <ng-container *ngIf="column['label']!='Ação' && column['label'] !='+'">

          <ng-container *ngIf="!column['tipo'] || (column['tipo'] != 'boolean' && column['tipo'] != 'labelDesc')">
            {{column['subProp'] ? element[column['id']][column['subProp']] : element[column['id']]}}
          </ng-container>
          <ng-container *ngIf="column['tipo'] && column['tipo'] == 'boolean'">

            <ng-container *ngIf="element[column['id']] ==1">
              Sim
            </ng-container>
            <ng-container *ngIf="element[column['id']] ==0">
              Não
            </ng-container>
          </ng-container>
          <ng-container *ngIf="column['tipo'] && column['tipo'] == 'labelDesc'">
            <ng-container *ngIf="element[column['id']] ==1">
              <div class="label " [class]="column['classLabelDesc']">{{column['label']['Desc']}}</div>
            </ng-container>
          </ng-container>
          <ng-container matColumnDef="{{subcolumn['id']}}" *ngFor="let subcolumn of column['campos']">

            <ng-container *ngIf="!subcolumn['tipo'] || (subcolumn['tipo'] != 'boolean' && subcolumn['tipo'] != 'labelDesc')">
              {{element[subcolumn['id']]}}
            </ng-container>
            <ng-container *ngIf="!subcolumn['tipo'] || subcolumn['tipo'] == 'boolean'">

              <ng-container *ngIf="element[subcolumn['id']] && element[subcolumn['id']] ==1">
                Sim
              </ng-container>
              <ng-container *ngIf="element[subcolumn['id']] && element[subcolumn['id']] ==0">
                Não
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="column['label']=='Ação'">
           
          <i  
             class="material-icons verde"
             matTooltip="Alterar Local"
             (click)="editarEntidade(element)">drive_file_rename_outline</i>
          <i *ngIf="!this._bPermitido"
             class="material-icons cinza"
             matTooltip="Não permitido">drive_file_rename_outline</i>
          
          <i  
             class="material-icons vermelho"
             matTooltip="Excluir Local"
             (click)="excluirEntidade(element)">highlight_off</i>
          <i *ngIf="!this._bPermitido"
             class="material-icons cinza"
             matTooltip="Não permitido">highlight_off</i>
           
        </ng-container>
      </mat-cell>

    </ng-container>

  </ng-container>



  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

</mat-table>

<ng-container *ngFor="let item of conteudoPagina.optionsPropPag; let i = index;">
  <div class="row tabMat">
    <div class="col-sm-12">
      <div class="blocoGridMobile">
        <ng-container *ngFor="let itemk of item | keyvalue">

          <ng-container *ngIf="itemk.key != 'acao' && itemk.key != 'Id' && getLabelKey(itemk.key) != ''">
            <b>{{getLabelKey(itemk.key)}}:</b>&nbsp;{{getValueKey(itemk)}}<br />
          </ng-container>
        </ng-container>
        <div class="buttonBlocoGridMobile">
          <i  
             class="material-icons verde"
             matTooltip="Alterar Local"
             (click)="editarEntidade(item)">drive_file_rename_outline</i>
          <i *ngIf="!this._bPermitido"
             class="material-icons cinza"
             matTooltip="Não permitido">drive_file_rename_outline</i>
          EDIÇÃO
          DELETE
          <i  
             class="material-icons vermelho"
             matTooltip="Excluir Local"
             (click)="excluirEntidade(item)">highlight_off</i>
          <i *ngIf="!this._bPermitido"
             class="material-icons cinza"
             matTooltip="Não permitido">highlight_off</i>
          DELETE
        </div>
      </div>
    </div>
  </div>
</ng-container>

<mat-paginator *ngIf="dataSouceGrid" #paginator [length]="this.conteudoPagina.collectionSize"
               [pageSize]="this.configPage.pageSize"
               [pageSizeOptions]="this.configPage.ItensPorPagina"
               (page)="confirmPageChange($event)"
               aria-label="Selecione a página"></mat-paginator>
 
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayExcluir}">
  
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="label" id="excluiEntidade">CONFIRMAÇÃO</h5>
          <button id="btnFechaModalExcluirEntidade"  (click)="displayExcluir = ''" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="container-fluid w-100 pt-2" *ngIf="entidade">
          <app-card id="cardModalExcluirEntidade" titulo="">
            <div class="row">
              <div class="col-sm">
                <label class="titulo-card" for="entidade"><i class="fas fa-times-circle" ></i> Confirma a exclusão do registro?</label>
              </div>
            </div>
            <ng-container *ngFor="let column of conteudoPagina.dadosGrid; let i = index;">
              <div class="row mt-12" *ngIf="column['label'] != 'Ação'">

                <ng-container *ngIf="column['label']">
                  <label class="label" for="entidade">{{column['label']}}:</label>&nbsp;

                  <label class="label label-imprimir" *ngIf="!column.formatConcat">

                    <ng-container *ngIf="!column['tipo'] || column['tipo'] != 'boolean'">
                      {{column['subProp'] ?entidade[column.prop][column['subProp']] :entidade[column.prop] }}
                    </ng-container>
                    <ng-container *ngIf="column['tipo'] && column['tipo'] == 'boolean'">
                      <ng-container *ngIf="entidade[column.prop] && entidade[column.prop] ==1">
                        Sim
                      </ng-container>
                      <ng-container *ngIf="entidade[column.prop] !=1">
                        Não
                      </ng-container>
                    </ng-container>

                  </label>
                </ng-container>



              </div>
            </ng-container>

          </app-card>
          <div class="input-group mb-3 mt-3 justify-content-end">

            <button (click)="displayExcluir = ''" id="btnCancelarExcluirEntidade" type="button"
                    class="btn btn-form btn-secondary mr-2" title="Cancelar" data-dismiss="modal">
              Cancelar <i class="fas fa-times"></i>
            </button>
            <button (keydown.space)="$event.preventDefault()"
                    id="btnConfirmarExcluirEntidade"
                    type="button"
                    class="btn btn-form btn-success"
                    data-dismiss="modal"
                    (click)="confirmarExcluirEntidade()">
              OK <i class="fas fa-check"></i>
            </button>
            <!--[attr.title]="this._incluirAlterarDesabilitado === false ? 'Acesso não permitido' : 'OK'"
            [ngClass]="{ 'desabilitado': this._incluirAlterarDesabilitado === false, '': this._incluirAlterarDesabilitado === true }"-->
          </div>
        </div>
      </div>
    </div>
  </div>


  <button (keydown.space)="$event.preventDefault()" id="btnmodaladdColumn" type="button" [hidden]="true"
          data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#modaladdColumn">
    Open Modal
  </button>

  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="label" id="excluiEntidade">Adicionar coluna</h5>
          <ng-container *ngIf="edicaoColuna">
            <button style=" width: 20px !important; border-radius: 1px !important;
          height: 23px !important; padding: 0px !important; margin: 0px !important; min-height: 5px !important;"
                    class="btn btn-form btn-primary btnRemover" type="button" (click)="removerCampo()">
              -
            </button>
            <button style=" width: 20px !important; border-radius: 1px !important;
          height: 23px !important; padding: 0px !important; margin: 0px !important; min-height: 5px !important;"
                    class="btn btn-form btn-primary " type="button" (click)="mudarPosicao(-1)">
              <
            </button>
            <button style=" width: 20px !important; border-radius: 1px !important;
          height: 23px !important; padding: 0px !important; margin: 0px !important; min-height: 5px !important;"
                    class="btn btn-form btn-primary " type="button" (click)="mudarPosicao(1)">
              >
            </button>
          </ng-container>


          <button id="btnFechaModaladdColumn" (keydown.space)="$event.preventDefault()" type="button"
                  class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="container-fluid w-100 pt-2">
          <app-card id="cardModaladdColumn" titulo="">

            <div class="row">
              <div class="col-sm-5">
                Propriedade:
              </div>
              <div class="col-sm-7">
                <input [(ngModel)]="novaColuna.prop">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                Sub-Propriedade:
              </div>
              <div class="col-sm-7">
                <input [(ngModel)]="novaColuna.subProp">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                Label:
              </div>
              <div class="col-sm-7">
                <input [(ngModel)]="novaColuna.label">
              </div>
            </div>



          </app-card>
          <div class="input-group mb-3 mt-3 justify-content-end">

            <button (keydown.space)="$event.preventDefault()" id="btnCancelaraddColumn" type="button"
                    class="btn btn-form btn-secondary mr-2" (click)="fecharAddColumn()" title="Cancelar" data-dismiss="modal">
              Cancelar <i class="fas fa-times"></i>
            </button>
            <button (keydown.space)="$event.preventDefault()"
                    id="btnConfirmaraddColumn"
                    type="button"
                    class="btn btn-form btn-success"
                    data-dismiss="modal"
                    (click)="confirmaraddColumn()">
              Adicionar <i class="fas fa-check"></i>
            </button>
            <!--[attr.title]="this._incluirAlterarDesabilitado === false ? 'Acesso não permitido' : 'OK'"
            [ngClass]="{ 'desabilitado': this._incluirAlterarDesabilitado === false, '': this._incluirAlterarDesabilitado === true }"-->
          </div>
        </div>
      </div>
    </div>
  </div>
