<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>Sistema</mat-label>
    <input type="text" placeholder="Escolha" aria-label="Number" matInput [formControl]="myControl"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="getOptionText">
      <mat-option *ngFor="let option of options" [value]="option">
        {{option.DescBase}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>

<div id="divChart">
  <ng-container *ngFor="let gra of graficos">
    <canvas [id]="gra.id"></canvas>
  </ng-container>
</div>
