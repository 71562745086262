 
 
  <div  tabindex="-1" role="dialog" [ngStyle]="{'display':displayAnotacao}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="row">
            <div class="col-lg-3" style="width: 350px;" *ngIf="isVisaoTotal && showQAAdmin()">

              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label> Responsável </mat-label>
                <mat-select [(ngModel)]="usuarioSelected" (selectionChange)="openModalAnotac('Anotações',fechados)">
                  <mat-option [value]="''">Todos</mat-option>
                  <mat-option value="naoatribuido">Não atribuidos</mat-option>
                  <mat-option *ngFor="let item of usuarios" [value]="item.valor">
                    {{ item.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-3" style="width: 160px;" *ngIf="isVisaoTotal && showQAAdmin()">

              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label> Prioridade </mat-label>
                <mat-select [(ngModel)]="prioridadeSelected" (selectionChange)="openModalAnotac('Anotações',fechados)">
                  <mat-option value="0">Todas</mat-option>
                  <mat-option value="1">1</mat-option>
                  <mat-option value="2">2</mat-option>
                  <mat-option value="3">3</mat-option>

                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-3" style="width: 150px;">

              <h2 style="cursor: pointer; padding: 16px; font-size: 19px;" class="label" id="excluiEntidade"
                  [style.background-color]="(!fechados) ? '#9ea8c3' : '#f5f7fbcf'"
                  *ngIf="!isVisaoTotal"
                  (click)="openModalAnotacao(blocoAnotacao,title,idxWorkflow)">
                Em aberto
              </h2>
              <h2 style="cursor: pointer; padding: 16px; font-size: 19px;"
                  [style.background-color]="(!fechados) ? '#9ea8c3' : '#f5f7fbcf'"
                  class="label" id="excluiEntidade"
                  *ngIf="isVisaoTotal"
                  (click)="openModalAnotac(title,false)">
                Em aberto
              </h2>



            </div>
            <div class="col-lg-3" style="width: 150px;">
              <h2 style="cursor: pointer;background: aqua;padding: 16px;" class="label" id="excluiEntidade"
                  [style.background-color]="(fechados) ? '#9ea8c3' : '#f5f7fbcf'"
                  *ngIf="!isVisaoTotal"
                  (click)="getFechados(blocoAnotacao,title,idxWorkflow)">
                Fechados
              </h2>
              <h2 style="cursor: pointer;padding: 16px;" class="label" id="excluiEntidade"
                  [style.background-color]="(fechados) ? '#9ea8c3' : '#f5f7fbcf'"
                  *ngIf="isVisaoTotal"
                  (click)="openModalAnotac(title,true)">
                Fechados
              </h2>


            </div>
            <div class="col-lg-3" style="width: 150px;">

              <h2 style="cursor: pointer;padding: 16px;" class="label" id="excluiEntidade"
                  [style.background-color]="(fechados2d) ? '#9ea8c3' : '#f5f7fbcf'"
                  *ngIf="isVisaoTotal"
                  (click)="openModalAnotac2d(title,true)">
                Fechados 2d
              </h2>

            </div>
          </div>

           
        </div>
      
        <ng-container *ngIf="true">

          <div class="container-fluid w-100 pt-2" *ngIf="mostrarAnotacao" style="overflow: scroll;">
            <div class="row">
              <ng-container *ngFor="let item of anotacaoFechados;let j = index;">
                <p><b>Item:</b> {{j+1}} | <b>Id anotação:</b> {{item.IdAnotacaoPagina}} | <b>Data inclusão:</b> {{item.DataInclusao | date:'dd/MM/yyyy'}} | <b>Aberto por:</b> {{item.Usuario}}</p>

                <div class="row" style="padding-bottom: 20px;">

                   
                </div>
                <div class="col-sm-12" *ngIf="item.Anotacao != '<p></p>'" [innerHTML]="getItem(item.Anotacao)">
                </div>
                <div class="row">

                  <ng-container *ngFor="let com of item.AnotacaoPagianaComentario;let j = index;">

                    <div class="col-sm-3">&nbsp;</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;">{{com.DataInclusao  | date:'dd/MM/yyyy'}}</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;">{{com.Usuario}}</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;" [innerHTML]="getItem(com.Comentario)"></div>
                  </ng-container>
                </div>

                <hr />
              </ng-container>
            </div>
          </div>
         
          <div class="container-fluid w-100 pt-2" *ngIf="addAnotacao">
            <div class="row">
              <hr />
              <ng-container *ngFor="let item of anotacaoSistemaAberto;let j = index;">
                <div class="row" style="padding-bottom: 20px;">
                  <p><b>Item:</b> {{j+1}} | <b>Id anotação:</b> {{item.IdAnotacaoPagina}} | <b>Data inclusão:</b> {{item.DataInclusao | date:'dd/MM/yyyy'}} | <b>Aberto por:</b> {{item.Usuario}}</p>

                  <div class="col-sm-12" *ngIf="item.LocalStorage != null">
                    <button type="button" class="btn btn-form btn-secondary" (click)="acessar(item)">acessar</button>

                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 img100" *ngIf="item.Anotacao != '<p></p>'" [innerHTML]="getItem(item.Anotacao)">

                  </div>
                </div>
                <div class="row">

                  <ng-container *ngFor="let com of item.AnotacaoPagianaComentario;let j = index;">

                    <div class="col-sm-3">&nbsp;</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;">{{com.DataInclusao  | date:'dd/MM/yyyy'}}</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;">{{com.Usuario}}</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;" [innerHTML]="getItem(com.Comentario)"></div>
                  </ng-container>
                </div>
                 
                <hr />
              </ng-container>

            </div>

          </div>
          <div class="container-fluid w-100 pt-2" *ngIf="!addAnotacao">
            <div class="row" style="padding-bottom: 20px;">

              <div class="col-sm-12" style="background-color:beige" *ngIf="!fechados">
                Qtde Itens em aberto:{{anotacaoSistema.length}}

              </div>
            </div>
            <div class="row">

              <ng-container *ngFor="let item of anotacaoSistema;let j = index;">
                <p>
                  Item:{{j+1}} | Pagina: {{item.Pagina.Nome}} |
                  Id anotação: {{item.IdAnotacaoPagina}} |
                  Data inclusão: {{item.DataInclusao | date:'dd/MM/yyyy'}} |
                  Aberto por: {{item.Usuario}}
                  | Responsável: {{item?.UsuarioResponsavel}}
                  <span *ngIf="item?.DataAjuste"><b>Ajustado:</b>  {{item.DataAjuste | date:'dd/MM/yyyy'}}</span>

                </p>
                


                <div class="col-sm-12 img100" *ngIf="item.Anotacao != '<p></p>'" [innerHTML]="getItem(item.Anotacao)">

                </div>
                <div class="row">

                  <ng-container *ngFor="let com of item.AnotacaoPagianaComentario;let j = index;">

                    <div class="col-sm-3">&nbsp;</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;">{{com.DataInclusao  | date:'dd/MM/yyyy'}}</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;">{{com.Usuario}}</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;" [innerHTML]="getItem(com.Comentario)"></div>
                  </ng-container>
                </div>
                 
                    
              </ng-container>

            </div>

          </div>
        </ng-container>
      </div>
    </div>
  </div>


