import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() titulo: String;
  @Input() tooltip: string = "";
  @Input() isCollpase: Boolean;
  id: String = this.getRandomString(32)
  collapseIsOpen: Boolean = false

  constructor() { }

  ngOnInit(): void {

  }

  toggleCollapse () {
    if (!this.isCollpase) return
    //ajustar
    //($(`#${this.id}`) as any).collapse('toggle')
    this.collapseIsOpen = !this.collapseIsOpen
  }

  getRandomString(length: any) {
    var randomChars = '0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  get bIsCollpase () : Boolean {
    return this.collapseIsOpen
  }

  ngDoCheck(): void 
  {
    //if (this.tooltip !== ""){
    //  $("#tooltipTexto").html(this.tooltip);
    //}
  }  
}
