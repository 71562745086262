<div (click)="openModalBuscaClick()">
  <div class="wrapper-search" *ngIf="!class">

    <div class="container"
         [style]="isMobile() ? 'padding: 1.7rem 2.5rem 1.7rem 2.5rem !important;' : 'padding: 1.7rem 5.5rem 1.7rem 5.5rem !important;' "
         >

      <form [formGroup]="formBusca">
        <input type="text" matInput [placeholder]="conteudo.labelForm" (input)="onInputChange($event)">
        <button type="submit" class="btn-search-menu " aria-label="search menu">
          <mat-icon matPrefix class="my-icon">search</mat-icon>
        </button>
        <!-- Lista de resultados -->

        <ul class="result-search" *ngIf="searchResults && searchResults.length >0">
          <li *ngFor="let result of searchResults"><a (click)="solicitarNovo(result)" [href]="'pedido'+result.aLink">{{
              result.titulo }}</a></li>
        </ul>
      </form>

    </div>
  </div>

  <ng-container *ngIf="class">
    <div class="fullbanner">
      <div class="container">
        <form [formGroup]="formBusca">
          <input type="text" matInput [placeholder]="conteudo.labelForm" (input)="onInputChange($event)">
          <ul class="result-search" *ngIf="searchResults && searchResults.length > 0">
            <li class="col-12 px-3" style="padding: 10px;" *ngFor="let result of searchResults; let i = index;"
              [ngClass]="i%2 === 0 ? 'classBlue' : 'classWhite'">
              <a class="w-100" (click)="solicitarNovo(result)" style="font-size: 20px; cursor:pointer">
                {{ result.titulo }}
              </a>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </ng-container>
</div>
