import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-crud-gobal-lista',
  templateUrl: './crud-gobal-lista.component.html',
  styleUrls: ['./crud-gobal-lista.component.scss']
})
export class CrudGlobalListaComponent implements OnInit, OnChanges {

  page: number = 1;
  pageSize: number = 15;

  validaAbreFecha: any;
  listaEntidade: any = [];
  _bLista: boolean = false;
  _tituloFiltrar: string = "";
  collapseIsOpen: boolean = true;
  _entidadeId: number = 0;
  _entidadeDescricao: string = "";
  _secretariaDescricao: string = "";
  _incluirAlterarDesabilitado: boolean = false;
  filtroOriginal: any;
  _itemSelecionado: any;
  @Input() entidadeOriginal: any;
  @Input() dadosEntidadeOriginal: any;
  @Input() pagina: any;
  @Input() conteudoPagina: any;
  @Input() configPage: any;
  @Input() dataSouceGrid: any;
  @Output() listarEntidade = new EventEmitter<any>();
  @Output() listar = new EventEmitter<any>();
  edicao: boolean = false
  
  @Input() tipoGrid: any=null;


  constructor(
    public service: CrudService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    public ls: LocalStorage
  ) {
    this.edicao = localStorage.getItem("modoEdicao") != null;
    
  }

  ngOnChanges() {
    //this.cdRef.detectChanges();
  }

  async ngOnInit() {
   
  }

  ngAfterViewInit(): void {

    //this.cdRef.detectChanges();

  }

  validaFiltro(): boolean {
    var valid = false;
    if (this.conteudoPagina.minOrFiltro) {
      for (var i = 0; i < this.conteudoPagina.minOrFiltro.length; i++) {
        if (this.conteudoPagina.dados['default'][this.conteudoPagina.minOrFiltro[i]] != '') {
          i = this.conteudoPagina.minOrFiltro.length + 1
          valid = true;
        }
      }
    } else {
      valid = true;
    }
    return valid;
  }

  recarregarGrid(recarregar: boolean = false) {
    this.listar.emit(recarregar)
  }

  alterarEntidade(item: any) {
    this.conteudoPagina.dadosEntidade = { default: item }
    this.conteudoPagina.edicao = true;
  }

  novaEntidade() {
    this.conteudoPagina.inputs = { ...this.entidadeOriginal };
    this.conteudoPagina.dadosEntidade = this.ls.localStorageGet("dadosEntidadeOriginal");

    //window.sessionStorage.setItem('ITEM' + this.conteudoPagina.pagina, JSON.stringify(this.conteudoPagina.dadosEntidade));

    this.conteudoPagina.edicao = true;
  }
}
