import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { CrudService } from '../../_sevice/CrudService';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-full-banner',
  templateUrl: './fullbanner.component.html',
  styleUrls: ['./fullbanner.component.scss']
})
export class FullBannerComponent implements OnInit {
  @Input() conteudo: any;
  retError: string
  index: number=0
  formBusca = new FormGroup({
    codigo: new FormControl('', Validators.required)
  });

  public environment = environment;

  get f() {
    return this.formBusca.controls;
  }
  get codigo() {
    return this.formBusca.controls.codigo.value;
  }
  constructor(
    public router: Router,
    public service: CrudService,
    private device: DeviceDetectorService
  ) {

  }

  ngOnInit(): void {
    //console.log(this.f)
  }
  navegar(link:any) {
    this.router.navigate([link]);

  }

  consultar(urlRedirectBusca:string) {

    if (this.formBusca.valid) {
        const url = environment.http.API + 'Devedor/RetornaValidacaoDevedorCodigo/?codigo=' + this.codigo;
      if (urlRedirectBusca) {

           this.router.navigateByUrl(urlRedirectBusca + this.codigo)
      } else {
        this.service.getObservableDirect(url).subscribe(
          resposta => {
            if (resposta) {
              var data = JSON.parse(resposta);
              if (data.success) {

                window.location.href = data.link;
              }
              else {
                //console.log(data)
                this.retError = data.message
                this.formBusca.controls.codigo.setErrors({ 'retError': true });
                //this.exibirErroValicacaoCodigo(data.message);
              }
            } else {
            }
          })
      }

    }
  }

  public isMobile(): boolean {
    return this.device.isMobile()
  }
}
