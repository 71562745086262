<!-- VERSAO DESKTOP -->
<div class="d-none d-lg-block">
  <!-- <section class="header-servico">
    <div class="container">
      <h2>Serviços</h2>
    </div>
  </section> -->
  <div class="container">
    <div class="row w-100">
      <div class="col-3">
        <!-- Tab navs -->
        <div
          class="nav flex-column nav-tabs text-center"
          id="v-tabs-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <a
            data-mdb-tab-init
            class="nav-link active"
            id="v-tabs-1-tab"
            href="#v-tabs-1"
            role="tab"
            aria-controls="v-tabs-1"
            aria-selected="true"
          >
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/195c7ab3b37cae3b869939cf07377de11a10441b1fa576174abd7629f380faf2?apiKey=112bb9cfb8df486f9471fee20124622a&"
            class="img-2"
          />
            <p>
              Cartórios Judicializados
            </p> 
          </a>
          <a
            data-mdb-tab-init
            class="nav-link"
            id="v-tabs-2-tab"
            href="#v-tabs-2"
            role="tab"
            aria-controls="v-tabs-2"
            aria-selected="false"
          >
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/52a2c5319ed171531011782feecbfa2b39eaa8de23cd8bfaf13ced8bccb8e857?apiKey=112bb9cfb8df486f9471fee20124622a&"
              class="img"
            />
            <p>
              Cível e Criminal
              <span class="div-4">
                1º ao 4º Registro de Distribuição da Capital 
              </span>
            </p>
          </a>
          <a
            data-mdb-tab-init
            class="nav-link"
            id="v-tabs-3-tab"
            href="#v-tabs-3"
            role="tab"
            aria-controls="v-tabs-3"
            aria-selected="false"
          >
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/195c7ab3b37cae3b869939cf07377de11a10441b1fa576174abd7629f380faf2?apiKey=112bb9cfb8df486f9471fee20124622a&"
              class="img-4"
            />
            <p>
              Atos Lavrados nos Cartórios de Notas – Escritura/Procuração
              <span class="div-13">5º e 6º Registro de Distribuição da Capital</span>
    
            </p>
          </a>
        </div>
        <!-- Tab navs -->
      </div>
    
      <div class="col-9">
        <!-- Tab content -->
        <div class="tab-content" id="v-tabs-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-tabs-1"
            role="tabpanel"
            aria-labelledby="v-tabs-1-tab"
          >
            <h3>Cartórios Judicializados</h3>
            <p class="description-cartorio"></p>
    
            <div class="wrapper-content-items">
              <div class="item">
                <h4 class="nome-certidao">Certidão Cível - Período 20 anos</h4>
                <p class="descricao-certidao">
                  Apontam os processos Cíveis respondidos durante os últimos 20 anos
                </p>
                <button class="btn-solicitar-certidao">Solicitar</button>
              </div>
              <div class="item">
                <h4 class="nome-certidao">Certidão de Pesquisa de Testamentos - Período 20 anos</h4>
                <p class="descricao-certidao">
                  Aponta existência de escritura de Testamento
                </p>
                <button class="btn-solicitar-certidao">Solicitar</button>
              </div>
              <div class="item">
                <h4 class="nome-certidao">Certidão de Pesquisa de Testamentos - Período 25 Anos</h4>
                <p class="descricao-certidao">
                  Aponta existência de escritura de Testamento
                </p>
                <button class="btn-solicitar-certidao">Solicitar</button>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="v-tabs-2"
            role="tabpanel"
            aria-labelledby="v-tabs-2-tab"
          >
            <h3>Cível e Criminal</h3>
            <p class="description-cartorio">1º ao 4º Registro de Distribuição da Capital</p>
    
            <div class="wrapper-content-items">
              <div class="item">
                <h4 class="nome-certidao">Certidão Cível - Período 20 anos</h4>
                <p class="descricao-certidao">
                  Apontam os processos Cíveis respondidos durante os últimos 20 anos
                </p>
                <button class="btn-solicitar-certidao">Solicitar</button>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="v-tabs-3"
            role="tabpanel"
            aria-labelledby="v-tabs-3-tab"
          >
            <h3>Atos Lavrados nos Cartórios de Notas - Escritura/Procuração</h3>
            <p class="description-cartorio">5º e 6º Registro de Distribuição da Capital</p>
    
            <div class="wrapper-content-items">
              <div class="item">
                <h4 class="nome-certidao">Certidão Cível - Período 20 anos</h4>
                <p class="descricao-certidao">
                  Apontam os processos Cíveis respondidos durante os últimos 20 anos
                </p>
                <button class="btn-solicitar-certidao">Solicitar</button>
              </div>
              <div class="item">
                <h4 class="nome-certidao">Certidão de Pesquisa de Testamentos - Período 20 anos</h4>
                <p class="descricao-certidao">
                  Aponta existência de escritura de Testamento
                </p>
                <button class="btn-solicitar-certidao">Solicitar</button>
              </div>
            </div>
          </div>
        </div>
        <!-- Tab content -->
      </div>
    </div>

  </div>
</div>

<!-- VERSAO MOBILE -->
<div class="d-block d-lg-none">
  <!-- <section class="header-servico">
    <div class="container">
      <h2>Serviços</h2>
    </div>
  </section> -->
  <div class="container">
    <div class="col-sm-12 col-xs-12">
      <mat-form-field class="col-12">
        <mat-label for="tipoServicos">Exibir filtros</mat-label>
        <mat-select id="tipoServicos" name="tipoServicos">
          <mat-option value="Cartórios Judicializados">Cartórios Judicializados</mat-option>
          <mat-option value="Cível e Criminal">Cível e Criminal</mat-option>
          <mat-option value="Atos Lavrados nos Cartórios de Notas – Escritura/Procuração">Atos Lavrados nos Cartórios de Notas – Escritura/Procuração</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <h3>Cartórios Judicializados mob</h3>
    <p class="description-cartorio"></p>
  
    <div class="wrapper-content-items">
      <div class="item">
        <h4 class="nome-certidao">Certidão Cível - Período 20 anos</h4>
        <p class="descricao-certidao">
          Apontam os processos Cíveis respondidos durante os últimos 20 anos
        </p>
        <button class="btn-solicitar-certidao">Solicitar</button>
      </div>
      <div class="item">
        <h4 class="nome-certidao">Certidão de Pesquisa de Testamentos - Período 20 anos</h4>
        <p class="descricao-certidao">
          Aponta existência de escritura de Testamento
        </p>
        <button class="btn-solicitar-certidao">Solicitar</button>
      </div>
      <div class="item">
        <h4 class="nome-certidao">Certidão de Pesquisa de Testamentos - Período 25 Anos</h4>
        <p class="descricao-certidao">
          Aponta existência de escritura de Testamento
        </p>
        <button class="btn-solicitar-certidao">Solicitar</button>
      </div>
    </div>

  </div>
</div>
