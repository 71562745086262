import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorage } from '../../_entidade/LocalStorage';
import { CrudService } from '../../_sevice/CrudService';

@Component({
  selector: 'app-admin-global',
  templateUrl: './pagina.component.html',
  styleUrls: ['./pagina.component.less']
})


export class AdminGlobalComponent implements OnInit, OnDestroy,AfterViewInit {
 

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public service: CrudService,
    private cdr: ChangeDetectorRef,
    public st: LocalStorage
  ) {
    

   

  }

  ngOnDestroy() {
    
  }


  ngOnInit() {
    //console.log('dfdfd')
  }

  

  ngAfterViewInit() {
 

  }
   

}
