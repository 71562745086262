import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ul',
  templateUrl: './ul.component.html',
  styleUrls: ['./ul.component.less']
})
export class UlComponent {
  @Input() linkCss: any;
  @Input() links: any;

}
