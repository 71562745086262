import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-exigencias',
  templateUrl: './exigencias.component.html',
  styleUrls: ['./../../_meus-pedidos.component.scss']
})
export class ExigenciasComponent {
  @Input() exigencias: any[];
  @Input() pedido: any;
  @Input() compraAtual: any

  @Output() enviarMensagemExigenciaEvent = new EventEmitter<any>();

  exigenciaSelecionado: any;
  posicaoExigenciaSelecionado: any;
  tipoExigenciaSelecioando: any;

  //listaMensagens: any[];

  efeturarPagamento = false;
  exibicaoLista = false;

  exigenciaFinalizada = false;

  ngOnInit(): void {

    /*Rotina para verificar se a Exigência está com status de Finalizado.*/
    if (this.exigencias && this.exigencias.length > 0) {
      let findExigenciaNaoFinalizada = this.exigencias.find((e : any) => e.statusExigencia != 'Exigência Finalizada');

      if (!findExigenciaNaoFinalizada)
        this.exigenciaFinalizada = true
      else 
        this.exigenciaFinalizada = false
    }
  }

  pagar(exigencia: any) {
    this.exigenciaSelecionado = exigencia;
    this.efeturarPagamento = true;
  }

  selecionarTipoUploadExigencia(exigencia: any, posicao: any, tipoExigencia: any ) {

    this.posicaoExigenciaSelecionado = posicao;
    this.exibicaoLista = true;
    this.tipoExigenciaSelecioando = tipoExigencia;
    this.exigenciaSelecionado = exigencia;
  }

  enviarMensagemExigencia({mensagemExigencia}: any) {
    this.enviarMensagemExigenciaEvent.emit({ mensagemExigencia, posicaoExigencia: this.posicaoExigenciaSelecionado });
    //this.exigenciaSelecionado.listaMensagem = [mensagemExigencia, ...this.exigenciaSelecionado.listaMensagem];
  }

  voltarPagina() {
    this.exibicaoLista = !this.exibicaoLista;
  }
}
