import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CrudService } from 'src/app/_sevice/CrudService';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-consulta-protesto',
  templateUrl: './consultaProtesto.component.html',
  styleUrls: ['./consultaProtesto.component.scss'],
})
export class ConsultaProtestoComponent implements OnInit {
  @Input() conteudo: any;
  formBusca = new FormGroup({
    codigo: new FormControl('', Validators.required),
    email: new FormControl('',
    Validators.compose([
      Validators.required,
      Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")
    ])
     )
  });
  concluido: boolean = false

  get f() {
    return this.formBusca.controls;
  }
  constructor(
    public service: CrudService
  ) {

  }
  get codigo() {
    return this.formBusca.controls.codigo.value;
  }
  get email() {
    return this.formBusca.controls.email.value;
  }
  ngOnInit(): void {

  }

  novaSolicitacao(){

    this.concluido = false

  }
  consultar() {
    
    if (this.formBusca.valid) {
       const url = environment.http.API + 'Devedor/SalvaRequisicaoConsultaProtesto/?CPFCNPJ=' + this.codigo +  '&email=' + this.email;

      this.service.postObservableDirect(url,{}).subscribe(
        resposta => {
          
            this.concluido = true
           
        })
    }
  }

}
