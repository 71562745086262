<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>Sistema</mat-label>
    <input type="text" placeholder="Escolha" aria-label="Number" matInput [formControl]="myControl"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="getOptionText">
      <mat-option *ngFor="let option of options" [value]="option">
        {{option.DescBase}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>


<div class="row mb-5 " *ngFor="let relatorio of listRelatorio; let i = index">
  <div class="col-12 col-md-4 p10 card">
    <h3><strong>{{relatorio.Nome}}</strong></h3>
    <mat-form-field color="accent" *ngIf="relatorio.TemFiltro && temData(relatorio,false)">
      <mat-label>Data inicial</mat-label>
      <input matInput [id]="i+'_dateI'" [name]="i+'_dateI'" [matDatepicker]="_dateI_i" placeholder="Data inicial"
             (dateChange)="onDateChange($event.value,relatorio,'DataInicial')">
      <mat-datepicker-toggle matSuffix [for]="_dateI_i"></mat-datepicker-toggle>
      <mat-datepicker #_dateI_i></mat-datepicker>
    </mat-form-field>

    <mat-form-field color="accent" *ngIf="relatorio.TemFiltro && temData(relatorio,true)">
      <mat-label>Data final</mat-label>
      <input matInput [id]="i+'_dateF'" [name]="i+'_dateF'" [matDatepicker]="_dateF_i" placeholder="Data Final"
             (dateChange)="onDateChange($event.value,relatorio,'DataFinal',false)">
      <mat-datepicker-toggle matSuffix [for]="_dateF_i"></mat-datepicker-toggle>
      <mat-datepicker #_dateF_i></mat-datepicker>
    </mat-form-field>

    <img loading="lazy" class="iconXls" src="assets/excel.png">
    <button mat-raised-button color="primary" (click)="download(relatorio.IdRelatorio,relatorio)">Download</button>
  </div>
  <div class="col-12 col-md-8 p10 card">
    {{relatorio.Descricao}}
  </div>
  <div *ngIf="relatorio.TemGrafico" class="col-12 col-md-4 p10 card">
    <button>VER GRÁFICO</button>
  </div>
</div>
