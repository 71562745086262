import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CrudService } from "src/app/_sevice/CrudService";

@Component({
    selector: 'app-agendamentos-zoom',
    templateUrl: './agendamentos-zoom.component.html',
    styleUrls: ['./agendamentos-zoom.component.less']
})
export class AgendamentosZoomComponent implements OnInit {
    dateSelected: Date | null;
    timesOptions: Date[];

    constructor(private service: CrudService, private router: Router) { }

    ngOnInit(): void {
    }

    selecionarDataAgendamento(eventDate: Date) {
        if (eventDate) {
            this.service.getObservable('MeetingAccess/Schedule').subscribe({
                next: (response: any) => {
                    this.timesOptions = this.carregarHorariosDisponiveis(eventDate);
                }
            });
        }
    }

    carregarHorariosDisponiveis(date: Date): Date[] {
        const result = [] as Date[];
        const timeInicial = 8;
        const timeFinal = 17;
    
        for (var i = timeInicial; i < timeFinal; i++) {
            result.push(new Date(new Date(date).setHours(i, 0, 0)));
            result.push(new Date(new Date(date).setHours(i, 15, 0)));
            result.push(new Date(new Date(date).setHours(i, 30, 0)));
            result.push(new Date(new Date(date).setHours(i, 45, 0)));
    
            if (i + 1 == timeFinal)
                result.push(new Date(new Date(date).setHours(i + 1, 0, 0)));
        }
    
        return result;
    }

    navegar(url: any) {
        this.router.navigate([url])
      }
}