import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';

declare var $: any;

@Component({
  selector: 'app-list-service',
  templateUrl: './list-service.component.html',
  styleUrls: ['./list-service.component.scss']
})
export class ListServiceComponent implements AfterViewInit  {
  @Input() conteudo: any;
  
  constructor() { }

  ngAfterViewInit(): void {
    $(document).ready(() => {
      $('#v-tabs-tab a.nav-link').click((e: Event) => {
        e.preventDefault();
        const targetAnchor = $(e.target).closest('a.nav-link');
        targetAnchor.tab('show');
      });
    });
  }

  ngOnInit(): void {
  }

}
