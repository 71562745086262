<div class="wrapper-item" *ngIf="!compra?.editar">
  <div class="row" *ngFor="let comprador of compra?.compradores">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Nome da Busca</b></h3>
      <p class="info-section">{{comprador.nome}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>CPF / CNPJ</b></h3>
      <p class="info-section">{{comprador.documento}}</p>
    </div>

    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>CERP</b></h3>
      <p class="info-section">{{compra?.idItem}}</p>
    </div>
  </div>
  <div class="row" *ngFor="let comprador of compra?.compradores">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Data</b></h3>
      <p class="info-section">{{comprador.nascimento | date:'dd/MM/yyyy':'UTC'}}</p>
    </div>
  </div>
</div>

<app-form-21 *ngIf="compra?.editar" [itemEdicao]="compra" [edicao]="true" (cancelarEdicao)="cancelarEdicao(compra)"
  (salvarItem)="salvarItem($event)" [itemAtual]="compra.produto"></app-form-21>
