<div class="wrapper-text" *ngIf="!conteudo?.versao">
  <div class="content-text">
    <h1>{{conteudo.titulo}}</h1>
    <ng-container *ngIf="!conteudo?.versao">
      <h3>{{conteudo.paragrafo1H3}}</h3>
      <p [innerHTML]="conteudo.paragrafo1Texto"></p>
      <h2 *ngIf="conteudo.subTitulo">{{conteudo.subTitulo}}</h2>
      <h3>{{conteudo.paragrafo2H3}}</h3>
      <p [innerHTML]="conteudo.paragrafo2Texto"></p>
      <h3>{{conteudo.paragrafo3H3}}</h3>
      <p [innerHTML]="conteudo.paragrafo3Texto"></p>
      <h3>{{conteudo.paragrafo4H3}}</h3>
      <p [innerHTML]="conteudo.paragrafo4Texto"></p>
      <h3>{{conteudo.paragrafo5H3}}</h3>
      <p [innerHTML]="conteudo.paragrafo5Texto"></p>
      <h3>{{conteudo.paragrafo6H3}}</h3>
      <p [innerHTML]="conteudo.paragrafo6Texto"></p>
      <h3>{{conteudo.paragrafo7H3}}</h3>
      <p [innerHTML]="conteudo.paragrafo7Texto"></p>
      <h3>{{conteudo.paragrafo8H3}}</h3>
      <p [innerHTML]="conteudo.paragrafo8Texto"></p>
    </ng-container>
    <ng-container *ngIf="conteudo?.versao ==1">

      <p *ngFor="let item of conteudo.paragrafos" [innerHTML]="item.valor"></p>

    </ng-container>
  </div>
</div>

<div class="wrapper-text pt-5" *ngIf="conteudo?.versao == 1">
  <div >
    <h1>{{conteudo.titulo}}</h1>

    <ng-container *ngIf="conteudo?.versao ==1">
      <p *ngFor="let item of conteudo.paragrafos" [innerHTML]="item.valor"></p>
    </ng-container>
  </div>
</div>
