<div *ngIf="!conteudoPagina">configuração da pagina nao encontrada</div>
<div class="container">
  <menu-edicao-topo [conteudoPagina]="conteudoPagina" (changeConteudo)="changeConteudo($event)" *ngIf="edicaoPG"></menu-edicao-topo>
  <app-crud-gobal-lista [conteudoPagina]="conteudoPagina"
                        [configPage]="configPage"
                        [tipoGrid]="tipoGrid"
                        [entidadeOriginal]="entidadeOriginal"
                        [dadosEntidadeOriginal]="dadosEntidadeOriginal"
                        [dataSouceGrid]="dataSouceGrid"
                        *ngIf="conteudoPagina && !conteudoPagina.edicao && loadItens"
                        (listar)="listar($event)">
  </app-crud-gobal-lista>
  <app-crud-gobal-editar [conteudoPagina]="conteudoPagina"
                         [entidadeOriginal]="entidadeOriginal"
                         (listar)="listar($event)"
                         *ngIf="conteudoPagina && conteudoPagina.edicao && conteudoPagina.inputs">
  </app-crud-gobal-editar>
</div>

