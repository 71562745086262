<div class="wrapper-item">
  <div class="wrapper-info">

    <h3 class="info-section"><b>Documentos anexados:</b></h3>
    <p class="info-section" *ngFor="let arquivo of compra.documentos">
      <a [href]="arquivo?.nome" target="_blank" class="col-md-6 col-xs-12">
        {{ arquivo.nomeOriginal }}
      </a>
    </p>
  </div>
  <h2 class="title-info-solicitante">Dados dos assinadores:</h2>
  <ng-container *ngFor="let assinador of compra.assinadores">
    <div class="row">
      <div class="wrapper-info col-lg-3">
        <h3 class="info-section"><b>Nome</b></h3>
        <p class="info-section">{{assinador.nomeCompleto}}</p>
      </div>
      <div class="wrapper-info col-lg-3">
        <h3 class="info-section"><b>Email</b></h3>
        <p class="info-section">{{assinador.email}}</p>
      </div>
      <div class="wrapper-info col-lg-3">
        <h3 class="info-section"><b>CPF | CNPJ</b></h3>
        <p class="info-section">{{assinador.cpf}}</p>
      </div>
      <div class="wrapper-info col-lg-3">
        <h3 class="info-section"><b>Telefone</b></h3>
        <p class="info-section">{{assinador.telefone}}</p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="esconderCopias != true">
    <h2 class="title-info-solicitante" *ngIf="compra.copia">Dados dos leitores em cópia:</h2>
    <ng-container *ngFor="let leitor of compra.copia">
      <div class="row">
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Nome</b></h3>
          <p class="info-section">{{leitor.nome}}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Email</b></h3>
          <p class="info-section">{{leitor.email}}</p>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>CERP</b></h3>
      <p class="info-section">{{compra?.idItem}}</p>
    </div>
  </div>
</div>
