export class ConfigPaginas {
  Paginas: any[] 

  constructor( ) {
     
    this.Paginas = [
      {
        url: "/g/f",
        pagina: "funcao"
      },
      {
        url: "/administrativo/cadastros/cadastrocorporativo/dispositivo",
        pagina: "dispositivo"
      },
      {
        url: "/administrativo/cadastros/cadastrocorporativo/municipio",
        pagina: "municipio"
      },
      {
        url: "/administrativo/cadastros/cadastrocorporativo/orgaoemissor",
        pagina: "orgaoemissor"
      },
      {
        url: "/administrativo/cadastros/cadastrocorporativo/tipodocumento",
        pagina: "tipodocumento"
      },
      {
        url: "/administrativo/cadastros/cadastrocorporativo/profissao",
        pagina: "profissao"
      },
      {
        url: "/administrativo/cadastros/cadastrocorporativo/tipoexigencia",
        pagina: "tipoexigencia"
      },
      {
        url: "/administrativo/cadastros/cadastrocorporativo/nacionalidade",
        pagina: "nacionalidade"
      },
      {
        url: "/administrativo/cadastros/cadastrocorporativo/feriado",
        pagina: "feriado"
      },
      {
        url: "/administrativo/cadastros/cadastrocorporativo/etnia",
        pagina: "etnia"
      },
      {
        url: "/administrativo/cadastros/cadastrocorporativo/cartorio",
        pagina: "cartorio"
      }
    ]

     
  }

}
