import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import saveAs from 'file-saver';
import { LocalStorage } from 'src/app/_entidade/LocalStorage';
import { CrudService } from 'src/app/_sevice/CrudService';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { debounce } from 'lodash';
import { DateValidators } from '../generic-form/generic-form.component';
import { GlobalService } from '../../_sevice/GlobalService';
import { Observable, switchMap, tap } from 'rxjs';

export class DateValidator {

  static ptDate(control: FormControl): { [key: string]: any } {
    let ptDatePattern = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;

    if (!control.value.match(ptDatePattern))
      return { "ptDate": true };

    return { "ptDate": false };
  }
}

@Component({
  selector: 'app-meus-agendamentos',
  templateUrl: './meus-agendamentos.component.html',
  styleUrls: ['./_meus-agendamentos.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class MeusAgendamentosComponent implements OnInit {
  @Input() conteudo: any;
  showMaisFiltro: boolean = false
  paginaAtual: number = 1;
  tamanhoPagina: number = 10;
  totalItens: number = 0;
  pedidos: any
  isLinear = true;
  panelOpenState = false;
  //public visibilidadeDetalhes: boolean = false;
  show: boolean = false
  showDadosFaturamento: boolean = false
  semRequerente: boolean = true
  camposForm: any
  currentDate = new Date()
  lastMonthDate = new Date(new Date().setMonth(new Date().getMonth() - 1))
  erroBusca: any = false
  pipe = new DatePipe('pt-BR');
  isBack = this.storage.localStorageGet('BackOffice')
  isSuperAdm = this.storage.localStorageGet('SuperAdm')
  exibeObrigatoriedadeCampos: string = 'redClass';

  constructor(
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public teste: TesteService,
    public gb: GlobalService,
    private route: Router,
    public service: CrudService
  ) {
    this.pesquisar = debounce(this.pesquisar, 900)

  }

  ngOnInit(): void {
    this.criarForm()

    this.pesquisar()

  }

  setaArquivosMeet(entrada: any) {
    console.log(entrada)
  }
  resetaArquivosMeet(entrada: any) {
    console.log(entrada)

  }

  criarForm() {
    this.camposForm = this.formBuilder.group({
      dataInicial: [this.lastMonthDate],
      dataFinal: [this.currentDate],
      numeroPedido: [''],
      numeroAto: [''],
      cerp: [''],
      selo: [''],
      tipoStatus: ['']
    });
  }

  isUUID(uuid: any) {
    let s = uuid;

    s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (s === null) {
      return false;
    }
    return true;
  }



  alterarPagina(event: PageEvent) {
    const novaPagina = event.pageIndex + 1;
    const novoTamanhoPagina = event.pageSize;

    this.paginaAtual = novaPagina;
    this.tamanhoPagina = novoTamanhoPagina;

    this.pesquisar();
  }

  pesquisar() {
    let objsend = this.camposForm.value
    let cerpValid = this.isUUID(this.camposForm.get('cerp').value)
    if (this.camposForm.get('cerp').value != '' && !cerpValid) {
      objsend.cerp = ''

    }
    objsend['pagina'] = this.paginaAtual
    objsend['tamanhoPagina'] = this.tamanhoPagina


    var base64 = btoa(JSON.stringify(objsend))


    let endPoint = this.isBack == null ? 'MeetingAccess/GetAgendamentos' : 'MeetingAccess/GetAgendamentosBackoffice'

    const url = `${environment.http.API}${endPoint}`;
    this.erroBusca = false;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.postObservableDirect(url, objsend).subscribe({
      next: async (response) => {
        //console.log(response)

        if (response != null) {
          this.pedidos = response.Dados;
          this.totalItens = response.Tamanho;
          let el = document.getElementById('topo');
          el?.scrollIntoView();

          //console.log('pedidos = ', this.pedidos);
        }
      }, error: (err) => {
        console.error('Erro ao buscar detalhes do pedido:', err);
        if (err?.status == 401)
          this.route.navigate(['/login/entrar?logoff=1'])
        else
          this.erroBusca = true

        //this.route.navigate(['/login/entrar?logoff=1'])
      }
    });




  }
  arquivosAta: any
  setaArquivos(arquivos: any) {

    this.arquivosAta = arquivos

  }
  faltaArquivo = false
  concluirMeet(item: any) {
    if (this.arquivosAta && this.arquivosAta.arquivos && this.arquivosAta.arquivos.length > 0) {

      this.adicionarAssinatura(item)
    } else {
      this.faltaArquivo = true
    }
  }
  resetaArquivos(arquivos: any) {


    this.arquivosAta = arquivos



  }

  adicionarAssinatura(item: any) {

    let use = this.storage.localStorageGet("userlogged")
    let assinadores = new Array<any>();
    delete item.meetingInfo[this.posicaoMeetEdicao].enviarAta
    item.meetingInfo[this.posicaoMeetEdicao].ataEnviada = true

    for (var i = 0; i < item.meetingInfo[this.posicaoMeetEdicao].anfitrioes.length; i++) {
      let assinador = {
        "cpf": item.meetingInfo[this.posicaoMeetEdicao].anfitrioes[i].cnpjCpf,
        "email": item.meetingInfo[this.posicaoMeetEdicao].anfitrioes[i].email,
        "nomeCompleto": item.meetingInfo[this.posicaoMeetEdicao].anfitrioes[i].nome,
        "telefone": item.meetingInfo[this.posicaoMeetEdicao].anfitrioes[i].telefone,
        "tipo": {
          "Descricao": "ELETRONICA",
          "Id": "E"
        }
      }
      assinadores.push(assinador)
    }

    for (var i = 0; i < item.meetingInfo[this.posicaoMeetEdicao].convidados.length; i++) {
      let assinador = {
        "cpf": item.meetingInfo[this.posicaoMeetEdicao].convidados[i].cnpjCpf,
        "email": item.meetingInfo[this.posicaoMeetEdicao].convidados[i].email,
        "nomeCompleto": item.meetingInfo[this.posicaoMeetEdicao].convidados[i].nome,
        "telefone": item.meetingInfo[this.posicaoMeetEdicao].convidados[i].telefone,
        "tipo": {
          "Descricao": "ELETRONICA",
          "Id": "E"
        }
      }
      assinadores.push(assinador)
    }

    for (var i = 0; i < item.meetingInfo[this.posicaoMeetEdicao].mediadores.length; i++) {
      let existe = assinadores.find((x: any) => x.cpf == item.meetingInfo[this.posicaoMeetEdicao].mediadores[i].cnpjCpf)
      if (existe == undefined || existe == null) {
        let assinador = {
          "cpf": item.meetingInfo[this.posicaoMeetEdicao].mediadores[i].cnpjCpf,
          "email": item.meetingInfo[this.posicaoMeetEdicao].mediadores[i].email,
          "nomeCompleto": item.meetingInfo[this.posicaoMeetEdicao].mediadores[i].nome,
          "telefone": item.meetingInfo[this.posicaoMeetEdicao].mediadores[i].telefone,
          "tipo": {
            "Descricao": "ELETRONICA",
            "Id": "E"
          }
        }
        assinadores.push(assinador)
      }
    }



    let url = environment.http.API_VENDA_ECARTORIO + 'ec_produtos/getbyalink?aLink=/pedido/assinatura-eletronica/assinatura-eletronica-credito-avulso-1-envelope'
    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {
        if (response != null) {
          let assinatura = {
            "emailUser": use.email,
            "guidPedido": crypto.randomUUID(),
            "guidPedidoPai": item.meetingInfo.guidPedido,
            "meetingGuid": item.meetingInfo[this.posicaoMeetEdicao].meetingGuid,
            "guidUser": use.keyLogin,
            "listaCompra": [
              {
                "assinadores": assinadores,
                copia: [{ nome: "", email: "" }],
                "documentos": this.arquivosAta.arquivos,
                "idItem": crypto.randomUUID(),
                "produto": {
                  "idItemUnico": 100001,
                  "titulo": "Assinatura Eletrônica - Crédito avulso 1 Envelope",
                  "aLink": "/pedido/assinatura-eletronica/assinatura-eletronica-credito-avulso-1-envelope",
                  "linkAnterior": "https://e-cartoriorj.com.br/Pedido/FormAssinatura/10013",
                  "descricao": "Permite até 99 assinaturas por envelope.",
                  "idAto": 10013,
                  "idTipoAto": 6,
                  "idForm": 5,
                  "valorUnitario": 27.9,
                  "origem": "RJ",
                  "valorTotal": 27.9,
                  "composicaoValor": [
                    {
                      "tipo": "valorAto",
                      "valor": 27.9
                    }
                  ],
                  "qtde": 1,
                  "semLocalidade": true,
                  "descricaoTipoAto": "Assinatura",
                  "idCartorioAto": 10150,
                  "tipo": 5
                },
                "totalAdicionalAdm": 0,
                "totalAto": response.valorTotal,
                "valorAto": response.valorTotal
              }
            ],
            "origem": "CNRAssinatura",
            "totalAdicionalAdm": 0,
            "totalPagar": response.valorTotal,
            "totalPedido": response.valorTotal,
            "v": 1
          }
          this.service.postObservable(assinatura, 'Venda/PreparaPedido').subscribe({
            next: (response) => {
              console.log(response);
              item.meetingInfo[this.posicaoMeetEdicao].pedidoAssinatura = response
              item.status = { slug: 'coletando-assinaturas', descricao: 'Coletando assinaturas' }
              if (item['historico']) {
                item['historico'].push({ acao: 'envio da ata e inicio da coleta de assinaturas', usuario: use.nomeUsuario, guidUser: use?.keyLogin, data: new Date() })

              } else {
                item['historico'] = [{ acao: 'envio da ata e inicio da coleta de assinaturas', usuario: use.nomeUsuario, guidUser: use?.keyLogin, data: new Date() }]

              }
              this.atualizaPedido(item)

              let urlBack = environment.http.API_BACK + 'Empresarial/Pedido';

              (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

              this.service.postObservableDirect(urlBack, response).subscribe({
                next: async (responseBack) => {
                  console.log(responseBack);



                }, error: (err) => {
                  console.log(err);
                }
              });
            }
          });
          console.log(assinatura)
        }
      }, error: (err) => {
        console.error(err);
      }
    });


  }

  entratMeet(item: any, meetingGuid: any) {
    let userlogged = this.storage.localStorageGet('userlogged');
    console.log(meetingGuid)

    let meet = item.meetingInfo[0].anfitrioes.find((x: any) => x.email == userlogged.email);
    if (meet == undefined) {
      meet = item.meetingInfo[0].participantes.find((x: any) => x.email == userlogged.email);
    }
    if (meet == undefined) {
      meet = item.meetingInfo[0].mediadores.find((x: any) => x.email == userlogged.email);
    }
    if (meet)
      this.route.navigate(['meeting/video-mediacao/' + meetingGuid + '/' + meet.userGuid])
  }
  validarObrigatoriedadeCategoria(entrada: any) {
    delete entrada.obrigatoriedadecategoria
    console.log(entrada);

  }
  validarObrigatoriedadetaxaCNR(entrada: any) {
    if (entrada.exigencia.taxaCNR > 0) {
      delete entrada.obrigatoriedadetaxaCNR

    }
  }
  validarObrigatoriedadetaxaAdministracao(entrada: any) {
    if (entrada.exigencia.taxaAdministracao > 0) {
      delete entrada.obrigatoriedadetaxaAdministracao
    }
  }
  validarObrigatoriedadehonorario(entrada: any) {
    if (entrada.exigencia.honorario > 0) {
      delete entrada.obrigatoriedadehonorario
    }
  }

  download() {
    let objsend = this.camposForm.value
    let cerpValid = this.isUUID(this.camposForm.get('cerp').value)
    if (this.camposForm.get('cerp').value != '' && !cerpValid) {
      objsend.cerp = ''

    }
    objsend['pagina'] = this.paginaAtual
    objsend['tamanhoPagina'] = this.tamanhoPagina
    const url = environment.http.API + 'MeetingAccess/ExportAgendamentosBackoffice'

    this.gb.postObservableBLOBDirect(url, objsend, 'rel')
  }

  salvarExigencia(entrada: any) {
    //
    let erro = 0
    if (entrada.exigencia.taxaCNR == 0) {
      entrada.obrigatoriedadetaxaCNR = 'redClass'
      erro++

    }

    if (entrada.exigencia.taxaAdministracao == 0) {
      entrada.obrigatoriedadetaxaAdministracao = 'redClass'
      erro++

    } else if (entrada.exigencia.taxaAdministracao < 1) {
      entrada.obrigatoriedadetaxaAdministracao = 'redClassValor'
      erro++

    }
    if (entrada.exigencia.honorario == 0) {
      entrada.obrigatoriedadehonorario = 'redClass'
      erro++

    }
    if (entrada.exigencia.categoria == undefined || entrada.exigencia.categoria == null) {
      entrada.obrigatoriedadecategoria = 'redClass'
      erro++

    }


    if (erro != 0) {
      return;

    }



    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";
    let tempId = entrada["_id"]
    delete entrada["_id"]
    let use = this.storage.localStorageGet("userlogged")

    let url = environment.http.API + 'MeetingAccess';
    entrada['status'] = { slug: 'aguardando-pagamento-audiencia', descricao: "Aguardando Pagamento Audiência" }
    //console.log('atualizarPedido', url);
    entrada['listaCompra'][0]['listaExigencia'] = []
    let exigencia = {
      "nuItem": entrada['listaCompra'][0]["numeroAto"],
      "dataExigencia": "2024-09-23T00:00:00",
      "statusExigencia": "Exigência Financeira",
      "composicaoValor": [
        {
          "idTipoAtoValor": 21,
          "idCartorio": 10460,
          "valor": entrada.exigencia.taxaAdministracao
        },
        {
          "idTipoAtoValor": 19,
          "idCartorio": 10460,
          "valor": entrada.exigencia.taxaCNR
        },
        {
          "idTipoAtoValor": 20,
          "idCartorio": 10460,
          "valor": entrada.exigencia.honorario
        }
      ],
      "valorExigencia": (entrada.exigencia.taxaAdministracao + entrada.exigencia.taxaCNR + entrada.exigencia.honorario),
      "dataPagamento": null,
      "valorPago": 0,
      "formaPagamento": null,
      "guidUsuario": use.keyLogin,
      "dtPrazo": "2024-09-23T00:00:00",
      "idExigencia": 0,
      "tipoExigencia": "Exigência Financeira",
      "descricao": "pagamento das taxas complementares",
      "dataLiberacao": null,
      "dataCumprimento": null,
      "origemAto": "RJ",
      "origemSistema": "CNR",
    }
    entrada['listaCompra'][0]['listaExigencia'].push(exigencia)

    if (entrada['historico']) {
      entrada['historico'].push({ acao: 'definir valores da audiência', usuario: use.nomeUsuario, guidUser: use?.keyLogin, data: new Date() })

    } else {
      entrada['historico'] = [{ acao: 'definir valores da audiência', usuario: use.nomeUsuario, guidUser: use?.keyLogin, data: new Date() }]

    }


    this.service.putObservableDirect(url, entrada).subscribe({
      next: async (response) => {


        let nepd = response
        nepd["_id"] = tempId
        entrada = response
        let urlBack = environment.http.API_BACK + 'Exigencia/AdicionarExigencia';

        (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

        this.service.postObservableDirect(urlBack, exigencia).subscribe({
          next: async (responseBack) => {
            entrada['listaCompra'][0]['listaExigencia'][0]['idExigencia'] = responseBack
            this.atualizaPedido(entrada, true)


          }, error: (err) => {
            console.log(err);
          },
          complete: () => (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%"
        });

      }, error: (err) => {

        //console.error(err);
      }
    });

  }
  atualizaPedido(entrada: any, enviaEmail: boolean = false, temHistrico: boolean = false, historico: string = '') {

    let url = environment.http.API + 'MeetingAccess';

    let tempId = entrada["_id"]
    delete entrada["_id"]
    delete entrada["editar"];
    delete entrada["pagaExigencia"];
    delete entrada["mostraUpload"];
    delete entrada["verDetalhes"];

    if (temHistrico) {
      let use = this.storage.localStorageGet("userlogged")

      if (entrada['historico']) {
        entrada['historico'].push({ acao: historico, usuario: use.nomeUsuario, guidUser: use?.keyLogin, data: new Date() })

      } else {
        entrada['historico'] = [{ acao: historico, usuario: use.nomeUsuario, guidUser: use?.keyLogin, data: new Date() }]

      }
    }
    //this.pesquisar()
    this.service.putObservableDirect(url, entrada).pipe(
      switchMap(() => this.enviarEmailSmsMeeting(entrada, enviaEmail))
    ).subscribe({
      next: (response) => {

        
        console.log('sasdfdsafs', response) 



      }, error: (err) => {

        console.error(err);
      }
    });
  }
  posicaoMeetEdicao: any = 0


  enviarEmailSmsMeeting(item: any, enviaEmail: boolean = false): Observable<any> {

    if (item?.nomeUser && item?.emailUser && enviaEmail) {

      const payloadEmail = {
        usersEmail: [
          {
            nome: item.nomeUser
            , email: item.emailUser
            , parametrosReplace: {
              user_name: item.nomeUser
              , link: `${location.origin}/fadmin/meus-agendamentos`
            }
          }
        ],
        assunto: `Audiência - ${item.numeroPedido}`,
        template: 'meeting_audiencia_aprovada'
      };

      const payloadSms = {
        usersSms: [
          {
            telefone: item.telefoneUser
            , parametrosReplace: {
              user_name: item.nomeUsuario,
              link: `${location.origin}/fadmin/meus-agendamentos`
            }
          }
        ],
        template: 'meeting_audiencia_aprovada'
      };

      let envio = { emailRequest: payloadEmail, smsRequest: payloadSms }
      return this.service.postObservableReturnText(envio, 'Notificacao/EnviarEmailSmsLote');
    } else {
      throw new Error("");
    }
  }


  enviarAta(meeet: any, posicao: any, item: any) {
    for (var i = 0; i < item.meetingInfo.length; i++) {
      item.meetingInfo[i].enviarAta = false
    }

    meeet.enviarAta = true
    this.posicaoMeetEdicao = posicao
  }

  trocarStatus(slug: any, descricao: any, entrada: any, finalizar = false, posi = 0) {

    let url = environment.http.API + 'MeetingAccess';
    entrada['status'] = { slug: slug, descricao: descricao }
    let tempId = entrada["_id"]
    delete entrada["_id"]
    let use = this.storage.localStorageGet("userlogged")

    if (entrada['historico']) {
      entrada['historico'].push({ acao: 'troca de status manualmente para ' + descricao, usuario: use?.nomeUsuario, guidUser: use?.keyLogin, data: new Date() })

    } else {
      entrada['historico'] = [{ acao: 'troca de status manualmente para ' + descricao, usuario: use?.nomeUsuario, guidUser: use?.keyLogin, data: new Date() }]

    }

    entrada.meetingInfo[posi].meetFinalizada = finalizar
    this.atualizaPedido(entrada)

  }

  togglePanel(index: any) {
    this.panelOpenState = !this.panelOpenState;
  }


  verDadosFaturamento(item: any, i: any) {


  }

  OcultarDadosFaturamento(item: any, i: any) {
    this.showDadosFaturamento = false
  }

  filtrarMeusPedidos() {

  }


  ocultarDetalhes(item: any, i: any) {
    item['verDetalhes'] = false
    //console.log('ocultar')
  }

  verPanelOpenState(item: any, index: any) {
    item.panelOpenState = !item.panelOpenState;
  }

  verDetalhesPedido(item: any, i: any) {
    //console.log('ver')
    item['verDetalhes'] = true

  }

  verDetalhesPedidoImpressao(item: any, j: any) {
    item['verDetalhes'] = true;



    //this.preparaPrint('', j);

  }

  print: any = false;
  agendar(item: any, status: any) {
    const meetingGuid = item.meetingInfo[item.meetingInfo.length - 1].meetingGuid;
    const statusJson = JSON.stringify(status);

    this.route.navigate(['fadmin/meeting/agendar-mediacao/' + meetingGuid + '/' + statusJson]);
  }

  preparaPrint(display: any, j: any) {
    this.print = true;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%";

    (<HTMLInputElement>document.getElementById("titleMeusPedidos")).style.display = display;
    (<HTMLInputElement>document.getElementById("header")).style.display = display;
    (<HTMLInputElement>document.getElementById("footer")).style.display = display;
    (<HTMLInputElement>document.getElementById("filtro")).style.display = display;
    (<HTMLInputElement>document.getElementById("paginacaoItens")).style.display = display;
    (<HTMLInputElement>document.getElementById("logoPrint")).style.display = '';

    (<NodeListOf<HTMLElement>>document.getElementsByName("opcaoPedido")).forEach(e => e.style.display = display);
    (<NodeListOf<HTMLElement>>document.getElementsByName("btnDetalhes")).forEach(e => e.style.display = display);

    (<HTMLInputElement>document.getElementById("conpr")).classList.remove('container');

    for (var i = 0; i < this.pedidos?.length; i++) {
      if (i != j) {
        (<HTMLInputElement>document.getElementById(i + "p")).style.display = display;

      }
    }
    let leng = this.pedidos?.length
    window.onafterprint = function () {
      (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%";
      (<HTMLInputElement>document.getElementById("logoPrint")).style.display = 'none';

      (<HTMLInputElement>document.getElementById("titleMeusPedidos")).style.display = '';
      (<HTMLInputElement>document.getElementById("header")).style.display = '';
      (<HTMLInputElement>document.getElementById("footer")).style.display = '';
      (<HTMLInputElement>document.getElementById("filtro")).style.display = '';
      (<HTMLInputElement>document.getElementById("paginacaoItens")).style.display = '';

      (<NodeListOf<HTMLElement>>document.getElementsByName("opcaoPedido")).forEach(e => e.style.display = '');
      (<NodeListOf<HTMLElement>>document.getElementsByName("btnDetalhes")).forEach(e => e.style.display = '');

      (<HTMLInputElement>document.getElementById("conpr")).classList.add('container');

      for (var i = 0; i < leng; i++) {
        if (i != j) {
          (<HTMLInputElement>document.getElementById(i + "p")).style.display = '';

        }
      }
    }

    if (display != '')
      window.print();

  }

  baixarTodosAtos(idPedido: any, numeroPedido: any, quantidade: any) {

    const url = `${environment.http.API_BACK}Pedido/BaixarAtosPorId?idPedido=${idPedido}&numeroPedido=${numeroPedido}`;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    if (quantidade == 1) {
      this.service.getObservableBlob(url).subscribe(blob => {
        saveAs(blob, numeroPedido + '.pdf');
      });
    }
    else {
      this.service.getObservableBlob(url).subscribe(blob => {
        saveAs(blob, numeroPedido + '.zip');
      });
    }
  }
  cancelarEdicao(itemAtual: any) {

    delete itemAtual['editar']

  }
  idItemEdicao: any;
  editarAto(itemAtual: any, pedido: any, posicao: any) {
    //console.log(itemAtual)

    for (var i = 0; i < pedido?.listaCompra?.length; i++) {
      if (i != posicao) {
        delete pedido?.listaCompra[i]['editar']
      }
    }
    itemAtual['editar'] = true
    this.idItemEdicao = itemAtual?.idItem
  }

  enviarMensagemExigencia({ mensagemExigencia, posicaoExigencia }: any, pedido: any, posicao: any) {
    this.updatePedidoExigencia(mensagemExigencia, pedido, posicao, posicaoExigencia);
  }



  vistarAto(compra: any) {

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    let userlogged = this.storage.localStorageGet('userlogged')

    //console.log(compra)
    //console.log(userlogged)

    let urlBack = `${environment.http.API_BACK}Ato/VistarAtoMongo?cerp=${compra?.cerp}&userGuid=${userlogged?.keyLogin}`;
    //console.log('urlBack', urlBack)

    this.service.postObservableDirect(urlBack, null).subscribe({
      next: async (response) => {
        //this.route.navigate(['pedido/pagar'])

        this.route.navigate(['pedido/pagar/' + response.pedidoGuid])


      }, error: (err) => {
        //console.error(err);
      }
    });

  }

  updatePedidoExigencia(mensagemExigencia: any, pedido: any, posicaoCompra: any, posicaoExigencia: any) {
    let url = environment.http.API + 'Venda';
    let tempId = { ...pedido["_id"] };

    let compraObj = pedido.listaCompra[posicaoCompra];
    let exigenciaObj = compraObj?.listaExigencia[posicaoExigencia];

    if (exigenciaObj?.listaMensagem)
      exigenciaObj.listaMensagem = [mensagemExigencia, ...exigenciaObj.listaMensagem];
    else
      exigenciaObj.listaMensagem = [mensagemExigencia];

    delete compraObj["editar"];
    delete pedido["_id"];

    compraObj.status = 'Exigência Respondida';

    this.service.putObservableDirect(url, pedido).subscribe({
      next: async (response) => {
        let nepd = response;

        let urlBackResponderExigencia = null;
        let payloadResponderExigencia = null;

        nepd["_id"] = tempId;
        pedido.editar = false;

        //console.log(compraObj);
        //console.log(compraObj?.produto?.origem);
        //console.log(mensagemExigencia);

        if (compraObj?.produto?.origem == 'BR') {
          payloadResponderExigencia = {
            idExigencia: exigenciaObj?.idExigencia,
            mensagem: mensagemExigencia?.mensagem,
            guidUsuario: pedido?.guidUser,
            Anexos: mensagemExigencia?.linksDownload?.map((file: any) => ({ pathDoc: file.nome, nomeArquivo: file.nomeOriginal }))
          };

          urlBackResponderExigencia = `${environment.http.API_BACK}Exigencia/ResponderExigenciaMongoBR`;
        }
        else {
          payloadResponderExigencia = nepd;
          urlBackResponderExigencia = `${environment.http.API_BACK}Exigencia/ResponderExigenciaMongo?origem=CNR&idExigencia=${exigenciaObj?.idExigencia}&mensagem=${mensagemExigencia?.mensagem}&guidUsuario=${pedido?.guidUser}`;
        }
        //console.log('urlBack', urlBack)

        this.service.postObservableDirect(urlBackResponderExigencia, payloadResponderExigencia).subscribe({
          next: async (responseBack) => { },
          error: (err) => { }
        });
      }, error: (err) => { }
    });
  }

  baixarNotaFiscal(idPedido: any) {

    const url = `${environment.http.API_BACK}Pedido/BaixarNotaFiscal?idPedido=${idPedido}`;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.getObservableBlob(url).subscribe(blob => {
      saveAs(blob, 'notafiscal.pdf');
    });

  }

  baixarAto(nuAto: any) {

    const url = `${environment.http.API_BACK}Ato/BaixarAtoV3?nuAto=${nuAto}`;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.getObservableBlob(url).subscribe(blob => {
      saveAs(blob, `${nuAto}.pdf`);
    });

  }

  baixarRecibo(nuAto: any) {

    const url = `${environment.http.API_BACK}Ato/BaixarReciboV3?nuAto=${nuAto}`;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.getObservableBlob(url).subscribe(blob => {
      saveAs(blob, `${nuAto}.pdf`);
    });

  }

  pagarExigencia({ exigencia }: any) {
    let idExigencia = exigencia?.idExigencia;
    this.route.navigate(['/pedido/pagar/' + idExigencia]);
  }

  efetuarPagamento(guidPedido: any) {
    this.route.navigate(['/pedido/pagar/' + guidPedido]);
  }



  getdate(dataenviada: any) {
    let dttratada = dataenviada?.substr(0, 10).replace('T00:00:00Z', '').split('-')

    return new Date(dttratada[0], dttratada[1] - 1, dttratada[2])
  }



}
function ngxPrint(elementToPrint: HTMLElement, printOptions: {
  printMode: string; // Pode ser 'iframe' ou 'popup'
  bodyClass: string; // Classe CSS a ser adicionada ao corpo ao imprimir
  standard: string; // Pode ser 'html5', 'iframe' ou 'popup'
}) {
  throw new Error('Function not implemented.');
}

