import { Component, OnInit, Input, Inject, Renderer2, HostListener, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocalStorage } from '../../_entidade/LocalStorage';
import { environment } from '../../../environments/environment';
import { CrudService } from '../../_sevice/CrudService';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericValidator } from 'src/app/_sevice/GenericValidator';
import { localidade } from 'src/app/_configPage/listas/localidade';
import { debounce } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
const SCRIPT_PATH = 'https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js';
import { ScriptService } from "../../_sevice/script.service";
import { DOCUMENT } from '@angular/common';
import { TesteService } from '../../_sevice/teste.service';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})


export class PaymentComponent implements OnInit, OnDestroy {

  @Input() totalPagar: any
  @Input() parcelas: any
  @Input() origem: any
  
  pedido: any
  @Input() installmentList: { number: any, amount: number }[] = [];

  panelOpenState = false;

  private cardTokenGerado = '';
  public pagandoCredito = false;
  private tentativasGerarCardToken = 0;
  public HabilitaForm: any = false;
  private exitComponent = false;

  dadosFaturamento: any//= [{ nome: 'Matheus Jardim Passos', cpf: '076.433.517-08', endereco: 'Rua Livre 2, nº 1000 - Acari 2', cidadeEestado: 'Rio de Janeiro - RJ', cep: '21531-450' }]
  dadosCartaoErrado: any = false
  pagamentoRecusado: any = false
  faturaAnr: any
  listaObjetos: any[]

  listuf: any[] = localidade.listuf
  listufFilter: any = this.listuf
  listCidade: any[]
  listCidadeFilter: any
  ufSelected = this.storage.localStorageGet('localidade')?.uf || { "Id": "65b11f81c0095aa0cc393545", "IdEstado": 19, "Sigla": "RJ", "Nome": "Rio de Janeiro" }
  cidadeSelected = this.storage.localStorageGet('localidade')?.cidade || { "Id": "65b11fe7c0095aa0cc394397", "IdCidade": "3658", "IdEstado": "19", "Nome": "Rio de Janeiro" }

  qrCodePix: any = {
    qrCode: null,
    qrCodeBase64: null
  }

  mercadoPago: any = {
    chavePublica: null,
    parcelas: ['']
  }


  ngOnDestroy(): void {
    this.exitComponent = true;
  }

  calculaQuantidadeCertidoes(pedido: any) {
    let qtde = 0
    for (var i = 0; i < pedido?.listaCompra?.length; i++) {
      if (pedido?.listaCompra[i]?.compradores) {
        if (pedido?.listaCompra[i]?.listCartorio)
          qtde += pedido?.listaCompra[i]?.compradores.length * pedido?.listaCompra[i]?.listCartorio?.length
        else
          qtde += pedido?.listaCompra[i]?.compradores.length

      } else {
        qtde++
      }
    }
    return qtde;
  }

  dadosCredito = this.formBuilder.group({
    nomeTitular: ['', Validators.required],
    tipoDocumento: ['', Validators.required],
    numeroDocumento: ['', Validators.required], //
    validadeMes: ['', Validators.required],
    validadeAno: ['', Validators.required],
    cvc: ['', Validators.required], //
    numeroCartao: ['', Validators.required],
    qtdParcelas: ['', Validators.required],
    opcoesParcelamento: [''],
    idEmissor: ['']
  });

  public dadosPagamento: any = {};

  public detalhesCartaoCredito: any = null; // recuperados do MP via ajax quando usuario digita numero do cartao

  dadosDebito = this.formBuilder.group({
    nomeTitular: ['', Validators.required],
    tipoDocumento: ['', Validators.required],
    numeroDocumento: ['', Validators.required],
    validadeMes: ['', Validators.required],
    validadeAno: ['', Validators.required],
    cvc: ['', Validators.required],
    numeroCartao: ['', Validators.required],
    qtdParcelas: ['-1', Validators.required],
    opcoesParcelamento: ['', Validators.required],
  });

  formDadosFaturamento = this.formBuilder.group({
    nome: ['', Validators.required],
    cnpjCpf: ['', Validators.compose([
      Validators.required,
      GenericValidator.isValidCPFCNPJ()
    ])],
    cep: ['', Validators.required],
    uf: ['', Validators.required],
    cidade: ['', Validators.required],
    bairro: ['', Validators.required],
    tipoLogradouro: ['', Validators.required],
    logradouro: ['', Validators.required],
    numero: ['', Validators.required],
    complemento: ['']
    //idUsuarioFaturacao: ['']
  });

  constructor(
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public service: CrudService,
    public device: DeviceDetectorService,
    private scriptService: ScriptService,
    @Inject(DOCUMENT) private document: Document,
    private routeAc: ActivatedRoute,
    private renderer: Renderer2,
    public teste: TesteService,
    private route: Router,
  ) {
    this.getFaturacao = debounce(this.getFaturacao, 800)
  }
  //
  ngOnInit(): void {

    

    (<HTMLInputElement>document.getElementById("myNavManual")).style.width = "0%";
    (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%";
    this.storage.localStorageRemove('pedido');

    setTimeout(() => {
      (<HTMLInputElement>document.getElementById("myNavManual")).style.width = "0%";
      (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%";
      this.storage.localStorageRemove('pedido');
    }, 500);

    this.uf?.valueChanges.subscribe((newValue: any) => {
      this.listufFilter = this._filter(newValue);
    })
    this.cidade?.valueChanges.subscribe((newValue: any) => {
      this.listCidadeFilter = this._filterCidade(newValue);
    })

    let produto = this.routeAc.snapshot.paramMap.get('produto');
    this.storage.localStorageRemove("pedido");
    if (this.origem != 'meet')
      this.carregarPedido(produto)
    else
      this.carregarPedidoMeet(produto)

  }
  carregarPedidoMeet(item: any) {

    const url = `${environment.http.API}MeetingAccess/getPedido?guidPedido=${item}`;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    //ajsutar
    this.service.getObservableDirect(url).subscribe({
      next: (response) => {
        if (response) {
          (<HTMLInputElement>document.getElementById("myNavManual")).style.width = "0%";
          (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

          this.pedido = response
          console.log(this.pedido)
          this.getDadosFaturamento();
        }
      },
      error: (err) => {

      }
    });
  }

  carregarPedido(item: any) {

    const url = `${environment.http.API}Venda/getPedido?guidPedido=${item}`;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    //ajsutar
    this.service.getObservableDirect(url).subscribe({
      next: (response) => {
        if (response) {
          (<HTMLInputElement>document.getElementById("myNavManual")).style.width = "0%";
          (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

          this.pedido = response
          console.log(this.pedido)
          this.getDadosFaturamento();
        }
      },
      error: (err) => {

      }
    });
  }

  //
  setTipoLogradouro(logra: any) {
    if (logra.indexOf('Rua') == 0) {
      this.formDadosFaturamento.get('tipoLogradouro')?.setValue('RUA');
    }
    else if (logra.indexOf('Av. ') == 0 || logra.indexOf('Avenida') == 0) {
      this.formDadosFaturamento.get('tipoLogradouro')?.setValue('AVENIDA');
    }
    else if (logra.indexOf('Alameda') == 0) {
      this.formDadosFaturamento.get('tipoLogradouro')?.setValue('ALAMEDA');
    }
  }

  buscarEndereco($event: any) {
    let cep = $event.target.value.replace('-', '')
    if (cep.length == 8) {
      this.service.getObservableDirect('https://viacep.com.br/ws/' + cep + '/json').subscribe(
        {
          next: async (response: any) => {
            if (!response['erro']) {
              let logLimpo = response.logradouro.replace('Rua ', '').replace('Av. ', '').replace('Avenida ', '').replace('Alameda ', '')

              this.formDadosFaturamento.get('logradouro')?.setValue(logLimpo)
              this.formDadosFaturamento.get('bairro')?.setValue(response.bairro)
              this.ufSelected = localidade.listuf.find((x: any) => x.Sigla == response.uf)
              this.formDadosFaturamento.get('uf')?.setValue(this.ufSelected)
              this.loadCidade(this.ufSelected.IdEstado, true, response.localidade)
              this.setTipoLogradouro(response.logradouro)
            } else {

              this.formDadosFaturamento.get('cep')?.setErrors({ cepNotMatch: true });

            }
          },
          error: (err) => {
            console.error(err);
          }
        }
      )

    }
  }

  get uf() {
    return this.formDadosFaturamento.get('uf')
  }

  get cidade() {
    return this.formDadosFaturamento.get('cidade')
  }

  public displayProperty(value: any) {
    if (value) {
      return value.Nome;
    }
  }

  public displayPropertyCidade(value: any) {

    if (value) {
      return value.Nome;
    }
  }

  getCidade(event: any) {
    this.setLocal()

    this.loadCidade(event.option.value['IdEstado'])
    this.ufSelected = this.uf?.value

    if (!event.isUserInput) {
      this.formDadosFaturamento.get('cidade')?.setValue(null);
    }
  }

  setLocal() {
    this.cidadeSelected = this.cidade?.value
  }

  private _filter(value: any): any {

    if (value && typeof value === "string")
      return this.listuf.filter((x) => x.Nome.toLowerCase().indexOf(value.toLowerCase()) == 0)
    else
      return this.listuf
  }

  private _filterCidade(value: any): any {
    if (value && typeof value === "string") {
      let valueNormalize = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return this.listCidade.filter((x) => x.Nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(valueNormalize) == 0);
    }
    else
      return this.listCidade
  }

  loadCidade(IdEstado: any, setCidade: boolean = false, strCidade: string = '') {
    const url = environment.http.API + 'Localidade/cidade?IdEstado=' + IdEstado;

    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {
        if (response != null) {
          this.formDadosFaturamento.get('cidade')?.setValue(null)

          this.listCidadeFilter = response;
          this.listCidade = response;
          if (setCidade) {
            if (strCidade == '')
              this.formDadosFaturamento.get('cidade')?.setValue(this.storage.localStorageGet('localidade')?.cidade)
            else {
              this.cidadeSelected = response.find((x: any) => {
                return x.Nome.toLowerCase() == strCidade.toLowerCase();
              })

              if (this.cidadeSelected != undefined)
                this.formDadosFaturamento.get('cidade')?.setValue(this.cidadeSelected)
            }
          }
        }
      }, error: (err) => {
        console.error(err);
      }
    });
  }
  //

  get debitCard() {
    return this.dadosCredito.controls;
  }
  get f() {
    return this.dadosCredito.controls;
  }

  get creditCard() {
    return this.dadosCredito.controls;
  }

  alterarFaturamento() {
    this.HabilitaForm = true;
  }


  editDadosFaturamento() {

    this.formDadosFaturamento.markAllAsTouched();
    if (this.formDadosFaturamento.valid) {
      if (!Array.isArray(this.dadosFaturamento)) {
        this.dadosFaturamento = [];
        this.dadosFaturamento.push(this.formDadosFaturamento.value);
      }

      this.pedido['dadosFaturamento'] = this.formDadosFaturamento.value

      //console.log('editDadosFaturamento', this.pedido['dadosFaturamento'])

      this.atualizarPedido(this.pedido)

      this.HabilitaForm = false;
    }
  }

  cancelarDadosFaturamento() {
    this.HabilitaForm = false;
  }


  excluirItem(i: any) {
    this.dadosFaturamento.splice(i, 1)

    let ped = this.storage.localStorageGet('pedido')
    ped['dadosFaturamento'] = this.dadosFaturamento

    this.atualizarPedido(ped)
  }

  getDadosFaturamento() {

    if (this.pedido?.dadosFaturamento) {
      this.getFaturacao();
      //let fat = this.pedido.dadosFaturamento;
      //this.pedido.dadosFaturamento = JSON.parse(fat)
      //console.log('getDadosFaturamento', this.pedido.dadosFaturamento )
    } else {
      this.setDadosFaturamento()
    }

  }
  setDadosFaturamento() {
    let keyuser = this.storage.localStorageGet('userlogged')?.keyLogin;

    let url = '';

    if (keyuser != null && this.formDadosFaturamento.get('cnpjCpf')?.value != '') {
      if (this.formDadosFaturamento.get('cnpjCpf')?.value?.length == 11 || this.formDadosFaturamento.get('cnpjCpf')?.value?.length == 14)
        url = environment.http.API_BACK + 'Empresarial/DadosFaturamento?origem=CNR&userGuid=' + keyuser + '&documento=' + this.formDadosFaturamento.get('cnpjCpf')?.value;
      else
        return
    } else {
      url = environment.http.API_BACK + 'Empresarial/DadosFaturamentoPorUsuario?origem=CNR&userGuid=' + keyuser;
    }

    this.service.getObservableDirect(url).subscribe({
      next: async (response: any) => {

        if (this.formDadosFaturamento.get('cnpjCpf')?.value != '' && response?.nome)
          this.formDadosFaturamento.setValue(response);

        if (response?.nome) {
          this.pedido.dadosFaturamento = response

        }
        else {
          let cpftemp = this.formDadosFaturamento.get('cnpjCpf')?.value
          if (cpftemp == null) {
            cpftemp = ''
          }
          this.formDadosFaturamento.reset()
          this.formDadosFaturamento.get('cnpjCpf')?.setValue(cpftemp)
        }

      }, error: (err) => {
        //this.carregaUsuario()
        console.error(err);
      }
    });
  }
  getFaturacao() {
    let fat = this.pedido.dadosFaturamento;

    console.log('getFaturacao', fat)

    if ((fat != null) || (fat != '')) {
      if (typeof fat != 'object')
        this.pedido.dadosFaturamento = JSON.parse(fat)
    }
    else {
      this.setDadosFaturamento()
    }

  }
  carregaUsuario() {

    let keyuser = this.storage.localStorageGet('userlogged')?.keyLogin

    let url = environment.http.API_AUTH + `v1/account/usuario/buscarusuario/${keyuser}`

    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {
        if (response != null) {

          let objetoJson = JSON.parse(response)

          delete objetoJson?.password;
          delete objetoJson?.confirmPassword;
          delete objetoJson?.dadosCobranca?.aceiteTermos;


          //console.log(response)

        }
      }, error: (err) => {

        console.error(err);
      }
    });
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  meusPedidos() {

    this.route.navigate(['/admin/meus-pedidos'])
  }

  novoPedido() {

    this.route.navigate(['/'])
  }


  minhasSolicitacoes() {

    this.route.navigate(['/fadmin/meus-agendamentos'])
  }

  atualizarPedido(pedido: any) {

    let tempId = pedido["_id"]
    delete pedido["_id"]
    delete pedido["pagaExigencia"]
    delete pedido["mostraUpload"]

    let url = environment.http.API + 'Venda';

    //console.log('atualizarPedido', url);

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.putObservableDirect(url, pedido).subscribe({
      next: async (response) => {

        //console.log('atualizarPedido-Ret', response)

        let nepd = response
        nepd["_id"] = tempId
        this.pedido = pedido

        if (pedido != null) {

          if (!pedido[0]) {

            this.dadosFaturamento = [];

            this.formDadosFaturamento.setValue(pedido['dadosFaturamento']);
            this.dadosFaturamento.push(pedido['dadosFaturamento'])
          }
        }

      }, error: (err) => {

        //console.error(err);
      }
    });
  }


  atualizarOpcaoPagamento(pedido: any) {
    let tempId = pedido["_id"]
    delete pedido["_id"]
    delete pedido["pagaExigencia"]
    delete pedido["mostraUpload"]


    let url = environment.http.API + 'Venda';

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.putObservableDirect(url, pedido).subscribe({
      next: async (response) => {

        let nepd = response
        nepd["_id"] = tempId
        this.pedido = pedido

      }, error: (err) => {

        //console.error(err);
      }
    });

  }

  atualizarFormaPagamento(dados: any, tipo: any) {
    this.pedido['pagamentoGerado'] = true
    this.pedido['tipoPagamento'] = tipo
    this.pedido['dadosPagamento'] = dados
    this.atualizarOpcaoPagamento(this.pedido)
  }

  GerarBoleto() {


    let url = environment.http.API_BACK + 'Empresarial/GerarBoletoBancario';

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";


    if (this.pedido?.dadosFaturamento?.tipoLogradouro == null) {
      this.pedido.dadosFaturamento.tipoLogradouro = 'ND'
    }


    let request: any = {
      numeroPedido: this.pedido["numeroPedido"],
      dadosCobranca: this.pedido['dadosFaturamento'],
      valor: this.pedido['totalPagar'],
      origemSistema: 'CNR',
      origem:'CNR'
    }

    this.service.postObservableDirect(url, request).subscribe({
      next: async (response: any) => {
        //console.log('GerarBoleto',response)

        this.atualizarFormaPagamento(response, 'boleto')


      }, error: (err) => {

        console.error(err);
      }
    });

  }

  GerarChavePix() {
    this.pedido.tipoPagamento = 'pix';

    if (this.qrCodePix.qrCode == null) {
      let url = environment.http.API_BACK + 'Empresarial/GerarChavePix';
      (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

      if (this.pedido?.dadosFaturamento?.tipoLogradouro == null) {
        this.pedido.dadosFaturamento.tipoLogradouro = 'ND'
      }
      //console.log(this.pedido)
      let request: any = {
        numeroPedido: this.pedido["numeroPedido"],
        dadosCobranca: this.pedido['dadosFaturamento'],
        valor: this.pedido['totalPagar'],
        origemSistema: 'CNR',
        origem: 'CNR'
      }

      this.service.postObservableDirect(url, request).subscribe({
        next: async (response) => {
          //console.log(response)

          //this.atualizarFormaPagamento(response, 'pix')
          this.qrCodePix.qrCode = response.qr_code;
          this.qrCodePix.qrCodeBase64 = response.qr_code_base64;
          this.verificarPagamentoPixEfetivado();

        }, error: (err) => {
          console.error(err);
        }
      });
    }
  }
  verificarPagamentoPixEfetivado() {
    let url = environment.http.API_BACK + 'Empresarial/VerificarPedidoPago/' + this.pedido["numeroPedido"] + '/' + this.verificarOrigemPedido();

    this.service.getObservableDirect(url).subscribe((response) => {
      if (response) {
        this.atualizarFormaPagamento(this.pedido['dadosFaturamento'], 'pix');
      } else if (!this.exitComponent) {
        setTimeout(() => {
          this.verificarPagamentoPixEfetivado();
        }, 5000);
      }
    });
  }
  verificarOrigemPedido(): 'RJ' | 'BR' {
    const comprasRJ = this.pedido.listaCompra.filter((c: any) => c.produto.origem == "RJ");
    return comprasRJ.length > 0 ? 'RJ' : 'BR'
  }


  CarregarDadosCartaoCredito() {

    this.pedido.tipoPagamento = 'credito';

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    let url = `${environment.http.API_BACK}Empresarial/GerarDadosCartaoCredito?numeropedido=${this.pedido["numeroPedido"]}&valorPagar=${this.pedido["totalPagar"]}&origem=CNR`

    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {

        this.mercadoPago.chavePublica = response.publicKey
        this.mercadoPago.parcelas = JSON.parse(response.installments)
        const scriptElement = this.scriptService.loadJsScript(this.renderer, SCRIPT_PATH);
        scriptElement.onload = () => {
          this.iniciaLibMercadoPago();
        }

        this.installmentList = []

        // Percorrer o objeto this.mercadoPago.parcelas e adicionar os valores à lista
        for (let key in this.mercadoPago.parcelas) {
          let number = parseInt(key);
          let amount = this.mercadoPago.parcelas[key];
          this.installmentList.push({ number, amount });
        }

      }, error: (err) => {
        console.error(err);
      }
    });
  }

  PagarComCartao() {

    //this.pedido['dadosPagamento'] = this.dadosCredito.value
    //this.atualizarOpcaoPagamento(this.pedido)
    this.dadosCredito.markAllAsTouched();

    if (this.dadosCredito.valid) {
      this.gerarCardToken();
    } else {
      console.log(this.dadosCredito)
    }
  }

  gerarCardToken() {

    //this.numeroCartaoCreditoAlterado()

    console.log('Ufa, passou daqui')

    // recupera token gerado pelo MP que é necessário para realizar um pagamento.
    const form = this.gerarBaseFormDePagamento();

    //console.log('Ufa, grouo o form')

    //console.log(form)
    // não precisa de header com bearer token, aqui a requisição é para o serviço do MP e não pra nossa API.
    const _window: any = window;
    this.dadosCartaoErrado = false
    _window.Mercadopago.createToken(form, (status: any, response: any) => {

      //console.log('Ufa, criou o token')

      if ((status == 200 || status == 201) && response.luhn_validation) {

        const tokenUtilizavel = response.status == 'active';

        if (tokenUtilizavel) {
          //console.log('Ufa, é token utilizável', response)

          this.cardTokenGerado = response.id;

          this.realizarPagamentoCredito();
        }
        else {
          //console.log('Ufa, não é token utilizável')

          this.tentativasGerarCardToken++;
          if (this.tentativasGerarCardToken < 5) {
            _window.Mercadopago.clearSession();

            this.iniciaLibMercadoPago();
            setTimeout(() => {
              this.gerarCardToken();
            }, 1000);
          }
          else {
            //console.log('Erro: gerando card token', { status: status, response: response })
          }
        }
      }
      else {
        this.dadosCartaoErrado = true
        //console.log('falha gerando card token', { status: status, response: response })
      }
    });
  }

  gerarBaseFormDePagamento(): HTMLFormElement {
    // Esse modelo de form é utilizado primeiro para recuperar um token no MP.
    // Depois uma nova instancia desse mesmo form recebe mais um campo contendo o
    // token e é submetida para a API do protesto para realizar o pagamento.

    const form: HTMLFormElement = document.createElement('form');
    form.method = 'POST';

    const inputNomeTitular: HTMLInputElement = document.createElement('input');
    inputNomeTitular.setAttribute('data-checkout', 'cardholderName');
    inputNomeTitular.setAttribute('value', this.dadosCredito.get('nomeTitular')?.value || ``);
    form.appendChild(inputNomeTitular);

    const inputTipoDocumento: HTMLInputElement = document.createElement('input');
    inputTipoDocumento.setAttribute('data-checkout', 'docType');
    inputTipoDocumento.setAttribute('value', this.dadosCredito.get('tipoDocumento')?.value || ``);
    form.appendChild(inputTipoDocumento);

    const inputNumeroDocumento: HTMLInputElement = document.createElement('input');
    inputNumeroDocumento.setAttribute('data-checkout', 'docNumber');
    inputNumeroDocumento.setAttribute('value', this.dadosCredito.get('numeroDocumento')?.value || ``);
    form.appendChild(inputNumeroDocumento);

    const inputValidadeMes: HTMLInputElement = document.createElement('input');
    inputValidadeMes.setAttribute('data-checkout', 'cardExpirationMonth');
    inputValidadeMes.setAttribute('value', this.dadosCredito.get('validadeMes')?.value || ``);
    form.appendChild(inputValidadeMes);

    const inputValidadeAno: HTMLInputElement = document.createElement('input');
    inputValidadeAno.setAttribute('data-checkout', 'cardExpirationYear');
    inputValidadeAno.setAttribute('value', this.dadosCredito.get('validadeAno')?.value || ``);
    form.appendChild(inputValidadeAno);

    const inputNumeroCartao: HTMLInputElement = document.createElement('input');
    inputNumeroCartao.setAttribute('data-checkout', 'cardNumber');
    inputNumeroCartao.setAttribute('value', this.dadosCredito.get('numeroCartao')?.value || ``);
    form.appendChild(inputNumeroCartao);

    const inputCVC: HTMLInputElement = document.createElement('input');
    inputCVC.setAttribute('data-checkout', 'securityCode');
    inputCVC.setAttribute('value', this.dadosCredito.get('cvc')?.value || ``);
    form.appendChild(inputCVC);

    const inputParcelas: HTMLInputElement = document.createElement('input');
    inputParcelas.setAttribute('data-checkout', 'installments');
    inputParcelas.setAttribute('value', this.dadosCredito.get('qtdParcelas')?.value || ``);
    form.appendChild(inputParcelas);

    const inputIssuer: HTMLInputElement = document.createElement('input');
    inputIssuer.setAttribute('name', 'issuer');
    inputIssuer.setAttribute('data-checkout', 'issuer');
    inputIssuer.setAttribute('value', this.dadosCredito.get('idEmissor')?.value || ``);
    form.appendChild(inputIssuer);

    return form;
  }

  realizarPagamentoCredito() {

    let url = environment.http.API_BACK + `Empresarial/PagarComCartaoCredito`;

    if (this.pedido?.dadosFaturamento?.tipoLogradouro == null) {
      this.pedido.dadosFaturamento.tipoLogradouro = 'ND'
    }

    let dados: any = {
      numeroPedido: this.pedido.numeroPedido,
      token: this.cardTokenGerado,
      qtdeParcelas: this.dadosCredito.get('qtdParcelas')?.value,
      paymentMethodId: this.detalhesCartaoCredito.id, // 'visa',
      nome: this.dadosCredito.get('nomeTitular')?.value,
      dadosCobranca: this.pedido['dadosFaturamento'],
      valor: this.pedido['totalPagar'],
      origemSistema: 'CNR',
      origem: 'CNR'
    };

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";
    this.pagamentoRecusado = false
    this.service.postObservableDirect(url, dados).subscribe({
      next: async (response) => {
        if (response?.status == 'Aprovado') {
          this.atualizarFormaPagamento(response, 'credito')
        } else {
          this.pagamentoRecusado = true
        }
      }, error: (err) => {
        this.pagamentoRecusado = true
        console.error(err);
      }
    });
  }

  private iniciaLibMercadoPago() {
    if (this.mercadoPago.chavePublica) {
      const chavePublicaMP = this.mercadoPago.chavePublica;
      (window as any).Mercadopago.setPublishableKey(chavePublicaMP);
      (window as any).Mercadopago.getIdentificationTypes();
    }

  }

  public numeroCartaoCreditoAlterado() {
    //console.log('numeroCartaoCreditoAlterado...', this.dadosCredito?.value)

    //this.dadosCredito.get('qtdParcelas')?.setValue('-1');
    // Recupera no MP alguns dados através do número do cartão de crédito digitado
    this.detalhesCartaoCredito = null;
    const numeroCartao = '' + this.dadosCredito.get('numeroCartao')?.value;
    //console.log('numeroCartao', numeroCartao)

    if (numeroCartao.length >= 6) {
      let _window: any = window;
      const bin = numeroCartao.substring(0, 6);


      // Recupera dados do tipo de pagamento deste cartão
      //console.log('Recupera dados do tipo de pagamento deste cartão')
      _window.Mercadopago.getPaymentMethod({ "bin": bin }, (status: any, response: any) => {
        this.detalhesCartaoCreditoRecebidos(status, response, this)
      });
    }
  }

  private detalhesCartaoCreditoRecebidos(status: any, response: any, _self: PaymentComponent) {

    //console.log('detalhesCartaoCreditoRecebidos - status', status)
    //console.log('detalhesCartaoCreditoRecebidos - response',response)

    if (status == 200) {
      const detalhesEncontrados = (!response) || (!!(response as Array<any>)) || response.length <= 0;
      if (!detalhesEncontrados) {
        const msg = 'Falha recuperando detalhes do cartão. Nenhuma informação retornada.';
        //console.log(msg);
        return;
      }
      const dadosTipoPagDesteCartao = response[0];
      //console.log('dados do tipo de pagamento deste cartão', dadosTipoPagDesteCartao);
      if (dadosTipoPagDesteCartao) {
        const idTipoPagamento = dadosTipoPagDesteCartao.id; // exemplo de resultado para esse id: 'master'
        _self.detalhesCartaoCredito = dadosTipoPagDesteCartao;

        // Recupera issuers do cartão
        const _window: any = window;
        _window.Mercadopago.getIssuers(idTipoPagamento, (status: any, response: any) => { _self.emissoresCartaoCreditoRecebidos(status, response, _self) });

        //console.log('idTipoPagamento',idTipoPagamento)
      }
    }
    else {
      const msg = 'Falha recuperando detalhes do cartão.';
      //console.log(msg);
      //console.log('Erro recuperando detalhes cartão de crédito:', response);
      _self.detalhesCartaoCredito = null;
    }
  }

  private emissoresCartaoCreditoRecebidos(status: any, response: any, _self: PaymentComponent) {

    //console.log('emissoresCartaoCreditoRecebidos', status)
    if (status == 200) {
      //console.log('emissores recebidos:', response);
      // selecionando como default sempre o primeiro emissor da lista que chega do MP.
      const idEmissor = response[0].id;
      //----------------------------this.dadosCredito.idEmissor = idEmissor;
      //console.log('emissor selecionado para o cartão: ', response[0]);
      // Recupera opções de parcelamento disponíveis para este cartão no emissor selecionado.
      const _window: any = window;
      _window.Mercadopago.getInstallments({
        "payment_method_id": _self.detalhesCartaoCredito.id,
        "amount": parseFloat(this.pedido.totalPagar),
        "issuer_id": parseInt(idEmissor)
      }, (status: any, response: any) => { _self.opcoesParcelasCreditoRecebidas(status, response, _self) });
    }
    else {
      //console.log('Erro recuperando emissores cartão de crédito:', response);
      _self.detalhesCartaoCredito = null;
      //-------------------------_self.dadosCredito.opcoesParcelamento = [];
    }
  }

  private opcoesParcelasCreditoRecebidas(status: any, response: any, _self: PaymentComponent) {
    if (status == 200) {
      //console.log('parcelamentos disponíveis: ', response);
      // popula a lista de opções de parcelamento disponíveis.
      //------------------------- _self.dadosCredito.opcoesParcelamento = [];
      const valorTotal = this.dadosPagamento.ValorPagar;

      for (let i = 0; i < response[0].payer_costs.length; i++) {

        //quando existirem opções de parcelamento acima de 1x, não vamos exibir a opção de 1x. ou seja, 1x só aparece caso seja a
        //  única opção de parcelamento que o MP informou para este cartão e este valor de compra.
        if (response[0].payer_costs.length > 1 && response[0].payer_costs[i].installments == 1)
          continue;

        // não vamos mais usar o valor retornado pelo MP para as parcelas, vamos utilizar uma tabela própria fixa
        // label = response[0].payer_costs[i].recommended_message,
        //---------------------------------_self.dadosCredito.opcoesParcelamento.push({
        //---------------------------------  'descricao': this.labelValorTaxaCredito(valorTotal, response[0].payer_costs[i].installments),
        //---------------------------------  'id': response[0].payer_costs[i].installments
        //---------------------------------});
      }
    }
    else {
      //console.log('Erro recuperando parcelamentos disponíveis:', response);
      this.detalhesCartaoCredito = null;
      //-------------------------- _self.dadosCredito.opcoesParcelamento = [];
    }
  }

  private labelValorTaxaCredito(valorTotal: number, qtdParcelas: number): string {
    const valorPorParcela = (this.mercadoPago.parcelas[qtdParcelas] / qtdParcelas);
    // TODO: traduzir
    const labelParcela = qtdParcelas == 1 ? 'parcela' : 'parcelas';
    const labelJuros = qtdParcelas == 1 ? '(sem juros)' : `(com juros R$${this.mercadoPago.parcelas[qtdParcelas].toFixed(2)})`;
    const valorPorParcelaFormatado = Number((valorPorParcela).toFixed(2)).toLocaleString();
    return `${qtdParcelas} ${labelParcela} de R$${valorPorParcelaFormatado} ${labelJuros}`;
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // //console.log(event.key, event.ctrlKey, event.shiftKey, environment.production)
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {

      this.teste.setValueForm(this.dadosCredito);

    }

  }

}
