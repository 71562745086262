import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CrudService } from 'src/app/_sevice/CrudService';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ajuda',
  templateUrl: './ajuda.component.html',
  styleUrls: ['./ajuda.component.scss'],
})
export class AjudaComponent implements OnInit {
  @Input() conteudo: any;
  
  formBusca = new FormGroup({
    Nome: new FormControl('', Validators.required),
    Email: new FormControl('', 
    Validators.compose([
      Validators.required,
      Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")
    ])),
    Telefone: new FormControl('', Validators.required),
    Assunto: new FormControl('', Validators.required),
    Mensagem: new FormControl('', Validators.required),
  });
  concluido: boolean = false

  get f() {
    return this.formBusca.controls;
  }
  constructor(
    public service: CrudService
  ) {

  }
  get nome() {
    return this.formBusca.controls.Nome.value;
  }
  get Email() {
    return this.formBusca.controls.Email.value;
  }
  get Telefone() {
    return this.formBusca.controls.Telefone.value;
  }
  get Assunto() {
    return this.formBusca.controls.Assunto.value;
  }
  get Mensagem() {
    return this.formBusca.controls.Mensagem.value;
  }

  ngOnInit(): void {

  }
  voltar() {
    this.concluido = false
    this.formBusca.reset()
  }
  novaSolicitacao() {

    this.concluido = false

  }
  consultar() {
     
    if (this.formBusca.valid) {
      let form = {
        nome: this.nome,
        email: this.Email,
        telefone: this.Telefone,
        pedido: '',
        assunto: this.Assunto,
        mensagem: this.Mensagem
      };
    
      const url = environment.http.API +'Backoffice/EnviarFormularioDeAjuda';
      this.service.postObservableDirect(url,form).subscribe(
        resposta => {
          
            this.concluido = true
          
        })
    }
  }

}
