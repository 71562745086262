<div class="wrapper-item" *ngIf="!compra?.editar">
  <div class="wrapper-info col-lg-12">
    <h3 class="info-section"><b>Cartório</b></h3>
    <p class="info-section">{{ compra?.cartorio?.cartorio }}</p>
  </div>

  <div class="row">
    <div class="wrapper-info col-lg-4">
      <h3 class="info-section"><b>Estado</b></h3>
      <p class="info-section">{{ compra?.localidade?.uf?.Sigla }}</p>
    </div>

    <div class="wrapper-info col-lg-8">
      <h3 class="info-section"><b>Município</b></h3>
      <p class="info-section">{{ compra?.localidade?.cidade?.Nome }}</p>
    </div>

  </div>

  <div *ngIf="compra.produto.subFormTipo === 'Casamento'">
    <div class="row" *ngFor="let item2 of compra.certidaoCasamento">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Cônjuge</b></h3>
        <p class="info-section">{{ item2.conjuge }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Cônjuge2</b></h3>
        <p class="info-section">{{ item2.conjuge2 }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>CPF</b></h3>
        <p class="info-section">{{ item2.cpf | mask:'000.000.000-00||00.000.000/0000-00' }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>CPF2</b></h3>
        <p class="info-section">{{ item2.cpf2 | mask:'000.000.000-00||00.000.000/0000-00' }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Data de Casamento</b></h3>
        <p class="info-section">{{ item2.dataCasamento | date:'dd/MM/yyyy':'UTC' }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Livro</b></h3>
        <p class="info-section">{{ item2.livro }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Folha</b></h3>
        <p class="info-section">{{ item2.folha }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Matrícula</b></h3>
        <p class="info-section">{{ item2.matricula }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Termo</b></h3>
        <p class="info-section">{{ item2.termo }}</p>
      </div>
    </div>
  </div>
  <div *ngIf="compra.produto.subFormTipo === 'Nascimento'">
    <div class="row" *ngFor="let item2 of compra.certidaoNascimento">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Nome Completo</b></h3>
        <p class="info-section">{{ item2.nomeCompleto }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>CPF</b></h3>
        <p class="info-section">{{ item2.cpf | mask:'000.000.000-00||00.000.000/0000-00' }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Data de Nascimento</b></h3>
        <p class="info-section">{{ item2.nascimento | date:'dd/MM/yyyy':'UTC' }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Nome da Mãe</b></h3>
        <p class="info-section">{{ item2.mae }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Livro</b></h3>
        <p class="info-section">{{ item2.livro }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Folha</b></h3>
        <p class="info-section">{{ item2.folha }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Matrícula</b></h3>
        <p class="info-section">{{ item2.matricula }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Termo</b></h3>
        <p class="info-section">{{ item2.termo }}</p>
      </div>
    </div>
  </div>
  <div *ngIf="compra.produto.subFormTipo === 'Óbito'">
    <div class="row" *ngFor="let item2 of compra.certidaoObito">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Nome</b></h3>
        <p class="info-section">{{ item2.nome }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>CPF</b></h3>
        <p class="info-section">{{ item2.cpf | mask:'000.000.000-00||00.000.000/0000-00' }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Data do Óbito</b></h3>
        <p class="info-section">{{ item2.dataObito | date:'dd/MM/yyyy':'UTC' }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Nome do Pai</b></h3>
        <p class="info-section">{{ item2.pai }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Nome da Mãe</b></h3>
        <p class="info-section">{{ item2.mae }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Livro</b></h3>
        <p class="info-section">{{ item2.livro }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Folha</b></h3>
        <p class="info-section">{{ item2.folha }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Matrícula</b></h3>
        <p class="info-section">{{ item2.matricula }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Termo</b></h3>
        <p class="info-section">{{ item2.termo }}</p>
      </div>
    </div>
  </div>

  <!--DADOS ENTREGA-->
  <div class="row">
    <div class="wrapper-info col-lg-12">
      <h3 class="info-section text-center"><b>Dados para Entrega</b></h3>
    </div>

  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="wrapper-info col-lg-12">
        <h3 class="info-section"><b>Nome Destinatário</b></h3>
        <p class="info-section">{{ compra.dadosEntrega?.nomeDestinatario }}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Logradouro</b></h3>
        <p class="info-section">{{ compra.dadosEntrega?.endereco }}</p>
      </div>
    </div>
    <div class="col-md-3">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Número</b></h3>
        <p class="info-section">{{compra.dadosEntrega?.numero}}</p>
      </div>
    </div>
    <div class="col-md-2">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Complemento</b></h3>
        <p class="info-section">{{compra.dadosEntrega?.complemento}}</p>
      </div>
    </div>

    <div class="col-md-3">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Bairro</b></h3>
        <p class="info-section">{{ compra.dadosEntrega?.bairro }}</p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Cidade</b></h3>
        <p class="info-section">{{ compra.dadosEntrega?.cidade.Nome }}</p>
      </div>
    </div>
    <div class="col-md-3">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Estado</b></h3>
        <p class="info-section">{{ compra.dadosEntrega?.uf?.Sigla }}</p>
      </div>
    </div>
    <div class="col-md-3">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>CEP</b></h3>
        <p class="info-section">{{ compra.dadosEntrega?.cep }}</p>
      </div>
    </div>

    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>CERP</b></h3>
      <p class="info-section">{{compra?.idItem}}</p>
    </div>
  </div>

</div>

<app-form-7 *ngIf="compra?.editar" [itemEdicao]="compra" [uf]="compra?.localidade?.uf" [cidade]="compra?.localidade?.cidade" [edicao]="true" (salvarItem)="salvarItem($event)" (cancelarEdicao)="cancelarEdicao(compra)"
            [itemAtual]="compra.produto"></app-form-7>
