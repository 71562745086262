import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Chart, registerables } from 'chart.js'; 
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
Chart.register(...registerables);

@Component({
  selector: 'app-grafico-projeto',
  templateUrl: './grafico.component.html',
  styleUrls: ['./grafico.component.less']
})


export class GraficoProjetoComponent implements OnInit {
 
  options: Array<any> = new Array()
  selectedSistema: any = { IdBase: 0, DescBase: "Escolha" }
  myControl = new FormControl(this.selectedSistema);

  constructor(
    public storage: LocalStorage
    , public service: CrudService) {
  }


  ngOnInit() {
    this.montarGraficoVenda()


  }
 

  getOptionText(option: any) {
    return option.DescBase;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedSistema = this.options.filter(x => x.IdBase == event.option.value.IdBase)[0]
    this.montarGraficoVenda()
  }

  litarBase() {
    this.service.getObservable('/base').subscribe({
      next: (retorno) => {
        this.options = retorno
        this.montarGraficoVenda()
      }
    })
  }
  retorno: any = [
    {
      "coluna": "09/06",
      "valor": 1
    },
    {
      "coluna": "14/06",
      "valor": 1
    },
    {
      "coluna": "19/06",
      "valor": 2
    },
    {
      "coluna": "20/06",
      "valor": 1
    },
    {
      "coluna": "28/06",
      "valor": 1
    },
    {
      "coluna": "05/07",
      "valor": 1
    },
    {
      "coluna": "21/07",
      "valor": 1
    },
    {
      "coluna": "25/07",
      "valor": 1
    },
    {
      "coluna": "31/07",
      "valor": 1
    }
  ]

  data:any = {
    labels: [
      'Red',
      'Blue',
      'Yellow'
    ],
    datasets: [{
      label: 'My First Dataset',
      data: [300, 50, 100],
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)'
      ],
      hoverOffset: 4
    }]
  };

  montarGraficoVenda() {
    var vlrs: any = [];
    var lbls: any = [];
    var total: any = 0
    

        for (var i = 0; i < this.retorno.length; i++) {
          vlrs.push(this.retorno[i].valor)
          lbls.push(this.retorno[i].coluna)
          total += this.retorno[i].valor
        }

        var chartExist = Chart.getChart("myChart"); // <canvas> id
        if (chartExist != undefined)
          chartExist.destroy();


        var myChart = new Chart("myChart", {
          type: 'line',
          data: {
            labels: lbls,
            datasets: [{
              label: 'Vendas (Total:' + total + ')',
              data: vlrs,
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)'
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)'
              ],
              borderWidth: 1
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });

    var chartExist = Chart.getChart("myChart2"); // <canvas> id
    if (chartExist != undefined)
      chartExist.destroy();


    var myChart2 = new Chart("myChart2", {
      type: 'pie',
      data: this.data
      //,
      //options: {
      //  scales: {
      //    weight: {
      //      beginAtZero: true
      //    }
      //  }
      //}
    });

       
   
  }

}
