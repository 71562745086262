import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router'; 
import { LocalStorage } from '../../_entidade/LocalStorage';
import { CrudService } from '../../_sevice/CrudService';
import { GlobalService } from '../../_sevice/GlobalService';
import { DateAdapter } from '@angular/material/core';
import { FuncaoGlobal } from '../../_sevice/FuncaoGlobal';

@Component({
  selector: 'app-meus-relatorios',
  templateUrl: './meus-relatorios.component.html',
  styleUrls: ['./meus-relatorios.component.less']
})
export class MeusRelatoriosComponent implements OnInit {

  listRelatorio: Array<any> = new Array()
  selectedSistema: any = { IdBase: 2, DescBase: "eNegocie" }
  myControl = new FormControl(this.selectedSistema);
  options: Array<any> = new Array() 
  constructor(
    public router: Router
    , public storage: LocalStorage
    , public service: CrudService
    , public gb: GlobalService
    , public fg: FuncaoGlobal
    , private dateAdapter: DateAdapter<Date>,
  )
  {
    this.dateAdapter.setLocale('pt');
  }

  temData(relatorio: any, dtFinal: any) {
    if (dtFinal) {
      if (relatorio.Parametros != null && relatorio.Parametros.indexOf('DataFinal') >= 0)
        return true
      else
        return false
    } else {
      if (relatorio.Parametros != null && relatorio.Parametros.indexOf('DataInicial') >= 0)
        return true
      else
        return false
    }
    

  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedSistema = this.options.filter(x => x.IdBase == event.option.value.IdBase)[0]
    this.litarRelatorios()
  }

  ngOnInit() {
    this.litarBase()
    
  }

  download(idRelatorio: number, relatorio: any) {
    this.gb.postObservableBLOB('User/GetFile/', idRelatorio, relatorio, relatorio.Nome)
  }
  getOptionText(option:any) {
    return option.DescBase;
  }

  litarBase() {
    this.service.getObservable('/base').subscribe({
      next: (retorno) => {
        this.options = retorno

        this.litarRelatorios()
      } 
    })
  }

  onDateChange(event: any, relatorio: any, campo: any, inicial: boolean=true) {
    relatorio[campo] = this.fg.formataDataDB(event, inicial)
  }

  litarRelatorios() {
    this.service.getObservable('/relatorio/?id=' + this.selectedSistema.IdBase).subscribe({
      next: (retorno) => {
        this.listRelatorio = retorno 
      } 
    })
  }

}
