import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-bar-busca',
  templateUrl: './nav-bar-busca.component.html',
  styleUrls: ['./nav-bar-busca.component.less']
})
export class NavBarBuscaComponent implements OnInit {

  @Input() iten: any;

  constructor() {}

  ngOnInit(): void {
    
   
  }

  openModalBusca() {
    //console.log('dfdf');
    (<HTMLInputElement>document.getElementById("myNavBusca")).style.width = "100%";

  }

  closeModal(modal: any) {
    (<HTMLInputElement>document.getElementById("myNavBusca")).style.width = "0%";
  }

}
