
<div class="wrapper-all-lastStep" *ngIf="!device.isMobile() && !pedido?.pagamentoGerado && !pedido?.pedidoGratuito ">
  <div class="wrapper-columns">
    <ng-container>
      <div class="column-left">
        <div class="wrapper-content-top">
          <h3 class="title-block">Selecione a forma de pagamento</h3>
          <div class="wrapper-payment-method-list">
            <div class="payment-option">
              <div class="wrapper-content-info-option">
                <mat-accordion class="d-block col-md-12">
                  <mat-expansion-panel [expanded]="panelOpenState">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <div class="content-img-text">
                          <img loading="lazy"
                               src="https://cdn.builder.io/api/v1/image/assets/TEMP/cac6e8d3f6f3c675c0840ed14fa664bcf040d18989f2b7b7dcdcec14a79569b5?apiKey=112bb9cfb8df486f9471fee20124622a&"
                               class="img" />
                          <p class="text-block">Boleto Bancário</p>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="prot-opcao-pg">
                      <p>
                        Total a pagar {{totalPagar |currency :'BLR'}}
                      </p>
                      <p>
                        Imprima o boleto e pague no banco. Ou pague pela internet utilizando o código de barras do boleto.
                      </p>
                      <p>
                        O boleto pode ser pago em 5 dias.
                      </p>
                      <br><br>
                      <div>
                        <button class="btn-pgtos" (click)="GerarBoleto()">
                          Gerar Boleto
                        </button>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
            <div class="payment-option" (click)="CarregarDadosCartaoCredito()">
              <div class="wrapper-content-info-option">
                <mat-accordion class="d-block col-md-12">
                  <mat-expansion-panel [expanded]="panelOpenState">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <div class="content-img-text">
                          <img loading="lazy"
                               src="https://cdn.builder.io/api/v1/image/assets/TEMP/c5e96283f0faab7fcd694d656ee5fdc6e945e2344940cb4ffe3548672ad66066?apiKey=112bb9cfb8df486f9471fee20124622a&"
                               class="img" />
                          <p class="text-block">Cartão de Crédito</p>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="prot-pgto-credito">
                      <form [formGroup]="dadosCredito">
                        <div>
                          <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                            <mat-label>Titular do Cartão</mat-label>
                            <input matInput type="text" class="col-lg-6" formControlName="nomeTitular"
                                   placeholder="Titular do Cartão">
                            <mat-error *ngIf="creditCard.nomeTitular.errors?.['required']">
                              Preenchimento obrigatório
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div>
                          <div class="content-documento">
                            <mat-form-field>
                              <mat-label for="tipoDocumento">Documento</mat-label>
                              <mat-select formControlName="tipoDocumento" name="tipoDocumento">
                                <mat-option value="CPF">CPF</mat-option>
                                <mat-option value="CNPJ">CNPJ</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div>
                            <ng-container *ngIf="creditCard.tipoDocumento.value === 'CPF'">
                              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                                <mat-label>Número do documento</mat-label>
                                <input matInput type="text"
                                       mask="000.000.000-00"
                                       class="col-lg-12" formControlName="numeroDocumento"
                                       placeholder="Número do documento">
                                <mat-error *ngIf="creditCard.numeroDocumento.hasError('required')">
                                  Preenchimento obrigatório
                                </mat-error>
                                <mat-error *ngIf="creditCard.numeroDocumento.hasError('pattern')">
                                  Preenchimento incorreto
                                </mat-error>
                                <mat-error *ngIf="creditCard.numeroDocumento.hasError('invalidCPF')">
                                  Documento inválido
                                </mat-error>
                              </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="creditCard.tipoDocumento.value === 'CNPJ'">
                              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                                <mat-label>Número do documento</mat-label>
                                <input matInput type="text"
                                       mask="00.000.000/0000-00"
                                       class="col-lg-12" formControlName="numeroDocumento"
                                       placeholder="Número do documento">
                                <mat-error *ngIf="creditCard.numeroDocumento.hasError('required')">
                                  Preenchimento obrigatório
                                </mat-error>
                                <mat-error *ngIf="creditCard.numeroDocumento.hasError('pattern')">
                                  Preenchimento incorreto
                                </mat-error>
                                <mat-error *ngIf="creditCard.numeroDocumento.hasError('invalidCNPJ')">
                                  Documento inválido
                                </mat-error>
                              </mat-form-field>
                            </ng-container>

                          </div>
                        </div>

                        <div>
                          <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                            <mat-label>Número do Cartão</mat-label>
                            <input matInput type="text" class="col-lg-6" formControlName="numeroCartao" (blur)="numeroCartaoCreditoAlterado()"
                                   placeholder="Número do Cartão" [maxlength]="16" (keypress)="service.apenasNumeros($event)">
                            <mat-error *ngIf="creditCard.numeroCartao.hasError('required')">
                              Preenchimento obrigatório
                            </mat-error>
                            <mat-error *ngIf="creditCard.numeroCartao.hasError('pattern')">
                              O número do cartão deve conter apenas números
                            </mat-error>
                            <mat-error *ngIf="creditCard.numeroCartao.hasError('maxlength')">
                              O número do cartão deve ter no máximo 16 dígitos
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div>
                          <div>
                            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                              <mat-label>CVC</mat-label>
                              <input matInput type="text" class="col-lg-6" formControlName="cvc"
                                     placeholder="CVC" [maxlength]="4" (keypress)="service.apenasNumeros($event)">
                              <mat-error *ngIf="creditCard.cvc.errors?.['required']">
                                Preenchimento obrigatório
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="content-validade-cartao">
                            <label>Data de vencimento</label>
                            <div class="col-md-12 col-xs-12">
                              <div class="wrapper-content">
                                <mat-form-field class="col-md-7">
                                  <mat-label for="ValidMes">Selecione o Mês</mat-label>
                                  <mat-select formControlName="validadeMes" name="ValidMes">
                                    <mat-option value="01">01</mat-option>
                                    <mat-option value="02">02</mat-option>
                                    <mat-option value="03">03</mat-option>
                                    <mat-option value="04">04</mat-option>
                                    <mat-option value="05">05</mat-option>
                                    <mat-option value="06">06</mat-option>
                                    <mat-option value="07">07</mat-option>
                                    <mat-option value="08">08</mat-option>
                                    <mat-option value="09">09</mat-option>
                                    <mat-option value="10">10</mat-option>
                                    <mat-option value="11">11</mat-option>
                                    <mat-option value="12">12</mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="col-md-4 my-2 prot-tab-input-conteudo">
                                  <mat-label>Ano</mat-label>
                                  <input matInput type="text" formControlName="validadeAno" placeholder="Ano" [maxlength]="4">
                                  <mat-error *ngIf="creditCard.cvc.errors?.['required']">
                                    Preenchimento obrigatório
                                  </mat-error>
                                </mat-form-field>

                              </div>

                            </div>
                          </div>
                        </div>
                        <div>
                          <mat-form-field class="col-md-12">
                            <mat-label for="ValidMes">Parcelas</mat-label>
                            <mat-select formControlName="qtdParcelas" name="qtdParcelas">
                              <ng-container *ngFor="let item of installmentList">
                                <mat-option *ngIf="item.number === 1" [value]="item.number">{{item.number}} à vista {{item.amount | currency:'BRL'}}</mat-option>
                                <mat-option *ngIf="item.number > 1" [value]="item.number">{{item.number}} parcelas de {{item.amount / item.number | currency:'BRL'}} (Total de {{item.amount | currency:'BRL'}})</mat-option>
                              </ng-container>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <span *ngIf="dadosCartaoErrado" style="color: red;font-size: 20px;text-align: center;display: flex;flex-direction: column;">Dados do cartão incorretos</span>
                        <span *ngIf="pagamentoRecusado" style="color: red;font-size: 20px;text-align: center;display: flex;flex-direction: column;">Pagamento recusado pela operadora</span>
                        <button class="btn-pgtos" type="button" (click)="PagarComCartao()">
                          Pagar
                        </button>

                      </form>

                    </div>
                  </mat-expansion-panel>
                </mat-accordion>

              </div>
            </div>

            <div class="payment-option" (click)="GerarChavePix()">
              <div class="wrapper-content-info-option">
                <mat-accordion class="d-block col-md-12">
                  <mat-expansion-panel [expanded]="panelOpenState">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <div class="content-img-text">
                          <img loading="lazy"
                               src="https://cdn.builder.io/api/v1/image/assets/TEMP/fd850d6c6965cf03183cc3cadf8f057bff5eb380e3f060ce2b454a4d0f43f2dd?apiKey=112bb9cfb8df486f9471fee20124622a&"
                               class="img" />
                          <p class="text-block">Pix</p>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="prot-pgto-pix">

                      <span class="text-center">QR Code para pagamento</span><br />

                      <div id="chave-pix" style="width: 100%;">
                        <img class="img-fluid" [src]="'data:image/jpeg;base64,' + qrCodePix.qrCodeBase64" style="width: 100%" />
                      </div>

                      <span class="text-center">
                        Ou Clique no botão abaixo para utilizar o pix na função "Copia e Cola"
                      </span>

                      <span class="text-center">
                        O Código poderá ser colado no aplicativo do seu banco.
                      </span>
                      <br />
                      <br />

                      {{pedido?.dadosPagamento?.qr_code}}

                      <button class="btn-pgtos mt-4 mb-4 btn btn-primary btn-sm w-100 " (click)="copyMessage(qrCodePix.qrCode)">Copiar código</button>
                      <p>
                        Lembre-se de que, por enquanto, o Banco Central do Brasil funciona de segunda a sexta-feira das 9h às 18hs e, caso se solicite um cadastro fora desse horário, confirmaremos no próximo dia útil. Além disso, existe um limite para o valor de Pix que poderá ser movimentado no decorrer do período noturno (entre 20h e 6h) de R$1 mil. Para o período diurno (entre 6h e 20h), contudo, não há limite de movimentação.
                      </p>

                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
        </div>
        <!--Trabalhar aqui Enio-->
        <div class="wrapper-content-bottom">
          <h3 class="title-block">Dados de Faturamento</h3>
          <div class="content-info-user" *ngIf="!HabilitaForm">

            <ng-container>
              <div class="content-info-user">
                <span class="title-info-user">Nome</span>
                <p class="text-info-user"> {{pedido?.dadosFaturamento?.nome}} </p>
                <span class="title-info-user">CPF/CNPJ</span>
                <p class="text-info-user"> {{ pedido?.dadosFaturamento?.cnpjCpf }} </p>
                <span class="title-info-user">Endereço</span>
                <p class="text-info-user">
                  {{pedido?.dadosFaturamento?.tipoLogradouro}} {{pedido?.dadosFaturamento?.logradouro}}, {{pedido?.dadosFaturamento?.numero}}-{{pedido?.dadosFaturamento?.bairro}}
                  <br />
                  {{pedido?.dadosFaturamento?.cidade?.Nome}}-{{pedido?.dadosFaturamento?.uf?.Sigla}}
                  <br />
                  {{pedido?.dadosFaturamento?.cep}}
                </p>
              </div>
            </ng-container>
          </div>
          <!--Novo Form-->
          <!-- Form para adicionar os Dados de Faturamento-->
          <br />
          <form [formGroup]="formDadosFaturamento" *ngIf="HabilitaForm">
            <div class="content-list" width="100px">
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>
                      CPF/CNPJ
                    </mat-label>
                    <input matInput type="text" (input)="getFaturacao()" formControlName="cnpjCpf" mask="000.000.000-00||00.000.000/0000-00">
                    <mat-error>
                      Preenchimento obrigatório
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <!--Nome-->
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Nome</mat-label>
                    <input matInput type="text" class="col-lg-12" formControlName="nome"
                           placeholder="Nome">
                    <mat-error>
                      Preenchimento obrigatório
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>CEP</mat-label>
                    <input matInput
                           type="text"
                           class="col-lg-12"
                           mask="00000-000"
                           formControlName="cep"
                           placeholder="CEP"
                           (blur)="buscarEndereco($event)">
                    <mat-error>
                      Preenchimento obrigatório
                    </mat-error>
                    <mat-error *ngIf="formDadosFaturamento.hasError('cepNotMatch')">
                      CEP não encontrado.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field class="w-100">
                    <mat-label>Estado</mat-label>
                    <input type="text"
                           placeholder="Estado"
                           aria-label="Number"
                           matInput
                           formControlName="uf"
                           [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProperty" (optionSelected)="getCidade($event)">
                      <mat-option *ngFor="let item of listufFilter" [value]="item">
                        {{item.Nome }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="w-100">
                    <mat-label>Cidade</mat-label>
                    <input type="text"
                           #cidade
                           id="Cidade"
                           placeholder="Cidade"
                           aria-label="Number"
                           matInput
                           formControlName="cidade"
                           [matAutocomplete]="auto2">
                    <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayPropertyCidade" (optionSelected)="setLocal()">
                      <mat-option *ngFor="let item of listCidadeFilter" [value]="item">
                        {{item.Nome }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>


              </div>
              <!--Dados do Endereço-->
              <div class="row">
                <div class="col-md-3">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Tipo de logradouro</mat-label>
                    <mat-select formControlName="tipoLogradouro">
                      <mat-option value="ALAMEDA">ALAMEDA</mat-option>
                      <mat-option value="AVENIDA">AVENIDA</mat-option>
                      <mat-option value="BECO">BECO</mat-option>
                      <mat-option value="CAMPO">CAMPO</mat-option>
                      <mat-option value="ESTRADA">ESTRADA</mat-option>
                      <mat-option value="ILHA">ILHA</mat-option>
                      <mat-option value="LADEIRA">LADEIRA</mat-option>
                      <mat-option value="LARGO">LARGO</mat-option>
                      <mat-option value="PRAÇA">PRAÇA</mat-option>
                      <mat-option value="RUA">RUA</mat-option>
                      <mat-option value="SERVIDAO">SERVIDAO</mat-option>
                      <mat-option value="TRAVESSA">TRAVESSA</mat-option>
                      <mat-option value="VIA">VIA</mat-option>
                      <mat-option value="VILA">VILA</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-9">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Logradouro</mat-label>
                    <input matInput type="text"
                           formControlName="logradouro"
                           placeholder="Logradouro">
                    <mat-error>
                      Preenchimento obrigatório
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Número</mat-label>
                    <input matInput type="text"
                           formControlName="numero"
                           placeholder="Número">
                    <mat-error>
                      Preenchimento obrigatório
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Bairro</mat-label>
                    <input matInput type="text"
                           formControlName="bairro"
                           placeholder="Bairro">
                    <mat-error>
                      Preenchimento obrigatório
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-5">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Complemento</mat-label>
                    <input matInput type="text"
                           formControlName="complemento"
                           placeholder="Complemento">
                    <mat-error>
                      Preenchimento obrigatório
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">



              </div>
              <!--Fim Endereço-->
              <div class="row">
                <div class="h-100 d-flex align-items-center justify-content-center">
                  <button type="button" class="btn btn-outline-success" (click)="editDadosFaturamento()">Salvar</button>
                  <button type="button" class="btn btn-outline-secondary" (click)="cancelarDadosFaturamento()">Cancelar</button>
                </div>
              </div>
            </div>
          </form>
          <!--Fim Novo Form-->

          <div class="add-data-user" (click)="alterarFaturamento()" style="cursor:pointer" *ngIf="!HabilitaForm">
            <div class="add-content">Alterar Pagador</div>
            <img loading="lazy"
                 src="https://cdn.builder.io/api/v1/image/assets/TEMP/2ce48ee3f698d03aff6c9df688eb52096c00a95a3a7c95aca7964dee43ab2f00?apiKey=112bb9cfb8df486f9471fee20124622a&"
                 class="img-fluid" />
          </div>
        </div>
        <!--Final do bloco-->
      </div>
      <div class="column-right">
        <div class="wrapper-content-info-order">
          <div class="content-box">
            <span class="title-box-bold">Número Pedido:</span>
            <div class="text-info-box-bold">{{ pedido?.numeroPedido }}</div>
          </div>

          <div class="content-box" *ngIf="origem!= 'meet'">
            <span class="title-box">Quantidade de certidões:</span>
            <div class="text-info-box">{{calculaQuantidadeCertidoes( pedido)}}</div>
          </div>
          <div class="content-box" *ngIf="origem!= 'meet'">
            <span class="title-box">SUBTOTAL</span>
            <div class="text-info-box">{{ pedido?.totalPedido | currency:'BRL'}}</div>
          </div>
          <div class="content-box" *ngIf="origem!= 'meet'">
            <span class="title-box">Taxa Administrativa</span>
            <div class="text-info-box">{{pedido?.totalAdicionalAdm | currency:'BRL'}}</div>
          </div>
          <div class="content-box">
            <span class="title-box-bold">Total a pagar:</span>
            <div class="text-info-box-bold">{{ pedido?.totalPagar | currency:'BRL'}}</div>
          </div>

        </div>
      </div>

    </ng-container>
  </div>
</div>

<div class="row" *ngIf="device.isMobile() && !pedido?.pagamentoGerado">
  <div class="col-12">

    <div class="wrapper-content-info-order p-2">

      <div class="content-box row">
        <div class="title-box     col-8">Quantidade de certidões:</div>
        <div class="text-info-box col-4 text-end">{{calculaQuantidadeCertidoes( pedido)}}</div>
      </div>

      <div class="content-box row">
        <div class="title-box col-8">SUBTOTAL</div>
        <div class="text-info-box col-4 text-end">{{ pedido?.totalPedido | currency:'BRL'}}</div>
      </div>

      <div class="content-box row">
        <div class="title-box col-8">Taxa Administrativa</div>
        <div class="text-info-box col-4 text-end">{{pedido?.totalAdicionalAdm | currency:'BRL'}}</div>
      </div>

      <div class="content-box row">
        <span class="title-box-bold col-8">Total a pagar:</span>
        <div class="text-info-box-bold col-4 text-end">{{ pedido?.totalPagar | currency:'BRL'}}</div>
      </div>

    </div>

  </div>
  <div class="col-lg-12">
    <div class="wrapper-content-top">
      <h3 class="title-block mt-4 mb-4">Selecione a forma de pagamento</h3>
      <div class="wrapper-payment-method-list">
        <div class="payment-option">
          <div class="wrapper-content-info-option" style="padding-bottom: 10px;">
            <mat-accordion class="d-block col-md-12">
              <mat-expansion-panel [expanded]="panelOpenState">
                <mat-expansion-panel-header>
                  <mat-panel-title style="margin-top: 10px;">
                    <div class="content-img-text">
                      <img loading="lazy" style="float:left"
                           src="https://cdn.builder.io/api/v1/image/assets/TEMP/cac6e8d3f6f3c675c0840ed14fa664bcf040d18989f2b7b7dcdcec14a79569b5?apiKey=112bb9cfb8df486f9471fee20124622a&"
                           class="img" />
                      <p class="text-block" style="float:left">&nbsp;&nbsp;&nbsp;Boleto Bancário</p>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="prot-opcao-pg">
                  <p>
                    Total a pagar {{totalPagar |currency :'BLR'}}
                  </p>
                  <p>
                    Imprima o boleto e pague no banco. Ou pague pela internet utilizando o código de barras do boleto.
                  </p>
                  <p>
                    O boleto pode ser pago em 5 dias.
                  </p>

                  <br>


                </div>
              </mat-expansion-panel>
            </mat-accordion>

          </div>
        </div>
        <div class="payment-option" (click)="CarregarDadosCartaoCredito()">
          <div class="wrapper-content-info-option" style="padding-bottom: 10px;">
            <mat-accordion class="d-block col-md-12">
              <mat-expansion-panel [expanded]="panelOpenState">
                <mat-expansion-panel-header>
                  <mat-panel-title style="margin-top: 10px;">
                    <div class="content-img-text">
                      <img loading="lazy" style="float:left"
                           src="https://cdn.builder.io/api/v1/image/assets/TEMP/c5e96283f0faab7fcd694d656ee5fdc6e945e2344940cb4ffe3548672ad66066?apiKey=112bb9cfb8df486f9471fee20124622a&"
                           class="img" />
                      <p class="text-block" style="float:left">&nbsp;&nbsp;&nbsp;Cartão de Crédito</p>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="prot-pgto-credito">
                  <form [formGroup]="dadosCredito">
                    <div>
                      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                        <mat-label>Titular do Cartão</mat-label>
                        <input matInput type="text" class="col-lg-6" formControlName="nomeTitular"
                               placeholder="Titular do Cartão">
                        <mat-error *ngIf="creditCard.nomeTitular.errors?.['required']">
                          Preenchimento obrigatório
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div>
                      <div class="content-documento">
                        <mat-form-field>
                          <mat-label for="tipoDocumento">Documento</mat-label>
                          <mat-select formControlName="tipoDocumento" name="tipoDocumento">
                            <mat-option value="CPF">CPF</mat-option>
                            <mat-option value="CNPJ">CNPJ</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div>
                        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                          <mat-label>Número do documento</mat-label>
                          <input matInput type="text" class="col-lg-6" formControlName="numeroDocumento"
                                 placeholder="Número do documento">
                          <mat-error *ngIf="creditCard.numeroDocumento.errors?.['required']">
                            Preenchimento obrigatório
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div>
                      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                        <mat-label>Número do Cartão</mat-label>
                        <input matInput type="text" class="col-lg-6" formControlName="numeroCartao" (change)="numeroCartaoCreditoAlterado()"
                               placeholder="Número do Cartão" [maxlength]="16">
                        <mat-error *ngIf="creditCard.numeroCartao.errors?.['required']">
                          Preenchimento obrigatório
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div>
                      <div>
                        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                          <mat-label>CVC</mat-label>
                          <input matInput type="text" class="col-lg-6" formControlName="cvc"
                                 placeholder="CVC" [maxlength]="4">
                          <mat-error *ngIf="creditCard.cvc.errors?.['required']">
                            Preenchimento obrigatório
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="content-validade-cartao">
                        <label>Data de vencimento</label>
                        <div class="col-md-12 col-xs-12">
                          <div class="wrapper-content">
                            <mat-form-field class="col-md-7">
                              <mat-label for="ValidMes">Selecione o Mês</mat-label>
                              <mat-select formControlName="validadeMes" name="ValidMes">
                                <mat-option value="01">Janeiro</mat-option>
                                <mat-option value="02">Fevereiro</mat-option>
                                <mat-option value="03">Março</mat-option>
                                <mat-option value="04">Abril</mat-option>
                                <mat-option value="05">Maio</mat-option>
                                <mat-option value="06">Junho</mat-option>
                                <mat-option value="07">Julho</mat-option>
                                <mat-option value="08">Agosto</mat-option>
                                <mat-option value="09">Setembro</mat-option>
                                <mat-option value="10">Outubro</mat-option>
                                <mat-option value="11">Novembro</mat-option>
                                <mat-option value="12">Dezembro</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="col-md-4 my-2 prot-tab-input-conteudo">
                              <mat-label>Ano</mat-label>
                              <input matInput type="text" formControlName="validadeAno">
                              <mat-error *ngIf="creditCard.cvc.errors?.['required']">
                                Preenchimento obrigatório
                              </mat-error>
                            </mat-form-field>

                          </div>

                        </div>
                      </div>
                    </div>
                    <div>
                      <mat-form-field class="col-md-12">
                        <mat-label for="ValidMes">Parcelas</mat-label>
                        <mat-select formControlName="qtdParcelas" name="qtdParcelas">
                          <mat-option *ngFor="let item of parcelas" [value]="item.valor">{{item.descricao}}</mat-option>

                        </mat-select>
                      </mat-form-field>
                    </div>


                    <button class="btn-pgtos btn btn-primary w-100">Pagar</button>

                  </form>

                </div>
              </mat-expansion-panel>
            </mat-accordion>

          </div>
        </div>

        <div class="payment-option" (click)="GerarChavePix()">
          <div class="wrapper-content-info-option" style="padding-bottom: 10px;">
            <mat-accordion class="d-block col-md-12">
              <mat-expansion-panel [expanded]="panelOpenState">
                <mat-expansion-panel-header>
                  <mat-panel-title style="margin-top: 10px;">
                    <div class="content-img-text">
                      <img loading="lazy" style="float:left"
                           src="https://cdn.builder.io/api/v1/image/assets/TEMP/fd850d6c6965cf03183cc3cadf8f057bff5eb380e3f060ce2b454a4d0f43f2dd?apiKey=112bb9cfb8df486f9471fee20124622a&"
                           class="img" />
                      <p class="text-block" style="float:left">&nbsp;&nbsp;&nbsp;Pix</p>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="prot-pgto-pix">

                  <span class="text-center">QR Code para pagamento</span><br />

                  <!--<span class="text-center rounded eneg-status-pix">
                    AGUARDANDO PAGAMENTO
                  </span>-->

                  <div id="chave-pix" style="width: 100px; height: 100px;">
                    <img class="img-fluid" [src]="'data:image/jpeg;base64,' + qrCodePix.qrCodeBase64" />
                  </div>

                  <span class="text-center">
                    Ou Clique no botão abaixo para utilizar o pix na função "Copia e Cola"
                  </span>

                  <span class="text-center">
                    O Código poderá ser colado no aplicativo do seu banco.
                  </span>
                  <br />
                  <br />

                  <!--<input [(ngModel)]="qrCodePix.qrCode" />-->

                  <button class="btn-pgtos mt-4 mb-4 btn btn-primary btn-sm w-100 " (click)="copyMessage(qrCodePix.qrCode)">Copiar código</button>
                  <p>
                    Lembre-se de que, por enquanto, o Banco Central do Brasil funciona de segunda a sexta-feira das 9h às 18hs e, caso se solicite um cadastro fora desse horário, confirmaremos no próximo dia útil. Além disso, existe um limite para o valor de Pix que poderá ser movimentado no decorrer do período noturno (entre 20h e 6h) de R$1 mil. Para o período diurno (entre 6h e 20h), contudo, não há limite de movimentação.
                  </p>

                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <div class="wrapper-content-bottom">
      <h3 class="title-block mt-4 mb-4">Dados de Faturamento</h3>
      <div class="content-info-user" *ngIf="!HabilitaForm">

        <ng-container>

          <div class="content-info-user row">

            <span class="title-info-user col-12">Nome</span>
            <p class="text-info-user col-12"> {{dadosFaturamento?.nome}} </p>

            <span class="title-info-user col-12">CPF/CNPJ</span>
            <p class="text-info-user col-12"> {{ dadosFaturamento?.cnpjCpf }} </p>

            <span class="title-info-user col-12">Endereço</span>
            <p class="text-info-user col-12">
              {{dadosFaturamento?.tipoLogradouro}} {{dadosFaturamento?.logradouro}}, {{dadosFaturamento?.numero}}-{{dadosFaturamento?.bairro}}
              <br />
              {{dadosFaturamento?.cidade?.Nome}}-{{dadosFaturamento?.uf?.Sigla}}
              <br />
              {{dadosFaturamento?.cep}}
            </p>
          </div>
        </ng-container>
      </div>
      <!--Novo Form-->
      <!-- Form para adicionar os Dados de Faturamento-->
      <br />
      <form [formGroup]="formDadosFaturamento" *ngIf="HabilitaForm">
        <div class="content-list" width="100px">
          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>
                  CPF/CNPJ
                </mat-label>
                <input matInput type="text" (input)="getFaturacao()" formControlName="cnpjCpf" mask="000.000.000-00||00.000.000/0000-00">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <!--Nome-->
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Nome</mat-label>
                <input matInput type="text" class="col-lg-12" formControlName="nome"
                       placeholder="Nome">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>CEP</mat-label>
                <input matInput
                       type="text"
                       class="col-lg-12"
                       mask="00000-000"
                       formControlName="cep"
                       placeholder="CEP"
                       (blur)="buscarEndereco($event)">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
                <mat-error *ngIf="formDadosFaturamento.hasError('cepNotMatch')">
                  CEP não encontrado.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field class="w-100">
                <mat-label>Estado</mat-label>
                <input type="text"
                       placeholder="Estado"
                       aria-label="Number"
                       matInput
                       formControlName="uf"
                       [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProperty" (optionSelected)="getCidade($event)">
                  <mat-option *ngFor="let item of listufFilter" [value]="item">
                    {{item.Nome }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field class="w-100">
                <mat-label>Cidade</mat-label>
                <input type="text"
                       #cidade
                       id="Cidade"
                       placeholder="Cidade"
                       aria-label="Number"
                       matInput
                       formControlName="cidade"
                       [matAutocomplete]="auto2">
                <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayPropertyCidade" (optionSelected)="setLocal()">
                  <mat-option *ngFor="let item of listCidadeFilter" [value]="item">
                    {{item.Nome }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>


          </div>
          <!--Dados do Endereço-->
          <div class="row">
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Tipo de logradouro</mat-label>
                <mat-select formControlName="tipoLogradouro">
                  <mat-option value="ALAMEDA">ALAMEDA</mat-option>
                  <mat-option value="AVENIDA">AVENIDA</mat-option>
                  <mat-option value="BECO">BECO</mat-option>
                  <mat-option value="CAMPO">CAMPO</mat-option>
                  <mat-option value="ESTRADA">ESTRADA</mat-option>
                  <mat-option value="ILHA">ILHA</mat-option>
                  <mat-option value="LADEIRA">LADEIRA</mat-option>
                  <mat-option value="LARGO">LARGO</mat-option>
                  <mat-option value="PRAÇA">PRAÇA</mat-option>
                  <mat-option value="RUA">RUA</mat-option>
                  <mat-option value="SERVIDAO">SERVIDAO</mat-option>
                  <mat-option value="TRAVESSA">TRAVESSA</mat-option>
                  <mat-option value="VIA">VIA</mat-option>
                  <mat-option value="VILA">VILA</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-9">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Logradouro</mat-label>
                <input matInput type="text"
                       formControlName="logradouro"
                       placeholder="Logradouro">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Número</mat-label>
                <input matInput type="text"
                       formControlName="numero"
                       placeholder="Número">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Bairro</mat-label>
                <input matInput type="text"
                       formControlName="bairro"
                       placeholder="Bairro">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-5">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Complemento</mat-label>
                <input matInput type="text"
                       formControlName="complemento"
                       placeholder="Complemento">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">



          </div>
          <!--Fim Endereço-->
          <div class="row">
            <div class="h-100 d-flex align-items-center justify-content-center">
              <button type="button" class="btn btn-outline-success" (click)="editDadosFaturamento()">Salvar</button>
              <button type="button" class="btn btn-outline-secondary" (click)="cancelarDadosFaturamento()">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
      <!--Fim Novo Form-->

      <div class="add-data-user row " (click)="alterarFaturamento()" style="cursor:pointer" *ngIf="!HabilitaForm">

        <div class="add-content col-6 text-end">Alterar</div>

        <div class="col-6 text-start">
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/2ce48ee3f698d03aff6c9df688eb52096c00a95a3a7c95aca7964dee43ab2f00?apiKey=112bb9cfb8df486f9471fee20124622a&"
               class="img-fluid" />

        </div>

      </div>
    </div>
  </div>
</div>

<div class="" *ngIf="pedido?.pagamentoGerado">

  <div *ngIf="device.isMobile()">

    <div id="quadrante-botoes" class="row mt-4 mb-4" *ngIf="pedido?.tipoPagamento == 'boleto'">

      <!-- tela pedido conclusao -->
      <!-- icone checkbox  -->
      <div class="row mt-5 mb-5">
        <div id="checkbox-image" class="col-12 text-center">
          <img src="../../../assets/Group.svg">
        </div>
      </div>

      <div class="row mt-2 mb-2">
        <div class="col-12 text-center"
             style="font-size: 2rem; font-weight: 600; color: #014F93; line-height: 2.2rem;">Pedido {{this.pedido.numeroPedido}} realizado com sucesso</div>
      </div>

      <div class="row mt-2 mb-2">
        <div class="col-12 text-center px-5" style=" font-size: 1.5rem; font-weight: normal; color: #56A5E9; line-height: 1.7rem">Caso ainda não tenha realizado o pagamento do boleto, efetue agora mesmo para darmos andamento ao seu pedido.</div>
      </div>

      <div class="row mt-4 mb-4">
        <div id="box branco" class="col-12 py-4 ">
          <div class="px-4 py-4" style="background-color: #ddd; width: 90%; margin: 0 auto;">
            <div style="font-family: Gotham; font-size: 1.6rem; font-weight: 600; color: #014F93; ">Número do boleto</div>
            <div id="codigo-de-barras" class="mt-4 mb-4 px-3 text-center" style="word-break: break-all; cursor: pointer;font-size: 1rem; ">{{ pedido?.dadosPagamento?.linhaDigitavel }}</div>
            <div id="botão" class="mt-2 mb-2 text-center">
              <button class="btn-pgtos btn btn-primary">
                <a [href]="pedido?.dadosPagamento?.linkBoleto" target="_blank" style="text-decoration: none; color: #fff; ">Baixar Boleto</a>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2 mb-2 px-4">
        <div class="py-2" style="border: 1px solid #019333; background-color: #E3FAEB; margin: 0 auto; width: 90%;">
          <div class="row">
            <div class="col-3 pt-3 text-center">
              <div id="ico-info" class="img-fluid"><img class="py-3" src="../../../assets/envelope.svg"></div>
            </div>
            <div class="col-9" style="font-family: Gotham; font-size: 0.95rem; font-weight: normal; color: #019333; padding-left: 0px;">
              Para sua tranquilidade, você receberá um e-mail a cada mudança de status para companhar a evolução do seu pedido.
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2 mb-2">
        <div class="col-12 mt-2 mb-2">
          <button class="btn btn-primary w-100" (click)="novoPedido()">Fazer novo pedido</button>
        </div>
        <div class="col-12 mt-2 mb-2">
          <button class="btn btn-outline-primary w-100" (click)="meusPedidos()">
            meus pedidos
          </button>
        </div>
      </div>

    </div>

    <ng-container *ngIf="pedido?.tipoPagamento == 'credito'">
      <div class="row">
        <!-- icone checkbox  -->
        <div class="row mt-5 mb-5">
          <div id="checkbox-image" class="col-12 text-center">
            <img src="../../../assets/Group.svg">
          </div>
        </div>
        <div class="row mt-2 mb-2">
          <div class="col-12 text-center"
               style="font-size: 2rem; font-weight: 600; color: #014F93; line-height: 2.2rem;">Pedido {{this.pedido.numeroPedido}} realizado com sucesso</div>
        </div>
        <div class="row mt-2 mb-2 px-4">
          <div class="py-2" style="border: 1px solid #019333; background-color: #E3FAEB; margin: 0 auto; width: 90%;">
            <div class="row">
              <div class="col-3 pt-3 text-center">
                <div id="ico-info" class="img-fluid"><img class="py-3" src="../../../assets/envelope.svg"></div>
              </div>
              <div class="col-9" style="font-family: Gotham; font-size: 0.95rem; font-weight: normal; color: #019333; padding-left: 0px;">
                Para sua tranquilidade, você receberá um e-mail a cada mudança de status para companhar a evolução do seu pedido.
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2 mb-2">
          <div class="col-12 mt-2 mb-2">
            <button class="btn btn-primary w-100" (click)="novoPedido()">Fazer novo pedido</button>
          </div>
          <div class="col-12 mt-2 mb-2">
            <button class="btn btn-outline-primary w-100" (click)="meusPedidos()">
              meus pedidos
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="pedido?.tipoPagamento == 'pix'">
      <div class="prot-pgto-pix">
        <!-- tela pedido conclusao -->
        <div class="row">
          <!-- icone checkbox  -->
          <div class="row mt-5 mb-5">
            <div id="checkbox-image" class="col-12 text-center">
              <img src="../../../assets/Group.svg">
            </div>
          </div>
          <div class="row mt-2 mb-2">
            <div class="col-12 text-center"
                 style="font-size: 2rem; font-weight: 600; color: #014F93; line-height: 2.2rem;">Pedido {{this.pedido.numeroPedido}} realizado com sucesso</div>
          </div>

           
           

          <div class="row mt-2 mb-2 px-4">
            <div class="py-2" style="border: 1px solid #019333; background-color: #E3FAEB; margin: 0 auto; width: 90%;">
              <div class="row">
                <div class="col-3 pt-3 text-center">
                  <div id="ico-info" class="img-fluid"><img class="py-3" src="../../../assets/envelope.svg"></div>
                </div>
                <div class="col-9" style="font-family: Gotham; font-size: 0.95rem; font-weight: normal; color: #019333; padding-left: 0px;">
                  Para sua tranquilidade, você receberá um e-mail a cada mudança de status para companhar a evolução do seu pedido.
                </div>
              </div>
            </div>
          </div>
 

          <div class="row mt-2 mb-2">
            <div class="col-12 mt-2 mb-2">
              <button class="btn btn-primary w-100" (click)="novoPedido()">Fazer novo pedido</button>
            </div>
            <div class="col-12 mt-2 mb-2">
              <button class="btn btn-outline-primary w-100" (click)="meusPedidos()">
                meus pedidos
              </button>
            </div>
          </div>

        </div>
      </div>
    </ng-container>

  </div>

  <div *ngIf="!device.isMobile()">
    <div id="quadrante-botoes" class="row mt-4 mb-4" *ngIf="pedido?.pedidoGratuito">

      <!-- tela pedido conclusao -->
      <div class="row">
        <!-- icone checkbox  -->
        <div class="row mt-5 mb-5">
          <div class="col-lg-4">&nbsp;</div>
          <div id="checkbox-image" class="col-lg-4 text-center">
            <img src="../../../assets/Group.svg">
          </div>
          <div class="col-lg-4">&nbsp;</div>
        </div>
        <div class="row mt-2 mb-2">

          <div class="col-lg-12 text-center"
               style="font-size: 2rem; font-weight: 600; color: #014F93;">Pedido {{this.pedido.numeroPedido}} realizado com sucesso</div>

        </div>
        <div class="row"><div class="cl-lg-12">&nbsp;</div></div>




        <div class="row mt-2 mb-2">
          <div class="col-lg-4">&nbsp;</div>
          <div class="col-lg-4 py-2">
            <div class="py-2" style="border: 1px solid #019333; background-color: #E3FAEB; margin: 0 auto; width: 90%;">
              <div class="row">
                <div id="ico-info" class="col-lg-3 text-center"><img class="py-3" src="../../../assets/envelope.svg"></div>
                <div id="texto-informativo" class="col-lg-9" style="font-family: Gotham; font-size: 0.95rem; font-weight: normal; color: #019333; padding-left: 0px;">Para sua tranquilidade, você receberá um e-mail a cada mudança de status para companhar a evolução do seu pedido.</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">&nbsp;</div>
        </div>
        <div class="row mt-2 mb-2">
          <div class="col-lg-3">&nbsp;</div>
          <div id="botoes" class="col-lg-6 mt-5 mb-5">
            <div class="row">
              <div class="col-lg-6">
                <button class="btn btn-primary w-100" (click)="novoPedido()">Fazer novo pedido</button>
              </div>
              <div class="col-lg-6">
                <button class="btn btn-outline-primary w-100" (click)="minhasSolicitacoes()">
                  minhas solitações
                </button>

              </div>
            </div>
          </div>
          <div class="col-lg-3">&nbsp;</div>
        </div>
      </div>
    </div>

    <div id="quadrante-botoes" class="row mt-4 mb-4" *ngIf="pedido?.tipoPagamento == 'boleto'">

      <!-- tela pedido conclusao -->
      <div class="row">
        <!-- icone checkbox  -->
        <div class="row mt-5 mb-5">
          <div class="col-lg-4">&nbsp;</div>
          <div id="checkbox-image" class="col-lg-4 text-center">
            <img src="../../../assets/Group.svg">
          </div>
          <div class="col-lg-4">&nbsp;</div>
        </div>
        <div class="row mt-2 mb-2">

          <div class="col-lg-12 text-center"
               style="font-size: 2rem; font-weight: 600; color: #014F93;">Resolução {{this.pedido.numeroPedido}} realizada com sucesso</div>

        </div>
        <div class="row"><div class="cl-lg-12">&nbsp;</div></div>

        <div class="row mt-2 mb-2">
          <div class="col-lg-3">&nbsp;</div>
          <div class="col-lg-6 text-center px-5" style=" font-size: 1.5rem; font-weight: normal; color: #56A5E9;">Caso ainda não tenha realizado o pagamento do boleto, efetue agora mesmo para darmos andamento ao seu pedido.</div>
          <div class="col-lg-3">&nbsp;</div>
        </div>

        <div class="row mt-4 mb-4">
          <div class="col-lg-3">&nbsp;</div>
          <div id="box branco" class="col-lg-6 py-4 ">
            <div class="px-4 py-4" style="background-color: #ddd; width: 90%; margin: 0 auto;">
              <div style="font-family: Gotham; font-size: 1.6rem; font-weight: 600; color: #014F93; ">Número do boleto</div>

              <div id="codigo-de-barras" class="mt-4 mb-4 px-3 text-center" style="word-break: break-all; cursor: pointer;font-size: 1rem; ">{{ pedido?.dadosPagamento?.linhaDigitavel }}</div>
              <div id="botão" class="mt-2 mb-2 text-center">
                <button class="btn-pgtos btn btn-primary">
                  <a [href]="pedido?.dadosPagamento?.linkBoleto" target="_blank" style="text-decoration: none; color: #fff; ">Baixar Boleto</a>
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-3">&nbsp;</div>
        </div>
        <div class="row mt-2 mb-2">
          <div class="col-lg-4">&nbsp;</div>
          <div class="col-lg-4 py-2">
            <div class="py-2" style="border: 1px solid #019333; background-color: #E3FAEB; margin: 0 auto; width: 90%;">
              <div class="row">
                <div id="ico-info" class="col-lg-3 text-center"><img class="py-3" src="../../../assets/envelope.svg"></div>
                <div id="texto-informativo" class="col-lg-9" style="font-family: Gotham; font-size: 0.95rem; font-weight: normal; color: #019333; padding-left: 0px;">Para sua tranquilidade, você receberá um e-mail a cada mudança de status para companhar a evolução do seu pedido.</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">&nbsp;</div>
        </div>
        <div class="row mt-2 mb-2">
          <div class="col-lg-3">&nbsp;</div>
          <div id="botoes" class="col-lg-6 mt-5 mb-5">
            <div class="row">
              <div class="col-lg-6">
                <button class="btn btn-primary w-100" (click)="novoPedido()">Fazer novo pedido</button>
              </div>
              <div class="col-lg-6">
                <button class="btn btn-outline-primary w-100" (click)="meusPedidos()">
                  meus pedidos
                </button>

              </div>
            </div>
          </div>
          <div class="col-lg-3">&nbsp;</div>
        </div>
      </div>
    </div>

    
    <ng-container *ngIf="pedido?.tipoPagamento == 'pix'">
      <div class="prot-pgto-pix">
        <!-- tela pedido conclusao -->
        <div class="row">
          <!-- icone checkbox  -->
          <div class="row mt-5 mb-5">
            <div class="col-lg-4">&nbsp;</div>
            <div id="checkbox-image" class="col-lg-4 text-center">
              <img src="../../../assets/Group.svg">
            </div>
            <div class="col-lg-4">&nbsp;</div>
          </div>


          <div class="row mt-2 mb-2">
            <div class="col-lg-12 text-center"
                 style="font-size: 2rem; font-weight: 600; color: #014F93;">Pedido {{this.pedido.numeroPedido}} realizado com sucesso</div>
          </div>
          <div class="row"><div class="col-lg-12">&nbsp;</div></div>





          <div class="row mt-2 mb-2">
            <div class="col-lg-4">&nbsp;</div>
            <div class="col-lg-4 py-2">
              <div class="py-2" style="border: 1px solid #019333; background-color: #E3FAEB; margin: 0 auto; width: 90%;">
                <div class="row">
                  <div id="ico-info" class="col-lg-3 text-center"><img class="py-3" src="../../../assets/envelope.svg"></div>
                  <div id="texto-informativo" class="col-lg-9" style="font-family: Gotham; font-size: 0.75rem; font-weight: normal; color: #019333; padding-left: 0px;">Para sua tranquilidade, você receberá um e-mail a cada mudança de status para companhar a evolução do seu pedido.</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">&nbsp;</div>
          </div>

          <div class="row mt-2 mb-2">
            <div class="col-lg-3">&nbsp;</div>
            <div id="botoes" class="col-lg-6 mt-5 mb-5">
              <div class="row">
                <div class="col-lg-6">
                  <button class="btn btn-primary w-100" (click)="novoPedido()">Fazer novo pedido</button>
                </div>
                <div class="col-lg-6">
                  <button class="btn btn-outline-primary w-100" (click)="meusPedidos()">meus pedidos</button>
                </div>
              </div>
            </div>
            <div class="col-lg-3">&nbsp;</div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="pedido?.tipoPagamento == 'credito'">

      <div class="row">
        <!-- icone checkbox  -->
        <div class="row mt-5 mb-5">
          <div class="col-lg-4">&nbsp;</div>
          <div id="checkbox-image" class="col-lg-4 text-center">
            <img src="../../../assets/Group.svg">
          </div>
          <div class="col-lg-4">&nbsp;</div>
        </div>
        <div class="row mt-2 mb-2">

          <div class="col-lg-12 text-center"
               style="font-size: 2rem; font-weight: 600; color: #014F93;">Pedido {{this.pedido.numeroPedido}} realizado com sucesso</div>

        </div>
        <div class="row"><div class="cl-lg-12">&nbsp;</div></div>
        <div class="row mt-2 mb-2">
          <div class="col-lg-3">&nbsp;</div>

          <div class="col-lg-6 py-2">
            <div class="py-2" style="border: 1px solid #019333; background-color: #E3FAEB; margin: 0 auto; width: 90%;">
              <div class="row">
                <div id="ico-info" class="col-lg-3 text-center"><img class="py-3" src="../../../assets/envelope.svg"></div>
                <div id="texto-informativo" class="col-lg-9" style="font-size: 1.25rem; font-weight: normal; color: #019333; padding-left: 0px;">Para sua tranquilidade, você receberá um e-mail a cada mudança de status para companhar a evolução do seu pedido.</div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-2 mb-2">
          <div class="col-lg-3">&nbsp;</div>
          <div id="botoes" class="col-lg-6 mt-5 mb-5">
            <div class="row">
              <div class="col-lg-6">
                <button class="btn btn-primary w-100" (click)="novoPedido()">Fazer novo pedido</button>
              </div>
              <div class="col-lg-6">
                <button class="btn btn-outline-primary w-100" (click)="meusPedidos()">
                  meus pedidos
                </button>

              </div>
            </div>
          </div>
          <div class="col-lg-3">&nbsp;</div>
        </div>
      </div>

    </ng-container>

  </div>

</div>
