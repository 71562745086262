<!-- Verifica se a exigencia está finalizada -->

  <div>
    <b>Exigencias</b>
  </div>

  <div class="wrapper-item mt-4" *ngIf="!efeturarPagamento && !exibicaoLista">
    <div class="row" *ngFor="let exigencia of exigencias; index as i">

      <div class="d-flex justify-content-between pb-3 border-bottom linha-exigencia align-items-center">
        <div class="d-flex col-lg-8">
          <div class="wrapper-info col-lg-4" *ngIf="exigencia?.statusExigencia">
            <h3 class="title-section">Tipo</h3>
            <p class="info-section">{{exigencia?.tipoExigencia}}</p>
          </div>

          <div class="wrapper-info col-lg-2" *ngIf="exigencia?.dataExigencia">
            <h3 class="title-section">Abertura</h3>
            <p class="info-section">{{exigencia?.dataExigencia | date: 'dd/MM/yyyy':'UTC'}}</p>
          </div>

          <div class="wrapper-info col-lg-2" *ngIf="exigencia?.dataLimitePagamento">
            <h3 class="title-section">Prazo</h3>
            <p class="info-section">{{exigencia?.dataLimitePagamento | date: 'dd/MM/yyyy':'UTC'}}</p>
          </div>

          <div class="wrapper-info col-lg-2" *ngIf="exigencia?.dataCumprimento">
            <h3 class="title-section">Conclusao</h3>
            <p class="info-section">{{exigencia?.dataCumprimento | date: 'dd/MM/yyyy'}}</p>
          </div>

          <div class="wrapper-info col-lg-2" *ngIf="exigencia?.valorExigencia">
            <h3 class="title-section">Valor Exigência</h3>
            <p class="info-section">{{exigencia?.valorExigencia | currency:'BRL'}}</p>
          </div>
        </div>

        <div class="wrapper-info d-flex col-lg-4 text-end justify-content-end acoes-exigencia">
          <button mat-button class="btn btn-light btn-sm d-flex text-danger"
                  (click)="selecionarTipoUploadExigencia(exigencia, i, 'TEXTO')">
            <mat-icon>search</mat-icon>
            <span>Visualizar Exigência</span>
          </button>

          <button mat-button class="btn btn-light btn-sm d-flex"
                  *ngIf="exigencia?.tipoExigencia == 'Documental' && exigencia?.statusExigencia == 'Exigência Documental'"
                  (click)="selecionarTipoUploadExigencia(exigencia, i, 'DOCUMENTO')">
            <mat-icon>upload_file</mat-icon>
            <span>Enviar Documentos</span>
          </button>

          <button mat-button class="btn btn-light btn-sm d-flex" (click)="pagar(exigencia)"
                  *ngIf="exigencia?.tipoExigencia == 'Financeira' && !exigencia?.dataPagamento && exigencia?.statusExigencia == 'Exigência Financeira'">
            <mat-icon>paid</mat-icon>
            <span>pagar</span>
          </button>
        </div>
      </div>
    </div>
  </div>






<!-- pagar exigencia -->
<div class="wrapper-item mt-4" *ngIf="efeturarPagamento">
  <app-payment-v2 [pedido]="pedido" [compraAtual]="compraAtual" [exigencia]="exigenciaSelecionado"> </app-payment-v2>

  <div class="text-end">
    <button mat-button class="btn btn-light btn-sm" *ngIf="efeturarPagamento"
            (click)="efeturarPagamento = !efeturarPagamento">
      Voltar
    </button>
  </div>
</div>
<!-- fim pagar exigencia -->
<!-- mensagens exigencia -->
<div class="wrapper-item mt-4" *ngIf="exibicaoLista">
  <app-post-exigencia [tipoUploadExigencia]="tipoExigenciaSelecioando"
                      [statusCompra]="compraAtual?.status" [pedido]="pedido" [compraAtual]="compraAtual" [exigencia]="exigenciaSelecionado"
                      (enviarMensagemExigenciaEvent)="enviarMensagemExigencia($event)"></app-post-exigencia>

  <div class="text-end">
    <button mat-button class="btn btn-light btn-sm" *ngIf="exibicaoLista"
            (click)="voltarPagina()">
      Voltar
    </button>
  </div>
</div>
<!-- fim mensagens exigencia -->
