export const localidade = {
  listuf: [{
    "Id": "66631cc6e4e2c9c913f2bf83",
    "IdEstado": 1,
    "Sigla": "AC",
    "Nome": "Acre"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf84",
    "IdEstado": 2,
    "Sigla": "AL",
    "Nome": "Alagoas"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf85",
    "IdEstado": 3,
    "Sigla": "AP",
    "Nome": "Amapá"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf86",
    "IdEstado": 4,
    "Sigla": "AM",
    "Nome": "Amazonas"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf87",
    "IdEstado": 5,
    "Sigla": "BA",
    "Nome": "Bahia"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf88",
    "IdEstado": 6,
    "Sigla": "CE",
    "Nome": "Ceará"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf89",
    "IdEstado": 7,
    "Sigla": "DF",
    "Nome": "Distrito Federal"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf8a",
    "IdEstado": 8,
    "Sigla": "ES",
    "Nome": "Espírito Santo"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf8b",
    "IdEstado": 9,
    "Sigla": "GO",
    "Nome": "Goiás"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf8c",
    "IdEstado": 10,
    "Sigla": "MA",
    "Nome": "Maranhão"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf8d",
    "IdEstado": 11,
    "Sigla": "MT",
    "Nome": "Mato Grosso"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf8e",
    "IdEstado": 12,
    "Sigla": "MS",
    "Nome": "Mato Grosso do Sul"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf8f",
    "IdEstado": 13,
    "Sigla": "MG",
    "Nome": "Minas Gerais"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf90",
    "IdEstado": 14,
    "Sigla": "PA",
    "Nome": "Pará"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf91",
    "IdEstado": 15,
    "Sigla": "PB",
    "Nome": "Paraíba"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf92",
    "IdEstado": 16,
    "Sigla": "PR",
    "Nome": "Paraná"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf93",
    "IdEstado": 17,
    "Sigla": "PE",
    "Nome": "Pernambuco"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf94",
    "IdEstado": 18,
    "Sigla": "PI",
    "Nome": "Piauí"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf95",
    "IdEstado": 19,
    "Sigla": "RJ",
    "Nome": "Rio de Janeiro"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf96",
    "IdEstado": 20,
    "Sigla": "RN",
    "Nome": "Rio Grande do Norte"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf97",
    "IdEstado": 21,
    "Sigla": "RS",
    "Nome": "Rio Grande do Sul"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf98",
    "IdEstado": 22,
    "Sigla": "RO",
    "Nome": "Rondônia"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf99",
    "IdEstado": 23,
    "Sigla": "RR",
    "Nome": "Roraima"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf9a",
    "IdEstado": 24,
    "Sigla": "SC",
    "Nome": "Santa Catarina"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf9b",
    "IdEstado": 25,
    "Sigla": "SP",
    "Nome": "São Paulo"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf9c",
    "IdEstado": 26,
    "Sigla": "SE",
    "Nome": "Sergipe"
  },
  {
    "Id": "66631cc6e4e2c9c913f2bf9d",
    "IdEstado": 27,
    "Sigla": "TO",
    "Nome": "Tocantins"
  }],
  listCidade: [
    {
      "Id": "65b11fe7c0095aa0cc394354",
      "IdCidade": "3591",
      "IdEstado": "19",
      "Nome": "Angra dos Reis"
    },
    {
      "Id": "65b11fe7c0095aa0cc394355",
      "IdCidade": "3592",
      "IdEstado": "19",
      "Nome": "Aperibé"
    },
    {
      "Id": "65b11fe7c0095aa0cc394356",
      "IdCidade": "3593",
      "IdEstado": "19",
      "Nome": "Araruama"
    },
    {
      "Id": "65b11fe7c0095aa0cc394357",
      "IdCidade": "3594",
      "IdEstado": "19",
      "Nome": "Areal"
    },
    {
      "Id": "65b11fe7c0095aa0cc394358",
      "IdCidade": "3595",
      "IdEstado": "19",
      "Nome": "Armação dos Búzios"
    },
    {
      "Id": "65b11fe7c0095aa0cc394359",
      "IdCidade": "3596",
      "IdEstado": "19",
      "Nome": "Arraial do Cabo"
    },
    {
      "Id": "65b11fe7c0095aa0cc39435a",
      "IdCidade": "3597",
      "IdEstado": "19",
      "Nome": "Barra do Piraí"
    },
    {
      "Id": "65b11fe7c0095aa0cc39435b",
      "IdCidade": "3598",
      "IdEstado": "19",
      "Nome": "Barra Mansa"
    },
    {
      "Id": "65b11fe7c0095aa0cc39435c",
      "IdCidade": "3599",
      "IdEstado": "19",
      "Nome": "Belford Roxo"
    },
    {
      "Id": "65b11fe7c0095aa0cc39435d",
      "IdCidade": "3600",
      "IdEstado": "19",
      "Nome": "Bom Jardim"
    },
    {
      "Id": "65b11fe7c0095aa0cc39435e",
      "IdCidade": "3601",
      "IdEstado": "19",
      "Nome": "Bom Jesus do Itabapoana"
    },
    {
      "Id": "65b11fe7c0095aa0cc39435f",
      "IdCidade": "3602",
      "IdEstado": "19",
      "Nome": "Cabo Frio"
    },
    {
      "Id": "65b11fe7c0095aa0cc394360",
      "IdCidade": "3603",
      "IdEstado": "19",
      "Nome": "Cachoeiras de Macacu"
    },
    {
      "Id": "65b11fe7c0095aa0cc394361",
      "IdCidade": "3604",
      "IdEstado": "19",
      "Nome": "Cambuci"
    },
    {
      "Id": "65b11fe7c0095aa0cc394362",
      "IdCidade": "3605",
      "IdEstado": "19",
      "Nome": "Campos dos Goytacazes"
    },
    {
      "Id": "65b11fe7c0095aa0cc394363",
      "IdCidade": "3606",
      "IdEstado": "19",
      "Nome": "Cantagalo"
    },
    {
      "Id": "65b11fe7c0095aa0cc394364",
      "IdCidade": "3607",
      "IdEstado": "19",
      "Nome": "Carapebus"
    },
    {
      "Id": "65b11fe7c0095aa0cc394365",
      "IdCidade": "3608",
      "IdEstado": "19",
      "Nome": "Cardoso Moreira"
    },
    {
      "Id": "65b11fe7c0095aa0cc394366",
      "IdCidade": "3609",
      "IdEstado": "19",
      "Nome": "Carmo"
    },
    {
      "Id": "65b11fe7c0095aa0cc394367",
      "IdCidade": "3610",
      "IdEstado": "19",
      "Nome": "Casimiro de Abreu"
    },
    {
      "Id": "65b11fe7c0095aa0cc394368",
      "IdCidade": "3611",
      "IdEstado": "19",
      "Nome": "Comendador Levy Gasparian"
    },
    {
      "Id": "65b11fe7c0095aa0cc394369",
      "IdCidade": "3612",
      "IdEstado": "19",
      "Nome": "Conceição de Macabu"
    },
    {
      "Id": "65b11fe7c0095aa0cc39436a",
      "IdCidade": "3613",
      "IdEstado": "19",
      "Nome": "Cordeiro"
    },
    {
      "Id": "65b11fe7c0095aa0cc39436b",
      "IdCidade": "3614",
      "IdEstado": "19",
      "Nome": "Duas Barras"
    },
    {
      "Id": "65b11fe7c0095aa0cc39436c",
      "IdCidade": "3615",
      "IdEstado": "19",
      "Nome": "Duque de Caxias"
    },
    {
      "Id": "65b11fe7c0095aa0cc39436d",
      "IdCidade": "3616",
      "IdEstado": "19",
      "Nome": "Engenheiro Paulo de Frontin"
    },
    {
      "Id": "65b11fe7c0095aa0cc39436e",
      "IdCidade": "3617",
      "IdEstado": "19",
      "Nome": "Guapimirim"
    },
    {
      "Id": "65b11fe7c0095aa0cc39436f",
      "IdCidade": "3618",
      "IdEstado": "19",
      "Nome": "Iguaba Grande"
    },
    {
      "Id": "65b11fe7c0095aa0cc394370",
      "IdCidade": "3619",
      "IdEstado": "19",
      "Nome": "Itaboraí"
    },
    {
      "Id": "65b11fe7c0095aa0cc394371",
      "IdCidade": "3620",
      "IdEstado": "19",
      "Nome": "Itaguaí"
    },
    {
      "Id": "65b11fe7c0095aa0cc394372",
      "IdCidade": "3621",
      "IdEstado": "19",
      "Nome": "Italva"
    },
    {
      "Id": "65b11fe7c0095aa0cc394373",
      "IdCidade": "3622",
      "IdEstado": "19",
      "Nome": "Itaocara"
    },
    {
      "Id": "65b11fe7c0095aa0cc394374",
      "IdCidade": "3623",
      "IdEstado": "19",
      "Nome": "Itaperuna"
    },
    {
      "Id": "65b11fe7c0095aa0cc394375",
      "IdCidade": "3624",
      "IdEstado": "19",
      "Nome": "Itatiaia"
    },
    {
      "Id": "65b11fe7c0095aa0cc394376",
      "IdCidade": "3625",
      "IdEstado": "19",
      "Nome": "Japeri"
    },
    {
      "Id": "65b11fe7c0095aa0cc394377",
      "IdCidade": "3626",
      "IdEstado": "19",
      "Nome": "Laje do Muriaé"
    },
    {
      "Id": "65b11fe7c0095aa0cc394378",
      "IdCidade": "3627",
      "IdEstado": "19",
      "Nome": "Macaé"
    },
    {
      "Id": "65b11fe7c0095aa0cc394379",
      "IdCidade": "3628",
      "IdEstado": "19",
      "Nome": "Macuco"
    },
    {
      "Id": "65b11fe7c0095aa0cc39437a",
      "IdCidade": "3629",
      "IdEstado": "19",
      "Nome": "Magé"
    },
    {
      "Id": "65b11fe7c0095aa0cc39437b",
      "IdCidade": "3630",
      "IdEstado": "19",
      "Nome": "Mangaratiba"
    },
    {
      "Id": "65b11fe7c0095aa0cc39437c",
      "IdCidade": "3631",
      "IdEstado": "19",
      "Nome": "Maricá"
    },
    {
      "Id": "65b11fe7c0095aa0cc39437d",
      "IdCidade": "3632",
      "IdEstado": "19",
      "Nome": "Mendes"
    },
    {
      "Id": "65b11fe7c0095aa0cc39437e",
      "IdCidade": "3633",
      "IdEstado": "19",
      "Nome": "Mesquita"
    },
    {
      "Id": "65b11fe7c0095aa0cc39437f",
      "IdCidade": "3634",
      "IdEstado": "19",
      "Nome": "Miguel Pereira"
    },
    {
      "Id": "65b11fe7c0095aa0cc394380",
      "IdCidade": "3635",
      "IdEstado": "19",
      "Nome": "Miracema"
    },
    {
      "Id": "65b11fe7c0095aa0cc394381",
      "IdCidade": "3636",
      "IdEstado": "19",
      "Nome": "Nativade"
    },
    {
      "Id": "65b11fe7c0095aa0cc394382",
      "IdCidade": "3637",
      "IdEstado": "19",
      "Nome": "Nilópolis"
    },
    {
      "Id": "65b11fe7c0095aa0cc394383",
      "IdCidade": "3638",
      "IdEstado": "19",
      "Nome": "Niterói"
    },
    {
      "Id": "65b11fe7c0095aa0cc394384",
      "IdCidade": "3639",
      "IdEstado": "19",
      "Nome": "Nova Friburgo"
    },
    {
      "Id": "65b11fe7c0095aa0cc394385",
      "IdCidade": "3640",
      "IdEstado": "19",
      "Nome": "Nova Iguaçu"
    },
    {
      "Id": "65b11fe7c0095aa0cc394386",
      "IdCidade": "3641",
      "IdEstado": "19",
      "Nome": "Paracambi"
    },
    {
      "Id": "65b11fe7c0095aa0cc394387",
      "IdCidade": "3642",
      "IdEstado": "19",
      "Nome": "Paraíba do Sul"
    },
    {
      "Id": "65b11fe7c0095aa0cc394388",
      "IdCidade": "3643",
      "IdEstado": "19",
      "Nome": "Parati"
    },
    {
      "Id": "65b11fe7c0095aa0cc394389",
      "IdCidade": "3644",
      "IdEstado": "19",
      "Nome": "Paty do Alferes"
    },
    {
      "Id": "65b11fe7c0095aa0cc39438a",
      "IdCidade": "3645",
      "IdEstado": "19",
      "Nome": "Petrópolis"
    },
    {
      "Id": "65b11fe7c0095aa0cc39438b",
      "IdCidade": "3646",
      "IdEstado": "19",
      "Nome": "Pinheiral"
    },
    {
      "Id": "65b11fe7c0095aa0cc39438c",
      "IdCidade": "3647",
      "IdEstado": "19",
      "Nome": "Piraí"
    },
    {
      "Id": "65b11fe7c0095aa0cc39438d",
      "IdCidade": "3648",
      "IdEstado": "19",
      "Nome": "Porciúncula"
    },
    {
      "Id": "65b11fe7c0095aa0cc39438e",
      "IdCidade": "3649",
      "IdEstado": "19",
      "Nome": "Porto Real"
    },
    {
      "Id": "65b11fe7c0095aa0cc39438f",
      "IdCidade": "3650",
      "IdEstado": "19",
      "Nome": "Quatis"
    },
    {
      "Id": "65b11fe7c0095aa0cc394390",
      "IdCidade": "3651",
      "IdEstado": "19",
      "Nome": "Queimados"
    },
    {
      "Id": "65b11fe7c0095aa0cc394391",
      "IdCidade": "3652",
      "IdEstado": "19",
      "Nome": "Quissamã"
    },
    {
      "Id": "65b11fe7c0095aa0cc394392",
      "IdCidade": "3653",
      "IdEstado": "19",
      "Nome": "Resende"
    },
    {
      "Id": "65b11fe7c0095aa0cc394393",
      "IdCidade": "3654",
      "IdEstado": "19",
      "Nome": "Rio Bonito"
    },
    {
      "Id": "65b11fe7c0095aa0cc394394",
      "IdCidade": "3655",
      "IdEstado": "19",
      "Nome": "Rio Claro"
    },
    {
      "Id": "65b11fe7c0095aa0cc394395",
      "IdCidade": "3656",
      "IdEstado": "19",
      "Nome": "Rio das Flores"
    },
    {
      "Id": "65b11fe7c0095aa0cc394396",
      "IdCidade": "3657",
      "IdEstado": "19",
      "Nome": "Rio das Ostras"
    },
    {
      "Id": "65b11fe7c0095aa0cc394397",
      "IdCidade": "3658",
      "IdEstado": "19",
      "Nome": "Rio de Janeiro"
    },
    {
      "Id": "65b11fe7c0095aa0cc394398",
      "IdCidade": "3659",
      "IdEstado": "19",
      "Nome": "Santa Maria Madalena"
    },
    {
      "Id": "65b11fe7c0095aa0cc394399",
      "IdCidade": "3660",
      "IdEstado": "19",
      "Nome": "Santo Antônio de Pádua"
    },
    {
      "Id": "65b11fe7c0095aa0cc39439a",
      "IdCidade": "3661",
      "IdEstado": "19",
      "Nome": "São Fidélis"
    },
    {
      "Id": "65b11fe7c0095aa0cc39439b",
      "IdCidade": "3662",
      "IdEstado": "19",
      "Nome": "São Francisco de Itabapoana"
    },
    {
      "Id": "65b11fe7c0095aa0cc39439c",
      "IdCidade": "3663",
      "IdEstado": "19",
      "Nome": "São Gonçalo"
    },
    {
      "Id": "65b11fe7c0095aa0cc39439d",
      "IdCidade": "3664",
      "IdEstado": "19",
      "Nome": "São João da Barra"
    },
    {
      "Id": "65b11fe7c0095aa0cc39439e",
      "IdCidade": "3665",
      "IdEstado": "19",
      "Nome": "São João de Meriti"
    },
    {
      "Id": "65b11fe7c0095aa0cc39439f",
      "IdCidade": "3666",
      "IdEstado": "19",
      "Nome": "São José de Ubá"
    },
    {
      "Id": "65b11fe7c0095aa0cc3943a0",
      "IdCidade": "3667",
      "IdEstado": "19",
      "Nome": "São José do Vale do Rio Preto"
    },
    {
      "Id": "65b11fe7c0095aa0cc3943a1",
      "IdCidade": "3668",
      "IdEstado": "19",
      "Nome": "São Pedro da Aldeia"
    },
    {
      "Id": "65b11fe7c0095aa0cc3943a2",
      "IdCidade": "3669",
      "IdEstado": "19",
      "Nome": "São Sebastião do Alto"
    },
    {
      "Id": "65b11fe7c0095aa0cc3943a3",
      "IdCidade": "3670",
      "IdEstado": "19",
      "Nome": "Sapucaia"
    },
    {
      "Id": "65b11fe7c0095aa0cc3943a4",
      "IdCidade": "3671",
      "IdEstado": "19",
      "Nome": "Saquarema"
    },
    {
      "Id": "65b11fe7c0095aa0cc3943a5",
      "IdCidade": "3672",
      "IdEstado": "19",
      "Nome": "Seropédica"
    },
    {
      "Id": "65b11fe7c0095aa0cc3943a6",
      "IdCidade": "3673",
      "IdEstado": "19",
      "Nome": "Silva Jardim"
    },
    {
      "Id": "65b11fe7c0095aa0cc3943a7",
      "IdCidade": "3674",
      "IdEstado": "19",
      "Nome": "Sumidouro"
    },
    {
      "Id": "65b11fe7c0095aa0cc3943a8",
      "IdCidade": "3675",
      "IdEstado": "19",
      "Nome": "Tanguá"
    },
    {
      "Id": "65b11fe7c0095aa0cc3943a9",
      "IdCidade": "3676",
      "IdEstado": "19",
      "Nome": "Teresópolis"
    },
    {
      "Id": "65b11fe7c0095aa0cc3943aa",
      "IdCidade": "3677",
      "IdEstado": "19",
      "Nome": "Trajano de Morais"
    },
    {
      "Id": "65b11fe7c0095aa0cc3943ab",
      "IdCidade": "3678",
      "IdEstado": "19",
      "Nome": "Três Rios"
    },
    {
      "Id": "65b11fe7c0095aa0cc3943ac",
      "IdCidade": "3679",
      "IdEstado": "19",
      "Nome": "Valença"
    },
    {
      "Id": "65b11fe7c0095aa0cc3943ad",
      "IdCidade": "3680",
      "IdEstado": "19",
      "Nome": "Varre-Sai"
    },
    {
      "Id": "65b11fe7c0095aa0cc3943ae",
      "IdCidade": "3681",
      "IdEstado": "19",
      "Nome": "Vassouras"
    },
    {
      "Id": "65b11fe7c0095aa0cc3943af",
      "IdCidade": "3682",
      "IdEstado": "19",
      "Nome": "Volta Redonda"
    }
  ],
  listPais: [
    {
      "id": 1,
      "pais": "Portugal"
    },
    {
      "id": 2,
      "pais": "Albânia"
    },
    {
      "id": 3,
      "pais": "Alemanha"
    },
    {
      "id": 4,
      "pais": "Andorra"
    },
    {
      "id": 5,
      "pais": "Angola"
    },
    {
      "id": 6,
      "pais": "Antígua e Barbuda"
    },
    {
      "id": 7,
      "pais": "Argélia"
    },
    {
      "id": 8,
      "pais": "Argentina"
    },
    {
      "id": 9,
      "pais": "Arménia"
    },
    {
      "id": 10,
      "pais": "Austrália"
    },
    {
      "id": 11,
      "pais": "Áustria"
    },
    {
      "id": 12,
      "pais": "Azerbaijão"
    },
    {
      "id": 13,
      "pais": "Bahamas"
    },
    {
      "id": 14,
      "pais": "Bahrein"
    },
    {
      "id": 15,
      "pais": "Bangladesh"
    },
    {
      "id": 16,
      "pais": "Barbados"
    },
    {
      "id": 17,
      "pais": "Bélgica"
    },
    {
      "id": 18,
      "pais": "Belize"
    },
    {
      "id": 19,
      "pais": "Benim"
    },
    {
      "id": 20,
      "pais": "Bielorrússia"
    },
    {
      "id": 21,
      "pais": "Bolívia"
    },
    {
      "id": 22,
      "pais": "Bósnia e Herzegovina"
    },
    {
      "id": 23,
      "pais": "Brasil"
    },
    {
      "id": 24,
      "pais": "Brunei"
    },
    {
      "id": 25,
      "pais": "Bulgária"
    },
    {
      "id": 26,
      "pais": "Burkina Faso"
    },
    {
      "id": 27,
      "pais": "Burundi"
    },
    {
      "id": 28,
      "pais": "Butão"
    },
    {
      "id": 29,
      "pais": "Canadá"
    },
    {
      "id": 30,
      "pais": "Catar"
    },
    {
      "id": 31,
      "pais": "Chile"
    },
    {
      "id": 32,
      "pais": "Chipre"
    },
    {
      "id": 33,
      "pais": "Colômbia"
    },
    {
      "id": 34,
      "pais": "Coreia do Sul"
    },
    {
      "id": 35,
      "pais": "Croácia"
    },
    {
      "id": 36,
      "pais": "Cuba"
    },
    {
      "id": 37,
      "pais": "Dinamarca"
    },
    {
      "id": 38,
      "pais": "Egito"
    },
    {
      "id": 39,
      "pais": "Emirados Árabes Unidos"
    },
    {
      "id": 40,
      "pais": "Eslováquia"
    },
    {
      "id": 41,
      "pais": "Eslovênia"
    },
    {
      "id": 42,
      "pais": "Espanha"
    },
    {
      "id": 43,
      "pais": "Estados Unidos"
    },
    {
      "id": 44,
      "pais": "Estónia"
    },
    {
      "id": 45,
      "pais": "Etiópia"
    },
    {
      "id": 46,
      "pais": "Finlândia"
    },
    {
      "id": 47,
      "pais": "França"
    },
    {
      "id": 48,
      "pais": "Geórgia"
    },
    {
      "id": 49,
      "pais": "Grécia"
    },
    {
      "id": 50,
      "pais": "Guatemala"
    },
    {
      "id": 51,
      "pais": "Hong Kong"
    },
    {
      "id": 52,
      "pais": "Hungria"
    },
    {
      "id": 53,
      "pais": "Índia"
    },
    {
      "id": 54,
      "pais": "Irã"
    },
    {
      "id": 55,
      "pais": "Iraque"
    },
    {
      "id": 56,
      "pais": "Irlanda"
    },
    {
      "id": 57,
      "pais": "Islândia"
    },
    {
      "id": 58,
      "pais": "Israel"
    },
    {
      "id": 59,
      "pais": "Itália"
    },
    {
      "id": 60,
      "pais": "Jamaica"
    },
    {
      "id": 61,
      "pais": "Japão"
    },
    {
      "id": 62,
      "pais": "Kiribati"
    },
    {
      "id": 63,
      "pais": "Letônia"
    },
    {
      "id": 64,
      "pais": "Líbia"
    },
    {
      "id": 65,
      "pais": "Liechtenstein"
    },
    {
      "id": 66,
      "pais": "Lituânia"
    },
    {
      "id": 67,
      "pais": "Luxemburgo"
    },
    {
      "id": 68,
      "pais": "Macedónia"
    },
    {
      "id": 69,
      "pais": "Madagáscar"
    },
    {
      "id": 70,
      "pais": "Malásia"
    },
    {
      "id": 71,
      "pais": "Malta"
    },
    {
      "id": 72,
      "pais": "Marrocos"
    },
    {
      "id": 73,
      "pais": "México"
    },
    {
      "id": 74,
      "pais": "Moçambique"
    },
    {
      "id": 75,
      "pais": "Moldávia"
    },
    {
      "id": 76,
      "pais": "Montenegro"
    },
    {
      "id": 77,
      "pais": "Noruega"
    },
    {
      "id": 78,
      "pais": "Nova Zelândia"
    },
    {
      "id": 79,
      "pais": "Países Baixos"
    },
    {
      "id": 80,
      "pais": "Panamá"
    },
    {
      "id": 81,
      "pais": "Peru"
    },
    {
      "id": 82,
      "pais": "Porto Rico"
    },
    {
      "id": 83,
      "pais": "Afeganistão"
    },
    {
      "id": 84,
      "pais": "Quênia"
    },
    {
      "id": 85,
      "pais": "Quirguistão"
    },
    {
      "id": 86,
      "pais": "Reino Unido"
    },
    {
      "id": 87,
      "pais": "República Checa"
    },
    {
      "id": 88,
      "pais": "República Democrática do Congo"
    },
    {
      "id": 89,
      "pais": "República Popular da China"
    },
    {
      "id": 90,
      "pais": "Roménia"
    },
    {
      "id": 91,
      "pais": "Rússia"
    },
    {
      "id": 92,
      "pais": "São Cristóvão e Nevis"
    },
    {
      "id": 93,
      "pais": "São Marino"
    },
    {
      "id": 94,
      "pais": "São Tomé e Príncipe"
    },
    {
      "id": 95,
      "pais": "Sérvia"
    },
    {
      "id": 96,
      "pais": "Singapura"
    },
    {
      "id": 97,
      "pais": "Síria"
    },
    {
      "id": 98,
      "pais": "Suécia"
    },
    {
      "id": 99,
      "pais": "Tailândia"
    },
    {
      "id": 100,
      "pais": "Tunísia"
    },
    {
      "id": 101,
      "pais": "Turquia"
    },
    {
      "id": 102,
      "pais": "Tuvalu"
    },
    {
      "id": 103,
      "pais": "Ucrânia"
    },
    {
      "id": 104,
      "pais": "Uzbequistão"
    },
    {
      "id": 105,
      "pais": "Vanuatu"
    },
    {
      "id": 106,
      "pais": "Venezuela"
    },
    {
      "id": 107,
      "pais": "Vietname"
    },
    {
      "id": 108,
      "pais": "Zâmbia"
    },
    {
      "id": 109,
      "pais": "Zimbabwe"
    },
    {
      "id": 110,
      "pais": "África do Sul"
    },
    {
      "id": 111,
      "pais": "Arábia Saudita"
    },
    {
      "id": 112,
      "pais": "Botswana"
    },
    {
      "id": 113,
      "pais": "Cabo Verde"
    },
    {
      "id": 114,
      "pais": "Camarões"
    },
    {
      "id": 115,
      "pais": "Camboja"
    },
    {
      "id": 116,
      "pais": "Cazaquistão"
    },
    {
      "id": 117,
      "pais": "Chade"
    },
    {
      "id": 118,
      "pais": "Comores"
    },
    {
      "id": 119,
      "pais": "Coreia do Norte"
    },
    {
      "id": 120,
      "pais": "Costa do Marfim"
    },
    {
      "id": 121,
      "pais": "Djibouti"
    },
    {
      "id": 122,
      "pais": "Dominica"
    },
    {
      "id": 123,
      "pais": "El Salvador"
    },
    {
      "id": 124,
      "pais": "Equador"
    },
    {
      "id": 125,
      "pais": "Eritreia"
    },
    {
      "id": 126,
      "pais": "Estados Federados da Micronésia"
    },
    {
      "id": 127,
      "pais": "Fiji"
    },
    {
      "id": 128,
      "pais": "Filipinas"
    },
    {
      "id": 129,
      "pais": "Gabão"
    },
    {
      "id": 130,
      "pais": "Gâmbia"
    },
    {
      "id": 131,
      "pais": "Gana"
    },
    {
      "id": 132,
      "pais": "Granada"
    },
    {
      "id": 133,
      "pais": "Guiana"
    },
    {
      "id": 134,
      "pais": "Guiné"
    },
    {
      "id": 135,
      "pais": "Guiné Equatorial"
    },
    {
      "id": 136,
      "pais": "Guiné-Bissau"
    },
    {
      "id": 137,
      "pais": "Haiti"
    },
    {
      "id": 138,
      "pais": "Honduras"
    },
    {
      "id": 139,
      "pais": "Iémen/Iêmen"
    },
    {
      "id": 140,
      "pais": "Ilhas Marshall"
    },
    {
      "id": 141,
      "pais": "Ilhas Salomão"
    },
    {
      "id": 142,
      "pais": "Indonésia"
    },
    {
      "id": 143,
      "pais": "Jordânia"
    },
    {
      "id": 144,
      "pais": "Kuwait"
    },
    {
      "id": 145,
      "pais": "Laos"
    },
    {
      "id": 146,
      "pais": "Lesoto"
    },
    {
      "id": 147,
      "pais": "Líbano"
    },
    {
      "id": 148,
      "pais": "Libéria"
    },
    {
      "id": 149,
      "pais": "Malawi"
    },
    {
      "id": 150,
      "pais": "Maldivas"
    },
    {
      "id": 151,
      "pais": "Mali"
    },
    {
      "id": 152,
      "pais": "Maurícia"
    },
    {
      "id": 153,
      "pais": "Mauritânia"
    },
    {
      "id": 154,
      "pais": "Mónaco"
    },
    {
      "id": 155,
      "pais": "Mongólia"
    },
    {
      "id": 156,
      "pais": "Myanmar"
    },
    {
      "id": 157,
      "pais": "Namíbia"
    },
    {
      "id": 158,
      "pais": "Nauru"
    },
    {
      "id": 159,
      "pais": "Nepal"
    },
    {
      "id": 160,
      "pais": "Nicarágua"
    },
    {
      "id": 161,
      "pais": "Níger"
    },
    {
      "id": 162,
      "pais": "Nigéria"
    },
    {
      "id": 163,
      "pais": "Omã"
    },
    {
      "id": 164,
      "pais": "Palau"
    },
    {
      "id": 165,
      "pais": "Papua-Nova Guiné"
    },
    {
      "id": 166,
      "pais": "Paquistão"
    },
    {
      "id": 167,
      "pais": "Paraguai"
    },
    {
      "id": 168,
      "pais": "República Centro-Africana"
    },
    {
      "id": 169,
      "pais": "República do Congo"
    },
    {
      "id": 170,
      "pais": "República Dominicana"
    },
    {
      "id": 171,
      "pais": "Ruanda"
    },
    {
      "id": 172,
      "pais": "Samoa"
    },
    {
      "id": 173,
      "pais": "Santa Lúcia"
    },
    {
      "id": 174,
      "pais": "São Vicente e Granadinas"
    },
    {
      "id": 175,
      "pais": "Senegal"
    },
    {
      "id": 176,
      "pais": "Serra Leoa"
    },
    {
      "id": 177,
      "pais": "Seychelles"
    },
    {
      "id": 178,
      "pais": "Somália"
    },
    {
      "id": 179,
      "pais": "Sri Lanka"
    },
    {
      "id": 180,
      "pais": "Suazilândia"
    },
    {
      "id": 181,
      "pais": "Sudão"
    },
    {
      "id": 182,
      "pais": "Sudão do Sul"
    },
    {
      "id": 183,
      "pais": "Suíça"
    },
    {
      "id": 184,
      "pais": "Suriname"
    },
    {
      "id": 185,
      "pais": "Tajiquistão"
    },
    {
      "id": 186,
      "pais": "Tanzânia"
    },
    {
      "id": 187,
      "pais": "Timor-Leste"
    },
    {
      "id": 188,
      "pais": "Togo"
    },
    {
      "id": 189,
      "pais": "Tonga"
    },
    {
      "id": 190,
      "pais": "Trinidad e Tobago"
    },
    {
      "id": 191,
      "pais": "Turquemenistão"
    },
    {
      "id": 192,
      "pais": "Uganda"
    },
    {
      "id": 193,
      "pais": "Uruguai"
    },
    {
      "id": 194,
      "pais": "Vaticano"
    }
  ]
}

