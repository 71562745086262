import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { LocalStorage } from '../../_entidade/LocalStorage';
import { CrudService } from '../../_sevice/CrudService';
import { GlobalService } from '../../_sevice/GlobalService';

@Component({
  selector: 'app-listar-usuario',
  templateUrl: './listar-usuario.component.html',
  styleUrls: ['./listar-usuario.component.less']
})
export class ListarUsuarioComponent implements OnInit {

  listUsuario: Array<any> = new Array()

  constructor(
    public router: Router
    , public storage: LocalStorage
    , public service: CrudService
    , public gb: GlobalService
  )
  {
  }


  ngOnInit() {
    this.listarUsuario()

  }

  listarUsuario() {
    this.service.getObservable('/User').subscribe({
      next: (retorno) => {
        this.listUsuario = retorno 
      } 
    })
  }

}
