<div class="wrapper-item">

  <div class="wrapper-header-item">
    <h3 class="content-title">
      {{ item.produto.titulo }}
    </h3>
    <div class="wrapper-buttons">
      <div class="content-button">
        <button class="buttons-action"  (click)="editar.emit(item)">
          Editar
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/05a3523c983604fe0000ef79b0c38af35a7a81c7e6b32230af7c4a88344d432f?"
               class="img-11" />
        </button>
      </div>
      <div class="content-button">
        <button class="buttons-action" (click)="excluirItem.emit(item)">
          Excluir
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/dcdd700d816beff971594d71d60bdd298e4130c545dd466135231c4783571eb5?"
               class="img-12" />
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="w-100"></mat-divider>

  <div class="wrapper-info-cartorio" *ngFor="let cartorio of item.listCartorio">
    <div class="wrapper-topo">
      <div class="content-left">
        <span class="title-box">Cartório selecionado</span>
        <p class="text-info-box">
          {{ cartorio.cartorio }}
        </p>
      </div>
      <div class="content-right">
        <span class="title-box">Emolumento</span>
        <p class="text-info-box">{{ cartorio.valor | currency:'BRL' }}</p>
      </div>
    </div>
    <ng-container *ngIf="visibilidadeDetalhes">
      <div class="content-info-solicitante">
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Livro</h3>
            <p class="info-section">{{item.livro}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Folha</h3>
            <p class="info-section">{{item.folha}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Data Ato</h3>
            <p class="info-section">{{item.dataAto | date:'dd/MM/yyyy'}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Natureza do Ato</h3>
            <p class="info-section">{{item.natureza}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">CPF | CNPJ</h3>
            <p class="info-section">{{item.documento | mask:'000.000.000-00||00.000.000/0000-00'}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Nome da Busca</h3>
            <p class="info-section">{{item.nomeBusca}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Nome do Pai</h3>
            <p class="info-section">{{item.pai}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Nome da Mãe</h3>
            <p class="info-section">{{item.mae}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-8">
            <h3 class="title-section">Observacao</h3>
            <p class="info-section">{{item.observacao}}</p>
          </div>
        </div>
        
      </div>
    </ng-container>
  </div>
  <button class="view-more-details" (click)="visibilidadeDetalhes=!visibilidadeDetalhes">
    {{ visibilidadeDetalhes ? 'Ocultar detalhes' : 'Ver detalhes' }}
  </button>
</div>
