import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { debounce } from 'lodash';
import { LocalStorage } from 'src/app/_entidade/LocalStorage';
import { CrudService } from 'src/app/_sevice/CrudService';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../../_sevice/GlobalService';



@Component({
  selector: 'app-relatorio-cnr',
  templateUrl: './relatorio-cnr.component.html',
  styleUrls: ['./relatorio-cnr.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class RelatorioCNRComponent implements OnInit {

  lastMonthDate = new Date(new Date().setMonth(new Date().getMonth() - 1))
  currentDate = new Date()
  pedidos: any
  isBack = this.storage.localStorageGet('BackOffice')

  camposForm = this.formBuilder.group({
    dataInicial: [this.lastMonthDate],
    dataFinal: [this.currentDate],
    numeroPedido: [''],
    numeroAto: [''],
    tipoAto: ['']
  });


  constructor(
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public teste: TesteService,
    public gb: GlobalService,
    private route: Router,
    public service: CrudService
  ) {
    this.pesquisar = debounce(this.pesquisar, 900)

  }

  ngOnInit(): void {
    console.log('hhhh')
    this.pesquisar()

  }


  //alterarPagina(event: PageEvent) {
  //  const novaPagina = event.pageIndex + 1;
  //  const novoTamanhoPagina = event.pageSize;

  //  this.paginaAtual = novaPagina;
  //  this.tamanhoPagina = novoTamanhoPagina;

  //  this.pesquisar();
  //}


  download() {
    let endPoint = 'ValoresCNR/GetFile'

    const url = `${environment.http.API_BACK}${endPoint}`;
   
    this.gb.postObservableBLOBDirect(url, this.camposForm.value, "rel")
  }

  pesquisar() {
    //let objsend = this.camposForm.value
    //let cerpValid = this.isUUID(this.camposForm.get('cerp').value)
    //if (this.camposForm.get('cerp').value != '' && !cerpValid) {
    //  objsend.cerp = ''

    //}
    //objsend['pagina'] = this.paginaAtual
    //objsend['tamanhoPagina'] = this.tamanhoPagina





    let endPoint = 'ValoresCNR/Buscar'

    const url = `${environment.http.API_BACK}${endPoint}`;


    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    let data = this.camposForm.value
    if (data['numeroAto'] =='')
      data['numeroAto'] = null

    if (data['numeroPedido'] == '')
      data['numeroPedido'] = null

    this.service.postObservableDirect(url, data).subscribe({
      next: async (response) => {
        console.log(response)
        this.pedidos = response;

      }, error: (err) => {

        //this.route.navigate(['/login/entrar?logoff=1'])
      }
    });




  }



}

