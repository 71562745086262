<div [style.background]="getMobile() ? 'url(/assets/' + conteudo.srcImageMobile + ')' : 'url(/assets/' + conteudo.srcImage + ')'"
     [style.background-size]="'cover'"
     [style.background-position-x]="conteudo?.componente === 'fullbanner-v1' && getMobile() ? '29rem' : '' "
     [style.position]="conteudo.position"
     [style.z-index]="conteudo?.componente === 'fullbanner-v1' && getMobile() ? '1' : conteudo.zindex "
     [class]="conteudo?.class"
     [style.margin-bottom]="conteudo?.componente !== 'fullbanner-v1' ? '2rem' : '1rem' "
     >

    <div class="container ng-star-inserted camada">

      <div class="row">

        <div class="col-12 col-sm-12 col-md-12 col-lg-12">

          <mat-icon matPrefix class="my-icon setas setaesquerda" *ngIf="conteudo['carrossel']">arrow_back_ios</mat-icon>
          <mat-icon matPrefix class="my-icon setas setadireita" *ngIf="conteudo['carrossel']">arrow_forward_ios</mat-icon>

          <div class="carousel-cell" [style.min-height]="conteudo?.height ?'': '500px'" *ngIf="index==0">

            <div class="enegocie-conteudo-hero">

              <div id="principal"
                   [class]="(conteudo.class != null) ? 'enegocie-hero-content ' + conteudo.class : 'enegocie-hero-content'"
                   [style.height]="conteudo?.height ? conteudo?.height: ''">

                <div *ngIf="conteudo.contentText != false"
                     class="content-banner-text "
                     [class]="conteudo.textClass">

                  <h1 class="fw-bold mt-4 text-sm-center text-md-start" [innerHTML]="conteudo.h1" [class]="conteudo?.txtClass"></h1>

                  <p class="lh-2 text-md-start" [innerHTML]="conteudo.p" [class]="conteudo?.txtClass">

                  </p>

                  <form class="d-flex d-md-flex d-lg-inline-flex flex-wrap flex-sm-nowrap " (ngSubmit)="consultar(conteudo?.urlRedirectBusca)"
                        [formGroup]="formBusca" *ngIf="conteudo.form">

                    <mat-form-field appearance="outline" class="w-100 my-2" *ngIf="!conteudo?.tipoForm">
                      <mat-label>{{conteudo.labelForm}}</mat-label>
                      <input matInput type="text" class="col-lg-6" formControlName="codigo" [placeholder]="conteudo.labelForm">
                      <mat-error *ngIf="f.codigo.errors?.['required']">
                        Preenchimento obrigatório
                      </mat-error>
                      <mat-error *ngIf="f.codigo.errors?.['retError']">
                        {{retError}}
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-100 my-2 " *ngIf="conteudo?.tipoForm == 'select'">

                      <mat-select id="codigo" formControlName="codigo" [placeholder]="conteudo.labelForm">
                        <mat-option *ngFor="let item of conteudo.listaOpcoes" [value]="item.Id">
                          {{item.Descricao}}
                        </mat-option>
                      </mat-select>

                      <mat-error *ngIf="f.codigo.errors">
                        Campo obrigatório
                      </mat-error>
                    </mat-form-field>

                    <button type="submit" class="d-flex flex-nowrap align-items-center
                      justify-content-center btn-verify-code ms-sm-3 mt-sm-2" aria-label="Consultar CPF">
                      <mat-icon matPrefix class="my-icon">search</mat-icon>
                      <ng-container *ngIf="conteudo?.txtBtn">
                        {{conteudo.txtBtn}}
                      </ng-container>
                      <ng-container *ngIf="!conteudo?.txtBtn">
                        Verificar
                      </ng-container>

                    </button>
                  </form>

                  <!-- style=" width: 800px; font-size:20px" -->
                  <!-- *ngIf="conteudo.formSearch && !deviceService.isMobile() "-->

                  <div class="wrapper-search" *ngIf="conteudo?.componente === 'fullbanner-v1' "
                       [style.width]="conteudo?.componente === 'fullbanner-v1' && !deviceService.isMobile() ? '800px' : ''"
                       [style.fontSize]="conteudo?.componente === 'fullbanner-v1' && !deviceService.isMobile() ? '20px' : ''">

                       <form [formGroup]="formBusca">

                        <app-searchbar  (click)="!deviceService.isMobile() ?false:abrirModalBusca()" [conteudo]="conteudo" [class]="'fullbanner'"></app-searchbar>

                      </form>

                  </div>

                  <a *ngIf="!conteudo?.form && conteudo.btn && conteudo.btnTipo == 'aLink'"
                     [routerLink]="conteudo.btnLink">
                   {{conteudo.txtBtn}}
                  </a>

                  <div class="w-100 text-lg-start text-sm-end ">

                    <button id="botaoKit"
                            type="button"
                            class="flex-nowrap btnBranco ms-sm-3 mt-sm-2 text-center"
                            *ngIf="!conteudo?.form && conteudo.btn && conteudo.btnTipo == 'button'"
                            (click)="navegar(conteudo.btnLink)"
                            [style]="!deviceService.isMobile() ? 'width: 452px;height: 82.68px;font-size: 24.8px;': ''">
                      <mat-icon matPrefix class="my-icon" *ngIf="conteudo?.iconBtn">{{conteudo?.iconBtn}}</mat-icon>

                      {{conteudo.txtBtn}}
                    </button>

                  </div>

                </div>

                <!--<img loading="lazy" *ngIf="conteudo.srcImage"
                      [src]="environment.assetsDir  + conteudo.srcImage"
                      alt="e-desenrola" class="img-desk d-none d-lg-block img-fluid">-->

              </div>

            </div>

          </div>
          <div class="carousel-cell" *ngIf="index==1">
            dd
          </div>
        </div>
      </div>
    </div>
</div>



