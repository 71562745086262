import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CrudService } from "src/app/_sevice/CrudService";
import { CalendarDay } from "./calendarioDia.model";

@Component({
    selector: 'app-agendamentos-zoom-teste',
    templateUrl: './agendamentos-zoom-teste.component.html',
    styleUrls: ['./agendamentos-zoom-teste.component.less']
})
export class AgendamentosZoomTesteComponent implements OnInit {
    public calendar: CalendarDay[] = [];
    public monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    public displayMonth: string;
    private monthIndex: number = 0;

    constructor(private service: CrudService, private router: Router) { }

    ngOnInit(): void {
        this.generateCalendarDays(this.monthIndex);
    }

    private generateCalendarDays(monthIndex: number): void {
        // we reset our calendar
        this.calendar = [];

        // we set the date
        let day: Date = new Date(new Date().setMonth(new Date().getMonth() + monthIndex));

        // set the dispaly month for UI
        this.displayMonth = this.monthNames[day.getMonth()];

        let startingDateOfCalendar = this.getStartDateForCalendar(day);

        let dateToAdd = startingDateOfCalendar;

        for (var i = 0; i < 42; i++) {
            this.calendar.push(new CalendarDay(new Date(dateToAdd)));
            dateToAdd = new Date(dateToAdd.setDate(dateToAdd.getDate() + 1));
        }
    }

    private getStartDateForCalendar(selectedDate: Date) {
        // for the day we selected let's get the previous month last day
        let lastDayOfPreviousMonth = new Date(selectedDate.setDate(0));

        // start by setting the starting date of the calendar same as the last day of previous month
        let startingDateOfCalendar: Date = lastDayOfPreviousMonth;

        // but since we actually want to find the last Monday of previous month
        // we will start going back in days intil we encounter our last Monday of previous month
        if (startingDateOfCalendar.getDay() != 1) {
            do {
                startingDateOfCalendar = new Date(startingDateOfCalendar.setDate(startingDateOfCalendar.getDate() - 1));
            } while (startingDateOfCalendar.getDay() != 1);
        }

        return startingDateOfCalendar;
    }

    public increaseMonth() {
        this.monthIndex++;
        this.generateCalendarDays(this.monthIndex);
    }

    public decreaseMonth() {
        this.monthIndex--
        this.generateCalendarDays(this.monthIndex);
    }

    public setCurrentMonth() {
        this.monthIndex = 0;
        this.generateCalendarDays(this.monthIndex);
    }
}
