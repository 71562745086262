import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-meusPedidosOrderDetails-3',
  templateUrl: './meusPedidosOrderDetail3.component.html',
  styleUrls: ['./../_meus-pedidos.component.scss']
})
export class MeusPedidosOrderDetails3Component  {
  @Input() compra: any

  public visibilidadeDetalhes: boolean=false;
  
  @Output() updatePedido = new EventEmitter<any>();

  cancelarEdicao(compra:any) {
    compra.editar = false;
  }

  salvarItem(itemPedido: any) {
    //console.log('chegou')
    this.updatePedido.emit(itemPedido)
  }
}
