import { Component, OnInit, Input, ElementRef, ViewChild, HostListener  } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
import { environment } from '../../../../environments/environment';
import { globalPage } from '../../../_configPage/globalPage';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-card-sequencial-v1',
  templateUrl: './cardSequencial.component.html',
  styleUrls: ['./cardSequencial.component.scss']
})
export class CardSequencialV1Component implements OnInit {
  // Dentro da classe do componente
  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.itemExpandidoIndex = -1; // Isso fecha todos os submenus ativos se clicado fora
    }
  }

  @Input() conteudo: any;
  @ViewChild('servicesCategories') servicesCategories!: ElementRef;

  servicesCategoriesHeight: number = 0;

  constructor(
    public router: Router,
    private elementRef: ElementRef
    , public deviceService: DeviceDetectorService
    , public storage: LocalStorage
    , public service: CrudService) {

  }

  estadoSubcategorias: { [key: string]: boolean } = {};

  itemExpandidoIndex: number = -1;

  // Método para alternar a exibição das subcategorias para um item específico
  toggleSubcategorias(index: number, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    if (this.itemExpandidoIndex === index) {
      // Se o mesmo item foi clicado novamente, oculte as subcategorias
      this.itemExpandidoIndex = -1;
    } else {
      // Mostrar as subcategorias para o item clicado
      this.itemExpandidoIndex = index;
    }
  }

  // Método para verificar se as subcategorias de um item específico devem ser exibidas
  isSubcategoriasVisiveis(index: number): boolean {
    return this.itemExpandidoIndex === index;
  }

  menuItens: any[] = [];
  showSubmenuIndex: number | null = null;

  ngAfterViewInit() {
    this.calculateServicesCategoriesHeight();
  }

  private calculateServicesCategoriesHeight() {
    const servicesCategoriesElement = this.elementRef.nativeElement.querySelector('.services-categories');
    if (servicesCategoriesElement) {
      this.servicesCategoriesHeight = servicesCategoriesElement.offsetHeight;
    }
  }


  setActiveSubmenu(index: number) {
    this.showSubmenuIndex = index;
  }
  solicitarNovo(item: any) {
    //console.log(item)
    this.storage.localStorageSet("item_atual", item)
    //this.router.navigate(['/pedido'+item.aLink])


  }
  clearActiveSubmenu() {
    this.showSubmenuIndex = null;
  }


  ngOnInit(): void {
    let gbpg = new globalPage()
    if ('Categorias' in gbpg && this.conteudo.urlItens) {
      this.conteudo.itens = environment.production ? gbpg.Categorias : null

      if (this.conteudo.urlItens) {
        this.getItens(this.conteudo.urlItens)
      }
    }

  }

  getItens(url: any) {
    if (url.indexOf('http') >-1) {
      this.service.getObservableDirect(url).subscribe({
        next: async (response) => {

          if (response != null) {

            this.conteudo.itens = response
          }
        }, error: (err) => {

          console.error(err);
        }
      });
    } else {
      this.service.getObservable(url).subscribe({
        next: async (response) => {

          if (response != null) {

            this.conteudo.itens = response
          }
        }, error: (err) => {

          console.error(err);
        }
      });
    }

  }

  handleSubmenuClick(event: MouseEvent) {
    // Verifique se o clique ocorreu dentro do submenu
    const isClickedInsideSubmenu = this.servicesCategories.nativeElement.contains(event.target as Node);

    // Verifique se o clique ocorreu em um elemento de texto
    const isClickedOnText = (event.target as HTMLElement).nodeName === 'P' || (event.target as HTMLElement).nodeName === 'A';

    // Se o clique ocorreu dentro do submenu ou em um elemento de texto, não feche o submenu
    if (isClickedInsideSubmenu || isClickedOnText) {
      return;
    }

    // Caso contrário, feche o submenu
    this.itemExpandidoIndex = -1;
  }

}
