import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
//SocialLogin
import { InputService } from '../../core/utils/inputValidation';
import { LocalStorage } from '../../_entidade/LocalStorage';
import { CrudService } from '../../_sevice/CrudService';
import { GlobalService } from '../../_sevice/GlobalService';
import { PaginaService } from '../../_sevice/paginaService';
//Services
import { debounce } from 'lodash';
import { TesteService } from '../../_sevice/teste.service';
import { environment } from 'src/environments/environment';

export class DateValidators {
  static dateLessThan(prop: string, dataComparar: string, dataInicio: string, dados: any, validatorField: { [key: string]: boolean }): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (dados['default'][prop][dataComparar] && dados['default'][prop][dataInicio]) {

        const date1 = new Date(dados['default'][prop][dataInicio]);
        const date2 = new Date(dados['default'][prop][dataComparar]);

        if ((date1 !== null && date2 !== null) && date2 > date1) {
          return validatorField;
        } else {
          return null;

        }
      } else {
        return null;
      }
    };
  }
}

@Component({
  selector: 'app-generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class GenericFormComponent implements OnInit, OnChanges {
  modoTeste: boolean = false;
  @Input() editar: boolean = false;
  @Input() conteudoPagina: any;
  @Input() entidadeCartao: any

  @Input() filtro: any = false
  showModalRemoveCard: any
  public ptBr = {
    firstDayOfWeek: 0,
    dayNames: ["Domingo", "Segunda", "Terça-feira", "Quarta-feita", "Quinta-feira", "Sexta-feira", "Sábado"],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    dayNamesMin: ["Do", "Sg", "Te", "Qa", "Qi", "Sx", "Sa"],
    monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "December"],
    monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    today: 'Hoje',
    clear: 'Limpar',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Wk'
  };
  //defaultChoice: any = 2
  public invalidDates: Date[] = []
  public defaultDate: Date = new Date()
  public module: any
  public type: any
  public action: any
  public rtarquitetura: any
  public rotaBase: any
  //public dados
  public loading: boolean = false;
  public campos: any
  public forms: any
  public eventos: any
  public tmpEventos: Array<any> = new Array();
  public value: Date;
  public results: any[] = []
  _tolltipBotaoAcaoSalvar: any
  modoEdicaoCartao: any
  //public inputs
  testEvents: any[] = []
  currentStep = 1;
  @Input() dados: any
  @Input() tipoForm: any
  add: boolean = true;
  novoInput: any = {
    "tipo": "",
    "prop": "",
    "placeholder": "",
    "label": "",
    "tamanhoMaximo": "",
    "largura": 1,
    "required": false,
    index: 0,
  };
  novoInputCopy: any = {
    "tipo": "",
    "prop": "",
    "placeholder": "",
    "label": "",
    "tamanhoMaximo": "",
    "largura": 1,
    "required": false,
    index: 0,
  };
  @Input() inputs: any
  @Input() configTema: any
  @Output() atualizarConteudo = new EventEmitter<string>();
  @Output() novaEntidade = new EventEmitter<any>();
  @Output() recarregarGrid = new EventEmitter<any>();

  celularMask = ['(', /[1-9]/, /\d/, ')', ' ', '9', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  cepMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

  formDinamico!: FormGroup;

  edicao: boolean = false
  safeHtmlBoleto: SafeHtml;
  safeUrlArquivo: SafeResourceUrl;
  showModalCard: any
  public showEtapa: boolean = false;
  public avancoDesabilitado: boolean = false;

  constructor(
    public crudService: CrudService
    , public router: Router
    , private activatedRoute: ActivatedRoute
    , private testeService: TesteService
    , public globalService: GlobalService
    , public sanitizer: DomSanitizer
    , public httpBoleto: HttpClient
    , public paginaService: PaginaService
    , public ls: LocalStorage
    , public inp: InputService
  ) {
    this.edicao = localStorage.getItem('modoEdicao') != null
    this.pesquisar = debounce(this.pesquisar, 500)
    this.activatedRoute.params.subscribe((params) => {
      this.rtarquitetura = params['rtarquitetura']
      this.module = params['module']
      this.type = params['type']
      this.action = params['action']
      this.rotaBase = '/' + this.rtarquitetura + '/' + this.module + '/' + this.type + '/'
    })

    //console.log(this.inputs)
  }

  ngOnChanges() {

    this.getFormConfig();

  }
  removerCartao(iten: any) {
  }
  addCartao(dados: any, form: any, inputs: any, input: any) {
  }
  pesquisar() {
    this.paginaService.validarPesquisa(this.conteudoPagina)
    this.recarregarGrid.emit()

  }
  bindDataCard(cartao: any) {

  }
  formataNumCartao(cartao: any) {

  }
  formataBandeira(cartao: any) {

  }
  alterarImagemPerfil(event: any) {

  }
  isLoginSocial() { }
  obterImagemPerfil() { }
  getPersonalizedSubTitle(iten: any) { }
  limpaFormulario() {
    this.formDinamico.reset();
  }
  toFormGroup() {
    const formPrincipal: any = {};
    const group: any = {};
    const subGroup: any = {};

    if (this.inputs) {
      for (var i = 0; i < this.inputs.forms.length; i++) {
        this.inputs.forms[i].campos.forEach((campo: any) => {
         // //console.log('df', campo)
          if (campo.urlBusca) {
            this.crudService.getObservable(campo.urlBusca).subscribe(
              resposta => {
                if (resposta) {
                  campo["data"] = resposta
                }
              })
          }

          if (!campo.subProp) {


            if (campo.prop)
              group[campo.prop] = campo.required ? new FormControl(campo.value || '', Validators.required) : new FormControl(campo.value || '');
            else if (campo.tipo == 'intervaloDataQuebrada') {
              group['DiaInicio'] = new FormControl(campo.value || '', Validators.required)
              group['MesInicio'] = new FormControl(campo.value || '', Validators.required)
              group['AnoInicio'] = new FormControl(campo.value || '');
              group['DiaFim'] = new FormControl(campo.value || '');
              group['MesFim'] = new FormControl(campo.value || '');
              group['AnoFim'] = new FormControl(campo.value || '');
              group['DiaUtil'] = new FormControl(campo.value || '');

            };
          } else {
            if (campo.tipo != 'date') {

              subGroup[campo.subProp] = campo.required ? new FormControl('', Validators.required) : new FormControl(campo.subProp.value || '');
            }
            else {
              //ajustar
              //subGroup[campo.subProp] = new FormControl('', [Validators.required, Validators.compose([
              //  DateValidators.dateLessThan(campo.prop, campo.subProp, campo.compareDate, this.dados, { 'loaddate': true })
              //])]);

            }
          }

        });
      }
      for (var i = 0; i < this.inputs.forms.length; i++) {
        this.inputs.forms[i].campos.forEach((campo: any) => {
          if (campo.subProp) {
            if (!group[campo.prop])
              group[campo.prop] = new FormGroup(subGroup)
          }

        });
      }
      formPrincipal['default'] = new FormGroup(group)
    }
    return new FormGroup(formPrincipal);
  }

  ngOnInit() {
    //console.log('dfdfdfd')

  }

  onSubmit(): void {

    this.formDinamico.markAllAsTouched();

    if (this.formDinamico.invalid) {
      return;
    } else {
      if (this.tipoForm == "busca")
        this.pesquisar()
      else
        this.salvar();
    }
  }
  openDialog() {
    this.add = true;
    this.novoInput = { ...this.novoInputCopy }

      (<HTMLInputElement>document.getElementById("btnModalExcluirEntidade")).click();

  }
  display = ''
  openModal() {
    this.display = "block";
  }
  onCloseHandled() {
    this.display = "none";
  }
  public camposNovos: Array<any> = new Array();

  editarInputProp(input: any) {
    this.novoInput = input;
    this.add = false;
    this.openModal();
  }
  alterNovoCampo() {
    this.salvarConfiguracao()
    this.onCloseHandled()

  }
  addNovoCampo() {
    this.novoInput.index = this.conteudoPagina.inputsFiltro.forms[0].campos.length;
    var pesquisar: any;
    this.camposNovos = new Array();
    if (this.filtro) {
      for (var i = 0; i < this.conteudoPagina.inputsFiltro.forms[0].campos.length; i++) {
        if (this.conteudoPagina.inputsFiltro.forms[0].campos[i].tipo != 'pesquisar') {
          this.camposNovos.push(this.conteudoPagina.inputsFiltro.forms[0].campos[i])
        } else {
          pesquisar = this.conteudoPagina.inputsFiltro.forms[0].campos[i];
        }
      }
      this.camposNovos.push({ ...this.novoInput })
      this.novoInput = { ...this.novoInputCopy }
      if (pesquisar != undefined)
        this.camposNovos.push(pesquisar);

      this.conteudoPagina.inputsFiltro.forms[0].campos = this.camposNovos
    } else {
      for (var i = 0; i < this.conteudoPagina.inputs.forms[0].campos.length; i++) {
        if (this.conteudoPagina.inputs.forms[0].campos[i].tipo != 'pesquisar') {
          this.camposNovos.push(this.conteudoPagina.inputs.forms[0].campos[i])
        } else {
          pesquisar = this.conteudoPagina.inputs.forms[0].campos[i];
        }
      }
      this.camposNovos.push({ ...this.novoInput })
      this.novoInput = { ...this.novoInputCopy }
      if (pesquisar != undefined)
        this.camposNovos.push(pesquisar);

      this.conteudoPagina.inputs.forms[0].campos = this.camposNovos

      this.getFormConfig();

      this.forms = this.inputs.forms;
    }




    this.salvarConfiguracao();

    this.onCloseHandled();

  }


  enviarFormUnico(iten: any) {



    this.crudService.postObservable(this.dados['default'], '/cartorio/CadastrarCartorio').subscribe(
      resposta => {
        if (resposta) {
          this.conteudoPagina["concluido"] = true
          this.formDinamico.reset()
        }
      })
  }

  salvarConfiguracao() {

    let pg = this.ls.localStorageGet("pgInfo")
    pg.JsonPagina = JSON.stringify(this.conteudoPagina)

    this.ls.localStorageSet("pgInfo", pg)

    let pgInfo = this.ls.localStorageGet("pgInfo")
    this.crudService.putObservableDirect(environment.http.API_SISTEMA + 'pagina', pgInfo).subscribe(
      resposta => {
        this.ls.localStorageSet('pgCrudEdit', this.conteudoPagina);
        // window.location.reload()
      })


  }


  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // //console.log('asdf', event.key, event.ctrlKey, event.shiftKey, environment.production)
    if (event.code == 'KeyK' && event.ctrlKey && event.shiftKey && !environment.production) {
      this.testeService.setDadosObjeto(this.dados);

    }
  }

  preencherDadosTeste() {

    this.testeService.setDadosObjeto(this.dados);
  }

  showItem(input: any) {
    return true
  }
  removerCampoFiltro() {

    if (this.filtro) {
      var idx = this.conteudoPagina.inputsFiltro.forms[0].campos.findIndex((p: any) => p.prop == this.novoInput.prop)

      this.conteudoPagina.inputsFiltro.forms[0].campos.splice(idx, 1)

    } else {
      var idx = this.conteudoPagina.inputs.forms[0].campos.findIndex((p: any) => p.prop == this.novoInput.prop)

      this.conteudoPagina.inputs.forms[0].campos.splice(idx, 1)
    }
    this.onCloseHandled();

  }
  // Função que obtém a configuração do formulário e os eventos pré-configurados nele
  getFormConfig() {

    this.formDinamico = this.toFormGroup();

    if (this.inputs) {
      this.forms = this.inputs.forms;
    }
  }

  getItenForm(iten: any) {
    return this.formDinamico.get(iten)
  }

  //Inicializa o formulário, preeenche os valores dos models e faz carregamentos preliminares
  loadForm() {

    /* Filtrar os inputs do tipo autocomplete e associar os valores globais a eles */

    for (let i = 0; i < this.forms.length; i++) {
      let formLocal = this.forms[i].campos.filter((x: any) => x.tipo == 'autocomplete')
      let formLocalOpcoes;

      //Associando valores obtidos da API ao ngModel de cada input

      for (let j = 0; j < formLocal.length; j++) {
        formLocal[j].entidade.Id = this.dados['default'][formLocal[j].propIdForm]
        let nvlprop = formLocal[j].prop.split(".");
        if (nvlprop.length == 1) {
          formLocal[j].entidade[formLocal[j].propSelectValue] = this.dados['default'][formLocal[j].prop]
        } else if (nvlprop.length == 2) {
          formLocal[j].entidade[formLocal[j].propSelectValue] = this.dados['default'][nvlprop[0]][nvlprop[1]]
        } else if (nvlprop.length == 3) {
          formLocal[j].entidade[formLocal[j].propSelectValue] = this.dados['default'][nvlprop[0]][nvlprop[1]][nvlprop[2]]
        }
      }

      //Verificando a  existência de um formulário de opções e fazendo associação dos valores ao ngModel

      if (this.forms[i].campos[0].opcoes) {
        formLocalOpcoes = this.forms[i].campos[0].campos.filter((x: any) => x.tipo == 'autocomplete')

        for (let k = 0; k < formLocalOpcoes.length; k++) {
          formLocalOpcoes[k].entidade.Id = this.dados['default'][formLocalOpcoes[k].propIdForm]

          let nvlprop = formLocalOpcoes[k].prop.split(".");
          if (nvlprop.length == 1) {
            formLocalOpcoes[k].entidade[formLocalOpcoes[k].propSelectValue] = this.dados['default'][formLocalOpcoes[k].prop]
          } else if (nvlprop.length == 2) {
            formLocalOpcoes[k].entidade[formLocalOpcoes[k].propSelectValue] = this.dados['default'][nvlprop[0]][nvlprop[1]]
          } else if (nvlprop.length == 3) {
            formLocalOpcoes[k].entidade[formLocalOpcoes[k].propSelectValue] = this.dados['default'][nvlprop[0]][nvlprop[1]][nvlprop[2]]
          }
        }
      }

    }
  }
  mudarPosicao(soma: any) {
    var idx = this.conteudoPagina.inputsFiltro.forms[0].campos.findIndex((p: any) => p.prop == this.novoInput.prop)

    var posi = idx + soma;

    if ((this.conteudoPagina.dadosGrid.length - 1) > posi && posi >= 0) {

      var antigo = this.conteudoPagina.inputsFiltro.forms[0].campos[posi];
      var campoatual = this.conteudoPagina.inputsFiltro.forms[0].campos[idx]
      this.conteudoPagina.inputsFiltro.forms[0].campos[posi] = campoatual;
      this.conteudoPagina.inputsFiltro.forms[0].campos[idx] = antigo;
    }

  }
  inputValidate(value: any, input: any) {

    let validate

    if (input.subProp) {
      //Verifica se a exibição do input no form está condicionada a uma propriedade nos dados
      if (input.ngIf && this.dados['default'][input.ngIfPropValor] && (this.dados['default'][input.ngIfPropValor] != input.ngIfEscolhaValor)) {
        return null
      }
      if (!input.confirmaSenha) {
        validate = this.inp.inputValidation(value, { type: input.inputType }, input.placeholder, input.subProp);
      } else {
        validate = this.inp.inputValidation(value, { type: input.inputType, firstInputMatch: this.dados['default'][input.prop][input.propSenhaConfirma], secondInputMatch: value }, input.placeholder, input.subProp);
      }

    } else {

      if (input.ngIf && this.dados['default'][input.ngIfPropValor] && (this.dados['default'][input.ngIfPropValor] != input.ngIfEscolhaValor)) {

        return null

      }

      validate = this.inp.inputValidation(value, { type: input.inputType }, input.placeholder, input.prop);

    }

    input.error = validate;
    input.message = validate;
    return validate;
  }

  validateForwardButton(inputs: any) {
    return inputs.some((input: any) => {
      if (input.obrigatorio) {
        if (input.subProp) {
          return this.inp.inputValidation(this.dados['default'][input.prop][input.subProp], { type: input.inputType }, input.placeholder, input.subProp)
        } else {

          return this.inp.inputValidation(this.dados['default'][input.prop], { type: input.inputType }, input.placeholder, input.prop)
        }
      } else {
        return true
      }
    });
  }

  changeInputValue(event: any, input: any): void {
    if (event) {

      if (input.obrigatorio) {
        this.inputValidate(event, input)

      }


      if (input.completeGlobalPropItens) {
        this.dados['default'][input.prop][input.completeGlobalPropItens] = this.dados['default'][input.completeGlobalPropItens]

      }


      let nextPropInput: any;

      if (input.impactoChange) {
        var arrimpacto = input.impactoChange.split('|')
        nextPropInput = arrimpacto[0];
        for (var i = 0; i < arrimpacto.length; i++) {
          this.dados['default'][arrimpacto[i]] = "";
        }
      }

      if (nextPropInput) {
        let input = this.inputs.forms[0].campos.find((i: any) => i.prop == nextPropInput)
        if (input) {
          this.autoCompleteSearchEvent("", input, input.condicaoType)
        } else {

        }
      }

      if (input.tipo == "checkboxBoolean") {

        if (event.target.checked) {
          if (input.subProp)
            this.dados['default'][input.prop][input.subProp] = 'S';
          else
            this.dados['default'][input.prop] = 'S';
        }
        else {
          if (input.subProp)
            this.dados['default'][input.prop][input.subProp] = 'N';
          else
            this.dados['default'][input.prop] = 'N';
        }

        //Desabilita avanço ou salvamento do form, caso um checkbox não esteja checkado
        if ((input.desabilitaAvanco && this.dados['default'][input.prop] == 'N') || (input.desabilitaAvanco && this.dados['default'][input.prop][input.subProp] == 'N')) {
          this.avancoDesabilitado = true;
          this.ls.localStorageSet('avancoDesabilitado', true);

        } else if ((input.desabilitaAvanco && this.dados['default'][input.prop] == 'S') || (input.desabilitaAvanco && this.dados['default'][input.prop][input.subProp] == 'S')) {

          this.avancoDesabilitado = false;
          this.ls.localStorageRemove('avancoDesabilitado');
        }

      }
      else if (input.tipo == "checkbox") {

        if ((<HTMLInputElement>document.getElementById(input.prop)).checked) {

          if (input.subProp)
            this.dados['default'][input.prop][input.subProp] = 1;
          else
            this.dados['default'][input.prop] = 1;
        }
        else {

          if (input.subProp)
            this.dados['default'][input.prop][input.subProp] = 0;
          else
            this.dados['default'][input.prop] = 0;
        }

        //Desabilita avanço ou salvamento do form, caso um checkbox não esteja checkado
        if ((input.desabilitaAvanco && this.dados['default'][input.prop] == 'N') || (input.desabilitaAvanco && this.dados['default'][input.prop][input.subProp] == 'N')) {
          this.avancoDesabilitado = true;
          this.ls.localStorageSet('avancoDesabilitado', true);

        } else if ((input.desabilitaAvanco && this.dados['default'][input.prop] == 'S') || (input.desabilitaAvanco && this.dados['default'][input.prop][input.subProp] == 'S')) {

          this.avancoDesabilitado = false;
          this.ls.localStorageRemove('avancoDesabilitado');
        }

      } else {


        if (input.subProp) {
          if (input.tipo == 'toggle') {
            this.dados['default'][input.prop][input.subProp] = event.checked
          }
          else if (input.tipo == 'texto') {
            this.dados['default'][input.prop][input.subProp] = event.target.value;

          } else {
            this.dados['default'][input.prop][input.subProp] = event.value;

          }
        } else {

          if (input.tipo == 'toggle') {

            this.dados['default'][input.prop] = event.checked

          }
          else if (input.tipo == 'texto') {
            this.dados['default'][input.prop] = event.target.value;

          } else {
            this.dados['default'][input.prop] = event.value;

          }
        }

      }

      if (input.impactoChangeNgIf) {
        var itens = input.impactoChangeNgIf.split('|')
        for (var i = 0; i < itens.length; i++) {

        }
      }

      if (this.filtro)
        this.pesquisar()
    }
  }

  checkDisable(input: any) {

    let retorno = false;

    return retorno;
  }

  checkDisabledInput(input: any) {

    return false
  }

  completeCallback() {

  }

  failurCallback() {

  }

  validarClick(): Promise<any> {
    var inputs = this.inputs.forms[0].campos

    return new Promise((resolve, reject) => {
      let formValido = false;

      inputs.map((input: any) => {
        if (input.obrigatorio) {
          if (input.subProp) {
            var validado = this.inputValidate(this.dados['default'][input.prop][input.subProp], input)
            if (validado != null) {
              formValido = true;
            }
          } else {
            var validado = this.inputValidate(this.dados['default'][input.prop], input);
            if (validado != null) {
              formValido = true;
            }
          }
        }
        return formValido;
      })
    })
  }
  async salvar() {
    var inputs = this.inputs.forms[0].campos
    let formValido = true;
    this.validarClick().then((result) => {
      //console.log('result')
      //console.log(result)
    })

    if (inputs) {

      await inputs.map((input: any) => {
        if (input.obrigatorio) {
          if (input.subProp) {
            var validado = this.inputValidate(this.dados['default'][input.prop][input.subProp], input)
            if (validado != null) {
              formValido = false;
            }
          } else {
            var validado = this.inputValidate(this.dados['default'][input.prop], input);
            if (validado != null) {
              formValido = false;
            }
            //this.inputValidate(this.dados['default'][input.prop], input)
          }
        }

        // var item = eval(this.conteudoPagina.validar);
        // if (formValido) {
        //   formValido = item;
        // }
        return formValido;
      })
    }
    if (formValido) {
      //Inputs já validados
      window.scroll(0, 0);


      this.setLoading(true);


      var vlrpk = this.dados.default[this.conteudoPagina.pk];
      if (vlrpk == undefined || vlrpk == null || vlrpk == '') {
        vlrpk = 0;
      }
      var ulr = "";
      if (vlrpk > 0) {
        if (this.conteudoPagina.urlRest) {
          ulr = this.conteudoPagina.urlRest;
        }
        else if (this.conteudoPagina.urlAlterar) {
          ulr = this.conteudoPagina.urlAlterar;
        }

      } else if (vlrpk == 0) {
        if (this.conteudoPagina.urlRest) {
          ulr = this.conteudoPagina.urlRest;
        }
        else if (this.conteudoPagina.urlIncluir) {
          ulr = this.conteudoPagina.urlIncluir;
        }
      }


      if (ulr != '') {
        if (vlrpk > 0) {
          ulr += '/'
          await this.crudService.putObservable(ulr, this.dados.default).subscribe(data => {
            this.setLoading(false);
            this.pesquisar()
          });
        } else {
          await this.crudService.postObservable(this.dados.default, ulr).subscribe(data => {

            this.setLoading(false);
            this.pesquisar()

          });
        }

      } else {
        if (vlrpk > 0) {
          let index = this.conteudoPagina.optionsProp.findIndex((x: any) => x.Id === this.dados.Id);
          this.conteudoPagina.optionsProp[index] = this.dados.default;
        } else {

          this.conteudoPagina.optionsProp.push(this.dados.default);
        }

        this.pesquisar();
      }

    }

  }

  //Executa evento contido na página do formulário
  async clickEventFormPage(data: any, event: any, inputs: any = null) {
    let naoPermitirAvanco = false;

    if (inputs) {

      inputs.map((input: any) => {
        if (input.obrigatorio) {
          if (input.subProp) {
            this.inputValidate(data[input.prop][input.subProp], input)

          } else {

            this.inputValidate(data[input.prop], input)
          }
        }
      })
    }

    if (!inputs || !inputs.some((input: any) => {
      if (input.obrigatorio) {
        if (input.subProp) {
          return this.inputValidate(data[input.prop][input.subProp], input)
        } else {
          return this.inputValidate(data[input.prop], input)
        }
      } else {
        return true
      }
    })) {

      //Inputs já validados
      window.scroll(0, 0);

      this.ls.localStorageSet("dataentidade", this.dados);
      var obj = JSON.parse(this.ls.localStorageGet("dataentidade"));
      let hasRequest: boolean = false;


      // Se for um evento com url para persistir os dados, persista e navegue
      if (event.urlEvento) {
        this.setLoading(true)
        await this.crudService.postObservable(data, event.urlEvento)
          .subscribe((data: any) => {
            // Após persistir os dados, navegue até a rota informada

            this.setLoading(false);

            if (!data) {
              naoPermitirAvanco = true;
              return
            }

            hasRequest = true;

            if (event.rota)
              this.router.navigate([event.rota])
          })

      } else if (event.rota) {
        // Apenas navegue até a rota informada
        this.router.navigate([event.rota])

      }
    }



  }

  //Busca os dados para o input através do evento completeMethod do autocomplete
  autoCompleteSearchEvent(event: any, input: any, condicaoType = null) {
    /*
      Se o input estiver com a condição do filtro 'notSearch', a busca não será feita na API, e as suggestions
      do autocomplete serão preenchidas com um valor já existente no results
    */

    if (condicaoType == "notSearch") {
      return new Promise((resolve, reject) => {
        if (this.results != null) {
          resolve(this.results)
        }
      }).then((resultData: any) => {
        input.dataComplete = resultData.map((value: any) => value)
      })
    }

    let filtro

    // Faz a busca com o filtro baseado no condicaoType do input
    if (condicaoType) {
      filtro = {
        ColunaOrdenacao: input.propOrdenacao,
        Campo: input.propValor,
        Valor: event.query,
        Condicao: condicaoType
      }

    } else if (input.propValueSearch) {
      // Faz a busca baseada numa propriedade já existente no ngModel dos dados globais
      filtro = {
        ColunaOrdenacao: input.propOrdenacao,
        Campo: input.propValor,
        Valor: this.dados['default'][input.propValueSearch],
      }
    }
    else {
      // Faz a busca baseada no valor digitado no autocomplete
      filtro = {
        ColunaOrdenacao: input.propOrdenacao,
        Campo: input.propValor,
        Valor: event.query
      }
    }
    if (input && input.urlOpcoes) {

      this.crudService.postObservable(filtro, input.urlOpcoes)
        .subscribe((data: any) => {
          input.dataComplete = data
          input.options = data


        })
    }

    return null
  }

  copyLinhaDigitavel(evento: any) {


    // const selBox = document.createElement('textarea');
    // selBox.style.position = 'fixed';
    // selBox.style.left = '0';
    // selBox.style.top = '0';
    // selBox.style.opacity = '0';
    // selBox.value = this.dados['default'][evento.prop];
    // window.document.body.appendChild(selBox);
    // selBox.focus();
    // selBox.select();
    // window.document.execCommand('copy');
    // window.document.body.removeChild(selBox);



    //this.alertService.config.timeout = 5000;
    //this.alertService.success("Código copiado para área de transferência!");

  }

  navigateRouterLink(item: any) {

    this.router.navigate([item.url])

  }

  //Alterna entre os índices de opções contidas no formulário de opcções
  switchOptions(event: any, form: any) {

    // Ex; this.dados['default']["FlPublico"] = ["S"]
    this.dados['default'][form.opcoes[event.index].propData] = form.opcoes[event.index].valor

  }

  // Executa uma ação no onSelect do autocomplete
  autoCompleteClickSelect(event: any, input: any) {

    // Atribui o valor obtido no filtro ao valor do ngModel dos dados globais
    this.dados['default'][input.propIdForm] = event[input.propIdEntidade]

    // Atribui múltiplos valores obtidos no filtro a multiplo valores do ngModel dos dados globais
    if (input.propIdsForms) {

      for (let i = 0; i < input.propIdsForms.length; i++) {
        this.dados['default'][input.propIdsForms[i]] = event[input.propIdsEntidades[i]]
      }
    }

  }

  limpa(input: any) {


  }

  // Exibe ou não o input, baseado na condição pré definida no atributo exibir do input.
  //Ex; exibir:"FlPublico, N" -> Exiba o input se a propriedade FlPublico do ngModel global for igual a 'N'
  showObject(input: any) {
    let retorno = true;

    if (input.exibir) {

      let condicao = input.exibir.split('|')


      for (let i = 0; i < condicao.length; i++) {

        let propValor = condicao[i].split(',')

        if (propValor.length == 1) {

          return this.dados['default'][propValor[0]]

        } else {
          if (this.dados['default'][propValor[0]] && this.dados['default'][propValor[0]] != propValor[1]) {
            retorno = false
          }

        }

      }
    }

    return retorno;

  }

  /*
     Aciona a alteração em cadeia  dos valores do ngModel global, baseada nos atributos definidos no impactoChange do input.
     Ex; impactoChange: 1,HrInicial|2,Cidade -> Nos formulários 1 e 2, ao mudar o valor do input em questão, altere os
     valores de HrInicial e Cidade do ngModel global.
  */
  ImpactoChangeAutoComplete($event: any, input: any, forms = null) {


    if (input.impactoChange && !forms) {
      let inputB;
      let a = input.impactoChange.split('|')

      for (let i = 0; i < a.length; i++) {
        let b = a[i].split(',')

        if (this.forms[b[0]].campos[0].opcoes) {
          // Faz a busca no form do tipo opcoes, caso exista
          inputB = this.forms[b[0]].campos[0].campos.find((x: any) => x.prop == b[1]);
        } else {
          inputB = this.forms[b[0]].campos.find((x: any) => x.prop == b[1]);
        }


        if (inputB && inputB.dataComplete) {
          // Limpa o ngModel do input impactado
          inputB.dataComplete = []

          inputB.entidade = {
            Id: 0,
            Descricao: ""
          }
        }

        if (inputB.options) {
          inputB.options = []
        }

      }
    }


  }

  getNvlPropSelectValue(inputPropSelectValue: any) {

    let nvlProp = inputPropSelectValue.split('.');
    return nvlProp[nvlProp.length - 1]
  }

  setDate(event: any, input: any) {
    this.getDates(input, this.value)
    this.dados['default'][input.propIdForm] = new Date(event).toISOString()

  }

  getDates(input: any, date: Date) {
    const obj = {
      IdCidade: this.dados['default']["LocalIdCidade"] != null ? this.dados['default']["LocalIdCidade"] : 1,
      DtReferencia: date.toISOString()
    }



    this.crudService.postObservable(obj, input.urlOpcoes)
      .subscribe(data => {
        this.invalidDates = []
        data.DatasIndisponiveis.map((d: any) => {
          this.invalidDates.push(new Date(d))
        })

        this.results = data.HorariosDisponiveis.map((hr: any) => hr)

      })

  }

  navigateFixRota(rota: any) {


    //Ambiente de produção
    this.router.navigate([rota])


  }

  escolhaCheboxU(data: any, vl: any) {
    data.FlOriginal = vl;
  }

  dropdownChange(event: any, input: any, data: any) {
    data[input.prop] = event.value;
  }

  secondaryAutoCompleteSetData(input: any, event: any, ref: any) {


  }

  autoCompleteSelected: any;
  @ViewChild('auto') auto: any;
  focused: boolean;

  selectEvent(item: any) {
    this.autoCompleteSelected = undefined;

  }

  onChangeSearch(value: string) {

  }

  changeAutoCompleteFocused(input: any) {


  }

  onDate(value: any, input: any) {

    if (input.subProp) {
      this.dados['default'][input.prop][input.subProp] = new Date(value).toISOString();
    } else {
      this.dados['default'][input.prop] = new Date(value).toISOString();
    }
  }

  eventOutput(evento: any) {
    this.clickEventFormPage(this.dados, evento);
  }

  setNewMode(mode: string, evento: any) {
    const obj = { type: mode };

    this.ls.localStorageSet("mode", obj);

    this.router.navigate([evento.rota]);

  }

  getErrorsForm(camposForm: any) {
    let containError = false;
    camposForm.forEach((input: any) => {
      if (input.message) {
        containError = true;
      }
    })

    return containError;

  }

  setLoading(value: any) {
    //this.store.dispatch(userActions.setLoading({ loading: value }));
  }

  switchPropForm(input: any, dados: any, item: any = null) {
    if (input.modeloSwitch && input.modeloSwitch == "navs") {
      dados['default'][input.prop] = item.prop
      //nav.checked = true

    } else if (input.modeloSwitch && input.modeloSwitch == "radio") {
      input.switched = !input.switched
      dados['default'][input.prop] = item.prop


    } else {

      input.switched = !input.switched
      dados['default'][input.prop] = !dados['default'][input.prop];
    }
  }

  selecionarTag(input: any, tag: any) {

    if (input.propIdEntidade) {
      this.dados['default'][input.propIdEntidade] = tag[input.propSelectValue];
    }

    if (input.propIdEntidades) {
      for (let i = 0; i < input.propIdEntidades.length; i++) {
        this.dados['default'][input.propIdEntidades[i]] = tag[input.propSelectValues[i]]
      }
    }

    input.selectedTag = tag;
  }

  async uploadImagem($event: any) {

    var file: File = $event.target.files[0];

    const objImagem: any = {
      Nome: null,
      ArquivoByte: null,
      Tipo: null,
      IdCliente: this.ls.localStorageGet('idClienteSite')
    }

    await this.fileToBase64(file)
      .then((arquivoByte: any) => {
        objImagem.Nome = file.name;
        objImagem.ArquivoByte = arquivoByte.split(',')[1]
        objImagem.Tipo = "Imagem";

        //campo.fileUrl = arquivoByte;
      })

    this.setLoading(true)

  }

  async fileToBase64(file: File) {
    return new Promise((resolve, reject) => {

      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    })
  }

  changeRadioItem(input: any, card: any, dados: any) {
    dados['default'][input.prop] = card.propValue;


  }

  setValueData(data: any) {

    this.dados = { ...data };
  }

} //Fim componente

