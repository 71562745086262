import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';


@Component({
  selector: 'app-form-14', templateUrl: './form14.component.html', styleUrls: ['../_formSteps.component.scss']
})
export class Form14Component implements OnInit, AfterViewInit {
  @Input() conteudo: any;
  @Input() totalPagar: any
  @Input() parcelas: any
  @Output() salvarItem = new EventEmitter<any>();
  @ViewChild('stepperPrincipal') stepper: MatStepper;

  @Input() itemAtual: any;
  @Input() itemEdicao: any;
  @Input() edicao: any = false;
  @Output() cancelarEdicao = new EventEmitter<any>();

  form: FormGroup;

  cartorioEmissor: any[];
  public visibilidadeDetalhes: boolean[] = [];

  camposForm = this.formBuilder.group({
    proprietario: this.formBuilder.array([this.createProprietario()]),
    dadosPet: this.formBuilder.group({
      nome: ['', Validators.required],
      especie: ['', Validators.required],
      raca: ['', Validators.required],
      sexo: ['', Validators.required],
      pelagem: ['', Validators.required],
      dataNascimento: [''],
      idade: ['', Validators.required],
      caracteristicas: [''],
      microchip: [''],
      endereco: [''],
    }),
    arquivosFotos: new FormControl([], Validators.required),
    arquivosIdentificacao: new FormControl([], Validators.required),
    arquivosResidencia: new FormControl([], Validators.required),
    aceites: ['']
  });


  createProprietario(): FormGroup {
    return this.formBuilder.group({
      nomeCompleto: ['', Validators.required],
      cpf: ['', Validators.required],
      identidade: ['', Validators.required],
      orgaoExpedidor: ['', Validators.required],
      dataExpedicao: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.email,
        Validators.required
      ])],
      telefone: ['', Validators.required],
    });
  }

  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef, private routeAc: ActivatedRoute,
    public storage: LocalStorage, public teste: TesteService, private route: Router, public service: CrudService) {
  }

  ngOnInit(): void {
    this.carrregarEdicao();

  }

  cancelarEdicaoForm() {
    this.cancelarEdicao.emit()
  }

  classObrigatoriaAviso: any;
  validarArquivosFotos: any;
  validarArquivosResidencia: any;
  validarArquivosIdentificacao: any;
  temAceiteObrigatorio = false
  aceites: any
  temAceite(event: any) {
    this.temAceiteObrigatorio = true
    this.aceites = event
  }

  ngAfterViewInit() {
  }

  addProrietario() {
    this.proprietario.push(this.createProprietario());
  }

  restartForm(i: number) {
    this.proprietario.removeAt(i)
  }

  get proprietario(): FormArray {
    return this.camposForm.get('proprietario') as FormArray;
  }

  // get itemAtual() {
  //   return this.storage.localStorageGet("item_atual");
  // }

  updatePedido() {
    if (this.temAceiteObrigatorio) {

      let aceitFinal = this.storage.localStorageGet('aceiteDireto')
      let temsemaceite = aceitFinal.find((x: any) => x.selecionado == false)
      if (temsemaceite == undefined) {
        this.camposForm.get('aceites')?.setValue(aceitFinal)
        this.classObrigatoriaAviso = ''
      } else {
        this.classObrigatoriaAviso = 'redClass'
        return
      }
    } else {
      this.classObrigatoriaAviso = ''
    }

    this.camposForm.markAllAsTouched();

    this.ValidarArquivos();

    const proprietarios = this.camposForm.get('proprietario') as FormArray;
    const dadosPet = this.camposForm.get('dadosPet') as FormGroup;

    proprietarios.controls.forEach((proprietarioControl: AbstractControl) => {
      const dataExpedicaoControl = proprietarioControl.get('dataExpedicao');
      if (dataExpedicaoControl && dataExpedicaoControl.hasError('maxlength')) {
        dataExpedicaoControl.setErrors(null);
      }
    });

    const dataNascimentoControl = dadosPet.get('dataNascimento');
    if (dataNascimentoControl && dataNascimentoControl.hasError('maxlength')) {
      dataNascimentoControl.setErrors(null);
    }

    if (this.camposForm.valid) {
      let item: any = this.camposForm.value;
      this.salvarItem.emit(item);
    } else {
      //console.log('form inválido', this.camposForm)
    }
  }

  ValidarArquivos() {
    const camposForm = this.camposForm;

    const arquivosFotos = camposForm.get('arquivosFotos')?.value;
    const arquivosIdentificacao = camposForm.get('arquivosIdentificacao')?.value;
    const arquivosResidencia = camposForm.get('arquivosResidencia')?.value;

    const areArquivosFotosFilledPreenchido = arquivosFotos && arquivosFotos.length > 0;
    const areArquivosIdentificacaoPreenchido = arquivosIdentificacao && arquivosIdentificacao.length > 0;
    const areArquivosResidenciaPreenchido = arquivosResidencia && arquivosResidencia.length > 0;

    if (!areArquivosFotosFilledPreenchido) {
      this.validarArquivosFotos = 'redClass';
    } else {
      this.validarArquivosFotos = '';
    }

    if (!areArquivosIdentificacaoPreenchido) {
      this.validarArquivosIdentificacao = 'redClass';
    } else {
      this.validarArquivosIdentificacao = '';
    }

    if (!areArquivosResidenciaPreenchido) {
      this.validarArquivosResidencia = 'redClass';
    } else {
      this.validarArquivosResidencia = '';
    }
  }


  arquivosFotos: any = []
  arquivosIdentificacao: any = []
  arquivosResidencia: any = []
  setaArquivos(event: any) {
    if (event.tipo === 1) {
      this.arquivosFotos = [...this.arquivosFotos, ...event.arquivos]
      this.camposForm.controls['arquivosFotos'].setValue(this.arquivosFotos);
      this.ValidarArquivos();
    } else if (event.tipo === 2) {
      this.arquivosIdentificacao = [...this.arquivosIdentificacao, ...event.arquivos]
      this.camposForm.controls['arquivosIdentificacao'].setValue(this.arquivosIdentificacao);
      this.ValidarArquivos();
    } else if (event.tipo === 3) {
      this.arquivosResidencia = [...this.arquivosResidencia, ...event.arquivos]
      this.camposForm.controls['arquivosResidencia'].setValue(this.arquivosResidencia);
      this.ValidarArquivos();
    }
  }

  resetaArquivos(entrada: any) {

    if (entrada.tipo === 1) {
      this.arquivosFotos = entrada.arquivos
      this.camposForm.controls['arquivosFotos'].setValue(this.arquivosFotos);
    } else if (entrada.tipo === 2) {
      this.arquivosIdentificacao = entrada.arquivos
      this.camposForm.controls['arquivosIdentificacao'].setValue(this.arquivosIdentificacao);
    } else if (entrada.tipo === 3) {
      this.arquivosResidencia = entrada.arquivos
      this.camposForm.controls['arquivosResidencia'].setValue(this.arquivosResidencia);
    }
  }

  prodAtu: any
  public files: any[] = [];

  carrregarEdicao() {
    let paramId = this.routeAc.snapshot.paramMap.get('id');

    if (paramId != null || this.itemEdicao != null) {
      let pedido = this.storage.localStorageGet("pedido")

      if (pedido?.listaCompra != null && pedido?.listaCompra.length > 0 && paramId != null) {
        this.prodAtu = { ...pedido.listaCompra?.find((x: any) => x.idItem == paramId) }
        this.service.preencheCampos(this.prodAtu, this.camposForm)
      } else {
        this.prodAtu = this.itemEdicao
        this.service.preencheCampos(this.prodAtu, this.camposForm)
      }

      if (this.prodAtu?.arquivosFotos) {
        this.arquivosFotos = this.prodAtu.arquivosFotos
      }

      //console.log(this.camposForm.value)
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // //console.log(event.key, event.ctrlKey, event.shiftKey, environment.production)
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {
      for (let control of this.proprietario.controls) {
        if (control instanceof FormControl) {
          // is a FormControl
        }
        if (control instanceof FormGroup) {
          //console.log('asfd')
          this.teste.setValueForm(control);
        }
        if (control instanceof FormArray) {
          // is a FormArray
        }
      }
    }
  }

}
