<div style="background-color: aliceblue; width: 100%; padding: 5px; font-weight: 600; margin-bottom: 10px; font-size: 1.3rem; "
     *ngIf="iskit && itemAtualKit?.subtitulo">{{itemAtualKit?.subtitulo}}</div>
<app-form-cartorio [uf]="uf"
                   [cidade]="cidade"
                   [itemAtualKit]="itemAtualKit"
                   [iskit]="iskit"
                   (setTemCoberturaFilho)="setTemCoberturaFilho($event)"
                   (setCartorios)="setCartorios($event)"
                   [cartorioEmissor]="cartorioEmissor"
                   [itemAtual]="itemAtual"
                   [multiplo]="multiplo"
                   [classObrigatoria]="classObrigatoria"></app-form-cartorio>


<ng-container *ngIf="temCobertura || edicao">

  <form [formGroup]="orderDetails">
    <div class="row ">
      <div class="col-md-12">

        <div style="height:50px" *ngIf="isLoading"><mat-spinner [diameter]="35" style="float:left"></mat-spinner>carregando finalidades...</div>
        <mat-form-field class="w-100" *ngIf="!isLoading">

          <mat-label *ngIf="cartoriosSelecionados.length>0" for="finalidade">Finalidades:</mat-label>
          <mat-label *ngIf="cartoriosSelecionados.length<1" for="finalidade">Primeiramente escolha um Cartório:</mat-label>

          <mat-select id="finalidade" formControlName="finalidade" [(ngModel)]="finAtu" [compareWith]="compareFinalidade">

            <mat-option *ngFor="let item of finalidadeCertidao"
                        [value]="item">{{item.descricao}}</mat-option>
          </mat-select>

          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="col-md-12">
      <div class="content-list" formArrayName="compradores">
        <div *ngFor="let comprador of compradores.controls; let i = index, let last=last" [formGroupName]="i" class="row" style="align-items: end;">
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Nome da Busca</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="nome" placeholder="Nome que Constará na Certidão" maxlength="200">
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-radio-group formControlName="tipoPessoa" aria-label="Select an option" (change)="limparDados(i)">
              <mat-radio-button value="fisica">CPF</mat-radio-button>
              <mat-radio-button value="juridica">CNPJ</mat-radio-button>
            </mat-radio-group>
            <ng-container *ngIf="comprador?.get('tipoPessoa')?.value === 'fisica'">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label for="documento">CPF</mat-label>
                <input matInput type="text" formControlName="documento" mask="000.000.000-00" placeholder="CPF">
                <mat-error *ngIf=" getCompradorControl(i)?.get('documento')?.hasError('documentoDuplicado')">
                  Documento duplicado
                </mat-error>
                <mat-error *ngIf="validaCPFCNPJ(i)">
                  Documento inválido
                </mat-error>
                <mat-error *ngIf="validaRequired(i)">
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="comprador?.get('tipoPessoa')?.value === 'juridica'">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label for="documento">CNPJ</mat-label>
                <input matInput type="text" formControlName="documento" mask="00.000.000/0000-00" placeholder="CNPJ">
                <mat-error *ngIf=" getCompradorControl(i)?.get('documento')?.hasError('documentoDuplicado')">
                  Documento duplicado
                </mat-error>
                <mat-error *ngIf="validaCPFCNPJ(i)">
                  Documento inválido
                </mat-error>
                <mat-error *ngIf="validaRequired(i)">
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </ng-container>
          </div>
          <ng-container *ngIf="comprador?.get('tipoPessoa')?.value === 'fisica'">
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Nome do Pai</mat-label>
                <input matInput type="text" class="col-lg-12" formControlName="pai" maxlength="100">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Nome da Mãe</mat-label>
                <input matInput type="text" class="col-lg-12" formControlName="mae" maxlength="100">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label for="nascimento">Data de Nascimento</mat-label>
                <input matInput [matDatepicker]="picker" type="text" formControlName="nascimento"
                       appMaskDate
                       maxlength="10">
                <mat-datepicker-toggle matIconSuffix [for]="picker" max="2024-02-29"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>

            </div>

          </ng-container>
          <div class="col-md-6" style="min-height: 94px;" *ngIf="!last || i >0">
            <button class="w-100 my-2 add-new-name" type="button" (click)="remComprador(i)">Remover Nome</button>
          </div>


          <div class="col-md-6" style="min-height: 94px;" *ngIf="last && !temCompradores && !edicao">
            <button class="w-100 my-2 add-new-name" type="button" (click)="addComprador()">Adicionar Novo Nome</button>
          </div>


        </div>
      </div>
    </div>

  </form>
  <app-aviso *ngIf="!edicao" [aviso]="itemAtual?.aviso" [classObrigatoriaAviso]="classObrigatoriaAviso" (temAceite)="temAceite($event)"></app-aviso>

  <div class="row">
    <div class="col-md-5"></div>
    <div class="col-auto" *ngIf="!edicao && !iskit">
      <button type="button" mat-button [routerLink]="['/']" class="btn-next" style="color: white; background-color: green;">Voltar</button>
    </div>
    <div class="col-auto" *ngIf="edicao">
      <button type="button" mat-button (click)="cancelarEdicaoForm()" class="btn-next" style="color: white; background-color: green;">Cancelar</button>
    </div>
    <div class="col-auto">
      <button mat-button (click)="updatePedido()" type="button" class="btn-next"
              style="display: block; margin: 0 auto;">
        <span *ngIf="!edicao">Continuar</span>
        <span *ngIf="edicao">Salvar</span>
      </button>
    </div>
  </div>
</ng-container>
