<!--<img style="display:none" id="logoPrint" src="/assets/img-parceiros-CNR.png" />-->
<section class="header-pedido" id="titleMeusPedidos">
  <div class="container">
    <h2>Meus Pedidos</h2>
  </div>
</section>
<div class="wrapper-content e-cartorio">
  <div class="container" id="conpr">
    <form [formGroup]="camposForm" id="filtro">
      <ng-container>
        <div class="row">
          <b>Filtros:</b>
          <div class="col-md-2">
            <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="dataInicial">Data inicial</mat-label>
              <input matInput [matDatepicker]="picker" type="text" formControlName="dataInicial" appMaskDate
                     maxlength="10" (dateInput)="pesquisar()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="dataFinal">Data final</mat-label>
              <input matInput [matDatepicker]="picker2" type="text" formControlName="dataFinal" appMaskDate
                     maxlength="10" (dateInput)="pesquisar()">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Número do pedido</mat-label>
              <input matInput type="text" (input)="pesquisar()" class="col-lg-12" formControlName="numeroPedido"
                     placeholder="Número do pedido">
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Número do ato</mat-label>
              <input matInput type="text" (input)="pesquisar()" class="col-lg-12" formControlName="numeroAto"
                     placeholder="Número do ato">
            </mat-form-field>
          </div>
          <div class="col-md-1" style="text-align: center;">
            <img title="Filtro avançado" style="width: 30px; margin-top: 25px; cursor: pointer;" alt=""
                 src="assets/icon-filter.svg" class="ng-star-inserted" (click)="showMaisFiltro = !showMaisFiltro">
          </div>

        </div>
        <div class="row" *ngIf="showMaisFiltro">
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>CERP</mat-label>
              <input matInput type="text" (input)="pesquisar()" class="col-lg-12" formControlName="cerp"
                     placeholder="CERP">
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Selo + Aleatório</mat-label>
              <input matInput type="text" (input)="pesquisar()" class="col-lg-12" formControlName="selo"
                     placeholder="Selo + Aleatório  EX:EBTC10015 LCJ">
            </mat-form-field>
          </div>
          <div class="col-md-4" *ngIf="false">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Status</mat-label>
              <mat-select formControlName="tipoStatus" (selectionChange)="pesquisar()">
                <mat-option value="">Todos</mat-option>
                <mat-option value="33">Aguardando Análise Documental</mat-option>
                <mat-option value="-33">Aguardando Orçamento</mat-option>
                <mat-option value="38">Aguardando Retirada na Sede do e-Cartório</mat-option>
                <mat-option value="35">Aguardando Retorno do Cliente</mat-option>
                <mat-option value="10">Ato Cancelado</mat-option>
                <mat-option value="11">Ato Disponivel</mat-option>
                <mat-option value="12">Ato Disponivel no Cartório</mat-option>
                <mat-option value="13">Ato Divergente</mat-option>
                <mat-option value="29">Ato Gratuito</mat-option>
                <mat-option value="14">Ato Transferido</mat-option>
                <mat-option value="15">Ato Transmitido</mat-option>
                <mat-option value="31">Coleta de assinatura finalizada</mat-option>
                <mat-option value="30">Coletando assinaturas</mat-option>
                <mat-option value="23">Divergência Material</mat-option>
                <mat-option value="32">Documento Disponível</mat-option>
                <mat-option value="37">Documento Entregue</mat-option>
                <mat-option value="36">Documento Retirado na Sede do e-Cartório</mat-option>
                <mat-option value="16">Em Execução Cartório</mat-option>
                <mat-option value="34">Exigência Documental</mat-option>
                <mat-option value="17">Exigência Finalizada</mat-option>
                <mat-option value="18">Exigência Financeira</mat-option>
                <mat-option value="19">Exigência Informacional</mat-option>
                <mat-option value="28">Exigência Pré-autorizada</mat-option>
                <mat-option value="20">Exigência Respondida</mat-option>
                <mat-option value="21">Exigência sem retorno</mat-option>
                <mat-option value="22">Pagamento Cancelado por compensação</mat-option>
                <mat-option value="1">Pagamento Comissionado</mat-option>
                <mat-option value="6">Pagamento Divergente</mat-option>
                <mat-option value="7">Pagamento Duplicado</mat-option>
                <mat-option value="27">Pagamento não aprovado</mat-option>
                <mat-option value="8">Pagamento Pendente</mat-option>
                <mat-option value="26">Pagamento pré-autorizado</mat-option>
                <mat-option value="9">Pagamento Realizado</mat-option>
                <mat-option value="25">Pré Aprovado</mat-option>
                <mat-option value="-2">Repasse Agendado</mat-option>
                <mat-option value="-3">Repasse Comissão</mat-option>
                <mat-option value="-4">Repasse Confirmado</mat-option>
                <mat-option value="-5">Repasse Divergente</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </form>

    <ng-container *ngFor="let item of pedidos; let i = index">
      
      <div class="card-item" [id]="i+'p'">
        <div class="col-lg-12" *ngIf="isBack">
          User: {{item?.guidUser}}
        </div>
        <div class="row d-flex justify-content-sm-between align-items-center">
          <div class="col-lg-3">
            <b>Número do pedido:</b> {{item?.numeroPedido}}
          </div>
          <div class="col-lg-2" *ngIf="item?.emExigencia">
            <div class="text-danger d-flex align-items-center">
              <mat-icon>priority_high</mat-icon>
              <b>Em exigencia</b>
            </div>
          </div>

          <div class="col-lg-3">
            <b>Quantidade Total:</b> {{item.listaCompra.length}}
          </div>


          <div class="col-lg-2" *ngIf="item.todosAtosDisponiveis">
            <a (click)="baixarTodosAtos(item.idPedido, item.numeroPedido, item.listaCompra.length)"
               style="cursor: pointer"><b>Baixar atos</b></a>
          </div>


          <div class="col-lg-2" *ngIf="item.dadosPagamento?.notaFiscalDisponivel">
            <a (click)="baixarNotaFiscal(item.idPedido)" style="cursor: pointer"><b>Nota Fiscal</b></a>
          </div>

          <div class="col-lg-3 txtRigth">
            <button name="opcaoPedido" mat-button [matMenuTriggerFor]="menu">
              Opções do pedido
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button type="button" mat-menu-item (click)="verDadosFaturamento(item,i)">
                <mat-icon>account_circle</mat-icon>
                <span>Dados do requerente</span>
              </button>
              <button type="button" (click)="verDetalhesPedidoImpressao(item,i)" mat-menu-item>
                <mat-icon>print</mat-icon>
                <span>Imprimir</span>
              </button>
              <button *ngIf="false" type="button" (click)="copiarPedido(item,i)" mat-menu-item>
                <mat-icon>content_copy</mat-icon>
                <span>Copiar</span>
              </button>
              <button type="button" *ngIf="item?.pendenciaPagamento == true && !item?.dadosPagamento?.dataPagamento"
                      (click)="efetuarPagamento(item?.guidPedido)" mat-menu-item>
                <mat-icon>money</mat-icon>
                <span>Efetuar Pagamento</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>

        <ng-container *ngIf="item?.showDadosFaturamento==true">

          <div style="background-color: azure; padding: 10px;">

            <ng-container *ngIf="!item?.dadosFaturamento">
              Sem dados
            </ng-container>
            <ng-container *ngIf="item?.dadosFaturamento">
              <div class="row">
                <div class="col-sm-4">
                  <h3 class="col-lg-8"><b>Nome</b></h3>
                  <p class="info-section">{{item?.dadosFaturamento?.nome}}</p>
                </div>

                <div class="col-sm-4">
                  <h3 class="col-lg-8"><b>Email</b></h3>
                  <p class="info-section">{{item?.dadosFaturamento?.email}}</p>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-4">
                  <h3 class="col-lg-4"><b>CPF/CNPJ</b></h3>
                  <p class="info-section">
                    {{item?.dadosFaturamento?.cnpjCpf}}
                  </p>
                </div>
                <div class="col-sm-4">
                  <h3 class="col-lg-4"><b>Celular</b></h3>
                  <p class="info-section">{{item?.dadosFaturamento?.celular}}</p>
                </div>
                <div class="col-sm-4">
                  <h3 class="col-lg-4"><b>Telefone Fixo</b></h3>
                  <p class="info-section">{{item?.dadosFaturamento?.telfixo}}</p>
                </div>
              </div>
              <hr>
            </ng-container>
          </div>

        </ng-container>
        <!---->
        <div class="row">
          <div class="col-lg-8">
            <b>Data do pedido:</b> {{ item?.dataCriacao | date: 'dd/MM/yyyy' }}
          </div>
          <div class="col-lg-4 txtRigth">
            <b>Valor total:</b> {{ item?.totalPagar | currency:'BRL' }}
          </div>
        </div>
        <!--/dados do pedido/-->
        <div class="row">
          <div class="col-lg-12 txtCenter" name="btnDetalhes">
            <button mat-button *ngIf="item?.verDetalhes" class="view-more-details" type="button"
                    (click)="ocultarDetalhes(item,i)">
              Ocultar detalhes
            </button>
            <button mat-button *ngIf="!item?.verDetalhes" class="view-more-details" type="button"
                    (click)="verDetalhesPedido(item,i)">
              Ver detalhes
            </button>
          </div>
        </div>
        <!---->

        <ng-container *ngIf="item?.verDetalhes">
          <ng-container *ngFor="let compra of item?.listaCompra;let i=index">

            <mat-accordion class="d-block col-md-12">
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header #panelH (click)="panelH._toggle()">
                  <mat-panel-title>
                    <div class="item">
                      <div class="div-16">
                        <div class="div-17">
                          Ato {{compra?.numeroAto}}
                        </div>
                        <ng-container *ngIf="compra?.listCartorio">
                          <div class="div-18" *ngFor="let cartorio of compra?.listCartorio">
                            Serviço: {{compra?.produto?.titulo}}
                          </div>
                          <div class="div-18" *ngFor="let cartorio of compra?.listCartorio">
                            Cartório: {{cartorio?.cartorio}}
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!compra?.listCartorio && !compra?.produto">
                          <div class="div-18">
                            {{compra?.cartorio?.nome}}
                          </div>
                        </ng-container>
                        <ng-container *ngIf="compra?.produto && !compra?.listCartorio">
                          <div class="div-18">
                            {{compra?.produto?.titulo}}

                            <span *ngIf="compra?.apostilamento=='true'">
                              (com apostilamento)
                            </span>

                          </div>
                        </ng-container>
                        <ng-container *ngIf="compra?.nuVisto">
                          <div class="div-18">
                            Número do Visto: {{compra?.nuVisto}}
                          </div>
                        </ng-container>
                        <ng-container *ngIf="compra?.nuAtoOrigem">
                          <div class="div-18">
                            Ato de Origem: {{compra?.nuAtoOrigem}}
                          </div>
                        </ng-container>
                        <ng-container *ngIf="compra?.novoAto">
                          <div class="div-18">
                            Novo Ato: {{compra?.novoAto}}
                          </div>
                        </ng-container>

                      </div>
                      <div class="div-19">
                        <div class="div-20">Status</div>
                        <div class="div-21">
                          <span class="d-flex align-items-center">
                            <span class="text-danger" *ngIf="compra?.status == 'Exigência Financeira'">
                              <mat-icon class="m-1">warning</mat-icon>
                            </span>
                            {{compra?.status ? compra?.status : 'Pagamento Pendente'}}
                          </span>
                          <a (click)="$event.stopPropagation();baixarAto(compra?.numeroAto)"
                             *ngIf="compra?.status == 'Ato Disponível' || compra?.status == 'Ato Disponivel' || compra?.status == 'Documento Disponivel' || compra?.status == 'Documento Disponível' || compra?.status == 'Coleta de assinatura finalizada' ">
                            Download <img style="width: 15px;" alt="" src="assets/icon-download.svg" class="ng-star-inserted">
                          </a>
                          <a (click)="$event.stopPropagation();baixarRecibo(compra?.numeroAto)"
                             *ngIf="compra?.status == 'Coleta de assinatura finalizada' ">
                            <img style="width: 15px;" alt="" src="assets/flat_document.svg" class="ng-star-inserted">
                          </a>
                        </div>
                      </div>
                      <div class="div-19">

                        <div class="col-lg-3 txtRigth">
                          <button mat-button [matMenuTriggerFor]="menu">
                            Opções do ato
                            <mat-icon>keyboard_arrow_down</mat-icon>
                          </button>
                          <mat-menu #menu="matMenu">
                            <button type="button" *ngIf="compra.podeVistar" mat-menu-item
                                    (click)="vistarAto(compra)">
                              <mat-icon>account_circle</mat-icon>
                              <span>Vistar</span>
                            </button>
                            <button type="button" *ngIf="(!compra?.status || compra?.status=='Pagamento Pendente' || compra?.status=='Pagamento pré-autorizado') && compra?.produto?.origem != 'BR' " mat-menu-item
                                    (click)="editarAto(compra,item,i)">
                              <mat-icon>account_circle</mat-icon>
                              <span>Editar</span>
                            </button>
                            <button type="button"
                                    *ngIf="(compra?.status  && compra?.status!='Pagamento Pendente' && compra?.status!='Pagamento pré-autorizado') || compra?.produto?.origem == 'BR'" mat-menu-item>
                              <mat-icon>account_circle</mat-icon>
                              <span>não existem opções disponívies</span>
                            </button>
                          </mat-menu>
                        </div>

                      </div>
                    </div>

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container>

                  <app-exigencias *ngIf="compra?.listaExigencia?.length > 0"
                                  (enviarMensagemExigenciaEvent)="enviarMensagemExigencia($event, item, i)" [pedido]="item"
                                  [compraAtual]="compra" [exigencias]="compra?.listaExigencia"
                                  (pagarEvent)="pagarExigencia($event)"></app-exigencias>
                  <app-meusPedidosOrderDetails-1 (updatePedido)="updatePedido($event,item,i)"
                                                 (cancelarEdicao)="cancelarEdicao($event)" [compra]="compra"
                                                 *ngIf="compra?.produto?.idForm==1"></app-meusPedidosOrderDetails-1>
                  <app-meusPedidosOrderDetails-2 (updatePedido)="updatePedido($event,item,i)"
                                                 (cancelarEdicao)="cancelarEdicao($event)" [compra]="compra"
                                                 *ngIf="compra?.produto?.idForm==2"></app-meusPedidosOrderDetails-2>
                  <app-meusPedidosOrderDetails-3 (updatePedido)="updatePedido($event,item,i)"
                                                 (cancelarEdicao)="cancelarEdicao($event)" [compra]="compra"
                                                 *ngIf="compra?.produto?.idForm==3"></app-meusPedidosOrderDetails-3>
                  <app-meusPedidosOrderDetails-4 (updatePedido)="updatePedido($event,item,i)"
                                                 (cancelarEdicao)="cancelarEdicao($event)" [compra]="compra"
                                                 *ngIf="compra?.produto?.idForm==4"></app-meusPedidosOrderDetails-4>
                  <app-meusPedidosOrderDetails-5 [compra]="compra"
                                                 *ngIf="compra?.produto?.idForm==5"></app-meusPedidosOrderDetails-5>
                  <app-meusPedidosOrderDetails-6 [compra]="compra"
                                                 *ngIf="compra?.produto?.idForm==6"></app-meusPedidosOrderDetails-6>
                  <app-meusPedidosOrderDetails-7 (updatePedido)="updatePedido($event,item,i)"
                                                 (cancelarEdicao)="cancelarEdicao($event)" [compra]="compra"
                                                 *ngIf="compra?.produto?.idForm==7"></app-meusPedidosOrderDetails-7>
                  <app-meusPedidosOrderDetails-8 (updatePedido)="updatePedido($event,item,i)"
                                                 (cancelarEdicao)="cancelarEdicao($event)" [compra]="compra"
                                                 *ngIf="compra?.produto?.idForm==8"></app-meusPedidosOrderDetails-8>
                  <app-meusPedidosOrderDetails-9 (updatePedido)="updatePedido($event,item,i)"
                                                 (cancelarEdicao)="cancelarEdicao($event)" [compra]="compra"
                                                 *ngIf="compra?.produto?.idForm==9"></app-meusPedidosOrderDetails-9>
                  <app-meusPedidosOrderDetails-10 (updatePedido)="updatePedido($event,item,i)"
                                                  (cancelarEdicao)="cancelarEdicao($event)" [compra]="compra"
                                                  *ngIf="compra?.produto?.idForm==10"></app-meusPedidosOrderDetails-10>
                  <app-meusPedidosOrderDetails-11 (updatePedido)="updatePedido($event,item,i)"
                                                  (cancelarEdicao)="cancelarEdicao($event)" [compra]="compra"
                                                  *ngIf="compra?.produto?.idForm==11"></app-meusPedidosOrderDetails-11>
                  <app-meusPedidosOrderDetails-12 (updatePedido)="updatePedido($event,item,i)"
                                                  (cancelarEdicao)="cancelarEdicao($event)" [compra]="compra"
                                                  *ngIf="compra?.produto?.idForm==12"></app-meusPedidosOrderDetails-12>
                  <app-meusPedidosOrderDetails-13 (updatePedido)="updatePedido($event,item,i)"
                                                  (cancelarEdicao)="cancelarEdicao($event)" [compra]="compra"
                                                  *ngIf="compra?.produto?.idForm==13"></app-meusPedidosOrderDetails-13>
                  <app-meusPedidosOrderDetails-14 (updatePedido)="updatePedido($event,item,i)"
                                                  (cancelarEdicao)="cancelarEdicao($event)" [compra]="compra"
                                                  *ngIf="compra?.produto?.idForm==14"></app-meusPedidosOrderDetails-14>
                  <app-meusPedidosOrderDetails-15 (updatePedido)="updatePedido($event,item,i)"
                                                  (cancelarEdicao)="cancelarEdicao($event)" [compra]="compra"
                                                  *ngIf="compra?.produto?.idForm==15"></app-meusPedidosOrderDetails-15>
                  <app-meusPedidosOrderDetails-21 (updatePedido)="updatePedido($event,item,i)"
                                                  (cancelarEdicao)="cancelarEdicao($event)" [compra]="compra"
                                                  *ngIf="compra?.produto?.idForm==21"></app-meusPedidosOrderDetails-21>
                  <app-meusPedidosOrderDetails-22 (updatePedido)="updatePedido($event,item,i)"
                                                  (cancelarEdicao)="cancelarEdicao($event)" [compra]="compra"
                                                  *ngIf="compra?.produto?.idForm==22"></app-meusPedidosOrderDetails-22>
                  <app-meusPedidosOrderDetails-101 (updatePedido)="updatePedido($event,item,i)"
                                                   (cancelarEdicao)="cancelarEdicao($event)" [compra]="compra"
                                                   *ngIf="compra?.produto?.idForm==101"></app-meusPedidosOrderDetails-101>

                </ng-container>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <h2></h2>
    <mat-paginator id="paginacaoItens" [length]="totalItens" [pageSize]="tamanhoPagina"
                   [pageSizeOptions]="[5, 10, 25, 100]" (page)="alterarPagina($event)" aria-label="Selecionar página">
    </mat-paginator>
    <h2></h2>
  </div>
</div>
