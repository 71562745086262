import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import {
    FormBuilder, Validators
} from '@angular/forms';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-form-6',
  templateUrl: './form6.component.html',
  styleUrls: ['../_formSteps.component.scss'] 
})
export class Form6Component implements OnInit {
 
  @Output() salvarItem = new EventEmitter<any>();
  @Input() cidade: any;
  @Input() uf: any;
  @Input() itemAtual: any;

  
  public listCartorios: any;
  public valor: any;
  public valorTotal: any;
  

  camposForm: any
  
  constructor( 
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public teste: TesteService, 
    private routeAc: ActivatedRoute,
    public service: CrudService,
  ) {
    
  }

  ngOnInit(): void {
    this.montaForm()
    if (document.location.href.indexOf('meu-carrinho') == -1) {
      this.carregarEdicao();

      
    }  
  }
  montaForm() {
    this.camposForm = this.formBuilder.group({
      ato: [this.itemAtual?.idAto, Validators.required],
      descricaoAto: [this.itemAtual.titulo, Validators.required],
      quantidade: [this.itemAtual.qtde, Validators.required],
      precoUnitarioAto: [this.itemAtual.valorUnitario],
      precoTotal: [this.itemAtual.valorTotal],
    });
  }
  changeAtos(value: any) {
    //console.log(value);
  }


  getCartorio() {
    const url = environment.http.API + `Localidade/cartorio?IdCidade=${this.cidade.Nome}&IdEstado=${this.uf.Sigla}`;

    this.service.getObservable('/cartorios-pedido').subscribe({
      next: async (response) => {
        if (response != null) {
          this.listCartorios = response;
        }
      }, error: (err) => {

        console.error(err);
      }
    });
  }

    


  updatePedido() {
     
    
    
      let prodAtu = null

      prodAtu = this.camposForm.value
      this.salvarItem.emit(prodAtu);

    
  }

  carregarEdicao() {
    if (this.routeAc.snapshot.paramMap.get('id') != null) {

      let pedido = this.storage.localStorageGet("pedido")

      if (pedido?.listaCompra != null && pedido?.listaCompra.length > 0) {
        
        let prodAtu = { ...pedido.listaCompra?.find((x: any) => x.idItem == this.routeAc.snapshot.paramMap.get('id')) }

        this.service.preencheCampos(prodAtu, this.camposForm)

      }
    }
  }
   
   

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {
       
          this.teste.setValueForm(this.camposForm);
    }
  }

}
