import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CrudService } from 'src/app/_sevice/CrudService';

@Component({
  selector: 'app-produto-sem-cobertura',
  templateUrl: './produtoSemCobertura.component.html',
  styleUrls: ['./produtoSemCobertura.component.scss']
})

export class ProdutoSemCobertura implements OnInit {
  @Input() cidade: any;
  @Input() uf: any;
  @Input() itemAtual: any;
  produto: any

  formEntreEmContato: FormGroup;
  formSubmited: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private service: CrudService

  ) { }


  ngOnInit(): void {
    this.formEntreEmContato = this.formBuilder.group({
        nome:     ['', Validators.required],
        email:    [
                    '', 
                    Validators.compose(
                      [
                        Validators.email
                      ]
                    )
                  ],
        celular:  ['', Validators.required],
    });
  }

  validarCampoFormEmail() : boolean {
    return this.formEntreEmContato.get(['email'])?.errors?.['email'] && this.formEntreEmContato.get(['email'])?.touched;
  }

  salvar() {
    this.service.addEntreEmContato(this.formEntreEmContato.get(['nome'])?.value, this.formEntreEmContato.get(['email'])?.value, this.formEntreEmContato.get(['celular'])?.value)
        .subscribe(res => {
          //console.log(res);

          this.formEntreEmContato.reset();
          this.formSubmited = true;
        });
  }


}
