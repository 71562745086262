<div class="wrapper-item">

  <div class="wrapper-header-item">
    <h3 class="content-title">
      {{ item.produto.titulo }}
    </h3>
    <div class="wrapper-buttons">
      <div class="content-button">
        <button class="buttons-action"  (click)="editar.emit(item)">
          Editar
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/05a3523c983604fe0000ef79b0c38af35a7a81c7e6b32230af7c4a88344d432f?"
               class="img-11" />
        </button>
      </div>
      <div class="content-button">
        <button class="buttons-action" (click)="excluirItem.emit(item)">
          Excluir
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/dcdd700d816beff971594d71d60bdd298e4130c545dd466135231c4783571eb5?"
               class="img-12" />
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="w-100"></mat-divider>

  <div class="wrapper-info-cartorio">
    <div class="wrapper-topo">
      <div class="content-left">
        <span class="title-box">{{item.produto.titulo}}</span>
      </div>
      <div class="content-right">
        <span class="title-box">Valor</span>
        <p class="text-info-box">{{item.totalAto | currency:'BRL' }}</p>
      </div>
    </div>

    <ng-container *ngIf="visibilidadeDetalhes">
      <div class="content-info-solicitante">
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Nome Completo</h3>
            <p class="info-section">{{item.nomeCompleto}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Email</h3>
            <p class="info-section">{{item.email}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Telefone</h3>
            <p class="info-section">{{item.telefone}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">N° ID Civil</h3>
            <p class="info-section">{{item.identidade}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">NIF</h3>
            <p class="info-section">{{item.nif}}</p>
          </div>
        </div>

        <h2 class="text-center">Tipo Certidão</h2>
        <div class="content-info-solicitante">
          <div *ngIf="item.tipo === 'Casamento'">
            <div class="row" *ngFor="let item2 of item.detalhes">
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Tipo certidão</h3>
                <p class="info-section">{{ item.tipo }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Finalidade</h3>
                <p class="info-section">{{ item.finalidade.descricao }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Nome do Pai</h3>
                <p class="info-section">{{ item2.nomePai }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Nome da Mãe</h3>
                <p class="info-section">{{ item2.nomeMae }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Nome do Cônjuge</h3>
                <p class="info-section">{{ item2.nomeConjuge }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Data do Casamento</h3>
                <p class="info-section">{{ item2.data | date:'dd/MM/yyyy'}}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Lugar do Casamento</h3>
                <p class="info-section">{{ item2.pais.pais }}</p>
              </div>
              <div *ngIf="item2.pais.pais === 'Portugal'">
                <div class="row">
                  <div class="wrapper-info col-lg-6">
                    <h3 class="title-section">Distrito Detalhes</h3>
                    <p class="info-section">{{ item2.distritoDetalhes.descricao }}</p>
                  </div>
                  <div class="wrapper-info col-lg-6">
                    <h3 class="title-section">Concelho Detalhes</h3>
                    <p class="info-section">{{ item2.concelhoDetalhes.descricao }}</p>
                  </div>
                  <div class="wrapper-info col-lg-6">
                    <h3 class="title-section">Freguesia Detalhes</h3>
                    <p class="info-section">{{ item2.freguesiaDetalhes.descricao }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Informações Adicionais</h3>
                <p class="info-section">{{ item.informacaoAdicional }}</p>
              </div>
            </div>
          </div>

          <div *ngIf="item.tipo === 'Nascimento'">
            <div class="row" *ngFor="let item2 of item.detalhes">
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Tipo certidão</h3>
                <p class="info-section">{{ item.tipo }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Finalidade</h3>
                <p class="info-section">{{ item.finalidade.descricao }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Nome do Pai</h3>
                <p class="info-section">{{ item2.nomePai }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Nome da Mãe</h3>
                <p class="info-section">{{ item2.nomeMae }}</p>
              </div>
            </div>
            <div class="row">
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Informações Adicionais</h3>
                <p class="info-section">{{ item.informacaoAdicional }}</p>
              </div>
            </div>
          </div>

          <div *ngIf="item.tipo === 'Óbito'">
            <div class="row" *ngFor="let item2 of item.detalhes">
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Tipo certidão</h3>
                <p class="info-section">{{ item.tipo }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Finalidade</h3>
                <p class="info-section">{{ item.finalidade.descricao }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Nome do Pai</h3>
                <p class="info-section">{{ item2.nomePai }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Nome da Mãe</h3>
                <p class="info-section">{{ item2.nomeMae }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Data do Óbito</h3>
                <p class="info-section">{{ item2.data | date:'dd/MM/yyyy'}}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">País do Óbito</h3>
                <p class="info-section">{{ item2.pais.pais }}</p>
              </div>
              <div *ngIf="item2.pais.pais === 'Portugal'">
                <div class="row">
                  <div class="wrapper-info col-lg-6">
                    <h3 class="title-section">Distrito Detalhes</h3>
                    <p class="info-section">{{ item2.distritoDetalhes.descricao }}</p>
                  </div>
                  <div class="wrapper-info col-lg-6">
                    <h3 class="title-section">Concelho Detalhes</h3>
                    <p class="info-section">{{ item2.concelhoDetalhes.descricao }}</p>
                  </div>
                  <div class="wrapper-info col-lg-6">
                    <h3 class="title-section">Freguesia Detalhes</h3>
                    <p class="info-section">{{ item2.freguesiaDetalhes.descricao }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Informações Adicionais</h3>
                <p class="info-section">{{ item.informacaoAdicional }}</p>
              </div>
            </div>
          </div>

          <div *ngIf="item.tipo === 'Declaracao de Maternindade'">
            <div class="row" *ngFor="let item2 of item.detalhes">
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Tipo certidão</h3>
                <p class="info-section">{{ item.tipo }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Finalidade</h3>
                <p class="info-section">{{ item.finalidade.descricao }}</p>
              </div>
              <h2></h2>
              <h1 class="text-center" style="font-size: 17px;">Dados da Mãe</h1>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Nome Completo</h3>
                <p class="info-section">{{ item2.nomeCompleto }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Data de Nascimento</h3>
                <p class="info-section">{{ item2.data | date:'dd/MM/yyyy'}}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Filho de...</h3>
                <p class="info-section">{{ item2.filhoDe1 }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Filho de...</h3>
                <p class="info-section">{{ item2.filhoDe2 }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Naturalidade</h3>
                <p class="info-section">{{ item2.pais.pais }}</p>
              </div>
              <div *ngIf="item2.pais.pais === 'Portugal'">
                <div class="row">
                  <div class="wrapper-info col-lg-6">
                    <h3 class="title-section">Distrito Detalhes</h3>
                    <p class="info-section">{{ item2.distritoDetalhes.descricao }}</p>
                  </div>
                  <div class="wrapper-info col-lg-6">
                    <h3 class="title-section">Concelho Detalhes</h3>
                    <p class="info-section">{{ item2.concelhoDetalhes.descricao }}</p>
                  </div>
                  <div class="wrapper-info col-lg-6">
                    <h3 class="title-section">Freguesia Detalhes</h3>
                    <p class="info-section">{{ item2.freguesiaDetalhes.descricao }}</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="wrapper-info col-lg-6">
                  <h3 class="title-section">Informações Adicionais</h3>
                  <p class="info-section">{{ item.informacaoAdicional }}</p>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="item.tipo === 'Perfilhação'">
            <div class="row" *ngFor="let item2 of item.detalhes">
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Tipo certidão</h3>
                <p class="info-section">{{ item.tipo }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Finalidade</h3>
                <p class="info-section">{{ item.finalidade.descricao }}</p>
              </div>
              <h2></h2>
              <h1 class="text-center" style="font-size: 17px;">Dados do Perfilhante</h1>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Nome Completo</h3>
                <p class="info-section">{{ item2.nomeCompleto }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Data de Nascimento</h3>
                <p class="info-section">{{ item2.data | date:'dd/MM/yyyy'}}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Filho de...</h3>
                <p class="info-section">{{ item2.filhoDe1 }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Filho de...</h3>
                <p class="info-section">{{ item2.filhoDe2 }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="title-section">Naturalidade</h3>
                <p class="info-section">{{ item2.pais.pais }}</p>
              </div>
              <div *ngIf="item2.pais.pais === 'Portugal'">
                <div class="row">
                  <div class="wrapper-info col-lg-6">
                    <h3 class="title-section">Distrito Detalhes</h3>
                    <p class="info-section">{{ item2.distritoDetalhes.descricao }}</p>
                  </div>
                  <div class="wrapper-info col-lg-6">
                    <h3 class="title-section">Concelho Detalhes</h3>
                    <p class="info-section">{{ item2.concelhoDetalhes.descricao }}</p>
                  </div>
                  <div class="wrapper-info col-lg-6">
                    <h3 class="title-section">Freguesia Detalhes</h3>
                    <p class="info-section">{{ item2.freguesiaDetalhes.descricao }}</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="wrapper-info col-lg-6">
                  <h3 class="title-section">Informações Adicionais</h3>
                  <p class="info-section">{{ item.informacaoAdicional }}</p>
                </div>
              </div>
            </div>
          </div>

        </div>

        <h2 class="text-center">Dados do Pedido</h2>
        <div class="content-info-solicitante">
          <div class="wrapper-info">
            <div class="row">
              <div class="col-lg-6">
                <h3 class="title-section">Pedido feito por terceiro?</h3>
                <p class="info-section">{{ item.pedidoFeitoPorTerceiro === 'true' ? 'Sim' : 'Não' }}</p>
              </div>
              <ng-container *ngIf="item.pedidoFeitoPorTerceiro === 'true'">
                <div class="col-lg-6">
                  <h3 class="title-section">Nº ID Civil</h3>
                  <p class="info-section">{{ item.dadosSolicitante[0].identidade }}</p>
                </div>
                <div class="col-lg-6">
                  <h3 class="title-section">Nome Completo</h3>
                  <p class="info-section">{{ item.dadosSolicitante[0].nomeCompleto }}</p>
                </div>
                <div class="col-lg-6">
                  <h3 class="title-section">Número | Ano do registro</h3>
                  <p class="info-section">{{ item.dadosSolicitante[0].dataExpedicao }}</p>
                </div>
                <div class="col-lg-6">
                  <h3 class="title-section">Data de Nascimento</h3>
                  <p class="info-section">{{ item.dadosSolicitante[0].dataNascimento }}</p>
                </div>
                <div class="col-lg-6">
                  <h3 class="title-section">Naturalidade</h3>
                  <p class="info-section">{{ item.dadosSolicitante[0].pais.pais }}</p>
                </div>
                <div class="col-lg-6" *ngIf="item.dadosSolicitante[0].pais.pais === 'Portugal'">
                  <h3 class="title-section">Distrito Solicitação</h3>
                  <p class="info-section">{{ item.dadosSolicitante[0].distritoSolicitante.descricao }}</p>
                </div>
                <div class="col-lg-6" *ngIf="item.dadosSolicitante[0].pais.pais === 'Portugal'">
                    <h3 class="title-section">Concelho Solicitação</h3>
                    <p class="info-section">{{ item.dadosSolicitante[0].concelhoSolicitante.descricao }}</p>
                </div>
                <div class="col-lg-6" *ngIf="item.dadosSolicitante[0].pais.pais === 'Portugal'">
                    <h3 class="title-section">Freguesia Solicitação</h3>
                    <p class="info-section">{{ item.dadosSolicitante[0].freguesiaSolicitante.descricao }}</p>
                </div>

              </ng-container>
            </div>
          </div>
        </div>

      </div>
    </ng-container>
  </div>
  
  <button class="view-more-details" (click)="visibilidadeDetalhes=!visibilidadeDetalhes">
    {{ visibilidadeDetalhes ? 'Ocultar detalhes' : 'Ver detalhes' }}
  </button>
</div>
