import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-meusPedidosOrderDetails-7',
  templateUrl: './meusPedidosOrderDetail7.component.html',
  styleUrls: ['./../_meus-pedidos.component.scss']
})
export class MeusPedidosOrderDetails7Component implements OnInit  {
  @Input() compra: any

  public visibilidadeDetalhes: boolean=false;
  
  @Output() updatePedido = new EventEmitter<any>();

  ngOnInit(): void {
  }
  cancelarEdicao(compra: any) {
    compra.editar = false
  }
  salvarItem(itemPedido: any) {     
    this.updatePedido.emit(itemPedido)
  }
}
