<form [formGroup]="camposForm">
  <div class="row d-flex">
    <!-- Tipo de Registro -->
    <div class="col-md-6">
      <mat-form-field class="w-100">
        <mat-label for="tipoRegistro">Tipo de registro</mat-label>
        <mat-select id="tipoRegistro" name="tipoRegistro" formControlName="tipoRegistro">
          <mat-option value="Novo registro">Novo registro</mat-option>
          <mat-option value="Averbação / Alteração de registro">Averbação / Alteração de registro</mat-option>
          <mat-option value="Cancelamento de registro">Cancelamento de registro</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Tipo Documento -->
    <div class="col-md-6">
      <mat-form-field class="w-100">
        <mat-label for="tipoDocumento">Tipo de documento</mat-label>
        <mat-select id="tipoDocumento" name="tipoDocumento" formControlName="tipoDocumento">
          <mat-option value="Registro de contratos">Registro de contratos</mat-option>
          <mat-option value="Documentos">Documentos</mat-option>
          <mat-option value="Atas">Atas</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row d-flex">
    <!-- UF -->
    <div class="col-md-6">
      <mat-form-field class="w-100">
        <mat-label for="uf">UF:</mat-label>
        <mat-select id="uf" name="uf" (selectionChange)="getCidade()" formControlName="uf">
          <mat-option *ngFor="let item of listuf" [value]="item">{{ item.Nome }}</mat-option>
        </mat-select>
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Cidade -->
    <div class="col-md-6">
      <mat-form-field class="w-100">
        <mat-label for="cidade">Cidade:</mat-label>
        <mat-select id="cidade" name="cidade" formControlName="cidade">
          <mat-option *ngFor="let item of listCidade" [value]="item">{{ item.Nome }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row d-flex">
    <!-- Informações Adicionais -->
    <div class="col-md-12">
      <mat-form-field class="w-100">
        <mat-label>Informações Adicionais</mat-label>
        <textarea formControlName="informacaoAdicional" matInput></textarea>
      </mat-form-field>
    </div>
  </div>

  <!-- Documentos para Registro -->
  <div class="row d-flex">
    <div class="col-md-6">
      <h2>Documentos para Registro</h2>
      <app-uploadFiles (setaArquivos)="setaArquivosRegistro($event)" [tipo]="1"></app-uploadFiles>   <!--[documentos]="registro"-->
    </div>

    <!-- Documentos Adicionais -->
    <div class="col-md-6">
      <h2>Documentos Adicionais</h2>
      <app-uploadFiles (setaArquivos)="setaArquivosAdicionais($event)" [tipo]="2"></app-uploadFiles> <!--[documentos]="adicionais"-->
    </div>
  </div>


</form>

<app-aviso [aviso]="itemAtual?.aviso"></app-aviso>

<div class="row">
  <div class="col-md-5"></div>
  <div class="col-auto" *ngIf="!edicao">
    <button type="button" mat-button [routerLink]="['/']" class="btn-next" style="color: white; background-color: green;">Voltar</button>
  </div>
  <div class="col-auto" *ngIf="edicao">
    <button type="button" mat-button (click)="cancelarEdicaoForm()" class="btn-next" style="color: white; background-color: green;">Cancelar</button>
  </div>
  <div class="col-auto">
    <button mat-button (click)="updatePedido()" class="btn-next"
            style="display: block; margin: 0 auto;">
      Continuar
    </button>
  </div>
</div>
