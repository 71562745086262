import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { globalPage } from '../../_configPage/globalPage';
import { LocalStorage } from '../../_entidade/LocalStorage';
import { CrudService } from '../../_sevice/CrudService';

@Component({
  selector: 'app-log-local-storage',
  templateUrl: './logLocalStorage.component.html',
  styleUrls: ['./logLocalStorage.component.less']
})


export class LogLocalStorage  {

  navigationSubscription;
  
  constructor(
    private router: Router,
    public st: LocalStorage
  ) {
   
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
       
        if(this.router.url.indexOf('lg=1')>-1)
          this.st.localStorageSet('logado',true)
        else
          this.st.localStorageRemove('logado')
      }
    });

  }

  

}
