<div class="row mt-1 card note rounded-sm">
  <a class="btn btn-closed-note"
     data-toggle="collapse"
     href="#{{id}}"
     (click)="toggleCollapse()"
     [ngClass]="{ 'is-card': !isCollpase }"
     role="button"
     [attr.aria-expanded]="collapseIsOpen"
     id="abreFechaPesquisa"
     aria-controls="collapse">
    <div class="card-header">
      <div class="row">
        <div class="col-10">
          <h6 class="tit-note align-middle">{{ titulo }}</h6>
        </div>
        <div class="col-2 text-right" *ngIf="isCollpase">
          <i class="fas fa-caret-down" id="caret-down"></i>
          <i class="fas fa-caret-up" id="caret-up"></i>
        </div>
      </div>
    </div>
  </a>
  <div class="card-body" id="tooltipTexto" *ngIf="!this.collapseIsOpen"></div>
  <div [attr.name]="'temTooltip' + this.collapseIsOpen" class="card-body" [ngClass]="{'collapse': isCollpase}" id={{id}}>
    <ng-content select=""></ng-content>
  </div>
</div>
