import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import * as fileSaver from 'file-saver';
import { map } from 'rxjs/operators';
import { LocalStorage } from '../_entidade/LocalStorage';


@Injectable({
  providedIn: 'root'
})
export class GlobalService {


  constructor(
    private http: HttpClient
    , public storage: LocalStorage

  ) {

  }

  formataUrl(urlapi: any) {
    var url = "";
    if (urlapi.length > 4 && urlapi.substring(0, 4) != 'http') {
      url = 'http://' + urlapi
    } else if (urlapi.length <= 4) {
      url = 'http://' + urlapi
    } else {
      url = urlapi;
    }
    if (url.substring(0, 5) == 'https') {
      url = url.replace('https://', '').replace('//', '/')
      url = "https://" + url;
    } else if (url.substring(0, 4) == 'http') {
      url = url.replace('http://', '').replace('//', '/')
      url = "http://" + url;
    }

    return url;
  }

  montaUrl(urlapi: string, mock: boolean = false): string {
    var url = "";
    url = environment.http.API + urlapi;
    //console.log(environment)
    if (url.substring(0, 5) == 'https') {
      url = url.replace('https://', '').replace('//', '/')
      url = "https://" + url;
    } else if (url.substring(0, 4) == 'http') {
      url = url.replace('http://', '').replace('//', '/')
      url = "http://" + url;
    }
    return url;
  }

  getPaginado(
    urlAPI: string,
    mock: boolean = false
  ): Observable<any> {
    var url = this.montaUrl(urlAPI, mock);
    if (mock)
      return this.http.get<any>(url, { observe: 'response' });
    else
      return this.http.get<any>(url);
  }

  getObservable(
    urlAPI: string,
    mock: boolean = false
  ): Observable<any> {

    var url = this.montaUrl(urlAPI, mock);
    //console.log(url)
    return this.http.get<any>(url);

  }

  getObservableDirect(
    urlAPI: string
  ): Observable<any> {
    return this.http.get<any>(urlAPI)
  }

  getObservableBlob(
    urlAPI: string
  ): Observable<any> {
    return this.http.get(urlAPI, { responseType: 'blob' })
  }


  postObservableBLOB(
    urlAPI: string,
    id: number,
    data: any,
    nome: any
  ): any {

    var url = this.montaUrl(urlAPI);

    return this.http.post(url, data, { responseType: 'blob' }).pipe(map(
      (res: any) => {
        return new Blob([res], { type: '' })
      })).subscribe(
        (res) => {

          fileSaver.saveAs(res, nome + new Date() + `.xlsx`);

        });
  }
  postObservableBLOBDirect(
    urlAPI: string,
    data: any,
    nome: any
  ): any {


    return this.http.post(urlAPI, data, { responseType: 'blob' }).pipe(map(
      (res: any) => {
        return new Blob([res], { type: '' })
      })).subscribe(
        (res) => {

          fileSaver.saveAs(res, nome + new Date() + `.xlsx`);

        });
  }
  postObservableReturnText(
    objectSend: any,
    urlAPI: string
  ): Observable<any> {

    var url = this.montaUrl(urlAPI);
    return this.http.post(url, objectSend, { responseType: 'text' });
  }

  postObservable(
    objectSend: any,
    urlAPI: string
  ): Observable<any> {

    var url = this.montaUrl(urlAPI);
    return this.http.post(url, objectSend);
  }

  putObservable(
    urlAPI: string,
    objectSend: any
  ): Observable<any> {

    var url = this.montaUrl(urlAPI);

    return this.http.put(url, objectSend)

  }

  patchObservable(
    urlAPI: string,
    objectSend: any
  ): Observable<any> {

    var url = this.montaUrl(urlAPI);

    return this.http.patch(url, objectSend)

  }

  putObservableDirect(
    urlAPI: string,
    objectSend: any
  ): Observable<any> {

    return this.http.put(urlAPI, objectSend)

  }

  postFileObservableDirect(
    urlAPI: string,
    objectSend: any
  ): Observable<any> {

    let headers = new HttpHeaders(
      {
        'Content-Type': 'multipart/form-data'
      }
    )

    //console.log(headers)
    return this.http.post(urlAPI, objectSend, { headers: headers })

  }


  postObservableDirect(
    urlAPI: string,
    objectSend: any
  ): Observable<any> {
    let uslogged = this.storage.localStorageGet('userlogged')
    let autorize = ''
    if (uslogged) {
      autorize = 'Bearer ' + uslogged.token
    }

    let headers = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': autorize
    });

    return this.http.post(urlAPI, objectSend, { headers: headers })

  }

  deleteObservableDirect(
    urlAPI: string
  ): Observable<any> {

    let uslogged = this.storage.localStorageGet('userlogged')
    let autorize = ''
    if (uslogged) {
      autorize = 'Bearer ' + uslogged.token
    }

    let headers = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': autorize
    });

    return this.http.delete(urlAPI, { headers: headers })


  }

  deleteObservable(
    urlAPI: string
  ): Observable<any> {

    var url = this.montaUrl(urlAPI);
    return this.http.delete(url);
  }


}
