import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const inputIptuRJValidator = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const palavra = control.value as any;
    
        if (palavra == null || palavra == '')
            return null;
    
        let texto = 'Erro';
        let parc = 0;
        let total = 0;
    
        for (var i = 0; i < 7; i++) {
            if (i % 2 == 0)
                parc = palavra.toString().substr(i, 1) * 2;
            else
                parc = palavra.toString().substr(i, 1);
    
            if (parc > 9)
                total = parseInt(total.toString()) + parseInt(parc.toString().substr(0, 1)) + parseInt(parc.toString().substr(1, 1));
            else
                total = parseInt(total.toString()) + parseInt(parc.toString());
        }
    
        total = ((10 * (1 + Math.floor((total - 0.1) / 10))) - total);
    
        if (total == palavra.toString().substr(7, 1))
            texto = 'OK';
    
        if (texto == 'Erro')
            return { iptuInvalido: true };
        else
            return null;
    }
}