import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: 'app-banco-ideias',
  templateUrl: './bancoideias.component.html',
  styleUrls: ['./bancoideias.component.scss']
})
export class BancoIdeiasComponent implements OnInit {

  
  @Input() conteudo: any;

  constructor(private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {

  }
  transform(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }
}
