import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-meusPedidosOrderDetails-1',
  templateUrl: './meusPedidosOrderDetail1.component.html',
  styleUrls: ['./../_meus-pedidos.component.scss']
})
export class MeusPedidosOrderDetails1Component  {
  @Input() compra: any
  public visibilidadeDetalhes: boolean=false;
  
  @Output() updatePedido = new EventEmitter<any>();

  ngOnInit(): void {
     
  }
  cancelarEdicao(compra: any) {
    compra.editar = false
  }
  salvarItem(itemPedido: any) {     
    this.updatePedido.emit(itemPedido)
  }
}
