
import { Component, Input, OnInit, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '../../../_entidade/LocalStorage';

@Component({
  selector: 'app-header-v4',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponentV4 implements OnInit {
  conteudo: any
  isScrolled = false;
  @Input() data: any;
  numeroItensCarrinho: number = 0;
  projeto: any;
  public environment = environment;

  isBack = this.st.localStorageGet('BackOffice')

  isCheckboxChecked = false;

  desmarcarCheckbox() {
    this.isCheckboxChecked = false;
  }
  logado = false;
  usuarioLogado:any
  urlCadastro: string = environment.urlCadastro
  urlLogin: string = environment.urlLogin
  urlPainel: string = environment.urlPainel
  navigationSubscription;
  constructor(
    private router: Router,
    public st: LocalStorage,
    private route: Router,
    public divice: DeviceDetectorService
  ) {


    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {

        let url = this.router.url


        if (url.indexOf('?logoff=1') > -1) {
          this.logado = false
          this.st.localStorageRemove('logado')
          this.st.localStorageRemove('userlogged')
          this.st.localStorageRemove('userMeetingSchedule')
          this.route.navigate(['/login/entrar'])


        } else {
          this.usuarioLogado = this.st.localStorageGet('userlogged')
          this.logado = this.usuarioLogado != null

        }

      }
    });





  }

  protected isCartorio = signal(false);

  verificarCartorio() {
    this.usuarioLogado = this.st.localStorageGet('userlogged')
    if(this.usuarioLogado != undefined){
      let temAcesso = this.usuarioLogado.myMenu.find((x: any) => x.url == '/fadmin/curriculos')


      this.isCartorio.set(temAcesso != undefined)
    }

  }

  showBackOffice(url: any) {

    if (this.st.localStorageGet('BackOffice') == null) {
      this.st.localStorageSet('BackOffice', true)
      this.route.navigate([url])
      window.location.reload()
    } else {
      this.st.localStorageRemove('BackOffice')
      this.route.navigate([url])
      window.location.reload()

    }


  }
  sair() {
    document.location.href = "/login/entrar?logoff=1"
    //this.route.navigate(["/login/entrar?logoff=1"])

  }
  navegar(url: any) {
    this.route.navigate([url])

  }
  ngOnInit() {

    this.verificarCartorio()
    let url = this.router.url


    if (url.indexOf('?logoff=1') > -1) {
      this.logado = false
      this.st.localStorageRemove('logado')
      this.st.localStorageRemove('userlogged')
      this.st.localStorageRemove('userMeetingSchedule')
      this.route.navigate(['/login/entrar'])


    } else {
      this.usuarioLogado = this.st.localStorageGet('userlogged')
      this.logado = this.usuarioLogado != null

    }


    this.atualizarContadorCarrinho();
    window.addEventListener('cartUpdated', () => this.atualizarContadorCarrinho());
    this.conteudo = this.data.header

    this.buscarProjetoNoLocalStorage();
  }



  ngOnDestroy() {
    window.removeEventListener('cartUpdated', () => this.atualizarContadorCarrinho());
  }

  changeRoute(rotaNova: string) {
    this.st.localStorageRemove("pgCrudEdit")
    this.route.navigate([rotaNova])
  }

  atualizarContadorCarrinho() {
    const pedidoObj = this.st.localStorageGet('pedido');

    if (pedidoObj) {

      if (pedidoObj && pedidoObj.listaCompra) {
        this.numeroItensCarrinho = pedidoObj.listaCompra.length;
      } else {
        this.numeroItensCarrinho = 0;
      }
    } else {
      this.numeroItensCarrinho = 0;
    }
  }

  buscarProjetoNoLocalStorage() {
    const projeto = this.st.localStorageGet('projeto');
    if (projeto) {
      this.projeto = projeto;
    }
  }

  public isMobile(): boolean {
    return this.divice.isMobile();
  }
}
