import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import saveAs from 'file-saver';
import { LocalStorage } from 'src/app/_entidade/LocalStorage';
import { CrudService } from 'src/app/_sevice/CrudService';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { debounce } from 'lodash';
import { DateValidators } from '../../../generic-form/generic-form.component';

export class DateValidator {

  static ptDate(control: FormControl): { [key: string]: any } {
    let ptDatePattern = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;

    if (!control.value.match(ptDatePattern))
      return { "ptDate": true };

    return { "ptDate": false };
  }
}

@Component({
  selector: 'app-meus-pedidos-area-cliente',
  templateUrl: './meus-pedidos.component.html',
  styleUrls: ['./_meus-pedidos.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class MeusPedidosAreaClienteComponent implements OnInit {
  @Input() conteudo: any;
  showMaisFiltro: boolean=false
  paginaAtual: number = 1;
  tamanhoPagina: number = 10;
  totalItens: number = 0;
  pedidos: any
  isLinear = true;
  panelOpenState = false;
  public visibilidadeDetalhes: boolean = false;
  show: boolean = false
  showDadosFaturamento: boolean = false
  semRequerente: boolean = true
  camposForm: any
  currentDate = new Date()
  lastMonthDate = new Date(new Date().setMonth(new Date().getMonth() -1))
  erroBusca:any=false
  pipe = new DatePipe('pt-BR');

  constructor(
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public teste: TesteService,
    private route: Router,
    public service: CrudService
  ) {
    this.pesquisar = debounce(this.pesquisar, 500)

  }

  ngOnInit(): void {
    this.criarForm()
    this.iniciaPedido()
  }

  criarForm() {
      this.camposForm = this.formBuilder.group({
        dataInicial: [this.lastMonthDate],
        dataFinal: [this.currentDate],
        numeroPedido: [''],
        numeroAto: [''],
        cerp: [''],
        selo: [''],
        tipoStatus: ['']
      });
  }

     //     dataInicial: [this.pipe.transform(this.lastMonthDate, 'dd/MM/yyyy'),
     //      [Validators.required, Validators.pattern(/^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/)]],
     //   dataFinal: [this.pipe.transform(this.currentDate, 'dd/MM/yyyy'),
     //      [Validators.required, Validators.pattern(/^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/)]],

  isUUID(uuid:any) {
    let s =  uuid;

    s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (s === null) {
      return false;
    }
    return true;
  }

  pesquisar() {
    let objsend = this.camposForm.value
    let cerpValid = this.isUUID(this.camposForm.get('cerp').value)
    if (this.camposForm.get('cerp').value != '' && !cerpValid) {
      objsend.cerp=''

    }
    //console.log(objsend, "teste")

    var base64 = btoa(JSON.stringify(objsend))

    const url = `${environment.http.API}Venda?pagina=${this.paginaAtual}&tamanhoPagina=${this.tamanhoPagina}&filtros=${base64}`;
    this.erroBusca = false;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {
        if (response != null) {
          this.pedidos = response.Dados;
          this.totalItens = response.Tamanho;
          let el = document.getElementById('topo');
          el?.scrollIntoView();
        }
      }, error: (err) => {
        console.error('Erro ao buscar detalhes do pedido:', err);
        if (err?.status == 401)
          this.route.navigate(['/login/entrar?logoff=1'])
        else
          this.erroBusca = true

        //this.route.navigate(['/login/entrar?logoff=1'])
      }
    });




  }
  togglePanel(index: any) {
    this.panelOpenState = !this.panelOpenState;
  }

  verDadosFaturamento(item: any, i: any) {

    this.verDetalhes(item, i,1)

  }

  OcultarDadosFaturamento(item: any, i: any) {
    this.showDadosFaturamento = false
  }

  filtrarMeusPedidos() {

  }

  print(item: any, i: any): void {
    let printContents, popupWin;
    printContents = (<HTMLInputElement>document.getElementById(i)).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin?.document.open();
    popupWin?.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin?.document.close();
  }

  ocultarDetalhes(item: any, i: any) {
    item['verDetalhes'] = false
  }

  verPanelOpenState(item:any,index: any) {
    item.panelOpenState = !item.panelOpenState;
  }

  verDetalhesPedido(item: any, i: any) {
    item['verDetalhes'] = true
    this.verDetalhes(item, i)
  }

  verDetalhesPedidoImpressao(item: any, i: any) {
    item['verDetalhes'] = true;
    this.verDetalhes(item, i);
    this.panelOpenState = true; // Expande o segundo nível de detalhes
  }


  verDetalhes(item: any, i: any,tipo:any=0) {

    let it = this.storage.localStorageGet("pedido")
    var base64 = btoa(JSON.stringify(this.camposForm.value))
    const url = `${environment.http.API}Venda/getPedidos?numeroPedido=${item.numeroPedido}&filtros=${base64}`;
    this.erroBusca = false;

      (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";
    //ajsutar
    this.service.getObservableDirect(url).subscribe({
      next: (response) => {
        if (response) {
          this.pedidos[i] = response;
          if (tipo==0)
            this.pedidos[i]['verDetalhes'] = true
          else
            this.pedidos[i]['showDadosFaturamento'] = true


          //Verifica se tem Requerente
          let fatura = response.dadosFaturamento
          if (fatura != null) {
            this.semRequerente = true
          }
          else {
            this.semRequerente = false
          }
          //
           
        }
      },
      error: (err) => {
        console.error('Erro ao buscar detalhes do pedido:', err);
        if (err?.status == 401)
          this.route.navigate(['/login/entrar?logoff=1'])
        else
          this.erroBusca = true
      }
    });

  }

  baixarTodosAtos(idPedido: any, numeroPedido: any) {

    const url = `${environment.http.API_BACK}Pedido/BaixarAtosPorId?idPedido=${idPedido}&numeroPedido=${numeroPedido}`;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    //ajsutar
    this.service.getObservableDirect(url).subscribe({
      next: (response) => {
        if (response) {

          //console.log(response);
        }
      },
      error: (err) => {
        console.error('Erro ao buscar detalhes do pedido:', err);
        if (err?.status == 401)
          this.route.navigate(['/login/entrar?logoff=1'])
        else
          this.erroBusca = true
      }
    });

  }

  baixarNotaFiscal(idPedido: any) {

    const url = `${environment.http.API_BACK}Pedido/BaixarNotaFiscal?idPedido=${idPedido}`;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.getObservableBlob(url).subscribe(blob => {
      saveAs(blob, 'notafiscal.pdf');
    });

  }

  baixarAto(nuAto: any) {

    const url = `${environment.http.API_BACK}Ato/BaixarAtoV3?nuAto=${nuAto}`;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.getObservableBlob(url).subscribe(blob => {
      saveAs(blob, `${nuAto}.pdf`);
    });

  }

  copiarPedido(item: any, i: any) {
    let it = this.storage.localStorageGet("pedido")
    const url = `${environment.http.API}Venda/getPedidos?numeroPedido=${item.numeroPedido}`;
    this.erroBusca = false;

      (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    //ajsutar
    this.service.getObservableDirect(url).subscribe({
      next: (response) => {
        if (response) {
          let pedido = response
          delete pedido['_id']
          delete pedido['id']
          delete pedido['Id']
          pedido['pedidoOrigem'] = pedido['numeroPedido']
          this.storage.localStorageSet('pedido', pedido)
          this.storage.localStorageRemove('item_atual')
          this.route.navigate(['/pedido/meu-carrinho'])
        }
      },
      error: (err) => {
        console.error('Erro ao buscar detalhes do pedido:', err);
        if (err?.status == 401)
          this.route.navigate(['/login/entrar?logoff=1'])
        else
          this.erroBusca = true
      }
    });
  }

  alterarPagina(event: PageEvent) {
    const novaPagina = event.pageIndex + 1;
    const novoTamanhoPagina = event.pageSize;

    this.paginaAtual = novaPagina;
    this.tamanhoPagina = novoTamanhoPagina;

    this.iniciaPedido(novaPagina, novoTamanhoPagina);
  }

  getdate(dataenviada: any) {
    let dttratada = dataenviada?.substr(0,10).replace('T00:00:00Z', '').split('-')

    return  new Date(dttratada[0], dttratada[1] - 1, dttratada[2])
  }

  iniciaPedido(pagina: number = this.paginaAtual, tamanhoPagina: number = this.tamanhoPagina): void {

    var base64 = btoa(JSON.stringify(this.camposForm.value))

    const url = `${environment.http.API}Venda?pagina=${this.paginaAtual}&tamanhoPagina=${this.tamanhoPagina}&filtros=${base64}`;
    this.erroBusca = false;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";


    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {
        if (response != null) {
          this.pedidos = response.Dados;
          this.totalItens = response.Tamanho;
          let el = document.getElementById('topo');
          el?.scrollIntoView();
          //console.log(this.pedidos, "teste")
        }
      }, error: (err) => {
        console.error('Erro ao buscar detalhes do pedido:', err.status);

        //if (err?.status ==401)
        //  this.route.navigate(['/login/entrar?logoff=1'])
        //else
        //  this.erroBusca = true

      }
    });

  }

}
function ngxPrint(elementToPrint: HTMLElement, printOptions: {
  printMode: string; // Pode ser 'iframe' ou 'popup'
  bodyClass: string; // Classe CSS a ser adicionada ao corpo ao imprimir
  standard: string; // Pode ser 'html5', 'iframe' ou 'popup'
}) {
  throw new Error('Function not implemented.');
}

