import { AfterViewInit, Component, HostListener, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
    FormBuilder, Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
import { GenericValidator } from 'src/app/_sevice/GenericValidator';



@Component({
  selector: 'app-form-3',
  templateUrl: './form3.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class Form3Component implements OnInit, AfterViewInit {
  @Output() salvarItem = new EventEmitter<any>();
  @Output() setTemCobertura = new EventEmitter<any>();
  @Output() cancelarEdicao = new EventEmitter<any>();

  @Input() uf: any;
  @Input() edicao: any=false;
  @Input() cidade: any;
  @Input() itemAtual: any;
  @Input() itemEdicao: any;

  public visibilidadeDetalhes: boolean[] = [];

  cartorioEmissor: any[];
  temCobertura: boolean = false
  classObrigatoria: any = ''

  camposForm = this.formBuilder.group({
    livro: ['', Validators.required],
    folha: ['', Validators.required],
    natureza: [''],
    documento: ['',  Validators.compose([ GenericValidator.isValidCPFCNPJ()])],
    dataAto: [''],
    nomeBusca: [''],
    pai: [''],
    mae: [''],
    observacao: [''],
  });

  constructor(
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    private route: Router,
    private routeAc: ActivatedRoute,
    public teste: TesteService,
    public service: CrudService
  ) {

  }

  ngOnInit(): void {
    this.carrregarEdicao()
  }

  ngAfterViewInit() {

  }

  erroCartorio = false;


  updatePedido() {

    this.camposForm.markAllAsTouched();
    if (this.camposForm.get('dataAto')?.hasError('maxlength'))
      this.camposForm.get('dataAto')?.setErrors(null);

    let carsel = this.cartorioEmissor?.filter((x: any) => x.selecionado == true)

    //console.log(carsel)

    if (carsel == null || carsel.length == 0) {
      this.erroCartorio = true;
      this.classObrigatoria = 'redClass'
    } else {
      this.erroCartorio = false;
      this.classObrigatoria = ''
    }

    if (this.camposForm.valid && !this.erroCartorio) {
      this.camposForm.markAllAsTouched();
      if (this.camposForm.valid) {
        let prodAtu = null
        let carsel = this.cartorioEmissor?.filter((x: any) => x.selecionado == true)
        let item: any = this.camposForm.value
        item['listCartorio'] = carsel

        this.salvarItem.emit(item);


        //console.log('updatePedido',item)


      }
    } else {
      this.service.sobeScrol()
    }

  }

  carrregarEdicao() {
    let paramId = this.routeAc.snapshot.paramMap.get('id');

    if (paramId != null || this.itemEdicao != null) {

      let pedido = this.storage.localStorageGet("pedido");

      if (pedido?.listaCompra != null && pedido?.listaCompra.length > 0 && paramId != null) {
        let prodAtu = { ...pedido.listaCompra?.find((x: any) => x.idItem == paramId) }
        this.service.preencheCampos(prodAtu, this.camposForm);

      } else {
        let prodAtu = this.itemEdicao
        this.service.preencheCampos(prodAtu, this.camposForm);
      }
    }

  }

  cancelarEdicaoForm() {
    this.cancelarEdicao.emit()
  }

  setTemCoberturaFilho(tem: boolean) {
    this.temCobertura = tem
    this.setTemCobertura.emit(tem);

  }

  setCartorios(cartorios: any) {
    this.cartorioEmissor = cartorios
    this.carrregarEdicao()
  }


  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // //console.log(event.key, event.ctrlKey, event.shiftKey, environment.production)
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {

      this.teste.setValueForm(this.camposForm);

      //console.log('HostListener', this.camposForm)

    }
  }

}
