import { Injectable } from '@angular/core';

const Regex = {
  email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  cpf: /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
  telefone: /^(?:\()[0-9]{2}(?:\))\s?[0-9]\s?[0-9]{4}(?:-)[0-9]{4}$/,
  cep: /^[\d]{5}-[\d]{3}/
} 


@Injectable({
  providedIn: 'root'
})
export class InputService {
    errors:any = {
      match: 0,
      length: 1,
      email: 2,
      incomplete: 3
    };


  getErrorMessage(inputPlaceholder: any, typeError: string) {
    switch (this.errors[typeError]) {
      case 0:
        return `As senhas não são idênticas`;
      case 1:
        return `Senha precisa ter no mínimo 6 caracteres`;
      case 2:
        return `E-mail não é válido`;
      case 3:
        return `Insira um valor válido.`
      default:
        return `O campo ${inputPlaceholder.toLowerCase()} é obrigatório`;
    }
  }


  verifyDigitsTelNumber(currentValue:any) {

    let telNumberArray = currentValue.split('')
    if (telNumberArray.length == 16) {
      if (
        (telNumberArray[7] == telNumberArray[8] && telNumberArray[9] == telNumberArray[10]) &&
        telNumberArray[12] == telNumberArray[13] && telNumberArray[14] == telNumberArray[15]) {
        return false
      } else {
        return true
      }
    }
    //console.log(telNumberArray)
    return false
  }

  verifyCpf(currentValue:any) {
    //console.log('Valor atual CPF: ')
    //console.log(currentValue)

    if (currentValue.length < 14) {
      return false
    }

    let cpf = currentValue;

    let newCpf = cpf.replaceAll('.', '').replaceAll('-', '')
    let numberDigits:any = newCpf.split('')

    let somaA = 0;
    let count = 0;


    for (let i = 10; i > 1; i--) {
      somaA += numberDigits[count] * i;
      count++;
    }

    let resto = somaA % 11;
    let primeiroDigito = resto < 2 ? 0 : 11 - resto;

    numberDigits[9] = primeiroDigito;

    let somaB = 0;
    count = 0;

    for (let i = 11; i > 1; i--) {
      somaB += numberDigits[count] * i;
      count++;
    }

    let resto2 = somaB % 11;
    let segundoDigito = resto2 < 2 ? 0 : 11 - resto2;

    //console.log('--- DIGITOS CORRETOS : {' + primeiroDigito + '} {' + segundoDigito + '}')
    if (parseInt(newCpf[9]) == primeiroDigito && parseInt(newCpf[10]) == segundoDigito) {
      //CPF VÁLIDO
      return true
    } else {
      //CPF INVÁLIDO
      return false

    }

    return true
  }

  verifyDate(currentValue:any) {

    let timeStamp = Date.parse(currentValue)

    let arrayDate = currentValue.split('/')
    let ano = arrayDate[2]
    let mes = arrayDate[1]
    let dia = arrayDate[0]

    let newDate: Date = new Date(`${ano}/${mes}/${dia}`)
    //ajustar
    //if (isNaN(new Date(newDate))) {
    //  //console.log('Data inválida')
    //  return false
    //}
    //console.log('Data válida')
    return true

  }

  verifyCep(currentValue:any) {
    //console.log('---- VERIFICANDO CEP ----')
    //console.log(currentValue)

    let cep = currentValue.replaceAll('-', '');
    let regex = /^(\d)\1+$/;

    if (regex.test(cep)) {
      //console.log('CEP INVALIDO')
      return false
    }
    //console.log('CEP VALIDO')
    return true
  }


  inputValidation(
    currentValue:any,
    inputType: any = {},
    inputPlaceholder: any,
    category: any
  ) {


    let isValid = true;
    let type = inputType.type;

    if (
      currentValue === "" ||
      currentValue === null ||
      currentValue === undefined ||
      currentValue.length === 0
    ) {
      isValid = false;
    }

    if (inputType.type === "password" && isValid) {
      if (currentValue.length < 6) {
        type = "length";
        isValid = false;
      } else if (inputType.firstInputMatch && inputType.secondInputMatch) {
        if (
          inputType.firstInputMatch.length !== 0 &&
          inputType.secondInputMatch.length !== 0 &&
          inputType.firstInputMatch !== inputType.secondInputMatch
        ) {
          type = "match";
          isValid = false;
        }
      }
    }

    if (inputType.type === "number") {
      //console.log('---- NUMBER TYPE')
      //console.log(this)
    }

    //if (inputType.type === "email") {
    //  if (!Regex.email.test(currentValue)) {
    //    type = "email";
    //    isValid = false;
    //  }
    //}



    if (category.toLowerCase() === "email") {
      if (!Regex.email.test(currentValue)) {
        type = "email";
        isValid = false;
      }
    }

    if ((category.toLowerCase() === 'telefone' || category.toLowerCase() === 'celular')) {
      if (!Regex.telefone.test(currentValue) || !this.verifyDigitsTelNumber(currentValue)) {
        type = 'incomplete';
        isValid = false;
      }

    }


    if (inputType.type === 'mask') {

      //console.log('---> mask')

      if (category.toLowerCase() === "cpf" && !Regex.cpf.test(currentValue)) {
        //console.log('CPF invalido')
        //console.log(currentValue)
        type = 'incomplete';
        isValid = false;
      }
      if ((category.toLowerCase() === 'telefone' || category.toLowerCase() === 'celular')) {

        if (!Regex.telefone.test(currentValue) || !this.verifyDigitsTelNumber(currentValue)) {
          //console.log('VALIDANDO CELULAR 2')
          //console.log(currentValue)
          type = 'incomplete';
          isValid = false;

        }
      }
      if ((category.toLowerCase() === 'cep') && !Regex.cep.test(currentValue)) {
        type = 'incomplete';
        isValid = false;
      }
    }

    if (!isValid) return this.getErrorMessage(inputPlaceholder, type);
    else return null;
  }
}
