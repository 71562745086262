import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../../_entidade/LocalStorage';
import { CrudService } from '../../_sevice/CrudService';
import { GlobalService } from '../../_sevice/GlobalService';


@Component({
  selector: 'app-usuario-perfil',
  templateUrl: './usuario-perfil.component.html',
  styleUrls: ['./usuario-perfil.component.less']
})
export class UsuarioPerfilComponent {


  constructor(
    public router: Router
    , public storage: LocalStorage
    , public service: CrudService
    , public gb: GlobalService
  ) {
  }


  ngOnInit() {
  }


}
