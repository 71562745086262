<div class="imgBg">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div style="margin:50px">
          <h2 style="font-size: 24px;">Não temos cobertura para <i>{{itemAtual?.titulo}}</i> no {{uf?.Sigla}}-{{cidade?.Nome}}:</h2>

          <div class="d-flex flex-row m-4" style="margin:50px">
            <p style="font-size: 20px;">
              No momento, não oferecemos cobertura para a emissão de <i>{{itemAtual?.titulo}}</i>
              no {{uf?.Sigla}}-{{cidade?.Nome}}.
            </p>
          </div>

          <div class="d-flex flex-row m-4" style="margin:50px">
            <p style="font-size: 20px;">
              Mas não se preocupe, estamos aqui para te ajudar!  
            </p>
          </div>

          <!--<div class="d-flex flex-row m-4" style="margin:50px">
            <button class="btn btn-success whatsapp-atendimento-button">
              <img src="/assets/whatsapp.svg" alt="WhatsApp Icon" style="width: 24px; height: 24px;">
              Atendimento WhatsApp
            </button>
          </div>-->

        </div>
      </div>

      <div class="col-lg-6">
        <div class="form-container p-5" style="background-color: white; color: black; border-radius: 0.25rem;">
          <div *ngIf="!formSubmited">
            <form class="d-flex flex-column" [formGroup]="formEntreEmContato" (ngSubmit)="salvar()">

              <h4 style="color: #003366; font-weight: bold; text-align: center; font-size: 20px;">
                Preencha o formulário abaixo e nosso <br>
                time de operações entrará em <br>
                contato com você:
              </h4>
              <div class="mb-3">
                <label for="nome" class="form-label">Nome*</label>
                <input type="text" class="form-control" formControlName="nome" id="nome" required>
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">E-mail*</label>
                <input type="email" class="form-control email" formControlName="email" id="email" required>
                <mat-error *ngIf="validarCampoFormEmail()">
                  Email invalido
                </mat-error>
              </div>
              <div class="mb-3">
                <label for="celular" class="form-label">Celular*</label>
                <input type="text" class="form-control" formControlName="celular" id="celular" mask="(00) 00000-0000" required>
              </div>
              <input type="submit" title="Enviar Agora" class="btn btn-primary mt-3 mb-3"
                     [disabled]="formEntreEmContato.invalid" />
            </form>
          </div>

          <div *ngIf="formSubmited">
            <h2 style="color: #1e8160;">Informação de contato cadastrada com sucesso.</h2>
            <button class="btn btn-secondary" (click)="formSubmited = false;">Voltar</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
