import { Component, OnInit, HostListener, ViewChild, ElementRef, QueryList, ViewChildren, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CrudService } from 'src/app/_sevice/CrudService';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { TesteService } from '../../../_sevice/teste.service';

import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { localidade } from 'src/app/_configPage/listas/localidade';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { StepState } from '@angular/cdk/stepper';
import { GenericValidator } from 'src/app/_sevice/GenericValidator';

@Component({
  selector: 'app-solicitar-mediacao',
  templateUrl: './solicitar-mediacao.component.html',
  styleUrls: ['./solicitar-mediacao.component.less'],
})
export class SolicitarMediacaoComponent implements OnInit, OnChanges {
  @ViewChild('cidade') fieldName: ElementRef;
  @ViewChildren(MatAutocompleteTrigger) trigger: QueryList<MatAutocompleteTrigger>;
  @Input() state: StepState;
  @Output('ngModelChange') update = new EventEmitter();
  iskit = false;
  edicao = false;
  listuf: any[] = localidade.listuf;
  public listufFilter: any = this.listuf;
  public listCidade: any[];
  public listCidadeFilter: any;
  public exibeObrigatoriedadeCampos: string;
  public exibeObrigatoriedadeCampos1: string;
  public exibeObrigatoriedadeCampos2: string;
  public exibeObrigatoriedadeCampos3: string;
  public exibeObrigatoriedadeCampos4: string;
  ufSelected = this.storage.localStorageGet('localidade')?.uf || { "Id": "65b11f81c0095aa0cc393545", "IdEstado": 19, "Sigla": "RJ", "Nome": "Rio de Janeiro" };
  cidadeSelected = this.storage.localStorageGet('localidade')?.cidade || { "Id": "65b11fe7c0095aa0cc394397", "IdCidade": "3658", "IdEstado": "19", "Nome": "Rio de Janeiro" };
  itemAtual: any = this.storage.localStorageGet("item_atual");
  load: boolean = false;
  meet: any
  formMediacao: FormGroup = this.fb.group({
    tipoPolo: ['', Validators.required],
    infoPolo: this.criarFormGroupPolo(),
    poloContraria: this.criarFormGroupPolo(),
    infoAdicionais: this.criarFormInfoAdicionais(),
    cienteInstrucoes: ['', Validators.required],
    cienteProcessos: ['', Validators.required]
  });

  localidade = this.fb.group({
    uf: [this.ufSelected, Validators.required],
    cidade: [this.cidadeSelected, Validators.required],
  });
  selectedIndex = 0;
  valor = 0;
  constructor(private fb: FormBuilder,
    private service: CrudService,
    public storage: LocalStorage,
    private route: Router,
    public teste: TesteService,
    private routeAc: ActivatedRoute
  ) { }
  ngOnInit() {
    let categoria = this.routeAc.snapshot.paramMap.get('modulo');
    if (categoria != 'pagar') {
      this.buscarValorMeet()
      this.meet = this.storage.localStorageGet("meetForm")
      //this.formMediacao.setValue(meet)
      if (this.meet) {
        this.service.preencheCampos(this.meet, this.formMediacao)
        if (this.meet['passo'])
          this.selectedIndex = this.meet['passo']
        else
          this.meet['passo'] = 0

        this.storage.localStorageSet('meetForm', this.meet);

      }
      this.changeInputs();
    } else {
      this.selectedIndex = 3
    }
    console.log(this.meet)
  }
  ngOnChanges(changes: SimpleChanges): void {
    //if ( changes !== null ) {
    //  this.exibeObrigatoriedadeCampos = '';
    //}
  }
  public onChange(event: Event, item: number): void {
    if (event !== null || event !== undefined) {
      switch (item) {
        case 1:
          this.exibeObrigatoriedadeCampos1 = '';
          break;
        case 2:
          this.exibeObrigatoriedadeCampos2 = '';
          break;
        case 3:
          this.exibeObrigatoriedadeCampos3 = '';
          break;
        case 4:
          this.exibeObrigatoriedadeCampos4 = '';
          break;
      }
    }
   
  }
  setaDadosPessoa() {
    console.log(this.infoPolo?.get('tipoPessoa')?.value)
    if (this.selectedIndex == 0 && this.infoPolo?.get('tipoPessoa')?.value === 'Física') {
      let it = this.infoPolo.get('nome') as FormControl;
      if (it != null) {
        it.setValidators(Validators.required);
      }
      it.updateValueAndValidity();

      let gen = this.infoPolo.get('genero') as FormControl;
      if (gen != null) {
        gen.setValidators(Validators.required);
      }
      gen.updateValueAndValidity();

      let rz = this.infoPolo.get('razaoSocial') as FormControl;
      if (rz != null) {
        rz.clearValidators();
      }
      rz.updateValueAndValidity();

    } else if (this.selectedIndex == 0 && this.infoPolo?.get('tipoPessoa')?.value === 'Jurídica') {
      let it = this.infoPolo.get('razaoSocial') as FormControl;
      if (it != null) {
        it.setValidators(Validators.required);
      }
      it.updateValueAndValidity();

      let rz = this.infoPolo.get('nome') as FormControl;
      if (rz != null) {
        rz.clearValidators();
      }
      rz.updateValueAndValidity();

      let gen = this.infoPolo.get('genero') as FormControl;
      if (gen != null) {
        gen.clearValidators();
      }
      gen.updateValueAndValidity();

    } else if (this.selectedIndex == 1 && this.poloContraria?.get('tipoPessoa')?.value === 'Física') {
      let it = this.poloContraria.get('nome') as FormControl;
      if (it != null) {
        it.setValidators(Validators.required);
      }
      it.updateValueAndValidity();
      let rz = this.poloContraria.get('razaoSocial') as FormControl;
      if (rz != null) {
        rz.clearValidators();
      }
      rz.updateValueAndValidity();

      let gen = this.poloContraria.get('genero') as FormControl;
      if (gen != null) {
        gen.setValidators(Validators.required);
      }
      gen.updateValueAndValidity();

    } else if (this.selectedIndex == 1 && this.poloContraria?.get('tipoPessoa')?.value === 'Jurídica') {
      let it = this.poloContraria.get('razaoSocial') as FormControl;
      if (it != null) {
        it.setValidators(Validators.required);
      }
      it.updateValueAndValidity();

      let rz = this.poloContraria.get('nome') as FormControl;
      if (rz != null) {
        rz.clearValidators();
      }
      rz.updateValueAndValidity();

      let gen = this.poloContraria.get('genero') as FormControl;
      if (gen != null) {
        gen.clearValidators();
      }
      gen.updateValueAndValidity();

    }


  }
  stepperNext() {

    this.setaDadosPessoa()
    let meet = this.formMediacao.value;
    if (this.selectedIndex == 0) {
      this.infoPolo.markAllAsTouched();
      if (this.formMediacao.controls['tipoPolo']?.status === 'INVALID') {
        this.exibeObrigatoriedadeCampos1 = 'redClass';
      } else {
        this.exibeObrigatoriedadeCampos1 = '';
      }
      if (this.infoPolo.controls['tipoPessoa']?.status === 'INVALID') {
        this.exibeObrigatoriedadeCampos2 = 'redClass';
      } else {
        this.exibeObrigatoriedadeCampos2 = '';
      }
      if (this.infoPolo.controls['genero']?.status === 'INVALID') {
        this.exibeObrigatoriedadeCampos3 = 'redClass';
      } else {
        this.exibeObrigatoriedadeCampos3 = '';
      }
      if (this.infoPolo.controls['possuiAdvogado']?.status === 'INVALID') {
        this.exibeObrigatoriedadeCampos4 = 'redClass';
      } else {
        this.exibeObrigatoriedadeCampos4 = '';
      }
    } else if (this.selectedIndex == 1) {
      this.poloContraria.markAllAsTouched();
      if (this.poloContraria.controls['tipoPessoa']?.status === 'INVALID') {
        this.exibeObrigatoriedadeCampos2 = 'redClass';
      } else {
        this.exibeObrigatoriedadeCampos2 = '';
      }
      if (this.poloContraria.controls['genero']?.status === 'INVALID') {
        this.exibeObrigatoriedadeCampos3 = 'redClass';
      } else {
        this.exibeObrigatoriedadeCampos3 = '';
      }
      if (this.poloContraria.controls['possuiAdvogado']?.status === 'INVALID') {
        this.exibeObrigatoriedadeCampos4 = 'redClass';
      } else {
        this.exibeObrigatoriedadeCampos4 = '';
      }
    } else if (this.selectedIndex == 2) {
      this.infoAdicionais.markAllAsTouched();
    }
    if (this.selectedIndex == 0 && this.infoPolo.valid) {
      this.selectedIndex++;
    } else if (this.selectedIndex == 1 && this.poloContraria.valid) {
      this.selectedIndex++;
    } else if (this.selectedIndex == 2 && this.infoAdicionais.valid) {
      this.selectedIndex++;
    }
    console.log(this.infoPolo)
    meet['passo'] = this.selectedIndex;
    this.storage.localStorageSet('meetForm', meet);
  }
  stepperPrev() {
    let meet = this.formMediacao.value;
    if (this.selectedIndex == 0) {
      this.infoPolo.markAsUntouched();
    } else if (this.selectedIndex == 1) {
      this.poloContraria.markAsUntouched();
    } else if (this.selectedIndex == 2) {
      this.infoAdicionais.markAsUntouched();
    }
    this.selectedIndex--;
    meet['passo'] = this.selectedIndex;
    this.storage.localStorageSet('meetForm', meet)
  }

  get uf() {
    return this.localidade.get('uf') as FormControl;
  }
  get cidade() {
    return this.localidade.get('cidade') as FormControl;
  }
  get cepPolo() {
    return this.formMediacao.get('infoPolo.cep') as FormControl;
  }
  get cepPoloContraria() {
    return this.formMediacao.get('poloContraria.cep') as FormGroup;
  }
  get infoAdicionais(): FormGroup {
    return this.formMediacao.get('infoAdicionais') as FormGroup;
  }
  get infoPolo(): FormGroup {
    return this.formMediacao.get('infoPolo') as FormGroup;
  }
  get poloContraria(): FormGroup {
    return this.formMediacao.get('poloContraria') as FormGroup;
  }
  buscarValorMeet() {
    let url = environment.http.API + 'MeetingAccess/ValorMeet'
    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {
        if (response != null) {
          this.valor = response.valor
        }
      }, error: (err) => {
        console.error(err);
      }
    });
  }
  criarFormGroupPolo(): FormGroup {
    return this.fb.group({
      tipoPessoa: ['Física', Validators.required],
      genero: [''],
      cnpjCpf: ['', Validators.compose([Validators.required, GenericValidator.isValidCPFCNPJ()])],
      nome: [''],
      nomeSocial: [''],
      razaoSocial: [''],
      nomeFantasia: [''],
      cep: ['', Validators.required],
      municipio: ['', Validators.required],
      uf: ['', Validators.required],
      logradouro: ['', Validators.required],
      numero: ['', Validators.required],
      complemento: [''],
      bairro: ['', Validators.required],
      telefone: ['', Validators.required],
      email: ['', Validators.compose(
        [
          Validators.required,
          Validators.email
        ]
      )],
      possuiAdvogado: ['', Validators.required],
      arquivos: new FormControl([])
    });
  }
  criarFormInfoAdicionais() {
    return this.fb.group({
      competencia: ['', Validators.required],
      relatoFatos: ['', Validators.required],
      pedidoSucinto: ['', Validators.required],
      valorSugerido: ['', Validators.required]
    });
  }
  changeInputs() {
    this.cepPolo.valueChanges.subscribe(cep => {
      if (cep.length == 8)
        this.buscarEndereco('infoPolo', cep);
    });
    this.cepPoloContraria.valueChanges.subscribe(cep => {
      if (cep.length == 8)
        this.buscarEndereco('poloContraria', cep);
    });
  }
  buscarEndereco(groupName: string, cep: any) {
    this.service.getObservableDirect('https://viacep.com.br/ws/' + cep + '/json').subscribe({
      next: async (response: any) => {
        if (!response['erro']) {
          let logLimpo = response.logradouro.replace('Rua ', '').replace('Av. ', '').replace('Avenida ', '').replace('Alameda ', '')
          this.formMediacao.get(`${groupName}.logradouro`)?.setValue(logLimpo);
          this.formMediacao.get(`${groupName}.bairro`)?.setValue(response.bairro);
          this.ufSelected = localidade.listuf.find((x: any) => x.Sigla == response.uf);
          this.formMediacao.get(`${groupName}.uf`)?.setValue(this.ufSelected);
          this.loadCidade(groupName, this.ufSelected.IdEstado, true, false, response.localidade);
          this.setTipoLogradouro(response.logradouro);
        } else {
          this.formMediacao.get('cep')?.setErrors({ cepNotMatch: true });
        }
        (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%";
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
  meetSalva: any
  salvar() {
    this.formMediacao.markAllAsTouched();
    if (!this.infoPolo.valid) {
      this.selectedIndex = 0
    }
    else if (!this.poloContraria.valid) {
      this.selectedIndex = 1
    }
    else if (!this.infoAdicionais.valid) {
      this.selectedIndex = 2
    }
    if (this.formMediacao.invalid) {
      const cienteInstrucoes = this.formMediacao?.controls['cienteInstrucoes'].valid;
      const cienteProcessos = this.formMediacao?.controls['cienteProcessos'].valid;
      if (!cienteInstrucoes && !cienteProcessos) {
        this.exibeObrigatoriedadeCampos = 'redClass';
      }
      return;
    } else {
      this.exibeObrigatoriedadeCampos = '';
    }
    let use = this.storage.localStorageGet("userlogged")
    if (use == null || use?.keyLogin == undefined || use?.keyLogin == null) {
      this.storage.localStorageRemove('logado');
      this.storage.localStorageRemove('userlogged');
      this.storage.localStorageSet('meetForm', this.formMediacao.value);
      this.route.navigate(['/login/entrar']);
    } else {
      const objMeeting = this.criarObjMeetingForm(this.formMediacao.value);

      (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";
      let userlogged = this.storage.localStorageGet('userlogged')
      if (userlogged && userlogged['dadosFaturamento']) {
        let existeDados = JSON.parse(userlogged['dadosFaturamento'])
        if (existeDados?.nome != null) {
         
          objMeeting['dadosFaturamento'] = JSON.stringify(existeDados)
        }
      }
      objMeeting['pedidoGratuito'] = true
      objMeeting['pendenciaPagamento'] = false
      objMeeting['pagamentoGerado'] = true
      objMeeting['historico'] = [{ acao: 'solicitação da mediacao', usuario: use.nomeUsuario, guidUser: use?.keyLogin, data: new Date() }]

      this.service.postObservable(objMeeting, 'MeetingAccess').subscribe({
        next: (response) => {
          this.meetSalva = response
        },
        complete: () => {
          (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";
          let urlBack = environment.http.API_BACK + 'Empresarial/Pedido';
          this.service.postObservableDirect(urlBack, this.meetSalva).subscribe({
            next: async (responseBack) => {
              this.selectedIndex = 3;
              let meet = this.formMediacao.value;
              meet['passo'] = this.selectedIndex;
              this.storage.localStorageRemove('meetForm');
              this.route.navigate(['mediacao/institucional/pagar/' + this.meetSalva.guidPedido])
            }, error: (err) => {
              console.log(err);
            },
            complete: () => {

            }
          });
        }
      });
    }
  }


  setaArquivosPolo(entrada: any) {
    if (entrada.tipo === 1) {
      let documentos = this.infoPolo.get('arquivos')?.value
      let resul = [...documentos, ...entrada.arquivos]
      this.infoPolo.get('arquivos')?.setValue(resul);
    } else if (entrada.tipo === 2) {
      let documentos = this.poloContraria.get('arquivos')?.value
      let resul = [...documentos, ...entrada.arquivos]
      this.poloContraria.get('arquivos')?.setValue(resul);
    }
  }
  resetaArquivosPolo(entrada: any) {
    if (entrada.tipo === 1) {
      let documentos = entrada.arquivos
      this.infoPolo.get('arquivos')?.setValue(documentos);
    } else if (entrada.tipo === 2) {
      let documentos = entrada.arquivos
      this.poloContraria.get('arquivos')?.setValue(documentos);
    }
  }


  criarObjMeetingForm(objForm: any): any {
    let use = this.storage.localStorageGet("userlogged")
    if (use == null) {
      this.storage.localStorageSet("meetAndamento", true)
      this.route.navigate(['login/entrar'])

    } else {
      return {
        origem: "CNR",
        guidUser: use.keyLogin,
        emailUser: use.email,
        telefoneUser: use.telefone,
        nomeUser: use.nomeUsuario,
        guidPedido: crypto.randomUUID(),
        meetingInfo: [{
          meetingGuid: crypto.randomUUID(),
          participantes: [
            this.criarObjParticipantePolo(objForm.infoPolo, objForm.tipoPolo),
            this.criarObjParticipantePolo(objForm.poloContraria, 'Parte Contrária')
          ],
          informacoesAdicionais: {
            Competencia: objForm.infoAdicionais.competencia,
            RelatoFatos: objForm.infoAdicionais.relatoFatos,
            PedidoSucinto: objForm.infoAdicionais.pedidoSucinto,
            ValorSugerido: objForm.infoAdicionais.valorSugerido
          },
        }],
        totalPagarMeet: this.valor,
        totalPagar: this.valor,
        totalPedido: this.valor,
        totalAdicionalAdm: 0,
        idPedido: 0,
        v: '1',
        exigencia: {
          taxaCNR: 0,
          taxaAdministracao: 0,
          honorario: 0
        },
        listaCompra: [
          {
            idItem: crypto.randomUUID(),
            compradores: [
              { nome: '' }
            ],
            idPedidoAtual: 0,
            listCartorio: [
              {
                "idCartorio": 10460,
                "cartorio": "Convênio CNR",
                "siglaConvenio": "ANR",
                "municipio": ""
              }],
            produto: {
              "titulo": "Mediação CNR",
              "aLink": "",
              "descricao": "Ato a ser utilizado na cobrança da sala de reuniao da CNR",
              "idAto": 10170,
              "idTipoAto": 7,
              "idForm": 0,
              "semLocalidade": true,
              "descricaoTipoAto": "Mediação",
              "origem": "RJ"
            }
          }
        ],
        status: {
          slug: 'pagamento-analise-realizado', descricao: "Pagamento Análise Realizado"
        },
        agendamentoConcluido: false
      };
    }
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {
      this.teste.setValueForm(this.poloContraria);
      this.teste.setValueForm(this.infoAdicionais);
      this.teste.setValueForm(this.infoPolo);
      this.teste.setValueForm(this.formMediacao);
    }
  }
  criarObjParticipantePolo(objPolo: any, tipoPoloDesc: string): any {
    return {
      userGuid: crypto.randomUUID(),
      tipoPolo: tipoPoloDesc,
      tipoPessoa: objPolo.tipoPessoa,
      nome: objPolo.nome,
      email: objPolo.email,
      genero: objPolo.genero,
      nomeSocial: objPolo.nomeSocial,
      razaoSocial: objPolo.razaoSocial,
      nomeFantasia: objPolo.nomeFantasia,
      cnpjCpf: objPolo.cnpjCpf,
      telefone: objPolo.telefone,
      possuiAdvogado: objPolo.possuiAdvogado,
      arquivos: objPolo.arquivos,
      endereco: {
        cep: objPolo.cep,
        uf: objPolo.uf,
        municipio: objPolo.municipio,
        bairro: objPolo.bairro,
        logradouro: objPolo.logradouro,
        numero: objPolo.numero,
        complemento: objPolo?.complemento
      }
    };
  }
  /** autocomplete estado e cidade */
  setTipoLogradouro(logra: any) {
    if (logra.indexOf('Rua') == 0) {
      this.formMediacao.get('tipoLogradouro')?.setValue('RUA');
    } else if (logra.indexOf('Av. ') == 0 || logra.indexOf('Avenida') == 0) {
      this.formMediacao.get('tipoLogradouro')?.setValue('AVENIDA');
    } else if (logra.indexOf('Alameda') == 0) {
      this.formMediacao.get('tipoLogradouro')?.setValue('ALAMEDA');
    }
  }
  public displayPropertyCidade(value: any) {
    if (value) {
      return value.Nome;
    }
  }
  public displayProperty(value: any) {
    if (value) {
      return value.Nome;
    }
  }
  getCidade(groupName: string, event: any) {
    this.ufSelected = this.uf.value;
    this.setLocal();
    this.loadCidade(groupName, event.option.value['IdEstado'], true, true, this.formMediacao.get('cidade'));
  }
  setLocal() {
    this.storage.localStorageSet('localidade', this.localidade?.value);
    this.cidadeSelected = this.cidade?.value;
  }
  loadCidade(groupName: string, IdEstado: any, setCidade: boolean = false, carrregarEdicao: boolean = false, cidade: any = null) {
    const url = environment.http.API + 'Localidade/cidade?IdEstado=' + IdEstado;
    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {
        if (response != null) {
          this.formMediacao.get('Cidade')?.setValue(null);
          this.listCidadeFilter = response;
          this.listCidade = response;
          if (setCidade) {
            let cit = response.find((x: any) => x.Nome?.toLowerCase() == cidade?.toLowerCase());
            if (groupName === 'infoPolo') {
              this.infoPolo.get('municipio')?.setValue(cit);
            } else {
              this.poloContraria.get('municipio')?.setValue(cit);
            }
          }
        }
      }, error: (err) => {
        console.error(err);
      }
    });
  }
  public cancelForm(): void {
    this.storage.localStorageRemove('meetForm');
    this.route.navigate(['/']);
  }
}
