import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Injectable, Input } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';
import { CrudService } from '../../_sevice/CrudService';
type Nullable<T> = T | null;
/**
 * Node for to-do item
 */
export class TodoItemNode {
  IdItem: number;
  IdItemPai?: Nullable<number>;
  Descricao: string;
  Icon: string;
  Ordem: number;
  children: TodoItemNode[];
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  IdItem: number;
  IdItemPai?: Nullable<number>;
  Descricao: string;
  Icon: string;
  Ordem: number;
  level: number;
  expandable: boolean;
  children: TodoItemNode[];
}


@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] {
    return this.dataChange.value.sort(function (a, b) {
      var nameA = a.Descricao.toLowerCase(), nameB = b.Descricao.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });
  }

  constructor() {

  }

  initialize(dados: any) {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    const data = this.buildFileTree(dados, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: any, level: number): TodoItemNode[] {

    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      //console.log(accumulator)



      const value = key == 'Descricao' ? obj[key] : null;
      const node = obj[key];

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.Descricao = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: any) {

    if (parent && parent.children) {
      parent.children.push({
        IdItem: 0,
        IdItemPai: parent.IdItem,
        Descricao: '',
        Icon: '',
        Ordem: 0,
        children: []
      } as TodoItemNode);
      this.dataChange.next(this.data);
    } else {
      //console.log(this.data)
      this.data.unshift({
        IdItem: 0,
        IdItemPai: null,
        Descricao: '',
        Icon: '',
        Ordem: 0,
        children: []
      } as TodoItemNode);
      this.dataChange.next(this.data);
    }
  }
  deleteItem(parent: any, item: any) {

    if (parent && parent.children) {

      let idx = parent.children.findIndex((x: any) => x.IdItem == item.IdItem)

      parent.children.splice(idx, 1);
      this.dataChange.next(this.data);
    } else {
      let idx = this.data.findIndex((x: any) => x.IdItem == item.IdItem)
      this.data.splice(idx, 1);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TodoItemNode, name: string, idinsert: number) {
    node.Descricao = name;
    node.IdItem = idinsert;
    this.dataChange.next(this.data);
  }

  editItem(node: any, newItemValue: string) {
    node.Descricao = newItemValue;
    this.dataChange.next(this.data);
  }
}
/**
 * @title Tree with checkboxes
 */
@Component({
  selector: 'app-tree',
  templateUrl: 'tree.component.html',
  styleUrls: ['tree.component.less'],
  providers: [ChecklistDatabase]
})
export class TreeComponent {


  constructor(
    private _database: ChecklistDatabase
    , private crudService: CrudService
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  @Input() conteudoPagina: any;
  @Input() configPage: any;
  @Input() collectionSize: any;


  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.Descricao === node.Descricao ? existingNode : new TodoItemFlatNode();
    flatNode.Descricao = node.Descricao;
    flatNode.IdItem = node.IdItem;
    flatNode.IdItemPai = node.IdItemPai;

    flatNode.children = node.children;
    flatNode.level = level;
    flatNode.expandable = true;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  treeControl = new FlatTreeControl<TodoItemFlatNode>(node => node.level, node => node.expandable);
  treeFlattener = new MatTreeFlattener(this.transformer, node => node.level, node => node.expandable, node => node.children);
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  //treeControl: FlatTreeControl<TodoItemFlatNode>;

  //treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  //dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);



  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.Descricao === '';


  ngOnInit() {
    this._database.initialize(this.conteudoPagina.optionsProp)
    this._database.dataChange.subscribe(data => { this.dataSource.data = data; });
  }


  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: TodoItemFlatNode) {
    const parentNode = this.flatNodeMap.get(node);

    this._database.insertItem(parentNode!);

    this.treeControl.expand(node);
  }
  addRaiz() {

    this._database.insertItem(null);

  }
  async deleteItem(node: TodoItemFlatNode) {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    let itenPut = { ...node }
    let url = this.conteudoPagina.urlExluir ? this.conteudoPagina.urlExluir : this.conteudoPagina.urlRest
    await this.crudService.deleteObservable(url + '/' + itenPut.IdItem).subscribe(
      data => {
        this._database.deleteItem(parent, node);
      }
    );

  }
  /** Save the node to database */
  async saveNode(node: TodoItemFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);

    let itenPut = { ...node }
    itenPut.Descricao = itemValue;

    if (itenPut.IdItem == 0) {
      let url = this.conteudoPagina.urlInserir ? this.conteudoPagina.urlInserir : this.conteudoPagina.urlRest
      await this.crudService.postObservable(itenPut, url).subscribe(
        data => {
          //console.log(data)

          this._database.updateItem(nestedNode!, itemValue, data.IdItem);
        }
      );
    }
  }
  async cancelSaveNode(node: TodoItemFlatNode) {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    this._database.deleteItem(parent, node);

  }
  async updateNode(node: any) {
    //console.log(node)
    const nestedNode = this.flatNodeMap.get(node);
    let url = this.conteudoPagina.urlAlterar ? this.conteudoPagina.urlAlterar : this.conteudoPagina.urlRest
    let itupdate = { ...node };
    itupdate.children = null;

    await this.crudService.putObservable(url, itupdate).subscribe(
      data => {
        //console.log(data)
        node['editNode'] = false;

        this._database.editItem(nestedNode, node.Descricao);

      }
    );

    //await this.crudService.putObservable(node, this.conteudoPagina.urlIncluir).subscribe(data => {

    // });
  }

  editNode(node: any) {
    node['editNode'] = true;
  }
}
