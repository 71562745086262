<div *ngIf="!efeturarPagamento">

  <div *ngIf="tipoUploadExigencia == 'TEXTO' && exigencia?.tipoExigencia != 'Documental' && exigencia?.statusExigencia != 'Exigência Finalizada'">
    <form [formGroup]="formMensagens" (submit)="salvarMensagem()">
      <div class="d-flex align-items-center linha-exigencia">
        <div class="col-lg-8 m-2">
          <label>Mensagem</label>
          <input type="text" matInput class="form-control" maxlength="550"
                 [ngClass]="{'is-invalid': validarMensagemUploadRequerid()}" formControlName="mensagemUpload" />
          <mat-error [style.visibility]="validarMensagemUploadRequerid() ? 'visible' : 'hidden'">
            Preenchimento obrigatório
          </mat-error>
        </div>

        <div class="col-lg-4 m-2">
          <input type="submit" class="btn btn-primary btn-sm me-2" value="salvar" />

          <button mat-button class="btn btn-light btn-sm" (click)="pagar()"
                  *ngIf="exigencia?.tipoExigencia == 'Financeira'">
            <mat-icon>paid</mat-icon>
            <span>pagar</span>
          </button>

        </div>
      </div>
    </form>
  </div>
  <!--//-->
  <div *ngIf="tipoUploadExigencia == 'TEXTO' && exigencia?.tipoExigencia == 'Documental'">
    <form [formGroup]="formMensagens" (submit)="salvarMensagem()">
      <div class="d-flex align-items-center linha-exigencia">
        <div class="col-lg-8 m-2">
          <label>Mensagem</label>
          <input type="text" matInput class="form-control" maxlength="550"
                 [ngClass]="{'is-invalid': validarMensagemUploadRequerid()}" formControlName="mensagemUpload" />
          <mat-error [style.visibility]="validarMensagemUploadRequerid() ? 'visible' : 'hidden'">
            Preenchimento obrigatório
          </mat-error>
        </div>

        <div class="col-lg-4 m-2">
          <input type="submit" class="btn btn-primary btn-sm me-2" value="salvar" />
          <button mat-button class="btn btn-light btn-sm d-flex"
                  *ngIf="exigencia?.tipoExigencia == 'Documental'"
            (click)="selecionarTipoUploadExigencia()">
            <mat-icon>upload_file</mat-icon>
            <span>Enviar Documentos</span>
          </button>
        </div>
                <!--<div class="col-lg-4 m-2">
                <input type="submit" class="btn btn-primary btn-sm me-2" value="salvar" />

                <button mat-button class="btn btn-light btn-sm d-flex"
                *ngIf="exigencia?.tipoExigencia == 'Documental'">
                <mat-icon>upload_file</mat-icon>
                <span>Enviar Documentos</span>
                </button>

                </div>-->
        </div>
</form>
  </div>

  <!--//-->

  <div class="uploadExigencia" *ngIf="tipoUploadExigencia == 'DOCUMENTO'">
    <form [formGroup]="formArquivos">
      <div class="row d-flex flex-wrap">
        <div class="col-md-12" [class]="classValidarArquivos">
          <h2>Arquivos</h2>
          <app-uploadFiles [tipo]="1" (setaArquivos)="setaArquivos($event)" [filesAtu]="arquivos"
                           (resetaArquivos)="resetaArquivos($event)"></app-uploadFiles>
          <mat-error *ngIf="classValidarArquivos">
            Preenchimento obrigatório
          </mat-error>
        </div>
      </div>
    </form>
  </div>

  <app-lista-mensagens-exigencia [mensagens]="exigencia?.listaMensagem"></app-lista-mensagens-exigencia>

</div>
<!-- pagar exigencia -->
<div class="wrapper-item mt-4" *ngIf="efeturarPagamento">
  <app-payment-v2 [pedido]="pedido" [compraAtual] ="compraAtual" [exigencia]="exigencia"> </app-payment-v2>

  <div class="text-end">
    <button mat-button class="btn btn-light btn-sm" *ngIf="efeturarPagamento"
            (click)="efeturarPagamento = !efeturarPagamento">
      Voltar
    </button>
  </div>
</div>
<!-- fim pagar exigencia -->
