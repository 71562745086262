import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild, signal } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarEvent, CalendarEventAction, CalendarView, CalendarWeekViewBeforeRenderEvent } from 'angular-calendar';
import { MonthViewDay } from 'calendar-utils';
import { format, isAfter, isBefore, isSameDay, isSameMonth, parse, parseISO } from 'date-fns';
import { Schedule } from '../schedule';
import { ScheduleService } from '../schedule.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Expert } from '../../experts/expert';
import { DeviceDetectorService } from 'ngx-device-detector';
import { forEach } from 'lodash';
import { LocalStorage } from '../../../_entidade/LocalStorage';
@Component({
  selector: 'app-schedules-list',
  templateUrl: './schedules-list.component.html',
  styleUrls: ['./schedules-list.component.css']
})
export class SchedulesListComponent implements OnInit, AfterViewInit {

  @ViewChild('modalContent', { static: true }) modalContent!: TemplateRef<any>;
  pipe = new DatePipe('pt-BR');
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  locale: string = 'pt-br';
  activeDayIsOpen = false;
  view = CalendarView.Week;
  events: CalendarEvent[] = [];
  expert: Expert[] = [];
  modalData!: { schedule: any };
  dayStartHour: number = 8;
  dayEndHour: number = 18;
  filtroCalendatio: any = {
    dataInicial: '2024-09-22',
    dataFinal: '2024-09-28'
  }
  user = this.storage.localStorageGet('userlogged');

  protected isMobile = signal(false);

  constructor(
    private router: Router,
    public divice: DeviceDetectorService,
    private modal: NgbModal,
    public storage: LocalStorage,
    private cdr: ChangeDetectorRef,
    private scheduleService: ScheduleService) { }

  ngOnInit(): void {
    this.getDataCalendarioWeek(new Date());
    this.isMobile.set(this.divice.isMobile())

  }
  ngAfterViewInit() {

    this.cdr.detectChanges();
  }



  getDataCalendarioWeek(d: Date) {

    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 0);

    let dtajustadaInicio = new Date(d.setDate(diff))

    var dayf = dtajustadaInicio.getDay()
    let difff = dtajustadaInicio.getDate() + 6

    let dtajustadaFim = new Date(d.setDate(difff))

    this.filtroCalendatio.dataInicial = dtajustadaInicio.getFullYear() + '-' + ("0" + (dtajustadaInicio.getUTCMonth() + 1)).slice(-2) + '-' + ("0" + dtajustadaInicio.getDate()).slice(-2)
    this.filtroCalendatio.dataFinal = dtajustadaFim.getFullYear() + '-' + ("0" + (dtajustadaFim.getMonth() + 1)).slice(-2) + '-' + ("0" + dtajustadaFim.getDate()).slice(-2)

    this.loadSchedules();
  }

  getDataCalendarioMont(d: Date) {

    var date = d, y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    this.filtroCalendatio.dataInicial = firstDay.getFullYear() + '-' + ("0" + (firstDay.getUTCMonth() + 1)).slice(-2) + '-' + ("0" + firstDay.getDate()).slice(-2)
    this.filtroCalendatio.dataFinal = lastDay.getFullYear() + '-' + ("0" + (lastDay.getMonth() + 1)).slice(-2) + '-' + ("0" + lastDay.getDate()).slice(-2)

    this.loadSchedules();
  }


  setView(view: CalendarView) {
    this.view = view;
    console.log(this.viewDate)
    let nvdate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate());
    if (view == CalendarView.Month) {
      this.getDataCalendarioMont(nvdate)
    } else {
      this.getDataCalendarioWeek(nvdate);

    }

  }

  closeActiveDay() {
    this.activeDayIsOpen = false;
  }

  activeRangeWeek(ev: any) {
    if (this.view == CalendarView.Month) {
      this.getDataCalendarioMont(ev)
    } else {
      this.getDataCalendarioWeek(ev);

    }
  }

  onDayClick({ date, events }: MonthViewDay) {
    if (isSameMonth(date, this.viewDate)) {
      if (events.length === 0 || (isSameDay(this.viewDate, date) && this.activeDayIsOpen)) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  onEventClick(event: CalendarEvent) {

    event.meta?.anfitrioes?.forEach((anf: any) => {
      anf.bg = this.gerarCorAleatoria();
    });
    event.meta?.participantes?.forEach((anf: any) => {
      anf.bg = this.gerarCorAleatoria();
    });
    event.meta?.mediadores?.forEach((anf: any) => {
      anf.bg = this.gerarCorAleatoria();
    });

    this.modalData = { schedule: event.meta };

    this.modal.open(this.modalContent, { size: 'lg' });
  }

  onSegmentClick(date: Date) {
    if (isAfter(date, new Date())) {
      this.router.navigate(['schedules/new'], {
        queryParams: {
          date: format(date, 'yyyy-MM-dd'),
          initTime: format(date, 'HH:mm')
        }
      });
    }
  }

  onBeforeRenderWeek({ hourColumns }: CalendarWeekViewBeforeRenderEvent) {

    //this.filtroCalendatio.dataInicio = hourColumns[0].date.getFullYear() + '-' + ("0" + (hourColumns[0].date.getUTCMonth() + 1)).slice(-2) + '-' + hourColumns[0].date.getDate()
    //this.filtroCalendatio.dataFim = hourColumns[6].date.getFullYear() + '-' + ("0" + (hourColumns[6].date.getMonth() + 1)).slice(-2) + '-' + hourColumns[6].date.getDate()


    const todayDate = new Date();
    const hours = hourColumns.flatMap(hc => hc.hours);
    const segments = hours.flatMap(hours => hours.segments);
    segments.forEach(segment => {
      segment.cssClass = isBefore(segment.date, todayDate) ? 'cell-disabled' : 'cell-available';
    });
  }

  private buildEventActions(schedule: Schedule) {
    const events: CalendarEventAction[] = [];
    events.push({
      label: '<i class="fa-solid fa-pencil mx-1 text-purple"></i>',
      onClick: (): void => {
        this.router.navigate(['schedules', schedule.id]);
      }
    });
    events.push({
      label: '<i class="fa-solid fa-trash-can mx-1 text-purple"></i>',
      onClick: (): void => {

      }
    });
    return events;
  }

  private buildEvent(schedule: Schedule) {

    const parsedDate = parseISO(schedule.date);

    const event: CalendarEvent = {
      title: schedule.title + "<br>" + schedule.initTime + " - " + schedule.endTime,
      start: parse(schedule.initTime, 'HH:mm', parsedDate),
      end: parse(schedule.endTime, 'HH:mm', parsedDate),
      cssClass: 'event-body',
      color: {
        primary: 'rgb(3, 155, 229)',
        secondary: 'rgb(3, 155, 229)'
      },
      meta: schedule
    }
    if (this.view == CalendarView.Month) {
      event.color = {
        primary: 'rgb(3, 155, 229)',
        secondary: '#dee1a4'
      }
    }

    if (isAfter(event.start, new Date())) {
      event.actions = this.buildEventActions(schedule);
    }
    return event;
  }

  private loadSchedules() {
    this.scheduleService.findAllPost(this.filtroCalendatio).subscribe(response => {
      this.events = response.map(schedule => this.buildEvent(schedule));
    });
  }

  public gerarCorAleatoria(): string {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomColor.padStart(6, '0')}`;
  }

}
