<div class="wrapper-item" *ngIf="!compra?.editar">
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Livro</b></h3>
      <p class="info-section">{{compra.livro}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Folha</b></h3>
      <p class="info-section">{{compra.folha}}</p>
    </div>
  </div>
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Data Ato</b></h3>
      <p class="info-section">{{compra.dataAto | date:'dd/MM/yyyy':'UTC'}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Natureza do Ato</b></h3>
      <p class="info-section">{{compra.natureza}}</p>
    </div>
  </div>
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>CPF | CNPJ</b></h3>
      <p class="info-section">{{compra.documento}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Nome da Busca</b></h3>
      <p class="info-section">{{compra.nomeBusca}}</p>
    </div>
  </div>
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Nome do Pai</b></h3>
      <p class="info-section">{{compra.pai}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Nome da Mãe</b></h3>
      <p class="info-section">{{compra.mae}}</p>
    </div>
  </div>
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Observação</b></h3>
      <p class="info-section">{{compra.observacao}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>CERP</b></h3>
      <p class="info-section">{{compra?.idItem}}</p>
    </div>
  </div>
</div>

<app-form-3 *ngIf="compra?.editar" [edicao]="true" [itemEdicao]="compra" (cancelarEdicao)="cancelarEdicao(compra)" (salvarItem)="salvarItem($event)"
             [itemAtual]="compra.produto"></app-form-3>
