import { Component, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { CrudService } from '../../_sevice/CrudService';

interface UploadEvent {
  tipo: any;
  arquivos: any;
}
@Component({
  selector: 'app-uploadFiles',
  templateUrl: './uploadArquivos.component.html',
  styleUrls: ['./uploadArquivos.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadFilesComponent),
      multi: true
    }
  ]
})
export class UploadFilesComponent implements OnInit, ControlValueAccessor {
  public files: any[] = [];
  private onChange: (value: any) => void;
  private onTouched: () => void;
  public http: any;

  @Input() filesAtu: any;
  @Input() itemAtual: any;
  @Input() prodAtu: any;
  @Input() teste: any;
  @Input() extensaoTipos: string[] = ['pdf'];
  @Input() tipo: any;
  @Input() classObrigatoria: any;
  @Output() filesChangeEmiter: EventEmitter<UploadEvent> = new EventEmitter();
  // @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
  @Output() setaArquivos: EventEmitter<any> = new EventEmitter();
  @Output() resetaArquivos: EventEmitter<any> = new EventEmitter();

  @ViewChild(UploadFilesComponent) uploadComponent!: UploadFilesComponent;

  acceptApplication: string;

  constructor(
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public cs: CrudService,
  ) {
  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {
    this.carregarAcceptApplication();
   
    if (this.filesAtu && this.filesAtu?.length > 0) {
      this.files = this.filesAtu
      
    }
  }

  carregarAcceptApplication() {
    let accetsLista = this.extensaoTipos.map(extensao => `.${extensao}`);
    this.acceptApplication = accetsLista.join(',');
  }


  handleClickUpload(tipo: any) {
    // Usando template literals para construir o seletor corretamente
    let selector = `input[type="file"][id='file${tipo}']`;
    let el = document.querySelector(selector) as HTMLElement;

    // Verifica se o elemento foi encontrado e clica nele
    el?.click();
    //console.log('click', el);

  }

  onDragOver(event: Event) {
    event.preventDefault(); // Evita o comportamento padrão para permitir o drop
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    // Chamando onFileChange diretamente com o evento
    this.onFileChange(event);
  }

  writeValue(files: any[]): void {
    if (files) {
      this.files = files;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onFileChange(event: any) {
    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    let fileList: FileList;

    // Verificando a origem do evento e atribuindo fileList corretamente
    if (event.dataTransfer)
      fileList = event.dataTransfer.files;
    else if (event.target)
      fileList = event.target.files;
    else {
      (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%";

      console.error("Evento de arquivo não reconhecido.");
      return;
    }

    //verficar a extensão
    let extensao = fileList[0].name.split('.').pop();

    if (this.extensaoTipos.find(e => e == extensao) != null) {
      let files: File[] = Array.from(fileList);
      let id = crypto.randomUUID();

      const url = environment.http.API_SISTEMA + 'Arquivo\\InserirMultiplosArquivos?id=' + id + "&sistema=cnr";
      const formData = new FormData();

      for (const index in files) {
        if (Object.prototype.hasOwnProperty.call(files, index)) {
          const thisFile = files[index];

          formData.append('files[]', thisFile);
          formData.append('paths[]', thisFile.webkitRelativePath);
        }
      }

      this.cs.postObservableDirect(url, formData).subscribe(
        (resultado: any) => {
          if (this.itemAtual == null || this.itemAtual == undefined)
            this.setaArquivos.emit({ tipo: this.tipo, arquivos: resultado });
          else
            this.itemAtual['arquivos'] = resultado

          if (this.files)
            this.files = [...this.files, ...resultado];
          else
            this.files = resultado;

          (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%";
          (<HTMLInputElement>document.getElementsByName("file")[0]).value = "";

        },
        (error) => {
          (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%";
          console.error('Erro ao enviar arquivo:', error);
        }
      );
    } else {
      (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%";

    }
  }


  deleteFile(f: any) {
    this.files = this.files.filter(function (w) { return w.nomeOriginal != f.nomeOriginal });
    if (this.itemAtual == null || this.itemAtual == undefined)
      this.resetaArquivos.emit({ tipo: this.tipo, arquivos: this.files });
    else
      this.itemAtual['arquivos'] = this.files
  }

}
