<div class="m-auto">
  <div class="uploadzone " [class]="classObrigatoria ? 'redClass' :''" (dragover)="onDragOver($event)" (drop)="onDrop($event)">
    <div class="text-wrapper">
      <div class="centered d-inline-flex flex-column justify-content-center gap-3">
        <input type="file" name="file" [attr.id]="'file' + tipo" style="display: none;" (change)="onFileChange($event)"
          multiple [accept]="acceptApplication">
         <!-- accept="application/pdf"  <img loading="lazy" src="/assets/PDF_file_icon.png" alt="e-cartorio icone documento"
                                   class="img-fluid" -->
        <span class="m-auto fs-1 material-symbols-outlined">
          upload_file
        </span>
        <label [attr.for]="'file' + tipo"><span class="textLink">Arraste e solte aqui um arquivo para enviar!
            {{(extensaoTipos.length == 1 && extensaoTipos[0].toUpperCase() == 'PDF' ? '(SOMENTE PDF)' :
            '')}}</span></label>
        <button class="m-auto align-items-center mb-3" type="button" (click)="handleClickUpload(tipo)">
          Selecionar um arquivo
          <span class="material-symbols-outlined">
            cloud_upload
          </span>
        </button>
      </div>
    </div>
  </div>
</div>


<mat-list class="d-flex gap-3 justify-content-center">
  <mat-list-item class="w-auto h-auto content-item-list" *ngFor="let file of files">
    <h4 class="mb-0" mat-line>{{file.nomeOriginal}}</h4>
    <button mat-raised-button color="warn" type="button" [title]="file.name" (click)="deleteFile(file)">Delete</button>
    <mat-divider></mat-divider>
  </mat-list-item>
</mat-list>
