<div class="py-0">
  <div class="py-0 ">
    <div [style]="conteudo.style" class="py-0 image-container">
      <div class="w-100 ">
        <!--banner-->
        <div class="pt-4">
          <div class="col-12 d-flex justify-content-center align-items-center" style="background: url('../../../assets/banner-nossos-cursos.jpg'); background-size: cover; min-height: 62vh;">
            <div class="w-100"><h1 class="position-relative text-center fs-1 fw-bold text-white">Nossos Cursos</h1></div>
          </div>
        </div>
        <div class="container" style="display: flex; ">
          <div class="row py-4 w-100">
            <div class="col-12">
              <div class="w-100 d-inline-flex flex-wrap gap-5 justify-content-start">
                <div *ngFor="let curso of cursos" class="" [style]="curso.style" style="width: 30.5%; border: 1px solid #f1f1f1; border-radius: 5px; background-color: #f1f1f1; ">
                  <div>
                    <div><img [src]="curso.pathImg" class="rounded-top" [style]="curso.imgStyle"></div>
                    <div class="" style="min-height: 7rem;">
                      <div [innerHTML]="curso.titulo" class="fs-5 lh-base text-black fw-bold text-center py-3"></div>
                    </div>
                    <div class="d-flex justify-content-center">
                      <a  [href]="curso.link" class="category" target="_blank">
                        <button class="btn btn-primary">{{ curso.btnTxt }}</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
