import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import saveAs from 'file-saver';
import { LocalStorage } from 'src/app/_entidade/LocalStorage';
import { CrudService } from 'src/app/_sevice/CrudService';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { debounce } from 'lodash';
import { DateValidators } from '../generic-form/generic-form.component';

export class DateValidator {

  static ptDate(control: FormControl): { [key: string]: any } {
    let ptDatePattern = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;

    if (!control.value.match(ptDatePattern))
      return { "ptDate": true };

    return { "ptDate": false };
  }
}

@Component({
  selector: 'app-meus-pedidos',
  templateUrl: './meus-pedidos.component.html',
  styleUrls: ['./_meus-pedidos.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class MeusPedidosComponent implements OnInit {
  @Input() conteudo: any;
  showMaisFiltro: boolean = false
  paginaAtual: number = 1;
  tamanhoPagina: number = 10;
  totalItens: number = 0;
  pedidos: any
  isLinear = true;
  panelOpenState = false;
  //public visibilidadeDetalhes: boolean = false;
  show: boolean = false
  showDadosFaturamento: boolean = false
  semRequerente: boolean = true
  camposForm: any
  currentDate = new Date()
  lastMonthDate = new Date(new Date().setMonth(new Date().getMonth() - 1))
  erroBusca: any = false
  pipe = new DatePipe('pt-BR');
  isBack = this.storage.localStorageGet('BackOffice')

  constructor(
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public teste: TesteService,
    private route: Router,
    public service: CrudService
  ) {
    this.pesquisar = debounce(this.pesquisar, 500)

  }

  ngOnInit(): void {
    this.criarForm()
    if (this.isBack == null)
      this.iniciaPedido()
    else
      this.pesquisar()
  }

  criarForm() {
    this.camposForm = this.formBuilder.group({
      dataInicial: [this.lastMonthDate],
      dataFinal: [this.currentDate],
      numeroPedido: [''],
      numeroAto: [''],
      cerp: [''],
      selo: [''],
      tipoStatus: ['']
    });
  }

  isUUID(uuid: any) {
    let s = uuid;

    s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (s === null) {
      return false;
    }
    return true;
  }

  pesquisar() {
    let objsend = this.camposForm.value
    let cerpValid = this.isUUID(this.camposForm.get('cerp').value)
    if (this.camposForm.get('cerp').value != '' && !cerpValid) {
      objsend.cerp = ''

    }
    //console.log(objsend, "teste")

    var base64 = btoa(JSON.stringify(objsend))

    let endPoint = this.isBack == null ? 'Venda' : 'Venda/GetVendaBackOffice'

    const url = `${environment.http.API}${endPoint}?pagina=${this.paginaAtual}&tamanhoPagina=${this.tamanhoPagina}&filtros=${base64}`;
    this.erroBusca = false;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {
        //console.log(response)

        if (response != null) {
          this.pedidos = response.Dados;
          this.totalItens = response.Tamanho;
          let el = document.getElementById('topo');
          el?.scrollIntoView();

          //console.log('pedidos = ', this.pedidos);
        }
      }, error: (err) => {
        console.error('Erro ao buscar detalhes do pedido:', err);
        if (err?.status == 401)
          this.route.navigate(['/login/entrar?logoff=1'])
        else
          this.erroBusca = true

        //this.route.navigate(['/login/entrar?logoff=1'])
      }
    });




  }
  togglePanel(index: any) {
    this.panelOpenState = !this.panelOpenState;
  }


  verDadosFaturamento(item: any, i: any) {

    this.verDetalhes(item, i, 1)

  }

  OcultarDadosFaturamento(item: any, i: any) {
    this.showDadosFaturamento = false
  }

  filtrarMeusPedidos() {

  }


  ocultarDetalhes(item: any, i: any) {
    item['verDetalhes'] = false
    //console.log('ocultar')
  }

  verPanelOpenState(item: any, index: any) {
    item.panelOpenState = !item.panelOpenState;
  }

  verDetalhesPedido(item: any, i: any) {
    //console.log('ver')
    item['verDetalhes'] = true
    this.verDetalhes(item, i)
  }

  verDetalhesPedidoImpressao(item: any, j: any) {
    item['verDetalhes'] = true;
    this.verDetalhes(item, j, 0, true);


    //this.preparaPrint('', j);

  }
  print: any = false;

  preparaPrint(display: any, j: any) {
    this.print = true;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%";

    (<HTMLInputElement>document.getElementById("titleMeusPedidos")).style.display = display;
    (<HTMLInputElement>document.getElementById("header")).style.display = display;
    (<HTMLInputElement>document.getElementById("footer")).style.display = display;
    (<HTMLInputElement>document.getElementById("filtro")).style.display = display;
    (<HTMLInputElement>document.getElementById("paginacaoItens")).style.display = display;
    (<HTMLInputElement>document.getElementById("logoPrint")).style.display = '';

    (<NodeListOf<HTMLElement>>document.getElementsByName("opcaoPedido")).forEach(e => e.style.display = display);
    (<NodeListOf<HTMLElement>>document.getElementsByName("btnDetalhes")).forEach(e => e.style.display = display);

    (<HTMLInputElement>document.getElementById("conpr")).classList.remove('container');

    for (var i = 0; i < this.pedidos?.length; i++) {
      if (i != j) {
        (<HTMLInputElement>document.getElementById(i + "p")).style.display = display;

      }
    }
    let leng = this.pedidos?.length
    window.onafterprint = function () {
      (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%";
      (<HTMLInputElement>document.getElementById("logoPrint")).style.display = 'none';

      (<HTMLInputElement>document.getElementById("titleMeusPedidos")).style.display = '';
      (<HTMLInputElement>document.getElementById("header")).style.display = '';
      (<HTMLInputElement>document.getElementById("footer")).style.display = '';
      (<HTMLInputElement>document.getElementById("filtro")).style.display = '';
      (<HTMLInputElement>document.getElementById("paginacaoItens")).style.display = '';

      (<NodeListOf<HTMLElement>>document.getElementsByName("opcaoPedido")).forEach(e => e.style.display = '');
      (<NodeListOf<HTMLElement>>document.getElementsByName("btnDetalhes")).forEach(e => e.style.display = '');

      (<HTMLInputElement>document.getElementById("conpr")).classList.add('container');

      for (var i = 0; i < leng; i++) {
        if (i != j) {
          (<HTMLInputElement>document.getElementById(i + "p")).style.display = '';

        }
      }
    }

    if (display != '')
      window.print();

  }

  verDetalhes(item: any, i: any, tipo: any = 0, print: any = false) {

    let it = this.storage.localStorageGet("pedido")
    var base64 = btoa(JSON.stringify(this.camposForm.value))
    const url = `${environment.http.API}Venda/getPedidos?numeroPedido=${item.numeroPedido}&filtros=${base64}`;

    //console.log('url GetPedido', url)

    this.erroBusca = false;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";
    //ajsutar
    this.service.getObservableDirect(url).subscribe({
      next: (response) => {

        if (response) {
          
          if (typeof response.dadosFaturamento != 'object' && response.dadosFaturamento != undefined) {
            response.dadosFaturamento = JSON.parse(response.dadosFaturamento)
          }

          this.pedidos[i] = response;
          if (tipo == 0)
            this.pedidos[i]['verDetalhes'] = true
          else
            this.pedidos[i]['showDadosFaturamento'] = true

            
          if (print) {
            this.panelOpenState = true;
            setTimeout(() => this.preparaPrint('none', i), 200);
            //this.preparaPrint('none', i);
          }
        }
      },
      error: (err) => {
        console.error('Erro ao buscar detalhes do pedido:', err);
        if (err?.status == 401)
          this.route.navigate(['/login/entrar?logoff=1'])
        else
          this.erroBusca = true
      }
    });

  }

  baixarTodosAtos(idPedido: any, numeroPedido: any, quantidade: any) {

    const url = `${environment.http.API_BACK}Pedido/BaixarAtosPorId?idPedido=${idPedido}&numeroPedido=${numeroPedido}`;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    if (quantidade == 1) {
      this.service.getObservableBlob(url).subscribe(blob => {
        saveAs(blob, numeroPedido + '.pdf');
      });
    }
    else {
      this.service.getObservableBlob(url).subscribe(blob => {
        saveAs(blob, numeroPedido + '.zip');
      });
    }
  }
  cancelarEdicao(itemAtual: any) {

    delete itemAtual['editar']

  }
  idItemEdicao: any;
  editarAto(itemAtual: any, pedido: any, posicao: any) {
    //console.log(itemAtual)

    for (var i = 0; i < pedido?.listaCompra?.length; i++) {
      if (i != posicao) {
        delete pedido?.listaCompra[i]['editar']
      }
    }
    itemAtual['editar'] = true
    this.idItemEdicao = itemAtual?.idItem
  }

  enviarMensagemExigencia({ mensagemExigencia, posicaoExigencia }: any, pedido: any, posicao: any) {
    this.updatePedidoExigencia(mensagemExigencia, pedido, posicao, posicaoExigencia);
  }

  updatePedido(item: any, pedido: any, posicao: any) {
    //console.log('dfdf')
    let url = environment.http.API + 'Venda'
    let tempId = { ...pedido["_id"] }
    delete pedido.listaCompra[posicao]["editar"]
    delete item["editar"]

    for (const key in item) {

      pedido.listaCompra[posicao][key] = item[key]
    }

    delete pedido["_id"];

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.putObservableDirect(url, pedido).subscribe({
      next: async (response) => {
        let nepd = response
        nepd["_id"] = tempId

        pedido.editar = false

        let urlBack = environment.http.API_BACK + 'Empresarial/AlterarPedido?idCerp=' + this.idItemEdicao
        //console.log('urlBack', urlBack)

        this.service.postObservableDirect(urlBack, nepd).subscribe({
          next: async (responseBack) => {
            //this.route.navigate(['pedido/pagar'])

          }, error: (err) => {
            //console.error(err);
          }
        });

      }, error: (err) => {

        //console.error(err);
      }
    });
  }

  vistarAto(compra: any) {

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    let userlogged = this.storage.localStorageGet('userlogged')

    //console.log(compra)
    //console.log(userlogged)

    let urlBack = `${environment.http.API_BACK}Ato/VistarAtoMongo?cerp=${compra?.cerp}&userGuid=${userlogged?.keyLogin}`;
    //console.log('urlBack', urlBack)

    this.service.postObservableDirect(urlBack, null).subscribe({
      next: async (response) => {
        //this.route.navigate(['pedido/pagar'])

        this.route.navigate(['pedido/pagar/' + response.pedidoGuid])


      }, error: (err) => {
        //console.error(err);
      }
    });

  }

  updatePedidoExigencia(mensagemExigencia: any, pedido: any, posicaoCompra: any, posicaoExigencia: any) {
    let url = environment.http.API + 'Venda';
    let tempId = { ...pedido["_id"] };

    let compraObj = pedido.listaCompra[posicaoCompra];
    let exigenciaObj = compraObj?.listaExigencia[posicaoExigencia];

    if (exigenciaObj?.listaMensagem)
      exigenciaObj.listaMensagem = [mensagemExigencia, ...exigenciaObj.listaMensagem];
    else
      exigenciaObj.listaMensagem = [mensagemExigencia];

    delete compraObj["editar"];
    delete pedido["_id"];

    compraObj.status = 'Exigência Respondida';

    this.service.putObservableDirect(url, pedido).subscribe({
      next: async (response) => {
        let nepd = response;

        let urlBackResponderExigencia = null;
        let payloadResponderExigencia = null;

        nepd["_id"] = tempId;
        pedido.editar = false;

        //console.log(compraObj);
        //console.log(compraObj?.produto?.origem);
        //console.log(mensagemExigencia);

        if (compraObj?.produto?.origem == 'BR') {
          payloadResponderExigencia = {
            idExigencia: exigenciaObj?.idExigencia,
            mensagem: mensagemExigencia?.mensagem,
            guidUsuario: pedido?.guidUser,
            Anexos: mensagemExigencia?.linksDownload?.map((file: any) => ({ pathDoc: file.nome, nomeArquivo: file.nomeOriginal }))
          };

          urlBackResponderExigencia = `${environment.http.API_BACK}Exigencia/ResponderExigenciaMongoBR`;
        }
        else {
          payloadResponderExigencia = nepd;
          urlBackResponderExigencia = `${environment.http.API_BACK}Exigencia/ResponderExigenciaMongo?origem=CNR&idExigencia=${exigenciaObj?.idExigencia}&mensagem=${mensagemExigencia?.mensagem}&guidUsuario=${pedido?.guidUser}`;
        }
        //console.log('urlBack', urlBack)

        this.service.postObservableDirect(urlBackResponderExigencia, payloadResponderExigencia).subscribe({
          next: async (responseBack) => { },
          error: (err) => { }
        });
      }, error: (err) => { }
    });
  }

  baixarNotaFiscal(idPedido: any) {

    const url = `${environment.http.API_BACK}Pedido/BaixarNotaFiscal?idPedido=${idPedido}`;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.getObservableBlob(url).subscribe(blob => {
      saveAs(blob, 'notafiscal.pdf');
    });

  }

  baixarAto(nuAto: any) {

    const url = `${environment.http.API_BACK}Ato/BaixarAtoV3?nuAto=${nuAto}`;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.getObservableBlob(url).subscribe(blob => {
      saveAs(blob, `${nuAto}.pdf`);
    });

  }

  baixarRecibo(nuAto: any) {

    const url = `${environment.http.API_BACK}Ato/BaixarReciboV3?nuAto=${nuAto}`;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.getObservableBlob(url).subscribe(blob => {
      saveAs(blob, `${nuAto}.pdf`);
    });

  }

  pagarExigencia({ exigencia }: any) {
    let idExigencia = exigencia?.idExigencia;
    this.route.navigate(['/pedido/pagar/' + idExigencia]);
  }

  efetuarPagamento(guidPedido: any) {
    this.route.navigate(['/pedido/pagar/' + guidPedido]);
  }

  copiarPedido(item: any, i: any) {
    let it = this.storage.localStorageGet("pedido")
    const url = `${environment.http.API}Venda/getPedidos?numeroPedido=${item.numeroPedido}`;
    this.erroBusca = false;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    //ajsutar
    this.service.getObservableDirect(url).subscribe({
      next: (response) => {
        if (response) {
          let pedido = response
          delete pedido['_id']
          delete pedido['id']
          delete pedido['Id']
          delete pedido['dadosPagamento']
          pedido['pagamento'] = false
          pedido['pagamentoGerado'] = false
          pedido['tipoPagamento'] = ''
          pedido['guidPedido'] = crypto.randomUUID();

          pedido['pedidoOrigem'] = pedido['numeroPedido']
          this.storage.localStorageSet('pedido', pedido)
          this.storage.localStorageRemove('item_atual')
          this.route.navigate(['/pedido/meu-carrinho'])
        }
      },
      error: (err) => {
        console.error('Erro ao buscar detalhes do pedido:', err);
        if (err?.status == 401)
          this.route.navigate(['/login/entrar?logoff=1'])
        else
          this.erroBusca = true
      }
    });
  }

  alterarPagina(event: PageEvent) {
    const novaPagina = event.pageIndex + 1;
    const novoTamanhoPagina = event.pageSize;

    this.paginaAtual = novaPagina;
    this.tamanhoPagina = novoTamanhoPagina;

    this.iniciaPedido(novaPagina, novoTamanhoPagina);
  }

  getdate(dataenviada: any) {
    let dttratada = dataenviada?.substr(0, 10).replace('T00:00:00Z', '').split('-')

    return new Date(dttratada[0], dttratada[1] - 1, dttratada[2])
  }

  iniciaPedido(pagina: number = this.paginaAtual, tamanhoPagina: number = this.tamanhoPagina): void {

    var base64 = btoa(JSON.stringify(this.camposForm.value))

    const url = `${environment.http.API}Venda?pagina=${this.paginaAtual}&tamanhoPagina=${this.tamanhoPagina}&filtros=${base64}`;
    this.erroBusca = false;

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {

        //console.log('Meus Pedidos: ', response)

        if (response != null) {
          this.pedidos = response.Dados;
          this.totalItens = response.Tamanho;
          let el = document.getElementById('topo');
          el?.scrollIntoView();
          //console.log(this.pedidos, "teste")

          //console.log('Meus Pedidos: ', this.pedidos);
        }
      },
      error: (err) => {
        console.error('Erro ao buscar detalhes do pedido:', err.status);

        if (err?.status == 401)
          this.route.navigate(['/login/entrar?logoff=1'])
        else
          this.erroBusca = true

      }
    });

  }

}
function ngxPrint(elementToPrint: HTMLElement, printOptions: {
  printMode: string; // Pode ser 'iframe' ou 'popup'
  bodyClass: string; // Classe CSS a ser adicionada ao corpo ao imprimir
  standard: string; // Pode ser 'html5', 'iframe' ou 'popup'
}) {
  throw new Error('Function not implemented.');
}

