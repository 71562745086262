<div class="wrapper-item">
  <div class="row">
    <div class="wrapper-info col-lg-4">
      <h3 class="info-section"><b>Ato</b></h3>
      <p class="info-section">{{compra.ato}}</p>
    </div>
    <div class="wrapper-info col-lg-4">
      <h3 class="info-section"><b>Quantidade</b></h3>
      <p class="info-section">{{compra.quantidade}}</p>
    </div>
    <div class="wrapper-info col-lg-4">
      <h3 class="info-section"><b>Valor Unitário</b></h3>
      <p class="info-section">{{compra.precoUnitarioAto | currency:'BRL'}}</p>
    </div>

    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>CERP</b></h3>
      <p class="info-section">{{compra?.idItem}}</p>
    </div>
  </div>
</div>
