<div class="wrapper-content">
  <div class="container">
    <div class="content-itens">
      <h2>{{conteudo.titulo}}</h2>
      <div class="wrapper-inputs">
        <div class="content-inputs">
          <mat-form-field>
            <mat-label for="estadoServico" (change)="onStateChange()">UF</mat-label>
            <mat-select [(ngModel)]="selecioneEstado" id="estadoServico">
              <mat-option *ngFor="let estado of estadosBrasil" [value]="estado">{{ estado }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label for="municipioServico">Selecione a cidade</mat-label>
            <mat-select [(ngModel)]="selecioneCidade" id="municipioServico" [disabled]="!selecioneEstado" (change)="onCityChange()">
              <mat-option *ngFor="let cidade of cidadesBrasil[selecioneEstado]" [value]="cidade">{{ cidade }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <a class="col-md-4 col-12" routerLink="/" [class.disabled]="!selecioneEstado || !selecioneCidade">
          <mat-icon matPrefix class="my-icon setas setadireita">arrow_forward_ios</mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>