import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl, FormGroup, Validators
} from '@angular/forms';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'app-form-8',
  templateUrl: './form8.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class Form8Component implements OnInit, AfterViewInit {
  @Input() itemAtual: any;
  @Input() itemEdicao: any;
  @Input() edicao: any=false;
  @Input() setaEnvioCorreio: any=false;


  @Output() salvarItem = new EventEmitter<any>();
  @Output() cancelarEdicao = new EventEmitter<any>();
  //
  tipoAtos: any = [{ "id": 'Nascimento', "descricao": "Batismo/Nascimento" },
    { "id": 'Casamento', "descricao": "Casamento" }, { "id": 'Obito', "descricao": "Óbito" }]

  finalidades: any = [{ "id": '19', "descricao": "EXTRAJUDICIAL" }, { "id": '20', "descricao": "INFORMAÇÃO PESSOAL" },
    { "id": '18', "descricao": "JUDICIAL" }]

  distritos: any

  concelhos: any

  freguesias: any
  //
  camposForm = this.formBuilder.group({
    tipo: ['', Validators.required],
    finalidade: ['', Validators.required],
    distrito: ['', Validators.required],
    concelho: ['', Validators.required],
    freguesia: ['', Validators.required],
    pai: ['', Validators.required],
    mae: ['', Validators.required],
    nome: ['', Validators.required],
    conjuge: [''],
    cota: [''],
    pagina: [''],
    dataato: ['', Validators.required],
    informacaoAdicional: [''],
    envioCorreios: ['false'],
    apostilamento: ['false', Validators.required],
    dadosEntrega: this.formBuilder.group({
      pais: [''],
      estado: [''],
      cidade: [''],
      enderecoCompleto: [''],
    }),
    aceites: ['']

  });
  constructor(
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public teste: TesteService,
    public service: CrudService,
    private routeAc: ActivatedRoute,
    private route: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {
    this.getDistrito()

  }

  getDistrito() {
    this.service.getObservableDirect(environment.http.API_BACK + 'Consulta/Distrito').subscribe({
      next: async (response: any) => {
        this.distritos = response
        if (document.location.href.indexOf('meu-carrinho') == -1) {
          this.carregarEdicao()
        }
      },
      error: (err) => {

        console.error(err);
      }
    })
  }
  getConcelhosInput(event: any) {
    this.getConcelhos(event.value.id)
  }
  getFreguesiaInput(event: any) {
    this.getFreguesia(event.value.id)
  }
  getConcelhos(value: any) {

    this.service.getObservableDirect(environment.http.API_BACK + 'Consulta/Concelho?idDistrito=' + value).subscribe({
      next: async (response: any) => {
        this.concelhos = response
        this.freguesias = null
      },
      error: (err) => {

        console.error(err);
      }
    })
  }
  getFreguesia(value: any) {

    this.service.getObservableDirect(environment.http.API_BACK + 'Consulta/Freguesia?idConcelho=' + value).subscribe({
      next: async (response: any) => {
        this.freguesias = response
      },
      error: (err) => {

        console.error(err);
      }
    })
  }

  cancelarEdicaoForm() {
    this.cancelarEdicao.emit()
  }

  setaDadosApostilamento() {

    this.setaEnvioCorreio = this.camposForm.get('apostilamento')?.value

    this.camposForm.get('envioCorreios')?.setValue(this.setaEnvioCorreio);

  }

  setaDadosEntregaObrigatorio() {
    
    let envioCorreios = this.camposForm.get('envioCorreios')?.value === 'true';

    if (envioCorreios == false) {
      this.camposForm.get('apostilamento')?.setValue('false')
    }

    const dadosEntregaControls = (this.camposForm.get('dadosEntrega') as FormGroup).controls;

    for (const key in dadosEntregaControls) {
      if (dadosEntregaControls.hasOwnProperty(key)) {
        const control = dadosEntregaControls[key];
        if (envioCorreios) {
          control.setValidators(Validators.required);
        } else {
          control.clearValidators();
        }
        control.updateValueAndValidity();
      }
    }

    
  }

  get DataDto() {

    return this.camposForm.get('dataato')
  }

  home() {
    this.route.navigate(['home'])
  }
  classObrigatoriaAviso: any;
  temAceiteObrigatorio = false
  aceites: any
  temAceite(event: any) {
    this.temAceiteObrigatorio = true
    this.aceites = event
  }

  updatePedido() {
    if (this.temAceiteObrigatorio) {

      let aceitFinal = this.storage.localStorageGet('aceiteDireto')
      let temsemaceite = aceitFinal.find((x: any) => x.selecionado == false)
      if (temsemaceite == undefined) {
        this.camposForm.get('aceites')?.setValue(aceitFinal)
        this.classObrigatoriaAviso = ''
      } else {
        this.classObrigatoriaAviso = 'redClass'
        return
      }
    } else {
      this.classObrigatoriaAviso = ''
    }


    this.camposForm.markAllAsTouched();
    if(this.camposForm.get('dataato')?.hasError('maxlength'))
      this.camposForm.get('dataato')?.setErrors(null);

    if (this.camposForm.valid) {
      let prodAtu = null

      prodAtu = this.camposForm.value
      this.salvarItem.emit(prodAtu);
    }
  }


  compareDistrito(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }
  compareConcelho(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }
  compareFreguesia(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }
  compareFinalidade(object1: any, object2: any) {

    return object1 && object2 && object1.id == object2.id;
  }
  compareTipoAto(object1: any, object2: any) {

    return object1 && object2 && object1.id == object2.id;
  }

  carregarEdicao() {
    let paramId = this.routeAc.snapshot.paramMap.get('id');

    if (paramId != null || this.itemEdicao != null) {
      let pedido = this.storage.localStorageGet("pedido")
      
      if (pedido?.listaCompra != null && pedido?.listaCompra.length > 0 && paramId != null) {
        let prodAtu = { ...pedido.listaCompra?.find((x: any) => x.idItem == paramId) }
        this.preencheCampos(prodAtu)

        if (prodAtu && prodAtu.distrito && prodAtu.concelho) {
          this.getConcelhos(prodAtu.distrito.id);
          this.getFreguesia(prodAtu.concelho.id);
        }
      }
    } else {
      let prodAtu = this.itemEdicao
      this.service.preencheCampos(prodAtu, this.camposForm)

      if (prodAtu && prodAtu.distrito && prodAtu.concelho) {
        this.getConcelhos(prodAtu.distrito.id);
        this.getFreguesia(prodAtu.concelho.id);
      }
    }
  }

  preencheCampos(object: any) {
    for (const key in object) {
      this.camposForm.get(key)?.setValue(object[key])
    }
  }

  ngAfterViewInit() {

  }

  // preencheDadosEntrega(object: any) {
  //   for (const key in object) {
  //     this.dadosEntrega.get(key)?.setValue(object[key])
  //   }
  // }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // //console.log(event.key, event.ctrlKey, event.shiftKey, environment.production)
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {

      //console.log('chegou aqui ctrl+shift+K')
      this.teste.setValueForm(this.camposForm);

      // for (let control of this.dadosEntrega.controls) {
      //   if (control instanceof FormGroup) {
      //     this.teste.setValueForm(control);
      //   }
      // }

    }
  }

}
