<ng-container *ngIf="isMobile()">
  <section class="bgBannerM" 
           [style.background]="conteudo?.srcImage ? 'url(/assets/' + conteudo.srcImage + ')':'' "
           [style.backgroundSize]="conteudo?.bgSize ? '' + conteudo.bgSize+'':'cover !important' "
           >
    <div class="container ">
      <div class="row">
        
        <div class="col-7" style="padding-top:30px" *ngIf="conteudo?.left">
          <h1 [class]="conteudo?.classText ?''+conteudo?.classText+'':''">{{conteudo.h1}}</h1>
          <p [class]="conteudo?.classText ?''+conteudo?.classText+'':''">
            {{conteudo.p}}
          </p>
          <br />
          <button class="text-md-start text-lg-start text-xl-start" (click)="navegar(conteudo.btnLink)"
                  [style]="isMobile() ? 'padding: 10px 10px 10px 10px;' : 'padding: 21px 89px 21px 70.333px;'"
                  style="display: inline-flex;
            height: 80px;
            align-items: center;
            flex-shrink: 0;
            border-radius: 5px;
            background: #F5FAFF;
            box-shadow: 0px 2.667px 0px 0px rgba(0, 0, 0, 0.04);">
            <span style="color: #19244F;
          text-align: center;
          text-shadow: 0px -1.333px 0px rgba(0, 0, 0, 0.12);
          font-family: var(--fonte-padrao);
          font-size: 30px;
          font-style: normal;
          font-weight: 400;
          line-height: 37.333px;">
              <ng-container *ngIf="!conteudo?.btnTxt">Solicitar resolução</ng-container>
              <ng-container *ngIf="conteudo?.btnTxt">{{conteudo?.btnTxt}}</ng-container>
            </span>
          </button>
        </div>
      </div>
    </div>
  </section>


</ng-container>
<ng-container *ngIf="!isMobile()">
  <section class="bgBanner"
           [style.background]="conteudo?.srcImage ? 'url(/assets/' + conteudo.srcImage + ')':'' "
           [style.backgroundSize]="conteudo?.bgSize ? '' + conteudo.bgSize+'':'cover !important' "
           [style.height]="conteudo?.hv ? '' + conteudo.hv+'px':'' ">
    <div class="container ">
      <div class="row">
        <div class="col-5" *ngIf="conteudo?.rigth"></div>
        <div class="col-7" style="padding-top:30px" *ngIf="conteudo?.left">
          <h1 [class]="conteudo?.classText ?''+conteudo?.classText+'':''">{{conteudo.h1}}</h1>
          <p [class]="conteudo?.classText ?''+conteudo?.classText+'':''">
            {{conteudo.p}}
          </p>
          <br />
          <button class="text-md-start text-lg-start text-xl-start" (click)="navegar(conteudo.btnLink)"
                  [style]="isMobile() ? 'padding: 10px 10px 10px 10px;' : 'padding: 21px 89px 21px 70.333px;'"
                  style="display: inline-flex;
            height: 80px;
            align-items: center;
            flex-shrink: 0;
            border-radius: 5px;
            background: #F5FAFF;
            box-shadow: 0px 2.667px 0px 0px rgba(0, 0, 0, 0.04);">
            <span style="color: #19244F;
          text-align: center;
          text-shadow: 0px -1.333px 0px rgba(0, 0, 0, 0.12);
          font-family: var(--fonte-padrao);
          font-size: 30px;
          font-style: normal;
          font-weight: 400;
          line-height: 37.333px;">
              <ng-container *ngIf="!conteudo?.btnTxt">Solicitar resolução</ng-container>
              <ng-container *ngIf="conteudo?.btnTxt">{{conteudo?.btnTxt}}</ng-container>
            </span>
          </button>
        </div>
      </div>
    </div>
  </section>


</ng-container>
