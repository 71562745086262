export class ModelPage {
  Itens: any[]
  urlApiConfig: any = "API_ADMINISTRATIVO_CADASTROS";
  pageSize: number = 6;

  constructor() {

    this.Itens = [
     
      //crud unico
      {
        "tipo": "crud",
        "urlLista": "",
        "urlGetById": "",
        "urlAlterar": "",
        "urlIncluir": "",
        "title": "Função",
        "iconTitle": "event",
        "titleInclusao": "Incluir",
        "titleAlteracao": "Alterar",
        "pagina": "funcao",
        "dadosFiltro": {
          default: {
            "Descricao": '',
            "Secretaria": {
              Id: 0,
              Descricao: ''
            },
            Pagina: 1
          }
        },
        "dadosEntidade": {
          default: {
            "Descricao": "",
            "Secretaria": {
              Id: 0,
              Descricao: ''
            }
          }
        },
        "minFiltro": true,
        "dadosGrid": [
        
          {
            id: 'acao',
            prop: 'acao',
            label: 'Ação',
          }
        ],
        "inputsFiltro": {
          "forms": [
            {
              "campos": [

              ]
            }
          ]
        },
        "inputs": {
          "forms": [
            {

              "campos": [
                
              ]
            }
          ]
        }
      }
    ]
  }

}
