<div class="wrapper-item" *ngIf="!compra?.editar">
  <div class="wrapper-topo">

    <div class="content-right">
      <span class="title-box">Valor</span>
      <p class="text-info-box">{{compra.precoTotal}}</p>
    </div>
  </div>
  <div class="content-info-solicitante">
    <div class="row">
      <div class="wrapper-info col-lg-4">
        <h3 class="info-section"><b>Nome Completo</b></h3>
        <p class="info-section">{{compra.nomeCompleto}}</p>
      </div>
      <div class="wrapper-info col-lg-4">
        <h3 class="info-section"><b>Email</b></h3>
        <p class="info-section">{{compra.email}}</p>
      </div>
      <div class="wrapper-info col-lg-4">
        <h3 class="info-section"><b>Telefone</b></h3>
        <p class="info-section">{{compra.telefone}}</p>
      </div>
    </div>
    <div class="row">
      <div class="wrapper-info col-lg-4">
        <h3 class="info-section"><b>N° ID Civil</b></h3>
        <p class="info-section">{{compra.identidade}}</p>
      </div>
      <div class="wrapper-info col-lg-4">
        <h3 class="info-section"><b>NIF</b></h3>
        <p class="info-section">{{compra.nif}}</p>
      </div>
    </div>
    <h2 class="text-center">Tipo Certidão</h2>

    <div class="content-info-solicitante">
      <div *ngIf="compra.tipo === 'Casamento'">
        <div class="row" *ngFor="let compra2 of compra.detalhes">
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Tipo certidão</b></h3>
            <p class="info-section">{{ compra.tipo }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Finalidade</b></h3>
            <p class="info-section">{{ compra.finalidade.descricao }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Nome do Pai</b></h3>
            <p class="info-section">{{ compra2.nomePai }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Nome da Mãe</b></h3>
            <p class="info-section">{{ compra2.nomeMae }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Nome do Cônjuge</b></h3>
            <p class="info-section">{{ compra2.nomeConjuge }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Data do Casamento</b></h3>
            <p class="info-section">{{ compra2.data | date:'dd/MM/yyyy':'UTC'}}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Lugar do Casamento</b></h3>
            <p class="info-section">{{ compra2.pais.pais }}</p>
          </div>
          <div *ngIf="compra2.pais.pais === 'Portugal'">
            <div class="row">
              <div class="wrapper-info col-lg-6">
                <h3 class="info-section"><b>Distrito Detalhes</b></h3>
                <p class="info-section">{{ compra2.distritoDetalhes.descricao }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="info-section"><b>Concelho Detalhes</b></h3>
                <p class="info-section">{{ compra2.concelhoDetalhes.descricao }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="info-section"><b>Freguesia Detalhes</b></h3>
                <p class="info-section">{{ compra2.freguesiaDetalhes.descricao }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="compra.tipo === 'Nascimento'">
        <div class="row" *ngFor="let compra2 of compra.detalhes">
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Tipo certidão</b></h3>
            <p class="info-section">{{ compra.tipo }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Finalidade</b></h3>
            <p class="info-section">{{ compra.finalidade.descricao }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Nome do Pai</b></h3>
            <p class="info-section">{{ compra2.nomePai }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Nome da Mãe</b></h3>
            <p class="info-section">{{ compra2.nomeMae }}</p>
          </div>
        </div>
      </div>

      <div *ngIf="compra.tipo === 'Óbito'">
        <div class="row" *ngFor="let compra2 of compra.detalhes">
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Tipo certidão</b></h3>
            <p class="info-section">{{ compra.tipo }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Finalidade</b></h3>
            <p class="info-section">{{ compra.finalidade.descricao }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Nome do Pai</b></h3>
            <p class="info-section">{{ compra2.nomePai }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Nome da Mãe</b></h3>
            <p class="info-section">{{ compra2.nomeMae }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Data do Óbito</b></h3>
            <p class="info-section">{{ compra2.data | date:'dd/MM/yyyy':'UTC'}}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>País do Óbito</b></h3>
            <p class="info-section">{{ compra2.pais.pais }}</p>
          </div>
          <div *ngIf="compra2.pais.pais === 'Portugal'">
            <div class="row">
              <div class="wrapper-info col-lg-6">
                <h3 class="info-section"><b>Distrito Detalhes</b></h3>
                <p class="info-section">{{ compra2.distritoDetalhes.descricao }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="info-section"><b>Concelho Detalhes</b></h3>
                <p class="info-section">{{ compra2.concelhoDetalhes.descricao }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="info-section"><b>Freguesia Detalhes</b></h3>
                <p class="info-section">{{ compra2.freguesiaDetalhes.descricao }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="compra.tipo === 'Declaracao de Maternindade'">
        <div class="row" *ngFor="let compra2 of compra.detalhes">
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Tipo certidão</b></h3>
            <p class="info-section">{{ compra.tipo }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Finalidade</b></h3>
            <p class="info-section">{{ compra.finalidade.descricao }}</p>
          </div>
          <h2></h2>
          <h1 class="text-center" style="font-size: 17px;">Dados da Mãe</h1>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Nome Completo</b></h3>
            <p class="info-section">{{ compra2.nomeCompleto }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Data de Nascimento</b></h3>
            <p class="info-section">{{ compra2.data | date:'dd/MM/yyyy':'UTC'}}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Filho de...</b></h3>
            <p class="info-section">{{ compra2.filhoDe1 }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Filho de...</b></h3>
            <p class="info-section">{{ compra2.filhoDe2 }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Naturalidade</b></h3>
            <p class="info-section">{{ compra2.pais.pais }}</p>
          </div>
          <div *ngIf="compra2.pais.pais === 'Portugal'">
            <div class="row">
              <div class="wrapper-info col-lg-6">
                <h3 class="info-section"><b>Distrito Detalhes</b></h3>
                <p class="info-section">{{ compra2.distritoDetalhes.descricao }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="info-section"><b>Concelho Detalhes</b></h3>
                <p class="info-section">{{ compra2.concelhoDetalhes.descricao }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="info-section"><b>Freguesia Detalhes</b></h3>
                <p class="info-section">{{ compra2.freguesiaDetalhes.descricao }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="compra.tipo === 'Perfilhação'">
        <div class="row" *ngFor="let compra2 of compra.detalhes">
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Tipo certidão</b></h3>
            <p class="info-section">{{ compra.tipo }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Finalidade</b></h3>
            <p class="info-section">{{ compra.finalidade.descricao }}</p>
          </div>
          <h2></h2>
          <h1 class="text-center" style="font-size: 17px;">Dados do Perfilhante</h1>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Nome Completo</b></h3>
            <p class="info-section">{{ compra2.nomeCompleto }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Data de Nascimento</b></h3>
            <p class="info-section">{{ compra2.data | date:'dd/MM/yyyy':'UTC'}}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Filho de...</b></h3>
            <p class="info-section">{{ compra2.filhoDe1 }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Filho de...</b></h3>
            <p class="info-section">{{ compra2.filhoDe2 }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Naturalidade</b></h3>
            <p class="info-section">{{ compra2.pais.pais }}</p>
          </div>
          <div *ngIf="compra2.pais.pais === 'Portugal'">
            <div class="row">
              <div class="wrapper-info col-lg-6">
                <h3 class="info-section"><b>Distrito Detalhes</b></h3>
                <p class="info-section">{{ compra2.distritoDetalhes.descricao }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="info-section"><b>Concelho Detalhes</b></h3>
                <p class="info-section">{{ compra2.concelhoDetalhes.descricao }}</p>
              </div>
              <div class="wrapper-info col-lg-6">
                <h3 class="info-section"><b>Freguesia Detalhes</b></h3>
                <p class="info-section">{{ compra2.freguesiaDetalhes.descricao }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <h2 class="text-center">Dados do Pedido</h2>
    <div class="content-info-solicitante">
      <div class="wrapper-info">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="info-section"><b>Pedido feito por terceiro?</b></h3>
            <p class="info-section">{{ compra.pedidoFeitoPorTerceiro === 'true' ? 'Sim' : 'Não' }}</p>
          </div>

          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>CERP</b></h3>
            <p class="info-section">{{compra?.idItem}}</p>
          </div>
          <ng-container *ngIf="compra.pedidoFeitoPorTerceiro === 'true'">
            <div class="col-lg-6">
              <h3 class="info-section"><b>Nº ID Civil</b></h3>
              <p class="info-section">{{ compra.dadosSolicitante[0].identidade }}</p>
            </div>
            <div class="col-lg-6">
              <h3 class="info-section"><b>Nome Completo</b></h3>
              <p class="info-section">{{ compra.dadosSolicitante[0].nomeCompleto }}</p>
            </div>
            <div class="col-lg-6">
              <h3 class="info-section"><b>Número | Ano do registro</b></h3>
              <p class="info-section">{{ compra.dadosSolicitante[0].dataExpedicao }}</p>
            </div>
            <div class="col-lg-6">
              <h3 class="info-section"><b>Data de Nascimento</b></h3>
              <p class="info-section">{{ compra.dadosSolicitante[0].dataNascimento }}</p>
            </div>
            <div class="col-lg-6">
              <h3 class="info-section"><b>Naturalidade</b></h3>
              <p class="info-section">{{ compra.dadosSolicitante[0].pais.pais }}</p>
            </div>
            <div class="col-lg-6" *ngIf="compra.dadosSolicitante[0].pais.pais === 'Portugal'">
              <h3 class="info-section"><b>Distrito Solicitação</b></h3>
              <p class="info-section">{{ compra.dadosSolicitante[0].distritoSolicitante.descricao }}</p>
            </div>
            <div class="col-lg-6" *ngIf="compra.dadosSolicitante[0].pais.pais === 'Portugal'">
              <h3 class="info-section"><b>Concelho Solicitação</b></h3>
              <p class="info-section">{{ compra.dadosSolicitante[0].concelhoSolicitante.descricao }}</p>
            </div>
            <div class="col-lg-6" *ngIf="compra.dadosSolicitante[0].pais.pais === 'Portugal'">
              <h3 class="info-section"><b>Freguesia Solicitação</b></h3>
              <p class="info-section">{{ compra.dadosSolicitante[0].freguesiaSolicitante.descricao }}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

  </div>
</div>

<app-form-9 *ngIf="compra?.editar" [itemEdicao]="compra" [edicao]="true" (cancelarEdicao)="cancelarEdicao(compra)"
  (salvarItem)="salvarItem($event)" [itemAtual]="compra.produto"></app-form-9>
