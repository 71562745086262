import { Component, ElementRef, HostListener, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { produtosLimpos } from 'src/app/_configPage/listas/prdutosCNR';
import { globalPage } from '../../_configPage/globalPage';
import { LocalStorage } from '../../_entidade/LocalStorage';
import { CrudService } from '../../_sevice/CrudService';

import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-card-sequencial',
  templateUrl: './cardSequencial.component.html',
  styleUrls: ['./cardSequencial.component.scss']
})
export class CardSequencialComponent implements OnInit {
  // Dentro da classe do componente
  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.itemExpandidoIndex = -1; // Isso fecha todos os submenus ativos se clicado fora
    }
  }

  FecharItens(event: MouseEvent) {
      //event.preventDefault();
      //this.myMethod();
      this.itemExpandidoIndex = -1;
  }

  @Input() conteudo: any;
  @ViewChild('servicesCategories') servicesCategories!: ElementRef;
  @ViewChild('containerPrincipal') containerPrincipal!: ElementRef;
  @ViewChildren("subCategorias") subCategorias: QueryList<ElementRef>;

  servicesCategoriesHeight: number = 0;
  comprimentoContainerCards: number = 0;

  public isLoading = true;
  public widthContainerPrincipal!: string;

  public fr = new FormGroup({
  });

  public displayAnotacao = 'none';

  public itemDropSelected = '';
  public pointMouse: any;

  constructor(
    public router: Router,
    public deviceService: DeviceDetectorService,
    private elementRef: ElementRef
    , public storage: LocalStorage
    , public service: CrudService
    , public fb: FormBuilder) {

  }

  estadoSubcategorias: { [key: string]: boolean } = {};

  itemExpandidoIndex: number = -1;

  dropSelected(id: number, item: string) {
    //console.log('13')

    this.itemDropSelected = item;
    const divPai = document.getElementById("div_" + id) as HTMLElement;
    const danger = document.getElementById("danger" + id) as HTMLElement;
    if (id !== 0) {
      if (danger != null) {
        divPai.removeChild(danger);
      }
    }
  }

  solicitarNovoPeriodo(id: number, neto: any) {

    const valorDrop = document.getElementById("dropSelect_" + id) as HTMLElement;
    const selectedIndex = document.getElementById("dropSelect_" + id) as HTMLSelectElement;
    const danger = document.getElementById("danger" + id) as HTMLElement;
    const divPai = document.getElementById("div_" + id) as HTMLElement;
    const divFilho = document.createElement("div" + id);
    if (Number(selectedIndex.selectedIndex) === 0) {
      if (danger != null) {
        divPai.removeChild(danger);
      }
      divFilho.id = "danger" + id;
      divFilho.style.fontSize = '0.6rem';
      divFilho.style.color = 'red';
      divFilho.style.display = 'block';
      divFilho.textContent = 'Preenchimento obrigatorio';
      divFilho.style.textAlign = 'center';
      divPai.appendChild(divFilho);
    } else {
      if (danger != null) {
        divPai.removeChild(danger);
      }
      this.storage.localStorageRemove("kit");
      this.storage.localStorageRemove("item_atual");
      if (neto?.localidadePadrao) {
        this.storage.localStorageSet('localidade', neto?.localidadePadrao)

      }
      let novoItem = produtosLimpos.find((x: any) => x.aLink == neto.aLink + neto.periodoSelecionado)
      //console.log(neto)
      if (novoItem != null && novoItem != undefined) {

        this.storage.localStorageSet('item_atual', novoItem)
        this.router.navigate([neto.aLink + neto.periodoSelecionado])

      }
    }
  }

  // Método para alternar a exibição das subcategorias para um item específico
  toggleSubcategorias(index: number, event?: Event) {

    if (event) {
      event.stopPropagation();
    }

    if (this.itemExpandidoIndex === index) {

      // Se o mesmo item foi clicado novamente, oculte as subcategorias
      this.itemExpandidoIndex = -1;

    } else {

      // Mostrar as subcategorias para o item clicado
      this.itemExpandidoIndex = index;

      // percorre as subCategorias e aplica formatação para manter de acordo com o container
      this.subCategorias.forEach((dv: ElementRef) => {

        if (dv.nativeElement.id === `subCategorias${index}`) {

          let card = document.getElementById(`subCategorias${index}`);

          dv.nativeElement.setAttribute('style', `width: ${this.widthContainerPrincipal}; padding: 0px; box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.50)`);

        }

      });

    }

  }

  menuItens: any[] = [];
  showSubmenuIndex: number | null = null;

  ngAfterViewInit() {
    this.storage.localStorageRemove("kit");
    this.storage.localStorageRemove("item_atual");
    this.calculateServicesCategoriesHeight();
    this.widthContainerPrincipal = this.containerPrincipal?.nativeElement.offsetWidth + 'px';

  }

  public fechar(): void {

    //let x = this.elementRef.nativeElement.querySelector('.subcategories');

    //x.setAttribute('style', 'display: none;');

    //x.setAttribute('style', `display: none; width: ${this.widthContainerPrincipal}; padding: 0px; box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.50)`);

    //const clickedInside = this.elementRef.nativeElement.contains(event.target);

    //const isSubMenuOpened = event.nativeElement(event.target as Node);

    //console.log(x);


  }

  private calculateServicesCategoriesHeight() {

    const servicesCategoriesElement = this.elementRef.nativeElement.querySelector('.services-categories');
    if (servicesCategoriesElement) {
      this.servicesCategoriesHeight = servicesCategoriesElement.offsetHeight;
    }
  }

  setActiveSubmenu(index: number) {
    this.showSubmenuIndex = index;
  }

  public DivErro(pai: number, filho: number): void {
    //console.log('6')

    for (let i in this.fr.controls) {
      if (`cat_${pai}_subCat_${filho}_drop` === i) {
        let valorDrop = this.fr.get(i)?.value;
        if (valorDrop === '') {
          let err = document.getElementById(`danger_cat_${pai}_subCat_${filho}_drop`) as HTMLElement;
          err.style.display = 'block';
          err.style.color = 'red';
          err.style.fontSize = '0.6rem';
          err.innerHTML = 'Preenchimento Obrigatório';
        }
      }
    }
  }

  solicitarNovo(item: any) {

    this.storage.localStorageRemove("kit");
    this.storage.localStorageSet("item_atual", item);
    if (item?.localidadePadrao) {
      this.storage.localStorageSet('localidade', item?.localidadePadrao)

    }
    this.router.navigate([item.aLink])
  }

  clearActiveSubmenu() {
    //console.log('4')

    this.showSubmenuIndex = null;
  }

  ngOnInit(): void {


    let gbpg = new globalPage()
    if ('Categorias' in gbpg && this.conteudo.urlItens) {
      this.conteudo.itens = gbpg.Categorias

      if (this.conteudo.urlItens) {

        this.getItens(this.conteudo.urlItens)



      }
    }
  }

  getItens(url: any) {
    //console.log('2')

    /*
    <div style="height:50px" * ngIf="isLoading" >
    <mat-spinner[diameter]="35" style = "float:left" >
    </mat-spinner>carregando atos...</div >
    */

    if (url.indexOf('http') > -1) {
      this.service.getObservableDirect(url).subscribe({
        next: async (response) => {
          if (response != null) {
            this.conteudo.itens = response;
            this.isLoading = false
          }
        }, error: (err) => {
          console.error(err);
        }
      });
    } else {
      this.service.getObservable(url).subscribe({
        next: async (response) => {
          if (response != null) {
            this.conteudo.itens = response;
            this.isLoading = false
          }
        }, error: (err) => {
          console.error(err);
        }
      });
    }
  }

  handleSubmenuClick(event: MouseEvent) {
    //console.log('1')
    // Verifique se o clique ocorreu dentro do submenu
    const isClickedInsideSubmenu = this.servicesCategories.nativeElement.contains(event.target as Node);

    // Verifique se o clique ocorreu em um elemento de texto
    const isClickedOnText = (event.target as HTMLElement).nodeName === 'P' || (event.target as HTMLElement).nodeName === 'A';

    // Se o clique ocorreu dentro do submenu ou em um elemento de texto, não feche o submenu
    if (isClickedInsideSubmenu || isClickedOnText) {
      return;
    }

    // Caso contrário, feche o submenu
    this.itemExpandidoIndex = -1;
  }


  public isMobile(): boolean {
    return this.deviceService.isMobile();
  }

}
