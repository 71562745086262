import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
import { ActivatedRoute } from '@angular/router';
import { forEach } from 'lodash';
/*import { encode } from 'querystring';*/


@Component({
  selector: 'app-form-cartorio',
  templateUrl: './formCartorio.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class FormCartorioComponent implements OnInit, OnChanges {
  @Input() uf: any;
  @Input() cidade: any;
  @Input() municipio: any;
  @Input() itemAtual: any;
  @Input() iskit: any;
  @Input() itemAtualKit: any;
  @Input() classObrigatoria: any;
  @Input() cartorioEmissor: any[];
  @Input() multiplo: any;
  @Output() setTemCoberturaFilho = new EventEmitter<any>();
  @Output() setCartorios = new EventEmitter<any>();
  

  load: boolean = false
  temCobertura: boolean = false




  retorno: any

  constructor(
    public storage: LocalStorage,
    private cdRef: ChangeDetectorRef,
    private routeAc: ActivatedRoute,
    public service: CrudService
  ) {

  }



  ngOnInit(): void {
    if (this.iskit)
      this.getCartorios()

  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log('1')
    if (changes['cidade'] && !this.iskit) {
      this.getCartorios()
    }

    //this.cdRef.detectChanges();


  }

  selecionarLinha(item: any) {
    //console.log('1')
    if (!item.selecionado)
      item.selecionado = true
    else
      item.selecionado = false

    this.setCartorios.emit(this.cartorioEmissor);

  }

  getCartorios() {
    //console.log('1')
    this.load = false
    if (this.uf?.Sigla && this.cidade?.Nome) {
      //melhorar
      let tit = this.itemAtual?.tituloBusca ? this.itemAtual?.tituloBusca : this.itemAtual?.titulo

      let titulo = tit.replace('Certidão de Busca Prévia (Pessoa Jurídica)','Certidão de Busca Prévia')
    // console.log(titulo)
      this.service.getCartorios(titulo, this.uf.Sigla, this.cidade.Nome, this.itemAtual?.origem).subscribe({
        next: async (response) => {

          if (response != null) {
            this.cartorioEmissor = response
            if (this.iskit && !this.itemAtualKit?.livreEscolhaCartorio) {
              this.cartorioEmissor.forEach(d => {
                
                  d["selecionado"] = true
                
              })
            }


            if (this.cartorioEmissor?.length > 0) {
              this.temCobertura = true
            } else {
              this.temCobertura = false
            }

            this.setTemCoberturaFilho.emit(this.temCobertura);

            this.load = true
            console.log('fsgfdsfgsdfg', this.cartorioEmissor)
            if (this.cartorioEmissor && this.cartorioEmissor != undefined)
              this.setCartorios.emit(this.cartorioEmissor);

            this.carrregarEdicao()
          }
        }, error: (err) => {

          console.error(err);
        }
      });
    }
  }




  carrregarEdicao() {
    //console.log('1')
    let pedido = this.storage.localStorageGet("pedido")

    if (pedido?.listaCompra != null && pedido?.listaCompra.length > 0) {

      for (let index = 0; index < this.cartorioEmissor?.length; index++) {
        const element = this.cartorioEmissor[index];
        if (this.routeAc.snapshot.paramMap.get('id') != null) {
          let prodAtu = { ...pedido.listaCompra?.find((x: any) => x.idItem == this.routeAc.snapshot.paramMap.get('id')) }
          let car = prodAtu?.listCartorio?.findIndex((y: any) => y.idCartorio == element.idCartorio)

          if (car > -1) {
            element.selecionado = true
          } else {
            element.selecionado = false

          }
        }
      }
       if (this.cartorioEmissor != null)
        this.setCartorios.emit(this.cartorioEmissor);

    }

  }


  onCheckboxChange(event: any, item: any) {

    if (this.multiplo) {
      if (event.checked) {
        item["selecionado"] = true
      } else {
        item["selecionado"] = false
      }
    } else {
      //console.log(event.value)
      this.cartorioEmissor.forEach(d => {
        if (d['idCartorio'] == event.value) {
          d["selecionado"] = true
        } else {
          d["selecionado"] = false
        }
      })
    }
    //console.log(this.cartorioEmissor)

    this.setCartorios.emit(this.cartorioEmissor);

  }


}
