

<span id="alertFiltro" style="display:none;color:red">Informe ao menos um filtro de pesquisa</span>
<app-generic-form [filtro]="true" *ngIf="conteudoPagina.dadosFiltro" [dados]="conteudoPagina.dadosFiltro" [tipoForm]="'busca'" [conteudoPagina]="conteudoPagina" style="width:100%"
                  [inputs]="conteudoPagina.inputsFiltro" (recarregarGrid)="recarregarGrid()" (novaEntidade)="novaEntidade()">

</app-generic-form>


<div class="row">
  <div class="col-sm-12">
    <ng-container  *ngIf="tipoGrid=='grid'">
     
      <app-grid  *ngIf="tipoGrid=='grid'" [conteudoPagina]="conteudoPagina"
            [configPage]="configPage"
            [collectionSize]="'6'"
            [dataSouceGrid]="dataSouceGrid"
            (recarregarGrid)="recarregarGrid($event)"
            (alterarEntidade)="alterarEntidade($event)" style="width:100%">

  </app-grid>
    </ng-container>
    <app-tree *ngIf="tipoGrid=='tree' && conteudoPagina.optionsProp"
              [conteudoPagina]="conteudoPagina"
              [configPage]="configPage"></app-tree>
  </div>
</div>


