<div class="wrapper-item">
  <div class="wrapper-header-item">
    <h3 class="content-title">
      {{ item.produto.titulo }}
    </h3>
    <div class="wrapper-buttons">
      <div class="content-button">
        <button class="buttons-action"  (click)="editar.emit(item)">
          Editar
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/05a3523c983604fe0000ef79b0c38af35a7a81c7e6b32230af7c4a88344d432f?"
               class="img-11" />
        </button>
      </div>
      <div class="content-button">
        <button class="buttons-action" (click)="excluirItem.emit(item)">
          Excluir
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/dcdd700d816beff971594d71d60bdd298e4130c545dd466135231c4783571eb5?"
               class="img-12" />
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="w-100"></mat-divider>

  <div class="wrapper-info-cartorio">
    <div class="wrapper-topo">
      <div class="content-left">
        <span class="title-box">{{item.produto.titulo}}</span>
      </div>
      <div class="content-right">
        <span class="title-box">Valor</span>
        <p class="text-info-box">{{getPreco(item.produto.composicaoValor)  | currency:'BRL'}}</p>
      </div>
    </div>
    <ng-container *ngIf="visibilidadeDetalhes">
      <div class="content-info-solicitante">
        <h2>Dados do Requerente</h2>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Nome</h3>
            <p class="info-section">{{item.nomeCompleto}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Nº ID Civil</h3>
            <p class="info-section">{{item.identidade}}</p>
          </div>
          
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">NIF</h3>
            <p class="info-section">{{item.nif}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Telefone</h3>
            <p class="info-section">{{item.telefone}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Email</h3>
            <p class="info-section">{{item.email}}</p>
          </div>
        </div>
        <h2>Dados do Pedido</h2>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Nome do Pai</h3>
            <p class="info-section">{{item.nomePai}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Nome da Mãe</h3>
            <p class="info-section">{{item.nomeMae}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Finalidade</h3>
            <p class="info-section">{{item.finalidade[0].Descricao}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Data do Casamento</h3>
            <p class="info-section">{{item.dataCasamento | date:'dd/MM/yyyy'}}</p>
          </div>
          <div  class="wrapper-info col-lg-4">
            <h3 class="title-section">Data do Óbito</h3>
            <p class="info-section">{{item.dataObito | date:'dd/MM/yyyy'}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Conjuge</h3>
            <p class="info-section">{{item.conjuge}}</p>
          </div>
        
          
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-4" *ngIf="item.ato == '10030' || item.ato == '10032'">
            <h3 *ngIf="item.ato == '10030'" class="title-section">Local do Casamento</h3>
            <h3 *ngIf="item.ato == '10032'"class="title-section">Local do Óbito</h3>
            <p class="info-section">{{item.pais}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Distrito</h3>
            <p class="info-section">{{item.distrito}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Concelho</h3>
            <p class="info-section">{{item.concelho}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Freguesia</h3>
            <p class="info-section">{{item.freguesia}}</p>
          </div>
          
        </div>
        <div class="row">
          
          
          
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Informação Adicional</h3>
            <p class="info-section">{{item.informacaoAdicional}}</p>
          </div>
        </div>
        <h2 class="title-info-solicitante">Dados do Solicitante</h2>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Nome</h3>
            <p class="info-section">{{item.dadosSolicitante.nomeCompleto}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Nº ID Civil</h3>
            <p class="info-section">{{item.dadosSolicitante.identidade}}</p>
          </div>
        
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">NIF</h3>
            <p class="info-section">{{item.dadosSolicitante.nif}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Data Nascimento</h3>
            <p class="info-section">{{item.dadosSolicitante.dataNascimento | date:'dd/MM/yyyy'}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Naturalidade</h3>
            <p class="info-section">{{item.dadosSolicitante.pais}}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <button class="view-more-details" (click)="visibilidadeDetalhes=!visibilidadeDetalhes">
    {{ visibilidadeDetalhes ? 'Ocultar detalhes' : 'Ver detalhes' }}
  </button>
</div>
