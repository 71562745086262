import { Component, OnInit, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-galeria-img-v1',
  templateUrl: './galeriaImg.component.html',
  styleUrls: ['./galeriaImg.component.scss']
})
export class GaleriaImgV1Component implements OnInit {
  @Input() conteudo: any;

  constructor(
    public deviceService: DeviceDetectorService
  ) { }

  ngOnInit(): void {

  }

  public isMobile(): boolean {
    return this.deviceService.isMobile();
  }

}
