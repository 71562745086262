<div class="wrapper-item">

  <div class="wrapper-header-item">
    <h3 class="content-title">
      {{ item.produto.titulo }}
    </h3>
    <div class="wrapper-buttons">
      <!--<div class="content-button">
        <button class="buttons-action"  (click)="editar.emit(item)">
          Editar
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/05a3523c983604fe0000ef79b0c38af35a7a81c7e6b32230af7c4a88344d432f?"
               class="img-11" />
        </button>
      </div>-->
      <div class="content-button">
        <button class="buttons-action" (click)="excluirItem.emit(item)">
          Excluir
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/dcdd700d816beff971594d71d60bdd298e4130c545dd466135231c4783571eb5?"
               class="img-12" />
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="w-100"></mat-divider>

  <div class="wrapper-info-cartorio">
    <div class="wrapper-topo">
      <div class="content-right">
        <span class="title-box">Quantidade</span>
        <p class="text-info-box">{{item.quantidade}}</p>
      </div>
      <div class="content-right">
        <span class="title-box">Valor Unitário</span>
        <p class="text-info-box">{{item.precoUnitarioAto | currency:'BRL'}}</p>
      </div>
      <div class="content-right">
        <span class="title-box">Valor</span>
        <p class="text-info-box">{{item.precoTotal | currency:'BRL'}}</p>
      </div>
    </div>    
  </div>

</div>
