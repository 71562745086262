<!--<script type="text/javascript">
  var test = `<style>::ng-deep .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item:first-child span { display: none !Important;}</style>`
  document.head.insertAdjacentHTML("beforeend", test )
    console.log(test);
</script>-->
   
 

<div class="container main-meeting mt-2 my-5 content-list" >
  <div [style.display]="userAccess?.accessAllowed ? 'block' : 'none'">
    <div [style.display]="inSession ? 'none' : 'block'" id="join-flow">
      <h1 class="title-mediacao">Mediação da CNR</h1>

      <div id='previewContainer'></div>
      <button class="btn btn-primary btn-sm w-25" (click)="getVideoSDKJWT()">Entrar</button>
    </div>

    <div id='sessionContainer'></div>
  </div>

  <div [style.display]="!userAccess?.accessAllowed ? 'block' : 'none'">
    <div class="sem-acesso m-5 text-center">
      <span>{{userAccess?.messageAccess}}</span>
    </div>
  </div>
</div>
