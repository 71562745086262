import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LocalStorage } from "src/app/_entidade/LocalStorage";

@Component({
    selector: 'app-post-exigencia',
    templateUrl: './postExigencia.component.html',
    styleUrls: ['./../../../_meus-pedidos.component.scss']
})
export class PostExigenciaComponent implements OnInit {
    @Input() tipoUploadExigencia = 'TEXTO';
    @Input() statusCompra: any;
    //@Input() listaMensagens: any[];
    @Input() exigencia: any;
    @Input() pedido: any;
    @Input() compraAtual: any;

    @Output() enviarMensagemExigenciaEvent = new EventEmitter<any>();

    formMensagens: FormGroup;
    formArquivos: FormGroup;

    arquivos: any = [];
    classValidarArquivos: string;
    efeturarPagamento= false;
    //
    exigenciaSelecionado: any;
    posicaoExigenciaSelecionado: any;
    tipoExigenciaSelecioando: any;


    constructor(private fb: FormBuilder, private st: LocalStorage) { }

    ngOnInit(): void {
        this.criarFormMensagens();
        this.criarFormArquivos();
     }

    criarFormMensagens() {
        this.formMensagens = this.fb.group({
            mensagemUpload: ['', Validators.required]
        });
    }

    criarFormArquivos() {
        this.formArquivos = this.fb.group({
            arquivoUpload: ['', Validators.required]
        });
    }

    salvarMensagem(mensagemOptional: any = null, linksDownload = null) {
        if (this.formMensagens.valid || mensagemOptional != null) {
            let usuarioLogado = this.st.localStorageGet('userlogged');
            let dataUtc = new Date().toISOString();
            this.formArquivos.controls['arquivoUpload'].setValue(this.arquivos)

            let mensagemExigencia = {
                mensagem: mensagemOptional == null ? this.formMensagens.get('mensagemUpload')?.value : mensagemOptional,
                nomeUsuario: usuarioLogado?.nomeUsuario,
                dataMensagem: dataUtc,
                linksDownload: this.arquivos  //linksDownload
            };

            //console.log("primeira etapa     ", mensagemExigencia)
            //console.log(this.formArquivos)
            //console.log(this.arquivos)

            this.formMensagens.reset()
            this.enviarMensagemExigenciaEvent.emit({ mensagemExigencia });
        }
    }

    validarMensagemUploadRequerid(): boolean {
        return this.formMensagens.get('mensagemUpload')?.errors?.['required'] && this.formMensagens.get('mensagemUpload')?.touched;
    }

    setaArquivos(event: any) {
        if (event.tipo === 1) {
            this.arquivos = [...this.arquivos, ...event.arquivos]
            this.formArquivos.controls['arquivoUpload'].setValue(this.arquivos);

            this.ValidarArquivos();
            //console.log('arquivos = ', this.arquivos);

            if (this.formArquivos.valid)
                this.salvarMensagem(`${event.arquivos.length} documento(s) adicionado(s) ao ato como resposta à exigencia.`, event.arquivos);
        }
    }

    resetaArquivos(entrada: any) {
        if (entrada.tipo === 1) {
            this.arquivos = entrada.arquivos
            this.formArquivos.controls['arquivoUpload'].setValue(this.arquivos);
        }
    }

    ValidarArquivos() {
        const camposForm = this.formArquivos;
        const arquivos = camposForm.get('arquivoUpload')?.value;
        const areArquivosFilledPreenchido = arquivos && arquivos.length > 0;

        if (!areArquivosFilledPreenchido)
            this.classValidarArquivos = 'redClass';
        else
            this.classValidarArquivos = '';
    }

  pagar() {
    this.efeturarPagamento = true;
  }


  selecionarTipoUploadExigencia() {
    //console.log('Arquivo selecionado?')
    this.tipoUploadExigencia = 'DOCUMENTO'
    //this.posicaoExigenciaSelecionado = posicao;
    //this.exibicaoLista = true;
    //this.tipoExigenciaSelecioando = tipoExigencia;
    //this.exigenciaSelecionado = exigencia;
  }
}
