<div class="wrapper-item" *ngIf="!compra?.editar">
  <ng-container *ngFor="let comprador of compra?.compradores;let i =index; let last= last; let first = first">
    <div class="row">
      <div class="wrapper-info col-lg-12">
        <h3 class="info-section"><b>Nome da Busca</b></h3>
        <p class="info-section">{{comprador.nome}}</p>
      </div>
    </div>

    <div class="row">
      <div class="wrapper-info col-lg-12">
        <h3 class="info-section"><b>Finalidade</b></h3>
        <p class="info-section">{{compra?.finalidade?.descricao}}</p>
      </div>
    </div>
    <div class="row">
      <div class="wrapper-info col-lg-3">
        <h3 class="info-section"><b>CPF / CNPJ</b></h3>
        <p class="info-section">{{comprador.documento}}</p>
      </div>

      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>CERP</b></h3>
        <p class="info-section">{{compra?.idItem}}</p>
      </div>
    </div>

    <div class="row" *ngIf="comprador.tipoPessoa=='fisica'">

      <div class="wrapper-info col-lg-12">
        <h3 class="info-section"><b>Data</b></h3>
        <p class="info-section">{{comprador.nascimento | date:'dd/MM/yyyy'}}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Nome do Pai</b></h3>
        <p class="info-section">{{comprador.pai}}</p>
      </div>

      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Nome da Mãe</b></h3>
        <p class="info-section">{{comprador.mae}}</p>
      </div>

    </div>
   
  </ng-container>

</div>

<app-form-2 *ngIf="compra?.editar" [edicao]="true" [itemEdicao]="compra"  (salvarItem)="salvarItem($event)"  (cancelarEdicao)="cancelarEdicao(compra)"
            [itemAtual]="compra.produto"></app-form-2>
