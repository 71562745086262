<app-form-cartorio [uf]="uf"
                   [cidade]="cidade"
                   (setTemCoberturaFilho)="setTemCoberturaFilho($event)"
                   (setCartorios)="setCartorios($event)"
                   [cartorioEmissor]="cartorioEmissor"
                   [itemAtual]="itemAtual"
                   [multiplo]="true"
                   [classObrigatoria]="classObrigatoria"></app-form-cartorio>
                   <!-- [classObrigatoria]="classObrigatoria" -->

<ng-container *ngIf="temCobertura || edicao">

  <form [formGroup]="camposForm">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Livro</mat-label>
          <input matInput type="text" class="col-lg-12" formControlName="livro"
                 placeholder="Livro" maxlength="50">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Folha</mat-label>
          <input matInput type="text" class="col-lg-12" formControlName="folha"
                 placeholder="Folha" maxlength="50">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Natureza do ato (exceto testamento)</mat-label>
          <input matInput type="text" class="col-lg-12" formControlName="natureza"
                 placeholder="Natureza do ato (exceto testamento)" maxlength="50">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>CPF/CNPJ</mat-label>
          <input matInput type="text"
                 mask="000.000.000-00||00.000.000/0000-00"
                 class="col-lg-12" formControlName="documento"
                 placeholder="Folha">
          <mat-error *ngIf="camposForm.get('documento')?.errors?.['required']">
            Preenchimento obrigatório
          </mat-error>
          <mat-error *ngIf="camposForm.get('documento')?.errors?.['maxlength']">
            Preenchimento obrigatório
          </mat-error>
          <mat-error *ngIf="camposForm.get('documento')?.errors?.['mask']">
            Preenchimento incorreto
          </mat-error>
          <mat-error *ngIf="camposForm.get('documento')?.errors?.['documentoNotValid']">
            Documento inválido
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label for="dataAto">Data do ato</mat-label>
          <input matInput appMaskDate maxlength="10" [matDatepicker]="picker" type="text" formControlName="dataAto"
                 name="dataAto">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Nome da busca</mat-label>
          <input matInput type="text" class="col-lg-12" formControlName="nomeBusca"
                 placeholder="Nome da busca" maxlength="100">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Nome do pai</mat-label>
          <input matInput type="text" class="col-lg-12" formControlName="pai"
                 placeholder="Nome da busca" maxlength="100">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Nome da mãe</mat-label>
          <input matInput type="text" class="col-lg-12" formControlName="mae"
                 placeholder="Nome da mãe" maxlength="100">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Observação do Ato</mat-label>
          <input matInput type="text" class="col-lg-12" formControlName="observacao"
                 placeholder="Observação" maxlength="255">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>
    </div>

  </form>
  <app-aviso *ngIf="!edicao" [aviso]="itemAtual?.aviso"></app-aviso>

  <div class="row">
    <div class="col-md-5"></div>
    <div class="col-auto" *ngIf="!edicao">
      <button type="button" mat-button  [routerLink]="['/']" class="btn-next" style="color: white; background-color: green;">Voltar</button>
    </div>
    <div class="col-auto" *ngIf="edicao">
      <button type="button" mat-button (click)="cancelarEdicaoForm()" class="btn-next" style="color: white; background-color: green;">Cancelar</button>
    </div>
    <div class="col-auto">
      <button mat-button (click)="updatePedido()" class="btn-next"
              style="display: block; margin: 0 auto;">
              <span  *ngIf="!edicao">Continuar</span> 
              <span  *ngIf="edicao">Salvar</span> 
      </button>
    </div>
  </div>

</ng-container>
