<ul [class]="linkCss">
   <li  *ngFor="let item of links; let j = index;"  [class]="item?.class" [routerLink]="[item?.url]" >
        <ng-container *ngIf="item?.label">
            {{item.label}}
        </ng-container>
        <ng-container *ngIf="item?.icon">
            <img loading="lazy" [class]="item?.class" [src]= "item?.icon" />
        </ng-container>
        <ng-container *ngIf="item?.isButton">
            <button type="button" class="btn btn-primary"  [routerLink]="['./cadastro']">Entrar</button>
        </ng-container>
    </li>
</ul>
