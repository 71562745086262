import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LocalStorage } from '../../_entidade/LocalStorage';
import { CrudService } from '../../_sevice/CrudService';
import { MockService } from '../../_sevice/MockService';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.less']
})
export class GridComponent implements OnInit, OnChanges {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: any;
  @Input() entidadeOriginal: any;


  displayedColumns: any = [];
  columnNames = [];
  entidade: any;
  _bPermitido: boolean = true;
  @Input() conteudoPagina: any;
  @Input() configPage: any;
  @Input() collectionSize: any;
  display: string=''
  @Input() dataSouceGrid: any;
  @Output() recarregarGrid = new EventEmitter<any>();
  edicao: boolean = false 
  novaColuna: any = {
    id: '',
    prop: '',
    subProp: '',
    label: '',
  }
  novaColunaCopy: any = {
    id: '',
    prop: '',
    label: '',
  }
  colunaAcao: any = {
    id: 'acao',
    prop: 'acao',
    label: 'Ação',
  }
  constructor(
    private cdRef: ChangeDetectorRef,
    private crudService: CrudService,
    public mockService: MockService,
    public ls: LocalStorage

  ) {
    this.edicao = this.ls.localStorageGet('modoEdicao') != null

  }
  getLabelKey(key: any): string {
    let lab = this.conteudoPagina.dadosGrid.filter((x: any) => x.prop == key);
    let ret = '';
    // //console.log(lab.lenth)
    if (lab && lab.length > 0) {

      ret = lab[0].label
    }
    return ret;
  }
  getValueKey(key: any): string {
    if (typeof key === 'object') {
      return key.value['Descricao']
    } else {
      return key.value
    }
  }
  ngOnChanges() {
   // this.cdRef.detectChanges();
   // this.loadPagina();

  }

  ngOnInit() {
    this.loadPagina();
  }

  novaEntidade() {
    //this.conteudoPagina.inputs = { ...this.entidadeOriginal };
    this.conteudoPagina.dadosEntidade = this.ls.localStorageGet("dadosEntidadeOriginal");

    this.conteudoPagina.edicao = true;
  }
  editarEntidade(entidade: any) {
    this.conteudoPagina.dadosEntidade = { default: entidade }
    this.conteudoPagina.edicao = true;


  }

  displayExcluir = ''
  openModal() {
    this.displayExcluir = "block";
  }

  excluirEntidade(entidade: any) {
    this.entidade = entidade;
   this.openModal()
  }
  edicaoColuna: boolean = false;
  addColumn(colum?: any) {

    this.display='block'
    if (colum) {
      this.novaColuna = colum
      this.edicaoColuna = true;
    }
    else {
      this.edicaoColuna = false;
      this.novaColuna = { ...this.novaColunaCopy }

    }

  }
  mudarPosicao(soma: any) {

    var idx = this.conteudoPagina.dadosGrid.findIndex((p: any) => p.prop == this.novaColuna.prop)
    var posi = idx + soma;

    if ((this.conteudoPagina.dadosGrid.length - 1) > posi && posi >= 0) {
      var antigo = this.conteudoPagina.dadosGrid[posi];
      var campoatual = this.conteudoPagina.dadosGrid[idx]
      this.conteudoPagina.dadosGrid[posi] = campoatual;
      this.conteudoPagina.dadosGrid[idx] = antigo;
    }


  }
  removerCampo() {
    var idx = this.conteudoPagina.dadosGrid.findIndex((p: any) => p.prop == this.novaColuna.prop)
    this.conteudoPagina.dadosGrid.splice(idx, 1)
    
    (<HTMLInputElement>document.getElementById("btnFechaModaladdColumn")).click();

    this.loadPagina();
  }
  fecharAddColumn() {
    this.display='none'
  }
  confirmaraddColumn() {
    if (!this.edicaoColuna) {
      this.novaColuna.id = this.novaColuna.prop;
      if (!this.novaColuna.subProp || this.novaColuna.subProp == '') {
        delete this.novaColuna.subProp;
      }
      this.conteudoPagina.dadosGrid.splice(this.conteudoPagina.dadosGrid.length - 1, 1)
      this.conteudoPagina.dadosGrid.push({ ...this.novaColuna })
      this.conteudoPagina.dadosGrid.push({ ...this.colunaAcao })
    } else {
      this.novaColuna.id = this.novaColuna.prop;
    }
 
    this.mockService.gerarMockLista(30, this.conteudoPagina);

    //console.log(this.conteudoPagina)
    this.conteudoPagina.paginaAtual = -1;
    this.display = 'none'

    this.loadPagina();
  }

  confirmarExcluirEntidade() {
     
    var vlrpk = this.entidade[this.conteudoPagina.pk];
    if (vlrpk == undefined || vlrpk == null || vlrpk == '') {
      vlrpk = 0;
    }
    var ulr = "";
    if (this.conteudoPagina.urlRest) {
      ulr = this.conteudoPagina.urlRest;
      ulr += '/' + vlrpk
    }
    else if (this.conteudoPagina.urlExcluir) {
      ulr = this.conteudoPagina.urlExcluir;
      ulr += '/' + vlrpk
    }
    if (ulr != '') {
       this.displayExcluir=''
      this.crudService.deleteObservable(ulr).subscribe(data => {
        this.recarregarGrid.emit(true);
      });
    } else {
      let index = this.conteudoPagina.optionsProp.findIndex((x: any) => x.Id === vlrpk);
      this.conteudoPagina.optionsProp.splice(index, 1)

      let tableArr: any[] = this.conteudoPagina.optionsProp
      this.conteudoPagina.collectionSize = tableArr.length

      const start = (this.conteudoPagina.paginaAtual - 1) * this.configPage.pageSize;
      const end = (this.conteudoPagina.paginaAtual) * this.configPage.pageSize;
      let itensCarregar = tableArr.slice(start, end)
      this.dataSouceGrid = new MatTableDataSource(itensCarregar);
    }


  }

  confirmPageChange(pageEvent: PageEvent) {
    this.conteudoPagina.paginaAtual = pageEvent.pageIndex + 1
    this.conteudoPagina.dadosFiltro.default.pagina = pageEvent.pageIndex + 1
    this.conteudoPagina.dadosFiltro.default.tamanhoPagina = pageEvent.pageSize 
    this.recarregarGrid.emit();
  }

  getBackground(dados: any) {
    var temlabel = this.conteudoPagina.inputsFiltro.forms[0].campos.find((x: any) => x.tipo === 'legenda');
    if (temlabel) {
      var corLabel = temlabel.options.find((x: any) => x.Descricao === dados[temlabel['prop']])
      if (corLabel)
        return corLabel.cor
      else
        return '';

    } else {
      return '';
    }
  }

  showColumn(campo: any): boolean {
    if (campo.label == undefined)
      return true;
    else
      return false;
  }

  loadPagina() {
    
    this.columnNames = this.conteudoPagina.dadosGrid;
    this.displayedColumns = this.columnNames.map((x: any) => x.id);
    
  }
}
