
<ng-container *ngFor="let item of conteudo.itens">
  <div class="row">
    <div class="col-lg-9 prot-card-quem-somos"  [style.background]="'url(/assets/' + item.imgbg + ')'">
      <div class="col-lg-5 prot-card-quem-somos-titulo">
        <img loading="lazy" [src]="['/assets/'+item.icone]" alt="">
        <h3 [class]="conteudo.class">{{item.titulo}}</h3>
      </div>
  
      <div class="col-lg-6 prot-card-quem-somos-txt">
        <p>{{item.texto}}</p>
      </div>

    </div>
  </div> 
</ng-container>
