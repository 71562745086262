
<ng-container *ngIf="load && temCobertura">
  <div class="col-md-12">
    <div class="content-list" [class]="classObrigatoria">
      <div class="row">
        <ul class="wrapper-items">
          <li>
            <div class="col-md-7" *ngIf="itemAtual?.tituloBusca != 'Certidão de Busca'">
              <h3>Cartório</h3>
            </div>
            <div class="col-md-7" *ngIf="itemAtual?.tituloBusca == 'Certidão de Busca'">
              <h3>Comarca</h3>
            </div>
            <div class="col-md-3" *ngIf="!itemAtual?.tituloBusca || itemAtual?.tituloBusca != 'Certidão de Busca'">
              <h3>Cidade</h3>
            </div>
            <div class="col-md-2" *ngIf="!iskit">
              <h3>Emolumento</h3>
            </div>
          </li>
        </ul>
        <ul class="wrapper-items">
          <ng-container *ngIf="multiplo">
            <li *ngFor="let item of cartorioEmissor">
              <div class="col-md-7">
                <div class="content-item">
                  <mat-checkbox *ngIf="multiplo" [disabled]="iskit && !itemAtualKit?.livreEscolhaCartorio" [name]="item.idCartorio"
                                [id]="item.IdCartorio" [checked]="item.selecionado" [value]="item.Cartorio"
                                (change)="onCheckboxChange($event, item)">
                  </mat-checkbox>
                  <p class="name-item txt-info">{{item.cartorio}}</p>
                </div>
              </div>
              <div class="col-md-3" *ngIf="itemAtual?.tituloBusca != 'Certidão de Busca'">
                <div class="content-item">
                  <p class="name-item txt-info">{{item.municipio}}</p>
                </div>
              </div>
              <div class="col-md-2" *ngIf="!iskit">
                <div class="content-item">
                  <p class="name-item txt-info">{{item.valor | currency:'BRL'}}</p>
                </div>
              </div>

            </li>

          </ng-container>

          <mat-radio-group *ngIf="!multiplo">
            <li *ngFor="let item of cartorioEmissor">
              <div class="col-md-7">
                <div class="content-item">

                  <mat-radio-button [checked]="item.selecionado"
                                    (change)="onCheckboxChange($event, item)"
                                    class="example-radio-button"
                                    [value]="item.idCartorio"
                                    [disabled]="iskit && !itemAtualKit?.livreEscolhaCartorio">

                  </mat-radio-button>


                  <p class="name-item txt-info">{{item.cartorio}}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="content-item">
                  <p class="name-item txt-info">{{item.municipio}}</p>
                </div>
              </div>
              <div class="col-md-2" *ngIf="!iskit">
                <div class="content-item">
                  <p class="name-item txt-info">{{item.valor | currency:'BRL'}}</p>
                </div>
              </div>

            </li>
          </mat-radio-group>
        </ul>

      </div>
    </div>

  </div>




</ng-container>
