<div class="wrapper-item" *ngIf="!compra?.editar">

  <div class="wrapper-info">
    <h3 class="info-section"><b>UF</b></h3>
    <p class="info-section">{{compra.uf.Sigla}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Cidade</b></h3>
    <p class="info-section">{{compra.cidade.Nome}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Tipo de Registro</b></h3>
    <p class="info-section">{{compra.tipoRegistro}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Tipo de Documento</b></h3>
    <p class="info-section">{{compra.tipoDocumento}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Informação Adicional</b></h3>
    <p class="info-section">{{compra.informacaoAdicional}}</p>
  </div>
</div>

<app-form-12 *ngIf="compra?.editar" [itemEdicao]="compra" [edicao]="true" (cancelarEdicao)="cancelarEdicao(compra)" (salvarItem)="salvarItem($event)"
             [itemAtual]="compra.produto"></app-form-12>
