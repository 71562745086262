import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CrudService } from '../../../_sevice/CrudService';

//import * as dataPage from '../../../../_configPage/global-Page.json';

@Component({
  selector: 'app-crud-gobal-editar',
  templateUrl: './crud-gobal-editar.component.html',
  styleUrls: ['./crud-gobal-editar.component.scss']
})

export class CrudGlobalEditarComponent implements OnInit {
 @Output() listar = new EventEmitter();
  @Output() change = new EventEmitter();
  formManterFuncaoEditar: FormGroup;
  mensagemErro: String = "";
  retInicial: string = "";
  secretarias: any = [];
  estacoes: any = [];
    
  @Input() conteudoPagina: any;
  @Input() entidadeOriginal: any;

  constructor(
    private cadastrosService: CrudService,
    private cdRef: ChangeDetectorRef) {

  }

  async ngOnInit() {
    //console.log('333',this.conteudoPagina)
  }  

  ngOnChanges() {
    //this.cdRef.detectChanges();
  }
  listarGrid(){
    this.listar.emit();
  }
  ngAfterViewInit(): void {
   // this.cdRef.detectChanges();
  }

  async salvarFuncao() {
    if (this.conteudoPagina.dadosEntidade.Id != '' && this.conteudoPagina.dadosEntidade.Id != '0') {
      await this.cadastrosService.postObservable(this.conteudoPagina.dadosEntidade, this.conteudoPagina.urlIncluir)
        .subscribe((resposta: any) => {

        if (resposta) {
          this.conteudoPagina.edicao = false;

        }

      })
    } else {
      await this.cadastrosService.putObservable(this.conteudoPagina.dadosEntidade, this.conteudoPagina.urlAlterar)
        .subscribe((resposta: any) => {

        if (resposta) {
          this.conteudoPagina.edicao = false;

        }

      })
    }

  } 
}
