import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { globalPage } from '../../_configPage/globalPage';
import { ModelPage } from '../../_configPage/modelPage';
import { LocalStorage } from 'src/app/_entidade/LocalStorage';
import { CrudService } from 'src/app/_sevice/CrudService';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Editor } from 'ngx-editor';

import { DomSanitizer } from "@angular/platform-browser";


//import { LocalStorage } from '@app/_Entidades/LocalStorage';
//import { CrudService } from '@app/_Services/CrudService';
//import { ConfigHotSiteService } from '../../_Services/ConfigHotSiteService';


@Component({
  selector: 'menu-edicao-topo',
  templateUrl: './menu-edicao-topo.component.html',
  styleUrls: ['./menu-edicao-topo.component.less']
})
export class MenuEdicaoTopoComponent implements OnInit, OnDestroy {

  json: boolean = false;
  model: any = new ModelPage().Itens;
  configPage: any = new globalPage().Itens;
  objeto: any;
  entidadeObjeto: any;
  cliente: any;
  pagina: any;
  showConfig: any;
  newAba: boolean = false;
  @Output()
  mudarShowConfig: EventEmitter<any> = new EventEmitter<any>();
  strNewAba: string;
  indiceAba: number = 0;
  rotaNewAba: string;
  novoInput: any = {}
  novoCardSequencial: any = {}
  encontreServicos: any = {}
  IdPaginaModel: any = 0
  @Input() conteudoPagina: any;
  @Input() icone: any = false;
  pgInfo: any
  editandoCard: any = false;
  novoCard: any = false;
  @Input() iten: any;
  @Input() title: any = '';
  @Input() tipoIcone: any = false;
  @Input() blocoAnotacao: any = false;
  @Input() idxWorkflow: any = -1
  @Output() changeConteudo = new EventEmitter<any>();
  anotacaoSistemaAberto: any
  editor: Editor;
  html = '';
  //  { valor: 'matheus.placio', key: 'asdg7*3', nome: 'Mathues Placio', perfil: 'dev' },

  usuarios = [
    { valor: 'heliegesio.souza',key:'er@44s6', nome: 'Heliegesio Souza', perfil:'superAdmin' },
    { valor: 'enio.vieira', key: 'ajkrt@45', nome: 'Ênio Vieira', perfil: 'dev' },  
    { valor: 'filipe.martins', key: 'aj5@ff56', nome: 'Filipe Martins', perfil: 'dev' },
    { valor: 'fernando.spelling', key: 'asd!de', nome: 'Fernando Spelling', perfil: 'dev' },
    { valor: 'alexandre.esteves', key: '34@66!dg', nome: 'Alexandre Esteves', perfil: 'dev' },
    { valor: 'anderson.andrade', key: 'f@gg!Fdd', nome: 'Anderson Andrade', perfil: 'dev' },
    { valor: 'cintia.valadares', key: 'aa@@rt', nome: 'Cintia Valadares', perfil: 'superAdmin' },
    { valor: 'daniel.vieira', key: 'rr!fhr', nome: 'Daniel Vieira', perfil: 'superAdmin' },
    { valor: 'gabriel.sarrat', key: '35j@5r4h', nome: 'Gabriel Sarrat', perfil: 'stakeholder' }
    
  ]
  prioridadeSelected: any = '1'

  usuarioSelected: any = this.ls.localStorageGet('userTeste')
  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Type something. Test the Editor... ヽ(^。^)丿',
    translate: 'no',
    "toolbar": [
      ["bold", "italic", "underline", "strikeThrough", "superscript", "subscript"],
      ["fontName", "fontSize", "color"],
      ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "indent", "outdent"],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      ["paragraph", "blockquote", "removeBlockquote", "horizontalLine", "orderedList", "unorderedList"],
      ["link", "unlink", "image", "video"]
    ]
  };
  constructor(
    public ls: LocalStorage,
    public service: CrudService,
    private route: Router,
    private sanitizer: DomSanitizer

  ) {
    this.pgInfo = this.ls.localStorageGet("pgInfo")
  }

  ngOnInit(): void {
    if (this.conteudoPagina == null) {
      this.conteudoPagina = this.model[0]

    }
    this.editor = new Editor();
    this.definirSelecaoUsuario()
    //var item = this.conteudoPagina.dadosAbas[this.conteudoPagina.dadosAbas.length - 1]
    //this.indiceAba =  1;
  }
  salvarModificacoes() {

  }
  ngOnDestroy(): void {
    this.editor.destroy();
  }
  recarregar() {
    this.ls.localStorageRemove("pgCrudEdit")
    this.ls.localStorageRemove("pgInfo")
    window.location.reload()
  }
  changeFlagBackgroundPagina(objeto: any) {
    objeto.backgroundPersonalizado = !objeto.backgroundPersonalizado
  }

  editCard(iten: any) {
    this.novoCardSequencial = iten
    this.editandoCard = true
  }

  addNovoCard() {
    this.novoCardSequencial = {}
    this.novoCard = true

  }
  definirSelecaoUsuario() {
    let use = this.ls.localStorageGet("userTeste")
    let key = this.ls.localStorageGet("keyQa")
    let useQa = this.usuarios.find((x: any) => x.valor == use && x.key == key && (x.perfil == 'stakeholder' || x.perfil == 'po'))

    if (useQa) {
      this.usuarioSelected=''
    } 
  }
  
  getItem(item: any) {
    return this.sanitizer.bypassSecurityTrustHtml(item)
  }
  consolePagina() {

    let pd = this.ls.localStorageGet("pgInfo")
    delete pd.ListAnotacaoPagina;
    let imte = (JSON.stringify(pd).replace(/\\n/g, '')
      .replace(/  /g, ' ').replace(/    /g, ' ').replace(/  /g, ' ').replace(/   /g, ' '))

    var input = document.createElement('input');
    input.setAttribute('value', imte);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    //console.log(imte)


  }
  verJson() {
    this.json = true;

  }

  jsonFormat() {
    return this.conteudoPagina;
  }

  onChangePaina(event: any) {
    let pagina = (<HTMLTextAreaElement>event.target).value
    var novoConteudo;
    if (pagina == 'crud') {
      novoConteudo = this.model[0];
    } else {
      novoConteudo = this.configPage.find((c: any) => c.pagina.toLocaleLowerCase() == pagina);

    }
    //var item = novoConteudo.dadosAbas[this.conteudoPagina.dadosAbas.length - 1]
    //this.indiceAba = item.id + 1;

    this.ls.localStorageSet('pgConfig', novoConteudo);
    this.changeConteudo.emit(pagina)
  }
  salvarJson2() {

    if (this.modifyProjeto) {
      let pgInfo = this.ls.localStorageGet("projeto")
      let jconfg = pgInfo.JsonConfiguracao
      pgInfo.JsonConfiguracao = JSON.stringify(jconfg)
      this.service.putObservableDirect(environment.http.API_SISTEMA + 'projeto', pgInfo).subscribe(
        resposta => {
          resposta.JsonConfiguracao = JSON.parse(resposta.JsonConfiguracao)
          this.ls.localStorageSet('projeto', resposta);
          window.location.reload()
        })
    } else {
      let pgInfo = this.ls.localStorageGet("pgInfo")
      this.service.putObservableDirect(environment.http.API_SISTEMA + 'pagina', pgInfo).subscribe(
        resposta => {
          this.ls.localStorageRemove('pgInfo');
          window.location.reload()
        })
    }


  }


  salvarNovaPagina() {

    let projeto = this.ls.localStorageGet("projeto")
    this.novoInput["IdPagina"] = 0
    this.novoInput["IdProjeto"] = projeto.IdProjeto

    let pgCopia = this.paginas.find((x: any) => x.IdPagina == this.IdPaginaModel)
    let json = JSON.parse(pgCopia.JsonPagina)
    json.pagina = this.novoInput["Nome"]
    this.novoInput["JsonPagina"] = JSON.stringify(json)
    //console.log(json)

    this.service.postObservableDirect(environment.http.API_SISTEMA + 'pagina', this.novoInput).subscribe(
      resposta => {
        this.ls.localStorageSet('pgInfo', resposta);
        this.route.navigate([resposta.Url])

      })
  }
  salvarAlteracaoComponent() {
    let pg = this.ls.localStorageGet("pgInfo")
    pg.JsonPagina = JSON.stringify(this.conteudoPagina)

    this.ls.localStorageSet("pgInfo", pg)
    this.salvarJson2()


  }

  salvarConfiguracaoModulo() {
    //console.log('-------------- OBJETO ----------------')
    //console.log(this.objeto)
  }

  display = ''
  openModal() {
    this.display = "block";
  }
  onCloseHandled() {
    this.display = "none";
  }
  paginas: any
  novaPagina() {
    let projeto = this.ls.localStorageGet("projeto")

    this.service.getObservableDirect(environment.http.API_SISTEMA + 'pagina/' + projeto.IdProjeto).subscribe(
      resposta => {
        this.paginas = resposta
        //console.log(resposta)
        this.openModal()
      })
  }

  modifyProjeto: boolean = false
  displayJson = ''
  displayAnotacao = ''
  displayComponent = ''
  addAnotacao: boolean = true
  mostrarAnotacao: boolean = false;
  anotacaoSistema: any
  anotacaoFechados: any
  jsonPg: any

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {

    if (event.key == 'Escape') {
      this.displayJson = "none";
      this.displayAnotacao = "none";
      this.displayComponent = "none";
      this.display = "none";


    }
  }

  openModalJson() {
    var pg = this.ls.localStorageGet("pgInfo");
    if (pg && pg.JsonPagina) {
      this.jsonPg = JSON.parse(this.ls.localStorageGet("pgInfo").JsonPagina)

    } else {
      this.jsonPg = this.ls.localStorageGet("pgCrudEdit")

    }
    this.modifyProjeto = false
    this.displayJson = "block";
  }

  openModalJsonHF() {
    this.jsonPg = this.ls.localStorageGet("projeto").JsonConfiguracao
    this.modifyProjeto = true

    this.displayJson = "block";
  }
  onCloseHandledJson() {
    this.displayJson = "none";
  }
  anotacaoEdicao: any = { Anotacao: "", IdAnotacaoPagina: 0, IdComponente: 0, IdPagina: 0, IndexWorkflow: -1 };

  temComentario(IdComponente: any, title: any, indexWorkflow: any = -1) {
    return ''
    // //console.log(this.pgInfo.ListAnotacaoPagina.find((x: any) => x.IdComponente == IdComponente && x.IndexWorkflow==indexWorkflow && x.IdPagina==this.pgInfo.IdPagina))
    var tem = this.pgInfo?.ListAnotacaoPagina.find((x: any) => x.IdComponente == IdComponente
      && x.IndexWorkflow == indexWorkflow && x.IdPagina == this.pgInfo.IdPagina)
    var red = tem == null ? tem : (tem?.Anotacao != null && tem?.Anotacao != '' && tem.Anotacao != '<p></p>') ? tem : null
    return red == null ? '' : 'red'
  }
  isVisaoTotal: boolean = false
  fechados: boolean = false
  openModalAnotacao(IdComponente: any, title: any, indexWorkflow: any = -1) {
    this.fechados=false
      this.abrirCarregando()
    this.fecharAnotacoesFechadas();
    this.anotacaoSistemaAberto = []
    this.addAnotacao = true
    if (title == 'Anotações') {
      let content = this.ls.localStorageGet("pgInfo")
      this.iniciaVariavel()
      this.service.getObservableDirect(environment.http.API_SISTEMA + 'AnotacaoPagina/GetPage/' + content.IdPagina + '/' + indexWorkflow + '/' + IdComponente).subscribe(
        resposta => {
          if (resposta != null && resposta.Anotacao != '') {
            this.anotacaoSistemaAberto = resposta

          }
        })



      this.displayAnotacao = "block";
    } else {
      this.displayComponent = "block";

    }
  }

  fecharAnotacao(item: any, posi: any,tipo:any) {
      this.abrirCarregando()

    this.service.postObservableDirect(environment.http.API_SISTEMA + 'AnotacaoPagina/MarcarAjustado/', item).subscribe(
      resposta => {
        if (tipo == 2)
          this.anotacaoSistema.splice(posi, 1)
        else
          this.anotacaoSistemaAberto.splice(posi, 1)
      })
  }
  atribuirAnotacao(item: any) {
      this.abrirCarregando()
    this.service.postObservableDirect(environment.http.API_SISTEMA + 'AnotacaoPagina/MarcarResponsavel/', item).subscribe(
      resposta => {
         
      })
  }


  getFechados(IdComponente: any, title: any, indexWorkflow: any = -1) {
    this.isVisaoTotal = false
    this.fechados = true
    if (title == 'Anotações') {
      this.anotacaoSistemaAberto = null
      this.abrirCarregando()

      let content = this.ls.localStorageGet("pgInfo");
      this.service.getObservableDirect(environment.http.API_SISTEMA + 'AnotacaoPagina/GetPageAjustado/' + content.IdPagina + '/' + indexWorkflow + '/' + IdComponente).subscribe(
        resposta => {
          if (resposta != null && resposta.Anotacao != '') {
            this.anotacaoFechados = resposta;
            this.mostrarAnotacao = true;
          } else {
            this.mostrarAnotacao = false;
            this.anotacaoFechados = '';
          }
        });
    } else {
      this.fecharAnotacoesFechadas();
    }
  }

  fecharAnotacoesFechadas() {
    this.anotacaoFechados = '';
    this.mostrarAnotacao = false;
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  acessar(item: any) {
    let it = JSON.parse(item?.LocalStorage)

    if (it?.item_atual)
      this.ls.localStorageSet("item_atual", it?.item_atual)
    if (it?.pedido)
      this.ls.localStorageSet("pedido", it?.pedido)
    if (it?.localidade)
      this.ls.localStorageSet("localidade", it?.localidade)

    //this.route.navigate([item?.Url])
    location.href = item?.Url
  }
  abrirCarregando() {
    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";
  }

  openModalAnotac2d(title: any, status: boolean) {
    this.addAnotacao = false
    this.isVisaoTotal = true
    this.fechados = status
    let statussend = status ? 0 : 1;
    this.anotacaoSistema = []
    if (title == 'Anotações') {
      let content = this.ls.localStorageGet("pgInfo")
      let urBusca = 'Pagina/GetAnotacoesProjeto/' + content.IdProjeto + '/0/0/2';

      this.abrirCarregando()

      this.service.getObservableDirect(environment.http.API_SISTEMA + urBusca).subscribe(
        resposta => {
          if (resposta != null) {

            this.anotacaoSistema = resposta
          }
        })

      this.displayAnotacao = "block";
    } else {

      this.displayComponent = "block";

    }
  }

  openModalAnotac(title: any,status:boolean) {
    this.addAnotacao = false
    this.isVisaoTotal = true
    this.fechados = status
    let statussend = status ? 0: 1;
    this.anotacaoSistema = []
    if (title == 'Anotações') {
      let content = this.ls.localStorageGet("pgInfo")
      let urBusca = this.usuarioSelected == '' ? 'Pagina/GetProjetoAnotacoes/'
        + content.IdProjeto + '/' + statussend + '/' + this.prioridadeSelected + '/2' : 'Pagina/GetProjetoAnotacoesUsuario/'
        + content.IdProjeto + '/' + this.usuarioSelected + '/' + statussend + '/' + this.prioridadeSelected;

      this.abrirCarregando()

      this.service.getObservableDirect(environment.http.API_SISTEMA + urBusca).subscribe(
        resposta => {
          if (resposta != null) {
             
            this.anotacaoSistema = resposta
          }
        })
     
      this.displayAnotacao = "block";
    } else {
      
      this.displayComponent = "block";

    }
  }

  getIdexAnotacoes() {
    if (document.location.href.indexOf('meu-carrinho') > -1)
      return -1
    else if (document.location.href.indexOf('pagar') > -1)
      return -2
    else if (document.location.href.indexOf('conclusao') > -1)
      return -3
    else if (document.location.href.indexOf('meus-pedidos') > -1)
      return -4
    else if (document.location.href.indexOf('pedido') > -1)
      return (this.itemAtual?.idItemUnico != null
        && this.itemAtual?.idItemUnico != undefined) ? this.itemAtual?.idItemUnico : this.itemAtual?.idAto
    else
      return -5
  }
  get itemAtual() {
    return this.ls.localStorageGet("item_atual");
  }

  iniciaVariavel() {
    this.anotacaoEdicao = null
    let content = this.ls.localStorageGet("pgInfo")
    let user = this.ls.localStorageGet("userTeste")
    let url = this.route.url

    let jsnoitens = { item_atual: this.ls.localStorageGet("item_atual"), pedido: this.ls.localStorageGet("pedido"), localidade: this.ls.localStorageGet("localidade") }

    // 
    this.anotacaoEdicao = { Anotacao: "", IdAnotacaoPagina: 0, IdComponente: 'workflow', IndexWorkflow: this.idxWorkflow, IdPagina: content.IdPagina, Url: url, Usuario: user, LocalStorage: JSON.stringify(jsnoitens) }
  }

  salvarJsonAnotacao() {
    this.anotacaoEdicao.Anotacao = this.anotacaoEdicao.Anotacao.replace(/<img /g, "<img style='width:100%' ")

    if (this.anotacaoEdicao.IdAnotacaoPagina > 0) {
      this.service.putObservableDirect(environment.http.API_SISTEMA + 'AnotacaoPagina', this.anotacaoEdicao).subscribe(
        resposta => {
          //this.onClosedisplayAnotacao()
          this.iniciaVariavel()
          this.openModalAnotacao('workflow', 'Anotações', this.getIdexAnotacoes())
          //this.ls.localStorageRemove('pgInfo');
          //window.location.reload()
        })
    } else {
      this.service.postObservableDirect(environment.http.API_SISTEMA + 'AnotacaoPagina', this.anotacaoEdicao).subscribe(
        resposta => {
          //this.onClosedisplayAnotacao()
          this.iniciaVariavel()
          this.openModalAnotacao('workflow', 'Anotações', this.getIdexAnotacoes())
          //this.ls.localStorageRemove('pgInfo');
          //window.location.reload()
        })
    }



  }
  showQAAdmin() {
    let use = this.ls.localStorageGet("userTeste")
    let key = this.ls.localStorageGet("keyQa")
    let useQa = this.usuarios.find((x: any) => x.valor == use && x.key == key && (x.perfil == 'stakeholder' || x.perfil == 'po' || x.perfil == 'dev' || x.perfil == 'superAdmin'))

    return useQa != null
  }

  isQaSuperAdmin() {
    let use = this.ls.localStorageGet("userTeste")
    let key = this.ls.localStorageGet("keyQa")
    let useQa = this.usuarios.find((x: any) => x.valor == use && x.key == key && (x.perfil == 'superAdmin'))


    return useQa != null
  }

  isQaPo() {
    let use = this.ls.localStorageGet("userTeste")
    let key = this.ls.localStorageGet("keyQa")
    let useQa = this.usuarios.find((x: any) => x.valor == use && x.key == key && (x.perfil == 'po' || x.perfil == 'stakeholder'))


    return useQa != null
  }

  isQaDev() {
    let use = this.ls.localStorageGet("userTeste")
    let key = this.ls.localStorageGet("keyQa")
    let useQa = this.usuarios.find((x: any) => x.valor == use && x.key == key && (x.perfil == 'dev' ))


    return useQa != null
  }
   
  isQADelete() {
    let use = this.ls.localStorageGet("userTeste")
    let key = this.ls.localStorageGet("keyQa")
    let useQa = this.usuarios.find((x: any) => x.valor == use && x.key == key && (x.perfil == 'superAdmin'))
    return useQa != null
  }
  deletar(item: any,j:any) {
    this.service.deleteObservableDirect(environment.http.API_SISTEMA + 'AnotacaoPagina/Delete/' + item.IdAnotacaoPagina).subscribe(
      resposta => {

        this.anotacaoSistema.splice(j,1)

      })
  }
  onClosedisplayAnotacao() {
    this.displayAnotacao = "none";
  }

  onClosedisplayConfiguracao() {
    this.displayComponent = "none";
  }
  alterarJson() {

    try {

      JSON.parse((<HTMLInputElement>document.getElementById("jsonvalue")).value)

    } catch (e) {
      alert('Json inválido')
      return
    }


    if (this.modifyProjeto) {


      let pg = this.ls.localStorageGet("projeto");
      pg.JsonConfiguracao = JSON.parse((<HTMLInputElement>document.getElementById("jsonvalue")).value);

      this.ls.localStorageSet("projeto", pg)
      this.salvarJson2()

    } else {
      let pg = this.ls.localStorageGet("pgInfo")
      pg.JsonPagina = (<HTMLInputElement>document.getElementById("jsonvalue")).value

      this.ls.localStorageSet("pgInfo", pg)
      this.salvarJson2()
    }


  }
  isModoEdicao() {
    return true;
    //return this.storage.ModoEdicao();
  }


  async changeBackground($event: any, objeto: any) {
    //console.log('Change Image Banner...')

    //await this.uploadImagem($event)
    //	.then((data) => {
    //		objeto.backgroundPaginaSrc = data.Url;
    //	})
  }
  changeInput(event: any) {

  }
  async uploadImagem($event: any) {

    //console.log('-------- UPLOAD CONTENT ---------');
    //console.log(input)

    var file: File = $event.target.files[0];

    const objImagem: any = {
      Nome: null,
      ArquivoByte: null,
      Tipo: null,
      IdCliente: 42
    }

    await this.fileToBase64(file)
      .then((arquivoByte: any) => {
        objImagem.Nome = file.name;
        objImagem.ArquivoByte = arquivoByte.split(',')[1]
        objImagem.Tipo = "Imagem";

        //campo.fileUrl = arquivoByte;
      })

    //return await this.crudService.post(objImagem, 'v1/ConfiguracaoSite/UploadImagem')
    //	.then((data) => {
    //		console.log('Return data -----------')
    //		console.log(data)

    //		return data;
    //	})
  }


  async fileToBase64(file: File) {
    return new Promise((resolve, reject) => {

      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    })
  }

}
