import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-galeria-img',
  templateUrl: './galeriaImg.component.html',
  styleUrls: ['./galeriaImg.component.scss']
})
export class GaleriaImgComponent implements OnInit {
  @Input() conteudo: any;
  public environment = environment;
  constructor() { }

  ngOnInit(): void {

  }

}
