import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalStorage } from 'src/app/_entidade/LocalStorage';

@Component({
  selector: 'app-orderDetails-22',
  templateUrl: './orderDetails2.2.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class OrderDetails22Component implements OnInit {
  @Input() item: any
  @Output() excluirItem = new EventEmitter<any>();
  @Output() editar = new EventEmitter<any>();
  public visibilidadeDetalhes: boolean=false;


  constructor(
  ) {

  }

  ngOnInit(): void {

  }

  emitirEventoESetarEdicao(item: any) {
    this.editar.emit(item);
  }

}
