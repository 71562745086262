<div *ngIf="mensagens">
  <div class="mt-2">
    <b>Lista de mensagens</b>
  </div>

    <div class="wrapper-item mt-2">
        <div class="row" *ngFor="let mensagem of mensagens">
          <div class="d-flex justify-content-between linha-exigencia">
            <div class="wrapper-info col-lg-4 m-2">
              <h3 class="title-section">Nome Usuario</h3>
              <p class="info-section-exigencia">{{mensagem?.nomeUsuario}}</p>
            </div>

            <div class="wrapper-info col-lg-5 m-2">
              <h3 class="title-section">Mensagem</h3>
              <p class="info-section-exigencia">{{mensagem?.mensagem}}</p>
              <p class="info-section-exigencia" *ngFor="let link of mensagem?.linksDownload">
                <a [href]="link.nome" target="_blank">{{link.nomeOriginal}}</a>
              </p>
            </div>

            <div class="wrapper-info col-lg-2 m-2">
              <h3 class="title-section">Data</h3>
              <p class="info-section-exigencia">{{mensagem?.dataMensagem | date: 'dd/MM/yyyy HH:mm:ss':'UTC'}}</p>
            </div>

          </div>
        </div>
    </div>
</div>
