import { FooterV3Component } from './core/footer/v3/footer.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AsyncPipe, CommonModule, NgFor, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav'; // Importe MatSidenavModule
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './core/footer/footer.component';
import { HeaderComponent } from './core/header/header.component';
import { CadastrarUsuarioComponent } from './_components/cadastrar-usuario/cadastrar-usuario.component';
import { ModalPadrao } from './_components/dialog/modal-padrao';
import { ListarUsuarioComponent } from './_components/listar-usuario/listar-usuario.component';
import { MeusRelatoriosComponent } from './_components/meus-relatorios/meus-relatorios.component';
import { PaginaComponent } from './_components/paginaGlobal/pagina.component';
import { UlComponent } from './_components/ul/ul.component';
import { LocalStorage } from './_entidade/LocalStorage';
import { AuthenticationGuard } from './_sevice/AuthenticationGuard';
import { CrudService } from './_sevice/CrudService';
import { GlobalService } from './_sevice/GlobalService';
import { HeadersInterceptor } from './_sevice/headers.interceptor';
import { GraficoComponent } from './_components/graficos/grafico.component';
import { UsuarioPerfilComponent } from './_components/usuario-perfil/usuario-perfil.component';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TrocarSenhaComponent } from './_components/trocar-senha/trocar-senha.component';
import localePt from '@angular/common/locales/pt';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CustomDateAdapter } from './_components/meus-relatorios/custom.date.adapter';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FuncaoGlobal } from './_sevice/FuncaoGlobal';
import { CrudGlobalComponent } from './_components/crudGlobal/crud-global.component';
import { GridComponent } from './_components/grid/grid.component';
import { CardComponent } from './_components/card/card.component';
import { MatTableModule } from '@angular/material/table';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatStepperModule} from '@angular/material/stepper';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CrudGlobalListaComponent } from './_components/crudGlobal/paginas/crud-gobal-lista.component';
import { GenericFormComponent } from './_components/generic-form/generic-form.component';
import { InputComponent } from './_components/UI/input/input.component';
import { TableDataComponent } from './_components/UI/table-data/table-data.component';
import { MatSelectModule } from '@angular/material/select';
import { CrudGlobalEditarComponent } from './_components/crudGlobal/paginas/crud-gobal-editar.component';
import { MenuEdicaoTopoComponent } from './_components/menu-edicao-topo/menu-edicao-topo.component';
import { FooterComponentV1 } from './core/footer/v1/footer.component';
import { FooterComponentV2 } from './core/footer/v2/footer.component';
import { LoginComponent } from './_components/login/login.component';
import { CardSequencialComponent } from './_components/cardSequencial/cardSequencial.component';
import { FullBannerComponent } from './_components/fullbanner/fullbanner.component';
import { GaleriaImgComponent } from './_components/galeriaImg/galeriaImg.component';
import { ConteudoComponent } from './_components/conteudo/conteudo.component';
import { TreeComponent } from './_components/tree/tree.component';
import { MatTreeModule } from '@angular/material/tree';
import {MatDividerModule} from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CardHorizontalComponent } from './_components/cardHorizontal/cardHorizontal.component';
import { BannerComponent } from './_components/banner/banner.component';
import { ConsultaProtestoComponent } from './_components/consulta-protesto/consultaProtesto.component';
import { TextoInstitucionalComponent } from './_components/texto-institucional/texto-institucional.component';
import { AjudaComponent } from './_components/ajuda/ajuda.component';
import { LogLocalStorage } from './_components/logLocalStorage/logLocalStorage.component';
import { PaymentComponent } from './_components/payment/payment.component';
import { EncontreServicosComponent } from './_components/encontreServicos/encontreServicos.component';
import { CadastroComponent } from './_components/cadastre-se/cadastre-se.component';
import { FormStepsComponent } from './_components/formSteps/formSteps.component';
import { Form1Component } from './_components/formSteps/forms/form1.component';
import { Form2Component } from './_components/formSteps/forms/form2.component';
import { Form21Component } from './_components/formSteps/forms/form2.1.component';
import { Form3Component } from './_components/formSteps/forms/form3.component';
import { Form4Component } from './_components/formSteps/forms/form4.component';
import { Form5Component } from './_components/formSteps/forms/form5.component';
import { Form6Component } from './_components/formSteps/forms/form6.component';
import { Form7Component } from './_components/formSteps/forms/form7.component';
import { Form8Component } from './_components/formSteps/forms/form8.component';
import { Form9Component } from './_components/formSteps/forms/form9.component';
import { Form10Component } from './_components/formSteps/forms/form10.component';
import { Form101Component } from './_components/formSteps/forms/form10.1.component';
import { Form11Component } from './_components/formSteps/forms/form11.component';
import { Form12Component } from './_components/formSteps/forms/form12.component';
import { Form13Component } from './_components/formSteps/forms/form13.component';
import { Form14Component } from './_components/formSteps/forms/form14.component';
import { Form15Component } from './_components/formSteps/forms/form15.component';
import { MatProgressSpinnerModule  } from '@angular/material/progress-spinner';
import { ImageViewerComponent } from './_components/imageViewer/imageViewer.component';
import { NgxCurrencyDirective } from 'ngx-currency';
import { BtnWhatsAppFlutuanteComponent } from './_components/btn-whatsapp-flutuante/btn-whatsapp.component';
import { SearchBarComponent } from './_components/searchbar/searchbar.component';
import { ListServiceComponent } from './_components/list-services/list-service.component';
import { MeusPedidosComponent } from './_components/meus-pedidos/meus-pedidos.component';
import { NgxEditorModule } from 'ngx-editor';
import { OrderDetails1Component } from './_components/formSteps/oderDetails/orderDetails1.component';
import { OrderDetails2Component } from './_components/formSteps/oderDetails/orderDetails2.component';
import { OrderDetails21Component } from './_components/formSteps/oderDetails/orderDetails2.1.component';
import { OrderDetails3Component } from './_components/formSteps/oderDetails/orderDetails3.component';
import { OrderDetails4Component } from './_components/formSteps/oderDetails/orderDetails4.component';
import { OrderDetails5Component } from './_components/formSteps/oderDetails/orderDetails5.component';
import { OrderDetails6Component } from './_components/formSteps/oderDetails/orderDetails6.component';
import { OrderDetails15Component } from './_components/formSteps/oderDetails/orderDetails15.component';
import { OrderDetails14Component } from './_components/formSteps/oderDetails/orderDetails14.component';
import { OrderDetails13Component } from './_components/formSteps/oderDetails/orderDetails13.component';
import { OrderDetails12Component } from './_components/formSteps/oderDetails/orderDetails12.component';
import { OrderDetails11Component } from './_components/formSteps/oderDetails/orderDetails11.component';
import { OrderDetails7Component } from './_components/formSteps/oderDetails/orderDetails7.component';
import { OrderDetails8Component } from './_components/formSteps/oderDetails/orderDetails8.component';
import { OrderDetails9Component } from './_components/formSteps/oderDetails/orderDetails9.component';
import { OrderDetails10Component } from './_components/formSteps/oderDetails/orderDetails10.component';
import { OrderDetails101Component } from './_components/formSteps/oderDetails/orderDetails10.1.component';
import { MeusPedidosOrderDetails1Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail1.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatListModule} from '@angular/material/list';
import { FileDragNDropDirective } from './_directives/fileDragDrop';
import { ProdutoSemCobertura } from './_components/produto-sem-cobertura/produtoSemCobertura.component';
import { FormCartorioComponent } from './_components/formSteps/forms/formCartorio.component';
import { MeusPedidosOrderDetails2Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail2.component';
import { MeusPedidosOrderDetails3Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail3.component';
import { MeusPedidosOrderDetails4Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail4.component';
import { MeusPedidosOrderDetails5Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail5.component';
import { MeusPedidosOrderDetails6Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail6.component';
import { MeusPedidosOrderDetails7Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail7.component';
import { MeusPedidosOrderDetails8Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail8.component';
import { MeusPedidosOrderDetails9Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail9.component';
import { MeusPedidosOrderDetails10Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail10.component';
import { MeusPedidosOrderDetails11Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail11.component';
import { MeusPedidosOrderDetails12Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail12.component';
import { MeusPedidosOrderDetails13Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail13.component';
import { MeusPedidosOrderDetails14Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail14.component';
import { MeusPedidosOrderDetails15Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail15.component';
import { MeusPedidosOrderDetails21Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail21.component';
import { MeusPedidosOrderDetails22Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail22.component';
import { DateMaskComponent } from './_directives/date-mask.component';
import { UploadFilesComponent } from './_components/uploadArquivos/uploadArquivos.component';
import { PaymentV1Component } from './_components/payment/v1/payment.component';
import { Form22Component } from './_components/formSteps/forms/form2.2.component';
import { OrderDetails22Component } from './_components/formSteps/oderDetails/orderDetails2.2.component';
import { AvisoComponent } from './_components/formSteps/forms/aviso.component';
import { FormKitComponent } from './_components/formSteps/forms/formKit.component';
import { NgxPrintModule } from 'ngx-print';
import { GraficoProjetoComponent } from './_components/menu-edicao-topo/graficos/grafico.component';
import { CardSequencialV1Component } from './_components/cardSequencial/v1/cardSequencial.component';
import { FullBannerV1Component } from './_components/fullbanner/v1/fullbanner.component';
import { GaleriaImgV1Component } from './_components/galeriaImg/v1/galeriaImg.component';
import { HeaderComponentV4 } from './core/header/v4/header.component';
import { AdminGlobalComponent } from './_components/adminGlobal/pagina.component';
import { MeusPedidosAreaClienteComponent } from './_components/adminGlobal/components/meus-pedidos/meus-pedidos.component';
import { ScriptService } from './_sevice/script.service';
import { ChamadosTopoComponent } from './_components/menu-edicao-topo/chamados/menu-edicao-topo.component';
import { MeusPedidosOrderDetails101Component } from './_components/meus-pedidos/orderDetails/meusPedidosOrderDetail10.1.component';
import { PaymentV2Component } from './_components/payment/v2/payment.component';
import { ExigenciasComponent } from './_components/meus-pedidos/orderDetails/exigencias/exigencias.component';
import { ListaMensagensExigenciaComponent } from './_components/meus-pedidos/orderDetails/exigencias/postExigencia/listaMensagensExigencia/listaMensagensExigencia.component';
import { SearchBarV1Component } from './_components/searchbar/v1/searchbar.component';
import { PostExigenciaComponent } from './_components/meus-pedidos/orderDetails/exigencias/postExigencia/postExigencia.component';
import { NavBarBuscaComponent } from './_components/nav-bar-busca/nav-bar-busca.component';
import { ModalComponent } from './_components/searchbar/v1mobile/modal.component';
import { AgendamentosZoomComponent } from './_components/meeting-zoom/agendamentos-zoom/agendamentos-zoom.component';
import { CadastroAgendamentoZoomComponent } from './_components/meeting-zoom/cadastro-agendamento-zoom/cadastro-agendamento-zoom.component';
import { SolicitarMediacaoComponent } from './_components/meeting-zoom/solicitar-mediacao/solicitar-mediacao.component';
import { VideoMedicaoZoomComponent } from './_components/meeting-zoom/video-medicao-zoom/video-medicao-zoom.component';
import { MeusAgendamentosComponent } from './_components/meus-agendamentos/meus-agendamentos.component';
import { RelatorioCNRComponent } from './_components/relatorio-cnr/relatorio-cnr.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChunkPipe } from './_components/meeting-zoom/agendamentos-zoom/versao-teste/chunck.pipe';
import { AgendamentosZoomTesteComponent } from './_components/meeting-zoom/agendamentos-zoom/versao-teste/agendamentos-zoom-teste.component';
import { ParceirosComponent } from './_components/parceiros/parceiros.component';
import { CursosComponent } from './_components/cursos/cursos.component';
import { CadastrarCursoComponent } from './_components/cadastrar-curso/cadastrar-curso.component';
import { MeusCurriculosComponent } from './_components/meus-curriculos/meus-curriculos.component';
import { PhonePipe } from './core/utils/phone.pipe';
import { DateFormatPipe } from './core/utils/date.pipe';
import { SchedulesModule } from './_components/schedules/schedules.module';
import { CalendarDateFormatter, CalendarNativeDateFormatter } from 'angular-calendar';
import { HtmlComponent } from './_components/htmlPage/html.component';
import { BancoIdeiasComponent } from './_components/bancoIdeias/bancoideias.component';

registerLocaleData(localePt, 'pt-br');
export const MY_FORMATS = {
  parse: {
    dateInput: 'dd/MM/yyyy'
  },
  display: {
    dateInput: 'dd/MM/yyyy',
  }
}

@NgModule({
  declarations: [
    AppComponent
    , PaginaComponent
    , LoginComponent
    , MeusRelatoriosComponent
    , ListarUsuarioComponent
    , CadastrarUsuarioComponent
    , TrocarSenhaComponent
    , UsuarioPerfilComponent
    , HeaderComponent
    , GraficoComponent
    , FooterComponent
    , UlComponent
    , ModalPadrao
    , FooterComponentV1
    , FooterComponentV2
    , FooterV3Component
    , FullBannerComponent
    , GaleriaImgComponent
    , ConteudoComponent
    , CrudGlobalComponent
    , CrudGlobalListaComponent
    , CrudGlobalEditarComponent
    , CardComponent
    , CardSequencialComponent
    , GridComponent
    , GenericFormComponent
    , BtnWhatsAppFlutuanteComponent
    , CardHorizontalComponent
    , BannerComponent
    , TreeComponent
    , InputComponent
    , TableDataComponent
    , MenuEdicaoTopoComponent
    , ConsultaProtestoComponent
    ,TextoInstitucionalComponent
    , AjudaComponent
    , SearchBarComponent
    , PaymentComponent
    , EncontreServicosComponent
    , ImageViewerComponent
    , UploadFilesComponent
    , CadastroComponent
    , LogLocalStorage
    , FormStepsComponent
    , MeusPedidosComponent
    , ListServiceComponent
    , Form1Component
    , Form2Component
    , Form21Component
    , Form3Component
    , Form4Component
    , Form5Component
    , Form6Component
    , Form7Component
    , Form8Component
    , Form9Component
    , Form10Component
    , Form101Component
    , Form11Component
    , Form12Component
    , Form13Component
    , Form14Component
    , Form15Component
    , OrderDetails1Component
    , OrderDetails2Component
    , OrderDetails21Component
    , OrderDetails3Component
    , OrderDetails4Component
    , OrderDetails5Component
    , OrderDetails6Component
    , OrderDetails7Component
    , OrderDetails8Component
    , OrderDetails9Component
    , OrderDetails10Component
    , OrderDetails101Component
    , OrderDetails11Component
    , OrderDetails12Component
    , OrderDetails13Component
    , OrderDetails14Component
    , OrderDetails15Component
    , MeusPedidosOrderDetails1Component
    , MeusPedidosOrderDetails2Component
    , MeusPedidosOrderDetails3Component
    , MeusPedidosOrderDetails4Component
    , MeusPedidosOrderDetails5Component
    , MeusPedidosOrderDetails6Component
    , MeusPedidosOrderDetails7Component
    , MeusPedidosOrderDetails8Component
    , MeusPedidosOrderDetails9Component
    , MeusPedidosOrderDetails10Component
    , MeusPedidosOrderDetails11Component
    , MeusPedidosOrderDetails12Component
    , MeusPedidosOrderDetails13Component
    , MeusPedidosOrderDetails14Component
    , MeusPedidosOrderDetails15Component
    , MeusPedidosOrderDetails21Component
    , MeusPedidosOrderDetails22Component
    , FileDragNDropDirective
    , ProdutoSemCobertura
    , FormCartorioComponent
    , DateMaskComponent
    , PaymentV1Component
    , Form22Component
    , OrderDetails22Component
    , AvisoComponent
    , FormKitComponent
    , GraficoProjetoComponent
    , CardSequencialV1Component
    , FullBannerV1Component
    , GaleriaImgV1Component
    , HeaderComponentV4
    , AdminGlobalComponent
    , MeusPedidosAreaClienteComponent
    , ChamadosTopoComponent
    , ExigenciasComponent
    , ListaMensagensExigenciaComponent
    , MeusPedidosOrderDetails101Component
    , PaymentV2Component
    , SearchBarV1Component
    , PostExigenciaComponent
    , NavBarBuscaComponent
    , ModalComponent
    , VideoMedicaoZoomComponent
    , AgendamentosZoomComponent
    , CadastroAgendamentoZoomComponent
    , SolicitarMediacaoComponent
    , MeusAgendamentosComponent
    , RelatorioCNRComponent
    , AgendamentosZoomTesteComponent
    , ChunkPipe
    , ParceirosComponent
    , CursosComponent
    , CadastrarCursoComponent
    , MeusCurriculosComponent
    , PhonePipe
    , DateFormatPipe
    , HtmlComponent
    , BancoIdeiasComponent
  ],
  exports: [
    GridComponent
    , CardComponent
    , CrudGlobalComponent
    , PhonePipe
    , DateFormatPipe
  ],
  imports: [
    NgxPrintModule,
    NgxCurrencyDirective,
    NgxMaskDirective,
    BrowserModule,
    CommonModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    ReactiveFormsModule
    , MatIconModule
    , MatInputModule
    , MatButtonModule
    , MatAutocompleteModule
    , MatDialogModule
    , MatExpansionModule
    , MatStepperModule
    , MatGridListModule
    , MatTabsModule
    , MatMenuModule
    , MatFormFieldModule
    , MatRadioModule
    , MatNativeDateModule
    , MatDatepickerModule
    , MatCardModule
    , MatTableModule
    , MatPaginatorModule
    , MatDividerModule
    , MatSelectModule
    , MatCheckboxModule
    , MatTreeModule
    , MatProgressBarModule
    , MatProgressSpinnerModule
    , MatListModule
    , NgxMaskPipe
    , NgxEditorModule
    , MatSnackBarModule
    , NgFor
    , AsyncPipe
    , MatTooltipModule
    , SchedulesModule
    // , SocialLoginModule.initialize(config)

  ],
  providers: [
    provideClientHydration(),
    //{ provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    AuthenticationGuard,
    LocalStorage,
    CrudService,
    FuncaoGlobal,
    HeadersInterceptor,
    GlobalService,
    MatSnackBarModule,
    ScriptService,
    { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-br' },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-br' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: CalendarDateFormatter, useClass: CalendarNativeDateFormatter },
    provideNgxMask(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// const config = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider('Sua-Chave-de-Cliente-do-Google-Aqui')
//   }
// ]);
