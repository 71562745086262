<div style="background-color: aliceblue; width: 100%; padding: 5px; font-weight: 600; margin-bottom: 10px; font-size: 1.3rem;" *ngIf="iskit && itemAtualKit?.subtitulo">{{itemAtualKit?.subtitulo}}</div>

<app-form-cartorio [uf]="uf"
                   [iskit]="iskit"
                   [itemAtualKit]="itemAtualKit"
                   [cidade]="cidade"
                   (setTemCoberturaFilho)="setTemCoberturaFilho($event)" (setCartorios)="setCartorios($event)"
                   [itemAtual]="itemAtual"
                   [multiplo]="false"
                   [classObrigatoria]="classObrigatoria"></app-form-cartorio>
<ng-container *ngIf="temCobertura">


  <form [formGroup]="camposForm">
    <div class="col-md-12" *ngIf="this.itemAtual?.idFormSubtipo">
      <div style="height:50px" *ngIf="isLoading"><mat-spinner [diameter]="35" style="float:left"></mat-spinner>carregando finalidades...</div>
      <mat-form-field class="w-100" *ngIf="!isLoading">
        <mat-label>Finalidade:</mat-label>
        <mat-select formControlName="finalidade" [(ngModel)]="finAtu" [compareWith]="compareFinalidade">
          <mat-option *ngFor="let item of finalidadeCertidao"
                      [value]="item">{{item.descricao}}</mat-option>
        </mat-select>
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>

    <div class="row">
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo" *ngIf="!this.itemAtual?.idFormSubtipo">
          <mat-label>Número da Matricula</mat-label>
          <input matInput type="text" class="col-lg-12"
                 formControlName="matricula"
                 placeholder="Numero da Matricula" maxlength="8">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo" *ngIf="this.itemAtual?.idFormSubtipo">
          <mat-label>Inscrição IPTU</mat-label>
          <input matInput type="text" class="col-lg-12"
                 formControlName="matricula"
                 placeholder="Inscrição IPTU" maxlength="8" mask="00000009">
          <mat-error *ngIf="camposForm.get(['matricula'])?.errors?.['required']">
            Preenchimento obrigatório
          </mat-error>
          <mat-error *ngIf="camposForm.get(['matricula'])?.errors?.['iptuInvalido']">
            Iptu inválido
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">

        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>CEP</mat-label>
          <input matInput
                 type="text"
                 class="col-lg-12"
                 mask="00000-000||''"
                 formControlName="cep"
                 placeholder="CEP"
                 maxlength="10"
                 (blur)="buscarEndereco($event)">
        </mat-form-field>
        <label class="red" *ngIf="cepInvalido && !cepNaoCorresponde">
          O CEP informado está inválido
        </label>
        <label class="red" *ngIf="cepInvalido && cepNaoCorresponde">
          {{ cepNaoCorresponde }}
        </label>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Bairro</mat-label>
          <input matInput type="text"
                 formControlName="bairro"
                 placeholder="Bairro"
                 maxlength="50">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">

      <div class="col-md-3">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Tipo de logradouro</mat-label>
          <mat-select formControlName="tipoLogradouro">
            <mat-option value="ALAMEDA">ALAMEDA</mat-option>
            <mat-option value="AVENIDA">AVENIDA</mat-option>
            <mat-option value="BECO">BECO</mat-option>
            <mat-option value="CAMPO">CAMPO</mat-option>
            <mat-option value="ESTRADA">ESTRADA</mat-option>
            <mat-option value="ILHA">ILHA</mat-option>
            <mat-option value="LADEIRA">LADEIRA</mat-option>
            <mat-option value="LARGO">LARGO</mat-option>
            <mat-option value="PRAÇA">PRAÇA</mat-option>
            <mat-option value="RUA">RUA</mat-option>
            <mat-option value="SERVIDAO">SERVIDAO</mat-option>
            <mat-option value="TRAVESSA">TRAVESSA</mat-option>
            <mat-option value="VIA">VIA</mat-option>
            <mat-option value="VILA">VILA</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Logradouro</mat-label>
          <input matInput type="text"
                 formControlName="logradouro"
                 placeholder="Logradouro"
                 maxlength="255">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label class="bold">Número</mat-label>
          <input matInput type="text"
                 formControlName="numero"
                 placeholder="Número"
                 maxlength="10">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>


    </div>

    <div class="content-list" formArrayName="complemento">
      <div class="row">
        <div *ngFor="let item of complemento.controls; let i = index; let last = last;" [formGroupName]="i" class="row">
          <div class="col-md-3" style="display: flex; justify-content: center; align-items: center;">
            <mat-label>Complemento</mat-label>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Tipo</mat-label>
              <mat-select formControlName="tipo">
                <mat-option value="ALA">ALA</mat-option>
                <mat-option value="ANDAR">ANDAR</mat-option>
                <mat-option value="ANEXO">ANEXO</mat-option>
                <mat-option value="APARTAMENTO">APARTAMENTO</mat-option>
                <mat-option value="AREA">AREA</mat-option>
                <mat-option value="BLOCO">BLOCO</mat-option>
                <mat-option value="BOX">BOX</mat-option>
                <mat-option value="CASA">CASA</mat-option>
                <mat-option value="COBERTURA">COBERTURA</mat-option>
                <mat-option value="COBERTURA">COBERTURA</mat-option>
                <mat-option value="CONDOMÍNIO">CONDOMÍNIO</mat-option>
                <mat-option value="CONJUNTO">CONJUNTO</mat-option>
                <mat-option value="ENTRADA">ENTRADA</mat-option>
                <mat-option value="ESTAÇÃO">ESTAÇÃO</mat-option>
                <mat-option value="FAVELA">FAVELA</mat-option>
                <mat-option value="FAZENDA">FAZENDA</mat-option>
                <mat-option value="FORTALEZA">FORTALEZA</mat-option>
                <mat-option value="FRENTE">FRENTE</mat-option>
                <mat-option value="FUNDOS">FUNDOS</mat-option>
                <mat-option value="GALERIA">GALERIA</mat-option>
                <mat-option value="GALPÃO">GALPÃO</mat-option>
                <mat-option value="GLEBA">GLEBA</mat-option>
                <mat-option value="GRUPO">GRUPO</mat-option>
                <mat-option value="ILHA">ILHA</mat-option>
                <mat-option value="JUNTO">JUNTO</mat-option>
                <mat-option value="LOJA">LOJA</mat-option>
                <mat-option value="LOJAS">LOJAS</mat-option>
                <mat-option value="LOTE">LOTE</mat-option>
                <mat-option value="LOTEAMENTO">LOTEAMENTO</mat-option>
                <mat-option value="MERCADO">MERCADO</mat-option>
                <mat-option value="NOME DO PROPRIETÁRIO">NOME DO PROPRIETÁRIO</mat-option>
                <mat-option value="PA">PA</mat-option>
                <mat-option value="PAL">PAL</mat-option>
                <mat-option value="PARTE">PARTE</mat-option>
                <mat-option value="PORTAO">PORTAO</mat-option>
                <mat-option value="PORTARIA">PORTARIA</mat-option>
                <mat-option value="PROPRIETÁRIO">PROPRIETÁRIO</mat-option>
                <mat-option value="PROXIMO">PROXIMO</mat-option>
                <mat-option value="QUADRA">QUADRA</mat-option>
                <mat-option value="QUILOMETRO">QUILOMETRO</mat-option>
                <mat-option value="QUINTA">QUINTA</mat-option>
                <mat-option value="S/LJ">S/LJ</mat-option>
                <mat-option value="SALA">SALA</mat-option>
                <mat-option value="SALAS">SALAS</mat-option>
                <mat-option value="SHOPPING">SHOPPING</mat-option>
                <mat-option value="SITIO">SITIO</mat-option>
                <mat-option value="SOBRADO">SOBRADO</mat-option>
                <mat-option value="SOBRADO">SOBRADO</mat-option>
                <mat-option value="SOBRELOJA">SOBRELOJA</mat-option>
                <mat-option value="SUBSOLO">SUBSOLO</mat-option>
                <mat-option value="TERRENO">TERRENO</mat-option>
                <mat-option value="TERREO">TERREO</mat-option>
                <mat-option value="TORRE">TORRE</mat-option>
                <mat-option value="VAGA">VAGA</mat-option>
                <mat-option value="VILA">VILA</mat-option>

              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label class="bold">Letra/Número</mat-label>
              <input matInput type="text" formControlName="valor"
                     placeholder="Letra/Número"
                     maxlength="40">
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3" style="min-height: 94px;" *ngIf="(!last && i >0) ||(last && i>0)">
            <button class="w-100 my-2 add-new-name" (click)="remComplemento(i)">Remover Complemento</button>
          </div>
          <div class="col-md-9 " *ngIf="last && i >0 ">
          </div>
          <div class="col-md-3 " *ngIf="last && i < 1">
            <button id="addNovoComplemento" class="w-100 my-2 add-new-name" (click)="addComplemento()">Adicionar Novo Complemento</button>
          </div>
        </div>
      </div>

    </div>
  </form> 
  <app-aviso #aviso [aviso]="itemAtual?.aviso" [classObrigatoriaAviso]="classObrigatoriaAviso" (temAceite)="temAceite($event)"></app-aviso>

  <div class="row">
    <div class="col-md-5"></div>
    <div class="col-auto" *ngIf="!edicao && !iskit">
      <button type="button" mat-button [routerLink]="['/']" class="btn-next" style="color: white; background-color: green;">Voltar</button>
    </div>
    <div class="col-auto" *ngIf="edicao">
      <button type="button" mat-button (click)="cancelarEdicaoForm()" class="btn-next" style="color: white; background-color: green;">Cancelar</button>
    </div>
    <div class="col-auto">
      <button mat-button (click)="updatePedido()" type="button" class="btn-next"
              style="display: block; margin: 0 auto;">
        <span *ngIf="!edicao">Continuar</span>
        <span *ngIf="edicao">Salvar</span>
      </button>
    </div>
  </div>
</ng-container>


<div id="myNavForm1" class="overlay-loading" *ngIf="iskit && itemAtualKit?.opcional">


  <div class="overlay-content-loading">

    <div class="login-logo">
    </div>

    <div class="cssload-container">
      <h1>A próxima etapa desse kit é opcional. Deseja solicitá-la?</h1>
      <p>{{itemAtual?.titulo}}</p>

    </div>
    <div class="sk-spinner sk-spinner-wave" style="text-align: center;color: #fff;font-size: 20px;">
      <button mat-button (click)="pularEtapa()" type="button" class="btn-next"
              style="display: block; margin: 0 auto; float: left; margin-right: 20px !important; position: absolute;
         left: 40%; transform: translate(-50%, -50%); background-color: #9999993b; ">
        Não
      </button>
      <button mat-button (click)="continuar()" type="button" class="btn-next"
              style="display: block; margin: 0 auto; float: left; position: absolute; left: 60%; transform: translate(-50%, -50%); ">
        Sim
      </button>
    </div>
  </div>

</div>
