<div class="prot-novo-geral">
  <div class="prot-novo-conteudo">
    <div class="prot-veri">
      <div class="prot-veri-conteudo">
        <div class="prot-veri-chamada" [ngClass]="!concluido ? 'col-lg-6' : 'col-lg-12'">

          <h2>{{conteudo.h2}}</h2>
          <p *ngIf="!concluido">{{conteudo.subTitulo}}</p>
          <p class="text-success" *ngIf="concluido">
            <mat-icon class='check-circle-ico' fontIcon="check_circle"></mat-icon>
            <span>{{conteudo.msgSucesso}}</span>
          </p>
          <button class="new-request" *ngIf="concluido" (click)="novaSolicitacao()">Nova Solicitação</button>

        </div>

        <div class="prot-veri-campos" [ngClass]="!concluido ? 'col-lg-6' : 'col-lg-12'" *ngIf="!concluido">
          <form class="" (ngSubmit)="consultar()" [formGroup]="formBusca">

            <div>
              <mat-form-field appearance="outline" class="w-100 my-2 wrapper-input">
                <mat-label>CPF ou CNPJ</mat-label>
                <input matInput type="text" class="col-lg-6" formControlName="codigo" mask="000.000.000-00||00.000.000/0000-00" placeholder="CPF ou CNPJ">
                <mat-error *ngIf="f.codigo.errors?.['required']">
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <br>
            <div class="d-flex flex-wrap flex-lg-nowrap align-items-center">
              <mat-form-field appearance="outline" class="w-100 my-2 wrapper-input">

                <mat-label>Email</mat-label>
                <input matInput type="text" class="col-lg-6 email" formControlName="email" placeholder="Email">
                <mat-error *ngIf="f.email.errors?.['required']">
                  Preenchimento obrigatório
                </mat-error>
                <mat-error *ngIf="f.email.errors?.['pattern']">
                  E-mail inválido
                </mat-error>
              </mat-form-field>
              <button class="verify-protest">Verificar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
