<div class="image-container"  *ngIf="conteudo?.showFull">
  <div class="bg-image" style="background: url('/assets/moveis.png'); background-size: cover; min-height: 32vh;">
    <div class="overlay"></div>
    <div class="text-content">
      <h1 class="text-center fs-1 fw-bold text-white">Registre suas doações facilmente</h1>
      <p class="text-center text-white">
        Facilite o processo de doação com nossa plataforma intuitiva que ajuda a organizar
        e registrar suas contribuições de forma eficaz.
      </p>
    </div>
  </div>
</div>

  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h1 *ngIf="!conteudo?.showFull">Registre suas doações facilmente</h1>
          <h2 *ngIf="conteudo?.showFull">Apoio a pequenos cartórios</h2>
          <p>
            Na era digital, um registro eficiente é crucial para o sucesso de pequenos cartórios. Por isso, estamos comprometidos em fornecer suporte através de doações de móveis e equipamentos usados, que possibilitam a modernização e a melhoria nos serviços oferecidos. Cada item doado não apenas fornece o suporte necessário, mas também ajuda a construir um ambiente de trabalho mais produtivo e acolhedor. Sua contribuição é a chave para que esses cartórios possam continuar servindo as comunidades de forma eficaz!
          </p>
          <button *ngIf="!conteudo?.showFull" routerLink="/doacoes" class="text-md-start text-lg-start text-xl-start btn-primary" style="display: inline-flex; height: 80px; align-items: center; flex-shrink: 0; border-radius: 5px; background: rgb(245, 250, 255); box-shadow: rgba(0, 0, 0, 0.04) 0px 2.667px 0px 0px; padding: 21px 89px 21px 70.333px;">
            <span _ngcontent-ng-c493740300="" style="color: #fff; text-align: center; text-shadow: 0px -1.333px 0px rgba(0, 0, 0, 0.12); font-family: var(--fonte-padrao); font-size: 30px; font-style: normal; font-weight: 400; line-height: 37.333px;">
              Saiba mais
            </span>
          </button>
          <button *ngIf="conteudo?.showFull" class="text-md-start text-lg-start text-xl-start btn-primary" style="display: inline-flex; height: 80px; align-items: center; flex-shrink: 0; border-radius: 5px; background: rgb(245, 250, 255); box-shadow: rgba(0, 0, 0, 0.04) 0px 2.667px 0px 0px; padding: 21px 89px 21px 70.333px;">
            <span _ngcontent-ng-c493740300="" style="color: #fff; text-align: center; text-shadow: 0px -1.333px 0px rgba(0, 0, 0, 0.12); font-family: var(--fonte-padrao); font-size: 30px; font-style: normal; font-weight: 400; line-height: 37.333px;">
              Cadastrar doação
            </span>
          </button>
          
        </div>
        <div class="col-md-6">
          <img src="/assets/doacao1.png"
               style="width: 75%;"
               class="img-border item-rigth">
        </div>
      </div>
    </div>
  </section>
  <ng-container *ngIf="conteudo?.showFull">

    <section class="donation-options">
      <div class="container">
        <h2 class="section-title">Contribua com itens para <span class="highlight">Fazer a Diferença</span></h2>
        <p class="section-subtitle">Através de suas doações, você pode fornecer itens essenciais que melhorarão o ambiente de trabalho de pequenos cartórios. Móveis que estão em bom estado e prontos para serem reutilizados podem fazer uma enorme diferença na forma como esses estabelecimentos operam. Sua generosidade não apenas apoia a infraestrutura, mas cria um impacto direto na qualidade do atendimento prestado à comunidade.</p>
        <div class="row row-cols-2 g-4">
          <!-- Two columns, gap of 4 units -->
          <div class="col">
            <div class="card mb-4">
              <img src="/assets/doacao2.png" class="card-img-top" alt="Mesa de escritório">
              <div class="card-body">
                <h5 class="card-title">Apoio a cartórios</h5>
                <p class="card-text">
                  <b>Transforme o ambiente de trabalho de pequenos cartórios com suas contribuições!</b>
                  Sua generosidade em doar mesas e cadeiras em bom estado pode fazer uma enorme diferença, proporcionando condições adequadas para o atendimento e a eficiência dos serviços. Juntos, podemos oferecer um suporte valioso e tornar o dia a dia desses profissionais mais acolhedor e produtivo.
                </p>

              </div>
            </div>
          </div>
          <div class="col">
            <div class="card mb-4">
              <img src="/assets/doacao3.png" class="card-img-top" alt="Sala de espera">
              <div class="card-body">
                <h5 class="card-title">Doe computadores</h5>
                <p class="card-text">
                  <b>
                    Capacite pequenos negócios com suas doações!
                  </b>
                  Ao contribuir com computadores usados e funcionais, você estará proporcionando ferramentas essenciais que podem impulsionar o crescimento e a eficiência dessas empresas. Sua generosidade não apenas ajuda na modernização dos serviços, mas também permite que esses negócios atendam melhor a comunidade e se destaquem no mercado.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card mb-4">
              <img src="/assets/doacao4.png" class="card-img-top" alt="Ambiente de escritório">
              <div class="card-body">
                <h5 class="card-title">Ambiente de Escritório</h5>
                <p class="card-text">
                  <b>
                    Contribua para a melhoria dos espaços de trabalho!
                  </b>
                  Com a doação de móveis simples e funcionais, você pode transformar pequenos escritórios em ambientes mais agradáveis e produtivos. Um espaço bem equipado não só melhora a eficiência do trabalho, mas também eleva o moral da equipe. Suas doações ajudam a criar um ambiente acolhedor, onde a criatividade e a colaboração podem florescer. Juntos, podemos fazer a diferença no dia a dia desses profissionais!
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card mb-4">
              <img src="/assets/doacao5.png" class="card-img-top" alt="Ambiente de escritório">
              <div class="card-body">
                <h5 class="card-title">Doe Agora!</h5>
                <p class="card-text">
                  <b>
                    Junte-se a nós e faça a diferença!
                  </b>
                  Participe da nossa campanha de doações voltada para pequenos cartórios e ajude a fortalecer essas instituições essenciais. Sua contribuição, seja de móveis, equipamentos ou outros itens úteis, pode transformar a maneira como eles operam e atendem à comunidade. Cada gesto conta, e juntos podemos criar um impacto significativo. Não perca a oportunidade de contribuir para um futuro melhor!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="junte-se-secao">
      <div class="row ">
        <svg class="svg-midle" fill="none" viewBox="0 0 404 392" data-v-3612f197=""><defs data-v-3612f197=""><pattern id="8228f071-bcee-4ec8-905a-2a059a2cc4fb" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse" data-v-3612f197=""><rect x="0" y="0" width="4" height="4" class="text-primary/10 dark:text-primary/30" fill="currentColor" data-v-3612f197=""></rect></pattern></defs><rect width="404" height="392" fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" data-v-3612f197=""></rect></svg>
        <div class="col-12">

          <div class="divrevolucao">

            <h1>Faça a Diferença - Participe das nossas iniciativas de doação!</h1>
            <p>Nossa plataforma não é apenas uma ferramenta, é um movimento para transformar a forma como as pessoas contribuem para causas sociais. Com recursos inovadores, nossa plataforma facilita o registro e a organização de doações, permitindo que você faça a diferença com apenas alguns cliques. Não é à toa que Mila Hill destaca a eficiência e a relevância do nosso trabalho. Ao se juntar a nós, você não apenas organiza suas contribuições, mas também se torna parte de uma comunidade que valoriza o impacto social e a solidariedade.</p>
            <button class="btn-doacao">Agendar Doação</button>

          </div>

        </div>
        <svg style="width:80%" class="svg-box" preserveAspectRatio="xMidYMid slice"
             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1463 360" data-v-3612f197="">
          <path class="text-primary/5 opacity-50" fill="currentColor" d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z" data-v-3612f197=""></path>
          <path class="text-primary/10 opacity-60" fill="currentColor" d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z" data-v-3612f197=""></path>
        </svg>

      </div>

    </section>
  </ng-container>
