import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-meusPedidosOrderDetails-21',
  templateUrl: './meusPedidosOrderDetail21.component.html',
  styleUrls: ['./../_meus-pedidos.component.scss']
})
export class MeusPedidosOrderDetails21Component  {
  @Input() compra: any

  public visibilidadeDetalhes: boolean=false;
  
  @Output() updatePedido = new EventEmitter<any>();

  cancelarEdicao(compra:any) {
    compra.editar = false;
  }

  salvarItem(itemPedido: any) {
    this.updatePedido.emit(itemPedido)
  }
}
