import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class TesteService {

  n: any;
  n1: any
  n2: any
  n3: any
  n4: any
  n5: any
  n6: any
  n7: any
  n8: any
  n9: any
  d1: any
  d2: any
  ret: any
  _nm: any
  _cl: any
  _cnpj: any
  _NumeroAleatorio: any
  _tl: any
  _em: any
  _senhaPadrao: any = '123456'
  _dataTeste: any = new Date(getRandomArbitrary(1990, 2010), 4, 10)
  _end: any =
    {
      cep: '04536-000',
      logradouro: 'Av. Cidade Jardim',
      numero: '427',
      cidade: 'São Paulo',
      bairro: 'Jardim Paulistano',
      uf: 'SP',
      complemento: '',
      pais: 'Brasil'

    }

  _end2: any =
    {
      cep: '04536-000',
      logradouro: 'Av. Cidade Jardim',
      numero: '318',
      cidade: 'São Paulo',
      bairro: 'Jardim Paulistano',
      uf: 'SP',
      complemento: '2º andar - Sala 21',
      pais: 'Brasil'
    }

  constructor(
  ) { }

  gerarValores() {
    this.n = 9;
    this.n1 = gera_random(this.n);
    this.n2 = gera_random(this.n);
    this.n3 = gera_random(this.n);
    this.n4 = gera_random(this.n);
    this.n5 = gera_random(this.n);
    this.n6 = gera_random(this.n);
    this.n7 = gera_random(this.n);
    this.n8 = gera_random(this.n);
    this.n9 = gera_random(this.n);
    this.d1 = this.n9 * 2 + this.n8 * 3 + this.n7 * 4 + this.n6 * 5 + this.n5 * 6 + this.n4 * 7 + this.n3 * 8 + this.n2 * 9 + this.n1 * 10;
    this.d1 = 11 - (mod(this.d1, 11));
    if (this.d1 >= 10) this.d1 = 0;
    this.d2 = this.d1 * 2 + this.n9 * 3 + this.n8 * 4 + this.n7 * 5 + this.n6 * 6 + this.n5 * 7 + this.n4 * 8 + this.n3 * 9 + this.n2 * 10 + this.n1 * 11;
    this.d2 = 11 - (mod(this.d2, 11));
    if (this.d2 >= 10) this.d2 = 0;
    this.ret = '' + this.n1 + this.n2 + this.n3 + '' + this.n4 + this.n5 + this.n6 + '' + this.n7 + this.n8 + this.n9 + '' + this.d1 + this.d2;
    this._nm = 'TESTE ' + this.n1 + this.n2 + this.n3 + this.n4 + this.n5 + this.n6;
    this._cl = '' + this.n1 + this.n2 + '' + this.n3 + this.n4 + this.n5 + this.n6 + this.n7 + '' + this.n8 + this.n9 + this.d1 + this.d2;
    this._NumeroAleatorio = this.n3 + '' + this.n4 + '' + this.n5 + '' + this.n6 + '' + this.n7;
    this._tl = '' + this.n1 + this.n2 + '' + this.n4 + this.n5 + this.n6 + this.n7 + '' + this.n8 + this.n9 + this.d1 + this.d2;
    //this._cl = '(11) 9 8807-6875';
    this._em = 'testemail' + this.n1 + this.n2 + this.n3 + this.n4 + this.n5 + this.n6 + '@teste.com.br';
    this._dataTeste = new Date(getRandomArbitrary(1990, 2010), getRandomArbitrary(1, 12), getRandomArbitrary(1, 27))
    this._cnpj = this.cnpj()
  }

  gera_random(n: any) {
    var ranNum = Math.round(Math.random() * n);
    return ranNum;
  }

  gera_aleatoryNumber() {

    var ranNum = Math.round(Math.random() * 8);
    return Math.round(Math.random() * 8) + '' + Math.round(Math.random() * 8) + '' + Math.round(Math.random() * 8) + '' + Math.round(Math.random() * 8) + '' + Math.round(Math.random() * 8);

  }

  mod(dividendo: any, divisor: any) {
    return Math.round(dividendo - (Math.floor(dividendo / divisor) * divisor));
  }

  cnpj() {
    var n = 9;
    var n1 = gera_random(n);
    var n2 = gera_random(n);
    var n3 = gera_random(n);
    var n4 = gera_random(n);
    var n5 = gera_random(n);
    var n6 = gera_random(n);
    var n7 = gera_random(n);
    var n8 = gera_random(n);
    var n9 = 0;//gera_random(n);
    var n10 = 0;//gera_random(n);
    var n11 = 0;//gera_random(n);
    var n12 = 1;//gera_random(n);
    var d1 = n12 * 2 + n11 * 3 + n10 * 4 + n9 * 5 + n8 * 6 + n7 * 7 + n6 * 8 + n5 * 9 + n4 * 2 + n3 * 3 + n2 * 4 + n1 * 5;
    d1 = 11 - (mod(d1, 11));
    if (d1 >= 10) d1 = 0;
    var d2 = d1 * 2 + n12 * 3 + n11 * 4 + n10 * 5 + n9 * 6 + n8 * 7 + n7 * 8 + n6 * 9 + n5 * 2 + n4 * 3 + n3 * 4 + n2 * 5 + n1 * 6;
    d2 = 11 - (mod(d2, 11));
    if (d2 >= 10) d2 = 0;
    let resultado = '' + n1 + n2 + '' + n3 + n4 + n5 + '' + n6 + n7 + n8 + '' + n9 + n10 + n11 + n12 + '' + d1 + d2;
    return resultado
  }

  setValueForm(form: FormGroup) {

    // nomeTitular: ['', Validators.required],
    //tipoDocumento: ['', Validators.required],
    //numeroDocumento: ['', Validators.required],
    //validadeMes: ['', Validators.required],
    //validadeAno: ['', Validators.required],
    //cvc: ['', Validators.required],
    //numeroCartao: ['', Validators.required],
    //qtdParcelas: ['', Validators.required],
    //opcoesParcelamento: ['', Validators.required],
    //idEmissor:['']

    this.gerarValores()
    //form.reset()
    var cvv = this.n1 + '' + this.n2 + '' + this.n3;
    console.log(form)
    if (form.get('tipoPolo') != null && (form.get('tipoPolo')?.value == null || form.get('tipoPolo')?.value == '')) {
      form.get('tipoPolo')?.setValue('Solicitante');
    }

    if (form.get('tipoPessoa') != null && (form.get('tipoPessoa')?.value == null || form.get('tipoPessoa')?.value == '')) {
      form.get('tipoPessoa')?.setValue('Física');
    }
    if (form.get('relatoFatos') != null && (form.get('relatoFatos')?.value == null || form.get('relatoFatos')?.value == '')) {
      form.get('relatoFatos')?.setValue(this._nm);
    }
    if (form.get('competencia') != null && (form.get('competencia')?.value == null || form.get('competencia')?.value == '')) {
      form.get('competencia')?.setValue('Cível');
    }
    if (form.get('nomeSocial') != null && (form.get('nomeSocial')?.value == null || form.get('nomeSocial')?.value == '')) {
      form.get('nomeSocial')?.setValue(this._nm);
    }
    if (form.get('complemento') != null && (form.get('complemento')?.value == null || form.get('complemento')?.value == '')) {
      form.get('complemento')?.setValue('12 a');
    }
    if (form.get('pedidoSucinto') != null && (form.get('pedidoSucinto')?.value == null || form.get('pedidoSucinto')?.value == '')) {
      form.get('pedidoSucinto')?.setValue(this._nm);
    }
    if (form.get('valorSugerido') != null && (form.get('valorSugerido')?.value == null || form.get('valorSugerido')?.value == '')) {
      form.get('valorSugerido')?.setValue(10000);
    }

    if (form.get('genero') != null && (form.get('genero')?.value == null || form.get('genero')?.value == '')) {
      form.get('genero')?.setValue('Masculino');
    }
    if (form.get('possuiAdvogado') != null && (form.get('possuiAdvogado')?.value == null || form.get('possuiAdvogado')?.value == '')) {
      form.get('possuiAdvogado')?.setValue('Sim');
    }
    if (form.get('nomeTitular') != null && (form.get('nomeTitular')?.value == null || form.get('nomeTitular')?.value == '')) {
      form.get('nomeTitular')?.setValue(this._nm);
    }
    if (form.get('tipoDocumento') != null && (form.get('tipoDocumento')?.value == null || form.get('tipoDocumento')?.value == '')) {
      form.get('tipoDocumento')?.setValue('CPF');
    }
    if (form.get('numeroDocumento') != null && (form.get('numeroDocumento')?.value == null || form.get('numeroDocumento')?.value == '')) {
      form.get('numeroDocumento')?.setValue(this.ret);
    }
    if (form.get('validadeMes') != null && (form.get('validadeMes')?.value == null || form.get('validadeMes')?.value == '')) {
      form.get('validadeMes')?.setValue('04');
    }
    if (form.get('validadeAno') != null && (form.get('validadeAno')?.value == null || form.get('validadeAno')?.value == '')) {
      form.get('validadeAno')?.setValue('25');
    }
    if (form.get('cvc') != null && (form.get('cvc')?.value == null || form.get('cvc')?.value == '')) {
      form.get('cvc')?.setValue('782');
    }
    if (form.get('numeroCartao') != null && (form.get('numeroCartao')?.value == null || form.get('numeroCartao')?.value == '')) {
      form.get('numeroCartao')?.setValue('4673780730715138');
    }
    if (form.get('qtdParcelas') != null && (form.get('qtdParcelas')?.value == null || form.get('qtdParcelas')?.value == '')) {
      form.get('qtdParcelas')?.setValue(1);
    }

    if (form.get('conjuge') != null && (form.get('conjuge')?.value == null || form.get('conjuge')?.value == '')) {
      form.get('conjuge')?.setValue(this._nm);
    }
    if (form.get('nomeDestinatario') != null && (form.get('nomeDestinatario')?.value == null || form.get('nomeDestinatario')?.value == '')) {
      form.get('nomeDestinatario')?.setValue(this._nm);
    }
    if (form.get('conjuge2') != null && (form.get('conjuge2')?.value == null || form.get('conjuge2')?.value == '')) {
      form.get('conjuge2')?.setValue(this._nm);
    }

    if (form.get('nome') != null && (form.get('nome')?.value == null || form.get('nome')?.value == '')) {
      form.get('nome')?.setValue(this._nm);
    }

    if (form.get('especie') != null && (form.get('especie')?.value == null || form.get('especie')?.value == '')) {
      form.get('especie')?.setValue('Cachorro');
    }
    if (form.get('raca') != null && (form.get('raca')?.value == null || form.get('raca')?.value == '')) {
      form.get('raca')?.setValue('Pitbull');
    }
    if (form.get('sexo') != null && (form.get('sexo')?.value == null || form.get('sexo')?.value == '')) {
      form.get('sexo')?.setValue('Macho');
    }
    if (form.get('pelagem') != null && (form.get('pelagem')?.value == null || form.get('pelagem')?.value == '')) {
      form.get('pelagem')?.setValue('Curta');
    }
    if (form.get('dataNascimento') != null && (form.get('dataNascimento')?.value == null || form.get('dataNascimento')?.value == '')) {
      form.get('dataNascimento')?.setValue(this._dataTeste);
    }
    if (form.get('idade') != null && (form.get('idade')?.value == null || form.get('idade')?.value == '')) {
      form.get('idade')?.setValue('3 anos');
    }
    if (form.get('caracteristicas') != null && (form.get('caracteristicas')?.value == null || form.get('caracteristicas')?.value == '')) {
      form.get('caracteristicas')?.setValue('Fica Bravo ao mexerem');
    }
    if (form.get('microchip') != null && (form.get('microchip')?.value == null || form.get('microchip')?.value == '')) {
      form.get('microchip')?.setValue(this.gera_aleatoryNumber());
    }

    //if (form.get('endereco') != null && (form.get('endereco')?.value == null || form.get('endereco')?.value == '')) {
    //  form.get('endereco')?.setValue(this._end2);
    //}

    if (form.get('tipoLogradouro') != null && (form.get('tipoLogradouro')?.value == null || form.get('tipoLogradouro')?.value == '')) {
      form.get('tipoLogradouro')?.setValue('AVENIDA');
    }

    if (form.get('matricula') != null && (form.get('matricula')?.value == null || form.get('matricula')?.value == '')) {
      form.get('matricula')?.setValue('04999611');
    }

    if (form.get('protocolo') != null && (form.get('protocolo')?.value == null || form.get('protocolo')?.value == '')) {
      form.get('protocolo')?.setValue(this._NumeroAleatorio);
    }

    if (form.get('numeroAto') != null && (form.get('numeroAto')?.value == null || form.get('numeroAto')?.value == '')) {
      form.get('numeroAto')?.setValue(this.gera_aleatoryNumber());
    }

    if (form.get('registro') != null && (form.get('registro')?.value == null || form.get('registro')?.value == '')) {
      form.get('registro')?.setValue(this.gera_aleatoryNumber());
    }

    if (form.get('placa') != null && (form.get('placa')?.value == null || form.get('placa')?.value == '')) {
      form.get('placa')?.setValue(this.gera_aleatoryNumber());
    }

    if (form.get('renavam') != null && (form.get('renavam')?.value == null || form.get('renavam')?.value == '')) {
      form.get('renavam')?.setValue(this.gera_aleatoryNumber());
    }

    if (form.get('inscricaoImobiliaria') != null && (form.get('inscricaoImobiliaria')?.value == null || form.get('inscricaoImobiliaria')?.value == '')) {
      form.get('inscricaoImobiliaria')?.setValue(this.gera_aleatoryNumber());
    }

    if (form.get('sql') != null && (form.get('sql')?.value == null || form.get('sql')?.value == '')) {
      form.get('sql')?.setValue(this.gera_aleatoryNumber());
    }

    if (form.get('rg') != null && (form.get('rg')?.value == null || form.get('rg')?.value == '')) {
      form.get('rg')?.setValue(this.gera_aleatoryNumber());
    }

    if (form.get('anoExercicio') != null && (form.get('anoExercicio')?.value == null || form.get('anoExercicio')?.value == '')) {
      form.get('anoExercicio')?.setValue(this.gera_aleatoryNumber());
    }

    if (form.get('senha') != null && (form.get('senha')?.value == null || form.get('senha')?.value == '')) {
      form.get('senha')?.setValue(this.gera_aleatoryNumber());
    }

    if (form.get('numero') != null && (form.get('numero')?.value == null || form.get('numero')?.value == '')) {
      form.get('numero')?.setValue(this._NumeroAleatorio);
    }
    //registro civil
    if (form.get('tipAto') != null && (form.get('tipAto')?.value == null || form.get('tipAto')?.value == '')) {
      //console.log(form.get('tipAto'))
      form.get('tipAto')?.setValue([{ "Id": 'Nascimento', "Descricao": "Batismo/Nascimento" }]);
    }

    if (form.get('finalidade') != null && (form.get('finalidade')?.value == null || form.get('finalidade')?.value == '')) {
      //console.log(form.get('finalidade'))
      form.get('finalidade')?.setValue([{ "Id": '19', "Descricao": "EXTRAJUDICIAL" }]);
    }

    if (form.get('conjuge') != null && (form.get('conjuge')?.value == null || form.get('conjuge')?.value == '')) {
      form.get('conjuge')?.setValue(this._nm);
    }

    if (form.get('termo') != null && (form.get('termo')?.value == null || form.get('termo')?.value == '')) {
      form.get('termo')?.setValue(this._nm);
    }

    if (form.get('cota') != null && (form.get('cota')?.value == null || form.get('cota')?.value == '')) {
      form.get('cota')?.setValue("cota 1");
    }

    if (form.get('pagina') != null && (form.get('pagina')?.value == null || form.get('pagina')?.value == '')) {
      form.get('pagina')?.setValue("12");
    }

    if (form.get('informacaoAdicional') != null && (form.get('informacaoAdicional')?.value == null || form.get('informacaoAdicional')?.value == '')) {
      form.get('informacaoAdicional')?.setValue("Não tenho certeza se eu nasci de verdade.");
    }
    if (form.get('enderecoCompleto') != null && (form.get('enderecoCompleto')?.value == null || form.get('enderecoCompleto')?.value == '')) {
      form.get('enderecoCompleto')?.setValue(this._end2.logradouro);
    }
    if (form.get('pais') != null && (form.get('pais')?.value == null || form.get('pais')?.value == '')) {
      form.get('pais')?.setValue(this._end2.pais);
    }

    if (form.get('nif') != null && (form.get('nif')?.value == null || form.get('nif')?.value == '')) {
      form.get('nif')?.setValue("9050603040");
    }

    if (form.get('envioCorreios') != null && (form.get('envioCorreios')?.value == null || form.get('envioCorreios')?.value == '')) {
      form.get('envioCorreios')?.setValue("false");
    }

    if (form.get('apostilamento') != null && (form.get('apostilamento')?.value == null || form.get('apostilamento')?.value == '')) {
      form.get('apostilamento')?.setValue("false");
    }

    if (form.get('pedidoPorTerceiro') != null && (form.get('pedidoPorTerceiro')?.value == null || form.get('pedidoPorTerceiro')?.value == '')) {
      form.get('pedidoPorTerceiro')?.setValue("false");
    }

    //pedido-ato
    if (form.get('ato') != null && (form.get('ato')?.value == null || form.get('ato')?.value == '')) {
      form.get('ato')?.setValue("Assinatura Eletrônica");
    }

    if (form.get('quantidade') != null && (form.get('quantidade')?.value == null || form.get('quantidade')?.value == '')) {
      form.get('quantidade')?.setValue("5");
    }

    if (form.get('precoUnitarioAto') != null && (form.get('precoUnitarioAto')?.value == null || form.get('precoUnitarioAto')?.value == '')) {
      form.get('precoUnitarioAto')?.setValue("R$ 5,00");
    }

    if (form.get('precoTotal') != null && (form.get('precoTotal')?.value == null || form.get('precoTotal')?.value == '')) {
      form.get('precoTotal')?.setValue("R$ 25,00");
    }
    //

    //console.log(form.get('nome')?.value)
    //if (form.get('possui_interveniente') != null && (form.get('possui_interveniente')?.value == null || form.get('possui_interveniente')?.value == '')) {
    //  form.get('possui_interveniente')?.setValue('possuiPrevidenciaNao');
    //}
    if (form.get('tipo') != null && (form.get('tipo')?.value == null || form.get('tipo')?.value == '')) {
      //console.log(form.get('tipo'))
      form.get('tipo')?.setValue({ "Id": 'I', "Descricao": "ICP BRASIL" });
    }

    if (form.get('nomeCompleto') != null && (form.get('nomeCompleto')?.value == null || form.get('nomeCompleto')?.value == '')) {
      form.get('nomeCompleto')?.setValue(this._nm);
    }
    if (form.get('nomeBusca') != null && (form.get('nomeBusca')?.value == null || form.get('nomeBusca')?.value == '')) {
      form.get('nomeBusca')?.setValue(this._nm);
    }
    if (form.get('observacao') != null && (form.get('observacao')?.value == null || form.get('observacao')?.value == '')) {
      form.get('observacao')?.setValue(this._nm);
    }
    if (form.get('natureza') != null && (form.get('natureza')?.value == null || form.get('natureza')?.value == '')) {
      form.get('natureza')?.setValue(this._nm);
    }
    if (form.get('livro') != null && (form.get('livro')?.value == null || form.get('livro')?.value == '')) {
      form.get('livro')?.setValue(this._nm);
    }
    if (form.get('folha') != null && (form.get('folha')?.value == null || form.get('folha')?.value == '')) {
      form.get('folha')?.setValue(this._nm);
    }
    if (form.get('dataAto') != null && (form.get('dataAto')?.value == null || form.get('dataAto')?.value == '')) {
      form.get('dataAto')?.setValue(this._dataTeste);
    }

    if (form.get('dataCasamento') != null && (form.get('dataCasamento')?.value == null || form.get('dataCasamento')?.value == '')) {
      form.get('dataCasamento')?.setValue(this._dataTeste);
    }

    if (form.get('dataObito') != null && (form.get('dataObito')?.value == null || form.get('dataObito')?.value == '')) {
      form.get('dataObito')?.setValue(this._dataTeste);
    }

    if (form.get('identidade') != null && (form.get('identidade')?.value == null || form.get('identidade')?.value == '')) {
      form.get('identidade')?.setValue(this._nm);
    }

    if (form.get('orgaoExpedidor') != null && (form.get('orgaoExpedidor')?.value == null || form.get('orgaoExpedidor')?.value == '')) {
      form.get('orgaoExpedidor')?.setValue(this._nm);
    }

    if (form.get('nome') != null && (form.get('nome')?.value == null || form.get('nome')?.value == '')) {
      form.get('nome')?.setValue(this._nm);
    }



    if (form.get('pai') != null && (form.get('pai')?.value == null || form.get('pai')?.value == '')) {
      form.get('pai')?.setValue(this._nm);
    }
    if (form.get('mae') != null && (form.get('mae')?.value == null || form.get('mae')?.value == '')) {
      form.get('mae')?.setValue(this._nm);
    }

    if (form.get('nomePai') != null && (form.get('nomePai')?.value == null || form.get('nomePai')?.value == '')) {
      form.get('nomePai')?.setValue(this._nm);
    }
    if (form.get('nomeMae') != null && (form.get('nomeMae')?.value == null || form.get('nomeMae')?.value == '')) {
      form.get('nomeMae')?.setValue(this._nm);
    }

    if (form.get('nascimento') != null && (form.get('nascimento')?.value == null || form.get('nascimento')?.value == '')) {
      form.get('nascimento')?.setValue(this._dataTeste);
    }

    if (form.get('dataExpedicao') != null && (form.get('dataExpedicao')?.value == null || form.get('dataExpedicao')?.value == '')) {
      form.get('dataExpedicao')?.setValue(this._dataTeste);
    }
    if (form.get('telefone') != null) {
      form.get('telefone')?.setValue(this._cl);
    }

    if (form.get('documento') != null && (form.get('documento')?.value == null || form.get('documento')?.value == ''))
    {
      form.get('documento')?.setValue(this.ret);

      if (form.get('tipoPessoa') != null)
      {
        form.get('tipoPessoa')?.setValue('fisica');
      }
    }

    if (form.get('cnpjComprador') != null && (form.get('cnpjComprador')?.value == null || form.get('cnpjComprador')?.value == '')) {
      form.get('cnpjComprador')?.setValue(this.ret);
    }
    if (form.get('cpf') != null && (form.get('cpf')?.value == null || form.get('cpf')?.value == '')) {
      form.get('cpf')?.setValue(this.ret);
    }
    if (form.get('cpfCnpj') != null && (form.get('cpfCnpj')?.value == null || form.get('cpfCnpj')?.value == '')) {
      form.get('cpfCnpj')?.setValue(this.ret);
    }
    if (form.get('Cnpj') != null && (form.get('Cnpj')?.value == null || form.get('Cnpj')?.value == '')) {
      form.get('Cnpj')?.setValue(this.ret);
    }
    if (form.get('cei') != null && (form.get('cei')?.value == null || form.get('cei')?.value == '')) {
      form.get('cei')?.setValue(this._NumeroAleatorio);
    }
    if (form.get('cnpj') != null && (form.get('cnpj')?.value == null || form.get('cnpj')?.value == '')) {
      form.get('cnpj')?.setValue(this._cnpj);
    }

    if (form.get('cpf1') != null && (form.get('cpf1')?.value == null || form.get('cpf1')?.value == '')) {
      form.get('cpf1')?.setValue(this.ret);
    }

    if (form.get('cpf2') != null && (form.get('cpf2')?.value == null || form.get('cpf2')?.value == '')) {
      form.get('cpf2')?.setValue(this.ret);
    }

    if (form.get('isWhatsapp') != null && (form.get('isWhatsapp')?.value == null || form.get('isWhatsapp')?.value == '')) {
      form.get('isWhatsapp')?.setValue('celWhatsappSim');
    }
    if (form.get('nationalidade') != null && (form.get('nationalidade')?.value == null || form.get('nationalidade')?.value == '')) {
      form.get('nationalidade')?.setValue('Brasileiro');
    }
    if (form.get('cnpjCpf') != null && (form.get('cnpjCpf')?.value == null || form.get('cnpjCpf')?.value == '')) {
      form.get('cnpjCpf')?.setValue(this.ret);
    }
    if (form.get('profissao') != null && (form.get('profissao')?.value == null || form.get('profissao')?.value == '')) {
      form.get('profissao')?.setValue('Comerciante');
    }
    if (form.get('email') != null && (form.get('email')?.value == null || form.get('email')?.value == '')) {
      form.get('email')?.setValue(this._em);
    }
    if (form.get('date_nasc') != null && (form.get('date_nasc')?.value == null || form.get('date_nasc')?.value == '')) {
      form.get('date_nasc')?.setValue(this._dataTeste);
    }
    if (form.get('tel_fixo') != null && (form.get('tel_fixo')?.value == null || form.get('tel_fixo')?.value == '')) {
      form.get('tel_fixo')?.setValue(this._tl);
    }
    if (form.get('tel_celular') != null && (form.get('tel_celular')?.value == null || form.get('tel_celular')?.value == '')) {
      form.get('tel_celular')?.setValue(this._cl);
    }
    if (form.get('celular') != null && (form.get('celular')?.value == null || form.get('celular')?.value == '')) {
      form.get('celular')?.setValue(this._cl);
    }

    if (form.get('cep') != null && (form.get('cep')?.value == null || form.get('cep')?.value == '')) {
      form.get('cep')?.setValue(this._end.cep);
    }
    if (form.get('cepImovel') != null && (form.get('cepImovel')?.value == null || form.get('cepImovel')?.value == '')) {
      form.get('cepImovel')?.setValue(this._end.cep);
    }
    if (form.get('fracaoImovel') != null && (form.get('fracaoImovel')?.value == null || form.get('fracaoImovel')?.value == '')) {
      form.get('fracaoImovel')?.setValue(100);
    }

    if (form.get('valorImovel') != null && (form.get('valorImovel')?.value == null || form.get('valorImovel')?.value == '')) {
      form.get('valorImovel')?.setValue(150654);
    }

    if (form.get('formaPagamentoImovel') != null && (form.get('formaPagamentoImovel')?.value == null || form.get('formaPagamentoImovel')?.value == '')) {
      form.get('formaPagamentoImovel')?.setValue('Á vista');
    }

    if (form.get('imovelRegistradoCartorio') != null && (form.get('imovelRegistradoCartorio')?.value == null || form.get('imovelRegistradoCartorio')?.value == '')) {
      form.get('imovelRegistradoCartorio')?.setValue('Sim');
    }
    if (form.get('imovelForeiro') != null && (form.get('imovelForeiro')?.value == null || form.get('imovelForeiro')?.value == '')) {
      form.get('imovelForeiro')?.setValue('Sim');
    }

    if (form.get('taxasLaudemios') != null && (form.get('taxasLaudemios')?.value == null || form.get('taxasLaudemios')?.value == '')) {
      form.get('taxasLaudemios')?.setValue('Sim');
    }

    if (form.get('nomeTabeliao') != null && (form.get('nomeTabeliao')?.value == null || form.get('nomeTabeliao')?.value == '')) {
      form.get('nomeTabeliao')?.setValue(this._nm);
    }

    if (form.get('onusGravames') != null && (form.get('onusGravames')?.value == null || form.get('onusGravames')?.value == '')) {
      form.get('onusGravames')?.setValue('Sim');
    }
    if (form.get('enderecoCartorio') != null && (form.get('enderecoCartorio')?.value == null || form.get('enderecoCartorio')?.value == '')) {
      form.get('enderecoCartorio')?.setValue(this._end.logradouro);
    }
    if (form.get('cartorioNome') != null && (form.get('cartorioNome')?.value == null || form.get('cartorioNome')?.value == '')) {
      form.get('cartorioNome')?.setValue('1º Ofício de Registro da Capital');
    }
    if (form.get('situacaoCondominio') != null && (form.get('situacaoCondominio')?.value == null || form.get('situacaoCondominio')?.value == '')) {
      form.get('situacaoCondominio')?.setValue('Sim');
    }
    if (form.get('impostosQuitados') != null && (form.get('impostosQuitados')?.value == null || form.get('impostosQuitados')?.value == '')) {
      form.get('impostosQuitados')?.setValue('Sim');
    }
    if (form.get('possui_interveniente') != null && (form.get('possui_interveniente')?.value == null || form.get('possui_interveniente')?.value == '')) {
      form.get('possui_interveniente')?.setValue('Nao');
    }
    if (form.get('estado_civil') != null && (form.get('estado_civil')?.value == null || form.get('estado_civil')?.value == '')) {
      form.get('estado_civil')?.setValue('solteiro');
    }
    if (form.get('tipoImovel') != null && (form.get('tipoImovel')?.value == null || form.get('tipoImovel')?.value == '')) {
      form.get('tipoImovel')?.setValue('Urbano');
    }
    if (form.get('logradouro') != null && (form.get('logradouro')?.value == null || form.get('logradouro')?.value == '')) {
      form.get('logradouro')?.setValue(this._end.logradouro);
    }
    if (form.get('num_logradouro') != null && (form.get('num_logradouro')?.value == null || form.get('num_logradouro')?.value == '')) {
      form.get('num_logradouro')?.setValue(this._end.numero);
    }
    if (form.get('bairro') != null && (form.get('bairro')?.value == null || form.get('bairro')?.value == '')) {
      form.get('bairro')?.setValue(this._end.bairro);
    }
    if (form.get('cidade') != null && (form.get('cidade')?.value == null || form.get('cidade')?.value == '')) {
      form.get('cidade')?.setValue(this._end.cidade);
    }
    if (form.get('estado') != null && (form.get('estado')?.value == null || form.get('estado')?.value == '')) {
      form.get('estado')?.setValue('São Paulo');
    }
    if (form.get('possui_procurador') != null && (form.get('possui_procurador')?.value == null || form.get('possui_procurador')?.value == '')) {
      form.get('possui_procurador')?.setValue('procuradorSim');
    }
    if (form.get('contribuinte_previdencia') != null && (form.get('contribuinte_previdencia')?.value == null || form.get('contribuinte_previdencia')?.value == '')) {
      form.get('contribuinte_previdencia')?.setValue('possuiPrevidenciaSim');
    }

    if (form.get('date_nasc') != null && (form.get('date_nasc')?.value == null || form.get('date_nasc')?.value == '')) {
      form.get('date_nasc')?.setValue(this._dataTeste);
    }

    if (form.get('inscricaoIPTU') != null && (form.get('date_nasc')?.value == null || form.get('inscricaoIPTU')?.value == '')) {
      form.get('inscricaoIPTU')?.setValue(this.d1);
    }
    if (form.get('inscricaoIPTU') != null && (form.get('date_nasc')?.value == null || form.get('inscricaoIPTU')?.value == '')) {
      form.get('inscricaoIPTU')?.setValue(this.d1);
    }

    //cep: ['',Validators.required],
    //logradouro: ['', Validators.required],
    //num_logradouro: ['', Validators.required],
    //bairro: ['', Validators.required],
    //cidade: ['', Validators.required],
    //estado: ['', Validators.required],
    //complemento_logradouro: ['', Validators.required],
    //contribuinte_previdencia: ['', Validators.required],
    //possui_procurador: ['', Validators.required],

    ////if (form.get('NOME') != null && (form.get('NOME').value == null || form.get('NOME').value == ''))
    ////	form.get('NOME').setValue(this._nm);

    ////if (form.get('EMPRESA') != null && (form.get('EMPRESA').value == null || form.get('EMPRESA').value == ''))
    ////	form.get('EMPRESA').setValue(this._nm);

    ////if (form.get('empresa') != null && (form.get('empresa').value == null || form.get('empresa').value == ''))
    ////	form.get('empresa').setValue(this._nm);

    ////if (form.get('NOME') != null && (form.get('NOME').value == null || form.get('NOME').value == ''))
    ////	form.get('NOME').setValue(this._nm);

    ////if (form.get('nome_paciente') != null && (form.get('nome_paciente').value == null || form.get('nome_paciente').value == ''))
    ////	form.get('nome_paciente').setValue(this._nm);


    ////if (form.get('PACIENTECLIENTE') != null)
    ////	form.get('PACIENTECLIENTE').setValue(true);


    ////if (form.get('email') != null && (form.get('email').value == null || form.get('email').value == ''))
    ////	form.get('email').setValue(this._em);

    ////if (form.get('EMAIL') != null && (form.get('EMAIL').value == null || form.get('EMAIL').value == ''))
    ////	form.get('EMAIL').setValue(this._em);

    ////if (form.get('cpf') != null && (form.get('cpf').value == null || form.get('cpf').value == ''))
    ////	form.get('cpf').setValue(this.ret);

    ////if (form.get('CPF') != null && (form.get('CPF').value == null || form.get('CPF').value == ''))
    ////	form.get('CPF').setValue(this.ret);

    ////if (form.get('celular') != null) {
    ////	form.get('celular').setValue(this._cl);
    ////}

    ////if (form.get('CELULAR') != null) {
    ////	form.get('CELULAR').setValue(this._cl);
    ////}
    ////if (form.get('whatsapp') != null) {
    ////	form.get('whatsapp').setValue(this._cl);
    ////}

    ////if (form.get('telefone') != null) {
    ////	form.get('telefone').setValue(this._cl);
    ////}

    ////if (form.get('CELULAR') != null) {
    ////	form.get('CELULAR').setValue(this._cl);
    ////}

    ////if (form.get('areaatuacao') != null) {

    ////	form.get('areaatuacao').setValue('Laboratório');

    ////}

    ////if (form.get('numerocartao') != null && (form.get('numerocartao').value == null || form.get('numerocartao').value == '')) {
    ////	form.get('numerocartao').setValue('4485 2874 7912 2797');
    ////}


    ////if (form.get('cvv') != null && (form.get('cvv').value == null || form.get('cvv').value == '')) {
    ////	form.get('cvv').setValue(cvv);
    ////}
    //form.va
  }

  setDadosObjeto(dados: any) {

    //  //console.log('Gerando valores aleatórios', dados)

    this.gerarValores();
    if (dados["default"]) {

      if (Object.hasOwn(dados['default'], 'Bairro'))
        dados["default"]['Bairro'] = this._end.bairro

      if (Object.hasOwn(dados['default'], 'CEP'))
        dados["default"]['CEP'] = this._end.cep

      if (Object.hasOwn(dados['default'], 'CNPJ'))
        dados["default"]['CNPJ'] = this.ret

      if (Object.hasOwn(dados['default'], 'Celular'))
        dados["default"]['Celular'] = this._cl

      if (Object.hasOwn(dados['default'], 'CofirmarSenha'))
        dados["default"]['CofirmarSenha'] = this._senhaPadrao

      if (Object.hasOwn(dados['default'], 'Complemento'))
        dados["default"]['Complemento'] = this._end.complemento

      if (Object.hasOwn(dados['default'], 'Email'))
        dados["default"]['Email'] = this._em

      if (Object.hasOwn(dados['default'], 'Logradouro'))
        dados["default"]['Logradouro'] = this._end.logradouro

      if (Object.hasOwn(dados['default'], 'Municipio'))
        dados["default"]['Municipio'] = this._end.cidade

      if (Object.hasOwn(dados['default'], 'NomeFantasia'))
        dados["default"]['NomeFantasia'] = this._nm

      if (Object.hasOwn(dados['default'], 'Numero'))
        dados["default"]['Numero'] = this._end.numero


      if (Object.hasOwn(dados['default'], 'NomeTabeliao'))
        dados["default"]['NomeTabeliao'] = this._nm


      if (Object.hasOwn(dados['default'], 'Senha'))
        dados["default"]['Senha'] = this._senhaPadrao

      if (Object.hasOwn(dados['default'], 'UF'))
        dados["default"]['UF'] = this._end.uf




      // //console.log(dados)


    }

    if (dados["Dependente"]) {

      dados["Dependente"]["Nome"] = this._nm + Math.floor(Math.random() * 100)
      dados["Dependente"]["Cpf"] = this.ret;

    }

    if (dados['DadosCartao']) {

      //Dados pagamento
      dados["DadosCartao"]["NumCartao"] = '4485 2874 7912 2797';
      dados["DadosCartao"]["NomeCartao"] = this._nm;
      dados["DadosCartao"]["Cpf"] = this.ret;
      dados["DadosCartao"]["CodigoSeguranca"] = "488";
      dados["DadosCartao"]["Validade"] = "12/2027";
    }

    if (dados["Nome"] != null && dados["Nome"] == '') {
      dados["Nome"] = this._nm;

    }

    if (dados["Celular"] != null && dados["Celular"] == '') {
      dados["Celular"] = this._cl;

    }

    if (dados["Email"] != null && dados["Email"] == '') {
      dados["Email"] = this._em;
    }

    if (dados["Cpf"] != null && dados["Cpf"] == '') {
      dados["Cpf"] = this.ret;
    }
    if (dados["CPF"] != null && dados["CPF"] == '') {
      dados["CPF"] = this.ret;
    }


    //objtFind = inputs.find(x => x.placeholder.replace('-', '').toLowerCase() == "email");
    //if (objtFind != null && objtFind.value != undefined && objtFind.value == '') {
    //  objtFind.value = this._em;
    //  objtFind.error = null;
    //  objtFind.message = null;
    //}

    //objtFind = inputs.find(x => x.placeholder.replace('-', '').toLowerCase() == "celular");
    //if (objtFind != null && objtFind.value != undefined && objtFind.value == '') {
    //  objtFind.value = this._cl;
    //  objtFind.error = null;
    //  objtFind.message = null;
    //}

    //objtFind = inputs.find(x => x.placeholder.replace('-', '').toLowerCase() == "cep");
    //if (objtFind != null && objtFind.value != undefined && objtFind.value == '') {
    //  objtFind.value = this._end[0].cep;
    //  objtFind.error = null;
    //  objtFind.message = null;
    //}

    //objtFind = inputs.find(x => x.placeholder.replace('-', '').toLowerCase() == "rua");
    //if (objtFind != null && objtFind.value != undefined && objtFind.value == '') {
    //  objtFind.value = this._end[0].logradouro;
    //  objtFind.error = null;
    //  objtFind.message = null;
    //}

    //objtFind = inputs.find(x => x.placeholder.replace('-', '').toLowerCase() == "número");
    //if (objtFind != null && objtFind.value != undefined && objtFind.value == '') {
    //  objtFind.value = this._end[0].numero;
    //  objtFind.error = null;
    //  objtFind.message = null;
    //}

    //objtFind = inputs.find(x => x.placeholder.replace('-', '').toLowerCase() == "cidade");
    //if (objtFind != null && objtFind.value != undefined && objtFind.value == '') {
    //  objtFind.value = this._end[0].cidade;
    //  objtFind.error = null;
    //  objtFind.message = null;
    //}

    //objtFind = inputs.find(x => x.placeholder.replace('-', '').toLowerCase() == "bairro");
    //if (objtFind != null && objtFind.value != undefined && objtFind.value == '') {
    //  objtFind.value = this._end[0].bairro;
    //  objtFind.error = null;
    //  objtFind.message = null;
    //}

    //objtFind = inputs.find(x => x.placeholder.replace('-', '').toLowerCase() == "uf");
    //if (objtFind != null && objtFind.value != undefined && objtFind.value == '') {
    //  objtFind.value = this._end[0].uf;
    //  objtFind.error = null;
    //  objtFind.message = null;
    //}

  }


  gerarCpfValido(cpfString: any) {

    //console.log('GERANDO NOVO CPF...')
    //console.log(cpfString)

    //let cpf = cpfString;

    //let newCpf = cpf.replaceAll('.', '').replace('-', '')
    //let numberDigits = newCpf.split('')


    //let numberDigits2 = numberDigits.map((e) => parseInt(e));

    //let somaA:number = 0;
    //let count:number = 0;


    //for (let i:number = 10; i > 1; i--) {
    //	somaA += numberDigits2[count] * i;
    //	count++;
    //}

    //console.log(count)
    //console.log(numberDigits2)


    //let resto:number = somaA % 11;
    //let primeiroDigito:number = resto < 2 ? 0 : 11 - resto;

    //numberDigits2[9] = primeiroDigito;

    //let somaB:number = 0;
    //count = 0;

    //for (let i:number = 11; i > 1; i--) {
    //	somaB += numberDigits2[count] * i;
    //	count++;
    //}

    //let resto2 = somaB % 11;
    //let segundoDigito = resto2 < 2 ? 0 : 11 - resto2;

    //console.log('Digitos...')
    //console.log(primeiroDigito)
    //console.log(segundoDigito)
    //console.log(newCpf)

  }

  setValueArrayInput(inputs: any) {
    this.gerarValores();
    let objtFind = inputs.find((x: any) => x.placeholder.toLowerCase() == "nome");
    if (objtFind != null && objtFind.value != undefined && objtFind.value == '') {
      objtFind.value = this._nm;
      objtFind.error = null;
      objtFind.message = null;

    }

    objtFind = inputs.find((x: any) => x.placeholder.replace('-', '').toLowerCase() == "email");
    if (objtFind != null && objtFind.value != undefined && objtFind.value == '') {
      objtFind.value = this._em;
      objtFind.error = null;
      objtFind.message = null;
    }

    objtFind = inputs.find((x: any) => x.placeholder.replace('-', '').toLowerCase() == "celular");
    if (objtFind != null && objtFind.value != undefined && objtFind.value == '') {
      objtFind.value = this._cl;
      objtFind.error = null;
      objtFind.message = null;
    }

    objtFind = inputs.find((x: any) => x.placeholder.replace('-', '').toLowerCase() == "cep");
    if (objtFind != null && objtFind.value != undefined && objtFind.value == '') {
      objtFind.value = this._end[0].cep;
      objtFind.error = null;
      objtFind.message = null;
    }

    objtFind = inputs.find((x: any) => x.placeholder.replace('-', '').toLowerCase() == "rua");
    if (objtFind != null && objtFind.value != undefined && objtFind.value == '') {
      objtFind.value = this._end[0].logradouro;
      objtFind.error = null;
      objtFind.message = null;
    }

    objtFind = inputs.find((x: any) => x.placeholder.replace('-', '').toLowerCase() == "número");
    if (objtFind != null && objtFind.value != undefined && objtFind.value == '') {
      objtFind.value = this._end[0].numero;
      objtFind.error = null;
      objtFind.message = null;
    }

    objtFind = inputs.find((x: any) => x.placeholder.replace('-', '').toLowerCase() == "cidade");
    if (objtFind != null && objtFind.value != undefined && objtFind.value == '') {
      objtFind.value = this._end[0].cidade;
      objtFind.error = null;
      objtFind.message = null;
    }

    objtFind = inputs.find((x: any) => x.placeholder.replace('-', '').toLowerCase() == "bairro");
    if (objtFind != null && objtFind.value != undefined && objtFind.value == '') {
      objtFind.value = this._end[0].bairro;
      objtFind.error = null;
      objtFind.message = null;
    }

    objtFind = inputs.find((x: any) => x.placeholder.replace('-', '').toLowerCase() == "uf");
    if (objtFind != null && objtFind.value != undefined && objtFind.value == '') {
      objtFind.value = this._end[0].uf;
      objtFind.error = null;
      objtFind.message = null;
    }

  }

}

function gera_random(n: any) {
  var ranNum = Math.round(Math.random() * n);
  return ranNum;
}

// Funcao para retornar o resto da divisao entre numeros (mod)
function mod(dividendo: any, divisor: any) {
  return Math.round(dividendo - (Math.floor(dividendo / divisor) * divisor));
}
function getRandomArbitrary(min: any, max: any) {
  return Math.random() * (max - min) + min;
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt(min: any, max: any) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
