<div id="topo"></div>
<menu-edicao-topo [icone]="true" [tipoIcone]="'description'" [title]="'Anotações'"
                  [idxWorkflow]="getIdexAnotacoes()" [blocoAnotacao]="'workflow'" *ngIf="isQA()" style="position: absolute;top: 0;z-index: 10;"></menu-edicao-topo>

<ng-container *ngIf="!isAreaCliente">
  <app-header [projeto]="projeto" *ngIf="loading && showHeader"></app-header>



  <router-outlet></router-outlet>


  <app-footer [projeto]="projeto" *ngIf="loading && showFooter"></app-footer>
</ng-container>

<ng-container *ngIf="isAreaCliente">


  <router-outlet></router-outlet>

</ng-container>


<div id="myNav" class="overlay-loading">

  <a href="javascript:void(0)" class="closebtn" (click)="closeModal('myNav')" onclick="document.getElementById('myNav').style.width = '0%';">&times;</a>


  <div class="overlay-content-loading">

    <div class="login-logo">
    </div>

    <div class="cssload-container">
      <div class="cssload-speeding-wheel"></div>
    </div>
    <div class="sk-spinner sk-spinner-wave" style="text-align: center;color: #fff;font-size: 20px;">Aguarde...</div>
  </div>

</div>


<div id="myNavManual" class="overlay-loading">

  <a href="javascript:void(0)" class="closebtn" (click)="closeModal('myNavManual')" onclick="document.getElementById('myNavManual').style.width = '0%';">&times;</a>


  <div class="overlay-content-loading">

    <div class="login-logo">
    </div>

    <div class="cssload-container">
      <div class="cssload-speeding-wheel"></div>
    </div>
    <div class="sk-spinner sk-spinner-wave" style="text-align: center;color: #fff;font-size: 20px;">Aguarde...</div>
  </div>

</div>

<div class="modal" style="z-index:6" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="label" id="excluiEntidade">PROJETO</h5>

        <button id="btnFechaModalExcluirEntidade" style="width:50px" (keydown.space)="$event.preventDefault()"
                (click)="onCloseHandled()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="container-fluid w-100 pt-2">
        <app-card id="cardModalExcluirEntidade" titulo="">

          <div class="row">
            <div class="col-sm-5">
              PROJETO:
            </div>
            <div class="col-sm-7">
              <mat-form-field appearance="outline" class="inputMat">
                <mat-label>PROJETO</mat-label>
                <mat-select [(ngModel)]="projeto">
                  <mat-option *ngFor="let option of projetos" [value]="option">
                    {{option.Nome}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </app-card>
        <div class="input-group mb-3 mt-3 justify-content-end">

          <button (click)="onCloseHandled()" id="btnCancelarExcluirEntidade"
                  type="button" class="btn btn-form " title="Cancelar" data-dismiss="modal">
            Carregar <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
