<div class="wrapper-item">

  <div class="wrapper-header-item">
    <h3 class="content-title">
      {{ item.produto.titulo }}
      <span data-toggle="tooltip" title="Para os pedidos de PET, o valor cobrado pelo cartório será informado posteriormente e deverá ser pago separadamente." *ngIf="item.produto.descricaoTipoAto === 'PET'"><img src="../../../../assets/question-circle-fill.svg"></span>
    </h3>
    <div class="wrapper-buttons">
      <div class="content-button">
        <button class="buttons-action" (click)="editar.emit(item)">
          Editar
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/05a3523c983604fe0000ef79b0c38af35a7a81c7e6b32230af7c4a88344d432f?"
            class="img-11"
          />
        </button>
      </div>
      <div class="content-button">
        <button class="buttons-action" (click)="excluirItem.emit(item)">
          Excluir
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/dcdd700d816beff971594d71d60bdd298e4130c545dd466135231c4783571eb5?"
            class="img-12"
          />
        </button>
      </div>
    </div>
  </div>

  <mat-divider class="w-100"></mat-divider>

  <div class="wrapper-info-cartorio">
    <div class="wrapper-topo">
      <div class="content-left">
        <span class="title-box">Cartório Selecionado</span>
        <p class="text-info-box">{{ item.cartorio}}</p>
      </div>
      <div class="content-right">
        <span class="title-box">Valor</span>
        <p class="text-info-box">{{ item.totalAto | currency:'BRL' }}</p>
      </div>
    </div>

    <ng-container *ngIf="visibilidadeDetalhes">
      <div class="content-info-solicitante">
        <h2 class="title-info-solicitante"
            *ngIf="item.proprietario?.length > 1; else singleOwner">
          Dados dos Proprietários
        </h2>
        <ng-template #singleOwner>
          <h2 class="title-info-solicitante">Dados do Proprietário</h2>
        </ng-template>
        <div *ngFor="let proprietario of item.proprietario">
          <div class="row">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Nome Completo</h3>
              <p class="info-section">{{ proprietario.nomeCompleto }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">CPF</h3>
              <p class="info-section">
                {{
                  proprietario.cpf | mask : "000.000.000-00||00.000.000/0000-00"
                }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Identidade</h3>
              <p class="info-section">{{ proprietario.identidade }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Orgão Expedidor</h3>
              <p class="info-section">{{ proprietario.orgaoExpedidor }}</p>
            </div>
          </div>
          <div class="row">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Data Expedição</h3>
              <p class="info-section">
                {{ proprietario.dataExpedicao | date : "dd/MM/yyyy" }}
              </p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Email</h3>
              <p class="info-section">{{ proprietario.email }}</p>
            </div>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Telefone</h3>
            <p class="info-section">{{ proprietario.telefone }}</p>
          </div>
        </div>
        <div class="row">
          <h2 class="title-info-solicitante">Dados do PET</h2>
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Nome</h3>
            <p class="info-section">{{ item.dadosPet.nome }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Espécie</h3>
            <p class="info-section">{{ item.dadosPet.especie }}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Raça</h3>
            <p class="info-section">{{ item.dadosPet.raca }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Sexo</h3>
            <p class="info-section">{{ item.dadosPet.sexo }}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Pelagem / Plumagem</h3>
            <p class="info-section">{{ item.dadosPet.pelagem }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Data de Nascimento</h3>
            <p class="info-section">
              {{ item.dadosPet.dataNascimento | date : "dd/MM/yyyy" }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Idade</h3>
            <p class="info-section">{{ item.dadosPet.idade }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Sinais Características</h3>
            <p class="info-section">{{ item.dadosPet.caracteristicas }}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Microchip</h3>
            <p class="info-section">{{ item.dadosPet.microchip }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Endereço</h3>
            <p class="info-section">{{ item.dadosPet.endereco }}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Foto do Pet</h3>
            <p class="info-section" *ngFor="let arquivo of item.arquivosFotos">
              <a [href]="arquivo?.nome" target="_blank" class="col-md-6 col-xs-12">
                {{ arquivo.nomeOriginal }}
              </a>
            </p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Documentos de Identificação</h3>
            <p class="info-section" *ngFor="let arquivo of item.arquivosIdentificacao">
              <a [href]="arquivo?.nome" target="_blank" class="col-md-6 col-xs-12">
                {{ arquivo.nomeOriginal }}
              </a>
            </p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Comprovante de Residência</h3>
            <p class="info-section" *ngFor="let arquivo of item.arquivosResidencia">
              <a [href]="arquivo?.nome" target="_blank" class="col-md-6 col-xs-12">
                {{ arquivo.nomeOriginal }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <button
    class="view-more-details"
    (click)="visibilidadeDetalhes = !visibilidadeDetalhes"
  >
    {{ visibilidadeDetalhes ? "Ocultar detalhes" : "Ver detalhes" }}
  </button>
</div>
