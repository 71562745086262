import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-meusPedidosOrderDetails-5',
  templateUrl: './meusPedidosOrderDetail5.component.html',
  styleUrls: ['./../_meus-pedidos.component.scss'] 
})
export class MeusPedidosOrderDetails5Component  {
  @Input() compra: any
  @Input() esconderCopias: any
}
