import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-meusPedidosOrderDetails-101',
  templateUrl: './meusPedidosOrderDetail10.1.component.html',
  styleUrls: ['./../_meus-pedidos.component.scss']
})
export class MeusPedidosOrderDetails101Component {
  @Input() compra: any

  public visibilidadeDetalhes: boolean = false;

  @Output() updatePedido = new EventEmitter<any>();

  cancelarEdicao(compra: any) {
    compra.editar = false;
  }

  salvarItem(itemPedido: any) {
    this.updatePedido.emit(itemPedido)
  }
   
}
