<div class="wrapper-item" *ngIf="!compra?.editar">
  <div class="content-info-solicitante">
    <div *ngIf="compra && compra.produto && compra.produto.titulo === 'Pesquisa de certidão de casamento'">
      <div class="row" *ngFor="let item2 of compra.detalhes">
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Nome Completo</b></h3>
          <p class="info-section">{{ compra.nomeCompleto }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Identidade</b></h3>
          <p class="info-section">{{ compra.identidade }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>NIF</b></h3>
          <p class="info-section">{{ compra.nif }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Telefone</b></h3>
          <p class="info-section">{{ compra.telefone }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Email</b></h3>
          <p class="info-section">{{ compra.email }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Nome do Pai</b></h3>
          <p class="info-section">{{ item2.nomePai }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Finalidade</b></h3>
          <p class="info-section">{{ item2.finalidade.descricao }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Nome Cônjuge</b></h3>
          <p class="info-section">{{ item2.nomeConjuge }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Nome da Mãe</b></h3>
          <p class="info-section">{{ item2.nomeMae }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Lugar do Casamento</b></h3>
          <p class="info-section">{{ item2.pais.pais }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Data do Casamento</b></h3>
          <p class="info-section">{{ item2.data }}</p>
        </div>

        <div *ngIf="item2.pais.pais === 'Portugal'">
          <div class="row">
            <div class="wrapper-info col-lg-6">
              <h3 class="info-section"><b>Distrito Detalhes</b></h3>
              <p class="info-section">{{ item2.distritoDetalhes.descricao }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="info-section"><b>Concelho Detalhes</b></h3>
              <p class="info-section">{{ item2.concelhoDetalhes.descricao }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="info-section"><b>Freguesia Detalhes</b></h3>
              <p class="info-section">{{ item2.freguesiaDetalhes.descricao }}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Pedido feito por terceiro?</b></h3>
            <p class="info-section">{{ compra.pedidoFeitoPorTerceiro === 'true' ? 'Sim' : 'Não' }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>CERP</b></h3>
            <p class="info-section">{{compra?.idItem}}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Nº ID Civil</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].identidade }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Nome Completo</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].nomeCompleto }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Número | Ano do registro</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].dataExpedicao }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Data de Nascimento</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].dataNascimento }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Naturalidade</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].pais.pais }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Distrito Solicitação</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].distritoSolicitante.descricao }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Concelho Solicitação</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].concelhoSolicitante.descricao }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Freguesia Solicitação</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].freguesiaSolicitante.descricao }}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-12">
            <h3 class="info-section"><b>Informação Adicional</b></h3>
            <p class="info-section">{{ compra.informacaoAdicional }}</p>
          </div>
        </div>

      </div>
    </div>

    <div *ngIf="compra && compra.produto && compra.produto.titulo === 'Pesquisa de certidão de nascimento'">
      <div class="row" *ngFor="let item2 of compra.detalhes">
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Nome Completo</b></h3>
          <p class="info-section">{{ compra.nomeCompleto }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Identidade</b></h3>
          <p class="info-section">{{ compra.identidade }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>NIF</b></h3>
          <p class="info-section">{{ compra.nif }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Telefone</b></h3>
          <p class="info-section">{{ compra.telefone }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Email</b></h3>
          <p class="info-section">{{ compra.email }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Nome do Pai</b></h3>
          <p class="info-section">{{ item2.nomePai }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Finalidade</b></h3>
          <p class="info-section">{{ item2.finalidade.descricao }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Nome da Mãe</b></h3>
          <p class="info-section">{{ item2.nomeMae }}</p>
        </div>

        <div class="row">
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Pedido feito por terceiro?</b></h3>
            <p class="info-section">{{ compra.pedidoFeitoPorTerceiro === 'true' ? 'Sim' : 'Não' }}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>CERP</b></h3>
            <p class="info-section">{{compra?.idItem}}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Nº ID Civil</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].identidade }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Nome Completo</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].nomeCompleto }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Número | Ano do registro</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].dataExpedicao }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Data de Nascimento</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].dataNascimento }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Naturalidade</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].pais.pais }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Distrito Solicitação</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].distritoSolicitante.descricao }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Concelho Solicitação</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].concelhoSolicitante.descricao }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Freguesia Solicitação</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].freguesiaSolicitante.descricao }}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-12">
            <h3 class="info-section"><b>Informação Adicional</b></h3>
            <p class="info-section">{{ compra.informacaoAdicional }}</p>
          </div>
        </div>

      </div>
    </div>

    <div *ngIf="compra && compra.produto && compra.produto.titulo === 'Pesquisa de certidão de óbito'">
      <div class="row" *ngFor="let item2 of compra.detalhes">
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Nome Completo</b></h3>
          <p class="info-section">{{ compra.nomeCompleto }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Identidade</b></h3>
          <p class="info-section">{{ compra.identidade }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>NIF</b></h3>
          <p class="info-section">{{ compra.nif }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Telefone</b></h3>
          <p class="info-section">{{ compra.telefone }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Email</b></h3>
          <p class="info-section">{{ compra.email }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Nome do Pai</b></h3>
          <p class="info-section">{{ item2.nomePai }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Finalidade</b></h3>
          <p class="info-section">{{ item2.finalidade.descricao }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Data do Óbito</b></h3>
          <p class="info-section">{{ item2.data }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>Nome da Mãe</b></h3>
          <p class="info-section">{{ item2.nomeMae }}</p>
        </div>
        <div class="wrapper-info col-lg-6">
          <h3 class="info-section"><b>País do Óbito</b></h3>
          <p class="info-section">{{ item2.pais.pais }}</p>
        </div>
        <div *ngIf="item2.pais.pais === 'Portugal'">
          <div class="row">
            <div class="wrapper-info col-lg-6">
              <h3 class="info-section"><b>Distrito Detalhes</b></h3>
              <p class="info-section">{{ item2.distritoDetalhes.descricao }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="info-section"><b>Concelho Detalhes</b></h3>
              <p class="info-section">{{ item2.concelhoDetalhes.descricao }}</p>
            </div>
            <div class="wrapper-info col-lg-6">
              <h3 class="info-section"><b>Freguesia Detalhes</b></h3>
              <p class="info-section">{{ item2.freguesiaDetalhes.descricao }}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Pedido feito por terceiro?</b></h3>
            <p class="info-section">{{ compra.pedidoFeitoPorTerceiro === 'true' ? 'Sim' : 'Não' }}</p>
          </div>

          <div class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>CERP</b></h3>
            <p class="info-section">{{compra?.idItem}}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Nº ID Civil</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].identidade }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Nome Completo</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].nomeCompleto }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Número | Ano do registro</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].dataExpedicao }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Data de Nascimento</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].dataNascimento }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Naturalidade</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].pais.pais }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Distrito Solicitação</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].distritoSolicitante.descricao }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Concelho Solicitação</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].concelhoSolicitante.descricao }}</p>
          </div>
          <div *ngIf="compra.pedidoFeitoPorTerceiro === 'true'" class="wrapper-info col-lg-6">
            <h3 class="info-section"><b>Freguesia Solicitação</b></h3>
            <p class="info-section">{{ compra.dadosSolicitante[0].freguesiaSolicitante.descricao }}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-12">
            <h3 class="info-section"><b>Informação Adicional</b></h3>
            <p class="info-section">{{ compra.informacaoAdicional }}</p>
          </div>
        </div>

      </div>
    </div>
  </div>

  <app-form-101 *ngIf="compra?.editar" [itemEdicao]="compra" [edicao]="true" (cancelarEdicao)="cancelarEdicao(compra)"
                (salvarItem)="salvarItem($event)" [itemAtual]="compra.produto">
  </app-form-101>
