
<mat-stepper orientation="vertical" linear="true" #stepper [selectedIndex]="0">
  <mat-step  *ngFor="let item of kit.Itens">
    
      <ng-template matStepLabel>
        {{item.titulo}}
        
      </ng-template>
      

      <div>
        <button mat-button class="action-button" >Próximo</button>
      </div>
  
  </mat-step>
  
  
  
</mat-stepper>
