import { AfterViewInit, Component, HostListener, OnInit, Output, EventEmitter, HostBinding, Input } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl, FormGroup, Validators
} from '@angular/forms';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
//
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';




@Component({
  selector: 'app-form-11',
  templateUrl: './form11.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class Form11Component implements OnInit, AfterViewInit {
  @Input() itemAtual: any;
  @Input() itemEdicao: any;
  @Input() edicao: any=false;
  @Output() cancelarEdicao = new EventEmitter<any>();

  @Output() salvarItem = new EventEmitter<any>();

  @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();

  atos: any = [{ "Id": '10030', "Descricao": "10030-Casamento" }, { "Id": '10031', "Descricao": "10031-Nascimento" }, { "Id": '10032', "Descricao": "10032-Óbito" }]

  finalidades: any = [{ "Id": '19', "Descricao": "EXTRAJUDICIAL" }, { "Id": '20', "Descricao": "INFORMAÇÃO PESSOAL" }, { "Id": '18', "Descricao": "JUDICIAL" }]

  distritos: any = [{ "Id": '10', "Descricao": "AÇORES - CORVO" }, { "Id": '11', "Descricao": "AÇORES - FAIAL" }, { "Id": '12', "Descricao": "AÇORES - FLORES" }]

  concelhos: any = [{ "Id": '905', "Descricao": "CORVO" }]

  freguesias: any = [{ "Id": '17585', "Descricao": "CORVO" }]

  paises: any = [{ "Id": '1', "Descricao": "AFEGANISTÃO" }, { "Id": '2', "Descricao": "ALBÂNIA" }, { "Id": '23', "Descricao": "BRASIL" }, { "Id": '83', "Descricao": "Portugal" }]

  public files: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public teste: TesteService,
    public service: CrudService,
    private routeAc: ActivatedRoute,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  camposForm = this.formBuilder.group({
    ato: ['', Validators.required],
    nomeCompleto: ['', Validators.required],
    identidade: [''],
    nif: [''],
    telefone: [''],
    email: ['', Validators.compose([
      Validators.email
    ])],
    nomePai: [''],
    nomeMae: [''],
    conjuge: [''],
    finalidade: [''],
    pais: [''],
    distrito: [''],
    concelho: [''],
    freguesia: [''],
    dataExpedicao: [''],
    dataNascimento: [''],
    dataCasamento: [''],
    dataObito: [''],
    dadosSolicitante: this.formBuilder.group({
      nomeCompleto: [''],
      identidade: [''],
      nif: [''],
      dataNascimento: [''],
      pais: [''],
    }),
    pedidoFeitoPorTerceiro: [''],
    informacaoAdicional: [''],
    arquivos: new FormControl([]),
  });

  ngOnInit(): void {
    if (document.location.href.indexOf('meu-carrinho') == -1) {
      this.carregarEdicao()
    }
  }

  ngAfterViewInit() {

  }

  changeAtos(value: any) {
    //console.log(value);
  }

  setaArquivos(arquivos: any) {
    //console.log(arquivos);

    this.camposForm.controls.arquivos.setValue(arquivos);

    //console.log(this.camposForm.value);
  }

  cancelarEdicaoForm() {
    this.cancelarEdicao.emit()
  }

  prodAtu: any
  carregarEdicao() {
    let paramId = this.routeAc.snapshot.paramMap.get('id');

    if (paramId != null || this.itemEdicao != null) {
      let pedido = this.storage.localStorageGet("pedido")

      if (pedido?.listaCompra != null && pedido?.listaCompra.length > 0) {
        this.prodAtu = { ...pedido.listaCompra?.find((x: any) => x.produto.aLink == this.itemAtual.aLink) }
        this.preencheCampos(this.prodAtu)

      }
    }

  }

  preencheCampos(object: any) {
    for (const key in object) {
      this.camposForm.get(key)?.setValue(object[key])

    }
  }

  handleClickUpload() {
    let el = document.getElementById('file');
    el?.click();
  }
  
  updatePedido() {
    this.camposForm.markAllAsTouched();
    if (this.camposForm.valid) {
      let prodAtu = null

      prodAtu = this.camposForm.value

      this.salvarItem.emit(prodAtu);
    }
  }


  // get itemAtual() {
  //   return this.storage.localStorageGet("item_atual");
  // }


  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // //console.log(event.key, event.ctrlKey, event.shiftKey, environment.production)
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {

      this.teste.setValueForm(this.camposForm);

    }

  }
}


