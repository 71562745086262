<ng-container *ngIf="!icone">
  <div #menuEdicaoTopo *ngIf="isModoEdicao()">
    <div class="row">
      <div class="col-12">
        <i class="fa fa-eye"></i>
        <strong (click)="openModalJson()" style="cursor:pointer;" class="hover-b-b-1">
          [Ver Json]
        </strong>
        <strong (click)="openModalJsonHF()" style="cursor:pointer;" class="hover-b-b-1">
          [Ver Json Haeder/Footer]
        </strong>
        <i class="fa fa-floppy-o"></i>
        <strong (click)="salvarJson2()" style="cursor:pointer;" class="hover-b-b-1" *ngIf="false">
          [Salvar]
        </strong>
        <strong (click)="recarregar()" style="cursor:pointer;" class="hover-b-b-1">
          [recarrregar]
        </strong>
        <strong (click)="novaPagina()" style="cursor:pointer;" class="hover-b-b-1">
          [nova pagina]
        </strong>
        <strong (click)="consolePagina()" style="cursor:pointer;" class="hover-b-b-1">
          [copiar/console pagina]
        </strong>
        &nbsp;&nbsp;

      </div>
    </div>

    <div *ngIf="conteudoPagina && false" class="row">
      <div class="col-2">
        <strong>Tipo:</strong>
        <select [(ngModel)]="conteudoPagina.tipo" class="form-control form-control-sm">
          <option value="crud">crud</option>
        </select>
      </div>
      <div class="col-3">
        <strong>Página:</strong>
        <select [(ngModel)]="conteudoPagina.pagina" (change)="onChangePaina($event)"
                class="form-control form-control-sm">
          <option value="crud">Novo crud</option>
          <ng-container *ngFor="let paginas of configPage; let i = index;">
            <option [value]="paginas.pagina">{{paginas.pagina}}</option>
          </ng-container>
        </select>
      </div>
      <div class="col-3" *ngIf="conteudoPagina.tipo == 'crud'">
        <strong>Id Pagina:</strong>
        <input type="text" name="name" value="" [(ngModel)]="conteudoPagina.pagina"
               class="form-control form-control-sm" />
      </div>
      <div class="col-4" *ngIf="conteudoPagina.tipo == 'crud'">
        <strong>Rota principal:</strong>
        <input type="text" name="name" value="" [(ngModel)]="conteudoPagina.url" class="form-control form-control-sm" />
      </div>

    </div>
  </div>

  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="label" id="excluiEntidade">ADICIONAR NOVA PÁGINA</h5>

          <button id="btnFechaModalExcluirEntidade" style="width:50px" (keydown.space)="$event.preventDefault()"
                  (click)="onCloseHandled()" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="container-fluid w-100 pt-2">
          <app-card id="cardModalExcluirEntidade" titulo="">

            <div class="row">
              <div class="col-sm-5">
                Pagina:
              </div>
              <div class="col-sm-7">
                <input [(ngModel)]="novoInput['Nome']">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                Url:
              </div>
              <div class="col-sm-7">
                <input [(ngModel)]="novoInput['Url']">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                Titulo:
              </div>
              <div class="col-sm-7">
                <input [(ngModel)]="novoInput['Titulo']">
              </div>
            </div>

            <div class="row">
              <div class="col-sm-5">
                Copiar de:
              </div>
              <div class="col-sm-7">
                <select [(ngModel)]="IdPaginaModel">
                  <option value="0">Selecione uma pagina base</option>
                  <ng-container *ngFor="let item of paginas">
                    <option [value]="item.IdPagina">{{item.Nome}}</option>

                  </ng-container>
                </select>
              </div>
            </div>
          </app-card>
          <div class="input-group mb-3 mt-3 justify-content-end">

            <button (click)="onCloseHandled()" id="btnCancelarExcluirEntidade" type="button" class="btn btn-form "
                    title="Cancelar" data-dismiss="modal">
              Cancelar <i class="fas fa-times"></i>
            </button>

            <button id="btnConfirmarExcluirEntidade" type="button" class="btn btn-form btn-success" data-dismiss="modal"
                    (click)="salvarNovaPagina()">
              Salvar <i class="fas fa-check"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayJson}">
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="width: 1300px; right: 20px; left: -400px;">
        <div class="modal-header">
          <h5 class="label" id="excluiEntidade">Json</h5>

          <button id="btnFechaModalJson" style="width:50px" (click)="onCloseHandledJson()" type="button" class="close"
                  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="container-fluid w-100 pt-2">
          <app-card id="cardModalExcluirEntidade" titulo="">

            <div class="row">

              <div class="col-sm-12">
                <textarea style="width:100%" id="jsonvalue" rows="25">{{jsonPg |json}}</textarea>

              </div>
            </div>

          </app-card>
          <div class="input-group mb-3 mt-3 justify-content-end">

            <button (click)="onCloseHandled()" id="btnCancelarExcluirEntidade" type="button" class="btn btn-form "
                    title="Cancelar" data-dismiss="modal">
              Cancelar <i class="fas fa-times"></i>
            </button>
            <button type="button" class="btn btn-form btn-success" data-dismiss="modal" (click)="alterarJson()">
              Alterar Jsond <i class="fas fa-check"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="icone"> 
  <div style="float: left; background-color: #CDDC39; padding: 9px; display: flex;cursor:pointer " (click)="openModalAnotacao(blocoAnotacao,title,idxWorkflow)">
    <mat-icon [title]="title"
              [style]="title =='Anotações'? 'color:'+temComentario(blocoAnotacao,title,idxWorkflow): ''">{{tipoIcone}}</mat-icon>
    Anotações da página
  </div>
  <div *ngIf="showQAAdmin()" style="float: left; margin-left: 5px; background-color: aquamarine; padding: 9px; display: flex; cursor: pointer " (click)="openModalAnotac(title,false)">
    <mat-icon [title]="'Chamados do projeto'"
              [style]="title === 'Anotações' ? 'color:' + temComentario(blocoAnotacao, title, idxWorkflow) : ''">
      visibility
    </mat-icon>
    Anotações do sistema
  </div>
  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayAnotacao}">
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="width: 1300px; right: 20px; left: -400px;">
        <div class="modal-header">
          <div class="row">
            <div class="col-lg-3" style="width: 350px;" *ngIf="isVisaoTotal && showQAAdmin()">

              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label> Responsável </mat-label>
                <mat-select [(ngModel)]="usuarioSelected" (selectionChange)="openModalAnotac('Anotações',fechados)">
                  <mat-option [value]="''">Todos</mat-option>
                  <mat-option value="naoatribuido">Não atribuidos</mat-option>
                  <mat-option *ngFor="let item of usuarios" [value]="item.valor">
                    {{ item.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-3" style="width: 160px;" *ngIf="isVisaoTotal && showQAAdmin()">

              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label> Prioridade </mat-label>
                <mat-select [(ngModel)]="prioridadeSelected" (selectionChange)="openModalAnotac('Anotações',fechados)">
                  <mat-option value="0">Todas</mat-option>
                  <mat-option value="1">1</mat-option>
                  <mat-option value="2">2</mat-option>
                  <mat-option value="3">3</mat-option>

                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-3" style="width: 150px;">

              <h2 style="cursor: pointer; padding: 16px; font-size: 19px;" class="label" id="excluiEntidade"
                  [style.background-color]="(!fechados) ? '#9ea8c3' : '#f5f7fbcf'"
                  *ngIf="!isVisaoTotal"
                  (click)="openModalAnotacao(blocoAnotacao,title,idxWorkflow)">
                Em aberto
              </h2>
              <h2 style="cursor: pointer; padding: 16px; font-size: 19px;"
                  [style.background-color]="(!fechados) ? '#9ea8c3' : '#f5f7fbcf'"
                  class="label" id="excluiEntidade"
                  *ngIf="isVisaoTotal"
                  (click)="openModalAnotac(title,false)">
                Em aberto
              </h2>



            </div>
            <div class="col-lg-3" style="width: 150px;">
              <h2 style="cursor: pointer;background: aqua;padding: 16px;" class="label" id="excluiEntidade"
                  [style.background-color]="(fechados) ? '#9ea8c3' : '#f5f7fbcf'"
                  *ngIf="!isVisaoTotal"
                  (click)="getFechados(blocoAnotacao,title,idxWorkflow)">
                Fechados
              </h2>
              <h2 style="cursor: pointer;padding: 16px;" class="label" id="excluiEntidade"
                  [style.background-color]="(fechados) ? '#9ea8c3' : '#f5f7fbcf'"
                  *ngIf="isVisaoTotal"
                  (click)="openModalAnotac(title,true)">
                Fechados
              </h2> 

            </div>
            <div class="col-lg-3" style="width: 150px;">
              
              <h2 style="cursor: pointer;padding: 16px;" class="label" id="excluiEntidade"
                  [style.background-color]="(fechados) ? '#9ea8c3' : '#f5f7fbcf'"
                  *ngIf="isVisaoTotal"
                  (click)="openModalAnotac2d(title,true)">
                Fechados 2d
              </h2>

            </div>
          </div>

          <button id="btnFechaModalJson" style="width:50px" (click)="onClosedisplayAnotacao()" type="button"
                  class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ng-container *ngIf="false">
          <app-grafico-projeto></app-grafico-projeto>

        </ng-container>
        <ng-container *ngIf="true">

          <div class="container-fluid w-100 pt-2" *ngIf="mostrarAnotacao" style="overflow: scroll;">
            <div class="row">
              <ng-container *ngFor="let item of anotacaoFechados;let j = index;">
                <p><b>Item:</b> {{j+1}} | <b>Id anotação:</b> {{item.IdAnotacaoPagina}} | <b>Data inclusão:</b> {{item.DataInclusao | date:'dd/MM/yyyy'}}
                | <b>Aberto por:</b> {{item.Usuario}}
                <span *ngIf="item?.DataAjuste"><b>Ajustado:</b>  {{item.DataAjuste | date:'dd/MM/yyyy'}}</span>
                </p>

                <div class="row" style="padding-bottom: 20px;">

                  <div class="col-sm-12" *ngIf="item.LocalStorage != null">
                    <button type="button" class="btn btn-form btn-secondary" (click)="acessar(item)">acessar</button>

                  </div>
                </div>
                <div class="col-sm-12" *ngIf="item.Anotacao != '<p></p>'" [innerHTML]="getItem(item.Anotacao)">
                </div>
                <div class="row">

                  <ng-container *ngFor="let com of item.AnotacaoPagianaComentario;let j = index;">

                    <div class="col-sm-3">&nbsp;</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;">{{com.DataInclusao  | date:'dd/MM/yyyy'}}</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;">{{com.Usuario}}</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;" [innerHTML]="getItem(com.Comentario)"></div>
                  </ng-container>
                </div>

                <hr />
              </ng-container>
            </div>
          </div>
          <div class="container-fluid w-100 pt-2" style="background-color: #9c9fa1;margin-bottom: 27px;" *ngIf="addAnotacao && !mostrarAnotacao">
            <div class="row">
              <div class="col-sm-12" style="text-align: left;">

                <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                <ngx-editor [editor]="editor" height="600px" style="height:600px;" [(ngModel)]="anotacaoEdicao.Anotacao"
                            [disabled]="false" [placeholder]="'Anotações'"></ngx-editor>
              </div>
            </div>

            <div class="input-group mb-3 mt-3 justify-content-end">

              <button (click)="onClosedisplayAnotacao()" id="btnCancelarExcluirEntidade" type="button"
                      class="btn btn-form " title="Cancelar" data-dismiss="modal">
                Cancelar <i class="fas fa-times"></i>
              </button>
              <button type="button" class="btn btn-form btn-primary" data-dismiss="modal" (click)="salvarJsonAnotacao()">
                Adicionar Anotação <i class="fas fa-check"></i>
              </button>
            </div>
          </div>
          <div class="container-fluid w-100 pt-2" *ngIf="addAnotacao">
            <div class="row">
              <hr />
              <ng-container *ngFor="let item of anotacaoSistemaAberto;let j = index;">
                <div class="row" style="padding-bottom: 20px;">
                  <p>
                    <b>Item:</b> {{j+1}} | <b>Id anotação:</b> {{item.IdAnotacaoPagina}} | <b>Data inclusão:</b> {{item.DataInclusao | date:'dd/MM/yyyy'}} |
                    <b>Aberto por:</b> {{item.Usuario}}
                  <span *ngIf="item?.DataAjuste"><b>Ajustado:</b>  {{item.DataAjuste | date:'dd/MM/yyyy'}}</span>
                  </p>

                  <div class="col-sm-12" *ngIf="item.LocalStorage != null">
                    <button type="button" class="btn btn-form btn-secondary" (click)="acessar(item)">acessar</button>

                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 img100" *ngIf="item.Anotacao != '<p></p>'" [innerHTML]="getItem(item.Anotacao)">

                  </div>
                </div>
                <div class="row">

                  <ng-container *ngFor="let com of item.AnotacaoPagianaComentario;let j = index;">

                    <div class="col-sm-3">&nbsp;</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;">{{com.DataInclusao  | date:'dd/MM/yyyy'}}</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;">{{com.Usuario}}</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;" [innerHTML]="getItem(com.Comentario)"></div>
                  </ng-container>
                </div>
                <div class="row" style="padding-top: 20px;">
                  <div class="col-sm-3" *ngIf="!fechados && (isQaSuperAdmin())">
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label> Responsável </mat-label>
                      <mat-select [(ngModel)]="item.UsuarioResponsavel">
                        <mat-option [value]="''">
                          Todos
                        </mat-option>
                        <mat-option *ngFor="let use of usuarios" [value]="use.valor">
                          {{ use.nome }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field> -
                    <button type="button" class="btn btn-form btn-danger" *ngIf="!fechados && isQaSuperAdmin()" (click)="atribuirAnotacao(item)">atribuir</button>
                  </div>

                  <div class="input-group mb-3 mt-3 justify-content-end" style="text-align:right">
                    <!--<button type="button" class="btn btn-form btn-success" *ngIf="isQaAdmin()" (click)="fecharAnotacao(item,j,1)">liberar ajuste</button>--> -
                    <button type="button" class="btn btn-form btn-success" *ngIf="!fechados && isQaSuperAdmin()" (click)="fecharAnotacao(item,j,1)">fechar ajuste</button> -

                  </div>
                </div>
                <hr />
              </ng-container>

            </div>

          </div>
          <div class="container-fluid w-100 pt-2" *ngIf="!addAnotacao">
            <div class="row" style="padding-bottom: 20px;">

              <div class="col-sm-12" style="background-color:beige" *ngIf="!fechados">
                Qtde Itens em aberto:{{anotacaoSistema.length}}

              </div>
            </div>
            <div class="row">

              <ng-container *ngFor="let item of anotacaoSistema;let j = index;">
                <p>
                  Item:{{j+1}} | Pagina: {{item.Pagina.Nome}} | Id anotação: {{item.IdAnotacaoPagina}} |
                  Data inclusão: {{item.DataInclusao | date:'dd/MM/yyyy'}} | Aberto por: {{item.Usuario}}
                  <span *ngIf="item?.DataAjuste"><b>Ajustado:</b>  {{item.DataAjuste | date:'dd/MM/yyyy'}}</span>

                </p>
                <div class="row" style="padding-bottom: 20px;">

                  <div class="col-sm-12" *ngIf="item.LocalStorage != null">
                    <button type="button" class="btn btn-form btn-secondary" (click)="acessar(item)">acessar</button>

                  </div>
                </div>


                <div class="col-sm-12 img100" *ngIf="item.Anotacao != '<p></p>'" [innerHTML]="getItem(item.Anotacao)">

                </div>
                <div class="row">

                  <ng-container *ngFor="let com of item.AnotacaoPagianaComentario;let j = index;">

                    <div class="col-sm-3">&nbsp;</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;">{{com.DataInclusao  | date:'dd/MM/yyyy'}}</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;">{{com.Usuario}}</div>
                    <div class="col-sm-3" style="background-color: darkblue;color: white;font-weight: 500;padding: 6px;" [innerHTML]="getItem(com.Comentario)"></div>
                  </ng-container>
                </div>
                <div class="col-sm-6">
                  <!--<button type="button" class="btn btn-form btn-success" *ngIf="isQaAdmin()" (click)="fecharAnotacao(item,j,2)">liberar ajuste</button>--> &nbsp;
                  <button type="button" class="btn btn-form btn-success" *ngIf="!fechados && isQaSuperAdmin()" (click)="fecharAnotacao(item,j,2)">fechar ajuste</button> &nbsp;
                  <button type="button" class="btn btn-form btn-danger" *ngIf="!fechados && isQADelete()" (click)="deletar(item,j)">deletar</button>
                </div>
                <div class="col-sm-3" *ngIf="isQaSuperAdmin() ||isQaPo() ||  isQaDev()">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label> Responsável </mat-label>
                    <mat-select [(ngModel)]="item.UsuarioResponsavel" [disabled]="isQaPo() ||  isQaDev()">
                      <mat-option [value]="''">
                        Todos
                      </mat-option>
                      <mat-option *ngFor="let use of usuarios" [value]="use.valor">
                        {{ use.nome }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -
                  <button type="button" class="btn btn-form btn-danger" *ngIf="!fechados && isQaSuperAdmin()" (click)="atribuirAnotacao(item)">atribuir</button>
                </div>
                <div class="col-sm-1" *ngIf="isQaSuperAdmin() ||  isQaPo() ||  isQaDev()">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Prioridade</mat-label>
                    <input matInput type="text" class="col-lg-12" [disabled]="isQaPo() ||  isQaDev()" [(ngModel)]="item.Prioridade" placeholder="Prioridade">

                  </mat-form-field>
                </div>
                <div class="col-sm-1" *ngIf="isQaSuperAdmin()  || isQaPo() ||  isQaDev() ">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>SLA</mat-label>
                    <input matInput type="text" class="col-lg-12" [disabled]="isQaPo() ||  isQaDev()" [(ngModel)]="item.SLA" placeholder="SLA">

                  </mat-form-field>
                </div>
                <hr />
              </ng-container>

            </div>

          </div>
        </ng-container>
      </div>
    </div>
  </div>



  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayComponent}">
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="width: 1300px; right: 20px; left: -400px;">
        <div class="modal-header">
          <h5 class="label" id="excluiEntidade">Configurações</h5>

          <button id="btnFechaModalJson" style="width:50px" (click)="onClosedisplayConfiguracao()" type="button"
                  class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="container-fluid w-100 pt-2" *ngIf="iten">

          <ng-container *ngIf="iten.tipo=='fullbanner'">
            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Imagem de fundo</mat-label>
                  <input matInput type="text" [(ngModel)]="iten.srcImage">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-label>Contém texto?</mat-label>
                <mat-radio-group [(ngModel)]="iten.contentText">
                  <mat-radio-button [value]="true">Sim</mat-radio-button>
                  <mat-radio-button [value]="false">Não</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="row" *ngIf="iten.contentText">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Classe texto</mat-label>
                  <input matInput type="text" [(ngModel)]="iten.textClass">
                </mat-form-field>
              </div>
            </div>
            <div class="row" *ngIf="iten.contentText">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>H1</mat-label>
                  <input matInput type="text" [(ngModel)]="iten.h1">
                </mat-form-field>
              </div>
            </div>
            <div class="row" *ngIf="iten.contentText">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Texto (html)</mat-label>
                  <input matInput type="text" [(ngModel)]="iten.p">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-label>Contém botão?</mat-label>
                <mat-radio-group [(ngModel)]="iten.btn">
                  <mat-radio-button [value]="true">Sim</mat-radio-button>
                  <mat-radio-button [value]="false">Não</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-label>Tipo botão?</mat-label>
                <mat-radio-group [(ngModel)]="iten.btnTipo">
                  <mat-radio-button [value]="'button'">button</mat-radio-button>
                  <mat-radio-button [value]="'aLink'">link</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="row" *ngIf="iten.btn">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Texto botão</mat-label>
                  <input matInput type="text" [(ngModel)]="iten.txtBtn">
                </mat-form-field>
              </div>
            </div>
            <div class="row" *ngIf="iten.btn">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Url botão</mat-label>
                  <input matInput type="text" [(ngModel)]="iten.btnLink">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-label>é carrossel</mat-label>
                <mat-radio-group [(ngModel)]="iten.carrossel">
                  <mat-radio-button [value]="true">Sim</mat-radio-button>
                  <mat-radio-button [value]="false">Não</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="row" *ngIf="displayComponent">
              <div class="col-12">
                <app-pagina [iten]="iten"></app-pagina>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="iten.tipo=='cardSequencial'">
            <div class="row">
              <div class="col-4">
                <mat-form-field>
                  <mat-label>Cor fundo</mat-label>
                  <mat-select [(ngModel)]="iten.style" style="z-index: 99999999;">
                    <mat-option value="background-color: var(--color1)"
                                style="background-color:var(--color1)">--color1</mat-option>
                    <mat-option value="background-color: var(--color2)"
                                style="background-color:var(--color2)">--color2</mat-option>
                    <mat-option value="background-color: var(--color3)"
                                style="background-color:var(--color3)">--color3</mat-option>
                    <mat-option value="background-color: var(--color4)"
                                style="background-color:var(--color4)">--color4</mat-option>
                    <mat-option value="background-color: var(--color5)"
                                style="background-color:var(--color5)">--color5</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-8">
                <button type="button" class="btn btn-form  btn-success" (click)="novoCard()">Novo Card</button>
              </div>
            </div>
            <ng-container *ngIf="editandoCard || novoCard">
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Largura</mat-label>
                    <input matInput type="text" [(ngModel)]="novoCardSequencial.col">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Titulo</mat-label>
                    <input matInput type="text" [(ngModel)]="novoCardSequencial.titulo">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>icon</mat-label>
                    <input matInput type="text" [(ngModel)]="novoCardSequencial.icon">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>descricao</mat-label>
                    <input matInput type="text" [(ngModel)]="novoCardSequencial.descricao">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12" style="
                text-align: right;
                margin-bottom: 50px;
            ">
                  <button type="button" class="btn btn-form  btn-success" *ngIf="novoCard">Adicionar</button>
                  <button type="button" class="btn btn-form  btn-success" *ngIf="editandoCard">Salvar</button>
                  <button type="button" class="btn btn-form  btn-cancel" *ngIf="editandoCard || novoCard">Cancelar</button>

                </div>
              </div>
            </ng-container>
            <div class="row">
              <div class="col-1">
                Largura
              </div>
              <div class="col-3">
                Titulo
              </div>
              <div class="col-3">
                Icone
              </div>
              <div class="col-4">
                Descricao
              </div>
              <div class="col-1">

              </div>
            </div>
            <ng-container *ngFor="let iten of iten.itens; let j = index;">


              <div class="row">
                <div class="col-1">
                  {{iten.col}}
                </div>
                <div class="col-3">
                  {{iten.titulo}}
                </div>
                <div class="col-3">
                  {{iten.icon}}
                </div>
                <div class="col-4">
                  {{iten.descricao}}
                </div>
                <div class="col-1">
                  <mat-icon (click)="editCard(iten)">edit</mat-icon>
                </div>
              </div>
            </ng-container>
            <div class="row" *ngIf="displayComponent">
              <div class="col-12">
                <app-pagina [iten]="iten"></app-pagina>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="iten.tipo == 'encontreServicos'">
            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Titulo</mat-label>
                  <input matInput type="text" [(ngModel)]="iten.titulo">
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="iten.tipo == 'login'">
            {{iten |json}}
          </ng-container>
          <ng-container *ngIf="iten.tipo == 'labelDescricao'">
            {{iten |json}}
          </ng-container>
          <ng-container *ngIf="iten.tipo == 'cardSequencial'">
            {{iten |json}}
          </ng-container>
          <ng-container *ngIf="iten.tipo == 'galeriaImg'">
            {{iten |json}}
          </ng-container>
          <ng-container *ngIf="iten.tipo == 'conteudo'">
            <ng-container *ngIf="iten.tipoBloco == 'txtimgleft'">
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Imagem esquerda</mat-label>
                    <input matInput type="text" [(ngModel)]="iten.itens[0].src">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Titulo(alt) Imagem esquerda</mat-label>
                    <input matInput type="text" [(ngModel)]="iten.itens[0].alt">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Titulo</mat-label>
                    <input matInput type="text" [(ngModel)]="iten.itens[1].h2">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Sub-Titulo</mat-label>
                    <input matInput type="text" [(ngModel)]="iten.itens[1].subTitulo">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>texto</mat-label>
                    <textarea matInput [(ngModel)]="iten.itens[1].texto" cols="20" rows="8"></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>texto botão</mat-label>
                    <input matInput type="text" [(ngModel)]="iten.itens[1].btn.texto">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>link botao</mat-label>
                    <input matInput type="text" [(ngModel)]="iten.itens[1].btn.link">
                  </mat-form-field>
                </div>
              </div>
              <div class="row" *ngIf="displayComponent">
                <div class="col-12">
                  <app-pagina [iten]="iten"></app-pagina>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="iten.tipo == 'contato'">
            {{iten |json}}
          </ng-container>

          <ng-container *ngIf="iten.tipo == 'conteudob'">
            {{iten |json}}
          </ng-container>

          <ng-container *ngIf="iten.tipo == 'banner'">
            {{iten |json}}
          </ng-container>
          <ng-container *ngIf="iten.tipo == 'cardHorizontal'">
            {{iten |json}}
          </ng-container>
          <ng-container *ngIf="iten.tipo == 'consulta'">
            {{iten |json}}
          </ng-container>
          <ng-container *ngIf="iten.tipo == 'texto-institucional'">
            {{iten |json}}
          </ng-container>
          <ng-container *ngIf="iten.tipo == 'ajuda'">
            {{iten |json}}
          </ng-container>
          <ng-container *ngIf="iten.tipo != 'conteudo'
                        && iten.tipo != 'login'
                        && iten.tipo != 'labelDescricao'
                        && iten.tipo != 'cardSequencial'
                        && iten.tipo != 'fullbanner'
                        && iten.tipo != 'galeriaImg'
                        && iten.tipo != 'banner'
                        && iten.tipo != 'cardHorizontal'
                        && iten.tipo != 'contato'
                        ">
            {{iten |json}}
          </ng-container>
          <div class="input-group mb-3 mt-3 justify-content-end">

            <button (click)="onClosedisplayConfiguracao()" id="btnCancelarExcluirEntidade" type="button"
                    class="btn btn-form " title="Cancelar" data-dismiss="modal">
              Cancelar <i class="fas fa-times"></i>
            </button>
            <button type="button" class="btn btn-form btn-success" data-dismiss="modal"
                    (click)="salvarAlteracaoComponent()">
              Alterar Configurações <i class="fas fa-check"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>
