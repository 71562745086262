import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lista-mensagens-exigencia',
  templateUrl: './listaMensagensExigencia.component.html',
  styleUrls: ['./../../../../_meus-pedidos.component.scss']
})
export class ListaMensagensExigenciaComponent implements OnInit {
  @Input() mensagens: any[];

  constructor() { }

  ngOnInit(): void {
  }
}
