import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  conteudo: any
  @Input() projeto: any;

  ngOnInit() {
    if (
      typeof this.projeto.JsonConfiguracao === 'object'
    ) {
      this.conteudo = this.projeto.JsonConfiguracao

    } else {
      this.conteudo = JSON.parse(this.projeto.JsonConfiguracao)

    }

  }

}
