import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocalStorage } from '../../_entidade/LocalStorage';
import { CrudService } from '../../_sevice/CrudService';
import { debounce } from 'lodash';
import { environment } from 'src/environments/environment';
import { produtosLimpos } from 'src/app/_configPage/listas/prdutosCNR';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchBarComponent implements OnInit {
  @Input() class: any;
  @Input() conteudo: any;
  formBusca = new FormGroup({
    palavraPesquisada: new FormControl('', Validators.required)
  });
  load: any
  itens: any
  @Output() searchQuery = new EventEmitter<string>();
  @Output() openModalBuscaEvent = new EventEmitter();
  searchResults: any;

  onInputChange(event: any): void {
    const query = event?.target?.value;

    // Emitir evento apenas se a string de pesquisa não estiver vazia
    if (query !== null && query !== undefined) {
      this.getProdutos(query)

    }
  }

  constructor(
    public storage: LocalStorage,
    private route: Router,
    private cdRef: ChangeDetectorRef,
    public service: CrudService,
    public device: DeviceDetectorService
  ) {
    this.onInputChange = debounce(this.onInputChange, 500)

  }



  ngOnInit(): void {

  }
  solicitarNovo(item: any) {

    this.storage.localStorageRemove("kit");

    this.storage.localStorageRemove("item_atual")


    let novoItem = produtosLimpos.find((x: any) => x.aLink == '/pedido'+item.aLink)

    if (novoItem != null && novoItem != undefined) {

        this.storage.localStorageSet('item_atual', novoItem)
      this.route.navigate(['/pedido' +item.aLink])

    }

  }

  public isMobile(): boolean {
    return this.device.isMobile();
  }

  openModalBuscaClick() {
    if (this.device.isMobile())
      this.openModalBuscaEvent.emit();
  }

  loadProdutos() {


    this.searchResults = [
      {
        "titulo": "buscando...",
        "aLink": "",
        "descricao": "buscando...",
        "idAto": 0,
        "idTipoAto": 0,
        "descricaoTipoAto": "0",
        "origem": ""
      }
    ]

  }

  getProdutos(query: any) {

    this.load = false
    if (query) {


      this.service.getObservableDirect(environment.http.API_BACK + 'Consulta/Produto?origem=CNR&busca=' + query).subscribe({
        next: async (response: any) => {
          this.searchResults = response
          if (response.length == 0) {
            this.searchResults = [
              {
                "titulo": "não encontramos itens com o valor pesquisado",
                "aLink": "",
                "descricao": "não encontramos itens com o valor pesquisado",
                "idAto": 0,
                "idTipoAto": 0,
                "descricaoTipoAto": "0",
                "origem": ""
              }
            ]
          }

        },
        error: (err) => {

          this.searchResults = [
            {
              "titulo": "não encontramos itens com o valor pesquisado",
              "aLink": "",
              "descricao": "não encontramos itens com o valor pesquisado",
              "idAto": 0,
              "idTipoAto": 0,
              "descricaoTipoAto": "0",
              "origem": ""
            }
          ]
        }
      })
    } else {
      this.searchResults = []
    }
  }


}
