<div class="container my-4">
  <form [formGroup]="formAgendamento" (submit)="enviar()">

    <span class="m-2 title-form">Reunião</span>
    <div class="d-flex flex-column content-list m-2">
      <div class="m-1">
        <mat-form-field class="w-100">
          <mat-label for="dataInicial">Titulo Reuniao</mat-label>
          <input matInput type="text" formControlName="titulo" />
          <mat-error> Preenchimento obrigatório</mat-error>
        </mat-form-field>
      </div>

      <div class="time-reuniao d-flex justify-content-between m-1">
        <mat-form-field>
          <mat-label for="dataInicial">Data Reuniao</mat-label>
          <input matInput [matDatepicker]="picker" [min]="dataAtual" type="datetime" formControlName="dataReuniao"
                 appMaskDate maxlength="10">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error> Preenchimento obrigatório</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label for="dataInicial">Hora inicial</mat-label>
          <input matInput type="time" formControlName="timeInicial" min="08:00" max="18:00" />
          <mat-error> Preenchimento obrigatório</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label for="dataInicial">Hora final</mat-label>
          <input matInput type="time" formControlName="timeFinal" min="08:00" max="18:00" />
          <mat-error> Preenchimento obrigatório</mat-error>
        </mat-form-field>
      </div>

    </div>
    <br />

    <span class="m-2 title-form">Anfitriões</span>
    <div class="content-list m-2" formArrayName="anfitrioes">
      <div class="participantes-list"
           *ngFor="let anfitriao of anfitrioes.controls; let i = index, let last=last" [formGroupName]="i">
        <div class="d-flex">
          <mat-form-field class="w-50 m-2">
            <mat-label for="cnpjCpf">CNPJ/CPF</mat-label>
            <input matInput mask="000.000.000-00||00.000.000/0000-00" type="text" formControlName="cnpjCpf" />
            <mat-error> Preenchimento obrigatório</mat-error>
          </mat-form-field>

          <mat-form-field class="w-50 m-2">
            <mat-label for="nome">Nome</mat-label>
            <input matInput type="text" formControlName="nome" />
            <mat-error> Preenchimento obrigatório</mat-error>
          </mat-form-field>

          <mat-form-field class="w-50 m-2">
            <mat-label for="email">Email</mat-label>
            <input matInput type="text" (focus)="setOldValue($event)" (blur)="buscarKey($event,i,'anfitrioes')" formControlName="email" />
            <mat-error> Preenchimento obrigatório</mat-error>
          </mat-form-field>

          <mat-form-field class="w-50 m-2">
            <mat-label for="telefone">Telefone</mat-label>
            <input matInput type="text" mask="(00) 00000-0000" formControlName="telefone" />
            <mat-error> Preenchimento obrigatório</mat-error>
          </mat-form-field>

          <div class="d-flex" *ngIf="!last || i > 0">
            <button class="w-100 my-2 add-new-participante" type="button" (click)="remAnfitriao(i)">
              Remover
            </button>
          </div>
        </div>

        <div>
          <div class="d-flex justify-content-end" *ngIf="last">
              <button class="w-50 my-2 add-new-participante" type="button" (click)="addAnfitriao()">
                  Adicionar
              </button>
          </div>
      </div>
      </div>
    </div>
    <br />
    <span class="m-2 title-form">Mediadores</span>
    <div class="content-list m-2" formArrayName="mediadores">
      <div class="d-flex">
        <mat-checkbox class="example-margin" name="copiarDados" id="copiarDados" (change)="copiarDadosAnfitriao($event)">

          <span *ngIf="anfitrioes.controls.length ==1">Copiar dados do Anfitrião</span>
          <span *ngIf="anfitrioes.controls.length >1">Copiar dados dos Anfitriões</span>
        </mat-checkbox>
      </div>
      <div class="participantes-list" *ngFor="let anfitriao of mediadores.controls; let i = index, let last=last" [formGroupName]="i">
        <div class="d-flex">

          <mat-form-field class="w-50 m-2">
            <mat-label for="cnpjCpf">CNPJ/CPF</mat-label>
            <input matInput mask="000.000.000-00||00.000.000/0000-00" type="text" formControlName="cnpjCpf" />
            <mat-error> Preenchimento obrigatório</mat-error>
          </mat-form-field>

          <mat-form-field class="w-50 m-2">
            <mat-label for="nome">Nome</mat-label>
            <input matInput type="text" formControlName="nome" />
            <mat-error> Preenchimento obrigatório</mat-error>
          </mat-form-field>

          <mat-form-field class="w-50 m-2">
            <mat-label for="email">Email</mat-label>
            <input matInput type="text" (focus)="setOldValue($event)" (blur)="buscarKey($event,i,'mediadores')" formControlName="email" />
            <mat-error> Preenchimento obrigatório</mat-error>
          </mat-form-field>

          <mat-form-field class="w-50 m-2">
            <mat-label for="telefone">Telefone</mat-label>
            <input matInput type="text" mask="(00) 00000-0000" formControlName="telefone" />
            <mat-error> Preenchimento obrigatório</mat-error>
          </mat-form-field>

          <div class="d-flex" *ngIf="!last || i > 0">
            <button class="w-100 my-2 add-new-participante" type="button" (click)="remMediador(i)">
              Remover
            </button>
          </div>
        </div>

        <div>
          <div class="d-flex justify-content-end" *ngIf="last">
            <button class="w-50 my-2 add-new-participante" type="button" (click)="addMediador()">
              Adicionar
            </button>
          </div>
        </div>
      </div>
    </div>
    <br />
    <span class="m-2 title-form">Participantes</span>
    <div class="content-list m-2" formArrayName="participantes">
      <div class="participantes-list"
           *ngFor="let participante of participantes.controls; let i = index, let last=last" [formGroupName]="i">
        <div class="d-flex">
          <mat-form-field class="w-50 m-2">
            <mat-label for="cnpjCpf">CNPJ/CPF</mat-label>
            <input matInput mask="000.000.000-00||00.000.000/0000-00" type="text" formControlName="cnpjCpf" />
            <mat-error> Preenchimento obrigatório</mat-error>
          </mat-form-field>

          <mat-form-field class="w-50 m-2">
            <mat-label for="dataInicial">Nome</mat-label>
            <input matInput type="text" formControlName="nome" />
            <mat-error> Preenchimento obrigatório</mat-error>
          </mat-form-field>

          <mat-form-field class="w-50 m-2">
            <mat-label for="dataInicial">Email</mat-label>
            <input matInput type="text" (focus)="setOldValue($event)" (blur)="buscarKey($event,i,'participantes')" formControlName="email" />
            <mat-error> Preenchimento obrigatório</mat-error>
          </mat-form-field>

          <mat-form-field class="w-50 m-2">
            <mat-label for="telefone">Telefone</mat-label>
            <input matInput type="text" mask="(00) 00000-0000" formControlName="telefone" />
            <mat-error> Preenchimento obrigatório</mat-error>
          </mat-form-field>

          <div class="d-flex" *ngIf="!last || i > 0">
            <button class="w-100 my-2 add-new-participante" type="button" (click)="remParticipante(i)">
              Remover
            </button>
          </div>
        </div>

        <div>
          <div class="d-flex justify-content-end" *ngIf="last">
            <button class="w-50 my-2 add-new-participante" type="button" (click)="addParticipante()">
              Adicionar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="my-3 d-flex justify-content-end">
      <input type="submit" class="btnSubmit btn w-25 btn-primary btn-sm" value="Enviar" />
    </div>
  </form>
</div>
