import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormControl, FormBuilder } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { LocalStorage } from 'src/app/_entidade/LocalStorage';
import { CrudService } from 'src/app/_sevice/CrudService';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { debounce } from 'lodash';
export class DateValidator {
  static ptDate(control: FormControl): { [key: string]: any } {
    let ptDatePattern = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;
    if (!control.value.match(ptDatePattern))
      return { "ptDate": true };
    return { "ptDate": false };
  }
}
@Component({
  selector: 'app-meus-curriculos',
  templateUrl: './meus-curriculos.component.html',
  styleUrls: ['./_meus-curriculos.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MeusCurriculosComponent implements OnInit {
  @Input() conteudo: any;
  showMaisFiltro: boolean = false;
  paginaAtual: number = 1;
  tamanhoPagina: number = 10;
  totalItens: number = 0;
  pedidos: any;
  isLinear = true;
  panelOpenState = false;
  //public visibilidadeDetalhes: boolean = false;
  show: boolean = false;
  showDadosFaturamento: boolean = false;
  semRequerente: boolean = true;
  camposForm: any;
  currentDate = new Date();
  lastMonthDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
  erroBusca: any = false;
  pipe = new DatePipe('pt-BR');
  isBack = this.storage.localStorageGet('BackOffice');
  isSuperAdm = this.storage.localStorageGet('SuperAdm');
  constructor(
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public teste: TesteService,
    private route: Router,
    public service: CrudService,
  ) {
    this.pesquisar = debounce(this.pesquisar, 900);
  }
  ngOnInit(): void {
    this.criarForm();
    this.pesquisar();
  }
  criarForm() {
    this.camposForm = this.formBuilder.group({
      dataInicial: [this.lastMonthDate],
      dataFinal: [this.currentDate],
      numeroPedido: [''],
      formacaoAcademica: [''],
      objetivo: [''],
      selo: [''],
      tipoStatus: ['']
    });
  }
  isUUID(uuid: any) {
    let s = uuid;
    s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (s === null) {
      return false;
    }
    return true;
  }
  alterarPagina(event: PageEvent) {
    const novaPagina = event.pageIndex + 1;
    const novoTamanhoPagina = event.pageSize;
    this.paginaAtual = novaPagina;
    this.tamanhoPagina = novoTamanhoPagina;
    this.pesquisar();
  }
  pesquisar() {
    let objsend = this.camposForm.value;
    
    objsend['pagina'] = this.paginaAtual;
    objsend['tamanhoPagina'] = this.tamanhoPagina;
    var base64 = btoa(JSON.stringify(objsend));
    let endPoint = this.isBack == null ? 'Curriculo/MeuCurriculo' : 'Curriculo/GetBackoffice';
    const url = `${environment.http.API}${endPoint}`;
    this.erroBusca = false;
    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";
    this.service.postObservableDirect(url, objsend).subscribe({
      next: async (response) => {
        //console.log(response)
        if (response != null) {
          this.pedidos = response.Dados;
          console.log(response.Dados);
          this.totalItens = response.Tamanho;
          let el = document.getElementById('topo');
          el?.scrollIntoView();
          //console.log('pedidos = ', this.pedidos);
        }
      }, error: (err) => {
        console.error('Erro ao buscar detalhes do pedido:', err);
        if (err?.status == 401)
          this.route.navigate(['/login/entrar?logoff=1']);
        else
          this.erroBusca = true;
        //this.route.navigate(['/login/entrar?logoff=1'])
      }
    });
  }
  arquivosAta: any
  setaArquivos(arquivos: any) {
       this.arquivosAta = arquivos;
    //this.camposForm.controls.arquivos.setValue(arquivos);
    //console.log(this.camposForm.value);
  }
  faltaArquivo=false;
    resetaArquivos(arquivos: any) {
    this.arquivosAta = arquivos;
  }
  atualizaPedido(entrada: any) {
    let url = environment.http.API + 'MeetingAccess';
    let tempId = entrada["_id"];
    delete entrada["_id"];
    this.service.putObservableDirect(url, entrada).subscribe({
      next: async (response) => {
        let nepd = response;
        nepd["_id"] = tempId;
        entrada = response;
      }, error: (err) => {
        //console.error(err);
      }
    });
  }
  posicaoMeetEdicao: any = 0;
  togglePanel(index: any) {
    this.panelOpenState = !this.panelOpenState;
  }
  ocultarDetalhes(item: any, i: any) {
    item['verDetalhes'] = false;
    //console.log('ocultar')
  }
  verPanelOpenState(item: any, index: any) {
    item.panelOpenState = !item.panelOpenState;
  }
  verDetalhesPedido(item: any, i: any) {
    //console.log('ver')
    item['verDetalhes'] = true;
  }
  print: any = false;
  preparaPrint(display: any, j: any) {
    this.print = true;
    (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%";
    (<HTMLInputElement>document.getElementById("titleMeusPedidos")).style.display = display;
    (<HTMLInputElement>document.getElementById("header")).style.display = display;
    (<HTMLInputElement>document.getElementById("footer")).style.display = display;
    (<HTMLInputElement>document.getElementById("filtro")).style.display = display;
    (<HTMLInputElement>document.getElementById("paginacaoItens")).style.display = display;
    (<HTMLInputElement>document.getElementById("logoPrint")).style.display = '';
    (<NodeListOf<HTMLElement>>document.getElementsByName("opcaoPedido")).forEach(e => e.style.display = display);
    (<NodeListOf<HTMLElement>>document.getElementsByName("btnDetalhes")).forEach(e => e.style.display = display);
    (<HTMLInputElement>document.getElementById("conpr")).classList.remove('container');
    for (var i = 0; i < this.pedidos?.length; i++) {
      if (i != j) {
        (<HTMLInputElement>document.getElementById(i + "p")).style.display = display;
      }
    }
    let leng = this.pedidos?.length;
    window.onafterprint = function () {
      (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%";
      (<HTMLInputElement>document.getElementById("logoPrint")).style.display = 'none';
      (<HTMLInputElement>document.getElementById("titleMeusPedidos")).style.display = '';
      (<HTMLInputElement>document.getElementById("header")).style.display = '';
      (<HTMLInputElement>document.getElementById("footer")).style.display = '';
      (<HTMLInputElement>document.getElementById("filtro")).style.display = '';
      (<HTMLInputElement>document.getElementById("paginacaoItens")).style.display = '';
      (<NodeListOf<HTMLElement>>document.getElementsByName("opcaoPedido")).forEach(e => e.style.display = '');
      (<NodeListOf<HTMLElement>>document.getElementsByName("btnDetalhes")).forEach(e => e.style.display = '');
      (<HTMLInputElement>document.getElementById("conpr")).classList.add('container');
      for (var i = 0; i < leng; i++) {
        if (i != j) {
          (<HTMLInputElement>document.getElementById(i + "p")).style.display = '';
        }
      }
    }
    if (display != '')
      window.print();
  }
  cancelarEdicao(itemAtual: any) {
    delete itemAtual['editar'];
  }
  idItemEdicao: any;
  editarAto(itemAtual: any, pedido: any, posicao: any) {
    //console.log(itemAtual)
    for (var i = 0; i < pedido?.listaCompra?.length; i++) {
      if (i != posicao) {
        delete pedido?.listaCompra[i]['editar'];
      }
    }
    itemAtual['editar'] = true;
    this.idItemEdicao = itemAtual?.idItem;
  }
  updatePedido(item: any, pedido: any, posicao: any) {
    //console.log('dfdf')
    let url = environment.http.API + 'Venda';
    let tempId = { ...pedido["_id"] };
    delete pedido.listaCompra[posicao]["editar"];
    delete item["editar"];
    for (const key in item) {
      pedido.listaCompra[posicao][key] = item[key];
    }
    delete pedido["_id"];
    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";
    this.service.putObservableDirect(url, pedido).subscribe({
      next: async (response) => {
        let nepd = response;
        nepd["_id"] = tempId;
        pedido.editar = false;
        let urlBack = environment.http.API_BACK + 'Empresarial/AlterarPedido?idCerp=' + this.idItemEdicao;
        //console.log('urlBack', urlBack)
        this.service.postObservableDirect(urlBack, nepd).subscribe({
          next: async (responseBack) => {
            //this.route.navigate(['pedido/pagar'])
          }, error: (err) => {
            //console.error(err);
          }
        });
      }, error: (err) => {
        //console.error(err);
      }
    });
  }
  getdate(dataenviada: any) {
    let dttratada = dataenviada?.substr(0, 10).replace('T00:00:00Z', '').split('-');
    return new Date(dttratada[0], dttratada[1] - 1, dttratada[2]);
  }
}
function ngxPrint(elementToPrint: HTMLElement, printOptions: {
  printMode: string; // Pode ser 'iframe' ou 'popup'
  bodyClass: string; // Classe CSS a ser adicionada ao corpo ao imprimir
  standard: string; // Pode ser 'html5', 'iframe' ou 'popup'
}) {
  throw new Error('Function not implemented.');
}
