<h2>CADASTRAR</h2>


<form (ngSubmit)="salvar()" [formGroup]="formCadastro">
  <div class="col-12">
    <mat-form-field appearance="outline" class="w-100 my-2">
      <mat-label>Nome</mat-label>
      <input matInput type="text" formControlName="nome" [(ngModel)]="usuario.Nome" placeholder="Digite seu nome"
             required>
      <mat-error *ngIf="f.nome.errors?.['required']">
        Preenchimento obrigatório
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-12">
    <mat-form-field appearance="outline" class="w-100 my-2">
      <mat-label>Login</mat-label>
      <input matInput type="text" formControlName="login" [(ngModel)]="usuario.Login" placeholder="Digite seu login"
             required>
      <mat-error *ngIf="f.login.errors?.['required']">
        Preenchimento obrigatório
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-12">
    <mat-form-field appearance="outline" class="w-100 my-2" *ngIf="!edicao">
      <mat-label>Senha</mat-label>
      <input matInput [type]="hidePassword1 ? 'password' : 'text'" formControlName="senha"
             placeholder="Digite sua senha" [(ngModel)]="usuario.Senha" required>
      <button class="border-0 mx-3 rounded-5" mat-icon-button matSuffix type="button"
              (click)="togglePasswordVisibility(1)">
        <mat-icon>{{ hidePassword1 ? 'remove_red_eye' : 'visibility_off' }}</mat-icon>
      </button>
      <mat-error *ngIf="f.senha.errors?.['required']">
        Preenchimento obrigatório
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-12">
    <mat-checkbox class="col-12" *ngFor="let option of usuario.Perfis" [value]="option">{{option.Descricao}}</mat-checkbox>
  </div>
  <div class="col-12">
    <button mat-raised-button color="primary" class="mr-5">Salvar</button>
  </div>
  <div class="col-12">
    <mat-form-field appearance="outline" class="w-100 my-2" *ngIf="false">
      <mat-label>Confirmar senha</mat-label>
      <input matInput [type]="hidePassword2 ? 'password' : 'text'" formControlName="passwordConfirm"
             [(ngModel)]="usuario.passwordConfirm" (blur)="validaConfirmacao()" placeholder="Confirmar sua senha"
             required>
      <mat-error *ngIf="f.passwordConfirm.errors?.['required']">
        Preenchimento obrigatório
      </mat-error>
      <mat-error *ngIf="f.passwordConfirm.hasError('erroConfirmarSenha')">
        {{erroConfirmarSenha}}
      </mat-error>
      <button class="border-0 mx-3 rounded-5" mat-icon-button matSuffix type="button"
              (click)="togglePasswordVisibility(2)">
        <mat-icon>{{ hidePassword2 ? 'remove_red_eye' : 'visibility_off' }}</mat-icon>
      </button>
    </mat-form-field>
  </div>
</form>

