import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { lista } from 'src/app/_configPage/listas/forms';

@Component({
  selector: 'app-meusPedidosOrderDetails-15',
  templateUrl: './meusPedidosOrderDetail15.component.html',
  styleUrls: ['./../_meus-pedidos.component.scss']
})
export class MeusPedidosOrderDetails15Component implements OnInit  {
  @Input() compra: any

  public visibilidadeDetalhes: boolean=false;
  itemDetalhes :any
  
  @Output() updatePedido = new EventEmitter<any>();

  ngOnInit(): void {
    this.itemDetalhes = lista.find((x:any) => x.idAto == this.compra.produto.idAto)
  }

  cancelarEdicao(compra:any) {
    compra.editar = false;
  }

  salvarItem(itemPedido: any) {     
    this.updatePedido.emit(itemPedido)
  }
}
