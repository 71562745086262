<!--{{cartorioEmissor |json}}---->
<app-form-cartorio [uf]="uf"
                   [cidade]="cidade"
                   (setTemCoberturaFilho)="setTemCoberturaFilho($event)"
                   (setCartorios)="setCartorios($event)"
                   [cartorioEmissor]="cartorioEmissor"
                   [itemAtual]="itemAtual"
                   [multiplo]="true"
                   [classObrigatoria]="classObrigatoria"></app-form-cartorio>
<ng-container *ngIf="temCobertura || edicao">

  <ng-container *ngIf="temCobertura || edicao">

    <form [formGroup]="orderDetails">

      <div class="col-md-12">
        <div class="content-list" formArrayName="compradores">
          <div *ngFor="let comprador of compradores.controls; let i = index, let last=last" [formGroupName]="i" class="row" style="align-items: end;">
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Nome da Busca</mat-label>
                <input matInput type="text" class="col-lg-12" formControlName="nome" placeholder="Nome que Constará na Certidão" maxlength="200">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label for="documento">CNPJ</mat-label>
                <input matInput type="text" formControlName="documento" mask="00.000.000/0000-00" required placeholder="CNPJ">
                <mat-error *ngIf=" getCompradorControl(i)?.get('documento')?.hasError('documentoDuplicado')">
                  Documento duplicado
                </mat-error>
                <mat-error *ngIf="validaCPFCNPJ(i)">
                  Documento inválido
                </mat-error>
                <mat-error *ngIf="validaRequired(i)">
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>



          </div>
        </div>
      </div>

    </form>

    <app-aviso *ngIf="!edicao" [aviso]="itemAtual?.aviso"></app-aviso>

    <div class="row">
      <div class="col-md-5"></div>
      <div class="col-auto" *ngIf="!edicao">
        <button type="button" mat-button [routerLink]="['/']" class="btn-next" style="color: white; background-color: green;">Voltar</button>
      </div>
      <div class="col-auto" *ngIf="edicao">
        <button type="button" mat-button (click)="cancelarEdicaoForm()" class="btn-next" style="color: white; background-color: green;">Cancelar</button>
      </div>
      <div class="col-auto">
        <button mat-button (click)="updatePedido()" class="btn-next"
                style="display: block; margin: 0 auto;">
                <span  *ngIf="!edicao">Continuar</span> 
                <span  *ngIf="edicao">Salvar</span> 
        </button>
      </div>
    </div>
  </ng-container>
