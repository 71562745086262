

<div class="container">
  <mat-stepper [linear]="isLinear" #stepperPrincipal [selectedIndex]="selectedIndex">
    <mat-step label="Solicitação">
      <ng-template matStepLabel>
        Solicitação
      </ng-template>

      <ng-container *ngIf="selectedIndex ==0">

        <!--<a *ngIf="tipoForm != 15" [href]="itemAtual?.linkAnterior ? itemAtual?.linkAnterior  : 'https://e-cartoriorj.com.br/Pedido/FormBusca/'+itemAtual?.idAto" target="_blank">Link antigo</a>
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo" *ngIf="abrangencia != null">
          <mat-label> Cidades Cobertas </mat-label>
          <mat-select>
            <mat-option *ngFor="let cid of abrangencia">
              {{ cid.uf }} - {{ cid.cidade }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <a *ngIf="tipoForm == 15" [href]="'https://e-cartoriorj.com.br/Pedido/FormDinamico/'+itemAtual?.idAto" target="_blank">Link antigo</a>
        <ng-container *ngIf="false">
          (mascara campo data, validar cpfs/cnpjs maxlength, validar email)
          <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
            <mat-label>Tipo</mat-label>
            <mat-select (selectionChange)="trocaform($event)" [(ngModel)]="tipoForm" [compareWith]="compareFormSelect">
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
              <mat-option value="4">4</mat-option>
              <mat-option value="5">5(add arquivos)</mat-option>
              <mat-option value="6">6</mat-option>
              <mat-option value="7">7(terminar)</mat-option>
              <mat-option value="8">8(melhorar edicao, ver carregamento dos inputs, verificar enviar correio)</mat-option>
              <mat-option value="9">9</mat-option>
              <mat-option value="10">10(verificar listas)</mat-option>
              <mat-option value="11">11(verificar listas, upload arquivo)</mat-option>
              <mat-option value="12">12(verificar listas, carregar edicao, upload arquivo)</mat-option>
              <mat-option value="13">13 (edicao ajustar, upload arquivo)</mat-option>
              <mat-option value="14">14( melorar botoes da lista,upload arquivo)</mat-option>
              <mat-option value="15">15</mat-option>
              <mat-option value="21">21  Certidão de Protesto </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>-->

        <ng-container *ngIf="!iskit">
          <div class="row">
            <div class="header-pedido">
              <div class="clo-12">
                <h2>{{itemAtual?.titulo}}</h2>
              </div>
            </div>
            <div class="clo-12 mb-4">
              <p [innerHTML]="itemAtual?.descricao">{{itemAtual?.descricao}}</p>
            </div>
            <div class="content-benefits">
              <ng-container *ngIf="showBene">
                <mat-accordion class="d-block col-lg-6 col-md-12 mb-3" *ngFor="let vantagem of vantages">
                  <mat-expansion-panel [expanded]="panelOpenState">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <div class="content-img-text d-flex gap-3 align-items-center">
                          <span class="material-symbols-outlined">
                            {{vantagem.Icone}}
                          </span>
                          <p class="text-block mb-0">{{vantagem.Titulo}}</p>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p [innerHTML]="vantagem.Descricao"></p>
                  </mat-expansion-panel>
                </mat-accordion>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="iskit">
          <div class="row">
            <div class="header-pedido">
              <div class="clo-12">
                <h2>{{kit?.titulo}}</h2>
              </div>
            </div>
            <div class="clo-12 mb-4">
              <p [innerHTML]="kit?.descricao"></p>
            </div>

          </div>
        </ng-container>
        <mat-divider class="w-100 my-4"></mat-divider>
        <form [formGroup]="localidade" *ngIf="!itemAtual?.semLocalidade">
          <div class="row">
            <div class="col-md-12">
              <div class="row d-flex">
                <!-- UF -->
                <div class="col-md-6">
                  <mat-form-field class="w-100">
                    <mat-label>Estado</mat-label>
                    <input type="text"
                           placeholder="Estado"
                           aria-label="Number"
                           matInput [readonly]="edicao || iskit"
                           formControlName="uf"
                           [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" id="autoUf" [displayWith]="displayProperty" disabled (optionSelected)="getCidade($event)">
                      <mat-option *ngFor="let item of listufFilter" [value]="item">
                        {{item.Nome }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error>
                      Preenchimento obrigatório
                    </mat-error>
                  </mat-form-field>

                </div>
                <!-- Cidade -->
                <div class="col-md-6">
                  <mat-form-field class="w-100">
                    <mat-label *ngIf="itemAtual?.titulo == 'Certidão de Busca'">Comarca</mat-label>
                    <mat-label *ngIf="itemAtual?.titulo != 'Certidão de Busca'">Cidade</mat-label>
                    <input type="text"
                           #cidade
                           id="Cidade"
                           [readonly]="edicao || iskit"
                           placeholder="Cidade"
                           aria-label="Number"
                           matInput
                           formControlName="cidade"
                           [matAutocomplete]="auto2">
                    <mat-autocomplete #auto2="matAutocomplete" id="autoCidade" [displayWith]="displayPropertyCidade" (optionSelected)="setLocal()">
                      <mat-option *ngFor="let item of listCidadeFilter" [value]="item">
                        {{item.Nome }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error>
                      Preenchimento obrigatório
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div id="topoPedido"></div>
        <ng-container *ngIf="loadComplete && iskit">
          <mat-stepper orientation="vertical" linear="true" #stepperKits [selectedIndex]="kit.index">
            <mat-step *ngFor="let item of kit.Itens;let i=index" (click)="setIndex(i)">
              <ng-template matStepLabel>
                {{item.titulo}}
                <ng-container *ngFor="let subitem of item?.itensComplementares;let j=index">
                  <br />{{subitem.titulo}}
                </ng-container>
              </ng-template>
              <ng-container *ngIf="item.idForm==1  && item?.show">
                <app-form-1 [uf]="ufSelected"
                            [iskit]="iskit"
                            [itemAtualKit]="kit?.Itens[i]"
                            [itemAtual]="itemAtual"
                            [cidade]="cidadeSelected"
                            (pularPasso)="pularPasso($event)"
                            (salvarItem)="salvaritem($event)"
                            (setTemCobertura)="setTemCobertura($event)"></app-form-1>
              </ng-container>
              <ng-container *ngIf="item.idForm==2  && item?.show">
                <app-form-2 [iskit]="iskit"
                            [itemAtualKit]="kit?.Itens[i]"
                            [uf]="ufSelected"
                            [itemAtual]="itemAtual"
                            [cidade]="cidadeSelected"
                            (salvarItem)="salvaritem($event)"
                            (setTemCobertura)="setTemCobertura($event)"></app-form-2>
              </ng-container> 
            <ng-container *ngIf="item.idForm==21  && item?.show">
              
              <app-form-21 [iskit]="iskit"
                          [itemAtualKit]="kit?.Itens[i]"
                          [uf]="ufSelected"
                          [itemAtual]="itemAtual"
                          [cidade]="cidadeSelected"
                          (salvarItem)="salvaritem($event)"
                          (setTemCobertura)="setTemCobertura($event)"></app-form-21>
            </ng-container>
              <ng-container *ngIf="item.idForm==15 && item?.show">
                <app-form-15 [itemAtualKit]="kit?.Itens[i]"
                             [itemAtual]="itemAtual"
                             (salvarItem)="salvaritem($event)"
                             (setTemCobertura)="setTemCobertura($event)"></app-form-15>                

              </ng-container>
            </mat-step>
          </mat-stepper>
        </ng-container>
        <ng-container *ngIf="!iskit && ufSelected != null && cidadeSelected != null">
          <app-form-1 *ngIf="tipoForm==1"
                      [uf]="ufSelected"
                      [itemAtual]="itemAtual"
                      [cidade]="cidadeSelected"
                      (salvarItem)="salvaritem($event)"
                      (setTemCobertura)="setTemCobertura($event)"></app-form-1>
          <app-form-2 *ngIf="tipoForm==2 && load"
                      [uf]="ufSelected"
                      [itemAtual]="itemAtual"
                      [cidade]="cidadeSelected"
                      (salvarItem)="salvaritem($event)"
                      (setTemCobertura)="setTemCobertura($event)"></app-form-2>
          <app-form-21 *ngIf="tipoForm==21"
                       [itemAtual]="itemAtual"
                       [uf]="ufSelected"
                       [cidade]="cidadeSelected"
                       (salvarItem)="salvaritem($event)"
                       (setTemCobertura)="setTemCobertura($event)"></app-form-21>
          <app-form-22 *ngIf="tipoForm==22"
                       [itemAtual]="itemAtual"
                       [uf]="ufSelected"
                       [cidade]="cidadeSelected"
                       (salvarItem)="salvaritem($event)"
                       (setTemCobertura)="setTemCobertura($event)"></app-form-22>
          <app-form-3 *ngIf="tipoForm==3"
                      [uf]="ufSelected"
                      [itemAtual]="itemAtual"
                      [cidade]="cidadeSelected"
                      (salvarItem)="salvaritem($event)"
                      (setTemCobertura)="setTemCobertura($event)"></app-form-3>
          <app-form-4 *ngIf="tipoForm==4"
                      [uf]="ufSelected"
                      [itemAtual]="itemAtual"
                      [cidade]="cidadeSelected"
                      (salvarItem)="salvaritem($event)"
                      (setTemCobertura)="setTemCobertura($event)"></app-form-4>
          <app-form-5 *ngIf="tipoForm==5" (salvarItem)="salvaritem($event)"></app-form-5>
          <app-form-6 *ngIf="tipoForm==6"
                      [cidade]="cidadeSelected"
                      [itemAtual]="itemAtual"
                      [uf]="ufSelected"
                      (salvarItem)="salvaritem($event)"></app-form-6>
          <app-form-7 *ngIf="tipoForm==7"
                      [cidade]="cidadeSelected"
                      [itemAtual]="itemAtual"
                      [uf]="ufSelected"
                      (salvarItem)="salvaritem($event)"></app-form-7>
          <app-form-8 *ngIf="tipoForm==8"
                      [itemAtual]="itemAtual"
                      (salvarItem)="salvaritem($event)"></app-form-8>
          <app-form-9 *ngIf="tipoForm==9" [itemAtual]="itemAtual" (salvarItem)="salvaritem($event)"></app-form-9>
          <app-form-10 *ngIf="tipoForm==10" [itemAtual]="itemAtual" (salvarItem)="salvaritem($event)"></app-form-10>
          <app-form-101 *ngIf="tipoForm==101" [itemAtual]="itemAtual" (salvarItem)="salvaritem($event)"></app-form-101>
          <app-form-11 *ngIf="tipoForm==11" [itemAtual]="itemAtual" (salvarItem)="salvaritem($event)"></app-form-11>
          <app-form-12 *ngIf="tipoForm==12" [itemAtual]="itemAtual" (salvarItem)="salvaritem($event)"></app-form-12>
          <app-form-13 *ngIf="tipoForm==13" [itemAtual]="itemAtual" (salvarItem)="salvaritem($event)"></app-form-13>
          <app-form-14 *ngIf="tipoForm==14" [itemAtual]="itemAtual" (salvarItem)="salvaritem($event)"></app-form-14>
          <app-form-15 *ngIf="tipoForm==15"
                       [itemAtual]="itemAtual"
                       (salvarItem)="salvaritem($event)"
                       (setTemCobertura)="setTemCobertura($event)"></app-form-15>
        </ng-container>
        <app-produto-sem-cobertura [uf]="ufSelected" [cidade]="cidadeSelected" [itemAtual]="itemAtual" *ngIf="!showSemCobertura && ufSelected != null && cidadeSelected != null"></app-produto-sem-cobertura>
      </ng-container>
    </mat-step>
    <mat-step label="Meu carrinho">
      <div class="wrapper-content">
        <div class="wrapper-columns">
          <div class="column-left">
            <div class="content-items">
              <ng-container *ngFor="let itemCompra of pedido?.listaCompra; let j= index">
                
                <app-orderDetails-1 [item]="itemCompra" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)" *ngIf="itemCompra?.produto?.idForm==1"></app-orderDetails-1>
                <app-orderDetails-2 [item]="itemCompra" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)" *ngIf="itemCompra?.produto?.idForm==2"></app-orderDetails-2>
                <app-orderDetails-21 [item]="itemCompra" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)" *ngIf="itemCompra?.produto?.idForm==21"></app-orderDetails-21>
                <app-orderDetails-22 [item]="itemCompra" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)" *ngIf="itemCompra?.produto?.idForm==22"></app-orderDetails-22>
                <app-orderDetails-3 [item]="itemCompra" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)" *ngIf="itemCompra?.produto?.idForm==3"></app-orderDetails-3>
                <app-orderDetails-4 [item]="itemCompra" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)" *ngIf="itemCompra?.produto?.idForm==4"></app-orderDetails-4>
                <app-orderDetails-5 [item]="itemCompra" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)" *ngIf="itemCompra?.produto?.idForm==5"></app-orderDetails-5>
                <app-orderDetails-6 [item]="itemCompra" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)" *ngIf="itemCompra?.produto?.idForm==6"></app-orderDetails-6>
                <app-orderDetails-7 [item]="itemCompra" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)" *ngIf="itemCompra?.produto?.idForm==7"></app-orderDetails-7>
                <app-orderDetails-8 [item]="itemCompra" [valor]="getPreco(itemCompra.produto.composicaoValor)" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)"
                                    *ngIf="itemCompra?.produto?.idForm==8"></app-orderDetails-8>
                <app-orderDetails-9 [item]="itemCompra" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)" *ngIf="itemCompra?.produto?.idForm==9"></app-orderDetails-9>
                <app-orderDetails-10 [item]="itemCompra" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)" *ngIf="itemCompra?.produto?.idForm==10"></app-orderDetails-10>
                <app-orderDetails-101 [item]="itemCompra" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)" *ngIf="itemCompra?.produto?.idForm==101"></app-orderDetails-101>
                <app-orderDetails-11 [item]="itemCompra" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)" *ngIf="itemCompra?.produto?.idForm==11"></app-orderDetails-11>
                <app-orderDetails-12 [item]="itemCompra" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)" *ngIf="itemCompra?.produto?.idForm==12"></app-orderDetails-12>
                <app-orderDetails-13 [item]="itemCompra" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)" *ngIf="itemCompra?.produto?.idForm==13"></app-orderDetails-13>
                <app-orderDetails-14 [item]="itemCompra" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)" *ngIf="itemCompra?.produto?.idForm==14"></app-orderDetails-14>
                <app-orderDetails-15 [item]="itemCompra" (editar)="editar($event)" (excluirItem)="excluirItem($event,j)" *ngIf="itemCompra?.produto?.idForm==15"></app-orderDetails-15>
              </ng-container>
            </div>
          </div>
          <div class="column-right">
            <div class="wrapper-box-content">
              <div class="div-75">
                <div class="div-76">Quantidade de certidões:</div>
                <div class="div-77">{{qtdeAtos()}}</div>
              </div>
              <div class="div-78">
                <div class="div-79">Total dos emolumentos:</div>
                <div class="div-80">{{ pedido?.totalPedido | currency:'BRL'}}</div>
              </div>
              <div class="div-81">
                <div class="div-82">Adicional de Administração:</div>
                <div class="div-83">{{ pedido?.totalAdicionalAdm | currency:'BRL'}}</div>
              </div>
              <mat-divider class="w-100 mt-4"></mat-divider>
              <div class="div-84">
                <div class="div-85">Total a pagar:</div>
                <div class="div-86">{{ pedido?.totalPagar | currency:'BRL'}}</div>
              </div>
              <button type="button" (click)="limparCarrinho()" class="btn-cart-clean">
                Limpar carrinho
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-auto">
          <button type="button" *ngIf="showBotaoteste" mat-button (click)="criaTesteRj()" class="btn-next" style="color: white; background-color: green;">CRIA TESTE RJ</button>
          <button type="button" *ngIf="showBotaoteste" mat-button (click)="criaTesteBR()" class="btn-next" style="color: white; background-color: green;">CRIA TESTE BR</button>
          <button type="button" mat-button (click)="home()" class="btn-next" style="color: white; background-color: green;">Nova Solicitação</button>
        </div>
        <div class="col-auto">
          <button type="button" mat-button (click)="Pagar()" *ngIf=" pedido?.listaCompra?.length >0" class="btn-next" style="color: white;">Finalizar Pedido</button>
        </div>
      </div>
    </mat-step>
    <mat-step label="Identificação" *ngIf="!logado">
      <app-login [interno]="true" class="m-3" [conteudo]="{ 'tipo': 'login', 'versao': 'v2',  'RedesSociais': [ { 'Rede': 'Faceebook' }, { 'Rede': 'Google' } ]  }" *ngIf="selectedIndex ==2" [relacionarCompra]="true"></app-login>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Pedido concluído</ng-template>
      <app-payment  *ngIf="showPagamento"> </app-payment>
    </mat-step>
  </mat-stepper>
</div>
