import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorage } from '../../_entidade/LocalStorage';
import { CrudService } from '../../_sevice/CrudService';
import { GlobalService } from '../../_sevice/GlobalService';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { ModalPadrao } from '../dialog/modal-padrao';


@Component({
  selector: 'app-trocar-senha',
  templateUrl: './trocar-senha.component.html',
  styleUrls: ['./trocar-senha.component.less']
})
export class TrocarSenhaComponent implements OnInit {

  cpf: any;
  password = true;
  confirmPassword = true;
  security: any;
  fitro: any;
  mensagem: string = '';
  exibeForm: boolean = false
  exibeFormAlteracao: boolean = false
  exibeFormAlerta: boolean = false

  isLoading: boolean = false
  @Input() conteudo: any;


  constructor(
    public router: Router
    , public storage: LocalStorage
    , public service: CrudService
    , public gb: GlobalService
    , public dialog: MatDialog
    , public routeActive: ActivatedRoute
    , private formBuilder: FormBuilder
    , private route: ActivatedRoute

  ) {
    this.formCadastro = this.formBuilder.group({
      cpf: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      security: [''],
      filtro: ['']
    }, { validator: this.passwordsMatchValidator });


  }

  formCadastro = this.formBuilder.group({
    cpf: ['', Validators.required],
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required],
    security: [''],
    filtro: ['']
  });


  get f() {
    return this.formCadastro.controls;
  }


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => { this.cpf = params['cpf']; });
    this.route.queryParams.subscribe(params => { this.security = params['security']; });
  }


  togglePassword(field: string) {
    if (field === 'password') {
      this.password = !this.password;
    } else if (field === 'confirmPassword') {
      this.confirmPassword = !this.confirmPassword;
    }
  }

  passwordsMatchValidator(formGroup: FormGroup) {
    const passwordControl = formGroup.get('password');
    const confirmPasswordControl = formGroup.get('confirmPassword');

    if (passwordControl && confirmPasswordControl) {
      const password = passwordControl.value;
      const confirmPassword = confirmPasswordControl.value;

      if (password !== confirmPassword) {
        confirmPasswordControl.setErrors({ passwordsNotMatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    }
  }


  trocarSenha() {
    // Pega o CPF informado via parâmetro na URL para troca de senha.
    this.formCadastro.get('cpf')?.setValue(this.cpf);

    this.formCadastro.markAllAsTouched();

    if (this.formCadastro.valid) {
      let login = {
        cpf: this.formCadastro.get('cpf')?.value,
        senha: this.formCadastro.get('password')?.value,
        security: this.security
      };

      let url = environment.http.API_AUTH + `v1/account/login/trocarsenha/`;

      //console.log('troca senha', url)

      this.service.putObservableDirect(url, login).subscribe({
        next: async (response: any) => {

          //console.log('trocou senha',response)

          if (response.code != 400) {
            let ret = JSON.parse(response.returnData)

            if (ret != null) {
              this.router.navigate(['/admin/meus-pedidos']);

            }
            else {
              this.exibeForm = true
              this.exibeFormAlerta = true

            }
          }
          else {
            this.exibeForm = true
            this.exibeFormAlerta = true

          }

        },
        error: (err) => {
          console.error(err);
        }
      });
    }
    else {
      //Usuario logado
      this.alterarSenha()
    }
  }

  alterarSenha() {
    let userlogged = this.storage.localStorageGet('userlogged')

    let login = {
      keyLogin: userlogged.keyLogin,
      senha: this.formCadastro.get('password')?.value
    };

    let url = environment.http.API_AUTH + `v1/account/login/AlterarSenha/`;

    this.service.putObservableDirect(url, login).subscribe({
      next: async (response: any) => {

        if (response.code != 400) {
          let ret = JSON.parse(response.returnData)

          if (ret != null) {
            //Na troca de senha, se tiver pedido, vai para o Carrinho.
            let pedido = this.storage.localStorageGet("pedido")
            if (pedido) {
              this.router.navigate(['/pedido/meu-carrinho'])
            }
            else {
              this.router.navigate(['/admin/meus-pedidos']);
            }
          }
          else {
            this.exibeForm = true
            this.exibeFormAlerta = true
          }

        }
        else {
          this.exibeForm = true
          this.exibeFormAlerta = true
        }

      },
      error: (err) => {
        console.error(err);
      }
    });
  }


  recuperarSenha() {
    //Rotina para localizar o Usuario.
    let pesquisa = this.formCadastro.get('filtro')?.value

    if (pesquisa != '') {
      let url = environment.http.API_AUTH + `v1/account/login/recuperarsenha/${pesquisa}`

      //console.log('RecuperarSenha', url)
      this.isLoading = true

      this.service.getObservableDirect(url).subscribe({
        next: async (response) => {
          //console.log('RecuperarSenha-response', response)

          if (response.code != 400) {
            if (response.returnData != null) {
              let ret2 = JSON.parse(response.returnData)
              this.mensagem = "Foi enviado um e-mail para " + ret2.email + " com informações para recuperar a senha."
              this.exibeForm = true
              this.exibeFormAlerta = false
              this.isLoading = false
            }
            else {
              this.isLoading = false
              this.exibeForm = true
              this.exibeFormAlerta = true
            }
          }
          else {
            this.isLoading = false
            this.exibeForm = true
            this.exibeFormAlerta = true
          }


        },
        error: (err) => {
          console.error('recuperacaoSenha ERROR:', err);
        }
      });
    }

  }
  voltarTela() {
    this.exibeForm = false
    this.exibeFormAlerta = false
  }

}
