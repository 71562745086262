<div class="sign-in-container container-full" *ngIf="!conteudo?.versao">
  <img [src]="'/assets/'+conteudo?.BgImagen" alt="" srcset="" *ngIf="conteudo?.BgImagen">
  <div class="content-login">
    <form (ngSubmit)="logar()" [formGroup]="formLogin">
      <h2>Entrar</h2>

      <mat-form-field appearance="outline" class="w-100 my-2">
        <mat-label>Login</mat-label>
        <input matInput type="text" formControlName="Username" [(ngModel)]="login.login" placeholder="Digite seu login"
          required>
        <mat-error *ngIf="f.Username.errors?.['required']">
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100 my-2">
        <mat-label>Senha</mat-label>
        <input matInput [type]="hidePassword1 ? 'password' : 'text'" formControlName="Password"
          placeholder="Digite sua senha" [(ngModel)]="login.senha" required>
        <button class="border-0 mx-3 rounded-5" mat-icon-button matSuffix type="button"
          (click)="togglePasswordVisibility(1)">
          <mat-icon>{{ hidePassword1 ? 'remove_red_eye' : 'visibility_off' }}</mat-icon>
        </button>
        <mat-error *ngIf="f.Password.errors?.['required']">
          Preenchimento obrigatório
        </mat-error>
        <mat-error *ngIf="f.Password.errors?.['usuarioerro']">
          Usuário não encontrado
        </mat-error>
      </mat-form-field>
      <a routerLink="/login/cadastre-se" *ngIf="conteudo?.Cadastrar">Cadastre-se</a>
      <div class="example-button-row">
        <button mat-raised-button color="primary" (click)="logar()" class="btn-login mr-5" type="button">Entrar</button>
        <!--<ng-container *ngFor="let item of conteudo?.RedesSociais">
          <button class="social-btn-google" *ngIf="item?.Rede=='Google'">
            <svg class="jss994" focusable="false" viewBox="0 0 186.69 190.5" aria-hidden="true" role="presentation" alt="">
              <g transform="translate(1184.583 765.171)">
                <path clip-path="none" mask="none" d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z" fill="#FFFFFF"></path>
                <path clip-path="none" mask="none" d="M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z" fill="#FFFFFF"></path>
                <path clip-path="none" mask="none" d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z" fill="#FFFFFF"></path>
                <path d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z" fill="#FFFFFF" clip-path="none" mask="none"></path>
              </g>
            </svg>
            Entrar com Google
          </button>
          <button class="social-btn-facebook" *ngIf="item?.Rede=='Faceebook'">
            <svg class="jss994" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation" alt="">
              <path d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z" fill="#FFFFFF"></path>
            </svg>
            Entrar com Facebook
          </button>
        </ng-container>-->

      </div>
    </form>

  </div>
</div>

<div class="imgBgLogin" [class]="interno ? 'container p-5' : ''" *ngIf="conteudo?.versao =='v2'">
  <div [class]="conteudo?.bannerFullContent ? 'container':''">
    <div class="row">
      <div class="col-lg-5">
        <form class="d-flex flex-column" [formGroup]="formLogin" (submit)="logar()">
          <h2>Acessar conta</h2>
          <div class="m-2">
            <span>CPF/CNPJ</span>
            <input [class.is-invalid]="validarCampoFormRequired('Username')" mask="000.000.000-00||00.000.000/0000-00"
              class="form-control" type="text" placeholder="CPF/CNPJ" formControlName="Username">
            <mat-error *ngIf="validarCampoFormRequired('Username')">
              Preenchimento obrigatório
            </mat-error>
          </div>

          <div class="m-2">
            <span>Senha</span>
            <div class="input-group">
              <input [class.is-invalid]="validarCampoFormRequired('Password')" class="form-control"
                style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;"
                [type]="hidePassword1 ? 'password' : 'text'" placeholder="Senha" formControlName="Password">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  style="border-radius: 0;border-top-right-radius: .375rem;border-bottom-right-radius: .375rem;"
                  (click)="togglePasswordVisibility(1)">
                  <mat-icon>
                    {{
                    hidePassword1 ?
                    'remove_red_eye' : 'visibility_off'
                    }}
                  </mat-icon>
                </span>
              </div>
            </div>

            <mat-error *ngIf="validarCampoFormRequired('Password')">
              Preenchimento obrigatório
            </mat-error>
            <mat-error *ngIf="formLogin.get('Password')?.errors?.['usuarioerro']">
              {{ mensagemErroResponse }}
            </mat-error>

            <div class="row m-2">
              <a style="color: white;text-align: end;" routerLink="/institucional/esqueci-senha"
                class="btn-forgot-password">
                Esqueci
                minha senha
              </a>
            </div>
          </div>

          <div class="m-2">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault">
                <small>Continuar conectado</small>
              </label>
            </div>
          </div>

          <!-- <button class="btn btn-primary m-2 d-flex justify-content-center" (click)="loginWithFacebook()">
            <mat-icon>facebook</mat-icon>
            <span class="px-2">Login pelo Facebook</span>
          </button> -->

          <input type="submit" value="Entrar" class="btn btn-primary m-2" />

          <!--<button class="btn btn-light m-2 d-flex justify-content-center" (click)="loginWithGoogle()">
            <img class="icon" src="/assets/icon-google.jpg" width="20" />
            <span class="px-2">Login pelo Google</span>
          </button>-->

          <span class="m-2">
            Ainda não tem uma conta? <a routerLink="/login/cadastre-se" style="color: white;">
              Crie agora
              mesmo
            </a>
          </span>
        </form>
      </div>
    </div>
  </div>
</div>


<!--
<ng-container class="sign-in-container"  *ngIf="conteudo?.versao =='v2'">
  <div class="content-login">
    <form [formGroup]="formLogin">
      <div class="wrapper-login">
        <h2 class="title-section">Acesse sua conta</h2>
        <div class="wrapper-fields">
          <mat-form-field appearance="outline" class="w-100 my-2">
            <mat-label>CPF/CNPJ ou E-mail</mat-label>
            <input matInput type="text" placeholder="CPF ou CNPJ" formControlName="Username">
            <mat-error>
              Preenchimento obrigatório
            </mat-error>
          </mat-form-field>
        </div>
        <div class="wrapper-fields">
          <mat-form-field appearance="outline" class="w-100 my-2">
            <mat-label>Senha</mat-label>
            <input matInput type="password" placeholder="Digite sua senha" formControlName="Password" required>
            <mat-error *ngIf="f.Password.errors?.['required']">
              Preenchimento obrigatório
            </mat-error>
            <mat-error *ngIf="f.Password.errors?.['usuarioerro']">
              Usuário não encontrado
            </mat-error>
          </mat-form-field>
        </div>
        <a (click)="logar()" class="btn-login">Entrar</a><br /><br /><br />

        <div class="example-button-row">

          <ng-container *ngFor="let item of conteudo?.RedesSociais">
            <button class="social-btn-google" *ngIf="item?.Rede=='Google'" (click)="loginWithGoogle()">
              <svg class="jss994" focusable="false" viewBox="0 0 186.69 190.5" aria-hidden="true" role="presentation" alt="">
                <g transform="translate(1184.583 765.171)">
                  <path clip-path="none" mask="none" d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z" fill="#FFFFFF"></path>
                  <path clip-path="none" mask="none" d="M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z" fill="#FFFFFF"></path>
                  <path clip-path="none" mask="none" d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z" fill="#FFFFFF"></path>
                  <path d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z" fill="#FFFFFF" clip-path="none" mask="none"></path>
                </g>
              </svg>
              Entrar com Google
            </button>
            -- <button class="social-btn-facebook" *ngIf="item?.Rede=='Faceebook'" (click)="loginWithFacebook()">
              <svg class="jss994" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation" alt="">
                <path d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z" fill="#FFFFFF"></path>
              </svg>
              Entrar com Facebook
            </button> --
          </ng-container>

        </div>

        <a routerLink="/institucional/esqueci-senha" class="btn-forgot-password">Esqueceu sua senha?</a>
        <p class="wrapper-text-account">
          <span class="text-account">OU</span>
        </p>
        <h2 class="title-section">Cadastre-se agora</h2>
        <p class="text-info-create-user">É necessário o registro para realizar os pedidos.</p>
        <a routerLink="/login/cadastre-se" class="btn-create-user">Criar Conta</a>
      </div>
    </form>

  </div>
</ng-container> -->
