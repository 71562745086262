<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
  <div class="modal-dialog" role="document" style="max-width: 700px;">
    <div class="modal-content">
      <div class="modal-header" style="background-color: #428bca;">
        <h5 class="modal-title" style="color: #fff; font-size: 18px;">Pedido de Certidão Predial (Certidão Permanente)</h5>
        <!-- <button id="btnFechaModalExcluirEntidade" style="width:50px" (keydown.space)="$event.preventDefault()"
                (click)="onCloseHandled()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>
      <div class="modal-body">
        <p>Prezado(a),</p>
        <p>Verificamos que todos os campos não foram preenchidos. Pelo CNR poderá solicitar a pesquisa de certidão inicialmente, principalmente quando não está em posse de todos os dados de preenchimentos do formulário.</p>
      </div>
      <div class="modal-footer">
        <div class="row w-100">
          <div class="col">
            <button type="button" class="btn btn-outline-secondary w-100" style="border-color: #e6e6e6;">Solicitar pesquisa de certidão</button>
          </div>
          <div class="col">
            <button type="button" (keydown.space)="$event.preventDefault()" (click)="onCloseHandled()" type="button"
                    class="close" data-dismiss="modal" aria-label="Close" class="btn btn-outline-secondary w-100" data-dismiss="modal"
                    style="border-color: #e6e6e6;">
              Continuar na solicitação da certidão
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<form [formGroup]="camposForm">
  <div class="content-list">
    <div class="row" formGroupName="localizacao">
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Distrito:</mat-label>
          <mat-select formControlName="distrito" (selectionChange)="getConcelhosInput($event)" [compareWith]="compareDistrito">
            <mat-option *ngFor="let item of distritos" [value]="item">
              {{ item.descricao }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Concelho:</mat-label>
          <mat-select formControlName="concelho" (selectionChange)="getFreguesiaInput($event)" [compareWith]="compareConcelho">
            <mat-option *ngFor="let item of concelhos" [value]="item">
              {{ item.descricao }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Freguesia:</mat-label>
          <mat-select formControlName="freguesia" [compareWith]="compareFreguesia">
            <mat-option *ngFor="let item of freguesias" [value]="item">
              {{ item.descricao }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
          <mat-label>Requerente</mat-label>
          <input matInput type="text" class="col-lg-12" formControlName="nomeRequerente"
                 placeholder="Requerente" maxlength="255">
          <mat-error>
            Preenchimento obrigatório
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field class="w-100">
          <mat-label for="finalidade">Finalidade</mat-label>
          <mat-select formControlName="finalidade" name="finalidade">
            <mat-option value="1">AVERBAÇÃO</mat-option>
            <mat-option value="2">EXTRAJUDICIAL</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="content-list">
    <div class="row">
      <div class="col-md-12">
        <mat-tab-group>
          <mat-tab label="Descrição em Ficha ou Informatizado">
            <div formGroupName="dadosFicha">
              <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Nº da Ficha/Prédio</mat-label>
                  <input matInput type="number" class="col-lg-12" formControlName="numero"
                         placeholder="Nº da Ficha/Prédio" maxlength="4">
                  <mat-error>
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Fração Autónoma</mat-label>
                  <input matInput type="number" class="col-lg-12" formControlName="fracaoAutonoma"
                         placeholder="Fração Autónoma" maxlength="4">
                  <mat-error>
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Fração Temporal</mat-label>
                  <input matInput type="number" class="col-lg-12" formControlName="fracaoTemporal"
                         placeholder="Fração Temporal" maxlength="4">
                  <mat-error>
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Descrição em Livro">
            <div formGroupName="dadosLivro">
              <!-- Número da Descrição -->
              <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Nº da Descrição</mat-label>
                  <input matInput type="text" class="col-lg-12" formControlName="descricao"
                         placeholder="Nº da Descrição" maxlength="100">
                  <mat-error>
                    Preenchimento obrigatório
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="row">
                <!-- Livro -->
                <div class="col-md-4">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Livro</mat-label>
                    <input matInput type="text" class="col-lg-12" formControlName="livro"
                           placeholder="Livro" maxlength="100">
                  </mat-form-field>
                </div>

                <!-- Folhas -->
                <div class="col-md-4">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Folhas</mat-label>
                    <input matInput type="text" class="col-lg-12" formControlName="folhas"
                           placeholder="Folhas" maxlength="100">
                  </mat-form-field>
                </div>

                <!-- Posição Folha -->
                <div class="col-md-4">
                  <mat-form-field class="w-100">
                    <mat-label for="posicaoFolha">Posição Folha</mat-label>
                    <mat-select id="posicaoFolha" name="posicaoFolha" formControlName="posicaoFolha">
                      <mat-option value="1">Frente</mat-option>
                      <mat-option value="2">Verso</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <!-- Fração Autónoma | Unid. Alojamento -->
              <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Fração Autónoma | Unid. Alojamento</mat-label>
                  <input matInput type="text" class="col-lg-12" formControlName="fracaoAutonoma"
                         placeholder="Fração Autónoma | Unid. Alojamento" maxlength="100">
                </mat-form-field>
              </div>
              <!-- Fração Temporal -->
              <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                  <mat-label>Fração Temporal</mat-label>
                  <input matInput type="text" class="col-lg-12" formControlName="fracaoTemporal"
                         placeholder="Fração Temporal" maxlength="100">
                </mat-form-field>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Informação Matricial">
            <div formGroupName="dadosMatricial">
              <div class="row">
                <!-- Select Natureza do Artigo -->
                <div class="col-md-12">
                  <mat-form-field class="w-100">
                    <mat-label for="natureza">Natureza do Artigo</mat-label>
                    <mat-select id="natureza" name="natureza" formControlName="natureza">
                      <mat-option value="Rústica">Rústica</mat-option>
                      <mat-option value="Urbana">Urbana</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <!-- Natureza do artigo -->
                <div class="col-md-8">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Natureza do Artigo</mat-label>
                    <input matInput type="text" class="col-lg-12" formControlName="numero"
                           placeholder="Natureza do Artigo" maxlength="100">
                  </mat-form-field>
                </div>

                <!-- Checkbox Provisório -->
                <div class="col-md-4">
                  <mat-checkbox formControlName="provisorio" class="my-2">Provisório</mat-checkbox>
                </div>
              </div>
              <div class="row">
                <!-- Fração -->
                <div class="col-md-12">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Fração</mat-label>
                    <input matInput type="text" class="col-lg-12" formControlName="fracao"
                           placeholder="Fração" maxlength="100">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <!-- Seção -->
                <div class="col-md-12">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Secção</mat-label>
                    <input matInput type="text" class="col-lg-12" formControlName="seccao"
                           placeholder="Secção" maxlength="100">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <!-- Árvore | Colonia -->
                <div class="col-md-12">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                    <mat-label>Árvore | Colonia</mat-label>
                    <input matInput type="text" class="col-lg-12" formControlName="arvores"
                           placeholder="Árvore | Colonia" maxlength="100">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
        <ng-container *ngIf="itemAtual.entregaCorreio === 'true'">
          <!--Enviar a certidão pelo Correios-->
          <div class="row">
            <div class="col-md-6">
              <mat-label>Enviar a certidão pelo Correios? </mat-label>
              <mat-radio-group formControlName="envioCorreios" (change)="setaDadosEntregaObrigatorio()">
                <mat-radio-button value='true'>Sim</mat-radio-button>
                <mat-radio-button value='false'>Não</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <ng-container *ngIf="camposForm.get('envioCorreios')?.value == 'true'">
            <div class="content-list" formGroupName="dadosEntrega">
              <ng-container class="row">
                <div class="row">
                  <!--Pais-->
                  <div class="col-md-6">
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Pais</mat-label>
                      <input matInput type="text" formControlName="pais" maxlength="50">
                      <mat-error>
                        Preenchimento obrigatório
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <!--Estado-->
                  <div class="col-md-6">
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Estado</mat-label>
                      <input matInput type="text" formControlName="estado" maxlength="50">
                      <mat-error>
                        Preenchimento obrigatório
                      </mat-error>
                    </mat-form-field>
                  </div>

                </div>

                <div class="row">
                  <!--Cidade-->
                  <div class="col-md-6">
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Cidade</mat-label>
                      <input matInput type="text" formControlName="cidade" maxlength="50">
                      <mat-error>
                        Preenchimento obrigatório
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <!--Logradouro-->
                  <div class="col-md-6">
                    <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                      <mat-label>Logradouro</mat-label>
                      <input matInput type="text" formControlName="enderecoCompleto" maxlength="100">
                      <mat-error>
                        Preenchimento obrigatório
                      </mat-error>
                    </mat-form-field>
                  </div>

                </div>

              </ng-container>
            </div>

          </ng-container>
          <!--Apostilar Certidão-->
          <div class="row">
            <div class="col-md-6">
              <mat-label>Apostilar Certidão? </mat-label>
              <mat-radio-group formControlName="apostilamento">
                <mat-radio-button value='true'>Sim</mat-radio-button>
                <mat-radio-button value='false'>Não</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</form>

<app-aviso [aviso]="itemAtual?.aviso"></app-aviso>

<div class="row">
  <div class="col-md-5"></div>
  <div class="col-auto" *ngIf="!edicao">
    <button type="button" mat-button [routerLink]="['/']" class="btn-next" style="color: white; background-color: green;">Voltar</button>
  </div>
  <div class="col-auto" *ngIf="edicao">
    <button type="button" mat-button (click)="cancelarEdicaoForm()" class="btn-next" style="color: white; background-color: green;">Cancelar</button>
  </div>
  <div class="col-auto">
    <button mat-button (click)="updatePedido()" class="btn-next"
            style="display: block; margin: 0 auto;">
            <span  *ngIf="!edicao">Continuar</span> 
            <span  *ngIf="edicao">Salvar</span> 
    </button>
  </div>
</div>
