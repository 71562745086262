<h5 mat-dialog-title>Selecione uma opção para continuar</h5>

<section>
<div mat-dialog-actions>
  <div class="example-button-row">
    <button mat-raised-button color="primary" (click)="onNoClick()">Listar Usuário</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="data.animal" cdkFocusInitial>Criar Novo</button>
  </div>
</div>
</section>
