import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
import { GenericValidator } from '../../../_sevice/GenericValidator';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-form-21',
  templateUrl: './form2.1.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class Form21Component implements OnInit, AfterViewInit ,OnChanges{
  @Input() conteudo: any;

  @Input() itemAtual: any;
  @Input() itemEdicao: any;
  @Input() edicao: any=false;
  @Output() cancelarEdicao = new EventEmitter<any>();

  @Input() iskit: any; 
  @Output() salvarItem = new EventEmitter<any>();
  @Output() setTemCobertura = new EventEmitter<any>();
  @ViewChild('stepperPrincipal') stepper: MatStepper;

  load: boolean = false
  temCobertura: boolean = false
  classObrigatoria: any = ''
  // rows: any[] = [];
  form: FormGroup;
  erroCartorio: boolean = false
  cartorioEmissor: any[];
  finalidadeCertidao: any[];
  public visibilidadeDetalhes: boolean[] = [];
  @Input() uf: any;
  @Input() cidade: any;
  @Input() itemAtualKit: any;

  orderDetails = this.formBuilder.group({
    compradores: this.formBuilder.array([this.createComprador()]),
    idPedidoAtual: ['']

  });

  createComprador(): FormGroup {
    return this.formBuilder.group({
      nome: ['', Validators.required],
      documento: ['', Validators.compose([
        Validators.required,
        GenericValidator.isValidCPFCNPJ()
      ])],
      tipoPessoa: ['fisica'],
      nascimento: ['']
    });
  }


  constructor(
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public teste: TesteService,
    private cdRef: ChangeDetectorRef,
    private routeAc: ActivatedRoute,
    public service: CrudService
  ) {
     
  }

  ngOnInit(): void {
    this.carrregarEdicao()
    //console.log('sfasfd')
  }

  ngAfterViewInit() {


  }
  ngOnChanges(changes: SimpleChanges) {

    this.cdRef.detectChanges();

  }


  validaCPFCNPJ(i: number) {
    return (this.getCompradorControl(i).get('documento')?.hasError('documentoNotValid'));
  }

  validaRequired(i: number) {

    return (this.getCompradorControl(i).get('documento')?.hasError('required'));
  }

  remComprador(i: number) {
    this.compradores.removeAt(i)
  }

  addComprador() {
    this.compradores.push(this.createComprador());
  }

  // Getter para obter o FormArray de compradores
  get compradores(): FormArray {

    return this.orderDetails.get('compradores') as FormArray;
  }

  getCompradorControl(index: number): FormGroup {
    return (this.orderDetails.get('compradores') as FormArray).at(index) as FormGroup;
  }

  cancelarEdicaoForm() {
    this.cancelarEdicao.emit()
  }

  limparDados(i: number) {
    this.getCompradorControl(i).get('documento')?.reset()
  }

  // get itemAtual() {
  //   return this.storage.localStorageGet("item_atual");
  // }

  setTemCoberturaFilho(tem: boolean) {
    this.temCobertura = tem
    this.setTemCobertura.emit(tem);

  }

  setCartorios(cartorios: any) {
    this.cartorioEmissor = cartorios
    this.carrregarEdicao()
  }


  updatePedido() {
    let carsel = this.cartorioEmissor?.filter((x: any) => x.selecionado == true)
    this.orderDetails.markAllAsTouched();

    if (!this.edicao) {
      if (carsel == null || carsel.length == 0) {
        this.erroCartorio = true;
        this.classObrigatoria = 'redClass'
      } else {
        this.erroCartorio = false;
        this.classObrigatoria = ''
      }
    }
    else {
      if (this.compradores.controls[0].get('nascimento')?.hasError('maxlength'))
        this.compradores.controls[0].get('nascimento')?.setErrors(null);
    }


    if (this.orderDetails.valid && !this.erroCartorio) {
      let item: any = this.orderDetails.value
      item['listCartorio'] = carsel
      this.salvarItem.emit(item);
    }


    //if (!this.edicao) {

    //  if (carsel == null || carsel.length == 0) {
    //    this.erroCartorio = true;
    //    this.classObrigatoria = 'redClass'

    //  } else {
    //    this.erroCartorio = false;
    //    this.classObrigatoria = ''
    //  }


    //  if (this.orderDetails.valid && !this.erroCartorio) {
    //    let item: any = this.orderDetails.value
    //    item['listCartorio'] = carsel
    //    this.salvarItem.emit(item);
    //  }
    //}
  }

  carrregarEdicao() {
    console.log("entrou no carrregarEdicao")
    console.log(this.routeAc.snapshot)
     let paramId = this.routeAc.snapshot.paramMap.get('id');

    if (paramId != null || this.itemEdicao != null) {
      let pedido = this.storage.localStorageGet("pedido")

        console.log("entrou no if")
      console.log(pedido?.listaCompra)

      if (pedido?.listaCompra != null && pedido?.listaCompra.length > 0 && paramId != null) {

        let prodAtu = { ...pedido.listaCompra?.find((x: any) => x.idItem == paramId) }

        console.log("1")
        console.log(prodAtu)

        let qtdeAss = prodAtu?.compradores?.length - 1
        for (var i = 0; i < qtdeAss; i++) {
          this.addComprador()
        }

        this.service.preencheCampos(prodAtu, this.orderDetails)
      } else {
        console.log("2")
        console.log(this.itemEdicao)

        let prodAtu = this.itemEdicao
        this.service.preencheCampos(prodAtu, this.orderDetails)
      }
    }

  }


  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // //console.log(event.key, event.ctrlKey, event.shiftKey, environment.production)
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {
      for (let control of this.compradores.controls) {
        if (control instanceof FormControl) {
          // is a FormControl
        }
        if (control instanceof FormGroup) {
          this.teste.setValueForm(control);
        }
        if (control instanceof FormArray) {
          // is a FormArray
        }
      }
    }
  }

}
