<div class="container py-4">

  <div class="row my-4">

    <div class="d-flex justify-content-end align-items-center col-12 col-md-8 col-lg-8 col-xl-8 py-2 text-end">
      <span>{{ viewDate | calendarDate:(view + 'ViewTitle') }}</span>
    </div>

    <div class="col-12 col-md-4 col-lg-4 col-xl-4">
      <div class="btn-toolbar justify-content-center justify-content-sm-end" role="toolbar" aria-label="Toolbar with button groups">
        <div class="btn-group me-3">
          <button type="button" class="btn" (click)="setView(CalendarView.Month)" [ngClass]="view === CalendarView.Month ? ' btn-purple' : ' btn-outline-purple'">
            <span>Mês</span>
          </button>
          <button type="button" class="btn" (click)="setView(CalendarView.Week)" [ngClass]="view === CalendarView.Week ? ' btn-purple' : ' btn-outline-purple'">
            <span>Semana</span>
          </button>
        </div>

        <div class="btn-group">
          <button type="button" class="btn btn-outline-purple" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeActiveDay()" (click)="activeRangeWeek(viewDate)">
            <!--i class="fa-solid fa-arrow-left"></!--i-->
            <svg _ngcontent-lsr-c135="" class="svg-inline--fa fa-arrow-left" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z"></path></svg>
          </button>
          <button type="button" class="btn btn-outline-purple" mwlCalendarToday [(viewDate)]="viewDate" (click)="activeRangeWeek(viewDate)">
            <!--i class="fa-solid fa-calendar-check"></!--i-->
            <svg _ngcontent-lsr-c135="" class="svg-inline--fa fa-calendar-check" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM328.1 304.1C338.3 295.6 338.3 280.4 328.1 271C319.6 261.7 304.4 261.7 295 271L200 366.1L152.1 319C143.6 309.7 128.4 309.7 119 319C109.7 328.4 109.7 343.6 119 352.1L183 416.1C192.4 426.3 207.6 426.3 216.1 416.1L328.1 304.1z"></path></svg>
          </button>
          <button type="button" class="btn btn-outline-purple" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeActiveDay()" (click)="activeRangeWeek(viewDate)">
            <!--i class="fa-solid fa-arrow-right"></!--i-->
            <svg _ngcontent-lsr-c135="" class="svg-inline--fa fa-arrow-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"></path></svg>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div [ngSwitch]="view">
    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month"
                             [viewDate]="viewDate"
                             [events]="events"
                             [activeDayIsOpen]="activeDayIsOpen"
                             (dayClicked)="onDayClick($event.day)"
                             (eventClicked)="onEventClick($event.event)">
    </mwl-calendar-month-view>

    <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week"
                            [viewDate]="viewDate"
                            [events]="events"
                            [locale]="locale"
                            [hourSegments]="4"
                            [dayStartHour]=dayStartHour
                            [dayEndHour]=dayEndHour
                            (beforeViewRender)="onBeforeRenderWeek($event)"
                            (eventClicked)="onEventClick($event.event)">
    </mwl-calendar-week-view>
    <!--(hourSegmentClicked)="onSegmentClick($event.date)"-->
  </div>

  <!--modal detalhe item day-->
  <ng-template #modalContent let-modal>
    <div class="modal-header d-flex justify-content-start align-items-center">
      <h3 class="mb-0 modal-title text-black"><b>{{modalData.schedule.title}}</b></h3>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <div class="modal-body">

      <div class="row py-3">
        <div class="col-lg-3 col-sm-12">
          <div class="ps-0 d-flex justify-content-start align-items-center">
            <span class="pe-1"><img src="../../../../assets/date.svg" alt="Data" title="Data"></span>
            <span>{{modalData.schedule.date | date:'dd/MM/yyyy'}}</span>
          </div>
        </div>
       
        <div class="col-lg-3 col-sm-12">
          <div class="ps-0 d-flex justify-content-start align-items-center">
            <span class="pe-1"><img src="../../../../assets/time.svg" alt="Hora agendada" title="Hora agendada"></span>
            <span class="ps-1 pt-1">{{modalData.schedule.initTime}} ~ {{modalData.schedule.endTime}}</span>
          </div>
        </div>
      
        <div class="col-lg-6 col-sm-12 " [class]="isMobile() ? '':'txtRigth'">
          <span>
            <span class="pe-1"><img src="../../../../assets/videocam.svg" alt="Vídeo chamada" title="Vídeo chamada"></span>
            <span><a [href]="modalData.schedule.linkMediacao+'/'+user.keyLogin" target="_blank"><button class="btn btn-primary btn-sm fs-7">Reunião de Mediação</button></a></span>
          </span>
        </div>
      </div>

      <!--valor-->
      <div class="row py-3">
        <div class="col-12">
          <div class="ps-0 d-flex justify-content-start align-items-center">
            <span class="pe-1"><img src="../../../../assets/money.svg" alt="Valor Sugerido" title="Valor Sugerido"></span>
            <span>Valor Sugerido</span>
          </div>
          <div class="ps-4 ps-0 d-flex justify-content-start align-items-center">
            <span>{{modalData.schedule.ValorSugerido | currency:'BRL' }} </span>
          </div>
        </div>
      </div>

      <!-- Pedido-->
      <div class="row py-3" *ngIf="modalData.schedule.description">
        <div class="col-12">
          <div class="ps-0 d-flex justify-content-start align-items-center">
            <span class="pe-1"><img src="../../../../assets/pedido.svg" alt="Pedido Sucinto" title="Pedido Sucinto"></span>
            <span>Pedido Sucinto</span>
          </div>
          <div class="ps-4 d-flex justify-content-start align-items-center">
            <span>{{modalData.schedule.PedidoSucinto}}</span>
          </div>
        </div>
      </div>

      <!--relato dos fatos-->
      <div class="row py-3" *ngIf="modalData.schedule.description">
        <div class="col-12">
          <div class="ps-0 d-flex justify-content-start align-items-center">
            <span class="pe-1"><img src="../../../../assets/order.svg" alt="Relato dos Fatos" title="Relato dos Fatos"></span>
            <span>Relato dos Fatos</span>
          </div>
          <div class="ps-4 d-flex justify-content-start align-items-center">
            <span>{{modalData.schedule.RelatoFatos}}</span>
          </div>
        </div>
      </div>

      <!--anfitriao-->
      <div class="row py-3">
        <div class="col-12">
          <div *ngIf="modalData.schedule.anfitrioes.length ==1">
            <img src="../../../../assets/anfitriao.svg" alt="Anfitrião" title="Anfitrião"><b> Anfitrião</b>
          </div>
          <div *ngIf="modalData.schedule.anfitrioes.length > 1">
            <img src="../../../../assets/anfitriao.svg" alt="Anfitriões" title="Anfitriões"><b> Anfitriões</b>
          </div>
        </div>
      </div>
      <div class="row py-3" *ngFor="let exp of modalData.schedule.anfitrioes">
        <div class="col-lg-6 col-sm-12 d-flex align-items-center">
          <div class="icoFirstLetterConvidado" [style]="'background-color:'+exp.bg+';margin-right: 5px;margin-left: 5px;'">{{ exp.nome.substring(0,1).toUpperCase() }}</div>
          <span>{{ exp.email }}</span>
        </div>
        <div class="col-lg-6 col-sm-12 d-flex align-items-center">
          <span class="px-2"><img src="../../../../assets/phone.svg" alt="telefone contato" title="telefone contato"></span>
          <span>{{ exp.telefone | mask:'(00) 00000-0000' }}</span>
        </div>
      </div>

      <!--anfitriao-->
      <div class="row py-3">
        <div class="col-12">
          <div *ngIf="modalData.schedule.mediadores.length ==1">
            <img src="../../../../assets/anfitriao.svg" alt="Mediador" title="Mediador"><b> Mediador</b>
          </div>
          <div *ngIf="modalData.schedule.mediadores.length > 1">
            <img src="../../../../assets/anfitriao.svg" alt="Mediadores" title="Mediadores"><b> Mediadores</b>
          </div>
        </div>
      </div>
      <div class="row py-3" *ngFor="let exp of modalData.schedule.mediadores">
        <div class="col-lg-6 col-sm-12 d-flex align-items-center">
          <div class="icoFirstLetterConvidado" [style]="'background-color:'+exp.bg+';border-color:'+exp.bg+';margin-right: 5px;margin-left: 5px;'">{{ exp.nome.substring(0,1).toUpperCase() }}</div>
          <span>{{ exp.email }}</span>
        </div>
        <div class="col-lg-6 col-sm-12 d-flex align-items-center">
          <span class="px-2"><img src="../../../../assets/phone.svg" alt="telefone contato" title="telefone contato"></span>
          <span>{{ exp.telefone | mask:'(00) 00000-0000' }}</span>
        </div>
      </div>

      <!--Participant-->
      <div class="row py-3">
        <div class="col-12">
          <div *ngIf="modalData.schedule.participantes.length == 1">
            <img src="../../../../assets/participant.svg" alt="Participante" title="Participante"><b> Participante</b>
          </div>
          <div *ngIf="modalData.schedule.participantes.length > 1">
            <img src="../../../../assets/participants.svg" alt="Participantes" title="Participantes"><b> Participantes</b>
          </div>
        </div>
      </div>
      <div class="row py-3" *ngFor="let exp of modalData.schedule.participantes">
        <div class="col-lg-6 col-sm-12 d-flex align-items-center">
          <div class="icoFirstLetterConvidado" [style]="'background-color:'+exp.bg+';border-color:'+exp.bg+';margin-right: 5px;margin-left: 5px'">{{ exp.nome.substring(0,1).toUpperCase() }}</div>
          <span>{{ exp.email }}</span>
        </div>
        <div class="col-lg-6 col-sm-12 d-flex align-items-center">
          <span class="px-2"><img src="../../../../assets/phone.svg" alt="telefone contato" title="telefone contato"></span>
          <span>{{ exp.telefone | mask:'(00) 00000-0000'}}</span>
        </div>
      </div>

    </div>
  </ng-template>

</div>
