import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../../../_entidade/LocalStorage';

@Component({
  selector: 'app-orderDetails-3',
  templateUrl: './orderDetails3.component.html',
  styleUrls: ['../_formSteps.component.scss'] 
})
export class OrderDetails3Component implements OnInit{
  @Input() item: any
  @Output() excluirItem = new EventEmitter<any>();
  @Output() editar = new EventEmitter<any>();
  public visibilidadeDetalhes: boolean=false;


  constructor(
    public storage: LocalStorage,
    private route: Router,
  ) {

  }

  ngOnInit(): void {

  }
   

}
