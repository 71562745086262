
<ng-container>
  <div class="wrapper-content" *ngIf="!dadosAtualizados">
    <h2>Dados Pessoais</h2>
    <ng-container>
      <form [formGroup]="camposForm" autoComplete="off">
        <div class="wrapper-form content-top">
          <div class="row align-items-center">
            <!--NOME-->
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Nome da Completo</mat-label>
                <input matInput type="text" class="col-lg-12" formControlName="nome">
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
            <!--DATA DE NASCIMENTO-->
            <div class="col-md-4">
              <mat-form-field *ngIf="!hideDataNasc" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label for="nascimento">Data de Nascimento</mat-label>
                <input matInput [matDatepicker]="picker2" type="text" formControlName="nascimento" appMaskDate maxlength="10">
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </div>
            <!--CELULAR-->
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Celular</mat-label>
                <input matInput type="text" class="col-lg-12" mask="(00) 00000-0000" formControlName="celular" placeholder="Celular">
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
            <!--TELEFONE FIXO-->
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Telefone Fixo</mat-label>
                <input matInput type="text" class="col-lg-12" mask="(00) 0000-0000" formControlName="telFixo" placeholder="Telefone Fixo">
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
            <!--EMAIL-->
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo" autoComplete="off">
                <mat-label>E-mail</mat-label>
                <input matInput type="text" required class="email" formControlName="email" autoComplete="off" placeholder="E-mail">
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
            <!--PROFISSAO-->
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-form-field class="w-100">
                <mat-label>Profissão:</mat-label>
                <mat-select formControlName="profissao">
                  <mat-option value="Administrador">Administrador</mat-option>
                  <mat-option value="Advogado">Advogado</mat-option>
                  <mat-option value="Babá">Babá</mat-option>
                  <mat-option value="Bancário">Bancário</mat-option>
                  <mat-option value="Comerciante">Comerciante</mat-option>
                  <mat-option value="Contador">Contador</mat-option>
                  <mat-option value="Corretor de Imóveis">Corretor de Imóveis</mat-option>
                  <mat-option value="Despachante">Despachante</mat-option>
                  <mat-option value="Empregada Doméstica">Empregada Doméstica</mat-option>
                  <mat-option value="Engenheiro">Engenheiro</mat-option>
                  <mat-option value="Escrevente">Escrevente</mat-option>
                  <mat-option value="Jornalista">Jornalista</mat-option>
                  <mat-option value="Médico">Médico</mat-option>
                  <mat-option value="Motorista Profissional">Motorista Profissional</mat-option>
                  <mat-option value="Professor">Professor</mat-option>
                  <mat-option value="Servidor Público">Servidor Público</mat-option>
                  <mat-option value="Taxista">Taxista</mat-option>
                  <mat-option value="Vigilante">Vigilante</mat-option>
                  <mat-option value="Outra">Outra</mat-option>
                </mat-select>
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6" *ngIf="camposForm?.get('profissao')?.value === 'Outra' ">
              <mat-form-field appearance="outline" class="ps-2 pt-0 w-100" >
                <mat-label>Outra Profissão</mat-label>
                <input matInput type="text" class="" formControlName="profissaoAvulso" placeholder="Informe a profissão">
              </mat-form-field>
            </div>
          </div>
        </div>
        <br />
        <h2>Objetivo</h2>
        <div class="wrapper-form content-top">
          <div class="row align-items-center">
            <div class="col-12 col-md-6 col-lg-12 col-xl-12">
              <mat-form-field class="w-100">
                <mat-label>Objetivo:</mat-label>
                <mat-select formControlName="objetivo">
                  <mat-option value="Primeiro Emprego">Primeiro Emprego</mat-option>
                  <mat-option value="Jovem Aprendiz">Jovem Aprendiz</mat-option>
                  <mat-option value="Estágio">Estágio</mat-option>
                  <mat-option value="Auxiliar">Auxiliar</mat-option>
                  <mat-option value="Escrevente">Escrevente</mat-option>
                  <mat-option value="OUTROS">Outros</mat-option>
                </mat-select>
                <mat-error>Preenchimento obrigatório</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <h2>Formação Acadêmica</h2>
        <div class="wrapper-form content-top">
          <div class="row align-items-center" formArrayName="formacaoAcademica">
            <div *ngFor="let academico of formacaoAcademica.controls; let i = index, let last=last" [formGroupName]="i" class="row d-flex alinhamentoCamposEmLinha position-relative" >
              <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                <mat-form-field class="w-100">
                  <mat-label>Formação Acadêmica:</mat-label>
                  <mat-select formControlName="formacaoAcademica">
                    <mat-option value="Ensino Fundamental Incompleto">Ensino Fundamental Incompleto</mat-option>
                    <mat-option value="Ensino Fundamental em Andamento">Ensino Fundamental em Andamento</mat-option>
                    <mat-option value="Ensino Fundamental Completo">Ensino Fundamental Completo</mat-option>
                    <mat-option value="Ensino Médio Incompleto">Ensino Médio Incompleto</mat-option>
                    <mat-option value="Ensino Médio em Andamento">Ensino Médio em Andamento</mat-option>
                    <mat-option value="Ensino Médio Completo">Ensino Médio Completo</mat-option>
                    <mat-option value="Ensino Técnico Incompleto">Ensino Técnico Incompleto</mat-option>
                    <mat-option value="Ensino Técnico em Andamento">Ensino Técnico em Andamento</mat-option>
                    <mat-option value="Ensino Técnico Completo">Ensino Técnico Completo</mat-option>
                    <mat-option value="Ensino Superior Incompleto">Ensino Superior Incompleto</mat-option>
                    <mat-option value="Ensino Superior em Andamento">Ensino Superior em Andamento</mat-option>
                    <mat-option value="Ensino Superior Completo">Ensino Superior Completo</mat-option>
                    <mat-option value="Pós-Graduação Especialização Incompleto">Pós-Graduação Especialização Incompleto</mat-option>
                    <mat-option value="Pós-Graduação Especialização em Andamento">Pós-Graduação Especialização em Andamento</mat-option>
                    <mat-option value="Pós-Graduação Especialização Completo">Pós-Graduação Especialização Completo</mat-option>
                    <mat-option value="Pós-Graduação MBA Incompleto">Pós-Graduação MBA Incompleto</mat-option>
                    <mat-option value="Pós-Graduação MBA em Andamento">Pós-Graduação MBA em Andamento</mat-option>
                    <mat-option value="Pós-Graduação MBA Completo">Pós-Graduação MBA Completo</mat-option>
                    <mat-option value="Mestrado Incompleto">Mestrado Incompleto</mat-option>
                    <mat-option value="Mestrado em Andamento">Mestrado em Andamento</mat-option>
                    <mat-option value="Mestrado Completo">Mestrado Completo</mat-option>
                    <mat-option value="Doutorado Incompleto">Doutorado Incompleto</mat-option>
                    <mat-option value="Doutorado em Andamento">Doutorado em Andamento</mat-option>
                    <mat-option value="Doutorado Completo">Doutorado Completo</mat-option>
                    <mat-option value="Pós-Doutorado Incompleto">Pós-Doutorado Incompleto</mat-option>
                    <mat-option value="Pós-Doutorado em Andamento">Pós-Doutorado em Andamento</mat-option>
                    <mat-option value="Pós-Doutorado Completo">Pós-Doutorado Completo</mat-option>
                  </mat-select>
                  <mat-error>Preenchimento obrigatório</mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo" autoComplete="off">
                  <mat-label>Instituição</mat-label>
                  <input matInput type="text" required class="email" formControlName="instituicaoAcademica" autoComplete="off" placeholder="Preencha o nome da Instituição de Ensino">
                  <mat-error>Preenchimento obrigatório</mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6 col-lg-2 col-xl-2">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo" autoComplete="off">
                  <mat-label for="periodoAnoInicio">Período Início</mat-label>
                  <input matInput [matDatepicker]="picker" type="text" formControlName="periodoAnoInicio" appMaskDate maxlength="10" placeholder="dd/mm/aaaa">
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6 col-lg-2 col-xl-2">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo" autoComplete="off">
                  <mat-label for="periodoAnoTermino">Período Término</mat-label>
                  <input matInput [matDatepicker]="picker1" type="text" formControlName="periodoAnoTermino" appMaskDate maxlength="10" placeholder="dd/mm/aaaa">
                  <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
              </div>
              <div class="col-12 col-md-6 col-lg-1 col-xl-1 position-relative posicaoBotaoDelete d-flex align-items-center" *ngIf="i > 0">
                <button mat-flat-button type="button" class="botao position-absolute" (click)="remFormacaoAcademica(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <div class="d-flex col-12 col-md-6 offset-md-6 col-lg-6 offset-lg-6 col-xl-6 offset-xl-6 justify-content-end" *ngIf="last">
                <button mat-button class="w-50 my-2" type="button" (click)="addFormacaoAcademica()">Adicionar nova Formação</button>
              </div>
            </div>
          </div>
        </div>
        <h2>Experiência Profissional</h2>
        <div class="wrapper-form content-top">
          <div class="row align-items-center" formArrayName="experienciaProfissional">
            <div *ngFor="let profissional of experienciaProfissional.controls; let i = index, let last=last" [formGroupName]="i" class="row d-flex alinhamentoCamposEmLinha position-relative" >
                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo" autoComplete="off">
                    <mat-label>Empresa</mat-label>
                    <input matInput type="text" formControlName="experienciaProfissionalEmpresa" autoComplete="off" placeholder="preencha o nome ou razão social da empresa">
                    <mat-error>Preenchimento obrigatório</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo" autoComplete="off">
                    <mat-label>Cargo</mat-label>
                    <input matInput type="text" class="" formControlName="experienciaProfissionalCargo" autoComplete="off" placeholder="Nome da atividade principal">
                    <mat-error>Preenchimento obrigatório</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-11 col-lg-11 col-xl-11">
                  <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo" autoComplete="off">
                    <mat-label>Atividades</mat-label>
                    <textarea matInput (input)="onInput()" [attr.maxlength]="maxCharacters" rows="5" cols="40" formControlName="experienciaProfissionalAtividades" autoComplete="off" placeholder="Preencha as atividades desenvolvidas">
                    </textarea>
                    <div *ngIf="showRemainCounter">
                      <span>{{ maxCharacters - getRemainingCharacters(i)  }} de {{ maxCharacters }}</span>&nbsp;caracteres restantes
                    </div>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-1 col-lg-1 col-xl-1 position-relative posicaoBotaoDelete d-flex align-items-center" *ngIf="i > 0">
                  <button mat-flat-button type="button" class="botao" (click)="remExperienciaProfissional(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
                <div class="d-flex col-12 col-md-6 offset-md-6 col-lg-6 offset-lg-6 col-xl-6 offset-xl-6 justify-content-end" *ngIf="last">
                  <button mat-button class="w-50 my-2" type="button position-absolute" (click)="addExperienciaProfissional()">Adicionar Experiência Profissional</button>
                </div>
              </div>
            </div>
        </div>
        <h2>Cursos Livres</h2>
        <div class="wrapper-form content-top">
          <div class="row align-items-center" formArrayName="cursosLivres">
            <div *ngFor="let cursos of cursosLivres.controls; let i = index, let last=last" [formGroupName]="i" class="row d-flex alinhamentoCamposEmLinha position-relative" >
              <div class="col-12 col-md-4 col-lg-4 col-xl-4">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo" autoComplete="off">
                  <mat-label>Nome do Curso</mat-label>
                  <input matInput type="text" formControlName="cursosLivresNomeCurso" autoComplete="off" placeholder="preencha o nome do curso ou atividade ex-curricular">
                </mat-form-field>
              </div>
              <div class="col-12 col-md-4 col-lg-4 col-xl-4">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo" autoComplete="off">
                  <mat-label>Instituicao</mat-label>
                  <input matInput type="text" formControlName="cursosLivresInstituicao" autoComplete="off" placeholder="preencha o nome da instituição de ensino">
                  <mat-error>Preenchimento obrigatório</mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-3 col-lg-3 col-xl-3">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo" autoComplete="off">
                  <mat-label>Ano</mat-label>
                  <input matInput type="text" formControlName="cursosLivresAno" inputmode="numeric" mask="0000" autoComplete="off" placeholder="ex. 2024">
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6 col-lg-1 col-xl-1 position-relative posicaoBotaoDelete d-flex align-items-center" *ngIf="i > 0">
                <button mat-flat-button type="button" class="botao position-absolute" (click)="remCursoslivres(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <div class="d-flex col-12 col-md-6 offset-md-6 col-lg-6 offset-lg-6 col-xl-6 offset-xl-6 justify-content-end" *ngIf="last">
                <button mat-button class="w-50 my-2" type="button" (click)="addCursosLivres()">Adicionar Cursos Livres</button>
              </div>
            </div>
          </div>
        </div>
        <h2>Idiomas</h2>
        <div class="wrapper-form content-top">
          <div class="row align-items-center" formArrayName="Idiomas">
            <div *ngFor="let idioma of idiomas.controls; let i = index, let last=last" [formGroupName]="i" class="row d-flex alinhamentoCamposEmLinha position-relative" >
              <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo" autoComplete="off">
                  <mat-label>Língua</mat-label>
                  <input matInput type="text" formControlName="IdiomasLingua" autoComplete="off" placeholder="informe o nome da lingua estrangeira">
                </mat-form-field>
              </div>
              <div class="col-12 col-md-5 col-lg-5 col-xl-5">
                <mat-form-field class="w-100">
                  <mat-label>Nível:</mat-label>
                  <mat-select formControlName="idiomasNivel">
                    <mat-option value="Básico">Básico</mat-option>
                    <mat-option value="Intermediário">Intermediário</mat-option>
                    <mat-option value="Avançado">Avançado</mat-option>
                    <mat-option value="Fluente">Fluente</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6 col-lg-1 col-xl-1 position-relative posicaoBotaoDelete d-flex align-items-center" *ngIf="i > 0">
                <button mat-flat-button type="button" class="botao position-absolute" (click)="remIdiomas(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <div class="d-flex col-12 col-md-6 offset-md-6 col-lg-6 offset-lg-6 col-xl-6 offset-xl-6 justify-content-end" *ngIf="last">
                <button mat-button class="w-50 my-2" type="button" (click)="addIdiomas()">Adicionar Cursos Livres</button>
              </div>
            </div>
          </div>
        </div>

        <div class="wrapper-form content-top">
          <app-uploadFiles (setaArquivos)="setaArquivosPolo($event)"
                           (resetaArquivos)="resetaArquivosPolo($event)"
                           [tipo]="1">
          </app-uploadFiles>
        </div>

        <div class="col-md-12" *ngIf="edicao == false">
          <span style="color:red" *ngIf="erroCadastro">{{erroCadastro}}</span>
          <button class="d-block mx-auto px-3 py-2 my-4" id="cadastrov2" (click)="cadastrar()">Cadastrar</button>
        </div>
        <br />
      </form>
    </ng-container>
  </div>
</ng-container>
<ng-container>
  <div class="wrapper-content" *ngIf="dadosAtualizados" style="height: 44vh;">
    <div class="row">
      <div class="col-12">
        <h2>Curriculum cadastrado com sucesso</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h2>Aguarde pelo nosso contato</h2>
      </div>
    </div>
    <br />
    <br />
  </div>
</ng-container>
