import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginaService {

  constructor(
  ) {
  }

  validarPesquisa(conteudoPagina: any): boolean {
    let preenchimentoMinimo = false;
    if (conteudoPagina.minFiltro 
      //&& document.getElementById('alertFiltro')
      ) {
      for (let key in conteudoPagina.dadosFiltro.default) {

        if (typeof conteudoPagina.dadosFiltro.default[key] != 'object') {
          if (conteudoPagina.dadosFiltro.default[key] != '' && key != 'Pagina') {
            preenchimentoMinimo = true;
          }
        } else {
          //console.log(conteudoPagina.dadosFiltro.default[key])
          if (conteudoPagina.dadosFiltro.default[key]['Descricao'] != '')
            preenchimentoMinimo = true;
        }
      }
    } else {
      preenchimentoMinimo = true;
    }
    if (preenchimentoMinimo) {

      // if (document.getElementById('alertFiltro')) {
      //   (<HTMLInputElement>document.getElementById('alertFiltro')).style.display = 'none';
      //   conteudoPagina.pesquisar = true;
      // }

      conteudoPagina.edicao = false;
      conteudoPagina.paginaAtual = -1;
    } else {
      // if (document.getElementById('alertFiltro'))
      //   (<HTMLInputElement>document.getElementById('alertFiltro')).style.display = '';

    }
    //console.log(preenchimentoMinimo)
    return preenchimentoMinimo;

  }


}
