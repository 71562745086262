<div class="row">
  <h2>Documentos:</h2>


  <form [formGroup]="camposForm">
    <div class="content-list" [class]="validarArquivoDocumentos">
      <!--[prodAtu]="prodAtu"-->
      <app-uploadFiles (setaArquivos)="setaArquivos($event)"
                       [filesAtu]="prodAtu?.documentos"
                      (resetaArquivos)="resetaArquivos($event)"
                      >
                    </app-uploadFiles>
    </div>

    <div class="col-md-12">
      <div class="row">
        <h3>Dados dos Assinadores:</h3>
      </div>
    </div>
    <div class="content-list" formArrayName="assinadores">
      <ng-container *ngFor="let item of assinadores.controls; let i = index; let last=last;let first=first" [formGroupName]="i" class="row">
        <div class="row">
          <div class="col-md-5">
            <!--Nome Completo-->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Nome</mat-label>
              <input matInput type="text" class="col-lg-12" formControlName="nomeCompleto"
                     placeholder="Nome" maxlength="400">
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-4">
            <!--Email-->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="email">Email</mat-label>
              <input matInput type="text" class="email" formControlName="email" placeholder="Email" maxlength="300" required>
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <!--CPF-->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="cpf">CPF/CNPJ</mat-label>
              <input matInput type="text"
                     mask="000.000.000-00 || 00.000.000/0000-00"
                     formControlName="cpf" placeholder="CPF/CNPJ">
              <mat-error>
                Preenchimento obrigatório
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-5">
            <!--Tipo-->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Tipo</mat-label>
              <mat-select formControlName="tipo" [compareWith]="compareTipo">
                <mat-option *ngFor="let tipo of tipos" [value]="tipo">
                  {{ tipo.Descricao }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-3" *ngIf="assinadores.controls[i].get('tipo')?.value?.Id == 'E'">
            <!--Celular-->
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="telefone">Celular</mat-label>
              <input matInput type="text" mask="(00) 0 0000-0000" formControlName="telefone" name="telefone">
            </mat-form-field>
          </div>


          <div class="col-md-2" *ngIf="!first">
            <button class="w-100 my-2 add-new-name" type="button" (click)="remAssinatura(i)">Remover</button>
          </div>

          <div class="col-md-12">
            <hr />
          </div>
        </div>
      </ng-container>
      <!--Adicionar novo e Reiniciar o Form-->
      <div class="row">
        <div class="col-md-2">
          <button class="w-100 my-2 add-new-name" type="button" (click)="addAssinatura()">Adicionar Novo</button>
        </div>
      </div>
    </div>


    <div class="col-md-12">
      <div class="row">
        <h3>Dados dos Leitores em cópia:</h3>
      </div>
    </div>

    <div class="content-list" formArrayName="copia">
      <ng-container *ngFor="let copia of copia.controls; let i = index; let last=last;let first=first" [formGroupName]="i" class="row">
        <div class="row">
          <div class="col-md-5">

            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="nome">Nome</mat-label>
              <input matInput type="text" formControlName="nome" name="nomeCompleto" maxlength="400">
            </mat-form-field>
          </div>

          <div class="col-md-5">

            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="email">Email</mat-label>
              <input matInput type="text" class="email" formControlName="email" placeholder="Email" maxlength="300">
            </mat-form-field>
          </div>
          <div class="col-md-2" *ngIf="!first">
            <button class="w-100 my-2 add-new-name" type="button" (click)="remCopia(i)">Remover</button>
          </div>
        </div>
      </ng-container>

      <!--Adicionar novo e Reiniciar o Form-->
      <div class="row">
        <div class="col-md-2">
          <button class="w-100 my-2 add-new-name" type="button" (click)="addCopia()">Adicionar Novo</button>
        </div>
      </div>
    </div>
  </form>





  <app-aviso [aviso]="itemAtual?.aviso"></app-aviso>

  <div class="row">
    <div class="col-md-5"></div>
    <div class="col-auto">
      <button type="button" mat-button [routerLink]="['/']" class="btn-next" style="color: white; background-color: green;">Voltar</button>
    </div>
    <div class="col-auto">
      <button mat-button (click)="updatePedido()" class="btn-next"
              style="display: block; margin: 0 auto;">
        Continuar
      </button>
    </div>
  </div>
