<div class="main-content">

  <header [class]="conteudo.class"
           class="bk-cor1"
          [ngClass]="{ 'bg-nav-custom': isScrolled, 'navbar-shrink': isScrolled }">

    <nav class="navbar navigation-container container-lg ">
      <a routerLink="/">
        <img loading="lazy"
             class="navigation-brand logo"
             [src]="isMobile() ? '/assets/cnr/logoCNR.png' : conteudo.logo"
             id="logo" alt="Home" />
      </a>

      <!--hamburger-->
      <input type="checkbox" id="toggler" [(ngModel)]="isCheckboxChecked" />
      <label for="toggler">
        <mat-icon class='menu-list-ico' fontIcon="menu"></mat-icon>
        <mat-icon class='close-menu-ico' fontIcon="close"></mat-icon>
      </label>
      <!--fim-->

      <div class="navigation-menu menu" *ngIf="conteudo" >

        <ul class="list">

          <li style="background: transparent; border: solid 0px; text-align: center; box-shadow: none; height: 100px;" *ngIf="logado == true">
            <div class="foto-perfil isMobile" style="margin: auto ">
              <img *ngIf="usuarioLogado?.fotoPerfil" src="https://lh3.googleusercontent.com/a/ACg8ocLspUUHOgMwjJfzA4uCAZ9kE89FvbIieHR7s83IQ-zl7PS4NfCrEQ=s360-c-no" class="img-circle" alt="User Image">
              H
            </div>
            <h3 class="isMobile" style="padding: 20px;font-size: 21px;">Olá {{usuarioLogado?.nomeUsuario}}</h3>

          </li>

          <li *ngIf="!logado"
              [style.background-color]="conteudo?.bgEntrarColor"
              class="link" [routerLink]="[urlLogin]"
              [style.color]="conteudo?.botaoColor"
              style=" width: 180px; text-align: center;">

             <img [src]="environment.assetsDir+'ico-loginCNR.png'" alt="Logo CNR" >&nbsp;&nbsp;&nbsp;Entrar

          </li>

          <li *ngFor="let option of conteudo.menu"
              class="link"
              [class]="option.class"
              [routerLink]="[option.url]"
              style="background-color: #0E82A8; color: #fff; width: 180px; text-align: center;">

              <!--img *ngIf="option.icon" src='{{option.icon}}' alt=""-->
              <img *ngIf="option.icon" src="../../../../assets/cartCNR.svg">
              &nbsp;&nbsp;&nbsp;
              {{option.label}}
              <ng-container *ngIf="option.class == 'cart-CNR'">
                <span *ngIf="numeroItensCarrinho >= 0" class="notificador-carrinho">
                  {{ numeroItensCarrinho }}
                </span>
              </ng-container>

          </li>


          <li *ngIf="logado == true && divice.isMobile()"
              [style.color]="conteudo?.botaoColor"
              [style.background-color]="conteudo?.bgEntrarColor">
            <a [href]="urlLogin+'?logoff=1'"><img [src]="environment.assetsDir+'icone-sair.svg'" alt="">&nbsp;Sair</a>
          </li>

          <li style="background: transparent;border: solid 0px;height: 0px;box-shadow: none;" class="isWeb" *ngIf="logado == true">

            <div class="foto-perfil isWeb" [matMenuTriggerFor]="beforeMenu">
              <img *ngIf="usuarioLogado?.fotoPerfil" src="https://lh3.googleusercontent.com/a/ACg8ocLspUUHOgMwjJfzA4uCAZ9kE89FvbIieHR7s83IQ-zl7PS4NfCrEQ=s360-c-no" class="img-circle" alt="User Image">
              <div class="primeiraLetra"
                   [style.background-color]="isBack? 'red': '#1b305b'"
                   style="color: white;cursor:pointer; height: 100%; display: flex; flex-wrap: nowrap; flex-direction: column; justify-content: center; font-weight: 600; ">
                {{usuarioLogado.nomeUsuario.substring(0,1).toUpperCase()}}
              </div>
            </div>
            <mat-menu class="isWeb" #beforeMenu="matMenu" xPosition="before">
              <h3 style="padding: 20px;font-size: 21px;">Olá {{usuarioLogado?.nomeUsuario}}</h3>

              <button type="button" (click)="navegar('/admin/meus-dados')" mat-menu-item>
                <img [src]="environment.assetsDir+'icone-editar-conta.svg'" alt="">&nbsp;
                <a>Editar conta</a>
              </button>
              <button type="button" (click)="navegar(urlPainel)" mat-menu-item>

                <img [src]="environment.assetsDir+'icone-meus-pedidos.svg'" alt="">&nbsp;
                <a>Meus pedidos</a>
              </button>
              <button type="button" (click)="navegar('/fadmin/meus-agendamentos')" mat-menu-item>

                <img [src]="environment.assetsDir+'CNR-icone-assinatura.svg'" alt="">&nbsp;
                <a>Mediações</a>
              </button>
              <button type="button" (click)="navegar('/fadmin/calendario-meeting')" mat-menu-item>

                <img [src]="environment.assetsDir+'CNR-icone-assinatura.svg'" alt="">&nbsp;
                <a>Calendário</a>
              </button>
              <button *ngIf="usuarioLogado?.backOffice" type="button" (click)="navegar('/fadmin/relatorio')" mat-menu-item>

                <img [src]="environment.assetsDir+'CNR-icone-assinatura.svg'" alt="">&nbsp;
                <a>Relatório</a>
              </button>
              <button *ngIf="usuarioLogado?.backOffice || isCartorio()" type="button" (click)="navegar('/fadmin/curriculos')" mat-menu-item>

                <img [src]="environment.assetsDir+'CNR-icone-assinatura.svg'" alt="">&nbsp;
                <a>Curriculos</a>
              </button>
              <button *ngIf="usuarioLogado?.backOffice" type="button" (click)="showBackOffice(urlPainel)" mat-menu-item>
                <img class="pt-2" [src]="environment.assetsDir+'CNR-icone-assinatura.svg'" alt="">&nbsp;
                <a style="text-align: left;">BackOffice</a>
              </button>

              <button type="button" (click)="sair()" mat-menu-item>
                <img [src]="environment.assetsDir+'icone-sair.svg'" alt="">&nbsp;
                <a>Sair</a>
              </button>
            </mat-menu>
          </li>
        </ul>

      </div>

    </nav>

  </header>

</div>

