import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from './GlobalService';

@Injectable({
  providedIn: 'root'
})
export class FuncaoGlobal {

  zeroEsquerda(num: any, len: any) {
    var numberWithZeroes = String(num);
    var counter = numberWithZeroes.length;
    while (counter < len) {
      numberWithZeroes = "0" + numberWithZeroes;
      counter++;
    }
    return numberWithZeroes;
  }

  formataData(data: Date) {
    var dia = this.zeroEsquerda(data.getDate(), 2)
    var mes = this.zeroEsquerda(data.getMonth() + 1, 2)
    var ano = data.getFullYear()

    return dia + '/' + mes + '/' + ano;
  }
  formataDataDB(data: Date, inicial: boolean = true) {
    var dia = this.zeroEsquerda(data.getDate(), 2)
    var mes = this.zeroEsquerda(data.getMonth() + 1, 2)
    var ano = data.getFullYear()
    if (inicial)
      return ano + '-' + mes + '-' + dia + ' 00:00:00';
    else
      return ano + '-' + mes + '-' + dia + ' 23:59:59';

  }
}
