<div class="py-0">

  <div class="content-wrapper pt-5 pb-4"
       [style.background]="conteudo?.bkImage ? 'url(/assets/' + conteudo.bkImage + ')':'' "
       [style.background-size]="'cover'"
       style="position: relative; z-index: 0;">

    <h2 *ngIf="conteudo.titulo" [innerHTML]="conteudo.titulo" class="titulo lh-1"></h2>

    <div [style]="isMobile() ? 'width: 82%;': 'width: 53%;'"
         style="margin: 0 auto;"
         class="pt-lg-2 pt-xl-2">
      <div class="subTitulo" *ngIf="conteudo?.subTitulo" [innerHTML]="conteudo.subTitulo"></div>
    </div>

    <div class="row our-partners" style="width: 62%; margin: 0 auto; padding: 25px 0px 25px 0px;">

      <ng-container *ngFor="let item of conteudo.itens">
        <div class="items" [class]="item.class">
          <a [href]="item.link" target="_blank" *ngIf="item.link; else semlink">
            <!--img loading="lazy" [src]="['./../assets/'+item.src]" [class]="item?.classImg" [alt]="item.alt" [title]="item.alt"-->
            <img *ngIf="item.link != true" loading="lazy" [src]="['./../assets/'+item.src]" [class]="item?.classImg" [alt]="item.alt" [title]="item.alt" class="img-partners">
          </a>
          <ng-template #semlink>
            <div [class]="item?.classImg">
              <img *ngIf="item.link != true" loading="lazy" [src]="['./../assets/'+item.src]" [class]="item?.classImg" [alt]="item.alt" [title]="item.alt" class="img-partners">
            </div>
          </ng-template>
        </div>
      </ng-container>

    </div>

  </div>

</div>
