<div class="wrapper-item" *ngIf="!compra?.editar">
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Tipo do Ato</b></h3>
      <p class="info-section">{{compra.tipo.descricao}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Distrito</b></h3>
      <p class="info-section">{{compra.distrito.descricao}}</p>
    </div>
  </div>
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Concelho</b></h3>
      <p class="info-section">{{compra.concelho.descricao}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Freguesia</b></h3>
      <p class="info-section">{{compra.freguesia.descricao}}</p>
    </div>
  </div>
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Nome do Pai</b></h3>
      <p class="info-section">{{compra.pai}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Nome da Mãe</b></h3>
      <p class="info-section">{{compra.mae}}</p>
    </div>
  </div>
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Nome</b></h3>
      <p class="info-section">{{compra.nome}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Conjuge</b></h3>
      <p class="info-section">{{compra.conjuge}}</p>
    </div>
  </div>
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Cota Atual</b></h3>
      <p class="info-section">{{compra.cota}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Página</b></h3>
      <p class="info-section">{{compra.pagina}}</p>
    </div>
  </div>
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Data do Ato</b></h3>
      <p class="info-section">{{compra.dataato | date: 'dd/MM/yyyy':'UTC'}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Informação Adicional</b></h3>
      <p class="info-section">{{compra.informacaoAdicional}}</p>
    </div>
  </div>
  <h2 class="title-info-solicitante">Endereço para envio</h2>
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>País</b></h3>
      <p class="info-section">{{compra.dadosEntrega.pais}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Estado / Província</b></h3>
      <p class="info-section">{{compra.dadosEntrega.estado}}</p>
    </div>
  </div>
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Cidade</b></h3>
      <p class="info-section">{{compra.dadosEntrega.cidade}}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Logradouro</b></h3>
      <p class="info-section">{{compra.dadosEntrega.enderecoCompleto}}</p>
    </div>

    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>CERP</b></h3>
      <p class="info-section">{{compra?.idItem}}</p>
    </div>
  </div>
</div>

<app-form-8 *ngIf="compra?.editar" [itemEdicao]="compra" [edicao]="true" (cancelarEdicao)="cancelarEdicao(compra)" (salvarItem)="salvarItem($event)"
             [itemAtual]="compra.produto"></app-form-8>
