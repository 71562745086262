<div class="wrapper-item" *ngIf="!compra?.editar">
  <div class="row" *ngIf="compra?.finalidade">
    <div class="wrapper-info col-lg-4">
      <h3 class="info-section"><b>Finalidade</b></h3>
      <p class="info-section">{{ compra?.finalidade?.descricao }}</p>
    </div>
  </div>

  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Estado</b></h3>
      <p class="info-section">{{ compra?.estado?.Nome }}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Cidade</b></h3>
      <p class="info-section">{{ compra?.municipio?.Nome }}</p>
    </div>
  </div>
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section" *ngIf="!compra?.produto?.idFormSubtipo">
        <b>Matrícula do Imóvel</b>
      </h3>
      <h3 class="info-section" *ngIf="compra?.produto?.idFormSubtipo">
        <b>IPTU</b>
      </h3>
      <p class="info-section">{{ compra.matricula }}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>CEP</b></h3>
      <p class="info-section">{{ compra.cep }}</p>
    </div>
  </div>
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Tipo de logradouro</b></h3>
      <p class="info-section">{{ compra.tipoLogradouro }}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Logradouro</b></h3>
      <p class="info-section">{{ compra.logradouro }}</p>
    </div>
  </div>
  <div class="row">
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Número</b></h3>
      <p class="info-section">{{ compra.numero }}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>Bairro</b></h3>
      <p class="info-section">{{ compra.bairro }}</p>
    </div>
    <div class="wrapper-info col-lg-6">
      <h3 class="info-section"><b>CERP</b></h3>
      <p class="info-section">{{ compra?.idItem }}</p>
    </div>
  </div>

  <h2 class="title-info-solicitante">Complemento</h2>
  <ng-container *ngFor="let complemento of compra.complemento">
    <div class="row">
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Tipo de logradouro</b></h3>
        <p class="info-section">{{ complemento.tipo }}</p>
      </div>
      <div class="wrapper-info col-lg-6">
        <h3 class="info-section"><b>Número</b></h3>
        <p class="info-section">{{ complemento.valor }}</p>
      </div>
    </div>
  </ng-container>
</div>

<app-form-1
  *ngIf="compra?.editar"
  [itemEdicao]="compra"
  [edicao]="true"
  (salvarItem)="salvarItem($event)"
  (cancelarEdicao)="cancelarEdicao(compra)"
  [itemAtual]="compra.produto"
></app-form-1>
