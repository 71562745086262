import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';

@Component({
  selector: 'app-encontre-servicos',
  templateUrl: './encontreServicos.component.html',
  styleUrls: ['./encontreServicos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EncontreServicosComponent implements OnInit {
  @Input() conteudo: any;

  estadosBrasil = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO'
  ];
  cidadesBrasil: { [key: string]: string[] } = {
    "SP": ["Município 1.1", "Município 1.2", "Município 1.3"],
    "RJ": ["Município 2.1", "Município 2.2", "Município 2.3"],
    "MG": ["Município 3.1", "Município 3.2", "Município 3.3"]
  };
  selecioneEstado: string = '';
  selecioneCidade: string = '';
  
  constructor() { }

  ngOnInit(): void {

  }

  onStateChange(): void {

    this.selecioneCidade = '';
  }

  onCityChange(): void {
    //console.log('teste');
    
  }

}
