import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-meusPedidosOrderDetails-6',
  templateUrl: './meusPedidosOrderDetail6.component.html',
  styleUrls: ['./../_meus-pedidos.component.scss'] 
})
export class MeusPedidosOrderDetails6Component  {
  @Input() compra: any

}
