<!--<img style="display:none" id="logoPrint" src="/assets/img-parceiros-CNR.png" />-->
<section class="header-pedido" id="titleMeusPedidos">
  <div class="container">
    <h2>Relatório</h2>
  </div>
</section>
<div class="wrapper-content e-cartorio">
  <div class="container" id="conpr">
    <form [formGroup]="camposForm" id="filtro">
      <ng-container>
        <div class="row">
          <b>Filtros:</b>
          <div class="col-md-2">
            <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="dataInicial">Data inicial</mat-label>
              <input matInput [matDatepicker]="picker" type="text" formControlName="dataInicial" appMaskDate
                     maxlength="10" (dateInput)="pesquisar()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="dataFinal">Data final</mat-label>
              <input matInput [matDatepicker]="picker2" type="text" formControlName="dataFinal" appMaskDate
                     maxlength="10" (dateInput)="pesquisar()">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Número do pedido</mat-label>
              <input matInput type="text" (input)="pesquisar()" class="col-lg-12" formControlName="numeroPedido"
                     placeholder="Número do pedido">
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Tipo ato</mat-label>
              <mat-select formControlName="tipoAto" (selectionChange)="pesquisar()">
                <mat-option value="">Todos</mat-option>
                <mat-option value="Certidão">Certidão</mat-option>
                <mat-option value="Mediação">Mediação</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-4">

            <button type="button" class="btnDownload d-flex justify-content-center" (click)="download()">
              <div id="button-xls" class="py-0 d-flex">
                <div class="material-symbols-outlined" style="height: 44px; padding-top: 0.4rem;">description</div>
                <div style="margin-top: 0px; padding-top: 0.6rem; height: 44px;">XLS</div>
              </div>
            </button>
          </div>
        </div>

      </ng-container>
    </form>

    <ng-container *ngFor="let item of pedidos; let i = index">

      <div class="card-item" [id]="i+'p'">
        <div class="row">
          <div class="col-lg-1" *ngIf="isBack">
            <h3 class="title-section"><b>Data</b></h3>
            {{item?.dtPedido| date: 'dd/MM/yyyy'}}

          </div>
          <div class="col-lg-1">
            <h3 class="title-section"><b>Tipo ato</b></h3>
            {{item?.tipoAto}}

          </div>
          <div class="col-lg-2">
            <h3 class="title-section"><b>Nº Pedido</b></h3>
            {{item?.nuPedido}}
          </div>
          <div class="col-lg-2">
            <h3 class="title-section"><b>Nº Ato</b></h3>
            {{item?.nuAto}}
          </div>
          <div class="col-lg-2">
            <h3 class="title-section"><b>Total do Pedido</b></h3>
            {{item?.vlTotalPedido.toFixed(2) | currency:'BRL'}}
          </div>
          <mat-divider [inset]="true" style="margin-top: 10px;margin-bottom: 10px;"></mat-divider>

          <div class="col-lg-4">

            <div class="row" style="background-color: #e6eba9">
              <div class="col-lg-6" *ngIf="item?.tipoAto == 'Mediação'">
                <h3 class="title-section"><b>Adicional Adm 1</b></h3>
                {{item?.valorMeet.toFixed(2) | currency:'BRL'}}
              </div>
              <div class="col-lg-6">
                <h3 class="title-section" *ngIf="item?.tipoAto == 'Mediação'"><b>Adicional Adm 2</b></h3>
                <h3 class="title-section" *ngIf="item?.tipoAto == 'Certidão'"><b>Adicional Adm</b></h3>
                {{item?.adicinalAdm.toFixed(2) | currency:'BRL'}}
              </div>
            </div>
          </div>
          <div class="col-lg-4">

            <div class="row" style="background-color: #abbb9f">

              <div class="col-lg-4">
                <h3 class="title-section"><b>Taxa Cnr</b></h3>
                {{item?.taxaCnr.toFixed(2) | currency:'BRL'}}
              </div>
              <div class="col-lg-3">
                <h3 class="title-section"><b>Custo MP</b></h3>
                {{item?.custoMp.toFixed(2) | currency:'BRL'}}
              </div>

              <div class="col-lg-5" *ngIf="item?.tipoAto == 'Mediação'">
                <h3 class="title-section"><b>Honorário Mediação</b></h3>
                {{item?.honorarioMediacao.toFixed(2) | currency:'BRL'}}
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="row" style="background-color: #C6E2FF ">

              <div class="col-lg-5" *ngIf="item?.tipoAto == 'Certidão'">
                <h3 class="title-section"><b>Diligência</b></h3>
                {{item?.etehss.toFixed(2) | currency:'BRL'}}
              </div>
              <div class="col-lg-7" *ngIf="item?.tipoAto == 'Certidão'">
                <h3 class="title-section"><b>Emolumento Cartório</b></h3>
                {{item?.emolumentoCartorio.toFixed(2) | currency:'BRL'}}
              </div>
            </div>
          </div>
          </div>
      </div>
    </ng-container>
    <h2></h2>
    <!--<mat-paginator id="paginacaoItens" [length]="totalItens" [pageSize]="tamanhoPagina"
                   [pageSizeOptions]="[5, 10, 25, 100]" (page)="alterarPagina($event)" aria-label="Selecionar página">
    </mat-paginator>-->
    <h2></h2>
  </div>
</div>
