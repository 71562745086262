<div style="position: relative; "
     [style.z-index]="deviceService.isMobile()? '1':'1'">


    <div id="0" class="wrapper-itens"
    *ngIf="!conteudo?.subTipo && conteudo?.Submenu != true && !conteudo?.versao"
    [class]="conteudo.class"
    [style]="conteudo.style">

 <div id="1" class="row content-itens">

   <h2 *ngIf="conteudo.titulo != null" [class]="conteudo.class">{{conteudo.titulo}}zxcvzxcv</h2>

   <ng-container id="3" *ngFor="let item of conteudo.itens">
     <div *ngIf="item.bk">
       &nbsp;
     </div>

     <div [class]="'col-lg-'+item.col+ ' col-lg-'+item.col+ ' col-12 col-sm-12 '" style="cursor:pointer">

       <div class="card-item" [class]="item.class || conteudo.cardTipo">

         <a [routerLink]="item.link">

           <div *ngIf="conteudo.txtPosition == 'top'" class="text-wrapper">

             <h3 class="title-card">{{item.titulo}}</h3>

             <p *ngIf="item.descricao != null" class="description-card">
               {{item.descricao}}
             </p>

             <a [routerLink]="item.aLink">
               {{item.btnTxt}}
               <img src="./assets/seta-next.svg" alt="">
             </a>

           </div>

           <div class="icon-wrapper" *ngIf="item.icon">
             <div class="fundo-img">
               <img loading="lazy" [src]="['./../assets/'+item.icon]" alt="Acesso rápido" class="mb-2">
             </div>
           </div>
           <div *ngIf="conteudo.txtPosition != 'top'" class="text-wrapper">
             <h3 class="title-card">{{item.titulo}}</h3>
             <p class="description-card" [innerHTML]="item.descricao"></p>

           </div>
         </a>
       </div>
     </div>
   </ng-container>
 </div>
</div>

<div class="services-categories first-md margem-top"
    #servicesCategories
    *ngIf="conteudo.Submenu == true"
    [ngClass]="{'meinu-con': conteudo.Submenu == true}"
    [class]="conteudo.class"
    [style]="conteudo.style">

 <div class="row cardSequencial-titulo mt-4 mt-sm-4 mt-md-5 pt-md-5 mt-lg-5 pt-lg-5" *ngIf="conteudo?.titulo">
   <div class="col-12 col-sm-12 col-md-12 col-lg-12 tituloCor1">{{conteudo.titulo}}</div>
 </div>

 <div class="row cardSequencial-subtitulo" *ngIf="conteudo?.subTitulo">
   <div class="col-12 col-sm-12 col-md-12 col-lg-12 mx-auto tituloCor1">{{conteudo.subTitulo}}</div>
 </div>

 <div #containerPrincipal class="categories"
      [style.margin]="deviceService.isMobile()? '2rem 0 2rem 0':'5rem 0 5rem 0'">

   <div id="bloco-cards" class="wrapper-categories">

     <div class="row center-sm">

       <div id="bloco-principal"
            class="card-item d-flex "
            *ngFor="let item of conteudo.itens; let i = index"
            (click)="toggleSubcategorias(i, $event)"
            [class]="item?.classDestaqueBg"
            [class.show-subcategories]="itemExpandidoIndex == i"
            style="cursor:pointer; background-color: #F5FAFF; border: 1px solid #18244f;">

         <ng-container *ngIf="!item.link && !item.aLink">
           <a [routerLink]="item.link" class="category">
             <div class="wrapper-all "
                  [style]="isMobile() ? 'display: flex; width: 100%;' : '' ">
               <img loading="lazy" [src]="['./../assets/'+item.icon]" alt="Acesso rápido" class="mb-2">
               <p class="title-card" [class]="item?.classDestaqueTxt" [style]="isMobile() ? 'padding: 1.5rem 0 1.5rem 1rem;' : ''">{{item.titulo}}</p>
             </div>
           </a>
         </ng-container>

         <ng-container *ngIf="item.link">
           <a [routerLink]="item.link" class="category">
             <div class="wrapper-all "
                  [style]="isMobile() ? 'display: flex; width: 100%;' : '' ">
               <img loading="lazy" [src]="['./../assets/'+item.icon]" alt="Acesso rápido" class="mb-2">
               <p class="title-card" [class]="item?.classDestaqueTxt" [style]="isMobile() ? 'padding: 1.5rem 0 1.5rem 1rem;' : ''">{{item.titulo}}</p>
             </div>
           </a>
         </ng-container>

         <ng-container *ngIf="!item.link && item.aLink != ''">
           <a [href]="item.aLink" class="category" target="_blank">
             <div class="wrapper-all" *ngIf="item.icon">
               <img loading="lazy" [src]="['./../assets/'+item.icon]" alt="Acesso rápido" class="mb-2">
               <p class="title-card" [style]="isMobile() ? 'padding: 1.5rem 0 1.5rem 1rem;' : ''">{{item.titulo}}</p>

             </div>
           </a>
         </ng-container>

         <!--subcategorias item cards-->
         <div id="detalhe-card sdsds"
              *ngIf="item.Itens"
              #subCategorias
              [id]="'subCategorias'+i"
              class="subcategories"
              style='padding: 0px; box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.50); '
              [class.show-subcategories]="itemExpandidoIndex == i"
              (click)="$event.stopPropagation()">

           <div id="container-subItem" class="start-sm">
             <ul id="subItens" class="list-component px-2 py-2">
               <li id="fechar" style="margin-bottom: 0rem !important; border: 0px !important; border-radius: 0 !important;">
                 <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-end">
                   <a (click)="FecharItens($event)" style="width: 16px; height: 16px;" style="cursor: pointer;">
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                       <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                       <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                     </svg>
                   </a>
                 </div>
               </li>

               <li *ngIf="isLoading">
                 <div style="height:30px">
                   <mat-spinner [diameter]="35" style="float:left"></mat-spinner>&nbsp;carregando atos...
                 </div>
               </li>

               <li id="linhaAgrupamento" [routerLink]="subItens.aLink"
                   *ngFor="let subItens of item.Itens; let z = index;"
                   [ngClass]="z%2 === 0 ? 'classBlue' : 'classWhite' " class="mx-2 my-2 px-2 py-2">
                 <!-- padding:0; margin-top: 1rem; margin-bottom: 1rem;-->
                 <!-- linha sem agrupamento -->
                 <div (click)="!subItens?.itemPai ? solicitarNovo(subItens) : subItens?.showNetos ?  subItens.showNetos = false: subItens.showNetos = true"
                      id="itemLinhaNormal" class="wrapper-content-card py-2" style="height: auto;">

                   <div class="col-9 col-md-9">
                     <h3 id="subItensTitulo">{{subItens.titulo}}</h3>
                     <p [innerHTML]="subItens.descricao"></p>
                     <h6 style="font-style: italic;">{{subItens?.cobertura}}</h6>
                   </div>

                   <!-- botao solicitar dentro da linha -->
                   <div id="botao"
                        class="col-md-3"
                        *ngIf="!subItens?.itemPai">

                     <button (click)="solicitarNovo(subItens)"
                             [routerLink]="subItens.aLink"
                             class="btn buttonShowSubItens" style="margin-left: 10px;">
                       Solicitar
                     </button>
                   </div>

                   <!-- seletor -->
                   <div id="seletor"
                        class="col-md-3"
                        style="text-align: center;"
                        *ngIf="subItens?.itemPai">
                     <button class="btn buttonShowSubItens"
                             *ngIf="!subItens?.showNetos"
                             role="button"
                             style="cursor:pointer">
                       Ver Todas
                     </button>
                     <!--<button
                      class="btn buttonShowSubItens"
                      *ngIf="subItens?.showNetos"
                      role="button"
                      >Ocultar</button>-->
                   </div>
                 </div>

                 <!-- linha com agrupamento -->
                 <ul class="list-component mx-2 my-2" *ngIf="subItens?.itemPai && subItens?.showNetos">

                   <li id="linhnaSemAgrupamento" (click)="neto?.periodos ? false : solicitarNovo(neto)"
                       class="service-link netos"
                       *ngFor="let neto of subItens.Itens; let x = index;"
                       [ngClass]="x%2 === 0 ? 'classBlueSubItem' : 'classWhiteSubItem' "
                       style="padding:8px">


                     <div id="neto" class="wrapper-content-card">

                       <div [class]="neto?.periodos? 'col-12 col-sm-12 col-md-6 col-lg-6': 'col-12 col-sm-12 col-md-9 col-lg-9'">
                         <h3>{{neto.titulo}}</h3>
                         <p [innerHTML]="neto.descricao"></p>
                         <h6 style="font-style: italic;">{{neto?.cobertura}}</h6>
                       </div>

                       <div [id]="'div_'+x" class="col-12 col-sm-12 col-md-3 col-lg-3 text-center"
                            *ngIf="neto?.periodos">
                         <!-- padding-left: 5rem; padding-right: 5rem; -->

                         <select [id]="'dropSelect_'+x"
                                 [(ngModel)]="neto.periodoSelecionado"
                                 (change)="dropSelected(x, $any($event.target).value)"
                                 class="btn btn-outline-dark-custom">
                           <!-- style="width: 10rem; height: 2.8rem; border: 1px solid #0f1b33; border-radius: 6px;"-->
                           <option style="text-align: left;" value="0">Selecione</option>
                           <option style="text-align: left;"
                                   [value]="periodo.valor"
                                   *ngFor="let periodo of neto?.periodos">
                             {{periodo.item}}
                           </option>
                         </select>

                       </div>

                       <div [id]="'drop'+x" class="col-12 col-sm-12 col-md-3 col-lg-3 aviso" style="text-align: center;">

                         <!-- solicitarNovo(neto) -->
                         <button id="liveAlertPlaceholder" (click)="neto?.periodos ? solicitarNovoPeriodo(x,neto): solicitarNovo(neto)"
                                 class="btn buttonShowSubItens mx-2 my-2">
                           Solicitar
                         </button> <!--style="margin-left: 10px;"-->

                       </div>

                     </div>
                   </li>
                 </ul>
               </li>

             </ul>
           </div>
         </div>
       </div>
     </div>
   </div>

 </div>
</div>

<ng-container *ngIf="conteudo?.versao == 2">

 <div [class]="conteudo.class" [style]="conteudo.style">

   <div class="row content-itens">

     <h2 *ngIf="conteudo.titulo != null" [class]="conteudo.class">{{conteudo.titulo}}</h2>

     <ng-container *ngFor="let item of conteudo.itens">
       <div *ngIf="item.bk">
         &nbsp;
       </div>

       <div [class]="'col-lg-'+item.col+ ' col-lg-'+item.col+ ' col-sm-12 col-12'">
         <div class="card-item" [class]="item.class || conteudo.cardTipo">
           <a [routerLink]="item.link">
             <div *ngIf="conteudo.txtPosition == 'top'" class="text-wrapper">
               <h3 class="title-card">{{item.titulo}}</h3>
               <p *ngIf="item.descricao != null" class="description-card">
                 {{item.descricao}}
               </p>
               <a [routerLink]="item.aLink">
                 {{item.btnTxt}}
                 <img src="./assets/seta-next.svg" alt="">
               </a>
             </div>
             <div class="icon-wrapper" *ngIf="item.icon">
               <div class="fundo-img">
                 <img loading="lazy" [src]="['./../assets/'+item.icon]" alt="Acesso rápido" class="mb-2">
               </div>
             </div>
             <div *ngIf="conteudo.txtPosition != 'top'" class="text-wrapper">
               <h3 class="title-card">{{item.titulo}}</h3>
               <p class="description-card" [innerHTML]="item.descricao"></p>

             </div>
           </a>
         </div>
       </div>
     </ng-container>
   </div>
 </div>

</ng-container>

<div class="wrapper-itens" *ngIf="!conteudo?.subTipo && conteudo?.Submenu != true && !conteudo?.versao" [class]="conteudo.class" [style]="conteudo.style">
 <div class="row content-itens">
   <h2 *ngIf="conteudo.titulo != null" [class]="conteudo.class">{{conteudo.titulo}}</h2>

   <ng-container *ngFor="let item of conteudo.itens">
     <div *ngIf="item.bk">
       &nbsp;
     </div>

     <div [class]="'col-lg-'+item.col+ ' col-lg-'+item.col+ ' col-sm-12 col-12'">
       <div class="card-item" [class]="item.class || conteudo.cardTipo">
         <a [routerLink]="item.link">
           <div *ngIf="conteudo.txtPosition == 'top'" class="text-wrapper">
             <h3 class="title-card">{{item.titulo}}</h3>
             <p *ngIf="item.descricao != null" class="description-card">
               {{item.descricao}}
             </p>
             <a [routerLink]="item.aLink">
               {{item.btnTxt}}
               <img src="./assets/seta-next.svg" alt="">
             </a>
           </div>
           <div class="icon-wrapper" *ngIf="item.icon">
             <div class="fundo-img">
               <img loading="lazy" [src]="['./../assets/'+item.icon]" alt="Acesso rápido" class="mb-2">
             </div>
           </div>
           <div *ngIf="conteudo.txtPosition != 'top'" class="text-wrapper">
             <h3 class="title-card">{{item.titulo}}</h3>
             <p class="description-card" [innerHTML]="item.descricao"></p>

           </div>
         </a>
       </div>
     </div>
   </ng-container>
 </div>
</div>


</div>
