<div class="wrapper-item" *ngIf="!compra?.editar">
  <div class="wrapper-info">
    <h3 class="info-section"><b>Tipo do Ato</b></h3>
    <p class="info-section">TESTE {{compra.ato}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Distrito</b></h3>
    <p class="info-section">{{compra.distrito}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Concelho</b></h3>
    <p class="info-section">{{compra.concelho}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Freguesia</b></h3>
    <p class="info-section">{{compra.freguesia}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Nome do Pai</b></h3>
    <p class="info-section">{{compra.nomePai}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Nome da Mãe</b></h3>
    <p class="info-section">{{compra.nomeMae}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Nome</b></h3>
    <p class="info-section">{{compra.nomeCompleto}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Conjuge</b></h3>
    <p class="info-section">{{compra.conjugue}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Telefone</b></h3>
    <p class="info-section">{{compra.telefone}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Página</b></h3>
    <p class="info-section">{{compra.identidade}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Data do Ato</b></h3>
    <p class="info-section">{{compra.dataato}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Informação Adicional</b></h3>
    <p class="info-section">{{compra.informacaoAdicional}}</p>
  </div>
  <h2 class="title-info-solicitante">Endereço para envio</h2>
  <div class="wrapper-info">
    <h3 class="info-section"><b>País</b></h3>
    <p class="info-section">{{compra.dadosEntrega[0].pais}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Estado / Província</b></h3>
    <p class="info-section">{{compra.dadosEntrega[0].estado}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Cidade</b></h3>
    <p class="info-section">{{compra.dadosEntrega[0].cidade}}</p>
  </div>
  <div class="wrapper-info">
    <h3 class="info-section"><b>Logradouro</b></h3>
    <p class="info-section">{{compra.dadosEntrega[0].enderecoCompleto}}</p>
  </div>
</div>

<app-form-11 *ngIf="compra?.editar" [itemEdicao]="compra" [edicao]="true" (cancelarEdicao)="cancelarEdicao(compra)"
  (salvarItem)="salvarItem($event)" [itemAtual]="compra.produto"></app-form-11>
