import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { CrudService } from '../../../_sevice/CrudService';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SearchBarComponent } from '../../searchbar/searchbar.component';

@Component({
  selector: 'app-full-banner-v1',
  templateUrl: './fullbanner.component.html',
  styleUrls: ['./fullbanner.component.scss']
})
export class FullBannerV1Component implements OnInit {
  @Input() conteudo: any;
  @Output() openModalBusca = new EventEmitter<any>();

  //@Input() iten: any = null
  retError: string
  index: number=0
  public iten = '';
  formBusca = new FormGroup({
    codigo: new FormControl('', Validators.required)
  });
  public environment = environment;

  get f() {
    return this.formBusca.controls;
  }
  get codigo() {
    return this.formBusca.controls.codigo.value;
  }
  constructor(
    public deviceService: DeviceDetectorService,
    public router: Router,
    public service: CrudService
  ) {

  }

  ngOnInit(): void {
    //console.log(this.f)
    //console.log(this.iten);
  }
  navegar(link:any) {
    this.router.navigate([link]);

  }
  abrirModalBusca() {
    //console.log('dfdgg')
    this.openModalBusca.emit()
  }
  getMobile(): boolean {
    return this.deviceService.isMobile();
  }

  consultar(urlRedirectBusca:string) {

    if (this.formBusca.valid) {
        const url = environment.http.API + 'Devedor/RetornaValidacaoDevedorCodigo/?codigo=' + this.codigo;
      if (urlRedirectBusca) {

           this.router.navigateByUrl(urlRedirectBusca + this.codigo)
      } else {
        this.service.getObservableDirect(url).subscribe(
          resposta => {
            if (resposta) {
              var data = JSON.parse(resposta);
              if (data.success) {

                window.location.href = data.link;
              }
              else {
                //console.log(data)
                this.retError = data.message
                this.formBusca.controls.codigo.setErrors({ 'retError': true });
                //this.exibirErroValicacaoCodigo(data.message);
              }
            } else {
            }
          })
      }

    }
  }

}
