import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorage } from '../../_entidade/LocalStorage';
import { CrudService } from '../../_sevice/CrudService';
import { GlobalService } from '../../_sevice/GlobalService';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { ModalPadrao } from '../dialog/modal-padrao';


@Component({
  selector: 'app-cadastrar-usuario',
  templateUrl: './cadastrar-usuario.component.html',
  styleUrls: ['./cadastrar-usuario.component.less']
})
export class CadastrarUsuarioComponent implements OnInit {
  erroConfirmarSenha = ''

  usuario: any = { Login: '', Senha: '', Nome: "", Perfis:[] }
  hidePassword1 = true;
  hidePassword2 = true;
  id: number = 0
  edicao: boolean=false
  perfis: Array<any> = new Array<any>()

  formCadastro = new FormGroup({
    nome: new FormControl(Validators.required),
    login: new FormControl(Validators.required),
    senha: new FormControl(Validators.required),
    passwordConfirm: new FormControl(Validators.required)
  });

 



  constructor(
    public router: Router
    , public storage: LocalStorage
    , public service: CrudService
    , public gb: GlobalService
    , public dialog: MatDialog
    , public routeActive: ActivatedRoute
  ) {
  }


  get f() {
    return this.formCadastro.controls;
  }


  ngOnInit() {
    
    this.id = Number(this.routeActive.snapshot.paramMap.get('id'));
    if (this.id != 0) {
      this.carregarUsuario()
    } else {
      this.edicao = false

      this.listarPerfis()

    }

  }

  carregarUsuario() {
    this.edicao = true
    this.service.getObservable('/user/GetById/' + this.id).subscribe({
      next: (retorno) => {
        this.usuario = retorno
        this.listarPerfis()
      }
    })
  }


  listarPerfis() {
    this.service.getObservable('/perfil').subscribe({
      next: (retorno) => {
        this.usuario.Perfis = retorno 
      }
    })
  }

  togglePasswordVisibility(field: number): void {
    if (field === 1) {
      this.hidePassword1 = !this.hidePassword1;
    } else if (field === 2) {
      this.hidePassword2 = !this.hidePassword2;
    }
  }

  validaConfirmacao(): boolean {
    return true
    if (this.usuario.passwordConfirm != this.usuario.senha) {
      this.formCadastro.controls.passwordConfirm.setErrors({ erroConfirmarSenha: true })
      this.erroConfirmarSenha = 'As senhas digitadas devem ser iguais.'
      return false
    } else {
      this.formCadastro.controls.passwordConfirm.updateValueAndValidity();
      this.erroConfirmarSenha = ''
      return true
    }
  }

  name: string = '.'
  animal: string = '.'

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalPadrao, {
      data: { name: this.name, animal: this.animal },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined)
        this.router.navigate(['/listar-usuario']);
    });
  }

  salvar(): void {
    if (this.formCadastro.invalid || !this.validaConfirmacao()) {
      return;
    }
    if (this.id == 0) {
      this.service.postObservable(this.usuario, 'User')
        .subscribe(
          retorno => {
            this.formCadastro.reset()
            this.openDialog()
          });
    } else {
      this.service.putObservable('User', this.usuario)
        .subscribe(
          retorno => {
            this.formCadastro.reset()
            this.openDialog()
          });
    }
    

  }
}


