import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'app-cursos',
  templateUrl: './cursos.component.html',
  styleUrls: ['./cursos.component.scss']
})
export class CursosComponent implements OnInit {

  public cursos: any = [
    {
      titulo: 'O NOVO MARCO DAS GARANTIAS',
      pathImg: '../../../assets/standard-quality-control-concept-m-min-1024x684.jpg',
      imgStyle: 'background-size: cover; width: 100%;',
      style: 'height: 434px',
      link:'https://ennor.org.br/v2/parceiros/o-novo-marco-das-garantias-lei-14-711-teoria-e-pratica-do-novo-sistema-registral-notarial-imobiliario/',
      btnTxt: 'Conheça o curso!'
    },
    {
      titulo: 'INCORPORAÇÕES IMOBILIÁRIAS',
      pathImg: '../../../assets/side-view-buyer-signing-document-768x512-1.jpg',
      imgStyle: 'background-size: cover; width: 100%;',
      style: 'height: 434px',
      link:'https://ennor.org.br/v2/course/incorporacoes-imobiliarias/',
      btnTxt: 'Conheça o curso!'
    },
    {
      titulo: 'PREVENÇÃO A FRAUDES DOCUMENTAIS',
      pathImg: '../../../assets/org-164529_school-165668_bd9a412f7fa914c80562f7c8b8fee922_pexels-tima-miroshnichenko-6266506.webp',
      imgStyle: 'background-size: cover; width: 100%;',
      style: 'height: 434px',
      link:'https://ennor.org.br/v2/parceiros/prevencao-a-fraudes-documentais/',
      btnTxt: 'Conheça o curso!'
    },
    {
      titulo: 'DETECÇÃO DE FRAUDES EM DOCUMENTOS DIGITAIS',
      pathImg: '../../../assets/org-164529_school-165668_1169f2ea7f65a9fd6339b05d87b6914c_pexels-hitesh-choudhary-693859-1.webp',
      imgStyle: 'background-size: cover; width: 100%;',
      style: 'height: 434px',
      link:'https://ennor.org.br/v2/parceiros/deteccao-de-fraudes-em-documentos-digitais/',
      btnTxt: 'Conheça o curso!'
    },
    {
      titulo: 'CURSO – DPO – ENCARREGADO DE PROTEÇÃO DE DADOS PARA SERVENTIAS EXTRAJUDICIAIS',
      pathImg: '../../../assets/Group-3-1-1024x683-1.png',
      imgStyle: 'background-size: cover; width: 100%;',
      style: 'height: 434px',
      link:'https://ennor.org.br/v2/course/curso-dpo-encarregado-de-protecao-de-dados-para-serventias-extrajudiciais/',
      btnTxt: 'Conheça o curso!'
    }
  ]

  @Input() conteudo: any;

  constructor() {}

  ngOnInit(): void {

  }

}
