import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { TesteService } from 'src/app/_sevice/teste.service';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AvisoComponent } from './aviso.component';
import { inputIptuRJValidator } from 'src/app/core/utils/Validators/Input-iptu-rj.validator';

@Component({
  selector: 'app-form-1',
  templateUrl: './form1.component.html',
  styleUrls: ['../_formSteps.component.scss']
})
export class Form1Component implements OnInit, AfterViewInit {
  @Input() uf: any;
  @Input() cidade: any;
  @Input() municipio: any;
  @Input() itemAtual: any;
  @Input() iskit: any;
  @Input() itemAtualKit: any;
  @Input() itemEdicao: any;
  @Input() edicao: any;

  @Output() salvarItem = new EventEmitter<any>();
  @Output() pularPasso = new EventEmitter<any>();
  @Output() cancelarEdicao = new EventEmitter<any>();
  @Output() setTemCobertura = new EventEmitter<any>();

  @ViewChild('aviso') avisoComponent: AvisoComponent;

  public visibilidadeDetalhes: boolean[] = [];

  municipios: String[] = [];
  selectedMunicipio: String;
  cartorioEmissor: any[];
  isshwo: boolean = false
  isLoading: boolean = false
  temCobertura: boolean = true
  classObrigatoria: any = ''
  erroCartorio: any
  camposForm: any
  finalidadeCertidao: any[];
  cepInvalido: boolean = false
  cepNaoCorresponde: string = '';
  mensagemCartorio12 = 'A Lei 6.206/12 criou o 12º Ofício de Registro de Imóveis, desmembrando a antiga freguesia de Campo Grande, compreendida na área do atual 4º Ofício do Registro de Imóveis, NÃO havendo uma transferência automática do acervo, até a abertura de matrícula no 12º Of. de Registro de Imóveis. Caso o imóvel NÃO tenha alteração registral após setembro de 2015, a matrícula ainda pertence ao 4º Ofício de Registro de Imóveis.';

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public storage: LocalStorage,
    public teste: TesteService,
    private routeAc: ActivatedRoute,
    public service: CrudService,
  ) {

  }

  setTemCoberturaFilho(tem: boolean) {
    this.temCobertura = tem
    this.setTemCobertura.emit(tem);

  }

  ngOnInit(): void {
    this.criarForm();
  }

  criarForm() {
    if (this.itemAtual?.idFormSubtipo) {
      this.camposForm = this.formBuilder.group({
        matricula: ['', Validators.required],
        finalidade: ['', Validators.required],
        cep: [''],
        estado: [this.uf],
        municipio: [this.cidade],
        tipoLogradouro: ['', Validators.required],
        logradouro: ['', Validators.required],
        numero: ['', Validators.required],
        bairro: ['', Validators.required],
        complemento: this.formBuilder.array([this.createComplemento()]),
      });

      this.criarValidacaoIptu(this.uf);
    } else {
      this.camposForm = this.formBuilder.group({
        matricula: [''],
        cep: [''],
        estado: [this.uf],
        municipio: [this.cidade],
        tipoLogradouro: ['', Validators.required],
        logradouro: ['', Validators.required],
        numero: ['', Validators.required],
        bairro: ['', Validators.required],
        complemento: this.formBuilder.array([this.createComplemento()]),
      });
    }

    if (this.itemEdicao != null) {
      this.cartorioEmissor = this.itemEdicao.listCartorio
      this.getFinalidade()
    }

  }

  criarValidacaoIptu(uf: any) {
    if (uf?.Sigla?.toString()?.toUpperCase() == 'RJ')
      this.camposForm.get(['matricula'])?.addValidators(inputIptuRJValidator());
  }

  ngAfterViewInit() {

  }
  getFinalidade() {

    let cart = this.cartorioEmissor.filter((x: any) => x.selecionado == true).map(({ idCartorio }) => idCartorio);

    if (cart && cart.length > 0) {
      var url = environment.http.API_BACK + `Consulta/FinalidadesPorCartorioAto?idAto=${this.itemAtual?.idAto}&idCartorios=${cart}`
      this.isLoading = true

      this.service.getObservableDirect(url).subscribe({
        next: async (response) => {
          if (response != null) {
            this.finalidadeCertidao = response
            this.isLoading = false
            if (this.itemEdicao != null) {

              this.carrregarEdicao()
            }
          }
        }, error: (err) => {

          console.error(err);
        }
      });
    }
  }
  createComplemento(): FormGroup {
    return this.formBuilder.group({
      tipo: [''],
      valor: ['']
    });
  }

  addComplemento() {

    if (this.complemento.controls.length < 2) {
      this.complemento.push(this.createComplemento());
    }
  }

  get complemento(): FormArray {
    return this.camposForm.get('complemento') as FormArray;
  }

  setTipoLogradouro(logra: any) {
    if (logra.indexOf('Rua') == 0) {
      this.camposForm.get('tipoLogradouro')?.setValue('RUA');
    } else if (logra.indexOf('Av. ') == 0 || logra.indexOf('Avenida') == 0) {
      this.camposForm.get('tipoLogradouro')?.setValue('AVENIDA');
    } else if (logra.indexOf('Alameda') == 0) {
      this.camposForm.get('tipoLogradouro')?.setValue('ALAMEDA');
    }
  }
  cancelarEdicaoForm() {
    this.cancelarEdicao.emit()
  }
  get f() {
    return this.camposForm.controls;
  }

  buscarEndereco($event: any) {
    let cep = $event.target.value.replace('-', '');
    let nomeCidade = this.storage.localStorageGet("localidade")?.cidade?.Nome
    this.cepInvalido = false;

    if (cep.length === 8) {
      this.service.getObservableDirect(`https://viacep.com.br/ws/${cep}/json`).subscribe({
        next: (response: any) => {
          if (response.erro) {
            this.cepInvalido = true;
            this.cepNaoCorresponde = '';
            this.camposForm.get('cep')?.setValue('');
            this.camposForm.get('logradouro')?.setValue('');
            this.camposForm.get('bairro')?.setValue('');
          } else if (response.localidade === nomeCidade) {
            this.cepInvalido = false;
            this.cepNaoCorresponde = '';
            let logLimpo = response.logradouro.replace('Rua ', '').replace('Av. ', '').replace('Avenida ', '').replace('Alameda ', '')
            this.camposForm.get('logradouro')?.setValue(logLimpo);
            this.camposForm.get('bairro')?.setValue(response.bairro);
            this.setTipoLogradouro(response.logradouro);
          } else {
            this.cepInvalido = true;


            this.cepNaoCorresponde = `O CEP: '${cep}' não corresponde à cidade selecionada: '${nomeCidade}'.`;
            this.camposForm.get('cep')?.setValue('');
            this.camposForm.get('logradouro')?.setValue('');
            this.camposForm.get('bairro')?.setValue('');
          }
        },
        error: (err) => {
          this.cepInvalido = true;
        }
      });
    } else if (cep.length > 0) {
      this.cepInvalido = true;
    }
  }

  remComplemento(i: number) {
    this.complemento.removeAt(i)
  }

  temAceiteObrigatorio = false
  aceites: any
  classObrigatoriaAviso: any;

  temAceite(event: any) {
    this.temAceiteObrigatorio = true
    this.aceites = event
  }

  updatePedido() {
    let localidade = this.storage.localStorageGet('localidade')

    this.camposForm.get('municipio')?.setValue(localidade?.cidade)
    this.camposForm.get('estado')?.setValue(localidade?.uf)

    if (this.temAceiteObrigatorio) {

      let aceitFinal = this.storage.localStorageGet('aceiteDireto')
      let temsemaceite = aceitFinal.find((x: any) => x.selecionado == false)

      if (temsemaceite == undefined) {

        this.camposForm.get('aceites')?.setValue(aceitFinal)
        this.classObrigatoriaAviso = ''

      } else {
        this.classObrigatoriaAviso = 'redClass'
        return

      }

    } else {

      this.classObrigatoriaAviso = ''
    }

    let carsel = this.cartorioEmissor?.filter((x: any) => x.selecionado == true)
    //console.log('markAllAsTouched')
    this.camposForm.markAllAsTouched();
    if (carsel == null || carsel.length == 0) {
      this.erroCartorio = true;
      this.classObrigatoria = 'redClass'
    } else {
      this.erroCartorio = false;
      this.classObrigatoria = ''
    }

    if (this.camposForm.valid && !this.erroCartorio) {
      let item: any = this.camposForm.value
      item['listCartorio'] = carsel
      this.salvarItem.emit(item);
    }

  }
  setCartorios(cartorios: any) {
    this.loadAvisos(cartorios);
    this.cartorioEmissor = cartorios;
    
    if (this.itemAtual?.idFormSubtipo)
      this.getFinalidade();

    this.carrregarEdicao();
  }

  loadAvisos(cartorios: any) {
    let objCartorio12 = cartorios.find((c: any) => c.idCartorio == 8239 && c.selecionado);
    //console.log('fasfasfds')
    if (!this.itemAtual?.aviso) {
      this.itemAtual.aviso=[]
    }
    let objMensagemCartorio12 = this.itemAtual?.aviso?.find((a: any) => a.mensagem == this.mensagemCartorio12);

    if (objCartorio12 && !objMensagemCartorio12)
      this.itemAtual.aviso.push({ tipo: "vermelho", mensagem: this.mensagemCartorio12 });
    else {
      this.itemAtual.aviso = this.itemAtual.aviso.filter((a: any) => a.mensagem != this.mensagemCartorio12);

    }

    this.avisoComponent.carregarAvisos(this.itemAtual.aviso);
  }

  carrregarEdicao() {
    let paramId = this.routeAc.snapshot.paramMap.get('id');

    if (paramId != null || this.itemEdicao != null) {
      if (this.itemEdicao == null) {
        let pedido = this.storage.localStorageGet("pedido")

        if (pedido?.listaCompra != null && pedido?.listaCompra.length > 0 && paramId != null) {
          let prodAtu = { ...pedido.listaCompra?.find((x: any) => x.idItem == paramId) }

          let qtdeAss = prodAtu?.complemento?.length - 1
          for (var i = 0; i < qtdeAss; i++) {
            if (this.complemento.controls.length <= qtdeAss)
              this.addComplemento()
          }
          this.service.preencheCampos(prodAtu, this.camposForm)

          this.finAtu = prodAtu?.finalidade

        }
      } else {
        let prodAtu = this.itemEdicao
        this.finAtu = prodAtu?.finalidade
        this.service.preencheCampos(prodAtu, this.camposForm)
      }
    }

  }
  continuar() {
    (<HTMLInputElement>document.getElementById("myNavForm1")).style.width = "0%";

  }
  pularEtapa() {
    this.pularPasso.emit(true);
  }
  finAtu: any;
  compareFinalidade(object1: any, object2: any) {
    //console.log('object1', object1, 'object2', object2)


    return object1 && object2 && object1.id == object2.id;
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // //console.log(event.key, event.ctrlKey, event.shiftKey, environment.production)
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {

      this.teste.setValueForm(this.camposForm);

    }
  }


}
