import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CadastrarUsuarioComponent } from './_components/cadastrar-usuario/cadastrar-usuario.component';
import { CrudGlobalComponent } from './_components/crudGlobal/crud-global.component';
import { GraficoComponent } from './_components/graficos/grafico.component';
import { ListarUsuarioComponent } from './_components/listar-usuario/listar-usuario.component';
import { MeusRelatoriosComponent } from './_components/meus-relatorios/meus-relatorios.component';
import { PaginaComponent } from './_components/paginaGlobal/pagina.component';
import { TrocarSenhaComponent } from './_components/trocar-senha/trocar-senha.component';
import { UsuarioPerfilComponent } from './_components/usuario-perfil/usuario-perfil.component';
import { AuthenticationGuard } from './_sevice/AuthenticationGuard';
import { LogLocalStorage } from './_components/logLocalStorage/logLocalStorage.component';
import { AdminGlobalComponent } from './_components/adminGlobal/pagina.component';
import { MeusPedidosAreaClienteComponent } from './_components/adminGlobal/components/meus-pedidos/meus-pedidos.component';
import { ChamadosTopoComponent } from './_components/menu-edicao-topo/chamados/menu-edicao-topo.component';
import { CadastroAgendamentoZoomComponent } from './_components/meeting-zoom/cadastro-agendamento-zoom/cadastro-agendamento-zoom.component';
import { SolicitarMediacaoComponent } from './_components/meeting-zoom/solicitar-mediacao/solicitar-mediacao.component';
import { VideoMedicaoZoomComponent } from './_components/meeting-zoom/video-medicao-zoom/video-medicao-zoom.component';
import { MeusAgendamentosComponent } from './_components/meus-agendamentos/meus-agendamentos.component';
import { RelatorioCNRComponent } from './_components/relatorio-cnr/relatorio-cnr.component';
import { AgendamentosZoomTesteComponent } from './_components/meeting-zoom/agendamentos-zoom/versao-teste/agendamentos-zoom-teste.component';
import { MeusCurriculosComponent } from './_components/meus-curriculos/meus-curriculos.component';
 
import { SchedulesListComponent } from './_components/schedules/schedules-list/schedules-list.component'; 

const routes: Routes = [
  {
    path: '',
    component: PaginaComponent
  },
  {
    path: 'escritura/cadastro',
    component: PaginaComponent
  },
  {
    path: 'escritura/cadastro/:local',
    component: PaginaComponent
  },
  {
    path: 'institucional/:modulo',
    component: PaginaComponent
  },
  {
    path: 'institucional/:modulo/:moduloFilho',
    component: PaginaComponent
  },
  {
    path: 'pedido/:modulo',
    component: PaginaComponent
  },
  {
    path: 'pedido/:modulo/:produto',
    component: PaginaComponent
  },
  {
    path: 'pedido/:modulo/:produto/:id',
    component: PaginaComponent
  },
  {
    path: 'login/:modulo',
    component: PaginaComponent
  },
  {
    path: 'admin/meus-dados',
    component: PaginaComponent
  },
  {
    path: 'login/:modulo/:token',
    component: PaginaComponent
  },
  {
    path: 'adminfix/meus-graficos',
    component: GraficoComponent

  },
  {
    path: 'adminfix/meus-relatorios',
    component: MeusRelatoriosComponent

  },
  {
    path: 'admin/meus-pedidos',
    component: PaginaComponent
  },
  {
    path: 'admin/meus-agendamentos',
    component: PaginaComponent
  },
  {
    path: 'admin/listar-usuario',
    canActivate: [AuthenticationGuard],
    component: ListarUsuarioComponent

  },
  {
    path: 'admin/meu-estoque',
    component: PaginaComponent
  },
  {
    path: 'admin/usuario-perfil',
    canActivate: [AuthenticationGuard],
    component: UsuarioPerfilComponent

  },
  {
    path: 'admin/cadastrar-usuario',
    canActivate: [AuthenticationGuard],
    component: CadastrarUsuarioComponent

  },
  {
    path: 'admin/trocar-senha',
    canActivate: [AuthenticationGuard],
    component: TrocarSenhaComponent

  },
  {
    path: 'admin/cadastrar-usuario/:id',
    canActivate: [AuthenticationGuard],
    component: CadastrarUsuarioComponent

  },
  {
    path: 'admin/usuario-perfil',
    canActivate: [AuthenticationGuard],
    component: UsuarioPerfilComponent

  },
  {
    path: 'admin/:modulo',
    component: CrudGlobalComponent,

  },
  {
    path: 'area-cliente',
    component: AdminGlobalComponent,
    children: [
      {
        path: 'meus-pedidos',
        component: MeusPedidosAreaClienteComponent
      }
    ],

  },
  {
    path: 'area-cliente/:modulo',
    component: AdminGlobalComponent,

  },
  {
    path: 'pedido/:modulo',
    component: CrudGlobalComponent,

  },
  {
    path: 'log/setls/sevl',
    component: LogLocalStorage,

  },
  {
    path: 'chamados/acompanhamento/ver',
    component: ChamadosTopoComponent,

  },
  // {
  //   path: 'fadmin/agendamentos-meeting',
  //   component: AgendamentosZoomComponent,
  // },
  {
    path: 'fadmin/meus-agendamentos',
    component: MeusAgendamentosComponent,
  },
  {
    path: 'fadmin/relatorio',
    component: RelatorioCNRComponent,
  },

 

  {
    path: 'fadmin/calendario-meeting',
    component: SchedulesListComponent,
  },


  {
    path: 'fadmin/curriculos',
    component: MeusCurriculosComponent,
  },
  {
    path: 'fadmin/meeting/agendar-mediacao/:meetingGuid/:status',
    component: CadastroAgendamentoZoomComponent,
  },
  {
    path: 'mediacao/institucional/:modulo',
    component: SolicitarMediacaoComponent,
  },
  {
    path: 'mediacao/institucional/:modulo/:produto',
    component: SolicitarMediacaoComponent,
  },
  {
    path: 'meeting/video-mediacao/:guidMeeting/:guidUser',
    component: VideoMedicaoZoomComponent,
  },
  {
    path: ':modulo',
    component: PaginaComponent
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
