<div class="background">
  <div *ngIf="!deviceService.isMobile()">
    <img id="xpto" loading="lazy" alt="cursos" [src]="'/assets/' + conteudo.srcImage" class="img-desk d-none d-lg-block px-5" />
    <div [class]="conteudo.bannerFullContent ? 'container':''">
      <div  class="position-absolute h-100"
            [class]="conteudo.class"
            style="top: 8px;width: -webkit-fill-available !important;">
        <!-- linha principal -->
        <div class="row w-100 h-100">
          <div class="col-lg-6">&nbsp;</div>
          <div class="col-lg-5 pt-5">
            <div class="pt-5">
              <div class="row pt-5">
                <div class="mb-4 text-start pt-5"
                     style=" color: #18244F; line-height: 4rem;">
                  <span class="pt-5 fw-bolder" style="font-size: 4.5rem;">Cadastre seu currículo?</span>
                </div>
              </div>
              <div class="row">
                <div class="mb-4 d-flex text-start"
                      style="font-size: 1.4rem; color: #18244F;line-height: 30px;">
                  <span style="">Cadastre seu currículo e seja visualizado por vários cartórios!</span>
                </div>
              </div>
              <div class="row">
                <div class=" text-center">
                  <button class="btn btn-primary w-50"  (click)="navegar('curriculo')">Cadastre-se</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column" *ngIf="deviceService.isMobile()">
    <div>
      <!-- primeira linha -->
      <div class="m-4">
        <div class="text-center"
          style="font-family: var(--fonte-padrao); font-size: 2.5rem; color: #18244F; font-weight: bold; line-height: 3rem;">
          <span>Cadastre seu currículo?</span>
        </div>
      </div>
      <!-- segunda linha -->
      <div class="m-4">
        <div class="text-center">
          <div style="font-size: 1.3rem; color: #18244F;line-height: 30px;">
            <span>Cadastre seu currículo e seja visualizado por vários cartórios!</span>
          </div>
        </div>
      </div>
      <!-- terceira linha -->
      <div class="row">
        <div class=" text-center">
          <button class="btn btn-primary w-50"  (click)="navegar('curriculo')">Cadastre-se</button>
        </div>
      </div>

       <!--
      <div class="m-4">
        <div class="text-center">
          <button class="btn btn-primary w-100">Saiba mais</button>
        </div>
      </div>
      -->
    </div>
    <img loading="lazy" [src]="'/assets/' + conteudo.srcImageMobile" class="img-fluid d-block mx-auto mask" />
  </div>
</div>
