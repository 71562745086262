<div class="container" *ngIf="edicaoPG && !iten">
  <menu-edicao-topo [conteudoPagina]="conteudoPagina"></menu-edicao-topo>
</div>

<ng-container *ngIf="conteudoPagina">

  <app-modal *ngIf="shoModal" (closeModal)="closeModal()"></app-modal>


  <ng-container *ngFor="let iten of conteudoPagina.itens; let j = index;">


    <div [class]="iten.bannerFullContent ? '':'container'">
     


      <ng-container *ngIf="iten.tipo == 'parceiros' ">
        <app-parceiros [conteudo]="iten"></app-parceiros>
      </ng-container>

      <ng-container *ngIf="iten.tipo == 'cursos'">
        <app-cursos [conteudo]="iten"></app-cursos>
      </ng-container>

      <ng-container *ngIf="iten.tipo == 'html'">
        <app-html [conteudo]="iten"></app-html>
      </ng-container>

      <ng-container *ngIf="iten.tipo == 'bancoIdeias'">
        <app-banco-ideias [conteudo]="iten"></app-banco-ideias>
      </ng-container>

      <ng-container *ngIf="iten.tipo == 'login'">

        <app-login [conteudo]="iten"></app-login>

      </ng-container>
 
   

      <ng-container *ngIf="iten.tipo == 'esquecisenha' || iten.tipo == 'redefinirsenha'">
        <app-trocar-senha [conteudo]="iten"></app-trocar-senha>
      </ng-container>


      
   
      <ng-container *ngIf="iten.tipo == 'cadastre-se'">

        <app-cadastre-se [conteudo]="iten"></app-cadastre-se>

      </ng-container>
      <ng-container *ngIf="iten.tipo == 'cadastrar-curso'">
        <app-cadastrar-curso [conteudo]="iten"></app-cadastrar-curso>
      </ng-container>

      <ng-container *ngIf="iten.tipo == 'labelDescricao'">
        <h2>{{iten.valor}}</h2>
      </ng-container>
       
      <ng-container *ngIf="iten.tipo == 'cardSequencial' && !iten?.versao">
        <app-card-sequencial [conteudo]="iten"></app-card-sequencial>
      </ng-container>
      <ng-container *ngIf="iten.tipo == 'cardSequencial' && iten?.versao ===1">
        <app-card-sequencial-v1 [conteudo]="iten"></app-card-sequencial-v1>
      </ng-container>
      <ng-container *ngIf="iten.tipo == 'cardSequencial' && iten?.versao == 2">
        <app-card-sequencial [conteudo]="iten"></app-card-sequencial>
      </ng-container>
     


      <ng-container *ngIf="iten.tipo == 'fullbanner' && !iten?.versao">
        <app-full-banner [conteudo]="iten"></app-full-banner>
      </ng-container>
      <ng-container *ngIf="iten.tipo == 'fullbanner' && iten?.versao ==1">

        <app-full-banner-v1 (openModalBusca)="openModalBusca()" [conteudo]="iten"></app-full-banner-v1>

      </ng-container>

      <ng-container *ngIf="iten.tipo == 'galeriaImg' && !iten?.versao">
        <app-galeria-img [conteudo]="iten"></app-galeria-img>
      </ng-container>
      <ng-container *ngIf="iten.tipo == 'galeriaImg' && iten?.versao ==1">
        <app-galeria-img-v1 [conteudo]="iten"></app-galeria-img-v1>
      </ng-container>


      <ng-container *ngIf="iten.tipo == 'conteudo'">
        <app-conteudo [conteudo]="iten"></app-conteudo>
      </ng-container>
      <ng-container *ngIf="iten.tipo == 'contato'">

      </ng-container>
      <ng-container *ngIf="iten.tipo == 'mediacao'">
        <app-solicitar-mediacao></app-solicitar-mediacao>
      </ng-container>

      <ng-container *ngIf="iten.tipo == 'conteudob'">
        <app-conteudo [conteudo]="iten"></app-conteudo>
      </ng-container>

      <ng-container *ngIf="iten.tipo == 'banner'">
        <app-banner [conteudo]="iten"></app-banner>
      </ng-container>
      <ng-container *ngIf="iten.tipo == 'cardHorizontal'">
        <app-card-horizontal [conteudo]="iten"></app-card-horizontal>
      </ng-container>
      
      <ng-container *ngIf="iten.tipo == 'consulta'">
        <app-consulta-protesto [conteudo]="iten"></app-consulta-protesto>
      </ng-container>
      <ng-container *ngIf="iten.tipo == 'texto-institucional'">
        <app-texto-institucional [conteudo]="iten"></app-texto-institucional>
      </ng-container>
      <ng-container *ngIf="iten.tipo == 'ajuda'">
        <app-ajuda [conteudo]="iten"></app-ajuda>
      </ng-container>

       
      <ng-container *ngIf="iten.tipo == 'table'">
        <app-table-data [conteudo]="iten"></app-table-data>
      </ng-container>
      <ng-container *ngIf="iten.tipo == 'form'">
        <app-generic-form *ngIf="!iten.concluido" [conteudoPagina]="iten"
                          [editar]="true"
                          [dados]="iten.dadosEntidade"
                          style="width:100%"
                          [inputs]="iten.inputs">

        </app-generic-form>

        <div *ngIf="iten.concluido">
          <h3>{{iten.H3MensagemSucesso}}</h3><br>
          <p>{{iten.MensagemSucesso}}</p><br>
          <button class="prot-botao-form-azul" (click)="iten.concluido = !iten.concluido">Voltar</button>

        </div>
      </ng-container>

       
      
    </div>
    <ng-container *ngIf="iten.tipo == 'meus-pedidos'">

      <div class="content-page" style="min-height: 71vh;">
        <app-meus-pedidos [conteudo]="iten"></app-meus-pedidos>

      </div>

    </ng-container>
    <ng-container *ngIf="iten.tipo == 'encontreServicos'">
      <app-encontre-servicos [conteudo]="iten"></app-encontre-servicos>
    </ng-container>



    <ng-container *ngIf="iten.tipo == 'formSteps'">
      <div class="content-page" style="min-height: 71vh;">

        <app-form-steps [conteudo]="iten"></app-form-steps>
      </div>

    </ng-container>
    <ng-container *ngIf="iten.tipo == 'lista-servicos'">
      <app-list-service [conteudo]="iten"></app-list-service>
    </ng-container>

    <ng-container *ngIf="iten.tipo == 'searchbar' && !iten?.versao">
      <app-searchbar [conteudo]="iten"></app-searchbar>
    </ng-container>

    <ng-container *ngIf="iten.tipo == 'searchbar' && iten?.versao == 1">
      <app-searchbar [conteudo]="iten" (openModalBuscaEvent)="openModalBusca()"></app-searchbar>
    </ng-container>
  </ng-container>


</ng-container>
