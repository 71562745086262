<!--{{cidade | json}} - {{estado| json}}-->

<form [formGroup]="camposForm">

  <div class="row">
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Ato</mat-label>
        <input matInput type="text" class="col-lg-6" formControlName="descricaoAto" [readonly]="true"
               placeholder="Ato">
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-2">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Quantidade</mat-label>
        <input matInput type="text" class="col-lg-2" formControlName="quantidade"
               placeholder="Quantidade"
               readonly>
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-2">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Preço Unitário</mat-label>
        <input matInput type="text" class="col-lg-2"
               currencyMask
               [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
               formControlName="precoUnitarioAto"
               placeholder="Preço Unitário"
               readonly>
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-2">
      <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
        <mat-label>Preço Total</mat-label>
        <input matInput type="text" class="col-lg-2"
               currencyMask
               [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
               formControlName="precoTotal"
               placeholder="Preço Total"
               readonly>
        <mat-error>
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>

<app-aviso [aviso]="itemAtual?.aviso"></app-aviso>

<div class="row">
  <div class="col-md-5"></div>
  <div class="col-auto">
    <button type="button" mat-button [routerLink]="['/']" class="btn-next" style="color: white; background-color: green;">Voltar</button>
  </div>
  <div class="col-auto">
    <button mat-button (click)="updatePedido()" class="btn-next"
            style="display: block; margin: 0 auto;">
      Continuar
    </button>
  </div>
</div>
