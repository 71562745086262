<div>
  <div>
    {{displayMonth}}
  </div>
  <div>
    <input type="button" (click)="setCurrentMonth()" value="today" />
    <input type="button" (click)="decreaseMonth()" value="-" />
    <input type="button" (click)="increaseMonth()" value="+" />
  </div>
</div>

<table class='calendar-table' *ngIf="calendar">
  <thead>
    <tr>
      <th>Monday</th>
      <th>Tuesday</th>
      <th>Wednesday</th>
      <th>Thursday</th>
      <th>Friday</th>
      <th>Saturday</th>
      <th>Sunday</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of calendar | chunk: 7; let i = index">
      <td class="calendar-day" [class]="{'past-date': c.isPastDate, 'today': c.isToday}"
        *ngFor="let c of row; let j = index">
        <div class="calendar-day-header" [class]="{'blue-date': c.isToday}"><strong>{{c.date.getDate()}}</strong>
          <strong *ngIf="c.isToday || (i==0 && j==0) || (c.date.getDate() == 1)">
            {{monthNames[c.date.getMonth()]}}</strong></div>
      </td>
    </tr>
  </tbody>
</table>
