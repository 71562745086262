import { Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';
//import * as textMask from 'vanilla-text-mask';
//import Inputmask from 'inputmask';


@Directive({ selector: '[appMaskDate]' })
export class DateMaskComponent implements OnDestroy {

  mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]; // dd/mm/yyyy
  maskedInputController: any;

  constructor(private element: ElementRef) {
    

  }

  ngOnDestroy() {
   // this.maskedInputController.destroy();
  }

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
   
    input.value = input.value.replace(/\D/g, "");
    input.value = input.value.replace(/(\d{2})(\d)/, "$1/$2");
    input.value = input.value.replace(/(\d{2})(\d)/, "$1/$2");
    return input.value;
     
  }

}
