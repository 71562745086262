
<div class="sign-in-container container-full" *ngIf="!conteudo.versao">
  <img src="/assets/escrituras (1).jpg" alt="" srcset="">
  <div class="content-login">
    <form (ngSubmit)="submitFormCadastro()" [formGroup]="formCadastro">
      <h2>Cadastre-se</h2>

      <mat-form-field appearance="outline" class="w-100 my-2">
        <mat-label>CPF/CNPJ ou E-mail</mat-label>
        <input matInput type="text" formControlName="cpf" placeholder="Digite seu login" required>

        <mat-error *ngIf="f['cpf']?.errors?.['required']">
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100 my-2">
        <input matInput [type]="password ? 'password' : 'text'" id="password" name="senha" formControlName="password" placeholder="Digite sua senha" required>
        <button mat-icon-button matSuffix type="button" (click)="togglePassword('password')">
          <mat-icon>{{ password ? 'remove_red_eye' : 'visibility_off' }}</mat-icon>
        </button>
        <mat-error *ngIf="f['password']?.errors?.['required']">
          Preenchimento obrigatório
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-100 my-2">
        <input matInput [type]="confirmPassword ? 'password' : 'text'" id="confirmPassword" name="confirmPassword" placeholder="Confirme a sua senha" formControlName="confirmPassword" required>
        <button mat-icon-button matSuffix type="button" (click)="togglePassword('confirmPassword')">
          <mat-icon>{{ confirmPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
        </button>
        <mat-error *ngIf="formCadastro.hasError('passwordsNotMatch', 'confirmPassword')">
          As senhas não coincidem.
        </mat-error>
      </mat-form-field>
      <div class="example-button-row">
        <mat-error *ngIf="formCadastro.hasError('usuarioExistente', 'cpf')">
          Usuário já cadastrado
        </mat-error>
        <button mat-raised-button color="primary" class="btn-login mr-5">Cadastrar</button>
      </div>
    </form>

  </div>
</div>

<!-- <ng-container></ng-container> -->
<ng-container *ngIf="conteudo.versao">
  <div class="wrapper-content" *ngIf="!dadosAtualizados">
    <h2>Dados Pessoais</h2>
    <ng-container>
      <form [formGroup]="camposForm" autoComplete="off">
        <div class="wrapper-form content-top">
          <div class="row align-items-center">
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Nome da Completo</mat-label>
                <input matInput type="text" class="col-lg-12" formControlName="nome">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>
                  CPF/CNPJ
                </mat-label>
                <input matInput type="text" formControlName="cnpjCpf" (keyup)="validateIsCnpj()" mask="000.000.000-00||00.000.000/0000-00">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <mat-form-field *ngIf="!hideDataNasc" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label for="nascimento">Data de Nascimento</mat-label>
                <input matInput [matDatepicker]="picker" type="text"
                       formControlName="nascimento"
                       appMaskDate
                       maxlength="10">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Celular</mat-label>
                <input matInput
                       type="text"
                       class="col-lg-12"
                       mask="(00) 00000-0000"
                       formControlName="celular"
                       placeholder="Celular">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Telefone Fixo</mat-label>
                <input matInput
                       type="text"
                       class="col-lg-12"
                       mask="(00) 0000-0000"
                       formControlName="telFixo"
                       placeholder="Telefone Fixo">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo" autoComplete="off">
                <mat-label>E-mail</mat-label>
                <input matInput
                       type="text" required class="email"
                       formControlName="email" autoComplete="off"
                       placeholder="E-mail">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field class="w-100">
                <mat-label>Profissão:</mat-label>
                <mat-select formControlName="profissao">
                  <mat-option value="ADMINISTRADOR">Administrador</mat-option>
                  <mat-option value="ADVOGADO">Advogado</mat-option>
                  <mat-option value="BABÁ">Babá</mat-option>
                  <mat-option value="BANCÁRIO">Bancário</mat-option>
                  <mat-option value="COMERCIANTE">Comerciante</mat-option>
                  <mat-option value="CONTADOR">Contador</mat-option>
                  <mat-option value="CORRETOR DE IMÓVEIS">Corretor de Imóveis</mat-option>
                  <mat-option value="DESPACHANTE">Despachante</mat-option>
                  <mat-option value="EMPREGADA DOMÉSTICA">Empregada Doméstica</mat-option>
                  <mat-option value="ENGENHEIRO">Engenheiro</mat-option>
                  <mat-option value="ESCREVENTE">Escrevente</mat-option>
                  <mat-option value="JORNALISTA">Jornalista</mat-option>
                  <mat-option value="MÉDICO">Médico</mat-option>
                  <mat-option value="MOTORISTA PROFISSIONAL">Motorista Profissional</mat-option>
                  <mat-option value="PROFESSOR">Professor</mat-option>
                  <mat-option value="SERVIDOR PÚBLICO">Servidor Público</mat-option>
                  <mat-option value="TAXISTA">Taxista</mat-option>
                  <mat-option value="VIGILANTE">Vigilante</mat-option>
                  <mat-option value="OUTRA">Outra</mat-option>
                </mat-select>
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!--<h2>Dados de Segurança</h2>-->
        <br />
        <div class="wrapper-form content-top" *ngIf="edicao == false">
          <h2>Dados de Segurança</h2>
          <div class="row align-items-center">
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 my-2">
                <mat-label>Senha</mat-label>
                <input matInput
                       [type]="password ? 'password' : 'text'" id="password"
                       name="senha" formControlName="password"
                       placeholder="Digite sua senha" required autocomplete="new-password">
                <button mat-icon-button matSuffix type="button" (click)="togglePassword('password')">
                  <mat-icon>{{ password ? 'remove_red_eye' : 'visibility_off' }}</mat-icon>
                </button>
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 my-2">
                <mat-label>Confirmar Senha</mat-label>
                <input matInput [type]="confirmPassword ? 'password' : 'text'" id="confirmPassword" name="confirmPassword" placeholder="Confirme a sua senha" formControlName="confirmPassword" required>
                <button mat-icon-button matSuffix type="button" (click)="togglePassword('confirmPassword')">
                  <mat-icon>{{ confirmPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                </button>
                <mat-error *ngIf="camposForm.hasError('passwordsNotMatch')">
                  As senhas não coincidem.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <h2>Dados de Cobrança</h2>
        <div class="wrapper-form content-bottom" formGroupName="dadosCobranca">
          <div class="col-md-12">
            <mat-checkbox class="example-margin" name="copiarDados" id="copiarDados" (change)="copiarDadosPessoais($event)">
              Copiar acima
            </mat-checkbox>
          </div>
          <div class="row align-items-center">
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Nome da Completo</mat-label>
                <input matInput type="text" class="col-lg-12" formControlName="nome">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>
                  CPF/CNPJ
                </mat-label>
                <input matInput type="text" formControlName="cnpjCpf" mask="000.000.000-00||00.000.000/0000-00">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>CEP</mat-label>
                <input matInput
                       type="text"
                       class="col-lg-12"
                       mask="00000-000"
                       formControlName="cep"
                       placeholder="CEP"
                       (blur)="buscarEndereco($event)">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
                <mat-error *ngIf="camposForm.hasError('cepNotMatch')">
                  CEP não encontrado.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field class="w-100">
                <mat-label>Estado</mat-label>
                <input type="text"
                       placeholder="Estado"
                       aria-label="Number"
                       matInput
                       formControlName="uf"
                       [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProperty" (optionSelected)="getCidade($event)">
                  <mat-option *ngFor="let item of listufFilter" [value]="item">
                    {{item.Nome }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field class="w-100">
                <mat-label>Cidade</mat-label>
                <input type="text"
                       #cidade
                       id="Cidade"
                       placeholder="Cidade"
                       aria-label="Number"
                       matInput
                       formControlName="cidade"
                       [matAutocomplete]="auto2">
                <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayPropertyCidade" (optionSelected)="setLocal()">
                  <mat-option *ngFor="let item of listCidadeFilter" [value]="item">
                    {{item.Nome }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Bairro</mat-label>
                <input matInput type="text"
                       formControlName="bairro"
                       placeholder="Bairro">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Tipo de logradouro</mat-label>
                <mat-select formControlName="tipoLogradouro">
                  <mat-option value="ALAMEDA">ALAMEDA</mat-option>
                  <mat-option value="AVENIDA">AVENIDA</mat-option>
                  <mat-option value="BECO">BECO</mat-option>
                  <mat-option value="CAMPO">CAMPO</mat-option>
                  <mat-option value="ESTRADA">ESTRADA</mat-option>
                  <mat-option value="ILHA">ILHA</mat-option>
                  <mat-option value="LADEIRA">LADEIRA</mat-option>
                  <mat-option value="LARGO">LARGO</mat-option>
                  <mat-option value="PRAÇA">PRAÇA</mat-option>
                  <mat-option value="RUA">RUA</mat-option>
                  <mat-option value="SERVIDAO">SERVIDAO</mat-option>
                  <mat-option value="TRAVESSA">TRAVESSA</mat-option>
                  <mat-option value="VIA">VIA</mat-option>
                  <mat-option value="VILA">VILA</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Logradouro</mat-label>
                <input matInput type="text"
                       formControlName="logradouro"
                       placeholder="Logradouro">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Número</mat-label>
                <input matInput type="text"
                       formControlName="numero"
                       placeholder="Número">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
                <mat-label>Complemento</mat-label>
                <input matInput type="text" maxlength="20"
                       formControlName="complemento"
                       placeholder="Complemento">
                <mat-error>
                  Preenchimento obrigatório
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <br />
          <div class="row" *ngIf="edicao == false">
            <div class="col-md-12">
              <div id="ajuda-termos" class="grupo-conteudo-ajuda" style="height: 400px;">
                <embed src="/assets/cnr/Termos_usuarios.pdf" style="width: 100%; height: 400px;"
                       type="application/pdf">
              </div>
            </div>
          </div>
          <div class="row" *ngIf="edicao == false">
            <div class="col-md-12">

              <mat-checkbox formControlName="aceiteTermos" required class="example-margin" name="termo" id="termo" (change)="confirmarAceite($event)">
                Aceito o Termo
              </mat-checkbox>
              <mat-error *ngIf="errorAceite">
                Aceite obrigatório
              </mat-error>

            </div>
          </div>

        </div>
        <div class="col-md-12" *ngIf="edicao == false">
          <span style="color:red" *ngIf="erroCadastro">{{erroCadastro}}</span>
          <button class="d-block mx-auto px-3 py-2 my-4" id="cadastrov2" (click)="cadastroUsuario()">Cadastrar</button>
        </div>
        <br />

        <!--<div class="col-md-12" *ngIf="edicao == true">
        <button class="d-block mx-auto px-3 py-2 my-4" id="alterarv2" (click)="alterarUsuario()">Alterar</button>
      </div>-->

        <div class="row">
          <div class="col-md-5"></div>
          <div class="col-auto" *ngIf="edicao == true">
            <button type="button" class="btn btn-primary btn-lg me-5" id="alterarv2" (click)="alterarUsuario()">Alterar Dados</button>
            <button type="button" class="btn btn-info btn-lg" id="alterarv3" (click)="alterarSenha()">Alterar Senha</button>
          </div>
        </div>
        <br />


      </form>
    </ng-container>
  </div>
</ng-container>

<ng-container>
  <div class="wrapper-content" *ngIf="dadosAtualizados">
    <div class="col-auto">
      <h2>Dados Atualizados com sucesso!</h2>
    </div>

    <br />
    <div class="row">
      <div class="col-md-5"></div>
      <div class="col-auto">
        <button type="button" mat-button [routerLink]="['/admin/meus-pedidos']"
                class="btn-next" style="color: white; background-color: midnightblue;" (click)="irMeusPedidos()">
          Meus Pedidos
        </button>
      </div>
      <div class="col-auto">
        <button mat-button (click)="voltarMeusDados()"
                class="btn-next" style="color: black; background-color:silver;">
          Meus Dados
        </button>
      </div>
    </div>
    <br />
  </div>
</ng-container>



