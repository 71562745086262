import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit, HostListener } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorage } from '../../_entidade/LocalStorage';
import { environment } from 'src/environments/environment';
import { CrudService } from '../../_sevice/CrudService';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TesteService } from 'src/app/_sevice/teste.service';
import { localidade } from 'src/app/_configPage/listas/localidade';
@Component({
  selector: 'app-cadastrar-curso',
  templateUrl: './cadastrar-curso.component.html',
  styleUrls: ['./cadastrar-curso.component.scss']
})
export class CadastrarCursoComponent implements OnInit {
  @Input() conteudo: any;
  @Input() edicao: boolean = false;
  @Input() dadosAtualizados = false;
  @Input() relacionarCompra: any = false;

  erroCadastro:any=null;
  password = true;
  confirmPassword = true;
  dataFormatada: string = '';
  hideDataNasc: boolean = false;
  temFormacaoAcademica = false;
  form: FormGroup;
  meet: any;
  public maxCharacters: number = 2000;
  public showRemainCounter = false;

  listuf: any[] = localidade.listuf;
  listufFilter: any = this.listuf;
  listCidade: any[];
  listCidadeFilter: any;
  ufSelected = this.storage.localStorageGet('localidade')?.uf || { "Id": "65b11f81c0095aa0cc393545", "IdEstado": 19, "Sigla": "RJ", "Nome": "Rio de Janeiro" };
  cidadeSelected = this.storage.localStorageGet('localidade')?.cidade || { "Id": "65b11fe7c0095aa0cc394397", "IdCidade": "3658", "IdEstado": "19", "Nome": "Rio de Janeiro" };
  errorAceite: any = false;
  //cnpjCpf: ['', Validators.compose([Validators.required, GenericValidator.isValidCPFCNPJ()])],
  public camposForm = this.formBuilder.group(
    {
      nome: ['', Validators.required],
      nascimento: ['', Validators.required],
      celular: ['', Validators.required],
      telFixo: [''],
      arquivos: new FormControl([]),
      email: ['',
        Validators.compose(
          [
            Validators.required,
            Validators.email
          ]
        )
      ],
      profissao: ['', Validators.required],
      profissaoAvulso: [''],
      objetivo: ['', Validators.required],
      guid: [''],
      formacaoAcademica: this.formBuilder.array([this.createFormacaoAcademica()]),
      experienciaProfissional: this.formBuilder.array([this.createExperienciaProfissional()]),
      cursosLivres: this.formBuilder.array([this.createCursosLivres()]),
      Idiomas: this.formBuilder.array([this.createIdioma()]),
    });
  createFormacaoAcademica(): FormGroup{
    return this.formBuilder.group ({
      instituicaoAcademica: ['', Validators.required],
      formacaoAcademica: ['', Validators.required],
      periodoAnoInicio: ['', Validators.required],
      periodoAnoTermino: ['', Validators.required],
    });
  }
  createExperienciaProfissional(): FormGroup {
    return this.formBuilder.group ({
      experienciaProfissionalEmpresa: [''],
      experienciaProfissionalCargo: [''],
      experienciaProfissionalAtividades: [''],
    })
  }
  createCursosLivres(): FormGroup {
    return this.formBuilder.group ({
      cursosLivresNomeCurso: [''],
      cursosLivresInstituicao: [''],
      cursosLivresAno: [''],
    })
  }
  createIdioma(): FormGroup {
    return this.formBuilder.group ({
      IdiomasLingua: [''],
      idiomasNivel: ['']
    })
  }
  createFormCadastro() {
    //cnpjCpf: ['', Validators.compose([Validators.required,GenericValidator.isValidCPFCNPJ()])],
    this.camposForm = this.formBuilder.group(
      {
        nome: ['', Validators.required],
        nascimento: ['', Validators.required],
        celular: ['', Validators.required],
        telFixo: [''],
        arquivos: new FormControl([]),
        email: ['',
          Validators.compose(
            [
            Validators.required,
              Validators.email
            ]
          )
        ],
        profissao: ['', Validators.required],
        profissaoAvulso: [''],
        objetivo: ['', Validators.required],
        guid: [''],
        formacaoAcademica: this.formBuilder.array([this.createFormacaoAcademica()]),
        experienciaProfissional: this.formBuilder.array([this.createExperienciaProfissional()]),
        cursosLivres: this.formBuilder.array([this.createCursosLivres()]),
        Idiomas: this.formBuilder.array([this.createIdioma()]),
      } );
  }

  validateIsCnpj() {
    let cnpjCpfLength = this.camposForm.get(['cnpjCpf'])?.value?.length;
    if (cnpjCpfLength == 14)
    {
      this.camposForm.get(['nascimento'])?.removeValidators([Validators.required]);
      this.hideDataNasc = true;
    }
    else
    {
      this.camposForm.get(['nascimento'])?.addValidators(Validators.required);
      this.hideDataNasc = false;
    }
    this.camposForm.get(['nascimento'])?.updateValueAndValidity();
  }
  formCadastro = this.formBuilder.group({
    cpf: ['', Validators.required],
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required]
  });
  constructor(
    private formBuilder: FormBuilder
    , public router: Router
    , public storage: LocalStorage
    , public service: CrudService
    , public teste: TesteService
    , public st: LocalStorage
    , @Inject(DOCUMENT) private document: any) {
    this.formCadastro = this.formBuilder.group({
      cpf: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
    this.formCadastro.get('cpf')?.setValue('');
  }
  ngOnInit() {
    this.service.ajustaSelect();
    this.createFormCadastro();
    this.uf?.valueChanges.subscribe((newValue: any) => {
      this.listufFilter = this._filter(newValue);
    })
    this.cidade?.valueChanges.subscribe((newValue: any) => {
      this.listCidadeFilter = this._filterCidade(newValue);
    });
    console.log(this.camposForm.controls['formacaoAcademica'].value.length);
    console.log(this.camposForm.controls['formacaoAcademica']);
  }
  get dadosCobranca(): any {
    return this.camposForm.get('dadosCobranca')
  }
  get f() {
    return this.formCadastro.controls;
  }
  copiarDadosPessoais(event: MatCheckboxChange): void {
    if (event.checked) {
      // Se o checkbox está marcado, copia os valores
      const nome = this.camposForm.get('nome')?.value;
      const cnpjCpf = this.camposForm.get('cnpjCpf')?.value;
      this.dadosCobranca.get('nome')?.setValue(nome);
      this.dadosCobranca.get('cnpjCpf')?.setValue(cnpjCpf);
    } else {
      // Se o checkbox está desmarcado, limpa os valores
      this.dadosCobranca.get('nome')?.setValue('');
      this.dadosCobranca.get('cnpjCpf')?.setValue('');
    }
  }
  confirmarAceite(event: MatCheckboxChange): void {
    if (event.checked) {
      this.errorAceite = false
    }
    else {
      this.errorAceite = true
    }
  }
  setTipoLogradouro(logra: any) {
    if (logra.indexOf('Rua') == 0) {
      this.dadosCobranca.get('tipoLogradouro')?.setValue('RUA');
    } else if (logra.indexOf('Av. ') == 0 || logra.indexOf('Avenida') == 0) {
      this.dadosCobranca.get('tipoLogradouro')?.setValue('AVENIDA');
    } else if (logra.indexOf('Alameda') == 0) {
      this.dadosCobranca.get('tipoLogradouro')?.setValue('ALAMEDA');
    }
  }
  buscarEndereco($event: any) {
    let cep = $event.target.value.replace('-', '')
    if (cep.length == 8) {
      this.service.getObservableDirect('https://viacep.com.br/ws/' + cep + '/json').subscribe(
        {
          next: async (response: any) => {
            if (!response['erro']) {
              let logLimpo = response.logradouro.replace('Rua ', '').replace('Av. ', '').replace('Avenida ', '').replace('Alameda ', '')
              this.dadosCobranca.get('logradouro')?.setValue(logLimpo)
              this.dadosCobranca.get('bairro')?.setValue(response.bairro)
              this.ufSelected = localidade.listuf.find((x: any) => x.Sigla == response.uf)
              this.dadosCobranca.get('uf')?.setValue(this.ufSelected)
              this.loadCidade(this.ufSelected.IdEstado, true, response.localidade)
              this.setTipoLogradouro(response.logradouro)
            } else {
              this.dadosCobranca.get('cep').setErrors({ cepNotMatch: true });
            }
          },
          error: (err) => {
            console.error(err);
          }
        }
      )
    }
  }
  get uf() {
    return this.dadosCobranca?.get('uf');
  }
  get cidade() {
    return this.dadosCobranca?.get('cidade');
  }
  cadastrar() {
    this.camposForm.markAllAsTouched();
    if (this.camposForm.valid) {
      let url = environment.http.API + 'Curriculo';
      let it = this.camposForm.value;
      it['guid'] = crypto.randomUUID();
      (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";
      this.service.postObservableDirect(url, it).subscribe({
        next: async (response) => {
          if (response != null ) {
            this.dadosAtualizados = true;
          } else {
            this.erroCadastro = response.messageError;
            //console.log(response)
          }
        }, error: (err) => {
          console.error(err);
        }
      });
      //console.log(this.camposForm.value, "teste form")
    }
  }
  public displayProperty(value: any) {
    if (value) {
      return value.Nome;
    }
  }
  public displayPropertyCidade(value: any) {
    if (value) {
      return value.Nome;
    }
  }
  getCidade(event: any) {
    this.setLocal()
    this.loadCidade(event.option.value['IdEstado'])
    this.ufSelected = this.uf?.value
    if (!event.isUserInput) {
      this.dadosCobranca.get('cidade')?.setValue(null);
    }
  }
  setLocal() {
    this.cidadeSelected = this.cidade?.value
  }
  loadCidade(IdEstado: any, setCidade: boolean = false, strCidade: string = '') {
    const url = environment.http.API + 'Localidade/cidade?IdEstado=' + IdEstado;
    this.service.getObservableDirect(url).subscribe({
      next: async (response) => {
        if (response != null) {
          //this.camposForm.get('cidade')?.setValue(null)
          this.listCidadeFilter = response;
          this.listCidade = response;
          if (setCidade) {
            let cit = response.find((x: any) => x.Nome.toLowerCase() == strCidade.toLowerCase())
            this.dadosCobranca.get('cidade')?.setValue(cit);
          }
        }
      }, error: (err) => {
        console.error(err);
      }
    });
  }
  compareUF(object1: any, object2: any) {
    //console.log(object1, object2)
    return object1 && object2 && object1.id == object2.id;
  }
  private _filter(value: any): any {
    if (value && typeof value === "string")
      return this.listuf.filter((x) => x.Nome.toLowerCase().indexOf(value.toLowerCase()) == 0)
    else
      return this.listuf
  }
  private _filterCidade(value: any): any {
    if (value && typeof value === "string")
      return this.listCidade?.filter((x) => x.Nome.toLowerCase().indexOf(value.toLowerCase()) == 0)
    else
      return this.listCidade
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key == 'K' && event.ctrlKey && event.shiftKey) {
      //  //console.log(this.camposForm)
      this.teste.setValueForm(this.camposForm);
      this.teste.setValueForm(this.dadosCobranca);
    }
  }
  get formacaoAcademica(): FormArray {
    return this.camposForm.get('formacaoAcademica') as FormArray;
  }
  remFormacaoAcademica(i: number) {
    this.formacaoAcademica.removeAt(i)
  }
  addFormacaoAcademica() {
    this.formacaoAcademica.push(this.createFormacaoAcademica());
  }
  get experienciaProfissional(): FormArray {
    return this.camposForm.get('experienciaProfissional') as FormArray;
  }
  remExperienciaProfissional(i: number) {
    this.experienciaProfissional.removeAt(i)
  }
  addExperienciaProfissional() {
    this.experienciaProfissional.push(this.createExperienciaProfissional());
  }
  get cursosLivres(): FormArray {
    return this.camposForm.get('cursosLivres') as FormArray;
  }
  remCursoslivres(i: number) {
    this.cursosLivres.removeAt(i)
  }
  addCursosLivres() {
    this.cursosLivres.push(this.createCursosLivres());
  }
  get idiomas(): FormArray {
    return this.camposForm.get('Idiomas') as FormArray;
  }
  remIdiomas(i: number) {
    this.idiomas.removeAt(i)
  }
  addIdiomas() {
    this.idiomas.push(this.createIdioma());
  }
  apenasNumeros(event: any) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  onInput(): void {
    this.showRemainCounter = true;
  }
  getRemainingCharacters(indice: any): number {
    const x = this.camposForm.controls.experienciaProfissional;
    const y = x.controls[indice].get('experienciaProfissionalAtividades');
    const z = y?.value.length;
    return this.maxCharacters - z;
  }

  setaArquivosPolo(entrada: any) {
    let documentos = this.camposForm.get('arquivos')?.value;
    let resul = [...documentos as any[], ...entrada.arquivos];
    this.camposForm.get('arquivos')?.setValue(resul as any);
  }
  resetaArquivosPolo(entrada: any) {
    let documentos = entrada.arquivos;
    this.camposForm.get('arquivos')?.setValue(documentos);
  }
}
