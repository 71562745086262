<div class="wrapper-item">
  <div class="wrapper-header-item">
    <h3 class="content-title">
      {{ item.produto.titulo }}
      <span data-toggle="tooltip" title="Para os pedidos de RGI, o valor cobrado pelo cartório será informado posteriormente e deverá ser pago separadamente." *ngIf="item.produto.descricaoTipoAto === 'RGI'"><img src="../../../../assets/question-circle-fill.svg"></span>

      <span data-toggle="tooltip" title="Para os pedidos de RTD, o valor cobrado pelo cartório será informado posteriormente e deverá ser pago separadamente." *ngIf="item.produto.descricaoTipoAto === 'RTD'"><img src="../../../../assets/question-circle-fill.svg"></span>

    </h3>

    <div class="wrapper-buttons">
      <div class="content-button">
        <button class="buttons-action"  (click)="editar.emit(item)">
          Editar
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/05a3523c983604fe0000ef79b0c38af35a7a81c7e6b32230af7c4a88344d432f?"
               class="img-11" />
        </button>
      </div>
      <div class="content-button">
        <button class="buttons-action" (click)="excluirItem.emit(item)">
          Excluir
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/dcdd700d816beff971594d71d60bdd298e4130c545dd466135231c4783571eb5?"
               class="img-12" />
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="w-100"></mat-divider>

  <div class="wrapper-info-cartorio">
    <div class="wrapper-topo">
      <div class="content-left">
        <span class="title-box">{{item.produto.titulo}}</span>

      </div>
      <div class="content-right">
        <span class="title-box">Valor</span>
        <p class="text-info-box">{{item.totalAto | currency:'BRL' }}</p>
      </div>
    </div>
    <ng-container *ngIf="visibilidadeDetalhes">
      <div class="content-info-solicitante ">
        <div class="row">
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">UF</h3>
            <p class="info-section">{{item.uf.Sigla}}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Cidade</h3>
            <p class="info-section">{{item.cidade.Nome}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Tipo de Registro</h3>
            <p class="info-section">{{item.tipoSolicitacao}}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Tipo de Documento</h3>
            <p class="info-section">{{item.tipoDocumento}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-6" *ngIf="item.produto.titulo == 'Registro de Imóvel'">
            <h3 class="title-section">Cartório</h3>
            <p class="info-section">{{item.cartorio}}</p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Informação Adicional</h3>
            <p class="info-section">{{item.informacaoAdicional}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Documentos para Registro</h3>
            <p class="info-section" *ngFor="let arquivo of item.arquivosRegistro">
              <a [href]="arquivo?.nome" target="_blank" class="col-md-6 col-xs-12">
                {{ arquivo.nomeOriginal }}
              </a>
            </p>
          </div>
          <div class="wrapper-info col-lg-6">
            <h3 class="title-section">Documentos Adicionais</h3>
            <p class="info-section" *ngFor="let arquivo of item.arquivosAdicionais">
              <a [href]="arquivo?.nome" target="_blank" class="col-md-6 col-xs-12">
                {{ arquivo.nomeOriginal }}
              </a>
            </p>
          </div>
        </div>

      </div>
</ng-container>
  </div>
  <button class="view-more-details" (click)="visibilidadeDetalhes=!visibilidadeDetalhes">
    {{ visibilidadeDetalhes ? 'Ocultar detalhes' : 'Ver detalhes' }}
  </button>
</div>
