import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LocalStorage } from '../../_entidade/LocalStorage';
import { CrudService } from '../../_sevice/CrudService';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})


export class LoginComponent implements OnInit {
  cpfMask = '000.000.000-00';
  phoneMask = '(00) 0000-0000';
  pedidos: any;
  login: any = { login: '', senha: '' }
  hidePassword1 = true;
  hidePassword2 = true;
  mensagemErroResponse = '';

  @Input() conteudo: any;
  @Input() relacionarCompra: any = false
  @Input() interno: any = false;

  formLogin = this.formBuilder.group({
    Username: ['', Validators.required],
    Password: ['', Validators.required]
  });


  get f() {
    return this.formLogin.controls;
  }
  togglePasswordVisibility(field: number): void {
    if (field === 1) {
      this.hidePassword1 = !this.hidePassword1;
    } else if (field === 2) {
      this.hidePassword2 = !this.hidePassword2;
    }
  }

  constructor(
    public route: ActivatedRoute
    , public router: Router
    , private http: HttpClient
    , public storage: LocalStorage
    , public service: CrudService
    , private formBuilder: FormBuilder
    , @Inject(DOCUMENT) private document: any) {

  }


  ngOnInit() {
    let token = this.route.snapshot.queryParamMap.get('tk')
    if (token) {
      this.validarAutenticacao(token)

    } else {
      this.limparSession()
    }

  }
  limparSession() {
    //this.storage.localStorageRemove("userlogged")
    //this.storage.localStorageRemove("logado")
  }
  mostrarCadastro() {
    const container = (<HTMLInputElement>this.document.getElementById('container'))
    container?.classList.add("right-panel-active")
  }

  mostrarLogin() {
    const container = (<HTMLInputElement>this.document.getElementById('container'))
    container?.classList.remove("right-panel-active")
  }


  logar() {
    const ctrl = this.formLogin.controls.Password


    let url = environment.http.API_AUTH + 'v1/account/login/entrar'
    //console.log(url)
    let use = {
      "userName": this.f.Username.value,
      "password": this.f.Password.value,
      "app": "cnr"
    };

    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    this.service.postObservableDirect(url, use).subscribe({
      next: async (response) => {
        if (response != null && response.code == 0) {

          this.storage.localStorageSet("userlogged", response['returnData'])
          this.storage.localStorageSet("logado", true)

          let usuario = this.storage.localStorageGet("userlogged");
          this.CarregaPerfis(usuario.keyLogin, usuario);
        } else {
          ctrl.setErrors({ usuarioerro: true });
          this.mensagemErroResponse = response?.messageAlert;
        }
      }, error: (err) => {

        ctrl.setErrors({ usuarioerro: true });
        this.mensagemErroResponse = "Erro ao realizar o login";

        console.error(err);
      },
      complete: () => (<HTMLInputElement>document.getElementById("myNav")).style.width = "0%"
    });


  }

  loginWithGoogle() {
    let url = environment.http.API_AUTH + 'v1/account/login/google'
    window.location.href = url;
  }

  loginWithFacebook() {
    let url = environment.http.API_AUTH + 'v1/account/login/facebook'
    window.location.href = url;
  }

  validarAutenticacao(token: any) {
    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";
    let url = environment.http.API_AUTH + 'v1/account/estalogado'
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    this.http.get(url, { headers: headers }).subscribe(
      (resultado1: any) => {
        this.storage.localStorageSet("userlogged", resultado1)
        this.storage.localStorageSet("logado", true)
        this.router.navigate(['/admin/meus-pedidos']);
      },
      (erro) => {
        //console.log(erro)
      }
    )
  }

  validarCampoFormRequired(campo: string): boolean {
    return this.formLogin.get([campo])?.errors?.['required'] &&
      (this.formLogin.get([campo])?.touched || this.formLogin.get([campo])?.dirty);
  }


  CarregaPerfis(guid: any, usuario: any) {
    (<HTMLInputElement>document.getElementById("myNav")).style.width = "100%";

    let dados = JSON.parse(usuario.dadosFaturamento)

    //console.log('Tem Informação de trocar senha', dados)
    if (dados.trocarsenha != null) {
      usuario.redefinicaoSenha = dados.trocarsenha
    }

    if (usuario.redefinicaoSenha == true) {
      //console.log('rota', this.router.navigate(['/institucional/redefinicao-senha']))
      this.router.navigate(['/institucional/redefinicao-senha'])

    } else { 
      let pedido = this.storage.localStorageGet("pedido")
      let meet = this.storage.localStorageGet("meetAndamento")
      if (pedido) {

        document.location.href = '/pedido/meu-carrinho'

      } else if (meet) {
        this.storage.localStorageRemove("meetAndamento")

        document.location.href = '/mediacao/institucional/solicitar-mediacao'

      } else {
        //this.router.navigate(['/admin/meus-pedidos']);
        document.location.href = '/admin/meus-pedidos?l=t'

      }
    }


  }
}
