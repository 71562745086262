import { ChangeDetectorRef, Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { LocalStorage } from '../../../_entidade/LocalStorage';
import { CrudService } from '../../../_sevice/CrudService';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() conteudo: any; 
  @Output() closeModal = new EventEmitter<any>();

  constructor(
    public storage: LocalStorage,
    private cdRef: ChangeDetectorRef,
    public service: CrudService
  ) {
    

  }
 

  closeModalInterno(modal: any) { 
    (<HTMLInputElement>document.getElementById("myNavBusca")).style.width = "0%";
    (<HTMLInputElement>document.getElementById("inputSearch")).innerText = "";
    (<HTMLInputElement>document.body).style.overflow = "visible";
    this.closeModal.emit()
  }

  ngOnInit(): void {
    //console.log('sdfsd');
    (<HTMLInputElement>document.getElementById("myNavBusca")).style.width = "100%";
    (<HTMLInputElement>document.body).style.overflow = "hidden";
  }
    

}
