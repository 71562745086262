<button mat-icon-button (click)="addRaiz()"><mat-icon>add</mat-icon></button>
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <!--<mat-checkbox class="checklist-leaf-node"
            [checked]="checklistSelection.isSelected(node)"
            (change)="todoLeafItemSelectionToggle(node)"></mat-checkbox>-->
    {{node.Descricao}}

  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-form-field>
      <mat-label>Item</mat-label>
      <input matInput #itemValue>
    </mat-form-field>
    <button mat-button (click)="saveNode(node, itemValue.value)">Save</button>
    <button mat-button (click)="cancelSaveNode(node)">Cancel</button>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding class="row">
    <div class="col-1">
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.Descricao">
        <mat-icon class="mat-icon-rtl-mirror" *ngIf="node.children.length >0">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
    </div>

    <div class="col-7">
      <span *ngIf="!node.editNode">{{node.Descricao}}</span>
      <input matInput *ngIf="node.editNode" [(ngModel)]="node.Descricao">
    </div>
    <div class="col-3">
      <button mat-icon-button (click)="addNewItem(node)" *ngIf="!node.editNode"><mat-icon>add</mat-icon></button>
      <button mat-icon-button (click)="editNode(node)" *ngIf="!node.editNode"><mat-icon>edit</mat-icon></button>
      <button mat-icon-button (click)="deleteItem(node)" *ngIf="!node.editNode"><mat-icon>delete</mat-icon></button>
      <button mat-icon-button (click)="updateNode(node)" *ngIf="node.editNode"><mat-icon>save</mat-icon></button>
    </div>



  </mat-tree-node>
</mat-tree>
