import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '../../../_entidade/LocalStorage';

@Component({
  selector: 'app-footer-v3',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterV3Component implements OnInit {

  @Input() data: any;
  conteudo: any

  navigationSubscription;
  logado = false;

  atosNotariais: any;
  urlCadastro :string = environment.urlCadastro

  constructor(
    private router: Router,
    public st: LocalStorage
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {

      if ( this.st.localStorageGet('logado') === true) {
        const elements = document.getElementsByClassName('faleconosco') as HTMLCollectionOf<HTMLElement>;

        for (let i = 0; i < elements.length; i++) {
          elements[i].style.display = 'none';
        }
      }

    });
  }
  ngOnInit() {
    this.conteudo = this.data.footer

  }

}
