<div class="wrapper-item">

  <div class="wrapper-header-item">
    <div>
      <h3 class="content-title" *ngIf="item.apostilamento=='true'">
        {{ item.produto.titulo }} (com apostilamento)
      </h3>
    </div>

    <div>
      <h3 class="content-title" *ngIf="item.apostilamento=='false'">
        {{ item.produto.titulo }}
      </h3>
    </div>

    <div class="wrapper-buttons">
      <div class="content-button">
        <button class="buttons-action" (click)="editar.emit(item)">
          Editar
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/05a3523c983604fe0000ef79b0c38af35a7a81c7e6b32230af7c4a88344d432f?"
               class="img-11" />
        </button>
      </div>
      <div class="content-button">
        <button class="buttons-action" (click)="excluirItem.emit(item)">
          Excluir
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/dcdd700d816beff971594d71d60bdd298e4130c545dd466135231c4783571eb5?"
               class="img-12" />
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="w-100"></mat-divider>

  <div class="wrapper-info-cartorio">
    <div class="wrapper-topo">
      <div class="content-left">

      </div>
      <div class="content-right">
        <span class="title-box">Valor</span>
        <p class="text-info-box">{{valor| currency:'BRL'  }}</p>
      </div>
    </div>

    <ng-container *ngIf="visibilidadeDetalhes">
      <div class="content-info-solicitante">
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Tipo do Ato</h3>
            <p class="info-section">{{item.tipo.descricao}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Distrito</h3>
            <p class="info-section">{{item.distrito.descricao}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Concelho</h3>
            <p class="info-section">{{item.concelho.descricao}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Freguesia</h3>
            <p class="info-section">{{item.freguesia.descricao}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Nome do Pai</h3>
            <p class="info-section">{{item.pai}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Nome da Mãe</h3>
            <p class="info-section">{{item.mae}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Nome</h3>
            <p class="info-section">{{item.nome}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Conjuge</h3>
            <p class="info-section">{{item.conjuge}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Cota Atual</h3>
            <p class="info-section">{{item.cota}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Página</h3>
            <p class="info-section">{{item.pagina}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Data do Ato</h3>
            <p class="info-section">{{item.dataato| date:'dd/MM/yyyy'}}</p>
          </div>
        </div>
        <div class="row">

          <div class="wrapper-info col-lg-12">
            <h3 class="title-section">Informação Adicional</h3>
            <p class="info-section">{{item.informacaoAdicional}}</p>
          </div>
        </div>
        <div class="row">
          <h2 class="title-info-solicitante">Endereço para envio</h2>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">País</h3>
            <p class="info-section">{{item.dadosEntrega.pais}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Estado / Província</h3>
            <p class="info-section">{{item.dadosEntrega.estado}}</p>
          </div>
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Cidade</h3>
            <p class="info-section">{{item.dadosEntrega.cidade}}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrapper-info col-lg-4">
            <h3 class="title-section">Logradouro</h3>
            <p class="info-section">{{item.dadosEntrega.enderecoCompleto}}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <button class="view-more-details" (click)="visibilidadeDetalhes=!visibilidadeDetalhes">
    {{ visibilidadeDetalhes ? 'Ocultar detalhes' : 'Ver detalhes' }}
  </button>
</div>
