<div class="wrapper-item">
  <div class="wrapper-header-item">
    <h3 class="content-title">
      {{ item.produto.titulo }}
    </h3>
    <div class="content-right">
      <p class="text-info-box">{{getPreco(item.produto.composicaoValor) | currency:'BRL'}}</p>
    </div>
    <div class="wrapper-buttons">
      <div class="content-button">
        <button class="buttons-action" (click)="editar.emit(item)">
          Editar
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/05a3523c983604fe0000ef79b0c38af35a7a81c7e6b32230af7c4a88344d432f?"
               class="img-11" />
        </button>
      </div>
      <div class="content-button">
        <button class="buttons-action" (click)="excluirItem.emit(item)">
          Excluir
          <img loading="lazy"
               src="https://cdn.builder.io/api/v1/image/assets/TEMP/dcdd700d816beff971594d71d60bdd298e4130c545dd466135231c4783571eb5?"
               class="img-12" />
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="w-100"></mat-divider>

  <div class="wrapper-info-cartorio">

    <ng-container *ngIf="visibilidadeDetalhes">
      <ng-container *ngFor="let result of itemDetalhes.campos">
        <div class="content-info-solicitante" *ngIf="result.tipo!='breakLine'">
          <div class="row">
            <div class="wrapper-info"  [class]="'col-lg-'+result.largura">
              <h3 class="title-section" >{{result.label}}</h3>
              <p class="info-section" *ngIf="result.tipo=='cpfCnpj' || result.tipo=='cpf' || result.tipo== 'cnpj'">{{item[result.prop] | mask:'000.000.000-00||00.000.000/0000-00'}}</p>
              <p class="info-section" *ngIf="result.tipo=='data'">{{item[result.prop] | date:'dd/MM/yyyy'}}</p>
              <p class="info-section" *ngIf="result.tipo=='uf'">{{item[result.prop]?.Nome}}</p>
              <p class="info-section" *ngIf="result.tipo!='cpfCnpj' && result.tipo!='cpf' &&  result.tipo!='data' &&  result.tipo!='cnpj' &&  result.tipo!='uf'">{{item[result.prop]}}</p>
            </div>
          </div>
        </div>
      </ng-container>


    </ng-container>
  </div>
  <button class="view-more-details" (click)="visibilidadeDetalhes=!visibilidadeDetalhes">
    {{ visibilidadeDetalhes ? 'Ocultar detalhes' : 'Ver detalhes' }}
  </button>
</div>
