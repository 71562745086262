export class configPage {
  Itens: any
   
  constructor() {
    this.Itens = {
      pageSize: 6,
      ItensPorPagina: [6, 12, 18, 24]
    }
  }

}
