<!--<iframe src="https://admin.e-cartoriodobrasil.com/Pedido/MeusPedidos" style="min-height:800px; width:100%"></iframe>-->
<!--<iframe src="https://homologacao1.e-cartoriotcs.com.br/Usuario/CrossDomainAccess/?org=nac&guid=952fbfe2-e18d-4705-b709-448adca36870&callback=https%3A%2F%2Fhomologacao1.e-cartoriotcs.com.br%2FPedido%2FMeusPedidos" style="min-height:800px; width:100%"></iframe>-->
<img style="display:none" id="logoPrint" src="/assets/cnr/logoCNR.png" />
<section class="header-pedido" id="titleMeusPedidos">
  <div class="container">
    <h2>Câmara Arbitral de Mediação</h2>
  </div>
</section>
<div class="wrapper-content e-cartorio">
  <div class="container" id="conpr">
    <form [formGroup]="camposForm" id="filtro">
      <ng-container>
        <div class="row">
          <b>Filtros:</b>
          <div class="col-md-2">
            <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="dataInicial">Data inicial</mat-label>
              <input matInput [matDatepicker]="picker" type="text" formControlName="dataInicial" appMaskDate
                     maxlength="10" (dateInput)="pesquisar()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label for="dataFinal">Data final</mat-label>
              <input matInput [matDatepicker]="picker2" type="text" formControlName="dataFinal" appMaskDate
                     maxlength="10" (dateInput)="pesquisar()">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Número do pedido</mat-label>
              <input matInput type="text" (input)="pesquisar()" class="col-lg-12" formControlName="numeroPedido"
                     placeholder="Número do pedido">
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo">
              <mat-label>Status</mat-label>
              <mat-select formControlName="tipoStatus" (selectionChange)="pesquisar()">
                <mat-option value="">Todos</mat-option>
                <mat-option value="aguardando-pagamento-audiencia">Aguardando Pagamento Audiência</mat-option>
                <mat-option value="pagamento-audiencia-realizado">Pagamento Audiência Realizado</mat-option>
                <mat-option value="aguardando-audiencia-inicial">Aguardando Audiência inicial</mat-option>
                <mat-option value="audiencia-inicial-realizada">Audiência inicial realizada</mat-option>
                <mat-option value="aguardando-audiencia-instruçao">Aguardando Audiência de instrução</mat-option>
                <mat-option value="audiencia-instrucao-realizada">Audiência de instrução realizada</mat-option>
                <mat-option value="aguardando-audiencia-julgamento">Aguardando Audiência de julgamento</mat-option>
                <mat-option value="audiencia-julgamento-realizada">Audiência de julgamento realizada</mat-option>
                <mat-option value="audiencia-finalizada">Audiência finalizada</mat-option>
                <mat-option value="mediacao-cancelada">Mediação Cancelada</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
        <div class="row">
          <div class="col-md-4">
            <button mat-raised-button color="primary" (click)="download()">Download Excel</button>

          </div>
        </div>
      </ng-container>
    </form>

    <ng-container *ngFor="let item of pedidos; let i = index">

      <div class="card-item" [id]="i+'p'">
        <div class="col-lg-12" *ngIf="isSuperAdm">
          Solicitante: {{item?.emailUser}}
          <button name="opcaoPedido" class="btn-next" mat-button (click)="trocarStatus('pagamento-analise-realizado','Pagamento Análise Realizado',item)">
            Agendar Audiência inicial
          </button><br />
                   <button name="opcaoPedido" class="btn-next" mat-button (click)="trocarStatus('aguardando-pagamento-audiencia','Aguardando Pagamento Audiência',item)">
                     Aguardando Pagamento Audiência
                   </button><br />
          <button name="opcaoPedido" class="btn-next" mat-button (click)="trocarStatus('pagamento-audiencia-realizado','Pagamento Audiência Realizado',item)">
            Pagamento Audiência Realizado
          </button><br />
          <button name="opcaoPedido" class="btn-next" mat-button (click)="trocarStatus('aguardando-audiencia-inicial','Aguardando Audiência inicial',item)">
            Aguardando Audiência inicial
          </button><br />
          <button name="opcaoPedido" class="btn-next" mat-button (click)="trocarStatus('audiencia-inicial-realizada','Audiência inicial realizada',item,true,0)">
            Audiência inicial realizada
          </button><br />
          <button name="opcaoPedido" class="btn-next" mat-button (click)="trocarStatus('audiencia-instrucao-realizada','Audiência de instrução realizada',item,true,1)">
            Audiência de instrução realizada
          </button><br />
        </div>
        <div class="row align-items-center">
          <div class="col-lg-3">
            <b>Número do pedido:</b> {{item?.numeroPedido}}
          </div>
          <div class="col-lg-3">
            <b>Status:</b>
            <ng-container *ngIf="item?.status?.slug=='pagamento-analise-realizado'">Agendar Audiência inicial</ng-container>
            <ng-container *ngIf="item?.status?.slug!='pagamento-analise-realizado'"> {{item?.status.descricao}}</ng-container>

          </div>
          <div class="col-lg-2">
            <a *ngIf="item?.dadosPagamento?.notaFiscalDisponivel" (click)="baixarNotaFiscal(item.idPedido)" style="cursor: pointer"><b>Nota Fiscal</b></a>
          </div>
          <div class="col-lg-4 txtRigth">
            <button name="opcaoPedido" mat-button [matMenuTriggerFor]="menu">
              Opções do pedido
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button type="button" (click)="item.showDadosHitorico = !item?.showDadosHitorico" mat-menu-item>
                <mat-icon>content_copy</mat-icon>
                <span>Histórico</span>
              </button>
              <button type="button" *ngIf="isBack" (click)="trocarStatus('mediacao-cancelada','Mediação Cancelada',item)" mat-menu-item>
                <mat-icon>content_copy</mat-icon>
                <span>Reprovar</span>
              </button>

            </mat-menu>
          </div>
          <mat-divider [inset]="true" style="margin-bottom:16px"></mat-divider>


          <div class="col-lg-6">

            <button mat-stroked-button color="primary" name="opcaoPedido" *ngIf="item?.status?.slug=='pagamento-audiencia-realizado' && isBack" class="btn-next" mat-button (click)="agendar(item, {slug: 'aguardando-audiencia-inicial', descricao: 'Aguardando Audiência inicial'})">
              Agendar Audiência inicial
            </button><br />

            <button mat-stroked-button color="primary" name="opcaoPedido" *ngIf="item?.status?.slug=='audiencia-inicial-realizada' && isBack" class="btn-next" mat-button (click)="agendar(item, {slug: 'aguardando-audiencia-instruçao', descricao: 'Aguardando Audiência de instrução'})">
              Agendar Audiência de instrução
            </button>

            <button mat-stroked-button color="primary" name="opcaoPedido" *ngIf="item?.status?.slug=='audiencia-instrucao-realizada' && isBack" class="btn-next" mat-button (click)="agendar(item, {slug: 'aguardando-audiencia-julgamento', descricao: 'Aguardando Audiência de julgamento'})">
              Agendar Audiência de julgamento
            </button>

          </div>
          <div class="col-md-6 txtRigth" *ngIf="!item?.mostraUpload">


            <button *ngIf="item?.status?.slug!='mediacao-cancelada'" mat-raised-button color="Basic" type="button" (click)="item.mostraUpload=true">
              Enviar arquivos
              <span class="material-symbols-outlined">
                cloud_upload
              </span>
            </button>
          </div>






        </div>
        <mat-divider [inset]="true"></mat-divider>

        <ng-container *ngIf="item?.showDadosFaturamento==true">

          <div style="background-color: azure; padding: 10px;">

            <ng-container *ngIf="!item?.dadosFaturamento">
              Sem dados
            </ng-container>
            <ng-container *ngIf="item?.dadosFaturamento">
              <div class="row">
                <div class="col-sm-4" *ngIf="">
                  <h3 class="col-lg-8"><b>Nome</b></h3>
                  <p class="info-section">{{item?.dadosFaturamento?.nome}}</p>
                </div>

                <div class="col-sm-4">
                  <h3 class="col-lg-8"><b>Email</b></h3>
                  <p class="info-section">{{item?.dadosFaturamento?.email}}</p>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-4">
                  <h3 class="col-lg-4"><b>CPF/CNPJ</b></h3>
                  <p class="info-section">
                    {{item?.dadosFaturamento?.cnpjCpf | mask:'000.000.000-00||00.000.000/0000-00'}}
                  </p>
                </div>
                <div class="col-sm-4">
                  <h3 class="col-lg-4"><b>Celular</b></h3>
                  <p class="info-section">{{item?.dadosFaturamento?.celular}}</p>
                </div>
                <div class="col-sm-4">
                  <h3 class="col-lg-4"><b>Telefone Fixo</b></h3>
                  <p class="info-section">{{item?.dadosFaturamento?.telfixo}}</p>
                </div>
              </div>
              <hr>
            </ng-container>
          </div>

        </ng-container>
        <!---->
        <ng-container *ngIf="item?.showDadosHitorico==true">

          <div style="background-color: azure; padding: 10px;">
  
            
            <div class="row" *ngFor="let hist of  item?.historico;let i=index">
              <div class="col-sm-4">
                <h3 class="col-lg-8"><b>Responsável</b></h3>
                <p class="info-section">{{hist?.usuario}}</p>
              </div>

              <div class="col-sm-4">
                <h3 class="col-lg-8"><b>Ação</b></h3>
                <p class="info-section">{{hist?.acao}}</p>
              </div>
              <div class="col-sm-4">
                <h3 class="col-lg-8"><b>Data</b></h3>
                <p class="info-section">{{hist?.data | date: 'dd/MM/yyyy' }}</p>
              </div>
            </div>

              
              <hr> 
          </div>

        </ng-container>

        <div class="row">
          <div class="col-lg-8">
            <b>Data do pedido:</b> {{ item?.dataCriacao | date: 'dd/MM/yyyy' }}
          </div>
          <div class="col-lg-4 txtRigth">
            <b>Valor Sugerido:</b> {{ item?.meetingInfo[0]?.informacoesAdicionais?.ValorSugerido | currency:'BRL' }}
          </div>
        </div>
        <div class="row" *ngIf="item?.status?.slug =='pagamento-analise-realizado' && isBack">


          <div class="col-lg-2">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo my-form-field" [ngClass]="item.obrigatoriedadecategoria">
              <mat-label>Categoria</mat-label>
              <mat-select [(ngModel)]="item.exigencia.categoria" (selectionChange)="validarObrigatoriedadeCategoria(item)">
                <mat-option value="Mediação">Mediação</mat-option>
                <mat-option value="Conciliação">Conciliação</mat-option>
                <mat-option value="Arbitragem">Arbitragem</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <div class="col-lg-2">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo my-form-field" (keyup)="validarObrigatoriedadetaxaCNR(item)" [ngClass]="item.obrigatoriedadetaxaCNR">
              <mat-label>Adicional CNR</mat-label>
              <input matInput type="text" currencyMask
                     [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="col-lg-12" [(ngModel)]="item.exigencia.taxaCNR"
                     placeholder="Taxa CNR">
            </mat-form-field>

          </div>
          <div class="col-lg-3 txtRigth">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo  my-form-field" (keyup)="validarObrigatoriedadetaxaAdministracao(item)" [ngClass]="item.obrigatoriedadetaxaAdministracao">
              <mat-label>
                Adicional de Administração
              </mat-label>
              <input matInput type="text" currencyMask
                     [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="col-lg-12" [(ngModel)]="item.exigencia.taxaAdministracao"
                     placeholder="Taxa de Administração ">
            </mat-form-field>
          </div>
          <div class="col-lg-3 txtRigth">
            <mat-form-field appearance="outline" class="w-100 my-2 prot-tab-input-conteudo my-form-field" (keyup)="validarObrigatoriedadehonorario(item)" [ngClass]="item.obrigatoriedadehonorario">
              <mat-label>Honorários do Mediador/Hora</mat-label>
              <input matInput type="text" currencyMask
                     [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" [(ngModel)]="item.exigencia.honorario"
                     placeholder="Honorários do Mediador/Hora">
            </mat-form-field>
          </div>
          <div class="col-lg-2 txtRigth">
            <button type="button" (click)="salvarExigencia(item)" class="w-100 my-2 btnSubmit btn w-100 btn-primary btn-sm"
                    style="display: block; margin: 0 auto;">
              <span>Salvar</span>
            </button>
          </div>
        </div>
        <div class="row" *ngIf="item?.status?.slug =='aguardando-pagamento-audiencia'">
          <div class="col-lg-2">
            <h3 class="title-section "><b>Categoria</b></h3>
            {{item.exigencia.categoria}}
          </div>
          <div class="col-lg-2">
            <h3 class="title-section "><b>Adicional CNR</b></h3>
            {{item.exigencia.taxaCNR.toFixed(2) | currency:'BRL'}}
          </div>
          <div class="col-lg-2">
            <h3 class="title-section"><b>Adicional de Administração</b></h3>
            {{item.exigencia.taxaAdministracao.toFixed(2) | currency:'BRL'}}
          </div>
          <div class="col-lg-3">
            <h3 class="title-section"><b>Honorários do Mediador/Hora</b></h3>
            {{item.exigencia.honorario.toFixed(2) | currency:'BRL'}}
          </div>

          <div class="col-lg-2 txtRigth">
            <button mat-button class="btnSubmit btn w-100 btn-primary btn-sm" (click)="item.pagaExigencia=true"
                    *ngIf="item?.status?.slug =='aguardando-pagamento-audiencia'">
              <mat-icon>paid</mat-icon>
              <span>Efetuar pagamento</span>
            </button>
          </div>
        </div>
        <ng-container *ngIf="item?.meetingInfo?.length >0">
          <ng-container *ngFor="let meet of  item?.meetingInfo;let i=index">
            <div class="row" style="background-color: #053d65;padding: 5px;" *ngIf="meet?.agendaDataInicio">
              <div class="col-lg-4" style=" color: white; ">
                <span style="font-weight: 500; color: white; ">{{meet?.tipo?.descricao}} :</span> <br />{{ meet?.tituloMeeting}}
              </div>
              <div class="col-lg-2" style=" color: white; ">
                <span style="font-weight: 500; color: white; ">Data:</span> <br />{{ meet?.agendaDataInicio | date: 'dd/MM/yyyy' }}
              </div>
              <div class="col-lg-2" style=" color: white; ">
                <span style="font-weight: 500; color: white; ">Horário:</span> <br />{{ meet?.agendaDataInicio | date: 'HH:mm' }} -
                {{ meet?.agendaDataFinal | date: 'HH:mm' }}
              </div>
              <div class="col-lg-4 txtRigth" style=" color: white; ">
                <button name="opcaoPedido" *ngIf="meet?.meetFinalizada ==false" style="color: white;background-color: #517ea5;margin: 2px;" mat-button (click)="entratMeet(item, meet.meetingGuid)">
                  Entrar
                </button>
                <button name="opcaoPedido" *ngIf="meet?.meetFinalizada && !meet?.ataEnviada && isBack && !meet?.enviarAta" style="color: white;background-color: #517ea5;margin: 2px;"
                        class="btn-next" mat-button (click)="enviarAta(meet,i,item)">
                  Finalizar/Enviar ata
                </button><br />
              </div>
              <div class="col-lg-12" *ngIf="!item?.mostraUpload">
                <app-meusPedidosOrderDetails-5 [esconderCopias]="true" [compra]="item?.meetingInfo[i]?.pedidoAssinatura?.listaCompra[0]"
                                               *ngIf="item?.meetingInfo[i]?.pedidoAssinatura"></app-meusPedidosOrderDetails-5>
              </div>
            </div>
            <div class="row" *ngIf="meet?.enviarAta">
              <div class="col-lg-12 " name="btnDetalhes">
                <app-uploadFiles (setaArquivos)="setaArquivos($event)" [classObrigatoria]="faltaArquivo" (resetaArquivos)="resetaArquivos($event)"></app-uploadFiles>
                <div class="row">
                  <div class="col-lg-2 ">
                    <button name="opcaoPedido" *ngIf="enviarAta" class="btnSubmit btn w-100 btn-primary btn-sm cancelButton" mat-button (click)="meet.enviarAta=false">
                      Cancelar
                    </button>
                  </div>
                  <div class="col-lg-8">
                  </div>
                  <div class="col-lg-2 txtRigth">
                    <button name="opcaoPedido" *ngIf="enviarAta" class="btnSubmit btn w-100 btn-primary btn-sm" mat-button (click)="concluirMeet(item)">
                      Concluir
                    </button>
                  </div>

                </div>
              </div>

            </div>
          </ng-container>
        </ng-container>

        <app-payment-v2 *ngIf="item?.status?.slug =='aguardando-pagamento-audiencia' && item?.pagaExigencia" [pedido]="item" [compraAtual]="item?.listaCompra[0]" [exigencia]="item?.listaCompra[0]?.listaExigencia[0]" [origem]="'mediacao'"> </app-payment-v2>

        <app-exigencias *ngIf="item?.listaExigencia?.length > 0"
                        (enviarMensagemExigenciaEvent)="enviarMensagemExigencia($event, item, i)" [pedido]="item"
                        [compraAtual]="item" [exigencias]="item?.listaExigencia"
                        (pagarEvent)="pagarExigencia($event)"></app-exigencias>
        <!--/dados do pedido/-->
        <div class="row" *ngIf="item?.mostraUpload">
          <div class="col-lg-12 txtCenter" name="btnDetalhes">
            <app-uploadFiles (setaArquivos)="setaArquivosMeet($event)" [itemAtual]="item"
                             (resetaArquivos)="resetaArquivosMeet($event)" [filesAtu]="item?.arquivos"></app-uploadFiles>
          </div>

        </div>
        <div class="row" *ngIf="item?.mostraUpload">
          <div class="col-lg-3 txtCenter" name="btnDetalhes">
            <button type="button" class="btnSubmit btn w-100 btn-primary btn-sm cancelButton" (click)="item.mostraUpload = false">Cancelar</button>
          </div>
          <div class="col-lg-6 txtCenter" name="btnDetalhes">

          </div>
          <div class="col-lg-3 txtCenter" name="btnDetalhes">
            <button type="button" class="btnSubmit btn w-100 btn-primary btn-sm" (click)="atualizaPedido(item,false,true,'envio de arquivos extras')">Salvar</button>
          </div>
        </div>
        <div class="row" *ngIf="!item?.mostraUpload">
          <div class="col-lg-12 txtCenter" name="btnDetalhes">
            <button mat-button *ngIf="item?.verDetalhes" class="view-more-details" type="button"
                    (click)="ocultarDetalhes(item,i)">
              Ocultar detalhes
            </button>
            <button mat-button *ngIf="!item?.verDetalhes" class="view-more-details" type="button"
                    (click)="verDetalhesPedido(item,i)">
              Ver detalhes
            </button>
          </div>
        </div>
        <!---->

        <ng-container *ngIf="item?.verDetalhes">
          <h2>Arquivos</h2>
          <div class="row">

            <div class="col-md-12" *ngFor="let arquivo of item?.arquivos; let i = index">
              <a [href]="arquivo?.nome" target="_blank" class="col-md-6 col-xs-12">
                {{ arquivo.nomeOriginal }}
              </a>
            </div>

          </div>

          <h2>Informações Polo</h2>
          <div class="row">

            <div class="col-md-3">
              <h3 class="title-section"><b>Polo</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.tipoPolo}}
            </div>

            <div class="col-md-3">
              <h3 class="title-section"><b>Tipo Pessoa</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.tipoPessoa}}
            </div>
            <div class="col-md-3" *ngIf="item.meetingInfo[0]?.participantes[0]?.tipoPessoa =='Física'">
              <h3 class="title-section"><b>Gênero</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.genero}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <h3 class="title-section" *ngIf="item.meetingInfo[0]?.participantes[0]?.tipoPessoa =='Física'"><b>CPF</b></h3>
              <h3 class="title-section" *ngIf="item.meetingInfo[0]?.participantes[0]?.tipoPessoa =='Jurídica'"><b>CNPJ</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.cnpjCpf| mask:'000.000.000-00||00.000.000/0000-00'}}
            </div>
            <div class="col-md-4" *ngIf="item.meetingInfo[0]?.participantes[0]?.tipoPessoa =='Física'">
              <h3 class="title-section"><b>Nome</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.nome}}
            </div>
            <div class="col-md-4" *ngIf="item.meetingInfo[0]?.participantes[0]?.tipoPessoa =='Jurídica'">
              <h3 class="title-section"><b>Razão Social</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.razaoSocial}}
            </div>
            <div class="col-md-4" *ngIf="item.meetingInfo[0]?.participantes[0]?.tipoPessoa =='Física'">
              <h3 class="title-section"><b>Nome Social</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.nomeSocial}}
            </div>
            <div class="col-md-4" *ngIf="item.meetingInfo[0]?.participantes[0]?.tipoPessoa =='Jurídica'">
              <h3 class="title-section"><b>Nome Fantasial</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.nomeFantasia}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <h3 class="title-section"><b>CEP</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.endereco.cep}}
            </div>
            <div class="col-md-3">
              <h3 class="title-section"><b>Municipio</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.endereco.municipio.Nome}}
            </div>
            <div class="col-md-3">
              <h3 class="title-section"><b>UF</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.endereco.uf.Sigla}}
            </div>
            <div class="col-md-3">
              <h3 class="title-section"><b>Logradouro</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.endereco.logradouro}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <h3 class="title-section"><b>Número</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.endereco.numero}}
            </div>
            <div class="col-md-3">
              <h3 class="title-section"><b>Complemento</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.endereco.complemento}}
            </div>
            <div class="col-md-3">
              <h3 class="title-section"><b>Bairro</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.endereco.bairro}}
            </div>
            <div class="col-md-3">
              <h3 class="title-section"><b>Telefone</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.telefone}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <h3 class="title-section"><b>Email</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.email}}
            </div>
            <div class="col-md-3">
              <h3 class="title-section"><b>Possui advodado</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.possuiAdvogado}}
            </div>
          </div>
          <div class="row">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Documentos</h3>
              <p class="info-section" *ngFor="let arquivo of item.meetingInfo[0]?.participantes[0]?.arquivos">
                <a [href]="arquivo?.nome" target="_blank" class="col-md-6 col-xs-12">
                  {{ arquivo.nomeOriginal }}
                </a>
              </p>
            </div>

          </div>

          <h2>Polo - Parte Contrária</h2>
          <div class="row">

            <div class="col-md-3">
              <h3 class="title-section"><b>Tipo Pessoa</b></h3>
              {{item.meetingInfo[0]?.participantes[1]?.tipoPessoa}}
            </div>
            <div class="col-md-3" *ngIf="item.meetingInfo[0]?.participantes[1]?.tipoPessoa =='Física'">
              <h3 class="title-section"><b>Gênero</b></h3>
              {{item.meetingInfo[0]?.participantes[1]?.genero}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <h3 class="title-section" *ngIf="item.meetingInfo[0]?.participantes[1]?.tipoPessoa =='Física'"><b>CPF</b></h3>
              <h3 class="title-section" *ngIf="item.meetingInfo[0]?.participantes[1]?.tipoPessoa =='Jurídica'"><b>CNPJ</b></h3>
              {{item.meetingInfo[0]?.participantes[1]?.cnpjCpf| mask:'000.000.000-00||00.000.000/0000-00'}}
            </div>
            <div class="col-md-4" *ngIf="item.meetingInfo[0]?.participantes[1]?.tipoPessoa =='Física'">
              <h3 class="title-section"><b>Nome</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.nome}}
            </div>
            <div class="col-md-4" *ngIf="item.meetingInfo[0]?.participantes[1]?.tipoPessoa =='Jurídica'">
              <h3 class="title-section"><b>Razão Social</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.razaoSocial}}
            </div>
            <div class="col-md-4" *ngIf="item.meetingInfo[0]?.participantes[1]?.tipoPessoa =='Física'">
              <h3 class="title-section"><b>Nome Social</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.nomeSocial}}
            </div>
            <div class="col-md-4" *ngIf="item.meetingInfo[0]?.participantes[1]?.tipoPessoa =='Jurídica'">
              <h3 class="title-section"><b>Nome Fantasial</b></h3>
              {{item.meetingInfo[0]?.participantes[0]?.nomeFantasia}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <h3 class="title-section"><b>CEP</b></h3>
              {{item.meetingInfo[0]?.participantes[1]?.endereco.cep}}
            </div>
            <div class="col-md-3">
              <h3 class="title-section"><b>Municipio</b></h3>
              {{item.meetingInfo[0]?.participantes[1]?.endereco.municipio.Nome}}
            </div>
            <div class="col-md-3">
              <h3 class="title-section"><b>UF</b></h3>
              {{item.meetingInfo[0]?.participantes[1]?.endereco.uf.Nome}}
            </div>
            <div class="col-md-3">
              <h3 class="title-section"><b>Logradouro</b></h3>
              {{item.meetingInfo[0]?.participantes[1]?.endereco.logradouro}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <h3 class="title-section"><b>Número</b></h3>
              {{item.meetingInfo[0]?.participantes[1]?.endereco.numero}}
            </div>
            <div class="col-md-3">
              <h3 class="title-section"><b>Complemento</b></h3>
              {{item.meetingInfo[0]?.participantes[1]?.endereco.complemento}}
            </div>
            <div class="col-md-3">
              <h3 class="title-section"><b>Bairro</b></h3>
              {{item.meetingInfo[0]?.participantes[1]?.endereco.bairro}}
            </div>
            <div class="col-md-3">
              <h3 class="title-section"><b>Telefone</b></h3>
              {{item.meetingInfo[0]?.participantes[1]?.telefone}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <h3 class="title-section"><b>Email</b></h3>
              {{item.meetingInfo[0]?.participantes[1]?.email}}
            </div>
            <div class="col-md-3">
              <h3 class="title-section"><b>Possui advodado</b></h3>
              {{item.meetingInfo[0]?.participantes[1]?.possuiAdvogado}}
            </div>
          </div>
          <div class="row">
            <div class="wrapper-info col-lg-6">
              <h3 class="title-section">Documentos</h3>
              <p class="info-section" *ngFor="let arquivo of item.meetingInfo[0]?.participantes[1]?.arquivos">
                <a [href]="arquivo?.nome" target="_blank" class="col-md-6 col-xs-12">
                  {{ arquivo.nomeOriginal }}
                </a>
              </p>
            </div>

          </div>

          <h2>Informações Adicionais</h2>
          <div class="row">

            <div class="col-md-3">
              <h3 class="title-section"><b>Competência</b></h3>
              {{item.meetingInfo[0].informacoesAdicionais.Competencia}}
            </div>

          </div>
          <div class="row">
            <div class="col-md-6">
              <h3 class="title-section"><b>Relato dos fatos</b></h3>
              {{item.meetingInfo[0].informacoesAdicionais.RelatoFatos}}
            </div>
            <div class="col-md-6">
              <h3 class="title-section"><b>Pedido sucinto</b></h3>
              {{item.meetingInfo[0].informacoesAdicionais.PedidoSucinto}}
            </div>
          </div>

        </ng-container>
        <div class="row">
          <div class="col-lg-12" name="btnDetalhes">
            <button name="opcaoPedido" *ngIf="item?.status?.slug=='audiencia-inicial-realizada' && isBack && !enviarAta" class="btn-next" mat-button (click)="agendar(item, {slug: 'aguardando-audiencia-instruçao', descricao: 'Aguardando Audiência de instrução'})">
              Agendar Audiência de instrução
            </button>
          </div>
        </div>

      </div>
    </ng-container>
    <h2></h2>
    <mat-paginator id="paginacaoItens" [length]="totalItens" [pageSize]="tamanhoPagina"
                   [pageSizeOptions]="[5, 10, 25, 100]" (page)="alterarPagina($event)" aria-label="Selecionar página">
    </mat-paginator>
    <h2></h2>
  </div>
</div>
