import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../../../_entidade/LocalStorage';


@Component({
  selector: 'app-orderDetails-11',
  templateUrl: './orderDetails11.component.html',
  styleUrls: ['../_formSteps.component.scss'] 
})
export class OrderDetails11Component implements OnInit {
 
  @Input() item: any
  @Output() excluirItem = new EventEmitter<any>();
  @Output() editar = new EventEmitter<any>();
  public visibilidadeDetalhes: boolean=false;


  constructor(
    public storage: LocalStorage,
    private route: Router,
  ) {

  }

  ngOnInit(): void {

  }

   getPreco(item:any):any{
    let valor=0
    item.forEach((resultado: any) => {        
         valor += resultado.valor        
        }); 
    return valor
  }

  // editar() {
  //   this.storage.localStorageSet("item_atual", this.item['Produto'])
  //   this.route.navigate([this.item['Produto'].aLink])

  // }
  // excluirItem(index: number) {
  //   const confirmar = confirm('Tem certeza que deseja excluir este item?');
  //   if (!confirmar) {
  //     return;
  //   }
  // }


  // toggleDetalhes(index: number): void {
  //   //this.visibilidadeDetalhes[index] = !this.visibilidadeDetalhes[index];
  // }
}
